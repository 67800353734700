<template>
    <b-col :lg="labelColsLg" :sm="labelColsSm">
        <b-form-group :description="description" :label="label + ':'" :label-for="id" :state="state" class="w-100" label-class="fs-14 grey-color fw-700">
            <multiselect
                v-model="text"
                :allow-empty="allowEmpty"
                :class="{ 'is-valid': state === true, 'is-invalid': state === false }"
                :clear-on-select="true"
                :close-on-select="true"
                :disabled="disabled"
                :label="labelField"
                :multiple="false"
                :options="options"
                :placeholder="placeholder"
                :preserve-search="false"
                :show-labels="false"
                :track-by="trackBy">
                <template v-if="allowEmpty" slot="clear">
                    <div v-if="value" class="multiselect__clear" @mousedown.prevent.stop="clearAll()"></div>
                </template>
                <span slot="noOptions">Нет данных.</span>
                <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
            </multiselect>
            <template v-if="!state">
                <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
            </template>
        </b-form-group>
    </b-col>
</template>

<script>
export default {
    name: 'frc-select',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            default: null
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        labelField: {
            type: String,
            default: 'name'
        },
        trackBy: {
            type: String,
            default: 'id'
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        allowEmpty: {
            type: Boolean,
            default: false
        },
        labelColsSm: {
            type: Number,
            default: 12
        },
        labelColsLg: {
            type: Number,
            default: 6
        }
    },
    methods: {
        clearAll() {
            this.text = null;
        }
    },
    computed: {
        text: {
            get() {
                return this.options ? this.options.find((e) => e[this.trackBy] === this.value) : null;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value ? value[this.trackBy] : null);
            }
        },
        id() {
            return 'form_row_single-select_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            }
            return '';
        }
    }
};
</script>

<style>
.multiselect__clear {
    position: absolute;
    right: 35px;
    height: 35px;
    width: 35px;
    display: block;
    cursor: pointer;
    z-index: 2;
}

.multiselect__clear:after,
.multiselect__clear:before {
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    height: 16px;
    background: #aaa;
    top: 12px;
    right: 4px;
}

.multiselect__clear:before {
    transform: rotate(45deg);
}

.multiselect__clear:after {
    transform: rotate(-45deg);
}
</style>
