var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-proposals" },
    },
    [
      _c("h3", [_vm._v("Предложения участников закупочной сессии")]),
      !_vm.$props.pivc.hasPoroposals && !_vm.$props.pivc.isProposalsInfoHidden
        ? _c("p", [_vm._v("Нет поданных предложений")])
        : _vm._e(),
      _vm.$props.pivc.isProposalsInfoHidden &&
      _vm.$props.pivc.purchaseitem.hide_proposals_at !== null
        ? _c("p", [
            _vm._v(
              "Период скрытия предложений участников в закупочной сессии наступил " +
                _vm._s(
                  _vm.$formatDateTimeIn(
                    _vm.$props.pivc.purchaseitem.hide_proposals_at
                  )
                )
            ),
          ])
        : _vm._e(),
      !_vm.$props.pivc.isProposalsInfoHidden &&
      _vm.$props.pivc.purchaseitem.hide_proposals_at !== null
        ? _c("p", [
            _vm._v(
              "Предложения участников будут скрыты " +
                _vm._s(
                  _vm.$formatDateTimeIn(
                    _vm.$props.pivc.purchaseitem.hide_proposals_at
                  )
                )
            ),
          ])
        : _vm._e(),
      _vm.$props.pivc.purchaseitem.protocol_date
        ? _c(
            "ul",
            {
              staticClass:
                "atmo-content-block-2-columns atmo-no-item-separator",
            },
            [
              _c("li", [
                _c("dt", [
                  _vm._v("Дата и время формирования итогового протокола:"),
                ]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.$props.pivc.purchaseitem.protocol_date,
                        "withSeconds"
                      )
                    )
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      !_vm.$props.pivc.isProposalsInfoHidden
        ? _c("div", { staticClass: "table-responsive" }, [
            _vm.$props.pivc.hasPoroposals
              ? _c(
                  "table",
                  { staticClass: "atmo-content-table atmo-proposals" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "atmo-place" }, [
                          _vm._v("Место"),
                        ]),
                        _c("th", { staticClass: "atmo-status" }, [
                          _vm._v("Статус"),
                        ]),
                        _c("th", { staticClass: "atmo-submit-date" }, [
                          _vm._v("Дата и время подачи"),
                        ]),
                        _c("th", { staticClass: "atmo-price-total" }, [
                          _vm._v("Стоимость без НДС, руб."),
                        ]),
                        _c("th", { staticClass: "atmo-price-total" }, [
                          _vm._v("Стоимость с НДС, руб."),
                        ]),
                        _c("th", { staticClass: "atmo-supplier" }, [
                          _vm._v("Поставщик"),
                        ]),
                        (_vm.$props.pivc.isPurchaseSummarized ||
                          _vm.$props.pivc.hasContract) &&
                        _vm.$props.pivc.isUserEngagedCustomer
                          ? _c("th", { staticClass: "atmo-attachments" }, [
                              _vm._v("Документы"),
                            ])
                          : _vm._e(),
                        _vm.$props.pivc.isPurchaseSummarized ||
                        _vm.$props.pivc.hasContract
                          ? _c(
                              "th",
                              {
                                staticClass: "atmo-signature",
                                attrs: { width: "50" },
                              },
                              [_vm._v("Подпись")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(this.$props.proposals, function (proposal, index) {
                        return _c("tr", { key: index }, [
                          _c("td", {
                            staticClass: "atmo-place",
                            domProps: { textContent: _vm._s(index + 1) },
                          }),
                          _c("td", { staticClass: "atmo-status" }, [
                            _vm._v(_vm._s(proposal.status.title) + " "),
                            proposal.is_current_supplier_proposal
                              ? _c("span", [_vm._v("(Мое)")])
                              : _vm._e(),
                          ]),
                          _c("td", { staticClass: "atmo-submit-date" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatdate")(
                                  proposal.submitted_at,
                                  "withSeconds"
                                )
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "atmo-price-total" }, [
                            _vm._v(
                              _vm._s(
                                proposal.price_wo_vat
                                  ? _vm.$formatPrice(proposal.price_wo_vat)
                                  : "—"
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "atmo-price-total" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatnumber")(proposal.total_price)
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "atmo-supplier" }, [
                            _vm.$props.pivc.isPurchaseSummarized ||
                            _vm.$props.pivc.hasContract ||
                            _vm.$props.pivc.hasPurchaseProtocol
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "atmo-button-link",
                                        attrs: {
                                          target: "_blank",
                                          to: {
                                            name: "OrganizationShow",
                                            params: {
                                              id: proposal.supplier.id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(proposal.supplier.title_brief)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v("Поставщик " + _vm._s(index + 1)),
                                ]),
                          ]),
                          (_vm.$props.pivc.isPurchaseSummarized ||
                            _vm.$props.pivc.hasContract) &&
                          _vm.$props.pivc.isUserEngagedCustomer
                            ? _c("td", { staticClass: "atmo-attachments" }, [
                                proposal.hasAttachments()
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        proposal.attachments,
                                        function (attachment, index) {
                                          return _c("li", { key: index }, [
                                            _c("button", {
                                              staticClass: "atmo-button-link",
                                              domProps: {
                                                textContent: _vm._s(
                                                  attachment.name
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$props.pivc.downloadFileAttachment(
                                                    attachment
                                                  )
                                                },
                                              },
                                            }),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c("span", [_vm._v("Нет")]),
                              ])
                            : _vm._e(),
                          _vm.$props.pivc.isPurchaseSummarized ||
                          _vm.$props.pivc.hasContract
                            ? _c(
                                "td",
                                {
                                  staticClass: "atmo-signature",
                                  attrs: { width: "50" },
                                },
                                [
                                  _c("action-button-small", {
                                    attrs: {
                                      icon: "clipboard-check",
                                      title: "Информация о подписи",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$props.pivc.invokeSignaturePopup(
                                          [proposal.signature]
                                        )
                                      },
                                    },
                                  }),
                                  proposal.signature.poa_number &&
                                  proposal.signature.poa_check_link
                                    ? _c("action-button-small", {
                                        staticClass: "ml-1",
                                        attrs: {
                                          icon: "clipboard-plus",
                                          title: "Информация о МЧД",
                                          variant: "warning",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$props.pivc.invokeSignaturePopup(
                                              [proposal.signature]
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }