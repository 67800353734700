<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <slot name="header">
                    <text-header>Спецификация</text-header>
                </slot>
            </b-col>
        </b-row>
        <b-table id="my-table" :fields="fields" :items="items" class="table-atmo" details-td-class="py-1" show-empty small responsive>
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>
            <template #row-details="data">
                <template v-if="data.item.ktru_item">
                    <p><b>Рег.номер ТРУ:</b> {{ data.item.ktru_item.reg_number }}</p>
                    <p><b>Группа ТРУ:</b> {{ data.item.ktru_item.ktru_group_name }}</p>
                    <p><b>Наименование:</b> {{ data.item.ktru_item.name }}</p>
                    <p><b>Тип:</b> {{ data.item.ktru_item.type }}</p>
                    <p v-if="data.item.okpd"><b>Код ОКПД2:</b> {{ data.item.okpd ? data.item.okpd.code : '&mdash;' }}</p>
                    <p v-if="data.item.ktru_item.country_of_origin_name"><b>Страна происхождения:</b> {{ data.item.ktru_item.country_of_origin_name }}</p>
                    <p v-if="data.item.ktru_item.description"><b>Описание:</b> {{ data.item.ktru_item.description }}</p>
                    <p v-if="data.item.ktru_item.specs && data.item.ktru_item.specs.length > 0"><b>Характеристики:</b></p>
                    <ul v-if="data.item.ktru_item.specs && data.item.ktru_item.specs.length > 0">
                        <li v-for="(spec, key) in data.item.ktru_item.specs" :key="'spec' + key">
                            <span class="fw-600">{{ spec.name }}</span>: {{ spec.value }}
                        </li>
                    </ul>
                </template>
                <template v-else>&mdash;</template>
            </template>
            <template #cell(specs)="data">
                <action-button-small :title="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" />
                {{ data.item.ktru_item.name }}
            </template>
            <template #cell(okei)="data">
                <template v-if="data.item.ktru_item.okei_local_symbol">{{ data.item.ktru_item.okei_local_symbol }}</template>
            </template>
            <template #cell(price)="data">
                {{ $formatPrice(data.item.price) }}
            </template>
            <template #cell(cost)="data">
                {{ $formatPrice(roundToTwoDecimals(data.item.total_price)) }}
            </template>
            <template #empty>
                <div class="w-100 text-center">Не добавлено позиций спецификации</div>
            </template>
        </b-table>
    </div>
</template>

<script>

import roundToTwoDecimals from "@lib/js/src/misc/roundToTwoDecimals";

export default {
    name: 'form-row-specification-table-view',
    props: {
        value: {
            type: Array,
            required: true
        },
        withPrice: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            baseFields: [
                { key: 'index', label: '№', tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '1px' } },
                { key: 'specs', label: 'Наименование товара (работ, услуг)', tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1', thStyle: { minWidth: '400px' } },
                { key: 'amount', label: 'Количество', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '160px' } },
                { key: 'okei', label: 'Ед.изм.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '100px' } },
            ],
            showSpec: false
        };
    },
    methods: {
        roundToTwoDecimals,
    },
    computed: {
        fields() {
            const fields = [...this.baseFields];
            if (this.withPrice) {
                fields.push({ key: 'price', label: 'Цена за ед., руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '160px' } });
                fields.push({ key: 'cost', label: 'Стоимость, руб.', tdClass: 'align-text-top py-1 text-nowrap text-right', thClass: 'py-1 text-right', thStyle: { width: '130px' } });
            }
            return fields;
        },
        items: {
            get() {
                return this.value;
            },
        }
    }
};
</script>
