'use strict';

export default {
    get: (state: Record<string, boolean>) => (popupName: string) => {
        const popupStatePoperty = `ison_${popupName}`;

        if (state[popupStatePoperty] === undefined) {
            throw new Error(`ATMO Exception: popup name "${popupName}" does not exist.`);
        }

        return state[popupStatePoperty];
    }
};
