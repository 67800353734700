'use strict';

import Vue from 'vue';
import ATMOAPIResponseNormalizedVO from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/ATMOAPIResponseNormalized.valueobject';
import ToastDataVO from './ToastData.valueobject';

export default class UIToastService {
    public vue: Vue;

    constructor(vue: Vue) {
        this.vue = vue;
    }

    public process(response: ATMOAPIResponseNormalizedVO): void {
        const toast = ToastDataVO.fromNormalizedResponse(response);

        // @ts-ignore
        this.vue.pushToast(toast.toToastOptions());
    }

    public processOnlyFailure(response: ATMOAPIResponseNormalizedVO): void {
        if (response.isSuccess) {
            return;
        }

        const toast = ToastDataVO.fromNormalizedResponse(response);

        // @ts-ignore
        this.vue.pushToast(toast.toToastOptions());
    }

    public show(toast: ToastDataVO): void {
        // @ts-ignore
        this.vue.pushToast(toast.toToastOptions());
    }
}
