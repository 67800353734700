'use strict';

export interface IRawDeliverableItemRequirement {
    name: string;
    value: string;
}

export default class DeliverableItemRequirementVO {
    title: string;
    description: string;

    constructor(rawData: IRawDeliverableItemRequirement) {
        this.title = rawData.name;
        this.description = rawData.value;

        Object.seal(this);
    }

    public static fromArray(data: IRawDeliverableItemRequirement[]): DeliverableItemRequirementVO[] {
        return data
            ? data.map((item: IRawDeliverableItemRequirement) => {
                  return new DeliverableItemRequirementVO(item);
              })
            : [];
    }
}
