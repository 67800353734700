'use strict';

import { IContractsState } from './state';
import ContractItem from '@core/js/ddriven/domain/model/contracts/ContractItem.valueobject';
import StandardPagination from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import { IContractRequest } from '@core/js/ddriven/application/http/requests/contracts/ContractRequest.valueobject';

export default {
    list: function (state: IContractsState): null | Readonly<ContractItem>[] {
        return state.list;
    },
    pagination: function (state: IContractsState): null | StandardPagination {
        return state.pagination;
    },
    request: function (state: IContractsState): IContractRequest {
        return state.request;
    },
    routereset: function (state: IContractsState): boolean {
        return state.routereset;
    },
    currentstatus: function (state: IContractsState): string | number | null | undefined {
        return state.request.status;
    }
};
