'use strict';

import { ExcludeMethodsNames } from '@core/js/ddriven/application/abstractions/types/misc';
import FinancingYearLimitVO from '../../common/dictionaries/kbk-limits/FinancingYearLimit.valueobject';
import KBKLimitDictionaryItemVO from '../../common/dictionaries/kbk-limits/KBKLimitDictionaryItem.valueobject';

type TSelfPOJO = Omit<KBKLimitSpecificationItemEditableVO, 'errors'>;

interface IErrors {
    amount_assigned: boolean;
}

export interface IRawKBKLimit {
    id: number;
    year: number;
    amount_assigned: number;
    amount_available: number;
    amount_total: number;
    kbk: string;
    code: string | null;

    // Details
    budget_name: string;
    subkosgu_code: string;
    action_code: string;
    funds_type: string;

    kosgu_code: string | null;
    source_funds: string | null;
    subsidy_code: string | null;
    invest_object: string | null;
    direction_code: string | null;
    regional_project_result: string | null;
    additional_information_code: string | null;
    eis_ident_no: string | null;
}

export default class KBKLimitSpecificationItemEditableVO {
    id: number | null;
    code: string | null;
    year: number | null;
    amount_total: number;
    amount_available: number;
    amount_assigned: number;

    // Details
    budget_name: string | null; // Наименование бюджета
    sub_kosgu_code: string | null; // Код СубКОСГУ;
    funds_type_code: string | null; // Код типа средств;
    event_code: string | null; // Код мероприятия;

    kosgu_code: string | null;
    source_funds: string | null;
    subsidy_code: string | null;
    invest_object: string | null;
    direction_code: string | null;
    regional_project_result: string | null;
    additional_information_code: string | null;
    eis_ident_no: string | null;

    constructor(self?: TSelfPOJO) {
        this.id = self?.id ?? null;
        this.code = self?.code ?? null;
        this.year = self?.year ?? null;
        this.amount_total = self?.amount_total ?? 0;
        this.amount_available = self?.amount_available ?? 0;
        this.amount_assigned = self?.amount_assigned ?? 0;

        this.budget_name = self?.budget_name ?? null;
        this.sub_kosgu_code = self?.sub_kosgu_code ?? null;
        this.funds_type_code = self?.funds_type_code ?? null;
        this.event_code = self?.event_code ?? null;

        this.kosgu_code = self?.kosgu_code ?? null;
        this.source_funds = self?.source_funds ?? null;
        this.subsidy_code = self?.subsidy_code ?? null;
        this.invest_object = self?.invest_object ?? null;
        this.direction_code = self?.direction_code ?? null;
        this.regional_project_result = self?.regional_project_result ?? null;
        this.additional_information_code = self?.additional_information_code ?? null;
        this.eis_ident_no = self?.eis_ident_no ?? null;
    }

    get errors(): IErrors {
        return {
            amount_assigned: this.amount_assigned > this.amount_available || this.amount_assigned <= 0
        };
    }

    public static fromDictionaryItem(data: KBKLimitDictionaryItemVO, limitYearSelected: number): KBKLimitSpecificationItemEditableVO {
        const limit = data.limits.find((limit: FinancingYearLimitVO) => {
            return limit.year === limitYearSelected;
        });

        const self: TSelfPOJO = {
            id: data.id,
            code: data.code,
            year: limitYearSelected,
            amount_available: limit?.amount_available ?? 0,
            amount_total: limit?.amount_total ?? 0,
            amount_assigned: 0,

            budget_name: data.budget_name ?? null,
            sub_kosgu_code: data.sub_kosgu_code ?? null,
            funds_type_code: data.funds_type_code ?? null,
            event_code: data.event_code ?? null,

            kosgu_code: data.kosgu_code,
            source_funds: data.source_funds,
            subsidy_code: data.subsidy_code,
            invest_object: data.invest_object,
            direction_code: data.direction_code,
            regional_project_result: data.regional_project_result,
            additional_information_code: data.additional_information_code,
            eis_ident_no: data.eis_ident_no
        };

        return new KBKLimitSpecificationItemEditableVO(self);
    }

    public static fromAPIResponse(data: IRawKBKLimit): KBKLimitSpecificationItemEditableVO {
        const self: TSelfPOJO = {
            id: data.id,
            code: data.code ?? data.kbk,
            year: data.year,
            amount_available: data.amount_available,
            amount_total: data.amount_total,
            amount_assigned: data.amount_assigned,

            budget_name: data.budget_name,
            sub_kosgu_code: data.subkosgu_code,
            funds_type_code: data.funds_type,
            event_code: data.action_code,

            kosgu_code: data.kosgu_code,
            source_funds: data.source_funds,
            subsidy_code: data.subsidy_code,
            invest_object: data.invest_object,
            direction_code: data.direction_code,
            regional_project_result: data.regional_project_result,
            additional_information_code: data.additional_information_code,
            eis_ident_no: data.eis_ident_no
        };

        return new KBKLimitSpecificationItemEditableVO(self);
    }

    public static build(item: KBKLimitSpecificationItemEditableVO, data: Partial<TSelfPOJO>): KBKLimitSpecificationItemEditableVO {
        const self: TSelfPOJO = Object.assign({}, item, data);

        return new KBKLimitSpecificationItemEditableVO(self);
    }
}
