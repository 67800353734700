import { createPopper } from '@popperjs/core';
import { Certificate } from 'crypto-pro';

export function scrollToTop(): void {
    window.scrollTo(0, 0);
}

export interface CertificateOwnerInfo {
    first_middle_name: String | null;
    inn: String | null;
    innul: String | null;
    last_name: String | null;
    ogrn: String | null;
    ogrnip: String | null;
    owner: String | null;
    snils: String | null;
}

export function json2xml(o: any) {
    // eslint-disable-next-line prefer-const
    let toXml = function (v: any, name: any, ind: any) {
            let xml = '';
            // убираем поля, которых не должно быть в xml
            if (name === 'signed_xml' || name === 'password' || name === 'password_confirmation' || name === 'created_at' || name === 'update_at' || name === 'deleted_at') {
                return xml;
            }
            if (typeof v == 'object') {
                let hasChild = false;
                xml += ind + '<' + name;
                for (const m in v) {
                    if (m.charAt(0) === '@') {
                        xml += ' ' + m.substr(1) + '="' + v[m].toString() + '"';
                    } else {
                        hasChild = true;
                    }
                }
                xml += hasChild ? '>\n' : '/>\n';
                if (hasChild) {
                    for (const m in v) {
                        if (m === '#text') {
                            xml += v[m];
                        } else if (m === '#cdata') {
                            xml += '<![CDATA[' + v[m] + ']]>';
                        } else if (m.charAt(0) !== '@') {
                            xml += toXml(v[m], m, ind + '\t');
                        }
                    }
                    xml += (xml.charAt(xml.length - 1) === '\n' ? ind : '') + '</' + name + '>\n';
                }
            } else {
                xml += ind + '<' + name + '>' + v.toString() + '</' + name + '>\n';
            }
            return xml;
        },
        xml = '';
    for (const m in o) {
        xml += toXml(o[m], m, '');
    }
    return xml;
}

// @ts-ignore
export function withPopper(dropdownList: any, component: any, { width }) {
    dropdownList.style.width = width;
    const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, -1]
                }
            }
        ]
    });

    return () => popper.destroy();
}

export async function prepareCertificateOwnerInfo(certificate: Certificate): Promise<CertificateOwnerInfo> {
    const ownerInfo = await certificate.getOwnerInfo();
    return {
        first_middle_name: ownerInfo.find((item) => item.title.toLowerCase() === 'имя отчество')?.description ?? null,
        inn: ownerInfo.find((item) => item.title.toLowerCase() === 'инн')?.description ?? null,
        innul: ownerInfo.find((item) => item.title.toLowerCase() === 'инн юл')?.description ?? null,
        last_name: ownerInfo.find((item) => item.title.toLowerCase() === 'фамилия')?.description ?? null,
        ogrn: ownerInfo.find((item) => item.title.toLowerCase() === 'огрн')?.description ?? null,
        ogrnip: ownerInfo.find((item) => item.title.toLowerCase() === 'огрнип')?.description ?? null,
        owner: ownerInfo.find((item) => item.title.toLowerCase() === 'владелец')?.description ?? null,
        snils: ownerInfo.find((item) => item.title.toLowerCase() === 'снилс')?.description ?? null
    };
}
