<template>
    <sign-organization-reliability-report-download-popup-controller v-slot:default="controller">
        <div v-if="controller.onoff.ison" class="atmo-popup-wrapper">
            <div v-bind:disabled="controller.custom.is_loading.report" class="atmo-popup-content atmo-save-purchase-popup">
                <button v-on:click.stop="controller.cancel" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                <header>
                    <h5>Скачать отчет о надежности организации</h5>

                    <tab-menu-controller v-slot:default="tmc" v-bind:tabmenuid="'sign-contract-tabmenu'">
                        <ul class="atmo-tabs-menu-universal">
                            <li>
                                <button v-on:click="tmc.select(1)" v-bind:class="{ 'atmo-active': tmc.isSelected(1) }">Информация о документе</button>
                            </li>
                            <li>
                                <button v-on:click="tmc.select(2)" v-bind:class="{ 'atmo-active': tmc.isSelected(2) }">XML-представление данных</button>
                            </li>
                        </ul>
                    </tab-menu-controller>
                </header>

                <tab-menu-tabs-controller v-slot:default="tmtc" v-bind:tabmenuid="'sign-contract-tabmenu'">
                    <main class="atmo-tabs-container">
                        <TabOrganizationInfo.view v-if="tmtc.isSelected(1)" v-bind:controller="controller" v-bind:certificate="controller.currentParsedCertificate" />

                        <TabXMLPreview.view v-if="tmtc.isSelected(2)" v-bind:xml="controller.xml" v-bind:certificate="controller.currentParsedCertificate" />
                    </main>
                </tab-menu-tabs-controller>

                <footer>
                    <CertificateInfo.view v-if="controller.hasCertificatesLoaded" v-bind:certificate="controller.currentParsedCertificate" />

                    <p>
                        Подписание запроса означает Ваше согласие с <b><a style="text-decoration: underline; color: var(--green-color)" v-bind:href="public_offer" target="_blank">офертой</a></b
                        >.
                    </p>

                    <ul class="atmo-buttons">
                        <li><button v-on:click="controller.cancel" class="atmo-button-inverse atmo-dimmed">Отмена</button></li>
                        <li v-if="!controller.isDataSigned && controller.hasCertificatesLoaded && controller.currentParsedCertificate">
                            <button v-on:click="controller.signData" class="atmo-button">Подписать</button>
                            <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
                        </li>
                        <li v-if="controller.isDataSigned">
                            <button v-on:click="controller.sign" class="atmo-button">Скачать</button>&nbsp;
                            <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
                        </li>
                    </ul>
                </footer>
            </div>
        </div>
    </sign-organization-reliability-report-download-popup-controller>
</template>

<script>
import Config from '@core/js/ddriven/application/config/Config';

import SignOrganizationReliabilityReportDownloadPopupController from '@core/js/viewmodels/misc/organization-reliability-report/SignOrganizationReliabilityReportDownloadPopupController.viewmodel';

import TabMenuController from '@lib/js/src/vue/components/tabmenu/TabMenuController.viewmodel';
import TabMenuTabsController from '@lib/js/src/vue/components/tabmenu/TabMenuTabsController.viewmodel';

import TabOrganizationInfo from './TabOrganizationInfo.view.vue';
import TabXMLPreview from 'themes/views/overlays/popups/common/partials/TabXMLPreview.view.vue';
import CertificateInfoView from 'themes/views/overlays/popups/common/partials/CertificateInfo.view.vue';

export default {
    components: {
        'sign-organization-reliability-report-download-popup-controller': SignOrganizationReliabilityReportDownloadPopupController,
        'tab-menu-controller': TabMenuController,
        'tab-menu-tabs-controller': TabMenuTabsController,
        'TabOrganizationInfo.view': TabOrganizationInfo,
        'TabXMLPreview.view': TabXMLPreview,
        'CertificateInfo.view': CertificateInfoView
    },
    computed: {
        public_offer() {
            return Config.get('theme.variables.links.info.public_offer');
        }
    }
};
</script>
