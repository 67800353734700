'use strict';

import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import X509SignatureVO, { IRawContractDisagreementSignatureData, IRawSupplementaryAgreementSignatureData } from '@core/js/ddriven/domain/model/common/X509Signature.valueobject';
import FileAttachmentVO, { IRawFileAttachmentData } from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import UserVO, { Types as UserTypes } from '@core/js/ddriven/domain/model/users/User.valueobject';

export interface IRawDisagreementData {
    [index: string]: unknown;

    // Common fields
    created_at: string;
    updated_at: string;

    // Supplier data source
    provider_organization_name: string;
    provider_comment: string;
    providerX509certificate: {
        '0': string;
    };
    providerX509certificateDate: string;
    provider_document: {
        id: string;
        name: string;
        type: string;
    };

    // Customer data source
    customer_organization_name: string | null;
    customer_comment: string;
    customerX509certificate: {
        '0': string | null;
    };
    customerX509certificateDate: string | null;
    customer_document: {
        id: string | null;
        name: string | null;
        type: string | null;
    };
    customer_signature: any;
    provider_signature: any;
}

export interface IRawDisputeData {
    created_at: string;
    updated_at: string;
    dispute_comment: string;
    dispute_file: IRawFileAttachmentData;
    dispute_signature: IRawSupplementaryAgreementSignatureData;
    reply_comment: string;
    reply_file: IRawFileAttachmentData;
    reply_signature: IRawSupplementaryAgreementSignatureData;
}

export default class DisputeResolutionEventVO extends BaseValueObject {
    private _usertype: string | null;
    private _translatedUsertype: string | null;

    created_at: string | null;
    comment: string | null;
    signature: X509SignatureVO | null;
    attachment: FileAttachmentVO | null;

    constructor(usertype?: UserTypes, data?: IRawDisagreementData) {
        super();

        this.defineHiddenProperty('_usertype');
        this.defineHiddenProperty('_translatedUsertype');

        this._usertype = usertype ?? null;
        this._translatedUsertype = usertype ? UserVO.translateType(usertype) : null;

        this.created_at = usertype && data ? (data[this.createDatePropertyNameByUsertype()] as string) : null;
        this.comment = usertype && data ? (data[this.propertyNameByUsertype('_comment')] as string) : null;
        this.signature = usertype && data && data[this.propertyNameByUsertype('X509certificate')] ? this.buildSignature(data) : null;
        this.attachment = usertype && data && data[this.propertyNameByUsertype('_document')] ? this.buildFileAttachment(data) : null;
    }

    public userType(): UserTypes {
        return this._usertype as UserTypes;
    }

    public isEmpty(): boolean {
        return this.attachment === null || (this.attachment !== null && this.attachment.file_id === null);
    }

    public isSigned(): boolean {
        return !!this.signature && this.signature.hasSignature();
    }

    private propertyNameByUsertype(propertyName: string): string {
        return `${this._translatedUsertype}${propertyName}`;
    }

    private buildSignature(data: IRawDisagreementData): X509SignatureVO {
        const signatureData: IRawContractDisagreementSignatureData = {
            organization_title: data[this.propertyNameByUsertype('_organization_name')] as string,
            owner: '',
            x509certificate: {
                '0': (data[this.propertyNameByUsertype('X509certificate')] as Record<string, string>)['0']
            },
            x509certificateDate: data[this.propertyNameByUsertype('X509certificateDate')] as string,
            signature: data[this.propertyNameByUsertype('_signature')] as X509SignatureVO
        };

        return new X509SignatureVO(this._usertype as UserTypes, signatureData);
    }

    private buildFileAttachment(data: IRawDisagreementData): FileAttachmentVO {
        const rawAttachmentData = data[this.propertyNameByUsertype('_document')] as Record<string, any>;
        const attachmentData: IRawFileAttachmentData = {
            id: rawAttachmentData.id,
            name: rawAttachmentData.name,
            is_archived: false,
            created_at: data[this.createDatePropertyNameByUsertype()] as string,
            type: rawAttachmentData.type
        };

        return new FileAttachmentVO(attachmentData);
    }

    private createDatePropertyNameByUsertype(): string {
        return this._usertype === UserTypes.supplier ? 'created_at' : 'updated_at';
    }
}
