var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          staticClass: "fs-18 grey-color text-left pb-0",
          attrs: {
            label: "Произошла ошибка при аутентификации",
            "label-class": "fs-18 fw-600 text-danger pl-3",
          },
        },
        [
          _c("b-container", { staticClass: "pl-3 pb-2" }, [
            _c("p", { staticClass: "fw-600 my-0" }, [
              _vm._v("Возможные причины ошибки:"),
            ]),
            _c("ul", { staticClass: "error-list" }, [
              _c("li", [
                _vm._v(
                  '— У вас не установлено и не настроено программное обеспечение "КриптоПро CSP" версии 4.0 или выше'
                ),
              ]),
              _c("li", [
                _vm._v(
                  "— Сертификат ключа проверки электронной подписи выдан Удостоверяющим центром Федерального казначейства."
                ),
              ]),
              _c("li", [_vm._v("— Срок действия сертификата истек.")]),
              _c("li", [
                _vm._v(
                  "— Сертификат ключа проверки электронной подписи находится в списке отозванных сертификатов."
                ),
              ]),
              _c("li", [
                _vm._v('— Вы нажали "отмена" при выборе сертификата.'),
              ]),
            ]),
          ]),
          _c("b-container", { staticClass: "pl-3" }, [
            _c("p", { staticClass: "fw-600 my-0" }, [
              _vm._v(
                'Техническая поддержка доступна по кнопке "Поддержка" (в правом нижнем углу).'
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }