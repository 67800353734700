'use strict';

import BaseValueObject from '../../../abstractions/ddriven/BaseValueObject.valueobject';
import { IData } from '@core/js/viewmodels/purchases/item/view/widgets/protocol/DeclinePoposalPopupController';

export default class DeclineProposalRequest extends BaseValueObject {
    offer_decline_status_id: number;
    comment: string;
    document: {
        id: string;
    };

    constructor(data: IData['inputs']) {
        super();

        this.offer_decline_status_id = data.declinereasonid as number;
        this.comment = data.comment as string;
        this.document = {
            id: data.attachment!.file_id as string
        };
    }
}
