import camelCase from 'lodash.camelcase';
import axios from 'axios';
import Config from '@/rearchitected/core/js/ddriven/application/config/Config';

const TTL = 5 * 60 * 1000;

const globalDictionariesModule = {
    namespaced: true,
    state: {
        deliverableGroups: [],
        purchaseStatuses: [],
        fl44ProposalStatuses: [],
        municipalities: [],
        budgetaryManagers: [],
        currencies: [],
        countries: [],
        userRoles: [],
        orderTypes: [],
        updatedAt: null,
        isUpdating: false
    },
    mutations: {
        set(state, data) {
            for (const key in data) {
                const stateKey = camelCase(key);
                if (stateKey in state) {
                    state[stateKey] = data[key];
                }
            }
            state['updatedAt'] = new Date();
        },
        globalDictionariesStartedUpdating(state) {
            state.isUpdating = true;
        },
        globalDictionariesFinishedUpdating(state) {
            state.isUpdating = false;
        }
    },
    actions: {
        async populateGlobalDictionaries(context) {
            if (context.state.isUpdating === true) {
                return;
            }
            context.commit('globalDictionariesStartedUpdating');
            if (context.state.updatedAt === null || new Date() - new Date(context.state.updatedAt).getTime() > TTL) {
                const globalDictionariesResponse = await axios.post(Config.get('api.config.v1.url') + '/dictionaries');
                context.commit('set', globalDictionariesResponse.data?.data);
            }
            context.commit('globalDictionariesFinishedUpdating');
        }
    },
    getters: {
        globalDictionaries(state) {
            return {
                deliverableGroups: state.deliverableGroups,
                purchaseStatuses: state.purchaseStatuses,
                fl44ProposalStatuses: state.fl44ProposalStatuses,
                municipalities: state.municipalities,
                budgetaryManagers: state.budgetaryManagers,
                currencies: state.currencies,
                countries: state.countries,
                userRoles: state.userRoles,
                orderTypes: state.orderTypes,
                orderTypesWithLimits: state.orderTypes.filter((item) => item.is_limits_used)
            };
        }
    }
};

export default globalDictionariesModule;
