'use strict';

import HTTPRESTAPIFacade from '@core/js/ddriven/application/ports/adapters/http/outgoing/HTTPRESTAPIFacade';
import { AxiosResponse } from 'axios';

import UserVO, { Types as UserTypes } from '@core/js/ddriven/domain/model/users/User.valueobject';
import PurchaseItemEditableRequestVO from '@core/js/ddriven/application/http/requests/purchases/PurchaseItemEditableRequest.valueobject';
import StoreContractRequestVO from '@core/js/ddriven/application/http/requests/contracts/StoreContractRequest.valueobject';
import LinkContractDisputeAttachmentRequestVO from '@core/js/ddriven/application/http/requests/purchases/disputes/LinkContractDisputeAttachmentRequest.valueobject';
import UpdateContractAnnexRequestVO from '@core/js/ddriven/application/http/requests/contracts/UpdateContractAnnexRequest.valueobject';
import StoreContractAnnexDisputeRequestVO from '@core/js/ddriven/application/http/requests/contracts/StoreContractAnnexDisputeRequest.valueobject';
import PurchaseItemEditableXMLRequestVO from '@core/js/ddriven/application/http/requests/purchases/PurchaseItemDetailsXMLRequest.valueobject';
import DeclineProposalRequest from '@core/js/ddriven/application/http/requests/purchases/DeclineProposalRequest.valueobject';
import SignContractRequestVO from '@core/js/ddriven/application/http/requests/contracts/SignContractRequest.valueobject';
import KBKLimitsUpdateRequestVO from '@/rearchitected/core/js/ddriven/application/http/requests/purchases/kbk-limits/KBKLimitsUpdateRequest.valueobject';
import ATMOAPIFacade from '../ATMOAPIFacade';
import ATMOAPIResponseNormalizedVO from '../ATMOAPIResponseNormalized.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';
import { Certificate } from 'crypto-pro';

export default class EntitiesAPIFacadePartial {
    http: HTTPRESTAPIFacade;
    baseFacade: ATMOAPIFacade;

    constructor(http: HTTPRESTAPIFacade, baseFacade: ATMOAPIFacade) {
        this.http = http;
        this.baseFacade = baseFacade;
    }

    // public async loadNoticesGlobal(queryString: string): Promise<AxiosResponse> {
    //     const path = `/notices?${queryString ?? ''}`;
    //
    //     return await this.http.call('GET', path);
    // }
    //
    public async loadNoticesFl223(queryString: string): Promise<AxiosResponse> {
        const path = `/notices/fl223?${queryString ?? ''}`;

        return await this.http.call('GET', path);
    }

    public async loadNoticesCommercial(queryString: string): Promise<AxiosResponse> {
        const path = `/notices/commercial?${queryString ?? ''}`;

        return await this.http.call('GET', path);
    }

    public async loadNoticesFl44(queryString: string): Promise<AxiosResponse> {
        const path = `/notices/fl44?${queryString ?? ''}`;

        return await this.http.call('GET', path);
    }

    public async loadPurchasesFl44(queryString: string): Promise<AxiosResponse> {
        const path = `/purchases?${queryString ?? ''}`;

        return await this.http.call('GET', path);
    }

    public async loadPurchasesItemById(id: number): Promise<AxiosResponse> {
        const path = `/purchases/${id}`;

        return await this.http.call('GET', path);
    }

    public async loadPurchaseProposalsForUserType(id: number, type: UserTypes): Promise<AxiosResponse> {
        const translatedType = UserVO.translateType(type);
        const path = `/purchases/${id}/proposal-dynamics/${translatedType}`;

        return await this.http.call('GET', path);
    }

    public async loadContractsFl44(queryString: string): Promise<AxiosResponse> {
        const path = `/contracts?${queryString}`;

        return await this.http.call('GET', path);
    }

    public async loadContractExecutionDocuments(id: number): Promise<AxiosResponse> {
        const path = `/contracts/${id}/execution_documents`;

        return await this.http.call('GET', path);
    }

    public async savePurchaseDraft(purchase: PurchaseItemEditableRequestVO): Promise<ATMOAPIResponseNormalizedVO> {
        const path = '/purchases/save_draft';

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, purchase as unknown as Record<string, unknown>);
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    public async removePurchaseDraft(id: number): Promise<AxiosResponse> {
        const path = `/purchases/${id}`;

        return await this.http.call('DELETE', path);
    }

    public async loadPurchaseDraft(id: number): Promise<AxiosResponse> {
        const path = `/purchases/${id}/draft`;

        return await this.http.call('GET', path);
    }

    public async savePurchase(purchase: PurchaseItemEditableRequestVO): Promise<ATMOAPIResponseNormalizedVO> {
        const path = '/purchases';

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, purchase as unknown as Record<string, unknown>);
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    public async signPurchaseContract(request: SignContractRequestVO): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/purchases/${request.purchase_id}/contract`;
        const { purchase_id, ...rest } = request.toPlainObject();

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, rest);
        ApplicationServiceLocator.get('uitoasts').process(response);

        return response;
    }

    public async storePurchaseContract(request: StoreContractRequestVO): Promise<ATMOAPIResponseNormalizedVO> {
        const path = '/purchases/contracts';

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, request as any);
        ApplicationServiceLocator.get('uitoasts').process(response);

        return response;
    }

    public async updatePurchaseStatus(contractId: number, purchaseStatusId: number): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/contracts/${contractId}/change_status`;
        const request = { status_id: purchaseStatusId };

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, request as any);
        ApplicationServiceLocator.get('uitoasts').process(response);

        return response;
    }

    public async uploadAndAttachContractFulfillmentAttachment(contractId: number, data: FormData): Promise<AxiosResponse> {
        const path = `/contracts/${contractId}/execution_documents`;

        return await this.http.call('POST', path, data as any);
    }

    public async removeContractFulfillmentAttachment(contractId: number, attachmentRecordId: number): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/contracts/${contractId}/execution_documents/${attachmentRecordId}`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'DELETE', path);
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    public async linkContractDisputeAttachment(request: LinkContractDisputeAttachmentRequestVO): Promise<ATMOAPIResponseNormalizedVO> {
        const path = '/purchases/contracts/sendDisagreementFile';

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, request.toPlainObject());
        ApplicationServiceLocator.get('uitoasts').process(response);

        return response;
    }

    public async loadSupplementaryAgreementItems(contractId: number): Promise<AxiosResponse> {
        const path = `/contracts/${contractId}/supplementary_agreement_items`;

        return await this.http.call('GET', path);
    }

    public async loadSupplementaryAgreements(contractId: number): Promise<AxiosResponse> {
        const path = `/contracts/${contractId}/supplementary_agreements`;

        return await this.http.call('GET', path);
    }

    public async updateContractAnnex(contractId: number, request: UpdateContractAnnexRequestVO): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/contracts/${contractId}/supplementary_agreements`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, request.toPlainObject());
        ApplicationServiceLocator.get('uitoasts').process(response);

        return response;
    }

    public async signContractAnnex(contractId: number, annexId: number, xml: string, thumbprint: string, limits: KBKLimitsUpdateRequestVO, supplier_accounting: SupplierAccountingVO, certificate: Certificate): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/contracts/${contractId}/supplementary_agreements/${annexId}/sign`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, {
            xml: xml,
            thumbprint: thumbprint,
            limits: JSON.parse(JSON.stringify(limits.items)),
            supplier_accounting: JSON.parse(JSON.stringify(supplier_accounting)),
            certificate: certificate
        });
        ApplicationServiceLocator.get('uitoasts').process(response);

        return response;
    }

    public async storeContractAnnexDispute(contractId: number, annexId: number, request: StoreContractAnnexDisputeRequestVO): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/contracts/${contractId}/supplementary_agreements/${annexId}/send_disagreement_file`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, request.toPlainObject());
        ApplicationServiceLocator.get('uitoasts').process(response);

        return response;
    }

    public async generatePurchaseContractXML(purchaseId: number, request: PurchaseItemEditableXMLRequestVO): Promise<AxiosResponse> {
        const path = `/purchases/${purchaseId}/contract/contract_xml`;

        return await this.http.call('POST', path, request.toPlainObject().supplier_requirements);
    }

    public async generatePurchaseXML(purchaseId: number): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/purchases/${purchaseId}/purchase_xml`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'GET', path);
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    public async summarizePurchaseProtocol(purchaseId: number): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/cabinet/customer/purchases/${purchaseId}/cancelHoldTime`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path);
        ApplicationServiceLocator.get('uitoasts').process(response);

        return response;
    }

    public async declineProposal(id: number, request: DeclineProposalRequest): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/offer/${id}/decline`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, request.toPlainObject());
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    public async updatePurchaseLimits(id: number, limits: KBKLimitsUpdateRequestVO): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/purchases/${id}/limits`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'PUT', path, JSON.parse(JSON.stringify(limits.items)));
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }
}
