'use strict';

import { Route } from 'vue-router';

import RouteDataServiceFacade from '../RouteDataServiceFacade';
import PurchaseRequest from '@core/js/ddriven/application/http/requests/purchases/PurchaseRequest.valueobject';

export default class PurchasesRouteDataService {
    private theFacade: RouteDataServiceFacade;

    constructor(theFacade: RouteDataServiceFacade) {
        this.theFacade = theFacade;
    }

    public async loadPurchasesGrouped(to: Route) {
        const request = PurchaseRequest.fromQueryObject(to.query, PurchaseRequest) as PurchaseRequest;
        await this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/purchases/dictionaries/loadDeliverablesGroups`);
        await this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/purchases/dictionaries/loadPurchaseTypes`);
        await this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/purchases/dictionaries/loadMunicipalities`);
        this.theFacade.loadEntitiesGrouped('purchases', request, to.query, to);
    }

    public async loadPurchasesGroupedItemView(to: Route, from: Route) {
        // Avoid API call if this is just the hash change on a page already loaded before.
        if (to.hash !== '' && from.path !== '/' && to.path === from.path) {
            return;
        }
        await this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/purchases/dictionaries/loadPurchaseTypes`);
        this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/purchases/loadItem`, to.params.id);
    }

    public async loadPurchasesGroupedItemCreate(to: Route) {
        await this.loadPurchasesGroupedItemUpdate(to);
        this.theFacade.store.commit(`rearchitected/groups/${to.params.group}/purchases/createNewPurchase`);
    }

    public async loadPurchasesGroupedItemEdit(to: Route) {
        await this.loadPurchasesGroupedItemUpdate(to);
        await this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/purchases/loadPurchaseDraft`, to.params.id);
    }

    private async loadPurchasesGroupedItemUpdate(to: Route) {
        if (this.theFacade.store.getters['rearchitected/users/isGuest']) {
            this.theFacade.router.push({ name: 'Login' });
            return;
        }
        await this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/purchases/dictionaries/forPurchaseEditable`);
    }
}
