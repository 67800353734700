<template>
    <div class="atmo-content-group">
        <h3>Требования к поставщикам</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt><label for="only-small-business">Участником закупки могут быть только субъекты малого предпринимательства (СМП):</label></dt>
                <dd>
                    <input v-model="$props.inputs.supplier_requirements.is_only_smb" type="checkbox" name="only-small-business" id="only-small-business" class="atmo-icon-checkmark" />
                </dd>
            </li>
            <li class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt><label for="rating">Установить требование к участникам по рейтингу с возможностью отклонения предложения участника с рейтингом ниже 4,5 балла:</label></dt>
                <dd>
                    <input v-model="$props.inputs.supplier_requirements.rating" type="checkbox" name="rating" id="rating" class="atmo-icon-checkmark" />
                </dd>
            </li>
            <li class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt>
                    <label for="federal-law-a31s1p1">Соответствие участника закупки требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:</label>
                </dt>
                <dd>
                    <input v-model="$props.inputs.supplier_requirements.a31s1p1" type="checkbox" name="federal-law-a31s1p1" id="federal-law-a31s1p1" class="atmo-icon-checkmark" />
                </dd>
            </li>

            <li v-show="$props.inputs.supplier_requirements.a31s1p1" class="atmo-nested-section">
                <!-- // REFACTOR: Replace as development finishes. -->
                <!-- <SupplierRequirementDetailsA31s1p1.view v-if="true"  -->
                <SupplierRequirementDetailsA31s1p1.view v-bind:requirementdetails="$props.inputs.supplier_requirements.a31s1p1_details" />
            </li>

            <li class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt>
                    <label for="federal-law-a31s1ps3_5ps7_11"
                        >Соответствие участника закупки требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ (обязательно):</label
                    >
                </dt>
                <dd>
                    <input v-model="$props.inputs.supplier_requirements.a31s1ps3_5ps7_11" disabled type="checkbox" name="federal-law-a31s1ps3_5ps7_11" id="federal-law-a31s1ps3_5ps7_11" class="atmo-icon-checkmark" />
                </dd>
            </li>
            <li class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt>
                    <label for="federal-law-a31s1_1">Соответствие участника закупки требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:</label>
                </dt>
                <dd>
                    <input v-model="$props.inputs.supplier_requirements.a31s1_1" type="checkbox" name="federal-law-a31s1_1" id="federal-law-a31s1_1" class="atmo-icon-checkmark" />
                </dd>
            </li>

            <li class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt><label for="only-small-business">Участник является социально ориентированной некоммерческой организацией (СОНКО):</label></dt>
                <dd>
                    <input v-model="$props.inputs.supplier_requirements.supplier_reqs_sonko" type="checkbox" name="requirement-sonko" id="requirement-sonko" class="atmo-icon-checkmark" />
                </dd>
            </li>

            <li class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt><label for="requirement-sonko">Дополнительные требования:</label></dt>
                <dd>
                    <input v-model="$props.inputs.supplier_requirements.additional.state.is_required" type="checkbox" name="requirement-sonko" id="requirement-sonko" class="atmo-icon-checkmark" />
                </dd>
            </li>

            <li v-show="$props.inputs.supplier_requirements.additional.state.is_required" class="atmo-nested-section">
                <AdditionalRequirement.view v-bind:additional="$props.inputs.supplier_requirements.additional" />
            </li>
        </ul>
    </div>
</template>

<script>
import SupplierRequirementDetailsA31s1p1View from './SupplierRequirementDetailA31s1p1.view.vue';
import AdditionalRequirementView from './AdditionalRequirement.view.vue';

export default {
    components: {
        'SupplierRequirementDetailsA31s1p1.view': SupplierRequirementDetailsA31s1p1View,
        'AdditionalRequirement.view': AdditionalRequirementView
    },
    props: {
        inputs: { required: true, type: Object }
    }
};
</script>
