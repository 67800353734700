var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-customer-info" },
    },
    [
      _c("h3", [_vm._v("Информация о заказчике")]),
      _c(
        "ul",
        { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
        [
          _c("li", [
            _c("dt", [_vm._v("Наименование:")]),
            _c("dd", [
              _c("a", {
                attrs: {
                  href: `/organizations/${this.$props.purchaseitem.customer.id}`,
                  target: "_blank",
                },
                domProps: {
                  textContent: _vm._s(this.$props.purchaseitem.customer.title),
                },
              }),
            ]),
          ]),
          _c("li", [
            _c("dt", [_vm._v("ИНН:")]),
            _c("dd", {
              staticClass: "atmo-placeholder-animated",
              domProps: {
                textContent: _vm._s(this.$props.purchaseitem.customer.inn),
              },
            }),
          ]),
          this.$props.purchaseitem.customer.kpp
            ? _c("li", [
                _c("dt", [_vm._v("КПП:")]),
                _c("dd", {
                  staticClass: "atmo-placeholder-animated",
                  domProps: {
                    textContent: _vm._s(this.$props.purchaseitem.customer.kpp),
                  },
                }),
              ])
            : _vm._e(),
          _c("li", [
            _c("dt", [_vm._v("Адрес:")]),
            _c("dd", {
              staticClass: "atmo-placeholder-animated",
              domProps: {
                textContent: _vm._s(this.$props.purchaseitem.customer.address),
              },
            }),
          ]),
          this.$props.purchaseitem.additional_contact_info
            ? _c("li", [
                _c("dt", [_vm._v("Дополнительная контактная информация:")]),
                _c("dd", {
                  staticClass: "atmo-placeholder-animated",
                  domProps: {
                    textContent: _vm._s(
                      this.$props.purchaseitem.additional_contact_info
                    ),
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }