var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
    [
      _c("li", { staticClass: "atmo-form__field atmo-inverse" }, [
        _vm._m(0),
        _c("dd", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$props.requirementdetails.description,
                expression: "$props.requirementdetails.description",
              },
            ],
            staticClass: "atmo-icon-checkmark",
            attrs: {
              type: "text",
              required: "",
              name: "federal-law-a31s1p1_details-description",
              id: "federal-law-a31s1p1_details-description",
            },
            domProps: { value: _vm.$props.requirementdetails.description },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.$props.requirementdetails,
                  "description",
                  $event.target.value
                )
              },
            },
          }),
        ]),
      ]),
      _c("supplier-requirement-supporting-documents-controller", {
        attrs: {
          initiallist: _vm.$props.requirementdetails.supporting_documents,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (srsdc) {
              return [
                _c("SRDA31s1p1SupportingDocuments.view", {
                  attrs: { controller: srsdc },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c(
        "label",
        { attrs: { for: "federal-law-a31s1p1_details-description" } },
        [
          _vm._v(
            "Укажите, каким требованиям должен соответствовать участник закупки:"
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }