'use strict';

import querystring from 'querystring';
import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';

export type TSelfBaseValueObject = keyof BaseValueObject;

export default abstract class BaseValueObject {
    public toPlainObject(): Record<string, any> {
        return JSON.parse(JSON.stringify(this));
    }

    public toQueryString(obj: Record<string, any>): string {
        const cleaned = Object.keys(obj).reduce((accumulator: any, key: string) => {
            if (!isPrimitiveEmpty(obj[key] as any)) {
                accumulator[key] = obj[key];
            }
            return accumulator;
        }, {});
        return querystring.stringify(cleaned);
    }

    protected defineHiddenProperty(name: string): void {
        Object.defineProperty(this, name, {
            writable: true
        });
    }
}
