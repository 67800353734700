'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import { IData, IVMEventPayload, TabIdentifier } from './TabMenuController.viewmodel';

@Component
export default class TabMenuTabsController extends BaseViewModel {
    constructor() {
        super();
        this.name = 'TabMenuTabsController';
    }

    @Prop({ required: true, type: String }) readonly tabmenuid!: number;

    created(): void {
        this.$root.$on('public:tabmenu:selected', this.processSelected);
    }

    data(): IData {
        return {
            tabIdentifier: 1
        };
    }

    /**
     * Computed
     */

    /**
     * Methods
     */
    isSelected(tabIdentifier: TabIdentifier): boolean {
        return this.$data.tabIdentifier === tabIdentifier;
    }

    /**
     * Common prototype methods
     */
    private processSelected(payload: IVMEventPayload): void {
        if (payload.id !== this.$props.tabmenuid) {
            return;
        }
        this.$data.tabIdentifier = payload.selected;
    }
}
