var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("standard-pagination-controller", {
    attrs: { pagination: _vm.pagination },
    on: { "pagination:pageupdate": _vm.pageupdatehandler },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (spc) {
          return [
            _c("div", { staticClass: "atmo-standard-pagination" }, [
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: spc.isPaginationVisible,
                      expression: "spc.isPaginationVisible",
                    },
                  ],
                  staticClass: "atmo-pages-list",
                },
                [
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: spc.isLeftControlsVisible,
                          expression: "spc.isLeftControlsVisible",
                        },
                      ],
                    },
                    [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return spc.page(spc.first)
                            },
                          },
                        },
                        [_c("span", [_vm._v("«")])]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: spc.isLeftControlsVisible,
                          expression: "spc.isLeftControlsVisible",
                        },
                      ],
                    },
                    [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return spc.page(spc.prev)
                            },
                          },
                        },
                        [_c("span", [_vm._v("‹")])]
                      ),
                    ]
                  ),
                  _vm._l(spc.pages, function (page) {
                    return _c("li", { key: page }, [
                      _c(
                        "button",
                        {
                          attrs: { disabled: spc.isActive(page) },
                          on: {
                            click: function ($event) {
                              return spc.page(page)
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(page) },
                          }),
                        ]
                      ),
                    ])
                  }),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: spc.isRightControlsVisible,
                          expression: "spc.isRightControlsVisible",
                        },
                      ],
                    },
                    [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return spc.page(spc.next)
                            },
                          },
                        },
                        [_c("span", [_vm._v("›")])]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: spc.isRightControlsVisible,
                          expression: "spc.isRightControlsVisible",
                        },
                      ],
                    },
                    [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return spc.page(spc.last)
                            },
                          },
                        },
                        [_c("span", [_vm._v("»")])]
                      ),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }