var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group", attrs: { id: "atmo-deliverables" } },
    [
      _c("h3", [_vm._v("Спецификация")]),
      _c("h5", [_vm._v("Список позиций спецификации")]),
      _vm._t("info"),
      _c("div", { staticClass: "table-responsive" }, [
        _c(
          "table",
          {
            staticClass: "atmo-content-table atmo-deliverables-editable",
            class: { "atmo-empty": this.isempty },
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "atmo-ctc-place" }, [_vm._v("№")]),
                _c("th", { staticStyle: { "min-width": "400px" } }, [
                  _vm._v("Наименование ТРУ"),
                ]),
                _c("th", { staticClass: "atmo-ctc-quantity" }, [
                  _vm._v("Количество"),
                ]),
                _c("th", { staticClass: "atmo-ctc-okpd-symbolic-code" }, [
                  _vm._v("Ед.изм"),
                ]),
                _c("th", { staticClass: "atmo-ctc-price-per-unit" }, [
                  _vm._v("Цена за ед., руб."),
                ]),
                _c("th", { staticClass: "atmo-ctc-price-total" }, [
                  _vm._v("Стоимость, руб."),
                ]),
                _vm.$props.islisteditable
                  ? _c("th", { staticClass: "atmo-ctc-command-remove" }, [
                      _vm._v(" "),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm._l(this.$props.deliverables, function (deliverable, index) {
                  return _c("fragment", { key: deliverable.id + index + 1 }, [
                    _c(
                      "tr",
                      { class: _vm.visible[index] ? "border-bottom-0" : "" },
                      [
                        _c("td", {
                          staticClass: "atmo-ctc-place",
                          domProps: { textContent: _vm._s(index + 1) },
                        }),
                        _c(
                          "td",
                          [
                            _c("action-button-small", {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: "collapse-" + index,
                                  expression: "'collapse-' + index",
                                },
                              ],
                              attrs: {
                                title:
                                  (!_vm.visible[index]
                                    ? "Показать"
                                    : "Скрыть") + " характеристики",
                                icon: !_vm.visible[index] ? "eye" : "eye-slash",
                              },
                            }),
                            _c("b-collapse", {
                              staticClass: "d-none",
                              attrs: { id: "collapse-" + index },
                              model: {
                                value: _vm.visible[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.visible, index, $$v)
                                },
                                expression: "visible[index]",
                              },
                            }),
                            _vm._v(" " + _vm._s(deliverable.title) + " "),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "atmo-ctc-quantity" },
                          [
                            _c("formatted-input-number", {
                              attrs: { decimals: 9, padright: false },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (fim) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "atmo-component-wrapper",
                                          },
                                          [
                                            !fim.isInputFocused
                                              ? _c("button", {
                                                  staticClass:
                                                    "atmo-button--small atmo-input",
                                                  class: {
                                                    "atmo-invalid":
                                                      !fim.isInputValid,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      fim.formatted
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return fim.focus.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    focus: fim.onTriggerFocus,
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: fim.isInputFocused,
                                                    expression:
                                                      "fim.isInputFocused",
                                                  },
                                                ],
                                                staticClass:
                                                  "atmo-form__field atmo-inverse atmo-small",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value:
                                                        deliverable.quantity,
                                                      expression:
                                                        "deliverable.quantity",
                                                      modifiers: {
                                                        number: true,
                                                      },
                                                    },
                                                    {
                                                      name: "stop-number-mousewheel",
                                                      rawName:
                                                        "v-stop-number-mousewheel",
                                                    },
                                                  ],
                                                  attrs: {
                                                    disabled:
                                                      !_vm.$props
                                                        .isquantityeditable,
                                                    required: "",
                                                    type: "number",
                                                    step: "0.000000001",
                                                    min: "0.000000001",
                                                  },
                                                  domProps: {
                                                    value: deliverable.quantity,
                                                  },
                                                  on: {
                                                    blur: [
                                                      fim.processBlurEvent,
                                                      function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    ],
                                                    keydown:
                                                      fim.processKeydownEvent,
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          deliverable,
                                                          "quantity",
                                                          _vm._n(
                                                            $event.target.value
                                                          )
                                                        )
                                                      },
                                                      fim.processInputEvent,
                                                    ],
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c("td", {
                          staticClass: "atmo-ctc-okpd-symbolic-code",
                          domProps: {
                            textContent: _vm._s(deliverable.okei_symbolic_code),
                          },
                        }),
                        _c(
                          "td",
                          { staticClass: "atmo-ctc-price-per-unit" },
                          [
                            _c("formatted-input-number", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (fim) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "atmo-component-wrapper",
                                          },
                                          [
                                            !fim.isInputFocused
                                              ? _c("button", {
                                                  staticClass:
                                                    "atmo-button--small atmo-input",
                                                  class: {
                                                    "atmo-invalid":
                                                      !fim.isInputValid,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      fim.formatted
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return fim.focus.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    focus: fim.onTriggerFocus,
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: fim.isInputFocused,
                                                    expression:
                                                      "fim.isInputFocused",
                                                  },
                                                ],
                                                staticClass:
                                                  "atmo-form__field atmo-inverse atmo-small",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value:
                                                        deliverable.price_per_unit,
                                                      expression:
                                                        "deliverable.price_per_unit",
                                                      modifiers: {
                                                        number: true,
                                                      },
                                                    },
                                                    {
                                                      name: "stop-number-mousewheel",
                                                      rawName:
                                                        "v-stop-number-mousewheel",
                                                    },
                                                  ],
                                                  attrs: {
                                                    required: "",
                                                    type: "number",
                                                    step: "0.01",
                                                    min: "0.01",
                                                  },
                                                  domProps: {
                                                    value:
                                                      deliverable.price_per_unit,
                                                  },
                                                  on: {
                                                    blur: [
                                                      fim.processBlurEvent,
                                                      function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    ],
                                                    keydown:
                                                      fim.processKeydownEvent,
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          deliverable,
                                                          "price_per_unit",
                                                          _vm._n(
                                                            $event.target.value
                                                          )
                                                        )
                                                      },
                                                      fim.processInputEvent,
                                                    ],
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return fim.processBlurEvent.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c("td", { staticClass: "atmo-ctc-price-total" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatnumber")(deliverable.price_total)
                            )
                          ),
                        ]),
                        _vm.$props.islisteditable
                          ? _c(
                              "td",
                              { staticClass: "atmo-ctc-command-remove" },
                              [
                                _c("action-button-small", {
                                  staticClass: "my-auto",
                                  attrs: {
                                    icon: "trash",
                                    title: "Удалить позицию из спецификации",
                                    disabled: !_vm.$props.islisteditable,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$props.controller.removeItem(
                                        index
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.visible[index],
                            expression: "visible[index]",
                          },
                        ],
                      },
                      [
                        _c(
                          "td",
                          { attrs: { colspan: "7" } },
                          [
                            _c("div", [
                              _c("p", [
                                _c("strong", [_vm._v("Рег. номер ТРУ:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      deliverable.gsc_details
                                        .registration_number
                                    )
                                ),
                              ]),
                              _c("ul", { staticClass: "fs-14" }, [
                                deliverable.okpd_code
                                  ? _c("li", [
                                      _c("strong", [_vm._v("Код ОКПД2: ")]),
                                      _vm._v(_vm._s(deliverable.okpd_code)),
                                    ])
                                  : _vm._e(),
                                _c("li", [
                                  _c("strong", [_vm._v("Группа ТРУ: ")]),
                                  _vm._v(_vm._s(deliverable.gsc_details.group)),
                                ]),
                                _c("li", [
                                  _c("strong", [_vm._v("Наименование: ")]),
                                  _vm._v(_vm._s(deliverable.gsc_details.title)),
                                ]),
                                _c("li", [
                                  _c("strong", [_vm._v("Тип: ")]),
                                  _vm._v(_vm._s(deliverable.gsc_details.type)),
                                ]),
                              ]),
                            ]),
                            _c("DeliverableDetails.view", {
                              attrs: {
                                description: deliverable.gsc_details
                                  ? deliverable.gsc_details.description
                                  : null,
                                requirements: deliverable.requirements,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                }),
                !this.isempty
                  ? _c("tr", { staticClass: "atmo-totals" }, [
                      _c("td", { attrs: { colspan: "5" } }, [
                        _vm._v("Стоимость спецификации итого, руб.:"),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatnumber")(
                              _vm.$props.controller.specificationTotalPrice
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                this.isempty
                  ? _c("tr", [
                      _c(
                        "td",
                        { staticClass: "atmo-center", attrs: { colspan: "7" } },
                        [_vm._v("Спецификация пуста")]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._t("errors"),
      _vm.$props.islisteditable
        ? _c(
            "button",
            {
              staticClass: "atmo-button",
              attrs: { disabled: !_vm.$props.islisteditable },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$props.controller.invokeDeliverablesSelectorPopup.apply(
                    null,
                    arguments
                  )
                },
              },
            },
            [_vm._v("Добавить позицию спецификации")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }