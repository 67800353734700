<template>
    <b-form-group :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" :class="compact ? 'mb-0' : ''">
        <ul :id="id" v-if="value.length > 0">
            <li v-for="document of value" :key="document.id">
                <a class="fs-14 col-form-label d-inline-block" :href="$getAttachmentDownloadLink(document.id)" target="_blank">{{ document.name }}</a>
            </li>
        </ul>
        <p :id="id" class="fs-14 grey-color my-auto col-form-label" v-else>Отсутствуют</p>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-documents',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            default: () => []
        },
        description: {
            type: String,
            default: ''
        },
        compact: {
            type: Boolean,
            default: false
        },
        labelColsLg: {
            default: 3
        },
        labelColsSm: {
            default: 4
        }
    },
    computed: {
        id() {
            return 'text_row_documents_' + this._uid;
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
a {
    color: var(--green-color) !important;
}
a:hover {
    color: var(--green-color) !important;
    text-decoration: underline !important;
}
</style>
