'use strict';

import StandardDictionaryItem from './StandardDictionaryItem.valueobject';

export default class ExtendedDictionaryItem extends StandardDictionaryItem {
    code?: null | number | string;
    value?: null | number | string | boolean;
    description?: null | string;

    constructor(dictionaryItemRawData: ExtendedDictionaryItem) {
        super(dictionaryItemRawData);

        this.id = dictionaryItemRawData.id;
        this.title = dictionaryItemRawData.title;
        this.code = dictionaryItemRawData.code || null;
        this.value = dictionaryItemRawData.value !== undefined ? dictionaryItemRawData.value : null;
        this.description = dictionaryItemRawData.description || null;
    }
}
