var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sign-organization-reliability-report-download-popup-controller", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (controller) {
          return [
            controller.onoff.ison
              ? _c("div", { staticClass: "atmo-popup-wrapper" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "atmo-popup-content atmo-save-purchase-popup",
                      attrs: { disabled: controller.custom.is_loading.report },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "atmo-button--small atmo-close",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return controller.cancel.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "atmo-icon-close" })]
                      ),
                      _c(
                        "header",
                        [
                          _c("h5", [
                            _vm._v("Скачать отчет о надежности организации"),
                          ]),
                          _c("tab-menu-controller", {
                            attrs: { tabmenuid: "sign-contract-tabmenu" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (tmc) {
                                    return [
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "atmo-tabs-menu-universal",
                                        },
                                        [
                                          _c("li", [
                                            _c(
                                              "button",
                                              {
                                                class: {
                                                  "atmo-active":
                                                    tmc.isSelected(1),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return tmc.select(1)
                                                  },
                                                },
                                              },
                                              [_vm._v("Информация о документе")]
                                            ),
                                          ]),
                                          _c("li", [
                                            _c(
                                              "button",
                                              {
                                                class: {
                                                  "atmo-active":
                                                    tmc.isSelected(2),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return tmc.select(2)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "XML-представление данных"
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("tab-menu-tabs-controller", {
                        attrs: { tabmenuid: "sign-contract-tabmenu" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (tmtc) {
                                return [
                                  _c(
                                    "main",
                                    { staticClass: "atmo-tabs-container" },
                                    [
                                      tmtc.isSelected(1)
                                        ? _c("TabOrganizationInfo.view", {
                                            attrs: {
                                              controller: controller,
                                              certificate:
                                                controller.currentParsedCertificate,
                                            },
                                          })
                                        : _vm._e(),
                                      tmtc.isSelected(2)
                                        ? _c("TabXMLPreview.view", {
                                            attrs: {
                                              xml: controller.xml,
                                              certificate:
                                                controller.currentParsedCertificate,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c(
                        "footer",
                        [
                          controller.hasCertificatesLoaded
                            ? _c("CertificateInfo.view", {
                                attrs: {
                                  certificate:
                                    controller.currentParsedCertificate,
                                },
                              })
                            : _vm._e(),
                          _c("p", [
                            _vm._v(
                              " Подписание запроса означает Ваше согласие с "
                            ),
                            _c("b", [
                              _c(
                                "a",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                    color: "var(--green-color)",
                                  },
                                  attrs: {
                                    href: _vm.public_offer,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("офертой")]
                              ),
                            ]),
                            _vm._v(". "),
                          ]),
                          _c("ul", { staticClass: "atmo-buttons" }, [
                            _c("li", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "atmo-button-inverse atmo-dimmed",
                                  on: { click: controller.cancel },
                                },
                                [_vm._v("Отмена")]
                              ),
                            ]),
                            !controller.isDataSigned &&
                            controller.hasCertificatesLoaded &&
                            controller.currentParsedCertificate
                              ? _c("li", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "atmo-button",
                                      on: { click: controller.signData },
                                    },
                                    [_vm._v("Подписать")]
                                  ),
                                  _c("div", { staticClass: "atmo-spinner" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                            controller.isDataSigned
                              ? _c("li", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "atmo-button",
                                      on: { click: controller.sign },
                                    },
                                    [_vm._v("Скачать")]
                                  ),
                                  _vm._v("  "),
                                  _c("div", { staticClass: "atmo-spinner" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }