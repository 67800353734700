import { PersonalFl44APIService } from './fl44';
import { PersonalFl44PurchaseReportsAPIService } from './fl44-reports';
import { PersonalFl223APIService } from './fl223';
import { PersonalCommercialAPIService } from './commercial';
import { PersonalAddressesAPIService } from './addresses';
import { PersonalLimitsAPIService } from './limits';
import { PersonalProfileAPIService } from './profile';
import { PersonalFl44ExternalPurchasesAPIService } from '@/services/backend/personal/external_purchases';

export default {
    fl44: new PersonalFl44APIService(),
    fl44ExternalPurchases: new PersonalFl44ExternalPurchasesAPIService(),
    fl44PurchaseReports: new PersonalFl44PurchaseReportsAPIService(),
    fl223: new PersonalFl223APIService(),
    commercial: new PersonalCommercialAPIService(),
    addresses: new PersonalAddressesAPIService(),
    limits: new PersonalLimitsAPIService(),
    profile: new PersonalProfileAPIService()
};
