'use strict';

import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';

type TSelfPOJO = Pick<KBKDictionaryFilteredRequestVO, 'year' | 'order_type_id' | 'purchase_id' | 'id'>;

export interface IFilterPresets {
    year: number;
    purchase_type_id: number;
    purchase_id: number;
    item_id?: number;
}

export default class KBKDictionaryFilteredRequestVO extends BaseValueObject {
    page: number | null;
    year: number | null;
    order_type_id: number | null;
    purchase_id: number | null;
    type: string | null;
    id: number | null;

    filter: {
        kbk: string | null;
        funds_type: string | null;
        subkosgu_code: string | null;
    };

    constructor(data?: TSelfPOJO) {
        super();

        this.page = 1;
        this.year = data?.year ?? null;
        this.order_type_id = data?.order_type_id ?? null;
        this.purchase_id = data?.purchase_id ?? null;
        this.type = data?.purchase_id ? 'fl44' : null;
        this.id = data?.id ?? null;
        this.filter = {
            kbk: null,
            funds_type: null,
            subkosgu_code: null
        };
    }

    public static get nullFilter(): KBKDictionaryFilteredRequestVO['filter'] {
        return new KBKDictionaryFilteredRequestVO().filter;
    }

    public static fromFiliterPresets(presets: IFilterPresets): KBKDictionaryFilteredRequestVO {
        const pojo: TSelfPOJO = {
            year: presets.year,
            order_type_id: presets.purchase_type_id,
            purchase_id: presets.purchase_id,
            id: presets.item_id as number
        };

        return new KBKDictionaryFilteredRequestVO(pojo);
    }

    public toQueryString(): string {
        const { filter, ...kept } = this;
        const flat = { ...kept, ...this.filter };
        return super.toQueryString(flat);
    }
}
