export default {
    methods: {
        debounce(method, timer = 400) {
            if (this.$_debounceTimer !== null) {
                clearTimeout(this.$_debounceTimer);
            }
            this.$_debounceTimer = setTimeout(() => {
                method();
            }, timer);
        }
    },
    created() {
        this.$_debounceTimer = null;
    }
};
