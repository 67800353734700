<template>
    <div id="atmo-customer-info" class="atmo-hash-jump-point atmo-content-group">
        <h3>Информация о заказчике</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Наименование:</dt>
                <dd><a v-bind:href="`/organizations/${this.$props.purchaseitem.customer.id}`" v-text="this.$props.purchaseitem.customer.title" target="_blank"></a></dd>
            </li>
            <li>
                <dt>ИНН:</dt>
                <dd v-text="this.$props.purchaseitem.customer.inn" class="atmo-placeholder-animated"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.customer.kpp">
                <dt>КПП:</dt>
                <dd v-text="this.$props.purchaseitem.customer.kpp" class="atmo-placeholder-animated"></dd>
            </li>
            <li>
                <dt>Адрес:</dt>
                <dd v-text="this.$props.purchaseitem.customer.address" class="atmo-placeholder-animated"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.additional_contact_info">
                <dt>Дополнительная контактная информация:</dt>
                <dd v-text="this.$props.purchaseitem.additional_contact_info" class="atmo-placeholder-animated"></dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        purchaseitem: { required: true, type: Object }
    }
};
</script>
