var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        description: _vm.description,
        label: _vm.label + ":",
        "label-cols-lg": _vm.labelColsLg,
        "label-cols-sm": _vm.labelColsSm,
        "label-for": _vm.id,
        state: _vm.state,
        "label-class": "fs-14 grey-color fw-700 align-items-baseline",
      },
    },
    [
      _c("date-time-picker", {
        class: _vm.stateClass,
        attrs: {
          id: _vm.id,
          disabled: _vm.disabled,
          label: _vm.placeholder,
          "max-date": _vm.maxDate,
          "min-date": _vm.minDate,
          "no-button-now": _vm.withoutNow,
          "no-label": !!_vm.value,
          "only-date": true,
          autoClose: "",
          buttonNowTranslation: "Сегодня",
          format: "YYYY-MM-DD",
          formatted: "DD.MM.YYYY",
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
      !_vm.state
        ? [
            _c("b-form-invalid-feedback", { attrs: { "force-show": "" } }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }