'use strict';

import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';

export type TSelfPOJO = SRDA31s1p1SupportingDocumentVO;

export default class SRDA31s1p1SupportingDocumentVO {
    id: number | null;
    description: string | null;
    attachment: FileAttachmentVO | null;

    constructor(data?: TSelfPOJO) {
        this.id = data?.id ?? null;
        this.description = data?.description ?? null;
        this.attachment = data?.attachment ? FileAttachmentVO.fromPOJO(data.attachment) : null;
    }
}
