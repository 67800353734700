'use strict';

export default async function actWithLoadingSpinner(evt: Event, action: Function) {
    const target = evt.target as HTMLElement;
    const button = target && target.tagName !== 'BUTTON' ? target.closest('BUTTON') : target;

    button && button.setAttribute('disabled', '');
    const response = await action();
    button && button.removeAttribute('disabled');
    return response;
}
