<template>
    <div id="atmo-supply-terms" class="atmo-hash-jump-point atmo-content-group" v-if="this.$props.purchaseitem.delivery_addresses.length > 0 || this.$props.purchaseitem.delivery_schedule">
        <h3>Условия поставки</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li v-if="this.$props.purchaseitem.delivery_addresses.length > 0">
                <dt>Доставка товаров или выполнение работ:</dt>
                <dd>
                    <ul class="atmo-list">
                        <li v-for="(address, index) in this.$props.purchaseitem.delivery_addresses" v-bind:key="index">
                            <b v-text="address"></b>
                        </li>
                    </ul>
                </dd>
            </li>
            <li v-if="this.$props.purchaseitem.delivery_schedule">
                <dt>График поставки товаров (выполнения работ, оказания услуг):</dt>
                <dd v-text="this.$props.purchaseitem.delivery_schedule"></dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        purchaseitem: { required: true, type: Object }
    }
};
</script>
