<template>
    <b-form-group :label-cols-sm="label ? 4 : 0" :label-cols-lg="label ? 3 : 0" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <template v-if="label" #label>
            {{ label }}:
            <div v-if="!!hint" class="atmo-help">
                &nbsp;<i class="atmo-icon-question-mark"></i><span class="atmo-help__text">{{ hint }}</span>
            </div>
        </template>
        <b-form-input ref="address" type="text" :id="id" :state="state" v-model="text" class="fs-14" placeholder="Начните вводить" />
        <template v-if="!state">
            <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
        <template v-if="description">
            <b-form-text class="desc-text">
                {{ description }}
            </b-form-text>
        </template>
    </b-form-group>
</template>

<script>
import $ from 'jquery';
import 'suggestions-jquery';

export default {
    name: 'form-row-edit-address-wo-coordinates',
    props: {
        value: {
            type: String,
            default: ''
        },
        v: {
            type: Object,
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            address: '',
            text: '',
            options: {
                token: process.env.VUE_APP_DADATA_TOKEN,
                type: 'ADDRESS',
                scrollOnFocus: false,
                triggerSelectOnBlur: false,
                triggerSelectOnEnter: false,
                addon: 'none'
            }
        };
    },
    mounted() {
        this.text = this.value;
        this.initSuggestion();
    },
    beforeDestroy() {
        this.destroySuggestion();
    },
    watch: {
        value() {
            this.address = this.value;
        },
        address() {
            if (this.v) {
                this.v.$touch();
            }
            this.$emit('input', this.address);
        }
    },
    methods: {
        initSuggestion() {
            const options = Object.assign({}, this.options, {
                onSelect: (suggestion) => this.onSelect(suggestion),
                onInvalidateSelection: (suggestion) => this.onInvalidateSelection(suggestion)
            });
            $(this.$refs.address.$el).suggestions(options);
        },
        destroySuggestion() {
            const plugin = $(this.$refs.address.$el).suggestions();
            plugin.dispose();
        },
        onSelect(suggestion) {
            this.address = suggestion.value;
            this.text = suggestion.value;
        },
        onInvalidateSelection() {
            this.address = '';
        }
    },
    computed: {
        id() {
            return 'form_row_edit_address_wo_coordinates_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Адрес не выбран';
            }
            return '';
        }
    }
};
</script>
