'use strict';

import notices from './notices/index';
import purchases from './purchases/index';
import contracts from './contracts/index';

const GroupFL223StoreModule = {
    namespaced: true,

    modules: {
        notices,
        purchases,
        contracts
    }
};

export default GroupFL223StoreModule;
