export default [
    {
        path: '/articles',
        component: () => import(/* webpackChunkName: "main" */ '@/components/articles/ArticlesList'),
        name: 'ArticlesList',
        meta: { name: 'Новости' }
    },
    {
        path: '/articles/:id',
        component: () => import(/* webpackChunkName: "main" */ '@/components/articles/Article'),
        name: 'Article',
        meta: { name: 'Новость' }
    }
];
