<template>
    <b-icon icon="x" style="width: 24px; height: 24px" class="btn btn-primary p-0" aria-hidden="true" />
</template>

<script>
export default {
    name: 'button-close-modal'
};
</script>
<style>
.modal-header .close {
    opacity: 1 !important;
}
</style>

<style scoped>
.btn-primary {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
    opacity: 1;
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(100%);
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:active {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
    filter: brightness(85%);
}
.btn-primary:focus {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
}
.btn-primary:hover {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(85%);
}
</style>
