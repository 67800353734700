var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          staticClass: "text-left",
          attrs: {
            label: "КриптоПро ЭЦП Browser plug-in не найден",
            "label-class": "fs-22 text-danger",
          },
        },
        [
          _c("div", { staticClass: "error" }, [
            _c("p", { staticClass: "fs-20 fw-600 grey-color" }, [
              _vm._v("Возможные причины ошибки:"),
            ]),
            _c("ul", { staticClass: "error-list" }, [
              _c("li", { staticClass: "fs-16 grey-color fw-400 my-2" }, [
                _vm._v("— Не установлен КриптоПро ЭЦП Browser plug-in"),
              ]),
              _c("li", { staticClass: "fs-16 grey-color fw-400 my-2" }, [
                _vm._v("— Не установлен программный комплекс КриптоПро CSP"),
              ]),
              _c("li", { staticClass: "fs-16 grey-color fw-400 my-2" }, [
                _vm._v(
                  "— КриптоПро ЭЦП Browser plug-in не активен в настройках расширений Вашего браузера"
                ),
              ]),
            ]),
            _c("p", { staticClass: "fs-18 fw-600 grey-color" }, [
              _c(
                "a",
                {
                  staticClass: "fs-20 fw-600 green-color",
                  attrs: {
                    href: "https://www.cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html",
                  },
                },
                [_vm._v("Проверить работу КриптоПро и плагина")]
              ),
            ]),
            _c("p", { staticClass: "fs-18 fw-600 grey-color" }, [
              _c(
                "a",
                {
                  staticClass: "fs-20 fw-600 green-color",
                  attrs: {
                    href: "https://info.zakupschik.ru/209--oshibka-plagin-kriptopro-ne-najden",
                  },
                },
                [_vm._v("Подробнее об ошибке и её устранении")]
              ),
            ]),
            _c("p", { staticClass: "fs-20 fw-600 grey-color" }, [
              _vm._v(
                'В случае возникновения дополнительных вопросов рекомендуем обратиться в техническую поддержку (Кнопка "Поддержка" в правом нижнем углу).'
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }