<template>
    <div class="atmo-overlay-container">
        <SupplierDepositReplenishPopupForm.view />
        <SignatureViewPopup.view />
        <DeliverablesSelectorPopup.view />
        <SavePurchasePopup.view />
        <SignContractPopup.view />
        <ConfirmContractFulfillmentAttachmentRemovePopup.view />
        <SignContractDisputeProtocolPopup.view />
        <ConfirmContractAnnexDeliverableDuplicateRemovePopup.view />
        <SignContractAnnexPopup.view />
        <SignContractAnnexDisputePopup.view />
        <KBKLimitSelectorPopup.view />
        <ConfirmProtocolSummarizationPopup.view />
        <DeclineProposalPopup.view />
        <GlobalLoadingSpinner.view />
        <SignOrganizationReliabilityReportDownloadPopup.view />
    </div>
</template>

<script>
import SupplierDepositReplenishPopupForm from './popups/SupplierDepositReplenishPopupForm.view.vue';
import SignatureViewPopup from './popups/signature/SignatureViewPopup.view.vue';
import DeliverablesSelectorPopup from './popups/deliverables/DeliverablesSelectorPopup.view.vue';
import SavePurchasePopup from './popups/purchases/save/SavePurchasePopup.view.vue';
import SignContractPopup from './popups/purchases/contract/sign/SignContractPopup.view.vue';
import ConfirmContractFulfillmentAttachmentRemovePopup from './popups/purchases/contract/attachments/fulfillment/ConfirmContractFulfillmentAttachmentRemovePopup.view.vue';
import SignContractDisputeProtocolPopup from './popups/purchases/contract/disputes/protocol/sign/SignContractDisputeProtocolPopup.view.vue';
import ConfirmContractAnnexDeliverableDuplicateRemovePopup from './popups/purchases/contract/annex/ConfirmContractAnnexDeliverableDuplicateRemovePopup.view.vue';
import SignContractAnnexPopupView from './popups/purchases/contract/annex/sign/SignContractAnnexPopup.view.vue';
import SignContractAnnexDisputePopupView from './popups/purchases/contract/annex/dispute/sign/SignContractAnnexDisputePopup.view.vue';
import KBKLimitSelectorPopupView from './popups/dictionaries/limits/KBKLimitSelectorPopup.view.vue';
import ConfirmProtocolSummarizationPopupView from './popups/purchases/protocol/ConfirmProtocolSummarizationPopup.view.vue';
import DeclineProposalPopupView from './popups/purchases/protocol/DeclineProposalPopup.view.vue';
import GlobalLoadingSpinnerView from './popups/common/GlobalLoadingSpinner.view.vue';
import SignOrganizationReliabilityReportDownloadPopupView from './popups/misc/SignOrganizationReliabilityReportDownloadPopup.view.vue';

export default {
    components: {
        'SupplierDepositReplenishPopupForm.view': SupplierDepositReplenishPopupForm,
        'SignatureViewPopup.view': SignatureViewPopup,
        'DeliverablesSelectorPopup.view': DeliverablesSelectorPopup,
        'SavePurchasePopup.view': SavePurchasePopup,
        'SignContractPopup.view': SignContractPopup,
        'ConfirmContractFulfillmentAttachmentRemovePopup.view': ConfirmContractFulfillmentAttachmentRemovePopup,
        'SignContractDisputeProtocolPopup.view': SignContractDisputeProtocolPopup,
        'ConfirmContractAnnexDeliverableDuplicateRemovePopup.view': ConfirmContractAnnexDeliverableDuplicateRemovePopup,
        'SignContractAnnexPopup.view': SignContractAnnexPopupView,
        'SignContractAnnexDisputePopup.view': SignContractAnnexDisputePopupView,
        'KBKLimitSelectorPopup.view': KBKLimitSelectorPopupView,
        'ConfirmProtocolSummarizationPopup.view': ConfirmProtocolSummarizationPopupView,
        'DeclineProposalPopup.view': DeclineProposalPopupView,
        'GlobalLoadingSpinner.view': GlobalLoadingSpinnerView,
        'SignOrganizationReliabilityReportDownloadPopup.view': SignOrganizationReliabilityReportDownloadPopupView
    }
};
</script>
