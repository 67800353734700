var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "atmo-tab-content atmo-content-group" },
    [
      _c("h6", [_vm._v("Организация")]),
      _c("OrganizationInfo.view", {
        attrs: {
          organization:
            this.controller.viewmodelDataService.viewables.organization,
        },
      }),
      _c("p", { staticClass: "atmo-error" }, [
        _vm._v("Стоимость отчета "),
        _c("b", {
          domProps: {
            textContent: _vm._s(
              this.controller.viewmodelDataService.viewables.financials
                .report_price
            ),
          },
        }),
        _vm._v(" руб."),
        _c("br"),
        _vm._v(
          "Эта сумма будет списана с лицевого счета Вашей организации при успешном скачивании отчета."
        ),
      ]),
      this.controller.viewmodelDataService.editable.has_to_email_report
        ? _c("p", [
            _vm._v("Отчет будет дополнительно отправлен на email: "),
            _c("b", {
              domProps: {
                textContent: _vm._s(
                  this.controller.viewmodelDataService.editable.email
                ),
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }