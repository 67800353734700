var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-disputes" },
    },
    [
      _c("h5", [_vm._v("Разногласия по контракту")]),
      _c("DisputesTable.view", {
        attrs: { pivc: _vm.$props.pivc, disputes: _vm.$props.disputes },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }