var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      class: { "mb-0": _vm.compact },
      attrs: {
        "label-for": _vm.id,
        state: _vm.state,
        "label-class": "fs-14 grey-color fw-700 align-items-baseline",
        "label-cols-lg": "3",
        "label-cols-sm": "4",
      },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function () {
            return [
              _vm._v(" " + _vm._s(_vm.label) + ": "),
              !!_vm.hint
                ? _c("span", { staticClass: "atmo-help" }, [
                    _vm._v("  "),
                    _c("i", { staticClass: "atmo-icon-question-mark" }),
                    _c("span", { staticClass: "atmo-help__text" }, [
                      _vm._v(_vm._s(_vm.hint)),
                    ]),
                  ])
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("b-form-input", {
        staticClass: "fs-14 fre-text",
        attrs: {
          id: _vm.id,
          autocomplete: _vm.autocomplete,
          disabled: _vm.disabled,
          maxlength: _vm.maxLength,
          placeholder: _vm.placeholder,
          state: _vm.state,
          type: _vm.type,
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _vm.description
        ? [
            _c("b-form-text", { staticClass: "desc-text" }, [
              _vm._v(" " + _vm._s(_vm.description) + " "),
            ]),
          ]
        : _vm._e(),
      !_vm.state
        ? [_c("b-form-invalid-feedback", [_vm._v(_vm._s(_vm.errorMessage))])]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }