'use strict';

import range from 'lodash.range';

import purchaseStatusRawDictionaryData from './data/statuses.json';
import durationsRawDictionaryData from './data/durations.json';
import urgencyReasonsRawDictionaryData from './data/urgency-reasons.json';

import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import ExtendedDictionaryCollection from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionary.collection';
import StatusDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StatusDictionaryItem.valueobject';

export default class State {
    statuses: null | StatusDictionaryItem[];
    menustatuses: null | StatusDictionaryItem[];
    contractstatuses: null | StatusDictionaryItem[];
    types: null | ExtendedDictionaryCollection<unknown>;
    deliverablesgroups: StandardDictionaryItem[];
    municipalities: StandardDictionaryItem[];
    limityears: StandardDictionaryItem[];
    durations: StandardDictionaryItem[];
    urgencyreasons: StandardDictionaryItem[];

    constructor() {
        this.statuses = purchaseStatusRawDictionaryData.map((purchaseStatusRawDataItem: StatusDictionaryItem) => {
            return Object.freeze(
                new StatusDictionaryItem({
                    id: purchaseStatusRawDataItem.id,
                    title: purchaseStatusRawDataItem.title,
                    code: purchaseStatusRawDataItem.code,
                    is_active: true
                })
            );
        });

        this.menustatuses = this.statuses.filter((item: StatusDictionaryItem) => {
            return !(item.code! as string).includes('contract');
        });

        this.contractstatuses = this.statuses.filter((item: StatusDictionaryItem) => {
            return (item.code! as string).includes('contract');
        });

        this.types = null;

        this.durations = durationsRawDictionaryData.map((rawItem: StandardDictionaryItem) => {
            return Object.freeze(new ExtendedDictionaryItem(rawItem));
        });

        this.urgencyreasons = urgencyReasonsRawDictionaryData.map((rawItem: StandardDictionaryItem) => {
            return Object.freeze(new StandardDictionaryItem(rawItem));
        });

        this.limityears = range(2019, new Date().getFullYear() + 2).map((year: number, index: number) => {
            const rawItem = { id: index, title: year.toString(), value: year, is_active: true };
            return Object.freeze(new ExtendedDictionaryItem(rawItem));
        });

        this.deliverablesgroups = [];

        this.municipalities = [];
    }
}
