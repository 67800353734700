var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      class: _vm.compact ? "mb-0" : "",
      attrs: {
        id: _vm.id,
        "label-cols-sm": _vm.labelColsSm,
        "label-cols-lg": _vm.labelColsLg,
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "fs-14 col-form-label d-inline-block",
          staticStyle: { color: "var(--green-color) !important" },
          attrs: {
            href: _vm.$getAttachmentDownloadLink(_vm.value.id),
            target: "_blank",
          },
        },
        [_vm._v(_vm._s(_vm.value.name))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }