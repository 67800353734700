'use strict';

import range from 'lodash.range';

export default class PaginationHelpers {
    public static allPages(totalPagesNo: number): number[] {
        return Array.from({ length: totalPagesNo }, (value, index) => index + 1);
    }

    /**
     * REFACTOR: Generally the method should not return undefined. But I do not see the
     * quick and satisfying solution to refactor the cases to avoid undefined return
     * decalred. Maybe refactror in the future.
     */
    public static fourToThreePages(currentPage: number, lastPage: number): undefined | number[] {
        if (currentPage < 4) {
            return PaginationHelpers.allPages(lastPage > 4 ? 4 : lastPage);
        }

        if (currentPage >= 4 && currentPage <= lastPage - 3) {
            const start = currentPage - 1;
            const end = currentPage < lastPage ? currentPage + 1 : lastPage;
            return range(start, end + 1);
        }

        if (currentPage > lastPage - 3) {
            const start = lastPage - 3;
            const end = lastPage;
            return range(start, end + 1);
        }
    }
}
