var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$props.purchaseitem
    ? _c("section", { staticClass: "atmo-content-group" }, [
        _c("h6", [_vm._v("Условия закупки")]),
        _c(
          "ul",
          {
            staticClass: "atmo-content-block-2-columns atmo-no-item-separator",
          },
          [
            _c("li", [
              _c("dt", [_vm._v("Предмет закупки:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.purchaseitem.description),
                },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Объект закупки:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.purchaseitem.purchase_object),
                },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Тип закупки:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.purchaseitem.type_title),
                },
              }),
            ]),
            this.$props.purchaseitem.with_advance
              ? _c("li", [
                  _c("dt", [_vm._v("Размер аванса, %:")]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(
                        this.$props.purchaseitem.advance_percentage
                      ),
                    },
                  }),
                ])
              : _vm._e(),
            _c("li", [
              _c("dt", [_vm._v("Дата и время размещения закупки:")]),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatdate")(
                      this.$props.purchaseitem.publication_date,
                      "withSeconds"
                    )
                  )
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Дата и время окончания подачи предложений:")]),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatdate")(
                      this.$props.purchaseitem.proposal_accept_end_date,
                      "withSeconds"
                    )
                  )
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Планируемая дата заключения контракта:")]),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatdate")(
                      this.$props.purchaseitem.planned_contract_signature_date,
                      "onlyDate"
                    )
                  )
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Планируемая дата исполнения контракта:")]),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatdate")(
                      this.$props.purchaseitem.planned_fulfillment_date,
                      "onlyDate"
                    )
                  )
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Начальная цена контракта, руб.:")]),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatnumber")(
                      this.$props.purchaseitem.starting_price
                    )
                  )
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [
                _vm._v(
                  "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации:"
                ),
              ]),
              this.$props.purchaseitem.unilateral_contract_termination_info
                ? _c("dd", {
                    domProps: {
                      textContent: _vm._s(
                        this.$props.purchaseitem
                          .unilateral_contract_termination_info
                      ),
                    },
                  })
                : _c("dd", [_vm._v("Нет")]),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Приложение заказчика к объявлению:")]),
              _c("dd", [
                this.$props.purchaseitem.attachments &&
                this.$props.purchaseitem.attachments.length > 0
                  ? _c(
                      "ul",
                      { staticClass: "atmo-list" },
                      _vm._l(
                        this.$props.purchaseitem.attachments,
                        function (attachment, index) {
                          return _c("li", { key: index }, [
                            _c("button", {
                              staticClass: "atmo-button-link",
                              domProps: {
                                textContent: _vm._s(attachment.name),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$props.handlers.downloadFileAttachment(
                                    attachment
                                  )
                                },
                              },
                            }),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }