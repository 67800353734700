'use strict';

import isPrimitiveEmpty from '@/rearchitected/lib/js/src/misc/isPrimitiveEmpty';
import SRDA31s1p1SupportingDocumentVO, { TSelfPOJO as SDTSelfPOJO } from './SRDA31s1p1SupportingDocument.valueobject';

export type TSelfPOJO = Omit<SupplierRequirementDetailsA31s1p1VO, 'updateSupportingDocuments'>;

export default class SupplierRequirementDetailsA31s1p1VO {
    description: string | null;
    supporting_documents: SRDA31s1p1SupportingDocumentVO[];

    constructor(data?: TSelfPOJO) {
        // "supplier_reqs_a_text"
        this.description = data?.description ?? null;

        // "supplier_required_documents"
        this.supporting_documents = data?.supporting_documents ? this.supportingDocumentsFactory(data.supporting_documents) : [];
    }

    public updateSupportingDocuments(documents: SRDA31s1p1SupportingDocumentVO[]): void {
        this.supporting_documents = documents.map((document: SRDA31s1p1SupportingDocumentVO) => {
            return new SRDA31s1p1SupportingDocumentVO(document);
        });
    }

    public isValid(): boolean {
        return (
            !isPrimitiveEmpty(this.description) &&
            this.supporting_documents.length > 0 &&
            this.supporting_documents.every((document: SRDA31s1p1SupportingDocumentVO) => {
                return !isPrimitiveEmpty(document.description);
            })
        );
    }

    private supportingDocumentsFactory(array: SDTSelfPOJO[]): SRDA31s1p1SupportingDocumentVO[] {
        return array.map((pojo: SDTSelfPOJO) => {
            return new SRDA31s1p1SupportingDocumentVO(pojo);
        });
    }
}
