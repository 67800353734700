'use strict';

import get from 'lodash.get';
import has from 'lodash.has';

import ConfigUtils from './ConfigUtils';
import { IATMOAPIConfig } from '../../ports/adapters/http/outgoing/atmo/ATMOAPIFacade';
import ThemeConfig from './ThemeConfig';
import FeatureFlagsConfig from './FeatureFlagsConfig';
import { FeatureFlags } from '../abstractions/types/FeatureFlags';

let _instance: Config = {};

/**
 * Application config singleton.
 *
 * @usage new Config(), Congig.get(), Config.get('optional.top-level-key.or.dotted.keys.path');
 */
export default class Config {
    [index: string]: unknown;

    timezone?: { current: string; customer: string };
    api?: { config: IATMOAPIConfig };
    dadata?: { baseurl: string; token: string };
    theme?: { version: { core: string; full: string }; variables: Record<string, any>; features: FeatureFlags };

    constructor() {
        if (Object.keys(_instance).length > 0) {
            return _instance;
        }

        _instance = this;

        this.theme = {
            version: {
                core: ConfigUtils.getSafeEnv('PACKAGE_VERSION'),
                full: `${ConfigUtils.getSafeEnv('PACKAGE_VERSION')}${ThemeConfig.get('version', null) ? '.' + ThemeConfig.get('version') : ''}`
            },
            variables: ThemeConfig.get(),
            features: FeatureFlagsConfig.get()
        };

        this.timezone = {
            current: Intl.DateTimeFormat().resolvedOptions().timeZone,
            customer: ThemeConfig.get('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
        };

        this.api = {
            config: {
                legacy: { url: `${ConfigUtils.getSafeEnv('APP_API_BASEURL')}${ThemeConfig.get('api.legacy')}` },
                v1: { url: `${ConfigUtils.getSafeEnv('APP_API_BASEURL')}${ThemeConfig.get('api.v1')}` }
            }
        };

        this.dadata = {
            baseurl: ThemeConfig.get('global.dadata.baseurl'),
            token: ConfigUtils.getSafeEnv('VUE_APP_DADATA_TOKEN')
        };
    }

    /**
     * Universal getter to return either entire config or the property value by name.
     */
    public static get(path?: string): any {
        // Create config at first invocation.
        if (Object.keys(_instance).length < 1) {
            new Config();
        }

        if (path && !has(_instance, path)) {
            throw new Error(`SVTA Error: Application config variable "${path}" not found.`);
        }

        return path ? get(_instance, path) : _instance;
    }
}
