var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-content-group" }, [
    _c("h3", [_vm._v("Условия закупки")]),
    _c(
      "ul",
      { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
      [
        _c("li", { staticClass: "atmo-align-top" }, [
          _c("dt", [
            _vm._v(
              "Примечание к закупке (отображается только в ЛК Заказчика):"
            ),
          ]),
          _c("dd", { staticClass: "atmo-form__field atmo-inverse" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$props.inputs.note,
                  expression: "$props.inputs.note",
                },
              ],
              attrs: { rows: "5" },
              domProps: { value: _vm.$props.inputs.note },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.$props.inputs, "note", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        this.$props.controller.withoutLimits
          ? _c("li", { staticClass: "atmo-form__field atmo-checkbox" }, [
              _vm._m(0),
              _c("dd", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$props.inputs.without_limits,
                      expression: "$props.inputs.without_limits",
                    },
                  ],
                  staticClass: "atmo-icon-checkmark",
                  attrs: {
                    type: "checkbox",
                    name: "without_limits",
                    id: "without_limits",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.$props.inputs.without_limits)
                      ? _vm._i(_vm.$props.inputs.without_limits, null) > -1
                      : _vm.$props.inputs.without_limits,
                  },
                  on: {
                    click: function ($event) {
                      _vm.$props.inputs.specification.kbkLimits.items = []
                    },
                    change: function ($event) {
                      var $$a = _vm.$props.inputs.without_limits,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.$props.inputs,
                              "without_limits",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.$props.inputs,
                              "without_limits",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.$props.inputs, "without_limits", $$c)
                      }
                    },
                  },
                }),
              ]),
            ])
          : _vm._e(),
        !this.$props.inputs.without_limits
          ? _c("li", [
              _c("dt", [_vm._v("Год ПГ (Плана-графика):")]),
              _c(
                "dd",
                { staticClass: "atmo-form__field atmo-as-input" },
                [
                  _c("dropdown-select", {
                    attrs: {
                      initialid: this.$props.controller.initialLimitYearId,
                      list: this.$props.dictionaries.limityears,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ds) {
                            return [
                              _c("on-off", {
                                attrs: { options: { onBody: "intelligent" } },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (onoff) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "atmo-dropdown-select",
                                            },
                                            [
                                              _c("input", {
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value:
                                                    _vm.$props.inputs
                                                      .limit_year,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.$props.inputs.limit_year =
                                                      ds.getByIdFrom(
                                                        $event.target.value,
                                                        "value"
                                                      )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: { click: onoff.toggle },
                                                },
                                                [
                                                  onoff.ison
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "atmo-placeholder",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Выберите год ПГ (Плана-графика)"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  ds.hasSelected && !onoff.ison
                                                    ? _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            ds.text
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  !onoff.ison
                                                    ? _c("i", {
                                                        staticClass:
                                                          "atmo-icon-angle-down",
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "atmo-icon-angle-up",
                                                      }),
                                                ]
                                              ),
                                              _c(
                                                "ul",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: onoff.ison,
                                                      expression: "onoff.ison",
                                                    },
                                                  ],
                                                  staticClass: "atmo-dropdown",
                                                  on: { click: onoff.off },
                                                },
                                                _vm._l(
                                                  ds.list,
                                                  function (item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: item.id,
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return ds.select(
                                                              item.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.title
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3220873869
                    ),
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("li", [
          _c("dt", [_vm._v("Предмет закупки:")]),
          _c(
            "dd",
            {
              staticClass: "atmo-form__field atmo-as-input atmo-vue-select",
              class: {
                "atmo-has-errors": this.$props.errors.deliverable_group_id,
              },
            },
            [
              _c(
                "multiselect",
                {
                  attrs: {
                    options: this.$props.controller.isRevokedDraftEdit
                      ? _vm.alldeliverablesgroups
                      : _vm.activedeliverablesgroups,
                    disabled: this.$props.controller.isRevokedDraftEdit,
                    multiple: false,
                    label: "title",
                    "track-by": "id",
                    placeholder: "Выберите предмет закупки",
                    "deselect-label": "Отменить",
                    "select-label": "Выбрать",
                    "selected-label": "Выбрано",
                  },
                  on: {
                    select: this.$props.deliverablesgroupsselecthandler,
                    remove: this.$props.deliverablesgroupsremovehandler,
                  },
                  model: {
                    value: _vm.deliverableGroup,
                    callback: function ($$v) {
                      _vm.deliverableGroup = $$v
                    },
                    expression: "deliverableGroup",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [
                      _vm._v(
                        "Записей, соответствующих вашему запросу, не найдено."
                      ),
                    ]
                  ),
                ]
              ),
              this.$props.errors.deliverable_group_id
                ? _c("p", { staticClass: "atmo-error" }, [
                    _vm._v("Поле обязательно для заполнения"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("li", [
          _vm._m(1),
          _c(
            "dd",
            {
              staticClass: "atmo-form__field atmo-inverse",
              class: { "atmo-has-errors": this.$props.errors.purchase_object },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$props.inputs.purchase_object,
                    expression: "$props.inputs.purchase_object",
                  },
                ],
                domProps: { value: _vm.$props.inputs.purchase_object },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$props.inputs,
                      "purchase_object",
                      $event.target.value
                    )
                  },
                },
              }),
              this.$props.errors.purchase_object
                ? _c(
                    "p",
                    {
                      staticClass: "atmo-error",
                      staticStyle: { display: "block" },
                    },
                    [_vm._v("Поле обязательно для заполнения")]
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _c("li", [
          _c("dt", [_vm._v("Тип закупки:")]),
          _c(
            "dd",
            { staticClass: "atmo-form__field atmo-as-input" },
            [
              _c("dropdown-select", {
                attrs: {
                  initialid: this.$props.inputs.type_id,
                  list: _vm.availableOrderTypes,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ds) {
                      return [
                        _c("on-off", {
                          attrs: { options: { onBody: "intelligent" } },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (onoff) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "atmo-dropdown-select" },
                                      [
                                        _c("input", {
                                          attrs: { type: "number" },
                                          domProps: {
                                            value: _vm.$props.inputs.type_id,
                                          },
                                          on: {
                                            input:
                                              _vm.$props.controller
                                                .setOrderType,
                                          },
                                        }),
                                        _c(
                                          "button",
                                          {
                                            attrs: { type: "button" },
                                            on: { click: onoff.toggle },
                                          },
                                          [
                                            onoff.ison
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "atmo-placeholder",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Выберите тип закупки"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            ds.hasSelected && !onoff.ison
                                              ? _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(ds.text),
                                                  },
                                                })
                                              : _vm._e(),
                                            !onoff.ison
                                              ? _c("i", {
                                                  staticClass:
                                                    "atmo-icon-angle-down",
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "atmo-icon-angle-up",
                                                }),
                                          ]
                                        ),
                                        _c(
                                          "ul",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: onoff.ison,
                                                expression: "onoff.ison",
                                              },
                                            ],
                                            staticClass: "atmo-dropdown",
                                            on: { click: onoff.off },
                                          },
                                          _vm._l(ds.list, function (item) {
                                            return _c(
                                              "li",
                                              {
                                                key: item.id,
                                                on: {
                                                  click: function ($event) {
                                                    return ds.select(item.id)
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.title
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("li", [
          _c("dt", [_vm._v("Продолжительность закупочной сессии:")]),
          _c(
            "dd",
            { staticClass: "atmo-form__field atmo-as-input" },
            [
              _c("dropdown-select", {
                attrs: {
                  initialid: this.$props.inputs.duration_id,
                  list: this.$props.dictionaries.durations,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ds) {
                      return [
                        _c("on-off", {
                          attrs: { options: { onBody: "intelligent" } },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (onoff) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "atmo-dropdown-select" },
                                      [
                                        _c("input", {
                                          attrs: { type: "number" },
                                          domProps: {
                                            value:
                                              _vm.$props.inputs.duration_id,
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.$props.inputs.duration_id =
                                                ds.toNullableInt(
                                                  $event.target.value
                                                )
                                            },
                                          },
                                        }),
                                        _c(
                                          "button",
                                          {
                                            attrs: {
                                              type: "button",
                                              disabled:
                                                _vm.$props.controller
                                                  .isRevokedDraftEdit,
                                            },
                                            on: { click: onoff.toggle },
                                          },
                                          [
                                            onoff.ison
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "atmo-placeholder",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Выберите продолжительность закупочной сессии"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            ds.hasSelected && !onoff.ison
                                              ? _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(ds.text),
                                                  },
                                                })
                                              : _vm._e(),
                                            !onoff.ison
                                              ? _c("i", {
                                                  staticClass:
                                                    "atmo-icon-angle-down",
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "atmo-icon-angle-up",
                                                }),
                                          ]
                                        ),
                                        _c(
                                          "ul",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: onoff.ison,
                                                expression: "onoff.ison",
                                              },
                                            ],
                                            staticClass: "atmo-dropdown",
                                          },
                                          _vm._l(ds.list, function (item) {
                                            return _c(
                                              "li",
                                              {
                                                key: item.id,
                                                on: {
                                                  click: function ($event) {
                                                    ds.select(item.id),
                                                      onoff.off()
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.title
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        this.$props.controller.isUrgentPurchase
          ? _c("li", [
              _c("dt", [
                _vm._v("Причина проведения срочной закупочной сессии:"),
              ]),
              _c(
                "dd",
                { staticClass: "atmo-form__field atmo-as-input" },
                [
                  _c("dropdown-select", {
                    attrs: {
                      initialid: this.$props.inputs.urgency_reason.id,
                      list: this.$props.dictionaries.urgencyreasons,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ds) {
                            return [
                              _c("on-off", {
                                attrs: { options: { onBody: "intelligent" } },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (onoff) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "atmo-dropdown-select",
                                            },
                                            [
                                              _c("input", {
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value:
                                                    _vm.$props.inputs
                                                      .urgency_reason.id,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.$props.inputs.urgency_reason.id =
                                                      ds.toNullableInt(
                                                        $event.target.value
                                                      )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "button",
                                                {
                                                  attrs: { type: "button" },
                                                  on: { click: onoff.toggle },
                                                },
                                                [
                                                  !ds.hasSelected || onoff.ison
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "atmo-placeholder",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Выберите причину проведения срочной закупочной сессии"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  ds.hasSelected && !onoff.ison
                                                    ? _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            ds.text
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  !onoff.ison
                                                    ? _c("i", {
                                                        staticClass:
                                                          "atmo-icon-angle-down",
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "atmo-icon-angle-up",
                                                      }),
                                                ]
                                              ),
                                              _c(
                                                "ul",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: onoff.ison,
                                                      expression: "onoff.ison",
                                                    },
                                                  ],
                                                  staticClass: "atmo-dropdown",
                                                  on: { click: onoff.off },
                                                },
                                                _vm._l(
                                                  ds.list,
                                                  function (item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: item.id,
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return ds.select(
                                                              item.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.title
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2809459908
                    ),
                  }),
                  this.$props.errors.urgency_reason_id
                    ? _c("p", { staticClass: "atmo-error" }, [
                        _vm._v("Поле обязательно для заполнения"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        this.$props.controller.isUrgentPurchase
          ? _c("li", [
              _c("dt", [
                _vm._v(
                  "Документ обоснования проведения срочной закупочной сессии:"
                ),
              ]),
              _c("dd", { staticClass: "atmo-form__field" }, [
                _c("table", { staticClass: "atmo-table-slim" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c("editable-file-attachments-list-controller", {
                            attrs: {
                              initiallist:
                                _vm.$props.inputs.urgency_reason.attachment &&
                                _vm.$props.inputs.urgency_reason.attachment
                                  .file_id
                                  ? [
                                      _vm.$props.inputs.urgency_reason
                                        .attachment,
                                    ]
                                  : [],
                              isrequired: true,
                              origin: "none",
                              maxfilesallowed: 1,
                            },
                            on: {
                              "fileattachment:changed":
                                _vm.$props.controller
                                  .updateUrgencyReasonFileAttachment,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (efalc) {
                                    return [
                                      _c("table", [
                                        _c("tbody", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "atmo-left" },
                                              [
                                                !efalc.isempty
                                                  ? _c("span", [
                                                      _c("button", {
                                                        staticClass:
                                                          "atmo-button-link",
                                                        attrs: {
                                                          title: "Скачать файл",
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            efalc.list[0].name
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return efalc.downloadFileAttachment(
                                                              efalc.list[0]
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("Прикрепите файл"),
                                                    ]),
                                              ]
                                            ),
                                            !_vm.$props.controller
                                              .isRevokedDraftEdit
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "atmo-tc-button-icon atmo-center",
                                                    attrs: {
                                                      title: "Прикрепить файл",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "atmo-button-icon",
                                                        attrs: {
                                                          disabled:
                                                            efalc.islistfull,
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _c("i", {
                                                            staticClass:
                                                              "atmo-icon-attachment",
                                                          }),
                                                        ]),
                                                        _c("input", {
                                                          attrs: {
                                                            type: "file",
                                                            accept:
                                                              ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx",
                                                          },
                                                          on: {
                                                            change: efalc.add,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.$props.controller
                                              .isRevokedDraftEdit
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "atmo-tc-button-icon atmo-center",
                                                    attrs: {
                                                      title: "Удалить файл",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "atmo-button-icon",
                                                        attrs: {
                                                          disabled:
                                                            efalc.isempty,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return efalc.remove(
                                                              efalc.list[0]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "atmo-icon-trash",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3595898853
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("li", { staticClass: "atmo-form__field atmo-checkbox" }, [
          _vm._m(2),
          _c("dd", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$props.inputs.with_advance,
                  expression: "$props.inputs.with_advance",
                },
              ],
              staticClass: "atmo-icon-checkmark",
              attrs: {
                type: "checkbox",
                name: "with_advance",
                id: "with_advance",
              },
              domProps: {
                checked: Array.isArray(_vm.$props.inputs.with_advance)
                  ? _vm._i(_vm.$props.inputs.with_advance, null) > -1
                  : _vm.$props.inputs.with_advance,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.$props.inputs.with_advance,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.$props.inputs,
                          "with_advance",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.$props.inputs,
                          "with_advance",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.$props.inputs, "with_advance", $$c)
                  }
                },
              },
            }),
          ]),
        ]),
        _vm.$props.inputs.with_advance
          ? _c("li", [
              _vm._m(3),
              _c(
                "dd",
                {
                  staticClass: "atmo-form__field atmo-inverse",
                  class: {
                    "atmo-has-errors": this.$props.errors.advance_percentage,
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.$props.inputs.advance_percentage,
                        expression: "$props.inputs.advance_percentage",
                        modifiers: { number: true },
                      },
                    ],
                    staticStyle: { "text-align": "left" },
                    attrs: {
                      type: "number",
                      min: "1",
                      max: "30",
                      step: "1",
                      onkeypress:
                        "return event.charCode >= 48 && event.charCode <= 57",
                      name: "advance_percentage",
                      id: "advance_percentage",
                    },
                    domProps: { value: _vm.$props.inputs.advance_percentage },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$props.inputs,
                          "advance_percentage",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  this.$props.errors.advance_percentage
                    ? _c(
                        "p",
                        {
                          staticClass: "atmo-error",
                          staticStyle: { display: "block" },
                        },
                        [
                          _vm.$props.inputs.advance_percentage === null ||
                          _vm.$props.inputs.advance_percentage === ""
                            ? [_vm._v("Поле обязательно для заполнения")]
                            : _vm.$props.inputs.advance_percentage < 1
                            ? [
                                _vm._v(
                                  "Значение должно быть больше или равно 1"
                                ),
                              ]
                            : _vm.$props.inputs.advance_percentage > 30
                            ? [_vm._v("Максимальное значение - 30")]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ])
          : _vm._e(),
        _c("li", [
          _vm._m(4),
          _c(
            "dd",
            {
              staticClass: "atmo-form__field atmo-as-input",
              class: {
                "atmo-has-errors":
                  this.$props.errors.planned_contract_signature_date,
              },
            },
            [
              _c("date-picker", {
                attrs: {
                  id: "purchase-contractSignatureDate",
                  position: "bottom",
                  label: "Выберите дату",
                  "button-now-translation": "Сегодня",
                  format: "YYYY-MM-DD",
                  formatted: "DD.MM.YYYY",
                  "min-date": new Date().toISOString(),
                  "no-label": true,
                  "auto-close": true,
                  "only-date": true,
                },
                model: {
                  value: _vm.$props.inputs.planned_contract_signature_date,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$props.inputs,
                      "planned_contract_signature_date",
                      $$v
                    )
                  },
                  expression: "$props.inputs.planned_contract_signature_date",
                },
              }),
              this.$props.errors.planned_contract_signature_date
                ? _c("p", { staticClass: "atmo-error" }, [
                    _vm._v("Поле обязательно для заполнения"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("li", [
          _vm._m(5),
          _c(
            "dd",
            {
              staticClass: "atmo-form__field atmo-as-input",
              class: {
                "atmo-has-errors":
                  this.$props.errors.planned_contract_fulfillment_date,
              },
            },
            [
              _c("date-picker", {
                attrs: {
                  id: "purchase-contractFulfillmentDate",
                  position: "bottom",
                  label: "Выберите дату",
                  "button-now-translation": "Сегодня",
                  format: "YYYY-MM-DD",
                  formatted: "DD.MM.YYYY",
                  "min-date": _vm.$props.inputs.planned_contract_signature_date,
                  disabled: !_vm.$props.inputs.planned_contract_signature_date,
                  "no-label": true,
                  "auto-close": true,
                  "only-date": true,
                },
                model: {
                  value: _vm.$props.inputs.planned_contract_fulfillment_date,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$props.inputs,
                      "planned_contract_fulfillment_date",
                      $$v
                    )
                  },
                  expression: "$props.inputs.planned_contract_fulfillment_date",
                },
              }),
              this.$props.errors.planned_contract_fulfillment_date
                ? _c("p", { staticClass: "atmo-error" }, [
                    _vm._v("Поле обязательно для заполнения"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("li", { staticClass: "atmo-align-top" }, [
          _c("dt", [
            _vm._v(
              "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации:"
            ),
          ]),
          _c("dd", { staticClass: "atmo-form__field atmo-inverse" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$props.inputs.unilateral_contract_termination_info,
                  expression:
                    "$props.inputs.unilateral_contract_termination_info",
                },
              ],
              attrs: { type: "text" },
              domProps: {
                value: _vm.$props.inputs.unilateral_contract_termination_info,
              },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.$props.inputs,
                    "unilateral_contract_termination_info",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "with_advance" } }, [
        _vm._v("Закупка без лимитов БО"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", { staticClass: "atmo-help" }, [
      _vm._v(" Объект закупки:  "),
      _c("i", { staticClass: "atmo-icon-question-mark" }),
      _c("span", { staticClass: "atmo-help__text" }, [
        _vm._v(
          "Укажите непосредственно товары, работы, услуги, поставка, выполнение, оказание которых будут являться предметом контракта."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "with_advance" } }, [_vm._v("Аванс")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "advance_percentage" } }, [
        _vm._v("Размер аванса, %"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", { staticClass: "atmo-help" }, [
      _vm._v(" Планируемая дата заключения договора:  "),
      _c("i", { staticClass: "atmo-icon-question-mark" }),
      _c("span", { staticClass: "atmo-help__text" }, [
        _vm._v("Не может быть меньше текущей даты."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", { staticClass: "atmo-help" }, [
      _vm._v(" Планируемая дата исполнения договора:  "),
      _c("i", { staticClass: "atmo-icon-question-mark" }),
      _c("span", { staticClass: "atmo-help__text" }, [
        _vm._v(
          "Дату можно выбрать только после выбора Планируемой даты заключения договора."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }