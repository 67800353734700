<template>
    <section class="atmo-content-group">
        <h6>Требования к поставщикам</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li v-if="$props.required.a31s1p1" class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt>
                    <label for="federal-law-a31s1p1">Подтверждаю, что соответствую требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:</label>
                </dt>
                <dd>
                    <input v-bind:disabled="isDataSigned" v-model="$props.confirmed.a31s1p1" required type="checkbox" name="federal-law-a31s1p1" id="federal-law-a31s1p1" class="atmo-icon-checkmark" />
                </dd>
            </li>

            <li v-if="$props.required.a31s1p1" class="atmo-wide-text">
                <dt>Следующими документами подтверждаю соответствие указанным требованиям:</dt>
            </li>

            <li v-if="$props.required.a31s1p1">
                <table class="atmo-table-slim atmo-table-fixed">
                    <tbody>
                        <tr v-for="(document, index) in $props.confirmed.a31s1p1_details.supporting_documents" v-bind:key="index">
                            <td style="width: 50%">{{ document.description }}:&nbsp;</td>
                            <td>
                                <editable-file-attachments-list-controller
                                    v-slot:default="efalc"
                                    v-bind:initiallist="document.attachment ? [document.attachment] : []"
                                    v-bind:isrequired="true"
                                    v-bind:origin="'requirements'"
                                    v-bind:maxfilesallowed="1"
                                    v-on:fileattachment:changed="$props.controller.updateSupplierRequirementsFileAttachments">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td class="atmo-left">
                                                    <span v-if="!efalc.isempty">
                                                        <button v-on:click="efalc.downloadFileAttachment(efalc.list[0])" class="atmo-button-link" v-text="efalc.list[0].name" title="Скачать файл"></button>
                                                    </span>
                                                    <span v-else class="atmo-error">Прикрепите файл</span>
                                                </td>
                                                <td class="atmo-tc-button-icon atmo-center" title="Прикрепить файл">
                                                    <label v-bind:disabled="efalc.islistfull || isDataSigned" class="atmo-button-icon">
                                                        <span><i class="atmo-icon-attachment"></i></span>
                                                        <input v-on:change="efalc.add($event, index)" type="file" accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx" />
                                                    </label>
                                                </td>
                                                <td class="atmo-tc-button-icon atmo-center" title="Удалить файл">
                                                    <button v-on:click="efalc.remove(efalc.list[0])" v-bind:disabled="efalc.isempty || isDataSigned" class="atmo-button-icon">
                                                        <i class="atmo-icon-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </editable-file-attachments-list-controller>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </li>

            <li v-if="$props.required.a31s1ps3_5ps7_11" class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt>
                    <label for="federal-law-a31s1ps3_5ps7_11">Подтверждаю, что соответствую требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:</label>
                </dt>
                <dd>
                    <input v-bind:disabled="isDataSigned" v-model="$props.confirmed.a31s1ps3_5ps7_11" required type="checkbox" name="federal-law-a31s1ps3_5ps7_11" id="federal-law-a31s1ps3_5ps7_11" class="atmo-icon-checkmark" />
                </dd>
            </li>
            <li v-if="$props.required.a31s1_1" class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt>
                    <label for="federal-law-a31s1_1">Подтверждаю, что соответствую требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:</label>
                </dt>
                <dd>
                    <input v-bind:disabled="isDataSigned" v-model="$props.confirmed.a31s1_1" required type="checkbox" name="federal-law-a31s1_1" id="federal-law-a31s1_1" class="atmo-icon-checkmark" />
                </dd>
            </li>

            <li v-if="$props.required.supplier_reqs_sonko" class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt>
                    <label for="requirement-sonko">Подтверждаю, что организация является социально-ориентированной некоммерческой организацией (СОНКО):</label>
                </dt>
                <dd>
                    <input v-bind:disabled="isDataSigned" v-model="$props.confirmed.supplier_reqs_sonko" required type="checkbox" name="requirement-sonko" id="requirement-sonko" class="atmo-icon-checkmark" />
                </dd>
            </li>

            <!-- Additional -->
            <li v-if="$props.required.additional.state.is_required" class="atmo-form__field atmo-checkbox atmo-wide-text">
                <dt><label for="requirement-additional">Подтверждаю, что соответствую дополнительным требованиям:</label></dt>
                <dd>
                    <input v-bind:disabled="isDataSigned" v-model="$props.confirmed.additional.state.is_confirmed_at_contract" required type="checkbox" name="requirement-additional" id="requirement-additional" class="atmo-icon-checkmark" />
                </dd>
            </li>

            <li v-if="$props.required.additional.state.is_required">
                <FileAttachmentSlimWidget.view v-bind:documents="$props.confirmed.additional.requirements" v-bind:update="$props.confirmed.additional.updateAttachment.bind($props.confirmed.additional)" v-bind:is_disabled="isDataSigned" />
            </li>
        </ul>
    </section>
</template>

<script>
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import FileAttachmentSlimWidgetView from './FileAttachmentSlimWidget.view.vue';

export default {
    components: {
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController,
        'FileAttachmentSlimWidget.view': FileAttachmentSlimWidgetView
    },
    props: {
        controller: { required: true, type: Object },
        required: { required: true, type: Object },
        confirmed: { required: true, type: Object }
    },
    computed: {
        isDataSigned: function () {
            return this.$props.controller.$data.isDataSigned;
        }
    }
};
</script>
