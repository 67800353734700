<template>
    <div class="atmo-content-group">
        <h3>Условия закупки</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li class="atmo-align-top">
                <dt>Примечание к закупке (отображается только в ЛК Заказчика):</dt>
                <dd class="atmo-form__field atmo-inverse">
                    <textarea v-model="$props.inputs.note" rows="5"></textarea>
                </dd>
            </li>
            <li v-if="this.$props.controller.withoutLimits" class="atmo-form__field atmo-checkbox">
                <dt><label for="with_advance">Закупка без лимитов БО</label></dt>
                <dd>
                    <input v-model="$props.inputs.without_limits" v-on:click="$props.inputs.specification.kbkLimits.items = []" type="checkbox" name="without_limits" id="without_limits" class="atmo-icon-checkmark" />
                </dd>
            </li>
            <li v-if="!this.$props.inputs.without_limits">
                <dt>Год ПГ (Плана-графика):</dt>
                <dd class="atmo-form__field atmo-as-input">
                    <dropdown-select v-slot:default="ds" v-bind:initialid="this.$props.controller.initialLimitYearId" v-bind:list="this.$props.dictionaries.limityears">
                        <on-off v-slot:default="onoff" v-bind:options="{ onBody: 'intelligent' }">
                            <div class="atmo-dropdown-select">
                                <input type="number" v-bind:value="$props.inputs.limit_year" v-on:input="$props.inputs.limit_year = ds.getByIdFrom($event.target.value, 'value')" />

                                <button type="button" v-on:click="onoff.toggle">
                                    <span v-if="onoff.ison" class="atmo-placeholder">Выберите год ПГ (Плана-графика)</span>
                                    <span v-if="ds.hasSelected && !onoff.ison" v-html="ds.text"></span>
                                    <i v-if="!onoff.ison" class="atmo-icon-angle-down"></i>
                                    <i v-else class="atmo-icon-angle-up"></i>
                                </button>
                                <ul v-show="onoff.ison" v-on:click="onoff.off" class="atmo-dropdown">
                                    <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id)">
                                        <span v-html="item.title"></span>
                                    </li>
                                </ul>
                            </div>
                        </on-off>
                    </dropdown-select>
                </dd>
            </li>
            <li>
                <dt>Предмет закупки:</dt>
                <dd v-bind:class="{ 'atmo-has-errors': this.$props.errors.deliverable_group_id }" class="atmo-form__field atmo-as-input atmo-vue-select">
                    <multiselect
                        v-model="deliverableGroup"
                        :options="this.$props.controller.isRevokedDraftEdit ? alldeliverablesgroups : activedeliverablesgroups"
                        v-bind:disabled="this.$props.controller.isRevokedDraftEdit"
                        v-bind:multiple="false"
                        v-on:select="this.$props.deliverablesgroupsselecthandler"
                        v-on:remove="this.$props.deliverablesgroupsremovehandler"
                        label="title"
                        track-by="id"
                        placeholder="Выберите предмет закупки"
                        deselect-label="Отменить"
                        select-label="Выбрать"
                        selected-label="Выбрано">
                        <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                    </multiselect>

                    <p v-if="this.$props.errors.deliverable_group_id" class="atmo-error">Поле обязательно для заполнения</p>
                </dd>
            </li>
            <li>
                <dt class="atmo-help">
                    Объект закупки:&nbsp;
                    <i class="atmo-icon-question-mark"></i>
                    <span class="atmo-help__text">Укажите непосредственно товары, работы, услуги, поставка, выполнение, оказание которых будут являться предметом контракта.</span>
                </dt>
                <dd v-bind:class="{ 'atmo-has-errors': this.$props.errors.purchase_object }" class="atmo-form__field atmo-inverse">
                    <input v-model="$props.inputs.purchase_object" />
                    <p v-if="this.$props.errors.purchase_object" class="atmo-error" style="display: block">Поле обязательно для заполнения</p>
                </dd>
            </li>

            <li>
                <dt>Тип закупки:</dt>
                <dd class="atmo-form__field atmo-as-input">
                    <dropdown-select v-slot:default="ds" v-bind:initialid="this.$props.inputs.type_id" v-bind:list="availableOrderTypes">
                        <on-off v-slot:default="onoff" v-bind:options="{ onBody: 'intelligent' }">
                            <div class="atmo-dropdown-select">
                                <input type="number" v-bind:value="$props.inputs.type_id" v-on:input="$props.controller.setOrderType" />

                                <button type="button" v-on:click="onoff.toggle">
                                    <span v-if="onoff.ison" class="atmo-placeholder">Выберите тип закупки</span>
                                    <span v-if="ds.hasSelected && !onoff.ison" v-html="ds.text"></span>
                                    <i v-if="!onoff.ison" class="atmo-icon-angle-down"></i>
                                    <i v-else class="atmo-icon-angle-up"></i>
                                </button>
                                <ul v-show="onoff.ison" v-on:click="onoff.off" class="atmo-dropdown">
                                    <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id)">
                                        <span v-html="item.title"></span>
                                    </li>
                                </ul>
                            </div>
                        </on-off>
                    </dropdown-select>
                </dd>
            </li>

            <li>
                <dt>Продолжительность закупочной сессии:</dt>
                <dd class="atmo-form__field atmo-as-input">
                    <dropdown-select v-slot:default="ds" v-bind:initialid="this.$props.inputs.duration_id" v-bind:list="this.$props.dictionaries.durations">
                        <on-off v-slot:default="onoff" v-bind:options="{ onBody: 'intelligent' }">
                            <div class="atmo-dropdown-select">
                                <input type="number" v-bind:value="$props.inputs.duration_id" v-on:input="$props.inputs.duration_id = ds.toNullableInt($event.target.value)" />

                                <button type="button" v-on:click="onoff.toggle" v-bind:disabled="$props.controller.isRevokedDraftEdit">
                                    <span v-if="onoff.ison" class="atmo-placeholder">Выберите продолжительность закупочной сессии</span>
                                    <span v-if="ds.hasSelected && !onoff.ison" v-html="ds.text"></span>
                                    <i v-if="!onoff.ison" class="atmo-icon-angle-down"></i>
                                    <i v-else class="atmo-icon-angle-up"></i>
                                </button>
                                <ul v-show="onoff.ison" class="atmo-dropdown">
                                    <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id), onoff.off()">
                                        <span v-html="item.title"></span>
                                    </li>
                                </ul>
                            </div>
                        </on-off>
                    </dropdown-select>
                </dd>
            </li>

            <li v-if="this.$props.controller.isUrgentPurchase">
                <dt>Причина проведения срочной закупочной сессии:</dt>

                <dd class="atmo-form__field atmo-as-input">
                    <dropdown-select v-slot:default="ds" v-bind:initialid="this.$props.inputs.urgency_reason.id" v-bind:list="this.$props.dictionaries.urgencyreasons">
                        <on-off v-slot:default="onoff" v-bind:options="{ onBody: 'intelligent' }">
                            <div class="atmo-dropdown-select">
                                <input type="number" v-bind:value="$props.inputs.urgency_reason.id" v-on:input="$props.inputs.urgency_reason.id = ds.toNullableInt($event.target.value)" />

                                <button type="button" v-on:click="onoff.toggle">
                                    <span v-if="!ds.hasSelected || onoff.ison" class="atmo-placeholder">Выберите причину проведения срочной закупочной сессии</span>
                                    <span v-if="ds.hasSelected && !onoff.ison" v-html="ds.text"></span>
                                    <i v-if="!onoff.ison" class="atmo-icon-angle-down"></i>
                                    <i v-else class="atmo-icon-angle-up"></i>
                                </button>
                                <ul v-show="onoff.ison" v-on:click="onoff.off" class="atmo-dropdown">
                                    <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id)">
                                        <span v-html="item.title"></span>
                                    </li>
                                </ul>
                            </div>
                        </on-off>
                    </dropdown-select>

                    <p v-if="this.$props.errors.urgency_reason_id" class="atmo-error">Поле обязательно для заполнения</p>
                </dd>
            </li>

            <li v-if="this.$props.controller.isUrgentPurchase">
                <dt>Документ обоснования проведения срочной закупочной сессии:</dt>
                <dd class="atmo-form__field">
                    <table class="atmo-table-slim">
                        <tbody>
                            <tr>
                                <td>
                                    <editable-file-attachments-list-controller
                                        v-slot:default="efalc"
                                        v-bind:initiallist="$props.inputs.urgency_reason.attachment && $props.inputs.urgency_reason.attachment.file_id ? [$props.inputs.urgency_reason.attachment] : []"
                                        v-bind:isrequired="true"
                                        v-bind:origin="'none'"
                                        v-bind:maxfilesallowed="1"
                                        v-on:fileattachment:changed="$props.controller.updateUrgencyReasonFileAttachment">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td class="atmo-left">
                                                        <span v-if="!efalc.isempty">
                                                            <button v-on:click="efalc.downloadFileAttachment(efalc.list[0])" class="atmo-button-link" v-text="efalc.list[0].name" title="Скачать файл"></button>
                                                        </span>
                                                        <span v-else>Прикрепите файл</span>
                                                    </td>
                                                    <td v-if="!$props.controller.isRevokedDraftEdit" class="atmo-tc-button-icon atmo-center" title="Прикрепить файл">
                                                        <label v-bind:disabled="efalc.islistfull" class="atmo-button-icon">
                                                            <span><i class="atmo-icon-attachment"></i></span>
                                                            <input v-on:change="efalc.add" type="file" accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx" />
                                                        </label>
                                                    </td>
                                                    <td v-if="!$props.controller.isRevokedDraftEdit" class="atmo-tc-button-icon atmo-center" title="Удалить файл">
                                                        <button v-on:click="efalc.remove(efalc.list[0])" v-bind:disabled="efalc.isempty" class="atmo-button-icon">
                                                            <i class="atmo-icon-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </editable-file-attachments-list-controller>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </dd>
            </li>

            <li class="atmo-form__field atmo-checkbox">
                <dt><label for="with_advance">Аванс</label></dt>
                <dd>
                    <input v-model="$props.inputs.with_advance" type="checkbox" name="with_advance" id="with_advance" class="atmo-icon-checkmark" />
                </dd>
            </li>
            <li v-if="$props.inputs.with_advance">
                <dt><label for="advance_percentage">Размер аванса, %</label></dt>
                <dd v-bind:class="{ 'atmo-has-errors': this.$props.errors.advance_percentage }" class="atmo-form__field atmo-inverse">
                    <input v-model.number="$props.inputs.advance_percentage" type="number" min="1" max="30" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57" name="advance_percentage" id="advance_percentage" style="text-align: left" />
                    <p v-if="this.$props.errors.advance_percentage" class="atmo-error" style="display: block">
                        <template v-if="$props.inputs.advance_percentage === null || $props.inputs.advance_percentage === ''">Поле обязательно для заполнения</template>
                        <template v-else-if="$props.inputs.advance_percentage < 1">Значение должно быть больше или равно 1</template>
                        <template v-else-if="$props.inputs.advance_percentage > 30">Максимальное значение - 30</template>
                    </p>
                </dd>
            </li>

            <li>
                <dt class="atmo-help">
                    Планируемая дата заключения контракта:&nbsp;
                    <i class="atmo-icon-question-mark"></i>
                    <span class="atmo-help__text">Не может быть меньше текущей даты.</span>
                </dt>
                <dd v-bind:class="{ 'atmo-has-errors': this.$props.errors.planned_contract_signature_date }" class="atmo-form__field atmo-as-input">
                    <date-picker
                        v-model="$props.inputs.planned_contract_signature_date"
                        id="purchase-contractSignatureDate"
                        position="bottom"
                        label="Выберите дату"
                        button-now-translation="Сегодня"
                        format="YYYY-MM-DD"
                        formatted="DD.MM.YYYY"
                        v-bind:min-date="new Date().toISOString()"
                        v-bind:no-label="true"
                        v-bind:auto-close="true"
                        v-bind:only-date="true">
                    </date-picker>

                    <p v-if="this.$props.errors.planned_contract_signature_date" class="atmo-error">Поле обязательно для заполнения</p>
                </dd>
            </li>

            <li>
                <dt class="atmo-help">
                    Планируемая дата исполнения контракта:&nbsp;
                    <i class="atmo-icon-question-mark"></i>
                    <span class="atmo-help__text">Дату можно выбрать только после выбора Планируемой даты заключения контракта.</span>
                </dt>
                <dd v-bind:class="{ 'atmo-has-errors': this.$props.errors.planned_contract_fulfillment_date }" class="atmo-form__field atmo-as-input">
                    <date-picker
                        v-model="$props.inputs.planned_contract_fulfillment_date"
                        id="purchase-contractFulfillmentDate"
                        position="bottom"
                        label="Выберите дату"
                        button-now-translation="Сегодня"
                        format="YYYY-MM-DD"
                        formatted="DD.MM.YYYY"
                        v-bind:min-date="$props.inputs.planned_contract_signature_date"
                        v-bind:disabled="!$props.inputs.planned_contract_signature_date"
                        v-bind:no-label="true"
                        v-bind:auto-close="true"
                        v-bind:only-date="true">
                    </date-picker>

                    <p v-if="this.$props.errors.planned_contract_fulfillment_date" class="atmo-error">Поле обязательно для заполнения</p>
                </dd>
            </li>

            <li class="atmo-align-top">
                <dt>Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации:</dt>
                <dd class="atmo-form__field atmo-inverse">
                    <textarea type="text" v-model="$props.inputs.unilateral_contract_termination_info"></textarea>
                </dd>
            </li>
        </ul>
    </div>
</template>

<script>
import DropdownSelect from '@lib/js/src/vue/components/DropdownSelect.viewmodel.ts';
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import Multiselect from 'vue-multiselect';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';

export default {
    data: function () {
        return {
            deliverableGroup: null
        };
    },
    watch: {
        inputs: {
            deep: true,
            immediate: true,
            handler: function (newInputs, oldInputs) {
                // NB: Guard against oldInputs null (at immediate stage) or agains null ID in newInputs (create purchase);
                if ((oldInputs && newInputs.deliverable_group_id === oldInputs.deliverable_group_id) || !newInputs.deliverable_group_id) {
                    return;
                }
                const group = ExtendedDictionaryItem.findById(newInputs.deliverable_group_id, this.$props.dictionaries.deliverablesgroups);
                this.$data.deliverableGroup = { id: group.id, title: group.title };
            }
        }
    },
    props: {
        controller: { required: true, type: Object },
        inputs: { required: true, type: Object },
        errors: { required: true, type: Object },
        dictionaries: { required: true, type: Object },
        deliverablesgroupsselecthandler: { required: true, type: Function },
        deliverablesgroupsremovehandler: { required: true, type: Function }
    },
    computed: {
        alldeliverablesgroups() {
            return this.$props.dictionaries.deliverablesgroups;
        },
        activedeliverablesgroups() {
            return this.$props.dictionaries.deliverablesgroups.filter((item) => item.is_active);
        },
        availableOrderTypes() {
            return this.$globalDictionaries.orderTypes.filter((item) => item.is_fl44);
        }
    },
    components: {
        'dropdown-select': DropdownSelect,
        'on-off': new OnOff(),
        'date-picker': VueCtkDateTimePicker,
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController,
        Multiselect
    }
};
</script>
