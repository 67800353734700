<template>
    <div id="atmo-contract-annexes" class="atmo-hash-jump-point atmo-content-group">
        <h3>Дополнительные соглашения</h3>

        <ContractAnnexView.view v-for="(annex, index) in this.$props.annexes" v-bind:key="index" v-bind:index="index" v-bind:pivc="pivc" v-bind:annex="annex"> </ContractAnnexView.view>
    </div>
</template>

<script>
import ContractAnnexView from './ContractAnnex.view.vue';

export default {
    props: {
        pivc: { require: true, type: Object },
        annexes: { required: true, type: Array }
    },
    components: {
        'ContractAnnexView.view': ContractAnnexView
    }
};
</script>
