<template>
    <b-form-group :class="compact ? 'mb-0' : ''" :description="description" :label="label + ':'" :label-cols-lg="labelColsLg" :label-cols-sm="labelColsSm" :label-for="id" label-class="fs-14 grey-color fw-700 align-items-baseline" :label-align="labelRight ? 'right' : 'left'">
        <p class="fs-14 grey-color my-auto col-form-label">
            <template v-if="routerLink">
                <router-link :to="routerLink" class="green-color" tag="a" target="_blank">{{ value }}</router-link>
            </template>
            <template v-else-if="href">
                <a :href="href" class="green-color" target="_blank">{{ value }}</a>
            </template>
            <template v-else>
                {{ value }}
            </template>
        </p>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-link',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        routerLink: {
            type: Object,
            default: undefined
        },
        href: {
            type: String,
            default: null
        },
        compact: {
            type: Boolean,
            default: false
        },
        labelColsLg: {
            type: Number,
            default: 3
        },
        labelColsSm: {
            type: Number,
            default: 4
        },
        labelRight: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        id() {
            return 'text_row_link_' + this._uid;
        }
    }
};
</script>
