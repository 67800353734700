'use strict';

import FinancingYearLimitVO from './FinancingYearLimit.valueobject';

export type TSelfPOJO = Omit<KBKLimitDictionaryItemVO, 'isEmpty' | 'isValid'>;

// NB: Only properties required on frontend are listed.
export interface IRawDictionaryEntry {
    id: number | null;
    kbk: string | null;
    funds_type: string | null; // Код типа средств;
    fin_source_code: string | null; // Код источника финансирования (расходы);
    action_code: string | null; // Код мероприятия;
    subkosgu_code: string | null; // Код СубКОСГУ;
    fin_year: string | null; // Год финансирования
    eis_ident_no: string | null; // ИКЗ
    limits: KBKLimitDictionaryItemVO['limits'];

    // Details
    account: string | null; // Лицевой счет
    rks_code: string | null; // Код РКС
    kosgu_code: string | null; // Код КОСГУ
    budget_name: string | null; // Наименование бюджета
    target_code: string | null; // Код цели
    source_funds: string | null; // Бюджет - источник трансферта
    subsidy_code: string | null; // Код субсидии
    invest_object: string | null; // Объект
    direction_code: string | null; // Код направления
    pay_promise_code: string | null; // Код расходного обязательства
    targeted_funds_code: string | null; // Код целевых средств
    budget_allocation_code: string | null; // Код бюджетного ассигнования
    regional_project_result: string | null; // Результат проекта
    additional_information_code: string | null; // Код доп. информации
}

export default class KBKLimitDictionaryItemVO {
    id: number | null;
    code: string | null;
    year_pg: number | null;

    budget_name: string | null; // Наименование бюджета
    sub_kosgu_code: string | null; // Код СубКОСГУ;
    funds_type_code: string | null; // Код типа средств;
    event_code: string | null; // Код мероприятия;

    // Details
    fin_year: number | null; // Год финансирования
    eis_ident_no: string | null; // ИКЗ
    account: string | null;
    rks_code: string | null;
    kosgu_code: string | null;
    target_code: string | null;
    source_funds: string | null;
    subsidy_code: string | null;
    invest_object: string | null;
    direction_code: string | null;
    pay_promise_code: string | null;
    targeted_funds_code: string | null;
    budget_allocation_code: string | null;
    regional_project_result: string | null;
    additional_information_code: string | null;

    limits: FinancingYearLimitVO[];

    constructor(data?: TSelfPOJO) {
        this.id = data?.id ?? null;
        this.code = data?.code ?? null;
        this.year_pg = data?.year_pg ?? null;

        this.budget_name = data?.budget_name ?? null;
        this.funds_type_code = data?.funds_type_code ?? null;
        this.event_code = data?.event_code ?? null;
        this.sub_kosgu_code = data?.sub_kosgu_code ?? null;

        // Details.
        this.fin_year = data?.fin_year ?? null;
        this.eis_ident_no = data?.eis_ident_no ?? null;
        this.account = data?.account ?? null;
        this.rks_code = data?.rks_code ?? null;
        this.kosgu_code = data?.kosgu_code ?? null;
        this.target_code = data?.target_code ?? null;
        this.source_funds = data?.source_funds ?? null;
        this.subsidy_code = data?.subsidy_code ?? null;
        this.invest_object = data?.invest_object ?? null;
        this.direction_code = data?.direction_code ?? null;
        this.pay_promise_code = data?.pay_promise_code ?? null;
        this.targeted_funds_code = data?.targeted_funds_code ?? null;
        this.budget_allocation_code = data?.budget_allocation_code ?? null;
        this.regional_project_result = data?.regional_project_result ?? null;
        this.additional_information_code = data?.additional_information_code ?? null;

        this.limits =
            data?.limits ??
            Array.from(Array(4), () => {
                return new FinancingYearLimitVO();
            });
    }

    public static fromDictionaryEntry(data: IRawDictionaryEntry): KBKLimitDictionaryItemVO {
        const pojo: TSelfPOJO = {
            id: data.id,
            code: data.kbk,

            // DEPRECATED: Probably redundant property for frontend.
            year_pg: data.fin_year ? parseInt(data.fin_year, 10) : null,

            budget_name: data.budget_name,
            funds_type_code: data.funds_type,
            sub_kosgu_code: data.subkosgu_code,
            event_code: data.action_code,

            // Details
            // DEPRECATED: Probably redundant property for frontend.
            fin_year: data.fin_year ? parseInt(data.fin_year, 10) : null,
            eis_ident_no: data.eis_ident_no,
            account: data.account,
            rks_code: data.rks_code,
            kosgu_code: data.kosgu_code,
            target_code: data.target_code,
            source_funds: data.source_funds,
            subsidy_code: data.subsidy_code,
            invest_object: data.invest_object,
            direction_code: data.direction_code,
            pay_promise_code: data.pay_promise_code,
            targeted_funds_code: data.targeted_funds_code,
            budget_allocation_code: data.budget_allocation_code,
            regional_project_result: data.regional_project_result,
            additional_information_code: data.additional_information_code,

            limits: data.limits.map((rawItem: FinancingYearLimitVO) => {
                return new FinancingYearLimitVO(rawItem);
            })
        };

        return new KBKLimitDictionaryItemVO(pojo);
    }

    // NB: So far not clear if the method is required. Will see later.
    // public isEmpty(): boolean {
    //     return this.id === null && this.limit_available === null;
    // }

    // NB: Same as above.
    // public isValid(): boolean {
    //     return this.id !== null && this.code !== null && this.code.length > 0;
    // }
}
