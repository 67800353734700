var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": _vm.labelColsSm,
        "label-cols-lg": _vm.labelColsLg,
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        state: _vm.state,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c("b-form-checkbox", {
        staticClass: "fs-14 mt-1",
        attrs: { id: _vm.id, state: _vm.state, disabled: _vm.disabled },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      !_vm.state
        ? [_c("b-form-invalid-feedback", [_vm._v(_vm._s(_vm.errorMessage))])]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }