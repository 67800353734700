'use strict';

import AnnexItemEditableVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItemEditable.valueobject';
import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import AnnexDeliverableItemEditableVO from '../../../../domain/model/common/deliverable/update/annex/AnnexDeliverableItemEditable.valueobject';

interface IItem {
    purchase_item_id: number | null;
    okei_id: number;
    ktru_item_id: number | null;
    okpd_id: number;
    amount: number;
    price: number;
    is_manually_added: boolean;
    is_delivery_item: boolean;
    is_deleted: boolean;
}

export default class UpdateContractAnnexRequestVO extends BaseValueObject {
    supplementary_agreement_date: string;
    document: {
        id: string;
    };
    items: IItem[];

    constructor(annex: AnnexItemEditableVO) {
        super();

        this.supplementary_agreement_date = annex.signature_date as string;
        this.document = {
            id: annex.attachment!.file_id as string
        };
        this.items = annex.deliverables.unfilteredList.map(this.transformDeliverables);
    }

    private transformDeliverables(deliverable: AnnexDeliverableItemEditableVO): IItem {
        return {
            purchase_item_id: deliverable.is_delivery ? null : (deliverable.id as number),
            okei_id: deliverable.okei_id,
            ktru_item_id: deliverable.gsc_details ? deliverable.gsc_details.id : null,
            okpd_id: deliverable.okpd_id,
            amount: deliverable.quantity,
            price: deliverable.price_per_unit,
            is_manually_added: deliverable.isDuplicate,
            is_delivery_item: deliverable.is_delivery,
            is_deleted: deliverable.isDeleted
        };
    }
}
