'use strict';

import { Component, Prop, Provide, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import isEqual from 'lodash.isequal';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IOnOffOnToggleEvent from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import IViewmodelEventPayload from '@core/js/ddriven/application/abstractions/vue/IViewmodelEventPayload';
import StandardPagination from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import KBKLimitDictionaryItemVO, { IRawDictionaryEntry } from '@/rearchitected/core/js/ddriven/domain/model/common/dictionaries/kbk-limits/KBKLimitDictionaryItem.valueobject';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import KBKDictionaryFilteredRequestVO from '../../../../../ddriven/application/http/requests/dictionaries/KBKDictionaryFilteredRequest.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

interface IData {
    request: KBKDictionaryFilteredRequestVO | null;
    list: KBKLimitDictionaryItemVO[];
    pagination: StandardPagination | null;
    isloading: boolean;
}

interface LoadedData {
    list: KBKLimitDictionaryItemVO[];
    pagination: StandardPagination;
}

@Component
export default class KBKLimitSelectorPopupController extends BaseViewModel {
    public static popupId = 'kbk-selector-popup';

    private respond?: Function;

    constructor() {
        super();
        this.name = 'KBKLimitSelectorPopupController';
    }

    @Provide() kbkspc = () => {
        return this;
    };

    created() {
        this.$data.request = new KBKDictionaryFilteredRequestVO(); // Provide null-object for view
        this.$root.$on('public:onoff:toggle', this.initializeViewmodel);
    }

    data(): IData {
        return {
            request: null,
            list: [],
            pagination: null,
            isloading: false
        };
    }

    /**
     * Computed
     */
    get islistempty(): boolean {
        return this.$data.list.length < 1;
    }

    get purchaseTypeTitle(): string | null {
        const types = this.$store.getters[`rearchitected/groups/${this.$route.params.group ?? 'fl44'}/purchases/dictionaries/types`] || [];
        const found = types.find((type: { id: number; text: string }) => {
            return type.id === this.$data.request.order_type_id;
        });

        return found ? found['title'] : null;
    }

    /**
     * Watch
     */

    /**
     * On filter change always request the first page of the filtered results.
     */
    @Debounce(600)
    @Watch('request.filter', { deep: true })
    async onRequestFilterChanged(newFilter: KBKDictionaryFilteredRequestVO['filter'], oldFilter: KBKDictionaryFilteredRequestVO['filter'] | null) {
        if (!newFilter || (newFilter && !oldFilter) || (isEqual(newFilter, KBKDictionaryFilteredRequestVO.nullFilter) && isEqual(oldFilter, KBKDictionaryFilteredRequestVO.nullFilter))) {
            return;
        }

        this.$data.request.page = 1;
        this.loadAndSetData(this.$data.request);
    }

    @Watch('request.page')
    async onPaginationChanged(newPage: number, oldPage: number | null) {
        if (!newPage || (newPage && !oldPage)) {
            return;
        }
        this.loadAndSetData(this.$data.request);
    }

    /**
     * Methods
     */
    public exit(): void {
        this.respond!(null);
        this.close();
    }

    public selected(dictionaryItemIndex: number, limitIndex: number): void {
        this.respond!([this.$data.list[dictionaryItemIndex], limitIndex]);
        this.close();
    }

    public pageUpdate(evt: IViewmodelEventPayload): void {
        this.$data.request.page = evt.value;
    }

    /**
     * Provide the handle to forcefully re-render a component to reset its state,
     * e.g. KBKLimitSelectorList view to close open dropdowns etc.
     */
    public random(): number {
        // Make the new value surely different vs previous by adding page number.
        return Math.random() * (1000 - 1) + 1 + (this.$data.pagination ? this.$data.pagination.page.current : 1);
    }

    /**
     * Prototype general methods.
     */
    private async initializeViewmodel(payload: IOnOffOnToggleEventPayload) {
        if (payload.id !== KBKLimitSelectorPopupController.popupId || !payload.ison) {
            return;
        }
        this.$data.request = KBKDictionaryFilteredRequestVO.fromFiliterPresets(payload.data.presets);
        this.respond = payload.respond;
        await this.loadAndSetData(this.$data.request);
    }

    private async loadAndSetData(request?: KBKDictionaryFilteredRequestVO) {
        const data: LoadedData = await this.load(request);
        this.$data.pagination = data.pagination;
        this.$data.list = data.list;
    }

    private async load(request?: KBKDictionaryFilteredRequestVO): Promise<LoadedData> {
        this.$data.isloading = true;
        const response = await ApplicationServiceLocator.get('api').dictionaries.loadKBKLimitsFiltered(request);

        const list: KBKLimitDictionaryItemVO[] = response.data.data.map((item: IRawDictionaryEntry) => {
            return Object.freeze(KBKLimitDictionaryItemVO.fromDictionaryEntry(item));
        });

        this.$data.isloading = false;

        return { list: list, pagination: new StandardPagination(response.data.meta) };
    }

    public close(): void {
        this.$root.$emit('public:onoff:toggle', { id: KBKLimitSelectorPopupController.popupId } as IOnOffOnToggleEvent);
    }
}
