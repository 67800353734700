<template>
    <b-navbar-nav class="fs-14 my-auto">
        <b-nav-dropdown right scrollable no-caret no-flip menu-class="py-0 notification-shadow" ref="notifications" @show="getNotifications(null)" @hide="hideNotifications">
            <template #button-content>
                <span class="d-flex align-items-center col my-auto" title="Уведомления">
                    <b-icon-bell-fill v-if="$store.state.user.notification_settings.internal" class="grey-color" />
                    <b-icon-bell-slash-fill v-else class="grey-color" />
                    <b-badge v-if="$store.state.unreadNotificationsCount > 0" variant="custom-green" class="notifications">{{ unreadNotificationsCount }}</b-badge>
                </span>
            </template>
            <b-card header-class="py-1" body-class="p-0">
                <template #header>
                    <b-row fluid>
                        <b-col>
                            <h5 class="fw-600 green-color my-0 cursor-pointer" @click="goToCabinet">Уведомления</h5>
                        </b-col>
                        <b-col class="col-auto grey-color cursor-pointer" @click="showSettings">
                            <b-icon-gear-fill />
                        </b-col>
                        <b-col class="col-auto px-0" @click="$refs.notifications.hide(true)">
                            <b-icon icon="x" style="width: 24px; height: 24px" class="cursor-pointer p-0" aria-hidden="true" />
                        </b-col>
                    </b-row>
                </template>
                <b-card-body class="p-1 notification-list grey-color">
                    <template v-if="!notifications.length">
                        <b-overlay :show="isNotificationsLoad" opacity="0.6" rounded spinner-variant="primary">
                            <empty-request />
                        </b-overlay>
                    </template>
                    <template v-else>
                        <b-overlay :show="isDataSending && isNotificationsLoad" class="pr-3" opacity="0.6" rounded spinner-variant="primary">
                            <notification-row v-for="notification in notifications" :notification="notification" :key="notification.id" :row-selected="notification.row_selected" @click="$refs.notifications.hide(true)" @delete="deleteNotifications" @read="markNotificationsAsRead" @unread="markNotificationsAsUnread" variant="white" />
                        </b-overlay>
                    </template>
                </b-card-body>
                <b-card-footer v-if="notifications.length && meta.total > 0 && meta.last_page > 1" class="py-1">
                    <b-row class="w-100 m-0">
                        <b-col class="text-center">
                            <b-overlay :show="isNotificationsLoad && isDataSending && notifications.length && meta.total > 0 && meta.last_page > 1" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                                <span class="atmo-button-link cursor-pointer fs-14" @click="goToCabinet">Показать все ({{ meta.total }})</span>
                            </b-overlay>
                        </b-col>
                    </b-row>
                </b-card-footer>
            </b-card>
        </b-nav-dropdown>
    </b-navbar-nav>
</template>
<script>
import NotificationRow from "@/layout/elements/notification-row.vue";

export default {
    name: 'navbar-notifications-dropdown',
    components: {NotificationRow},
    data() {
        return {
            isNotificationsLoad: false,
            isDataSending: false,
            notifications: [],
            meta: {
                current_page: 1,
                total: 0,
                last_page: 1,
                per_page: 50
            },
            unreadCount: 0
        }
    },
    methods: {
        async getNotifications(page = null) {
            this.isNotificationsLoad = true;
            await this.$api.notifications.getNotificationList({page: page ? page : this.meta.current_page})
                .then(response => {
                    const selected = this.notifications.filter((notification) => notification.row_selected);
                    response.data.forEach(notification => notification.row_selected = selected.find((value) => value.id === notification.id) !== undefined);
                    this.notifications = response.data;
                    this.meta = response.meta;
                    this.unreadCount = response.unread_notifications_count;
                    this.$store.commit('set_unread_notifications_count', response.unread_notifications_count);
                })
                .finally(() => {
                    this.isNotificationsLoad = false;
                });
        },
        async markNotificationsAsRead(id = null) {
            const data = [];
            if (id) {
                data.push(id);
            } else {
                this.notifications.filter(notification => notification.row_selected && !notification.is_read).forEach(notification => { data.push(notification.id) });
            }
            if (data.length > 0) {
                this.isDataSending = !id;
                this.isNotificationsLoad = true;
                await this.$api.notifications.markAsRead({ notifications: data })
                    .then(async response => {
                        this.unreadCount = response.count;
                        this.$store.commit('set_unread_notifications_count', response.count);
                        await this.getNotifications();
                    })
                    .finally(() => {
                        this.isDataSending = false;
                    });
            }
        },
        async markNotificationsAsUnread(id) {
            const data = [];
            data.push(id);
            this.isNotificationsLoad = true;
            await this.$api.notifications.markAsUnread({ notifications: data })
                .then(async response => {
                    this.unreadCount = response.count;
                    this.$store.commit('set_unread_notifications_count', response.count);
                    await this.getNotifications();
                });
        },
        showSettings() {
            this.$refs.notifications.hide(true);
            this.$router.push({ name: 'NotificationSettings' });
        },
        goToCabinet() {
            this.$refs.notifications.hide(true);
            this.$router.push({ name: 'NotificationsList' });
        },
        hideNotifications() {
            this.meta.current_page = 1;
            this.meta.total = 0;
            this.meta.last_page = 1;
            this.notifications = [];
        },
        async deleteNotifications(id) {
            const data = [];
            this.isDataSending = true;
            this.isNotificationsLoad = true;
            data.push(id);
            const newCurrentPage = this.meta.current_page !== this.meta.last_page ? this.meta.current_page : Math.ceil((this.meta.total - data.length) / this.meta.per_page) ?? 1;
            await this.$api.notifications.delete(data)
                .then(async response => {
                    this.unreadCount = response.count;
                    this.$store.commit('set_unread_notifications_count', response.count);
                    await this.getNotifications(newCurrentPage);
                })
                .finally(() => {
                    this.isDataSending = false;
                });
        }
    },
    computed: {
        unreadNotificationsCount() {
            return parseInt(this.$store.state.unreadNotificationsCount) < 100 ? this.$store.state.unreadNotificationsCount : '99+';
        }
    }
};
</script>
<style>
.notification-shadow {
    -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5) !important;
    -moz-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5) !important;
}
</style>

<style scoped>
.notification-list {
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 600px;
}
.notifications {
    border-radius: 1rem;
    font-size: 11px;
    position: absolute;
    top: -10px;
    right: -3px;
    width: 24px;
    height: 14px;
    padding: 2px;
}
</style>
