var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "atmo-content-group" }, [
    _c("h6", [
      _vm._v("Дополнительное соглашение № "),
      _c("span", {
        domProps: { textContent: _vm._s(this.$props.annex.sequence_number) },
      }),
    ]),
    _c("ul", { staticClass: "atmo-content-block-2-columns" }, [
      _c("li", [
        _c("dt", [_vm._v("Дата заключения дополнительного соглашения:")]),
        _c("dd", [
          _vm._v(
            _vm._s(_vm._f("formatdate")(this.$props.annex.date, "onlyDate"))
          ),
        ]),
      ]),
      _c("li", [
        _c("dt", [_vm._v("Документы:")]),
        _c("dd", [
          _c("ul", { staticClass: "atmo-list" }, [
            this.$props.annex.hasAttachments()
              ? _c("li", [
                  _c("button", {
                    staticClass: "atmo-button-link",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$props.annex.actualAttachment().name
                      ),
                    },
                    on: {
                      click: function ($event) {
                        _vm.$props.handlers.downloadFileAttachment(
                          _vm.$props.annex.actualAttachment()
                        )
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("li", [
        _c("dt", [_vm._v("Спецификация:")]),
        _c("dd", [
          _c("table", { staticClass: "atmo-table-mini" }, [
            _c(
              "tbody",
              _vm._l(
                this.$props.annex.deliverables,
                function (deliverable, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(index + 1) + ".")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(deliverable.title) },
                    }),
                    _c("td", {
                      domProps: { textContent: _vm._s(deliverable.quantity) },
                    }),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(deliverable.okei_symbolic_code),
                      },
                    }),
                    _c("td", { domProps: { textContent: _vm._s("*") } }),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatnumber")(deliverable.price_per_unit)
                        )
                      ),
                    ]),
                    _c("td", { domProps: { textContent: _vm._s("руб.") } }),
                    _c("td", { domProps: { textContent: _vm._s("=") } }),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm._f("formatnumber")(deliverable.price_total))
                      ),
                    ]),
                    _c("td", { domProps: { textContent: _vm._s("руб.") } }),
                  ])
                }
              ),
              0
            ),
          ]),
        ]),
      ]),
      _c("li", [
        _c("dt", [_vm._v("Общая стоимость, руб.:")]),
        _c("dd", [
          _vm._v(_vm._s(_vm._f("formatnumber")(this.$props.annex.price_total))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }