var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "atmo-fast-access-menu" }, [
    _c("ul", { staticClass: "atmo-container-fixed" }, [
      _vm._m(0),
      _vm._m(1),
      !_vm.pivc.isPurchaseOutATMO
        ? _c("li", [
            _c("a", { attrs: { href: "#atmo-supply-terms" } }, [
              _vm._v("Условия поставки"),
            ]),
          ])
        : _vm._e(),
      _vm._m(2),
      _vm.pivc.hasPoroposals
        ? _c("li", [
            _c("a", { attrs: { href: "#atmo-proposals" } }, [
              _vm._v("Предложения участников закупочной сессии"),
            ]),
          ])
        : _vm._e(),
      _vm.pivc.isPurchaseSummarized ||
      _vm.pivc.hasContract ||
      _vm.pivc.hasPurchaseProtocol
        ? _c("li", [
            _c("a", { attrs: { href: "#atmo-supplier-info" } }, [
              _vm._v("Информация о поставщике (исполнителе)"),
            ]),
          ])
        : _vm._e(),
      (_vm.pivc.isPurchaseSummarized ||
        _vm.pivc.hasContract ||
        _vm.pivc.hasPurchaseProtocol) &&
      !_vm.pivc.isPurchaseOutATMO
        ? _c("li", [
            _c("a", { attrs: { href: "#atmo-price-breakdown" } }, [
              _vm._v("Стоимость"),
            ]),
          ])
        : _vm._e(),
      _vm.pivc.hasContract
        ? _c("li", [
            _c("a", { attrs: { href: "#atmo-contract" } }, [_vm._v("Договор")]),
          ])
        : _vm._e(),
      _vm.pivc.hasContract &&
      (_vm.pivc.isUserEngagedCustomer || _vm.pivc.isUserEngagedSupplier) &&
      _vm.pivc.isPurchaseSummarized &&
      _vm.pivc.purchaseitem.contract.hasDisputes()
        ? _c("li", [
            _c("a", { attrs: { href: "#atmo-disputes" } }, [
              _vm._v("Разногласия"),
            ]),
          ])
        : _vm._e(),
      _vm.pivc.hasContractAnnexes
        ? _c("li", [
            _c("a", { attrs: { href: "#atmo-contract-annexes" } }, [
              _vm._v("Дополнительные соглашения"),
            ]),
          ])
        : _vm._e(),
      _vm.pivc.isUserEngagedCustomer &&
      _vm.pivc.hasContract &&
      _vm.pivc.isContractSignedByBoth
        ? _c("li", [
            _c("a", { attrs: { href: "#atmo-contract-fulfillment" } }, [
              _vm._v("Исполнение договора"),
            ]),
          ])
        : _vm._e(),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "#atmo-customer-info" } }, [
        _vm._v("Информация о заказчике"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "#atmo-purchase-terms" } }, [
        _vm._v("Условия закупки"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "#atmo-notice-deliverables" } }, [
        _vm._v("Спецификация извещения"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "atmo-group" }, [
      _c("li", { attrs: { title: "К верху страницы" } }, [
        _c(
          "a",
          { staticClass: "atmo-button-icon", attrs: { href: "#atmo-top" } },
          [_c("i", { staticClass: "atmo-icon-angle-up" })]
        ),
      ]),
      _c("li", { attrs: { title: "К низу страницы" } }, [
        _c(
          "a",
          { staticClass: "atmo-button-icon", attrs: { href: "#atmo-bottom" } },
          [_c("i", { staticClass: "atmo-icon-angle-down" })]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }