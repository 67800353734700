import { CabinetModelApiService } from '@/services/backend/cabinet/base';
import axios from 'axios';

export class CabinetUsersAPIService extends CabinetModelApiService {
    constructor() {
        super('users');
    }

    async block(id) {
        if (!id) throw Error('Id is not provided');
        try {
            await axios.post(this.getUrl(id) + '/block');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async unblock(id) {
        if (!id) throw Error('Id is not provided');
        try {
            await axios.post(this.getUrl(id) + '/unblock');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
