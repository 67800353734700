var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isUserProvider && _vm.allowToAddExplanationRequest
        ? [
            _c(
              "b-button",
              {
                staticClass: "text-uppercase",
                attrs: { variant: "custom-green" },
                on: { click: _vm.onAddRequestClick },
              },
              [_vm._v("Добавить запрос")]
            ),
            _c("b-form-text", { staticClass: "desc-text mb-2 mt-0" }, [
              _vm._v(
                " Доступна отправка не более двух запросов от организации до подачи предложения "
              ),
            ]),
          ]
        : _vm._e(),
      _c("b-table", {
        attrs: {
          fields: _vm.fields,
          items: _vm.explanationRequests,
          "table-class": "table-atmo",
          "show-empty": "",
          "empty-text": "Запросы отсутствуют",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(request)",
            fn: function (row) {
              return [
                _c(
                  "div",
                  { class: row.item.is_my_request ? "text-danger" : "" },
                  [_vm._v(" " + _vm._s(row.item.request) + " ")]
                ),
                row.item.request_document
                  ? _c(
                      "a",
                      {
                        staticClass: "atmo-button-link",
                        attrs: {
                          href: _vm.$getAttachmentDownloadLink(
                            row.item.request_document.id
                          ),
                        },
                      },
                      [_vm._v(_vm._s(row.item.request_document.name))]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(answer)",
            fn: function (row) {
              return [
                _c("div", [_vm._v(" " + _vm._s(row.item.answer ?? "—") + " ")]),
                row.item.answer_document
                  ? _c(
                      "a",
                      {
                        staticClass: "atmo-button-link",
                        attrs: {
                          href: _vm.$getAttachmentDownloadLink(
                            row.item.answer_document.id
                          ),
                        },
                      },
                      [_vm._v(_vm._s(row.item.answer_document.name))]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (row) {
              return [
                row.item.allow_to_add_answer
                  ? _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase bg-secondary",
                        attrs: { variant: "custom-green-small" },
                        on: {
                          click: function ($event) {
                            return _vm.onAnswerClick(row.item.id)
                          },
                        },
                      },
                      [_vm._v("Ответить")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("ExplanationRequestAddModal", {
        attrs: {
          "show-modal": _vm.showModal,
          "is-request": _vm.isRequest,
          "request-id": _vm.requestId,
        },
        on: {
          done: _vm.onDone,
          close: function ($event) {
            _vm.showModal = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }