var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("notice-list-page-controller", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (nlpc) {
          return [
            _c(
              "div",
              { staticClass: "atmo-page-entities-list" },
              [
                _c("section", { staticClass: "atmo-page-banner-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "atmo-container-fixed" },
                    [
                      _c("h2", { staticClass: "mb-4" }, [
                        _vm._v("Реестр извещений 223-ФЗ"),
                      ]),
                      _c("NoticeFiltersView", { attrs: { elpc: nlpc } }),
                    ],
                    1
                  ),
                ]),
                _c("entity-status-menu-controller", {
                  attrs: {
                    route: nlpc.storeprefix,
                    statuses: nlpc.dictionaries.menustatuses,
                    currentstatus: nlpc.currentstatus,
                  },
                  on: { "status:update": nlpc.processRequestUpdate },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (esmc) {
                          return [
                            _c("responsive-on-off", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ronoff) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "atmo-container-fixed",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "atmo-status-menu-controller",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "atmo-onoff",
                                                    on: {
                                                      click: ronoff.toggle,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "atmo-icon-burger",
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          esmc.currentTitle
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "ul",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: ronoff.ison,
                                                        expression:
                                                          "ronoff.ison",
                                                      },
                                                    ],
                                                    staticClass: "atmo-menu",
                                                  },
                                                  _vm._l(
                                                    esmc.statuses,
                                                    function (menuitem) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: menuitem.id,
                                                          on: {
                                                            click: ronoff.off,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  esmc.isDisabledMenuItem(
                                                                    menuitem.id
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return esmc.status(
                                                                      menuitem.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("span", {
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      menuitem.title
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("StandardPaginationView", {
                  attrs: {
                    pagination: nlpc.pagination,
                    pageupdatehandler: nlpc.processRequestUpdate,
                  },
                }),
                _c(
                  "ul",
                  { staticClass: "atmo-container-fixed" },
                  [
                    _vm._l(nlpc.list, function (noticeitem) {
                      return _c(
                        "li",
                        { key: noticeitem.id, staticClass: "atmo-entity-item" },
                        [
                          _c("notice-item", {
                            attrs: { elpc: nlpc, noticeitem: noticeitem },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (pi) {
                                    return [
                                      _c("NoticeItemView", {
                                        attrs: { pi: pi },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    }),
                    nlpc.isListEmpty
                      ? _c("li", [
                          _c("figure", { staticClass: "atmo-notfound-image" }, [
                            _c("img", {
                              attrs: {
                                src: "/images/common/other/empty-box.svg",
                                alt: "Список пуст",
                              },
                            }),
                            _c("figcaption", [
                              _vm._v("По вашему запросу ничего не найдено"),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _c("StandardPaginationView", {
                  attrs: {
                    pagination: nlpc.pagination,
                    pageupdatehandler: nlpc.processRequestUpdate,
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }