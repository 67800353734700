'use strict';

import KBKLimitSpecificationItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/KBKLimitSpecificationItemEditable.valueobject';
import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import KBKLimitUpdateItemVO from './KBKLimitUpdateItem.valueobject';

export default class KBKLimitsUpdateRequestVO extends BaseValueObject {
    items: KBKLimitUpdateItemVO[];

    constructor(limits: KBKLimitSpecificationItemEditableVO[]) {
        super();

        this.items = limits.map((item: KBKLimitSpecificationItemEditableVO) => {
            return new KBKLimitUpdateItemVO(item as KBKLimitUpdateItemVO);
        });
    }
}
