var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block text-center" },
    [
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            rounded: "",
            opacity: "0.6",
            "spinner-variant": "primary",
          },
        },
        [
          _vm.isLoginError
            ? _c("LoginError")
            : _vm.certificateErrorCode === 0
            ? [
                !_vm.hasOrganizationsList
                  ? _c(
                      "b-form-group",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          label: "Выберите сертификат",
                          "label-class": "fs-18 grey-color",
                        },
                      },
                      [
                        _c(
                          "multiselect",
                          {
                            attrs: {
                              "allow-clear": false,
                              "allow-empty": false,
                              "close-on-select": true,
                              multiple: false,
                              options: _vm.certificates,
                              "preserve-search": false,
                              "show-labels": false,
                              label: "subjectName",
                              placeholder: "Выберите сертификат",
                              "track-by": "thumbprint",
                            },
                            on: { input: _vm.onCertificateChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "singleLabel",
                                  fn: function (props) {
                                    return [
                                      _c("strong", [_vm._v("Субъект:")]),
                                      _vm._v(
                                        " " + _vm._s(props.option.subjectName)
                                      ),
                                      _c("br"),
                                      _c("br"),
                                      _c("strong", [_vm._v("Срок действия:")]),
                                      _vm._v(
                                        " с " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              props.option.validFrom
                                            )
                                          ) +
                                          " по " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              props.option.validTo
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "option",
                                  fn: function (props) {
                                    return [
                                      _c("strong", [_vm._v("Субъект:")]),
                                      _vm._v(
                                        " " + _vm._s(props.option.subjectName)
                                      ),
                                      _c("br"),
                                      _c("br"),
                                      _c("strong", [_vm._v("Срок действия:")]),
                                      _vm._v(
                                        " с " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              props.option.validFrom
                                            )
                                          ) +
                                          " по " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              props.option.validTo
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1394471126
                            ),
                            model: {
                              value: _vm.selectedCertificate,
                              callback: function ($$v) {
                                _vm.selectedCertificate = $$v
                              },
                              expression: "selectedCertificate",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "noOptions" },
                                slot: "noOptions",
                              },
                              [_vm._v("Нет данных")]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "noResult" }, slot: "noResult" },
                              [
                                _vm._v(
                                  "По вашему запросу сертификаты не найдены"
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "b-form-invalid-feedback",
                          { staticClass: "mt-3", attrs: { state: _vm.state } },
                          [_vm._v(_vm._s(_vm.errorMessage))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm.certificateErrorCode === 90097
            ? _c("CryptoproError")
            : _c(
                "b-container",
                { staticClass: "text-danger fs-18 fw-600 text-center my-5" },
                [_c("p", [_vm._v(_vm._s(_vm.certificateErrorMessage))])]
              ),
          _vm.hasOrganizationsList
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-2",
                  attrs: {
                    label: "Выберите организацию",
                    "label-class": "fs-18 grey-color",
                  },
                },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        "allow-clear": false,
                        "allow-empty": false,
                        "close-on-select": true,
                        multiple: false,
                        options: _vm.organizations,
                        "preserve-search": false,
                        "show-labels": false,
                        label: "name",
                        placeholder: "Выберите организацию",
                        "track-by": "id",
                      },
                      on: { input: _vm.onCertificateChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "singleLabel",
                            fn: function (props) {
                              return [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      props.option.is_provider ? "(П)" : "(З)"
                                    ) +
                                      " " +
                                      _vm._s(props.option.name)
                                  ),
                                ]),
                                _c("br"),
                                _c("strong", [_vm._v("ИНН: ")]),
                                _vm._v(_vm._s(props.option.inn)),
                                props.option.kpp
                                  ? [
                                      _vm._v(", "),
                                      _c("strong", [_vm._v("КПП: ")]),
                                      _vm._v(_vm._s(props.option.kpp)),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "option",
                            fn: function (props) {
                              return [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      props.option.is_provider ? "(П)" : "(З)"
                                    ) +
                                      " " +
                                      _vm._s(props.option.name)
                                  ),
                                ]),
                                _c("br"),
                                _c("strong", [_vm._v("ИНН: ")]),
                                _vm._v(_vm._s(props.option.inn)),
                                props.option.kpp
                                  ? [
                                      _vm._v(", "),
                                      _c("strong", [_vm._v("КПП: ")]),
                                      _vm._v(_vm._s(props.option.kpp)),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3043004310
                      ),
                      model: {
                        value: _vm.selectedOrganization,
                        callback: function ($$v) {
                          _vm.selectedOrganization = $$v
                        },
                        expression: "selectedOrganization",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noOptions" }, slot: "noOptions" },
                        [_vm._v("Нет данных")]
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("По вашему запросу организации не найдены")]
                      ),
                    ]
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    { staticClass: "mt-3", attrs: { state: _vm.state } },
                    [_vm._v(_vm._s(_vm.errorMessage))]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoginError
            ? _c("footer", { staticClass: "modal-footer pb-0 px-0" }, [
                _c(
                  "div",
                  { staticClass: "w-100 text-left" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase mr-2",
                        attrs: { variant: "custom-outline-secondary" },
                        on: { click: _vm.onCancel },
                      },
                      [_vm._v("Отмена")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase",
                        attrs: {
                          disabled: !_vm.state,
                          variant: "custom-green",
                        },
                        on: { click: _vm.login },
                      },
                      [_vm._v("Продолжить")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }