var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _vm._t("header", function () {
                return [_c("text-header", [_vm._v("Спецификация")])]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("b-table", {
        staticClass: "table-atmo",
        attrs: {
          id: "my-table",
          fields: _vm.fields,
          items: _vm.items,
          "details-td-class": "py-1",
          "show-empty": "",
          small: "",
          responsive: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(index)",
            fn: function (data) {
              return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
            },
          },
          {
            key: "row-details",
            fn: function (data) {
              return [
                data.item.ktru_item
                  ? [
                      _c("p", [
                        _c("b", [_vm._v("Рег.номер ТРУ:")]),
                        _vm._v(" " + _vm._s(data.item.ktru_item.reg_number)),
                      ]),
                      _c("p", [
                        _c("b", [_vm._v("Группа ТРУ:")]),
                        _vm._v(
                          " " + _vm._s(data.item.ktru_item.ktru_group_name)
                        ),
                      ]),
                      _c("p", [
                        _c("b", [_vm._v("Наименование:")]),
                        _vm._v(" " + _vm._s(data.item.ktru_item.name)),
                      ]),
                      _c("p", [
                        _c("b", [_vm._v("Тип:")]),
                        _vm._v(" " + _vm._s(data.item.ktru_item.type)),
                      ]),
                      data.item.okpd
                        ? _c("p", [
                            _c("b", [_vm._v("Код ОКПД2:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.okpd ? data.item.okpd.code : "—"
                                )
                            ),
                          ])
                        : _vm._e(),
                      data.item.ktru_item.country_of_origin_name
                        ? _c("p", [
                            _c("b", [_vm._v("Страна происхождения:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.ktru_item.country_of_origin_name
                                )
                            ),
                          ])
                        : _vm._e(),
                      data.item.ktru_item.description
                        ? _c("p", [
                            _c("b", [_vm._v("Описание:")]),
                            _vm._v(
                              " " + _vm._s(data.item.ktru_item.description)
                            ),
                          ])
                        : _vm._e(),
                      data.item.ktru_item.specs &&
                      data.item.ktru_item.specs.length > 0
                        ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                        : _vm._e(),
                      data.item.ktru_item.specs &&
                      data.item.ktru_item.specs.length > 0
                        ? _c(
                            "ul",
                            _vm._l(
                              data.item.ktru_item.specs,
                              function (spec, key) {
                                return _c("li", { key: "spec" + key }, [
                                  _c("span", { staticClass: "fw-600" }, [
                                    _vm._v(_vm._s(spec.name)),
                                  ]),
                                  _vm._v(": " + _vm._s(spec.value) + " "),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]
                  : [_vm._v("—")],
              ]
            },
          },
          {
            key: "cell(specs)",
            fn: function (data) {
              return [
                _c("action-button-small", {
                  attrs: {
                    title:
                      (data.detailsShowing ? "Скрыть" : "Показать") +
                      " характеристики",
                    icon: data.detailsShowing ? "eye-slash" : "eye",
                  },
                  on: { click: data.toggleDetails },
                }),
                _vm._v(" " + _vm._s(data.item.ktru_item.name) + " "),
              ]
            },
          },
          {
            key: "cell(okei)",
            fn: function (data) {
              return [
                data.item.ktru_item.okei_local_symbol
                  ? [_vm._v(_vm._s(data.item.ktru_item.okei_local_symbol))]
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(price)",
            fn: function (data) {
              return [
                _vm._v(" " + _vm._s(_vm.$formatPrice(data.item.price)) + " "),
              ]
            },
          },
          {
            key: "cell(cost)",
            fn: function (data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$formatPrice(
                        _vm.roundToTwoDecimals(data.item.total_price)
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "empty",
            fn: function () {
              return [
                _c("div", { staticClass: "w-100 text-center" }, [
                  _vm._v("Не добавлено позиций спецификации"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }