<template>
    <div class="d-flex align-items-center fast_auth col my-auto">
        <template v-if="!$store.state.token">
            <b-btn class="text-nowrap text-uppercase" variant="custom-danger" @click="showRegistrationModal">Регистрация</b-btn>
            <b-btn class="text-nowrap text-uppercase ml-2" variant="custom-green" @click="showLoginModal">Вход в ЛК</b-btn>
        </template>
        <template v-else>
            <b-navbar-nav class="align-items-end ml-auto">
                <b-nav-item-dropdown right class="demo-navbar-user fs-14">
                    <template #button-content>
                        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle" style="max-width: 300px">
                            <img :src="`/images/user.png`" alt class="d-block ui-w-30 rounded-circle" />
                            <span class="px-1 mr-lg-2 ml-2 ml-lg-0 grey-color text-right text-truncate">
                                {{ $store.state.user.name }}<br />
                                {{ $store.state.user.organization_name }}
                            </span>
                        </span>
                    </template>

                    <b-dd-item :to="{ name: 'Cabinet' }" :active-class="''" class="fs-14 text-right text-uppercase" link-class="grey-color">Личный кабинет</b-dd-item>
                    <b-dd-item @click="logout" class="fs-14 text-right text-uppercase" link-class="grey-color">Выйти</b-dd-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </template>
    </div>
</template>

<script>
export default {
    name: 'NavbarUserMenu',
    methods: {
        showLoginModal() {
            this.$emit('login');
        },
        showRegistrationModal() {
            this.$emit('register');
        },
        logout() {
            this.$emit('logout');
        }
    }
};
</script>
