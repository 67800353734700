var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sign-contract-annex-dispute-popup-controller", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (scadpc) {
          return [
            _c("on-off", {
              attrs: {
                options: {
                  id: "sign-contract-annex-dispute-popup",
                  onEsc: false,
                  onBody: false,
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (onoff) {
                      return [
                        onoff.ison
                          ? _c("div", { staticClass: "atmo-popup-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "atmo-popup-content atmo-save-purchase-popup",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "atmo-button--small atmo-close",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return onoff.off.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "atmo-icon-close",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "header",
                                    [
                                      _c("h5", [
                                        _vm._v(
                                          "Подписать и отправить протокол разногласий"
                                        ),
                                      ]),
                                      _c("tab-menu-controller", {
                                        attrs: {
                                          tabmenuid: "sign-contract-tabmenu",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (tmc) {
                                                return [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "atmo-tabs-menu-universal",
                                                    },
                                                    [
                                                      _c("li", [
                                                        _c(
                                                          "button",
                                                          {
                                                            class: {
                                                              "atmo-active":
                                                                tmc.isSelected(
                                                                  1
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return tmc.select(
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Информация о документе"
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _c(
                                                          "button",
                                                          {
                                                            class: {
                                                              "atmo-active":
                                                                tmc.isSelected(
                                                                  2
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return tmc.select(
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "XML-представление данных"
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("tab-menu-tabs-controller", {
                                    attrs: {
                                      tabmenuid: "sign-contract-tabmenu",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (tmtc) {
                                            return [
                                              _c(
                                                "main",
                                                {
                                                  staticClass:
                                                    "atmo-tabs-container",
                                                },
                                                [
                                                  tmtc.isSelected(1)
                                                    ? _c(
                                                        "TabDisputeInfoPreview.view",
                                                        {
                                                          attrs: {
                                                            controller: scadpc,
                                                            certificate:
                                                              scadpc.currentParsedCertificate,
                                                            attachment:
                                                              scadpc.custom
                                                                .attachment,
                                                            comment:
                                                              scadpc.custom
                                                                .comment,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  tmtc.isSelected(2)
                                                    ? _c("TabXMLPreview.view", {
                                                        attrs: {
                                                          xml: scadpc.xml,
                                                          certificate:
                                                            scadpc.currentParsedCertificate,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c(
                                    "footer",
                                    [
                                      scadpc.hasCertificatesLoaded
                                        ? _c("CertificateInfo.view", {
                                            attrs: {
                                              certificate:
                                                scadpc.currentParsedCertificate,
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "ul",
                                        { staticClass: "atmo-buttons" },
                                        [
                                          _c("li", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "atmo-button-inverse atmo-dimmed",
                                                on: { click: onoff.off },
                                              },
                                              [_vm._v("Отмена")]
                                            ),
                                          ]),
                                          !scadpc.isDataSigned &&
                                          scadpc.hasCertificatesLoaded &&
                                          scadpc.currentParsedCertificate
                                            ? _c("li", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "atmo-button",
                                                    on: {
                                                      click: scadpc.signData,
                                                    },
                                                  },
                                                  [_vm._v("Подписать")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "atmo-spinner",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          scadpc.isDataSigned
                                            ? _c("li", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "atmo-button",
                                                    on: {
                                                      click:
                                                        scadpc.signAnnexDispute,
                                                    },
                                                  },
                                                  [_vm._v("Отправить")]
                                                ),
                                                _vm._v("  "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "atmo-spinner",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }