var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
    [
      _c("li", [
        _c("dt", [_vm._v("ИНН:")]),
        _c("dd", { domProps: { textContent: _vm._s(_vm.organization.inn) } }),
      ]),
      _c("li", [
        _c("dt", [_vm._v("Тип:")]),
        _c("dd", [
          _vm.organization.type === "legal"
            ? _c("span", [_vm._v("Организация")])
            : _vm._e(),
          _vm.organization.type === "individual"
            ? _c("span", [_vm._v("ИП")])
            : _vm._e(),
        ]),
      ]),
      _c("li", [
        _c("dt", [_vm._v("Полное наименование:")]),
        _c("dd", {
          domProps: { textContent: _vm._s(_vm.organization.title_full) },
        }),
      ]),
      _c("li", [
        _c("dt", [_vm._v("Адрес:")]),
        _c("dd", {
          domProps: { textContent: _vm._s(_vm.organization.address) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }