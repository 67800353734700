<template>
    <section class="atmo-content-group" v-if="this.$props.purchaseitem">
        <h6>Условия поставки</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Адреса для доставки товаров/выполнения работ/оказания услуг:</dt>
                <dd v-if="this.$props.purchaseitem.delivery_addresses && this.$props.purchaseitem.delivery_addresses.length > 0">
                    <ul>
                        <li v-for="(address, index) in this.$props.purchaseitem.delivery_addresses" v-bind:key="index">
                            <span v-text="index + 1"></span>.&nbsp;
                            <span v-text="address"></span>
                        </li>
                    </ul>
                </dd>
                <dd v-else>Нет</dd>
            </li>
            <li>
                <dt>График поставки товаров (выполнения работ, оказания услуг):</dt>
                <dd v-if="this.$props.purchaseitem.delivery_schedule" v-text="this.$props.purchaseitem.delivery_schedule"></dd>
                <dd v-else>Нет</dd>
            </li>
            <li>
                <dt>Спецификации:</dt>
                <dd>
                    <ul>
                        <li v-for="(deliverable, index) in this.$props.purchaseitem.deliverables" v-bind:key="index">
                            <span v-text="index + 1"></span>.&nbsp;
                            <span v-text="deliverable.title"></span>
                        </li>
                    </ul>
                </dd>
            </li>
            <li>
                <dt>Документы:</dt>
                <dd>
                    <ul v-if="this.$props.purchaseitem.contract && this.$props.purchaseitem.contract.attachments.main.length > 0" class="atmo-list">
                        <li>
                            <button v-on:click="$props.handlers.downloadFileAttachment($props.purchaseitem.contract.actualMainAttachment())" class="atmo-button-link" v-text="this.$props.purchaseitem.contract.actualMainAttachment().name"></button>
                        </li>
                    </ul>
                </dd>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        purchaseitem: { required: true },
        handlers: { required: true, type: Object }
    }
};
</script>
