'use strict';

import state from './state';
import getters from './getters';
// import actions from './actions';
import mutations from './mutations';

const ApplicationPopupsStoreModule = {
    namespaced: true,

    state: state,
    getters: getters,
    // actions: actions,
    mutations: mutations
};

export default ApplicationPopupsStoreModule;
