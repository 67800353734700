'use strict';

import HTTPRESTAPIFacade from '@core/js/ddriven/application/ports/adapters/http/outgoing/HTTPRESTAPIFacade';
import { AxiosResponse } from 'axios';
import { TOrganizationTypes } from '@core/js/ddriven/domain/model/misc/organization-reliability-report/OrganizationReliabilityReportOrganization.valueobject';

export interface DadataAPIConfig {
    count?: number;
    locations_boost?: DadataLocationsBoost[];
}

export interface DadataLocationsBoost {
    kladr_id: string;
}

export default class DadataAPIFacade {
    private _http: HTTPRESTAPIFacade;

    constructor(apiURL: undefined | string, token: string, customHeaders?: Record<string, string | undefined>) {
        let headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + token
        };

        headers = { ...headers, ...(customHeaders || {}) };

        this._http = new HTTPRESTAPIFacade(apiURL, headers);
    }

    public async iplocate(): Promise<AxiosResponse> {
        const path = 'iplocate/address';

        return await this._http.call('GET', path);
    }

    public async addresses(query: string, config?: DadataAPIConfig): Promise<AxiosResponse> {
        const path = '/suggest/address';
        const payload = { ...{ query: query }, ...(config || {}) };

        return await this._http.call('POST', path, payload);
    }

    public async findByInn(inn: string, organizationType: TOrganizationTypes): Promise<AxiosResponse> {
        const path = '/findById/party';
        const payload = { query: inn, type: organizationType.toUpperCase(), branch_type: 'MAIN' };

        return await this._http.call('POST', path, payload);
    }
}
