var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-overlay-container" },
    [
      _c("SupplierDepositReplenishPopupForm.view"),
      _c("SignatureViewPopup.view"),
      _c("DeliverablesSelectorPopup.view"),
      _c("SavePurchasePopup.view"),
      _c("SignContractPopup.view"),
      _c("ConfirmContractFulfillmentAttachmentRemovePopup.view"),
      _c("SignContractDisputeProtocolPopup.view"),
      _c("ConfirmContractAnnexDeliverableDuplicateRemovePopup.view"),
      _c("SignContractAnnexPopup.view"),
      _c("SignContractAnnexDisputePopup.view"),
      _c("KBKLimitSelectorPopup.view"),
      _c("ConfirmProtocolSummarizationPopup.view"),
      _c("DeclineProposalPopup.view"),
      _c("GlobalLoadingSpinner.view"),
      _c("SignOrganizationReliabilityReportDownloadPopup.view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }