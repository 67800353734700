var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$props.purchaseitem
    ? _c("section", { staticClass: "atmo-content-group" }, [
        _c("h6", [_vm._v("Предмет контракта")]),
        _c(
          "ul",
          {
            staticClass: "atmo-content-block-2-columns atmo-no-item-separator",
          },
          [
            _c("li", [
              _c("dt", [_vm._v("Предмет закупки:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.purchaseitem.description),
                },
              }),
            ]),
            this.$props.purchaseitem.winnerProposal &&
            this.$props.purchaseitem.winnerProposal.delivery_cost
              ? _c("li", [
                  _c("dt", [_vm._v("Стоимость доставки (руб.):")]),
                  _c("dd", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatnumber")(
                          this.$props.purchaseitem.winnerProposal.delivery_cost
                        )
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            this.$props.purchaseitem.winnerProposal &&
            this.$props.purchaseitem.winnerProposal.total_price
              ? _c("li", [
                  _c("dt", [_vm._v("Цена контракта, руб:")]),
                  _c("dd", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatnumber")(
                          this.$props.purchaseitem.winnerProposal.total_price
                        )
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }