<template>
    <section class="atmo-content-group" v-if="this.$props.certificate">
        <h6>Сертификат</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Владелец:</dt>
                <dd v-text="this.$props.certificate.subject ?? this.$props.certificate.subjectName"></dd>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        certificate: { required: true }
    }
};
</script>
