var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group" },
    [
      _vm._t("default"),
      _vm.$props.controller.islistfull
        ? _c("p", [
            _vm._v("Добавлено максимально возможное количество документов: "),
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.$props.controller.$props.maxfilesallowed
                ),
              },
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "table-responsive" }, [
        _c(
          "table",
          {
            staticClass: "atmo-content-table atmo-editable-attachments",
            class: {
              "atmo-empty":
                _vm.$props.controller.isrequired &&
                _vm.$props.controller.isempty,
            },
          },
          [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(
                  _vm.$props.controller.list,
                  function (attachment, index) {
                    return _c("tr", { key: index }, [
                      _c("td", {
                        domProps: { textContent: _vm._s(index + 1) },
                      }),
                      _c("td", [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "var(--green-color)" },
                            attrs: {
                              href: _vm.$getAttachmentDownloadLink(
                                attachment.file_id
                              ),
                            },
                          },
                          [_vm._v(_vm._s(attachment.name))]
                        ),
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("action-button-small", {
                            attrs: {
                              icon: "trash",
                              title: "Удалить файл из списка",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$props.controller.remove(attachment)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  }
                ),
                _vm.$props.controller.isempty
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "atmo-centered",
                          attrs: { colspan: "3" },
                        },
                        [_vm._v("Не добавлено документов")]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm.$props.controller.isrequired && _vm.$props.controller.isempty
        ? _c("p", { staticClass: "atmo-error" }, [_vm._v("Добавьте документы")])
        : _vm._e(),
      _vm._t("aux"),
      _c(
        "ul",
        { staticClass: "atmo-buttons" },
        [
          !_vm.$props.controller.islistfull
            ? _c("li", [
                _c(
                  "label",
                  {
                    staticClass: "atmo-button",
                    attrs: { disabled: _vm.$props.controller.is_waiting },
                  },
                  [
                    _c("span", [_vm._v("Добавить")]),
                    _c("input", {
                      attrs: {
                        type: "file",
                        accept: ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx",
                      },
                      on: { change: _vm.$props.controller.add },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._t("controls"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№")]),
        _c("th", [_vm._v("Наименование")]),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }