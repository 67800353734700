<template>
    <div>
        <p>Укажите, какими документами участник закупки должен подтвердить соответствие указанным требованиям:</p>

        <div class="table-responsive">
            <table v-bind:class="{ 'atmo-empty': $props.controller.isListEmpty }" class="atmo-content-table atmo-supporting-documents">
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Описание документа</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(document, index) in $props.controller.list" v-bind:key="index">
                        <td v-text="index + 1"></td>
                        <td>
                            <span class="atmo-form__field atmo-inverse">
                                <!-- // NB: The ID is used by the viewmodel to set the input focus -->
                                <input v-bind:id="$props.controller.inputIdPrefix + index" v-model="document.description" type="text" required min="1" />
                                <p class="atmo-error">Заполните поле</p>
                            </span>
                        </td>
                        <td>
                            <button v-on:click.stop="$props.controller.remove(index)" class="atmo-button-icon" title="Удалить описание"><i class="atmo-icon-trash"></i></button>
                        </td>
                    </tr>

                    <tr v-if="$props.controller.isListEmpty">
                        <td colspan="3" class="atmo-centered">Не добавлено описаний документов</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer>
            <p v-if="$props.controller.isListEmpty" class="atmo-error">Добавьте описание документа</p>

            <button v-on:click.stop="$props.controller.add" class="atmo-button">Добавить описание документа</button>
        </footer>
    </div>
</template>

<script>
export default {
    props: {
        controller: { required: true, type: Object }
    }
};
</script>
