'use strict';

export default class StatusDictionaryItem {
    id: null | number | string;
    title: string;
    is_active: boolean;
    code?: null | number | string;
    value?: null | number | string | boolean;
    description?: null | string;

    constructor(dictionaryItemRawData: StatusDictionaryItem) {
        this.id = dictionaryItemRawData.id;
        this.title = dictionaryItemRawData.title;
        this.code = dictionaryItemRawData.code || null;
        this.value = dictionaryItemRawData.value !== undefined ? dictionaryItemRawData.value : null;
        this.description = dictionaryItemRawData.description || null;
        this.is_active = dictionaryItemRawData.is_active ?? true;
    }

    public static findById(id: number | null, dictionary: StatusDictionaryItem[]): StatusDictionaryItem | undefined {
        return dictionary.find((item: StatusDictionaryItem) => {
            return item.id === id;
        });
    }

    public static findByTitle(title: string | null, dictionary: StatusDictionaryItem[]): StatusDictionaryItem | undefined {
        return dictionary.find((item: StatusDictionaryItem) => {
            return item.title === title;
        });
    }
}
