var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("on-off", {
    attrs: { options: { onEsc: false, onBody: false } },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (onoff) {
          return [
            _c("td", { staticClass: "atmo-ctc-finyears-limits-dropdown" }, [
              _c(
                "button",
                {
                  staticClass: "atmo-button-icon",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return onoff.toggle.apply(null, arguments)
                    },
                  },
                },
                [
                  !onoff.ison
                    ? _c(
                        "span",
                        {
                          attrs: {
                            title:
                              "Показать список лимитов по годам финансирования",
                          },
                        },
                        [_c("i", { staticClass: "atmo-icon-show" })]
                      )
                    : _c("span", { attrs: { title: "Скрыть список" } }, [
                        _c("i", { staticClass: "atmo-icon-hide" }),
                      ]),
                ]
              ),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: onoff.ison,
                      expression: "onoff.ison",
                    },
                  ],
                  staticClass: "atmo-dropdown",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("ul", { staticClass: "atmo-details" }, [
                    _vm.$props.kbkitem.kosgu_code
                      ? _c("li", [
                          _c("dt", [_vm._v("Код КОСГУ: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.kosgu_code
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.source_funds
                      ? _c("li", [
                          _c("dt", [_vm._v("Бюджет - источник трансферта: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.source_funds
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.subsidy_code
                      ? _c("li", [
                          _c("dt", [_vm._v("Код субсидии: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.subsidy_code
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.invest_object
                      ? _c("li", [
                          _c("dt", [_vm._v("Объект: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.invest_object
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.direction_code
                      ? _c("li", [
                          _c("dt", [_vm._v("Код направления: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.direction_code
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.regional_project_result
                      ? _c("li", [
                          _c("dt", [_vm._v("Результат проекта: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.regional_project_result
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.additional_information_code
                      ? _c("li", [
                          _c("dt", [_vm._v("Код доп. информации: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.additional_information_code
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.funds_type_code
                      ? _c("li", [
                          _c("dt", [_vm._v("Код типа средств: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.funds_type_code
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.event_code
                      ? _c("li", [
                          _c("dt", [_vm._v("Код мероприятия: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.event_code
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.$props.kbkitem.eis_ident_no
                      ? _c("li", [
                          _c("dt", [_vm._v("ИКЗ: ")]),
                          _c("dd", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$props.kbkitem.eis_ident_no
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "atmo-right" }, [
                          _vm._v("Год финансирования"),
                        ]),
                        _c("th", { staticClass: "atmo-right" }, [
                          _vm._v("Доступный лимит, руб."),
                        ]),
                        _c("th", { staticClass: "atmo-right" }, [
                          _vm._v("Общий лимит, руб."),
                        ]),
                        _c("th", [_vm._v(" ")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      [
                        _vm._l(
                          _vm.$props.kbkitem.limits,
                          function (limit, index) {
                            return [
                              limit.amount_total !== 0
                                ? _c("tr", { key: index }, [
                                    _c("td", {
                                      staticClass: "atmo-right",
                                      domProps: {
                                        textContent: _vm._s(limit.year),
                                      },
                                    }),
                                    _c("td", { staticClass: "atmo-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatnumber")(
                                            limit.amount_available
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", { staticClass: "atmo-right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatnumber")(
                                            limit.amount_total
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "atmo-button-icon",
                                          attrs: { title: "Выбрать лимит" },
                                          on: {
                                            click: function ($event) {
                                              _vm
                                                .kbkspc()
                                                .selected(
                                                  _vm.$props.index,
                                                  index
                                                )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "atmo-icon-add-to-list",
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          }
                        ),
                        _vm.isLimitsListEmpty
                          ? _c("tr", [
                              _c("td", { attrs: { colspan: "4" } }, [
                                _vm._v("Нет доступных лимитов."),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }