var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-contract-annexes" },
    },
    [
      _c("h3", [_vm._v("Дополнительные соглашения")]),
      _vm._l(this.$props.annexes, function (annex, index) {
        return _c("ContractAnnexView.view", {
          key: index,
          attrs: { index: index, pivc: _vm.pivc, annex: annex },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }