<template>
    <section class="atmo-content-group">
        <h6>Дополнительное соглашение № <span v-text="this.$props.annex.sequence_number"></span></h6>

        <ul class="atmo-content-block-2-columns">
            <li>
                <dt>Дата заключения дополнительного соглашения:</dt>
                <dd>{{ this.$props.annex.date | formatdate('onlyDate') }}</dd>
            </li>

            <li>
                <dt>Документы:</dt>
                <dd>
                    <ul class="atmo-list">
                        <!-- <li v-for="(attachment, index) in this.$props.annex.attachments" v-bind:key="index">
                            <button 
                                v-on:click="$props.handlers.downloadFileAttachment(attachment)"
                                class="atmo-button-link" v-text="attachment.name">
                            </button>
                        </li> -->
                        <li v-if="this.$props.annex.hasAttachments()">
                            <button v-on:click="$props.handlers.downloadFileAttachment($props.annex.actualAttachment())" class="atmo-button-link" v-text="$props.annex.actualAttachment().name"></button>
                        </li>
                    </ul>
                </dd>
            </li>

            <li>
                <dt>Спецификация:</dt>
                <dd>
                    <table class="atmo-table-mini">
                        <tbody>
                            <tr v-for="(deliverable, index) in this.$props.annex.deliverables" v-bind:key="index">
                                <td>{{ index + 1 }}.</td>
                                <td v-text="deliverable.title"></td>
                                <td v-text="deliverable.quantity"></td>
                                <td v-text="deliverable.okei_symbolic_code"></td>
                                <td v-text="'*'"></td>
                                <td>{{ deliverable.price_per_unit | formatnumber }}</td>
                                <td v-text="'руб.'"></td>
                                <td v-text="'='"></td>
                                <td>{{ deliverable.price_total | formatnumber }}</td>
                                <td v-text="'руб.'"></td>
                            </tr>
                        </tbody>
                    </table>
                </dd>
            </li>

            <li>
                <dt>Общая стоимость, руб.:</dt>
                <dd>{{ this.$props.annex.price_total | formatnumber }}</dd>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        annex: { required: true, type: Object },
        handlers: { required: true, type: Object }
    }
};
</script>
