'use strict';

import { IState } from './state';

import UserVO, { IRawUserData } from '@core/js/ddriven/domain/model/users/User.valueobject';
import { IRawOrganizationData } from '@core/js/ddriven/domain/model/users/Organization.valueobject';

interface ISetUserData {
    user: IRawUserData;
    organization: IRawOrganizationData;
}

const mutations = {
    setUser: function (state: IState, userData: ISetUserData): void {
        state.user = new UserVO(userData.user, userData.organization);
    },
    setMissingDepositAmount: function name(state: IState, amount: string | number) {
        state.user?.organization.setMissingDepositAmount(amount);
    }
};

export default mutations;
