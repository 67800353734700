<template>
    <ul class="atmo-content-block-2-columns atmo-no-item-separator">
        <li class="atmo-form__field atmo-inverse">
            <dt><label for="federal-law-a31s1p1_details-description">Укажите, каким требованиям должен соответствовать участник закупки:</label></dt>
            <dd>
                <input v-model="$props.requirementdetails.description" type="text" required name="federal-law-a31s1p1_details-description" id="federal-law-a31s1p1_details-description" class="atmo-icon-checkmark" />
            </dd>
        </li>

        <supplier-requirement-supporting-documents-controller v-slot:default="srsdc" v-bind:initiallist="$props.requirementdetails.supporting_documents">
            <SRDA31s1p1SupportingDocuments.view v-bind:controller="srsdc" />
        </supplier-requirement-supporting-documents-controller>
    </ul>
</template>

<script>
import SRDA31s1p1SupportingDocumentsView from './SRDA31s1p1SupportingDocuments.view.vue';
import SupplierRequirementSupportingDocumentsController from '@core/js/viewmodels/purchases/item/update/SupplierRequirementSupportingDocumentsController.viewmodel.ts';

export default {
    components: {
        'SRDA31s1p1SupportingDocuments.view': SRDA31s1p1SupportingDocumentsView,
        'supplier-requirement-supporting-documents-controller': SupplierRequirementSupportingDocumentsController
    },
    props: {
        requirementdetails: { required: true, type: Object }
    }
};
</script>
