var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "atmo-page-banner-wrapper atmo-slim" }, [
    _c("div", { staticClass: "atmo-container-fixed" }, [
      _c("h2", [_vm._v("Объявление о закупочной сессии")]),
      _c(
        "ul",
        {
          staticClass:
            "atmo-content-block-2-columns atmo-no-item-separator atmo-in-banner",
        },
        [
          _c("li", [
            _c("dt", [_vm._v("Организация:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(this.$props.user.organization.title),
              },
            }),
          ]),
          _c("li", [
            _c("dt", [_vm._v("Сокращенное наименование:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(this.$props.user.organization.title_brief),
              },
            }),
          ]),
          _c("li", [
            _c("dt", [_vm._v("ИНН:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(this.$props.user.organization.inn),
              },
            }),
          ]),
          this.$props.user.organization.kpp
            ? _c("li", [
                _c("dt", [_vm._v("КПП:")]),
                _c("dd", {
                  domProps: {
                    textContent: _vm._s(this.$props.user.organization.kpp),
                  },
                }),
              ])
            : _vm._e(),
          this.$props.user.organization.ogrn
            ? _c("li", [
                _c("dt", [_vm._v("ОГРН:")]),
                _c("dd", {
                  domProps: {
                    textContent: _vm._s(this.$props.user.organization.ogrn),
                  },
                }),
              ])
            : _vm._e(),
          _c("li", [
            _c("dt", [_vm._v("Адрес:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(this.$props.user.organization.address),
              },
            }),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }