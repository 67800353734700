'use strict';

import notices from './notices/index';
import contracts from './contracts/index';

const GroupGlobalStoreModule = {
    namespaced: true,

    modules: {
        notices,
        contracts
    }
};

export default GroupGlobalStoreModule;
