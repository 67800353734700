'use strict';

import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import KBKLimitsEditableController from '@core/js/viewmodels/purchases/item/update/kbk-limits/KBKLimitsEditableController.viewmodel';
import KBKLimitsSpecificationItemsEditableCollection from '@core/js/ddriven/domain/model/purchases/update/KBKLimitsSpecificationItemsEditable.collection';
import ContractItemDetailsVO from '@core/js/ddriven/domain/model/contracts/ContractItemDetails.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import KBKLimitsUpdateRequestVO from '@core/js/ddriven/application/http/requests/purchases/kbk-limits/KBKLimitsUpdateRequest.valueobject';
import ToastDataVO from '@core/js/ddriven/application/services/uitoast/ToastData.valueobject';

interface IData {
    have_limits_changed: boolean;
    limits: KBKLimitsSpecificationItemsEditableCollection;
}

@Component
export default class KBKLimitsAutonomousUpdateWidgetController extends BaseViewModel {
    private _initialLimits?: KBKLimitsSpecificationItemsEditableCollection;

    constructor() {
        super();

        this.name = 'KBKLimitsAutonomousUpdateWidgetController';
        this._initialLimits = undefined;
    }

    @Prop({ required: true, type: Number }) readonly purchaseid!: number;
    @Prop({ required: true, type: Object }) readonly entity!: ContractItemDetailsVO;
    @Prop({ required: true, type: Object }) readonly onoff!: { off: Function };

    created() {
        this.$root.$on(KBKLimitsEditableController.eventLimitsUpdated, this.processLimitsUpdate);
        this._initialLimits = this.$props.entity.kbk_limits.duplicate();
    }

    data(): IData {
        return {
            have_limits_changed: false,
            // NB: Get the detached copy of limits from purchaseitem.contract.limits to be changed in the widget.
            limits: this.$props.entity.kbk_limits.duplicate()
        };
    }

    /**
     * Computed
     */
    get has_limits_errors() {
        return this.$data.limits.hasErrors() || this.$props.entity.deliverables_total_amount !== this.$data.limits.distributed_amount_total;
    }

    get deliverables_total_amount(): number {
        return this.$props.entity.deliverables_total_amount;
    }

    /**
     * Methods
     */
    public processLimitsUpdate(evt: { kbkLimits: KBKLimitsSpecificationItemsEditableCollection }) {
        this.$data.have_limits_changed = !this._initialLimits!.isSameAs(evt.kbkLimits);
    }

    public async saveLimits() {
        const response = await ApplicationServiceLocator.get('api').entities.updatePurchaseLimits(this.$props.purchaseid, new KBKLimitsUpdateRequestVO(this.$data.limits.items));
        response.isSuccess && ApplicationServiceLocator.get('uitoasts').show(new ToastDataVO({ message: 'Лимиты изменены.' }));

        response.isSuccess && this.$props.onoff.off();
        setTimeout(() => {
            response.isSuccess && window.location.reload();
        }, 500);
    }

    public close() {
        // Reset the limits at its state on purchaseitem.contract.limits.
        this.$data.limits = this.$props.entity.kbk_limits.duplicate();
    }
}
