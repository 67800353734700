<template>
    <standard-pagination-controller v-slot:default="spc" v-bind:pagination="pagination" v-on:pagination:pageupdate="pageupdatehandler">
        <div class="atmo-standard-pagination">
            <ul v-show="spc.isPaginationVisible" class="atmo-pages-list">
                <li v-show="spc.isLeftControlsVisible">
                    <button v-on:click="spc.page(spc.first)"><span>«</span></button>
                </li>
                <li v-show="spc.isLeftControlsVisible">
                    <button v-on:click="spc.page(spc.prev)"><span>‹</span></button>
                </li>

                <li v-for="page in spc.pages" v-bind:key="page">
                    <button v-on:click="spc.page(page)" v-bind:disabled="spc.isActive(page)"><span v-text="page"></span></button>
                </li>

                <li v-show="spc.isRightControlsVisible">
                    <button v-on:click="spc.page(spc.next)"><span>›</span></button>
                </li>
                <li v-show="spc.isRightControlsVisible">
                    <button v-on:click="spc.page(spc.last)"><span>»</span></button>
                </li>
            </ul>
        </div>
    </standard-pagination-controller>
</template>

<script>
import StandardPaginationController from '@core/js/viewmodels/common/pagination/StandardPaginationController.viewmodel.ts';

export default {
    props: {
        pagination: { required: true },
        pageupdatehandler: { required: true, type: Function }
    },
    components: {
        'standard-pagination-controller': StandardPaginationController
    }
};
</script>
