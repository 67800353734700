var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "atmo-tab-content" },
    [
      _c("CustomerInfo.view", {
        attrs: { customer: this.$props.purchaseitem.customer },
      }),
      _c("SupplierInfo.view", {
        attrs: { supplier: this.$props.purchaseitem.supplier },
      }),
      _c("PurchaseTerms.view", {
        attrs: {
          purchaseitem: this.$props.purchaseitem,
          handlers: this.$props.handlers,
        },
      }),
      !this.$props.controller.isSupplier
        ? _c("SupplierRequirements.view", {
            attrs: {
              requirements: this.$props.purchaseitem.supplier_requirements,
            },
          })
        : _vm._e(),
      _c("SupplyTerms.view", {
        attrs: {
          purchaseitem: this.$props.purchaseitem,
          handlers: this.$props.handlers,
        },
      }),
      _c("ContractDetails.view", {
        attrs: { purchaseitem: this.$props.purchaseitem },
      }),
      this.$props.controller.isSupplier
        ? _c("SupplierRequirementsConfirmation.view", {
            attrs: {
              controller: this.$props.controller,
              required: this.$props.purchaseitem.supplier_requirements,
              confirmed: this.$props.controller.supplier_requirements_confirmed,
            },
          })
        : _vm._e(),
      this.$props.certificate
        ? _c("CertificateInfo.view", {
            attrs: { certificate: this.$props.certificate },
          })
        : _vm._e(),
      this.$props.controller.hasErrors && this.$props.controller.isSupplier
        ? _c("p", { staticClass: "atmo-error" }, [
            _vm._v(
              'Невозможно подписать и сохранить договор. Пожалуйста, заполните раздел "Требования к поставщикам".'
            ),
          ])
        : _vm._e(),
      _c("ul", { staticClass: "atmo-buttons" }, [
        _c("li", [
          _c(
            "button",
            {
              staticClass: "atmo-button-inverse atmo-dimmed",
              on: { click: _vm.onoff.off },
            },
            [_vm._v("Отмена")]
          ),
        ]),
        !this.$props.controller.isDataSigned && this.$props.certificate
          ? _c(
              "li",
              [
                _c("ProgressIndicatingButton.component", {
                  attrs: { clickhandler: this.$props.controller.signData },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (slotProps) {
                          return [
                            _c(
                              "button",
                              {
                                staticClass: "atmo-button",
                                attrs: {
                                  disabled:
                                    slotProps.component.isprogressing ||
                                    !_vm.$props.controller
                                      .isSignContractAllowed,
                                },
                                on: { click: slotProps.component.handler },
                              },
                              [_vm._v("Подписать")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3391988962
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        this.$props.certificate &&
        this.$props.controller.isDataSigned &&
        this.$props.controller.isSignContractAllowed
          ? _c("li", [
              _c(
                "button",
                {
                  staticClass: "atmo-button",
                  on: { click: this.$props.controller.signContract },
                },
                [_vm._v("Отправить")]
              ),
              _vm._m(0),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "atmo-spinner" }, [
      _c("img", {
        attrs: {
          src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }