<template>
    <h2 class="grey-color font-weight-bold" :class="marginClass">
        <slot></slot>
    </h2>
</template>

<script>
export default {
    name: 'text-header',
    props: {
        marginClass: {
            type: String,
            default: 'my-3'
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
