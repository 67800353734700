<template>
    <div>
        <div v-if="isDevEnv()" class="text-center" style="background-color: #f8c8c3; padding: 0 5px">
            <b-container class="d-flex justify-content-between">
                <strong
                    >ВНИМАНИЕ!!! Это тестовая площадка {{ theme.variables.view.application_name_short }}. Основной адрес {{ theme.variables.view.application_name_short }} - <a v-bind:href="theme.variables.view.production_url">{{ theme.variables.view.production_url }}</a></strong
                >
                <span class="grey-color fs-12">v{{ theme.version.full }}</span>
            </b-container>
        </div>
        <b-container class="top_info-line" fluid>
            <b-container style="max-height: 100px">
                <b-row>
                    <b-col>
                        <div class="w-100" style="height: 36px; display: flex; align-items: center">
                            <div class="d-flex justify-content-between align-items-center w-100">
                                <div class="d-flex times">
                                    <div class="today" style="margin-right: 42px">
                                        <span class="grey-color fs-12">{{ localDate }} ({{ weekday }})</span>
                                    </div>
                                    <div class="local_time mr-4">
                                        <img alt="clock" src="/images/clock.svg" />
                                        <span class="ml-2 grey-color fs-12">{{ theme.variables.view.city }} &mdash; {{ localTime }}</span>
                                    </div>
                                    <div class="moscow_time ml-1">
                                        <img alt="clock" src="/images/clock.svg" />
                                        <span class="ml-2 grey-color fs-12">Москва &mdash; {{ moscowTime }}</span>
                                    </div>
                                </div>
                                <div class="d-flex ml-auto center-768">
                                    <div class="ml-3">
                                        <a :href="$links.telegram_channel" title="Информационный канал ИС АТМО" target="_blank"><b-icon icon="telegram" style="color: #229ed9" /></a>
                                    </div>
                                    <div class="ml-3">
                                        <!-- Add .atmo-override-link-off class to switch off link in themes  -->
                                        <a :href="$links.legal_documents" class="atmo-override-link-off grey-color fs-12 fw-600 text-nowrap cursor-pointer" target="_blank">ПРАВОВЫЕ ДОКУМЕНТЫ</a>
                                    </div>
                                    <div class="ml-3">
                                        <!-- Add .atmo-override-link-off class to switch off link in themes  -->
                                        <a :href="$links.info_materials" class="atmo-override-link-off grey-color fs-12 fw-600 text-nowrap cursor-pointer" target="_blank">ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container class="nav_line" fluid>
            <b-container class="py-2">
                <b-row class="d-flex flex-nowrap">
                    <b-col cols="auto">
                        <router-link style="border: none !important" to="/">
                            <ThemeLogo></ThemeLogo>
                        </router-link>
                    </b-col>
                    <b-col class="ml-auto" cols="auto">
                        <b-row class="h-100 fast_links">
                            <b-col class="d-flex align-items-center desktop_link right_line">
                                <a href="#">
                                    <b-dropdown variant="link" toggle-class="text-decoration-none mx-0 px-0 fs-14 font-weight-bold" no-caret>
                                        <template #button-content><div class="grey-color">44-ФЗ</div></template>
                                        <b-dropdown-item :to="{ name: 'notices.fl44', query: { status: 'accepting' } }" link-class="fs-14 grey-color">Извещения</b-dropdown-item>
                                        <b-dropdown-item :link-class="$route.matched[0]?.path === '/contracts/fl44' ? 'fs-14 grey-color router-link-active' : 'fs-14 grey-color'" :to="{ name: 'ContractsList', params: { contract: 'all' } }">Контракты</b-dropdown-item>
                                    </b-dropdown>
                                </a>
                            </b-col>
                            <b-col class="d-flex align-items-center desktop_link right_line">
                                <router-link class="text-nowrap" :class="$route.matched[0]?.path === '/notices/fl223' && 'router-link-active'" :to="{ name: 'Fl223NoticesList', params: { status: 'accepting' } }">223-ФЗ</router-link>
                            </b-col>
                            <b-col class="d-flex align-items-center desktop_link right_line">
                                <router-link class="text-nowrap" :class="$route.matched[0]?.path === '/notices/commercial' && 'router-link-active'" :to="{ name: 'CommercialNoticesList', params: { status: 'accepting' } }">КОММЕРЧЕСКИЕ</router-link>
                            </b-col>

                            <b-col class="d-flex align-items-center desktop_link right_line">
                                <router-link class="text-nowrap" to="/quotation-requests">ЗАПРОСЫ КП</router-link>
                            </b-col>
                            <template>
                                <b-col class="d-flex align-items-center desktop_link" v-if="$links.marketplace_link">
                                    <a :href="$links.marketplace_link" target="_blank" class="text-center">Башмаркет</a>
                                </b-col>
                                <b-col class="d-flex align-items-center desktop_link" v-else>
                                    <router-link :to="{ name: 'ImportSubstitutionRequestsList', params: { path: 'open' } }" class="text-center">Импортозамещение</router-link>
                                </b-col>
                            </template>

                            <b-col class="d-flex justify-content-center align-items-center">
                                <i :class="{ 'fas fa-times': menuVisible, 'fas fa-bars': !menuVisible }" aria-controls="full-menu" class="cursor-pointer grey-color my-auto" style="width: 15px" @click="menuVisible = !menuVisible">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </i>
                            </b-col>
                            <navbar-notifications-dropdown v-if="$store.state.token" />
                            <NavbarUserMenu @login="showLoginModal" @logout="logout" @register="showRegistrationModal" />
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <Login :show-modal="visibleLoginModal" @close="onCloseLoginModal" />
        <b-collapse id="full-menu" v-model="menuVisible" class="full-screen">
            <div>
                <b-container v-if="windowWidth >= 768" class="top_info-line py-4" fluid>
                    <full-menu v-on:hide-menu="menuVisible = false"></full-menu>
                </b-container>
                <b-container v-if="windowWidth < 768" class="top_info-line py-4" fluid>
                    <mobile-menu v-on:hide-menu="menuVisible = false" v-on:show-modal="visibleRegistrationModal = true" @gotologin="showLoginModal" @logout="logout"></mobile-menu>
                </b-container>
            </div>
        </b-collapse>
        <div v-if="menuVisible" class="backdrop" @click="toggleFullMenu"></div>
        <RegisterModal :show-modal="visibleRegistrationModal" @close="visibleRegistrationModal = false" />
        <template v-if="visibleVerificationEmailModal">
            <VerificationEmailModal :email="userEmail" :visible="visibleVerificationEmailModal" @hidden="hideVerificationModal" />
        </template>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import 'moment/locale/ru';

import { mapGetters, mapState } from 'vuex';
import ThemeLogo from 'themes/views/layouts/logo/ThemeLogo.view.vue';
import Config from '@core/js/ddriven/application/config/Config';
import RegisterModal from '@/components/auth/register-modal.vue';
import Login from '@/components/auth/Login.vue';
import VerificationEmailModal from '@/components/elements/VerificationEmailModal.vue';
import NavbarUserMenu from '@/layout/NavbarUserMenu.vue';
import NavbarNotificationsDropdown from "@/layout/navbar-notifications-dropdown.vue";

const FullMenu = () => import(/* webpackChunkName: "main" */ './elements/FullLandingMenu');
const MobileMenu = () => import(/* webpackChunkName: "main" */ './elements/MobileMenu');

export default {
    components: {
        NavbarNotificationsDropdown,
        NavbarUserMenu,
        VerificationEmailModal,
        Login,
        RegisterModal,
        FullMenu,
        MobileMenu,
        ThemeLogo
    },
    name: 'AppLayoutNavbar',
    data() {
        return {
            form: {
                email: '',
                theme: '',
                text: ''
            },
            feedSucess: false,
            showFeedModal: false,
            menuVisible: false,
            ufaDate: null,
            ufaTime: null,
            weekday: null,
            moscowTime: null,
            windowWidth: null,
            readedDocumetation: false,
            visibleLoginModal: false,
            localDate: null,
            localTime: null,
            visibleRegistrationModal: false
        };
    },
    created() {
        this.windowWidth = window.innerWidth;
        this.setTime();
        setInterval(this.setTime, 1000);
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    props: {},
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                localStorage.setItem('need_reload', '/');
                setTimeout(() => localStorage.removeItem('need_reload'), 10);
                window.location.assign('/');
            });
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        showMessage(message) {
            this.pushToast({
                title: 'Уведомление',
                text: message,
                timer: 5000
            });
        },
        toggleFullMenu() {
            this.menuVisible = !this.menuVisible;
        },
        setTime() {
            let timeMsk = moment().tz('Europe/Moscow');
            let timeLocal = moment().tz(Config.get('theme.variables.timezone'));
            this.localDate = timeLocal.format('DD.MM.YYYY');
            this.localTime = timeLocal.format('HH:mm');
            this.moscowTime = timeMsk.format('HH:mm');
            this.weekday = timeMsk.format('dddd');
        },
        onCloseLoginModal() {
            this.visibleLoginModal = false;
        },
        showLoginModal() {
            this.visibleLoginModal = true;
            //this.$route.path.match('/auth/login') ? this.$router.go() : this.$router.push('/auth/login').catch(() => {});
        },
        showRegistrationModal() {
            this.visibleRegistrationModal = true;
        },
        hideVerificationModal() {
            this.visibleVerificationEmailModal = false;
        }
    },
    computed: {
        theme() {
            return Config.get('theme');
        },
        city() {
            return Config.get('theme.variables.view.city');
        },
        ...mapGetters({
            user: 'getUser'
        }),
        isCheckRules: function () {
            return this.readedDocumetation;
        },
        ...mapState({ showVerificationModal: 'showVerificationModal', userEmail: (state) => state.user?.email ?? '' }),
        visibleVerificationEmailModal: {
            get() {
                return typeof this.showVerificationModal === 'boolean' && this.showVerificationModal;
            },
            set(val) {
                this.$store.commit('set_show_verification_modal', val);
            }
        }
    },
    watch: {
        $route: function () {
            this.menuVisible = false;
            this.visibleRegistrationModal = false;
        }
    }
};
</script>
<style scoped>
i:hover {
    color: var(--green-color);
}

.center-768 a:hover {
    color: var(--green-color);
}

.fast_links div + div {
    margin-left: 10px;
}

.top_info-line {
    background-color: var(--greyBg-color);
}

.fast_links div > a {
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--grey-color);
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.right_line > a::after {
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    background-color: #f5f5f6;
    position: absolute;
    right: -20px;
    top: 0;
}

.right_line:last-child > a::after {
    content: none;
    display: none;
}

.nav_line {
    background-color: white;
    -webkit-box-shadow: 0 2px 5px -4px #aaa;
    -moz-box-shadow: 0 2px 5px -4px #aaa;
    box-shadow: 0 2px 5px -4px #aaa;
}

.full-screen {
    position: absolute;
    width: 100%;
}

.notifications {
    max-height: 90%;
}

.backdrop {
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0.8;
    -webkit-transition: opacity 0.25s linear;
    -o-transition: opacity 0.25s linear;
    transition: opacity 0.25s linear;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0);
    z-index: -1;
}

.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}

.router-link-active {
    color: #333333 !important;
    border-bottom: 2px solid #333 !important;
}

@media screen and (max-width: 1200px) {
    .desktop_link {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .times {
        display: none !important;
    }

    .center-768 {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        margin: 0 !important;
    }

    .fast_auth {
        display: none !important;
    }
}
</style>

<style>
.nav_line .fas span {
    display: block;
    width: 18px;
    height: 2px;
    background: var(--grey-color);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.nav_line .fa-bars span + span {
    margin-top: 3px;
}

.nav_line .fa-times span {
    -webkit-transform: rotate(-45deg) translateY(-1px);
    -moz-transform: rotate(-45deg) translateY(-1px);
    -ms-transform: rotate(-45deg) translateY(-1px);
    -o-transform: rotate(-45deg) translateY(-1px);
    transform: rotate(-45deg) translateY(-1px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}

.nav_line .fa-times span:nth-child(1) {
    display: none;
}

.nav_line .fa-times span:nth-child(2) {
    -webkit-transform: rotate(45deg) translateY(1px);
    -moz-transform: rotate(45deg) translateY(1px);
    -ms-transform: rotate(45deg) translateY(1px);
    -o-transform: rotate(45deg) translateY(1px);
    transform: rotate(45deg) translateY(1px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}
</style>
