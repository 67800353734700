<template>
    <div>
        <b-form-group label="Произошла ошибка при аутентификации" label-class="fs-18 fw-600 text-danger pl-3" class="fs-18 grey-color text-left pb-0">
            <b-container class="pl-3 pb-2">
                <p class="fw-600 my-0">Возможные причины ошибки:</p>
                <ul class="error-list">
                    <li>— У вас не установлено и не настроено программное обеспечение "КриптоПро CSP" версии 4.0 или выше</li>
                    <li>— Сертификат ключа проверки электронной подписи выдан Удостоверяющим центром Федерального казначейства.</li>
                    <li>— Срок действия сертификата истек.</li>
                    <li>— Сертификат ключа проверки электронной подписи находится в списке отозванных сертификатов.</li>
                    <li>— Вы нажали "отмена" при выборе сертификата.</li>
                </ul>
            </b-container>
            <b-container class="pl-3">
                <p class="fw-600 my-0">Техническая поддержка доступна по кнопке "Поддержка" (в правом нижнем углу).</p>
            </b-container>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: 'login-error'
};
</script>

<style scoped>
.error-list {
    list-style-type: none;
}
</style>
