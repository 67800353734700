'use strict';

import { Types as UserTypes } from '@core/js/ddriven/domain/model/users/User.valueobject';
import DisputeResolutionEventVO, { IRawDisagreementData, IRawDisputeData } from './DisputeResolutionEvent.valueobject';

export default class DisputeResolutionRoundVO {
    protocol: DisputeResolutionEventVO | null;
    reply: DisputeResolutionEventVO | null;

    constructor(data?: IRawDisagreementData) {
        this.protocol = data ? new DisputeResolutionEventVO(UserTypes.supplier, data) : null;
        this.reply = data ? new DisputeResolutionEventVO(UserTypes.customer, data) : null;
    }

    public isResolved(): boolean {
        return !!this.protocol && !!this.reply && this.protocol.isSigned() && this.reply.isSigned();
    }

    public static fromAnnexItem(data: IRawDisputeData): DisputeResolutionRoundVO {
        const interimData: IRawDisagreementData = {
            created_at: data.created_at,
            updated_at: data.updated_at,
            provider_organization_name: data.dispute_signature.organization_name,
            provider_comment: data.dispute_comment,
            providerX509certificate: {
                '0': data.dispute_signature.md5
            },
            providerX509certificateDate: data.dispute_signature.signing_datetime,
            provider_document: {
                id: data.dispute_file.id as string,
                name: data.dispute_file.name,
                type: data.dispute_file.type
            },
            customer_organization_name: data.reply_signature ? data.reply_signature.organization_name : null,
            customer_comment: data.reply_comment ?? null,
            customerX509certificate: data.reply_signature
                ? {
                      '0': data.reply_signature.md5
                  }
                : { '0': null },
            customerX509certificateDate: data.reply_signature ? data.reply_signature.signing_datetime : null,
            customer_document: {
                id: data.reply_file ? (data.reply_file.id as string) : null,
                name: data.reply_file ? data.reply_file.name : null,
                type: data.reply_file ? data.reply_file.type : null
            },
            customer_signature: data.reply_signature,
            provider_signature: data.dispute_signature
        };
        return new DisputeResolutionRoundVO(interimData);
    }
}
