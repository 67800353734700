'use strict';

import CommonRequest from '@/rearchitected/core/js/ddriven/application/http/requests/common/CommonRequest';
import ICommonRequest from '@core/js/ddriven/application/http/requests/common/interfaces/ICommonRequest';
import ContractRequestFilter, { IContractRequestFilter } from './ContractRequestFilter.valueobject';

export interface IContractRequest extends ICommonRequest {
    filter: IContractRequestFilter;
}

export default class ContractRequest extends CommonRequest implements ICommonRequest {
    [index: string]: unknown;

    filter: IContractRequestFilter;
    status: null | number;
    page: null | number;

    constructor(contractRequestData?: ICommonRequest) {
        super();

        this.status = null;

        this.filter = new ContractRequestFilter();

        CommonRequest.initializeFilter(this.filter, contractRequestData?.filter);

        const status = CommonRequest.actualOrDefault('status', null, contractRequestData);
        const page = CommonRequest.actualOrDefault('page', null, contractRequestData);

        this.status = CommonRequest.normalizeToNumber(status);
        this.page = CommonRequest.normalizeToNumber(page);
    }
}
