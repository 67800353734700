var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "atmo-container-fixed atmo-page-content" }, [
      _vm.purchases_map && _vm.purchases_map_url
        ? _c("iframe", {
            staticClass: "atmo-purchases-map",
            attrs: { src: _vm.purchases_map_url, title: "Карта закупок" },
          })
        : _c("p", [_vm._v("Карта не подключена в конфигурации.")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "atmo-page-banner-wrapper atmo-slim" },
      [
        _c("div", { staticClass: "atmo-container-fixed" }, [
          _c("h2", [_vm._v("Карта малых закупок республики Башкортостан")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }