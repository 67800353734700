<template>
    <table class="atmo-table-slim atmo-table-fixed">
        <tbody>
            <tr v-for="(document, index) in $props.documents" v-bind:key="index">
                <td style="max-width: 50%" v-bind:title="document.description">{{ document.description }}:&nbsp;</td>
                <td>
                    <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:initiallist="document.attachment ? [document.attachment] : []" v-bind:isrequired="true" v-bind:origin="'slim'" v-bind:maxfilesallowed="1" v-on:fileattachment:changed="$props.update">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="atmo-left">
                                        <span v-if="!efalc.isempty">
                                            <button v-on:click="efalc.downloadFileAttachment(efalc.list[0])" class="atmo-button-link" v-text="efalc.list[0].name" title="Скачать файл"></button>
                                        </span>
                                        <span v-else class="atmo-error">Прикрепите файл</span>
                                    </td>
                                    <td class="atmo-tc-button-icon atmo-center" title="Прикрепить файл">
                                        <label v-bind:disabled="efalc.islistfull || $props.is_disabled" class="atmo-button-icon">
                                            <span><i class="atmo-icon-attachment"></i></span>
                                            <input v-on:change="efalc.add($event, index)" type="file" accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx" />
                                        </label>
                                    </td>
                                    <td class="atmo-tc-button-icon atmo-center" title="Удалить файл">
                                        <button v-on:click="efalc.remove(efalc.list[0], index)" v-bind:disabled="efalc.isempty || $props.is_disabled" class="atmo-button-icon">
                                            <i class="atmo-icon-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </editable-file-attachments-list-controller>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';

export default {
    components: {
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController
    },
    props: {
        documents: { required: true, type: Array },
        update: { required: true, type: Function },
        is_disabled: { required: true, type: Boolean }
    }
};
</script>
