var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("on-off", {
    attrs: { options: { id: "signature-popup", onEsc: false, onBody: false } },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (onoff) {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: onoff.ison,
                    expression: "onoff.ison",
                  },
                ],
                staticClass: "atmo-popup-wrapper",
              },
              [
                onoff.content
                  ? _c(
                      "div",
                      { staticClass: "atmo-popup-content" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "atmo-button--small atmo-close",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return onoff.off.apply(null, arguments)
                              },
                            },
                          },
                          [_c("i", { staticClass: "atmo-icon-close" })]
                        ),
                        _c("h5", [
                          onoff.content.length < 2
                            ? _c("span", [_vm._v("Электронная подпись")])
                            : _c("span", [_vm._v("Электронные подписи")]),
                        ]),
                        _vm._l(onoff.content, function (signature, index) {
                          return _c("SignatureViewItem.view", {
                            key: index,
                            attrs: { data: signature },
                          })
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }