<template>
    <div>
        <!-- // DEPRECATED: Heading already existst below;  -->
        <!-- <p class="fs-32 grey-color my-4">Спецификации</p> -->
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <h2 class="grey-color font-weight-bold my-3">Спецификация</h2>
            </b-col>
        </b-row>
        <b-row class="py-1" no-gutters>
            <b-col class="fs-14 grey-color">
                <div :class="v.$invalid || specErrors ? 'is-invalid' : ''" class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1"></th>
                                <th>Наименование товара/работы/услуги</th>
                                <th class="text-right" style="min-width: 140px" width="160px">Количество</th>
                                <th nowrap width="100px">Ед.изм</th>
                                <th v-if="withPrice" class="text-right" style="min-width: 140px" width="160px">Цена за ед., руб.</th>
                                <th v-if="withPrice" class="text-right" style="min-width: 140px" width="140px">Стоимость, руб.</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <fragment v-for="(item, index) in specs" :key="index">
                                <tr>
                                    <td class="align-text-top">{{ item.number }}</td>
                                    <td class="align-text-top">
                                        <action-button-small v-b-toggle="'collapse-' + index" :icon="!visible[index] ? 'eye' : 'eye-slash'" :title="(!visible[index] ? 'Показать' : 'Скрыть') + ' характеристики'" />
                                        <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                        {{ item.name }}
                                    </td>
                                    <td v-if="withCountEdit" class="align-text-top" style="text-align: right">
                                        <formatted-input-number v-slot:default="fim" v-bind:decimals="9" v-bind:padright="false">
                                            <div class="atmo-component-wrapper">
                                                <span class="atmo-form__field atmo-inverse atmo-small" v-bind:class="{ 'atmo-invalid': !fim.isInputValid }">
                                                    <input
                                                        v-model.number="specs[index].count"
                                                        v-stop-number-mousewheel
                                                        class="mb-0"
                                                        min="0.000000001"
                                                        required
                                                        step="0.000000001"
                                                        style="height: 30px !important"
                                                        type="number"
                                                        @focus="$event.target?.select()"
                                                        v-on:blur="fim.processBlurEvent"
                                                        v-on:input="fim.processInputEvent"
                                                        v-on:keydown="fim.processKeydownEvent" />
                                                </span>
                                            </div>
                                        </formatted-input-number>
                                    </td>
                                    <td v-else class="align-text-top" style="text-align: right">
                                        {{ parseFloat(specs[index].count).toPrecision() }}
                                    </td>
                                    <td class="align-text-top">{{ item.okei.local_symbol }}</td>
                                    <td v-if="withPrice" class="align-text-top">
                                        <currency-input v-model="specs[index].price" :class="specs[index].price > 0 ? 'is-valid' : 'is-invalid'" class="form-control fs-14 text-right currency-input mb-0" currency="RUB" locale="ru" @focus="$event.target?.select()" />
                                    </td>
                                    <td v-if="withPrice" class="align-text-top" style="text-align: right">
                                        {{ $formatPrice(roundToTwoDecimals(specs[index].price * specs[index].count)) }}
                                    </td>
                                    <td class="align-text-top" nowrap>
                                        <action-button-small v-if="!noDeleteSpec" icon="trash" @click="removeSpec(index)" />
                                    </td>
                                </tr>
                                <tr v-show="visible[index]">
                                    <td :colspan="withPrice ? 7 : 5">
                                        <p><b>Рег.номер ТРУ:</b> {{ item.ktru.reg_number }}</p>
                                        <p><b>Группа ТРУ:</b> {{ item.ktru.ktru_group_name }}</p>
                                        <p><b>Наименование:</b> {{ item.ktru.name }}</p>
                                        <p><b>Тип:</b> {{ item.ktru.type }}</p>
                                        <p v-if="item.okpd"><b>Код ОКПД2:</b> {{ item.okpd ? item.okpd.code : '&mdash;' }}</p>
                                        <p v-if="item.ktru.description"><b>Описание:</b> {{ item.ktru.description }}</p>
                                        <p v-if="item.ktru.specs && item.ktru.specs.length > 0"><b>Характеристики:</b></p>
                                        <ul v-if="item.ktru.specs && item.ktru.specs.length > 0">
                                            <li v-for="(spec, specIndex) of item.ktru.specs" :key="index + '_spec_' + specIndex">
                                                <b>{{ spec.name }}</b> - {{ spec.value }}
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </fragment>
                            <tr v-if="specs.length === 0">
                                <td :colspan="withPrice ? 7 : 5" align="center">Не добавлено позиций спецификации</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <text-row-price v-if="withPrice" :value="roundedSpecSum" compact label="Общая стоимость" />
                <p v-if="specErrors" class="font-weight-bold fs-14 my-auto col-form-label text-danger fml-5px">{{ specErrorMessage }}</p>
            </b-col>
        </b-row>
        <div>
            <b-button v-if="!noAddSpec" variant="custom-green" @click="showSpec = true">Добавить позицию спецификации</b-button>
        </div>

        <purchase-specification-modal :show="showSpec" @add="addSpec" @cancel="cancelSpecModal" />
    </div>
</template>

<script>
import { CurrencyDirective } from 'vue-currency-input';
import PurchaseSpecificationModal from '@/components/common/purchase-specification-modal';
import FormattedInputNumber from '@lib/js/src/vue/components/FormattedInputNumber.viewmodel';
import roundToTwoDecimals from '../../../rearchitected/lib/js/src/misc/roundToTwoDecimals';

export default {
    name: 'FormRowSpecificationTable',
    props: {
        value: {
            type: Array,
            required: true
        },
        grey: {
            type: Boolean,
            default: false
        },
        v: {
            type: Object,
            required: true
        },
        withPrice: {
            type: Boolean,
            default: false
        },
        withCountEdit: {
            type: Boolean,
            default: false
        },
        specSum: {
            type: Number
        },
        specErrors: {
            type: Boolean
        },
        specErrorMessage: {
            type: String,
            default: ''
        },
        noAddSpec: {
            type: Boolean,
            default: false
        },
        noDeleteSpec: {
            type: Boolean,
            default: false
        },
        noDoubles: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'formatted-input-number': FormattedInputNumber,
        PurchaseSpecificationModal
    },
    directives: {
        currency: CurrencyDirective
    },
    data() {
        return {
            spec: {
                number: 1,
                order: 1,
                name: '',
                count: 1,
                okei: null,
                ktru_item_id: null,
                ktru: null,
                price: 0
            },
            showSpec: false,
            tmpSpecs: [],
            visible: []
        };
    },
    methods: {
        roundToTwoDecimals,
        cancelSpecModal() {
            this.spec = { number: 1, order: 1, name: '', count: 1, okei: null, okpd: null, ktru_item_id: null, ktru: null, price: 0 };
            this.showSpec = false;
        },
        addSpec(spec) {
            this.tmpSpecs = this.specs;
            if (!spec.ktru_item_id) {
                this.pushToast({
                    text: 'Неверный формат спецификации',
                    title: 'Ошибка',
                    variant: 'danger'
                });
            } else {
                this.showSpec = false;
                const alreadyExists = this.specs.find((i) => i.ktru_item_id === spec.ktru_item_id);
                if (this.noDoubles && alreadyExists) {
                    return;
                }
                let newSpec = JSON.parse(JSON.stringify(spec)); // clone
                newSpec['number'] = this.tmpSpecs.length + 1;
                newSpec['order'] = this.tmpSpecs.length + 1;
                if (this.withPrice) {
                    newSpec['price'] = 0;
                }
                this.tmpSpecs.push(newSpec);
                this.specs = this.tmpSpecs;
            }
        },
        removeSpec(index) {
            this.tmpSpecs = this.specs;
            this.tmpSpecs.splice(index, 1);
            let startIndex = 1;
            this.tmpSpecs.forEach((item) => {
                item.number = startIndex;
                item.order = startIndex;
                startIndex++;
            });
            this.specs = this.tmpSpecs;
        }
    },
    computed: {
        specs: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        roundedSpecSum() {
            return Math.round(this.specSum * 100) / 100;
        }
    }
};
</script>

<style scoped>
.table-responsive {
    border: 1px solid transparent;
    border-radius: 2px;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 8px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
