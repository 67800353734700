<template>
    <section class="atmo-content-group" v-if="this.$props.purchaseitem">
        <h6>Условия закупки</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Предмет закупки:</dt>
                <dd v-text="this.$props.purchaseitem.description"></dd>
            </li>
            <li>
                <dt>Объект закупки:</dt>
                <dd v-text="this.$props.purchaseitem.purchase_object"></dd>
            </li>
            <li>
                <dt>Тип закупки:</dt>
                <dd v-text="this.$props.purchaseitem.type_title"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.with_advance">
                <dt>Размер аванса, %:</dt>
                <dd v-text="this.$props.purchaseitem.advance_percentage"></dd>
            </li>
            <li>
                <dt>Дата и время размещения закупки:</dt>
                <dd>{{ this.$props.purchaseitem.publication_date | formatdate('withSeconds') }}</dd>
            </li>
            <li>
                <dt>Дата и время окончания подачи предложений:</dt>
                <dd>{{ this.$props.purchaseitem.proposal_accept_end_date | formatdate('withSeconds') }}</dd>
            </li>
            <li>
                <dt>Планируемая дата заключения контракта:</dt>
                <dd>{{ this.$props.purchaseitem.planned_contract_signature_date | formatdate('onlyDate') }}</dd>
            </li>
            <li>
                <dt>Планируемая дата исполнения контракта:</dt>
                <dd>{{ this.$props.purchaseitem.planned_fulfillment_date | formatdate('onlyDate') }}</dd>
            </li>
            <li>
                <dt>Начальная цена контракта, руб.:</dt>
                <dd>{{ this.$props.purchaseitem.starting_price | formatnumber }}</dd>
            </li>
            <li>
                <dt>Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации:</dt>
                <dd v-if="this.$props.purchaseitem.unilateral_contract_termination_info" v-text="this.$props.purchaseitem.unilateral_contract_termination_info"></dd>
                <dd v-else>Нет</dd>
            </li>
            <li>
                <dt>Приложение заказчика к объявлению:</dt>
                <dd>
                    <ul v-if="this.$props.purchaseitem.attachments && this.$props.purchaseitem.attachments.length > 0" class="atmo-list">
                        <li v-for="(attachment, index) in this.$props.purchaseitem.attachments" v-bind:key="index">
                            <button v-on:click="$props.handlers.downloadFileAttachment(attachment)" class="atmo-button-link" v-text="attachment.name"></button>
                        </li>
                    </ul>
                </dd>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        purchaseitem: { required: true },
        handlers: { required: true, type: Object }
    }
};
</script>
