'use strict';

import { AxiosResponse } from 'axios';

type TSelfPOJO = Omit<ATMOAPIResponseNormalizedVO, 'hasUserMessage'>;

export default class ATMOAPIResponseNormalizedVO {
    private static DOMAIN_RESPONSE_CODES = [403, 422];

    isSuccess: boolean;
    status: {
        code: number;
        message: string;
    };
    message: {
        general: string | null;
        details: string[];
    };
    original: {
        response?: AxiosResponse;
    };

    constructor(data?: TSelfPOJO) {
        this.isSuccess = data?.isSuccess ?? false;
        this.status = {
            code: data?.status.code ?? 418,
            message: data?.status.message ?? 'I’m a teapot'
        };
        this.message = {
            general: data?.message.general ?? null,
            details: data?.message.details ?? []
        };
        this.original = {
            response: data?.original.response
        };

        Object.seal(this);
    }

    public static fromAxiosResponse(response?: AxiosResponse): ATMOAPIResponseNormalizedVO {
        const pojo = {
            isSuccess: response ? response.status >= 200 && response.status < 300 : false,
            status: {
                code: response?.status ?? 0,
                message: response?.statusText ?? 'System error: response is undefined.'
            },
            message: {
                general: this.isDomainResponse(response?.status) ? (response?.status !== 422 ? response!.data.message : null) : null,
                details: this.isDomainResponse(response?.status) ? this.detailsFactory(response!.data.errors) : []
            },
            original: {
                response: response
            }
        };
        return new ATMOAPIResponseNormalizedVO(pojo);
    }

    private static detailsFactory(errors?: Record<string, string[]>): TSelfPOJO['message']['details'] | [] {
        return errors
            ? Object.values(errors).reduce((accumulator: string[], error: string[]) => {
                  accumulator.push(...error);
                  return accumulator;
              }, [])
            : [];
    }

    private static isDomainResponse(statusCode?: number): boolean {
        return statusCode !== undefined ? this.DOMAIN_RESPONSE_CODES.includes(statusCode) : false;
    }

    public hasUserMessage(): boolean {
        return this.message.general !== null || this.message.details.length > 0;
    }
}
