var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("contract-disputes-resolution-widget-controller", {
    attrs: { purchaseid: this.$props.pivc.purchaseitem.id },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (cdrwc) {
          return [
            _c(
              "div",
              {
                staticClass: "atmo-hash-jump-point atmo-content-group",
                attrs: { id: "atmo-disputes-resolution" },
              },
              [
                _c("h3", [_vm._v("Согласование разногласий по договору")]),
                _vm.pivc.isUserEngagedCustomer && _vm.pivc.isContractDisputed
                  ? _c("editable-file-attachments-list-controller", {
                      attrs: {
                        isrequired: true,
                        origin: "contract",
                        maxfilesallowed: 1,
                      },
                      on: { "fileattachment:changed": cdrwc.handleAttachment },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (efalc) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "atmo-component-wrapper" },
                                  [
                                    _c(
                                      "EditableFileAttachmentsList.view",
                                      { attrs: { controller: efalc } },
                                      [
                                        _c("span", [
                                          _c("h5", [
                                            _vm._v("Новая редакция договора"),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
                _c("editable-file-attachments-list-controller", {
                  attrs: {
                    isrequired: true,
                    origin: "dispute",
                    maxfilesallowed: 1,
                  },
                  on: { "fileattachment:changed": cdrwc.handleAttachment },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (efalc) {
                          return [
                            _c(
                              "div",
                              { staticClass: "atmo-component-wrapper" },
                              [
                                _c(
                                  "EditableFileAttachmentsList.view",
                                  {
                                    attrs: { controller: efalc },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "aux",
                                          fn: function () {
                                            return [
                                              _c(
                                                "section",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: !efalc.isempty,
                                                      expression:
                                                        "!efalc.isempty",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "atmo-form__field atmo-inverse",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "atmo-dispute-comment",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Комментарий к протоколу разногласий:"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("textarea", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: cdrwc.comment,
                                                        expression:
                                                          "cdrwc.comment",
                                                      },
                                                    ],
                                                    class: {
                                                      "atmo-empty":
                                                        _vm.pivc
                                                          .isUserEngagedCustomer &&
                                                        cdrwc.isCommentEmpty,
                                                    },
                                                    attrs: {
                                                      id: "atmo-dispute-comment",
                                                      cols: "2",
                                                    },
                                                    domProps: {
                                                      value: cdrwc.comment,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          cdrwc,
                                                          "comment",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "controls",
                                          fn: function () {
                                            return [
                                              _c(
                                                "li",
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "atmo-button mr-2",
                                                      attrs: {
                                                        disabled:
                                                          efalc.isempty ||
                                                          (_vm.pivc
                                                            .isUserEngagedCustomer &&
                                                            (cdrwc.isCommentEmpty ||
                                                              cdrwc.isContractAttachmentEmpty)) ||
                                                          !_vm.$store.getters
                                                            .getCanSign,
                                                      },
                                                      on: {
                                                        click:
                                                          cdrwc.invokeSignContractDisputeProtocol,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Подписать и отправить "
                                                      ),
                                                      _vm.pivc
                                                        .isUserEngagedCustomer
                                                        ? _c("span", [
                                                            _vm._v("ответ на "),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "протокол разногласий "
                                                      ),
                                                    ]
                                                  ),
                                                  !_vm.$store.getters.getCanSign
                                                    ? [
                                                        _vm.$store.getters
                                                          .getLoginType ===
                                                        "cert"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Для действия требуется загрузить/заменить МЧД на странице: "
                                                                ),
                                                                _c(
                                                                  "router-link",
                                                                  {
                                                                    staticClass:
                                                                      "green-link",
                                                                    attrs: {
                                                                      to: {
                                                                        name: "UserProfile",
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Данные учетной записи"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Подписание возможно только при авторизации при помощи ЭЦП"
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _c("h5", [
                                        _vm._v("Добавьте "),
                                        _vm.$props.pivc.isUserEngagedCustomer
                                          ? _c("span", [_vm._v("ответ на")])
                                          : _vm._e(),
                                        _vm._v(" протокол разногласий"),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }