<template>
    <notice-item-commercial :pi="pi" />
</template>

<script>
// import DealStageActionButton from './DealStageActionButton.view.vue';

import NoticeItemCommercial from './NoticeItemCommercial.view.vue';

export default {
    props: {
        pi: { required: true, type: Object }
    },
    components: {
        NoticeItemCommercial
        // DealStageActionButton
    }
};
</script>
