<template>
    <section class="atmo-tab-content">
        <section class="atmo-content-group">
            <h6>Документ</h6>

            <ul class="atmo-content-block-2-columns atmo-no-item-separator">
                <li>
                    <dt>Приложенные документы:</dt>
                    <dd>
                        <button v-on:click="$props.controller.downloadFileAttachment($props.attachment)" class="atmo-button-link" v-text="$props.attachment.name"></button>
                    </dd>
                </li>
                <li>
                    <dt>Комментарий к протоколу разногласий:</dt>
                    <dd v-text="this.$props.comment"></dd>
                </li>
            </ul>
        </section>
    </section>
</template>

<script>
export default {
    props: {
        controller: { required: true, type: Object },
        certificate: { required: true, type: Object },
        attachment: { required: true, type: Object },
        comment: { required: true }
    }
};
</script>
