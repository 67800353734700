'use strict';

export interface IRawActiveStageData {
    code: string;
    title: string;
    hash: string;
}

interface IStages {
    [index: string]: unknown;
}

import dealStagesViewdata from './deal-stages-viewdata.dictionary.json';

export default class ActiveDealStage {
    code: string;
    title: string;
    hash: string;

    constructor(rawActiveStageData: IRawActiveStageData) {
        if (!this.isValid(rawActiveStageData)) {
            throw new Error('ATMO Exception: the "rawActiveStageData" argument structure is invalid. Please verify you provide valid dictionary data.');
        }
        this.code = rawActiveStageData.code;
        this.title = rawActiveStageData.title;
        this.hash = rawActiveStageData.hash;
    }

    public static factoryFromDealActivities(dealActivities: Record<string, boolean>): ActiveDealStage | null {
        const stages: IStages = {
            send_contract: dealActivities?.customer_need_send_contract_to_provider,
            answer_contract_dispute: dealActivities?.customer_need_answer_contract_dispute || dealActivities?.customer_need_answer_supplementary_agreement_dispute,
            sign_contract: dealActivities?.customer_need_sign_contract || dealActivities?.provider_need_sign_contract,
            sign_supplementary_agreement: dealActivities?.customer_need_sign_supplementary_agreement || dealActivities?.provider_need_sign_supplementary_agreement
        };
        const activeStageKey = Object.keys(stages).find((key) => {
            return stages[key] === true;
        });

        if (!activeStageKey) {
            return null;
        }

        /**
         * NB: [performance] The entire loop with replace() takes
         * 9 nanoseconds more than with no replace().
         */
        const rawActiveStage = (dealStagesViewdata as IRawActiveStageData[]).find((item) => {
            return item.code.replace(/-/g, '_') === activeStageKey;
        });

        if (!rawActiveStage) {
            throw new Error(`ATMO Exception: There is no dictionary entry found for active stage key "${activeStageKey}"`);
        }

        return new ActiveDealStage(rawActiveStage);
    }

    private isValid(rawActiveStageData: IRawActiveStageData): boolean {
        return !!rawActiveStageData.code && !!rawActiveStageData.title;
    }
}
