'use strict';

import { RouteConfig, Route } from 'vue-router';

import NoticesListView from '@/components/global/notices/NoticesList.view.vue';
import CommercialNoticesListView from '@/components/global/commercial-notices/NoticesList.view.vue';
import Fl223NoticesListView from '@/components/global/fl223-notices/NoticesList.view.vue';
import Fl44NoticesListView from '@/components/global/fl44-notices/NoticesList.view.vue';

export default [
    // {
    //     path: '/notices',
    //     name: 'notices',
    //     props: {
    //         group: 'global'
    //     },
    //     meta: { title: 'Извещения' },
    //     component: NoticesListView
    // },
    {
        path: '/notices/commercial',
        name: 'notices.commercial',
        props: {
            group: 'commercial'
        },
        meta: { title: 'Коммерческие извещения' },
        component: CommercialNoticesListView
    },
    {
        path: '/notices/fl223',
        name: 'notices.fl223',
        props: {
            group: 'fl223'
        },
        meta: { title: 'Извещения 223-ФЗ' },
        component: Fl223NoticesListView
    },
    {
        path: '/notices/fl44',
        name: 'notices.fl44',
        props: {
            group: 'fl44'
        },
        meta: { title: 'Извещения 44-ФЗ' },
        component: Fl44NoticesListView
    }
] as RouteConfig[];
