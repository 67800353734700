var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("confirmation-dialogue", {
    attrs: {
      id: "contract-annex-deliverable-duplicate-remove-confirmation-popup",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (cd) {
          return [
            _c("on-off", {
              attrs: {
                options: { id: cd.$props.id, onEsc: false, onBody: false },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (onoff) {
                      return [
                        onoff.ison
                          ? _c("div", { staticClass: "atmo-popup-wrapper" }, [
                              _c("div", { staticClass: "atmo-popup-content" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "atmo-button--small atmo-close",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return cd.cancel.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "atmo-icon-close" })]
                                ),
                                _c("header", [
                                  _c("p", {
                                    domProps: { textContent: _vm._s(cd.item) },
                                  }),
                                  _c("h5", [
                                    _vm._v(
                                      "Подтвердите удаление дубликата позиции спецификации"
                                    ),
                                  ]),
                                ]),
                                _c("main", [
                                  _c("p", [
                                    _vm._v(
                                      "Вы действительно хотите удалить этот дубликат позиции спецификации?"
                                    ),
                                  ]),
                                ]),
                                _c("footer", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "atmo-buttons atmo-align-right",
                                    },
                                    [
                                      _c("li", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "atmo-button",
                                            on: { click: cd.cancel },
                                          },
                                          [_vm._v("Отмена")]
                                        ),
                                      ]),
                                      _c("li", [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "atmo-button atmo-accented",
                                            on: { click: cd.confirm },
                                          },
                                          [_vm._v("Удалить")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }