var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("contract-annex-dispute-resolution-controller", {
    attrs: {
      contract: this.$props.pivc.purchaseitem.contract,
      user: this.$props.pivc.user,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (cadrc) {
          return [
            _c(
              "div",
              { staticClass: "atmo-component-wrapper" },
              [
                _vm.$props.pivc.isUserEngagedCustomer &&
                _vm.$props.annex.hasUnresolvedDisputes()
                  ? _c("ContractAnnexUpdate.view", {
                      attrs: {
                        mode: "edit",
                        pivc: _vm.$props.pivc,
                        disputecontroller: cadrc,
                      },
                    })
                  : _vm._e(),
                _c("editable-file-attachments-list-controller", {
                  attrs: {
                    origin: "dispute",
                    isrequired: true,
                    maxfilesallowed: 1,
                  },
                  on: { "fileattachment:changed": cadrc.handleAttachment },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (efalc) {
                          return [
                            _c(
                              "EditableFileAttachmentsList.view",
                              { attrs: { controller: efalc } },
                              [
                                _c("h5", [
                                  _vm._v("Добавьте "),
                                  _vm.$props.pivc.isUserEngagedCustomer
                                    ? _c("span", [_vm._v("ответ на")])
                                    : _vm._e(),
                                  _vm._v(" протокол разногласий"),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c(
                  "section",
                  { staticClass: "atmo-form__field atmo-inverse" },
                  [
                    _c("label", { attrs: { for: "atmo-dispute-comment" } }, [
                      _vm._v("Комментарий к "),
                      _vm.$props.pivc.isUserEngagedCustomer
                        ? _c("span", [_vm._v("ответу на протокол")])
                        : _c("span", [_vm._v("протоколу")]),
                      _vm._v(" разногласий: "),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: cadrc.comment,
                          expression: "cadrc.comment",
                        },
                      ],
                      class: { "atmo-empty": cadrc.isCommentEmpty },
                      attrs: { id: "atmo-dispute-comment", cols: "2" },
                      domProps: { value: cadrc.comment },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(cadrc, "comment", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "atmo-buttons" },
                  [
                    _vm.$props.pivc.isUserEngagedSupplier
                      ? _c(
                          "button",
                          {
                            staticClass: "atmo-button",
                            attrs: {
                              disabled:
                                cadrc.isCommentEmpty ||
                                cadrc.isDisputeAttachmentEmpty ||
                                !_vm.$store.getters.getCanSign,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return cadrc.invokeSignContractAnnexDisputePopup.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Подписать протокол разногласий и отправить заказчику "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.$props.pivc.isUserEngagedCustomer
                      ? _c(
                          "button",
                          {
                            staticClass: "atmo-button",
                            attrs: {
                              disabled:
                                cadrc.isCommentEmpty ||
                                cadrc.isAnnexAttachmentEmpty ||
                                cadrc.isAnnexEditableEmpty ||
                                !_vm.$store.getters.getCanSign,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return cadrc.invokeSignContractAnnexDisputePopup.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Подписать ответ на протокол разногласий и отправить поставщику "
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm.$store.getters.getCanSign
                      ? [
                          _vm.$store.getters.getLoginType === "cert"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                },
                                [
                                  _vm._v(
                                    "Для действия требуется загрузить/заменить МЧД на странице: "
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "green-link",
                                      attrs: { to: { name: "UserProfile" } },
                                    },
                                    [_vm._v("Данные учетной записи")]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                },
                                [
                                  _vm._v(
                                    "Подписание возможно только при авторизации при помощи ЭЦП"
                                  ),
                                ]
                              ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }