'use strict';

import { RouteConfig } from 'vue-router';

import ContractsList from 'themes/views/pages/contracts/ContractsList.view.vue';

export default [
    {
        path: '/contracts/grouped/:group',
        name: 'contracts.grouped',
        meta: { title: 'Контракты' },
        component: ContractsList
    }
] as RouteConfig[];
