<template>
    <section class="atmo-tab-content">
        <CustomerInfo.view v-bind:customer="this.$props.purchaseitem.customer" />

        <SupplierInfo.view v-bind:supplier="this.$props.purchaseitem.supplier" />

        <PurchaseTerms.view v-bind:purchaseitem="this.$props.purchaseitem" v-bind:handlers="this.$props.handlers" />

        <SupplierRequirements.view v-if="!this.$props.controller.isSupplier" v-bind:requirements="this.$props.purchaseitem.supplier_requirements" />

        <SupplyTerms.view v-bind:purchaseitem="this.$props.purchaseitem" v-bind:handlers="this.$props.handlers" />

        <ContractDetails.view v-bind:purchaseitem="this.$props.purchaseitem" />

        <SupplierRequirementsConfirmation.view v-if="this.$props.controller.isSupplier" v-bind:controller="this.$props.controller" v-bind:required="this.$props.purchaseitem.supplier_requirements" v-bind:confirmed="this.$props.controller.supplier_requirements_confirmed" />

        <CertificateInfo.view v-if="this.$props.certificate" v-bind:certificate="this.$props.certificate" />

        <p v-if="this.$props.controller.hasErrors && this.$props.controller.isSupplier" class="atmo-error">Невозможно подписать и сохранить договор. Пожалуйста, заполните раздел "Требования к поставщикам".</p>

        <ul class="atmo-buttons">
            <li><button v-on:click="onoff.off" class="atmo-button-inverse atmo-dimmed">Отмена</button></li>
            <li v-if="!this.$props.controller.isDataSigned && this.$props.certificate">
                <ProgressIndicatingButton.component v-bind:clickhandler="this.$props.controller.signData" v-slot="slotProps">
                    <button v-on:click="slotProps.component.handler" v-bind:disabled="slotProps.component.isprogressing || !$props.controller.isSignContractAllowed" class="atmo-button">Подписать</button>
                </ProgressIndicatingButton.component>
            </li>
            <li v-if="this.$props.certificate && this.$props.controller.isDataSigned && this.$props.controller.isSignContractAllowed">
                <button v-on:click="this.$props.controller.signContract" class="atmo-button">Отправить</button>
                <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
            </li>
        </ul>
    </section>
</template>

<script>
import CustomerInfoView from 'themes/views/overlays/popups/common/partials/CustomerInfo.view.vue';
import SupplierInfoView from '../../../common/partials/SupplierInfo.view.vue';
import PurchaseTermsView from './partials/PurchaseTerms.view.vue';
import SupplierRequirementsView from './partials/SupplierRequirements.view.vue';
import SupplierRequirementsConfirmationView from './partials/SupplierRequirementsConfirmation.view.vue';
import SupplyTermsView from './partials/SupplyTerms.view.vue';
import ContractDetailsView from './partials/ContractDetails.view.vue';
import CertificateInfoView from 'themes/views/overlays/popups/common/partials/CertificateInfo.view.vue';
import ProgressIndicatingButtonComponent from '@lib/js/src/vue/components/progress/button/ProgressIndicatingButton.component.vue';

export default {
    props: {
        user: { required: true },
        controller: { required: true, type: Object },
        onoff: { required: true, type: Object },
        purchaseitem: { required: true, type: Object },
        handlers: { required: true, type: Object },
        certificate: { required: true }
    },
    components: {
        'CustomerInfo.view': CustomerInfoView,
        'SupplierInfo.view': SupplierInfoView,
        'PurchaseTerms.view': PurchaseTermsView,
        'SupplierRequirements.view': SupplierRequirementsView,
        'SupplierRequirementsConfirmation.view': SupplierRequirementsConfirmationView,
        'SupplyTerms.view': SupplyTermsView,
        'ContractDetails.view': ContractDetailsView,
        'CertificateInfo.view': CertificateInfoView,
        'ProgressIndicatingButton.component': ProgressIndicatingButtonComponent
    }
};
</script>
