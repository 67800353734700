var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("supplier-deposit-replenish-popup-form", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (sdrpf) {
          return [
            _c("on-off", {
              attrs: { currentstate: sdrpf.ison },
              on: { "onoff:ontoggle": sdrpf.close },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (onoff) {
                      return [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: sdrpf.ison,
                                expression: "sdrpf.ison",
                              },
                            ],
                            staticClass: "atmo-popup-wrapper",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "atmo-popup-content",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "atmo-button--small atmo-close",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return onoff.off.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "atmo-icon-close" })]
                                ),
                                _c("h5", [_vm._v("Внимание!")]),
                                _c("p", [
                                  _vm._v(
                                    "Блокировка денежных средств для обеспечения проведения торгов невозможна. Пожалуйста пополните лицевой счет."
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v("Необходимо для списания "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(sdrpf.minimumAmount),
                                    },
                                  }),
                                  _vm._v(" рублей."),
                                ]),
                                _c(
                                  "form",
                                  {
                                    staticClass: "atmo-form--inverse",
                                    attrs: {
                                      id: "form-supplier-deposit-replenish",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "atmo-form__field atmo-inverse",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: sdrpf.amount,
                                              expression: "sdrpf.amount",
                                            },
                                          ],
                                          attrs: {
                                            type: "number",
                                            id: "deposit-amount",
                                            name: "deposit-amount",
                                            min: "0",
                                          },
                                          domProps: { value: sdrpf.amount },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                sdrpf,
                                                "amount",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "deposit-amount" } },
                                          [_vm._v("Сумма пополнения депозита")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "atmo-form__field atmo-inverse",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: sdrpf.email,
                                              expression: "sdrpf.email",
                                            },
                                          ],
                                          attrs: {
                                            type: "email",
                                            id: "deposit-user-email",
                                            name: "deposit-user-email",
                                          },
                                          domProps: { value: sdrpf.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                sdrpf,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "deposit-user-email",
                                            },
                                          },
                                          [_vm._v("E-mail пользователя")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "atmo-controls" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "atmo-button",
                                      attrs: {
                                        disabled:
                                          !sdrpf.isInputValid ||
                                          sdrpf.issending,
                                      },
                                      on: { click: sdrpf.sendInvoiceToEmail },
                                    },
                                    [_vm._v("Отправить на E-MAIL")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "atmo-button",
                                      attrs: {
                                        disabled:
                                          !sdrpf.isInputValid ||
                                          sdrpf.issending,
                                      },
                                      on: { click: sdrpf.downloadInvoice },
                                    },
                                    [_vm._v("Скачать PDF")]
                                  ),
                                  sdrpf.issending
                                    ? _c(
                                        "div",
                                        { staticClass: "atmo-spinner" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("../../../assets/images/loaders/spinner-sector.svg"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                !sdrpf.isInputValid
                                  ? _c("div", { staticClass: "atmo-message" }, [
                                      _c("p", [
                                        _vm._v(
                                          "Введены некорректные значения полей. Пожалуйста, исправьте для отправки инвойса."
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }