'use strict';

import BaseVO from '@core/js/ddriven/domain/model/common/Base.valueobject';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import PurchaseItemEditableVO from './PurchaseItemEditable.valueobject';

export default class PurchaseItemEditablePartialDecodedVO extends BaseVO {
    id: number | null;
    deliverable_group_title: string | null;
    type_title: string | null;
    duration_title: string | null;
    urgency_reason_title: string | null;

    constructor(data?: PurchaseItemEditableVO, dictionaries?: Record<string, StandardDictionaryItem[]>) {
        super();

        this.id = this.valueOrDefault('id', data);
        this.deliverable_group_title = data && dictionaries ? this.titleOrNull(data?.deliverable_group_id as number, dictionaries?.deliverablesgroups) : null;
        this.type_title = data && dictionaries ? this.titleOrNull(data?.type_id as number, dictionaries?.types) : null;
        this.duration_title = data && dictionaries ? this.titleOrNull(data?.duration_id as number, dictionaries?.durations) : null;
        this.urgency_reason_title = data && dictionaries ? this.titleOrNull(data?.urgency_reason.id, dictionaries?.urgencyreasons) : null;
    }

    private titleOrNull(id: any, dictionary?: StandardDictionaryItem[]): string | null {
        if (!dictionary) {
            return null;
        }

        const found = StandardDictionaryItem.findById(id, dictionary);
        return found ? found.title : null;
    }
}
