'use strict';

import DownloadConfig, { AllowedConfigTypes } from '../DownloadConfig.valueobject';
import OrganizationVO from '../../../model/users/Organization.valueobject';

export default class OrganizationReportStrategy extends DownloadConfig {
    constructor(organization: OrganizationVO) {
        super(AllowedConfigTypes.organizationreport);
        this.verb = 'GET';
        this.endpoint = `/organizations/${organization.id}/report`;
        this.name = `Аналитический отчет об организации ${organization.inn}.pdf`;
    }

    public setBody() {
        return {};
    }
}
