import { mapActions } from 'vuex';
export default {
    methods: {
        pushToast(payload) {
            payload['id'] = new Date().getTime() + Math.floor(Math.random() * (100 - 1) + 1);
            const options = this.buildOptions(payload.text, payload.title, payload.variant, payload.boldText, payload.link, payload.timer);
            // console.time(payload['id']);
            // console.log('open  '+payload.id)
            this.addToast(options);
        },
        showError(error, timeout = 5000) {
            let errorCode = error.message.replace(/\D+/g, '');
            let errorType = +errorCode[0];
            if (400 === +errorCode) {
                let options = this.buildOptions(error.response.data.message, 'Ошибка', 'danger', null, null, timeout);
                this.pushToast(options);
            } else if (422 === +errorCode) {
                let err = error.response.data.errors;
                for (let key in err) {
                    let options = this.buildOptions(err[key].join(', '), 'Ошибка', 'danger');
                    this.pushToast(options);
                }
            } else if (4 === errorType) {
                let options = this.buildOptions('Неизвестная ошибка приложения, обратитесь в тех. поддержку', 'Ошибка');
                this.pushToast(options);
            } else if (5 === errorType) {
                let options = this.buildOptions('Сервер не отвечает', 'Ошибка');
                this.pushToast(options);
            } else {
                let options = this.buildOptions('Неизвестная ошибка, обратитесь в тех. поддержку', 'Ошибка');
                this.pushToast(options);
            }
        },
        buildOptions(text = null, title = null, variant = 'orange', boldText = null, link = null, timer = 5000) {
            return {
                id: new Date().getTime() + Math.floor(Math.random() * (100 - 1) + 1),
                title: title,
                variant: variant,
                boldText: boldText,
                text: text,
                link: link,
                timer: timer
            };
        },
        ...mapActions({
            addToast: 'addToast'
        }),
        ...mapActions(['showSuccessToast'])
    }
};
