'use strict';

import DownloadConfig, { Body } from '../DownloadConfig.valueobject';

export default class Protocol extends DownloadConfig {
    protected static _type = 'protocol';

    /**
     * REFACTOR: See the refactor note in front\src\rearchitected\core\js\ddriven\domain\services\downloads\strategies\PurchaseinfoStrategy.valueobject.ts.
     */
    constructor(id: number, purchaseRegistrationNumber: string) {
        super(Protocol._type);
        this.verb = 'GET';
        this.endpoint = `/purchases/${id}/final_protocol_pdf`;
        this.name = `Итоговый протокол к извещению ${purchaseRegistrationNumber}.pdf`;
    }
}
