<template>
    <div class="d-block text-center w-100 m-auto">
        <div v-if="!showForgotWindow">
            <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
                <b-container class="w-50 pt-2">
                    <form-row-edit-text class="mb-1" label="Логин" v-model="form.username" :v="$v.form.username" @input="onInput" />
                    <form-row-edit-text class="mb-1" type="password" label="Пароль" v-model="form.password" :v="$v.form.password" @input="onInput" />
                    <b-form-invalid-feedback :state="state">{{ errorMessage }}</b-form-invalid-feedback>
                    <b-button class="fs-14" variant="link" @click="showForgotWindow = true" @close="cancelForgot">Забыли пароль?</b-button>
                </b-container>
                <footer class="modal-footer pb-0 px-0">
                    <div class="w-100 text-left">
                        <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="onClose">Отмена</b-button>
                        <b-button :disabled="!state" class="text-uppercase" variant="custom-green" @click="login">Продолжить</b-button>
                    </div>
                </footer>
            </b-overlay>
        </div>
        <ForgotPassword v-else @close="cancelForgot" @loadStart="forgotLoadStart" @loadEnd="forgotLoadEnd" @done="onClose" />
    </div>
</template>

<script>
import { helpers, required } from 'vuelidate/lib/validators';
import ForgotPassword from '@/components/auth/forgot-password.vue';
import { $api } from '@/services/backend/api';

const loginBackError = (param) => helpers.withParams({ type: 'loginBackError', value: param }, (value) => !helpers.req(value) || param === false);
export default {
    name: 'login-with-password',
    components: { ForgotPassword },
    validations() {
        return {
            form: {
                username: {
                    required
                },
                password: {
                    required
                }
            },
            loginBackError: loginBackError(this.loginBackError)
        };
    },
    data() {
        return {
            form: {
                username: '',
                password: ''
            },
            loginBackError: false,
            loginErrorText: '',
            isLoading: false,
            showForgotWindow: false
        };
    },
    mounted() {
        this.$emit('hideClose');
    },
    methods: {
        onInput() {
            this.loginBackError = false;
        },
        onClose() {
            this.$emit('close');
        },
        cancelForgot() {
            this.showForgotWindow = false;
        },
        forgotLoadStart() {
            this.$emit('loadStart');
        },
        forgotLoadEnd() {
            this.$emit('loadEnd');
        },
        async login() {
            this.$emit('loadStart');
            this.isLoading = true;

            try {
                await $api.authentication.loginByLoginPassword(this.form.username, this.form.password).then((response) => {
                    this.$store.commit('remove_current_certificate');
                    this.$store
                        .dispatch('login', {
                            token: response.access_token,
                            user: response.user,
                            organization: response.organization
                        })
                        .then(() => {
                            window.location.assign('/cabinet');
                        });
                });
            } catch (e) {
                this.loginBackError = true;
                this.loginErrorText = e.response.data.message;
            }
            this.$emit('loadEnd');
            this.isLoading = false;
        }
    },
    computed: {
        state() {
            return this.$v !== null ? !this.$v.$invalid : null;
        },
        errorMessage() {
            if (this.$v) {
                if (!this.$v.loginBackError) return this.loginErrorText;
            }
            return '';
        }
    }
};
</script>
