'use strict';

import ContractItem from '@core/js/ddriven/domain/model/contracts/ContractItem.valueobject';
import StandardPagination from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import ContractRequest, { IContractRequest } from '@/rearchitected/core/js/ddriven/application/http/requests/contracts/ContractRequest.valueobject';

export interface IContractsState {
    list: Readonly<ContractItem>[];
    pagination: null | StandardPagination;
    request: IContractRequest;
    routereset: boolean;
}

export default class State implements IContractsState {
    list: ContractItem[];
    pagination: null | StandardPagination;
    request: IContractRequest;
    routereset: boolean;

    constructor() {
        this.list = [];
        this.pagination = null;
        this.request = new ContractRequest();
        this.routereset = false;
    }
}
