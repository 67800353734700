'use strict';

import State from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import dictionaries from './dictionaries/index';

const NoticesStoreModule = {
    namespaced: true,

    state: new State(),
    getters: getters,
    actions: actions,
    mutations: mutations,
    modules: {
        dictionaries
    }
};

export default NoticesStoreModule;
