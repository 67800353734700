import { CabinetModelApiService } from '@/services/backend/cabinet/base';
import axios from 'axios';
import store from '@/store';

export class CabinetKtruFavoritesAPIService extends CabinetModelApiService {
    constructor() {
        super('ktru/favorites');
    }

    async getList(page) {
        try {
            const response = await axios.get(this.getUrl(), { params: { page: page } });
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async addKtruItem(payload) {
        try {
            await axios.post(this.getUrl(), payload);
            await store.dispatch('showSuccessToast', 'Позиция КТРУ успешно добавлена в избранное');
            return true;
        } catch (error) {
            this.handleErrors(error);
            throw new Error(error.message);
        }
    }

    async deleteKtruItem(id) {
        try {
            await axios.delete(this.getUrl(id));
            await store.dispatch('showSuccessToast', 'Позиция КТРУ успешно удалена из избранного');
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async createDraft(payload) {
        try {
            const response = await axios.post(this.getUrl('create_draft'), payload);
            await store.dispatch('showSuccessToast', 'Черновик закупки успешно создан');
            return response.data;
        } catch (error) {
            this.handleErrors(error);
            throw new Error(error.message);
        }
    }
}
