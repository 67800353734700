<template>
    <section v-on:click.stop v-if="data" class="atmo-component-wrapper">
        <ul class="atmo-popup-block-2-columns">
            <li>
                <dt>
                    <span v-if="this.$props.data.userType() === 'customer'">Заказчик:</span>
                    <span v-if="this.$props.data.userType() === 'supplier'">Поставщик:</span>
                </dt>
                <dd v-text="this.$props.data.organization_name"></dd>
            </li>
            <li v-if="this.$props.data.username">
                <dt>ФИО:</dt>
                <dd>{{ this.$props.data.username }}</dd>
            </li>
            <li v-if="this.$props.data.poa_check_link">
                <dt>МЧД:</dt>
                <dd>
                    <a :href="this.$props.data.poa_check_link" target="_blank" class="green-link">{{ this.$props.data.poa_number }}</a>
                </dd>
            </li>
            <li v-if="this.$props.data.sign_datetime">
                <dt>Дата и время:</dt>
                <dd>{{ this.$props.data.sign_datetime | formatdate('withSeconds') }}</dd>
            </li>
            <li v-else>
                <span>Контракт ожидает подписи </span>
                <span v-if="this.$props.data.userType() === 'customer'">Заказчика</span>
                <span v-if="this.$props.data.userType() === 'supplier'">Поставщика</span>
            </li>
        </ul>

        <div v-if="this.$props.data.md5" class="atmo-signature">
            <dd>
                <pre>{{ this.$props.data.md5 }}</pre>
            </dd>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        data: { required: true, type: Object }
    }
};
</script>
