<template>
    <b-col :lg="labelColsLg" :sm="labelColsSm">
        <b-form-group :description="description" :label="label" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700">
            <b-form-input :id="id" v-model="text" :disabled="disabled" :maxlength="maxLength" :state="state" :type="type" class="fs-14" />
            <template v-if="!state">
                <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
            </template>
        </b-form-group>
    </b-col>
</template>

<script>
export default {
    name: 'form-row-edit-text-compact',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        maxLength: {
            type: Number,
            default: -1
        },
        labelColsSm: {
            type: Number,
            default: 12
        },
        labelColsLg: {
            type: Number,
            default: 6
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value.replace(/&lrm;|\u200E/gi, ''));
            }
        },
        id() {
            return 'form_row_edit_text_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.maxLength && !!this.v.$params.maxLength) return `Максимальная длина поля - ${this.v.$params.maxLength.max} символа/ов`;
                if (!this.v.minLength && !!this.v.$params.minLength) return `Минимальная длина поля - ${this.v.$params.minLength.min} символа/ов`;
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
                if (!this.v.minLength && !!this.v.$params.thumbprint) return 'Отпечаток содержит недопустимые символы';
            }
            return '';
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
