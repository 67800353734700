'use strict';

import * as EmailValidator from 'email-validator';
import OrganizationVO, { IRawOrganizationData } from './Organization.valueobject';

export enum Types {
    guest = 'guest',
    supplier = 'supplier',
    customer = 'customer'
}

export interface IRawUserData {
    id: number;
    email: string;
    name: string;
}

/**
 * NB: Potentially to be refactored to entity in the future
 * if the responsibilities of the object would become
 * more massive.
 */
export default class UserVO {
    static Types = Types;

    id: number;
    email: string;
    name: string;
    is_logged_in: boolean;
    is_supplier: boolean;
    is_customer: boolean;
    type: Types;
    organization: OrganizationVO;

    constructor(rawUserData: IRawUserData, rawOrganizationData: IRawOrganizationData) {
        this.id = rawUserData.id;
        this.email = rawUserData.email;
        this.name = rawUserData.name;
        this.is_logged_in = true;

        this.organization = new OrganizationVO(rawOrganizationData);

        this.is_supplier = this.organization.is_supplier;
        this.is_customer = this.organization.is_customer;
        this.type = this.getType();
    }

    public isEmailValid(email: string): boolean {
        return EmailValidator.validate(email);
    }

    public static translateType(type: Types): Types | 'provider' {
        return type === Types.supplier ? 'provider' : type;
    }

    public static type(user: UserVO | null): Types {
        return user ? user.type : Types.guest;
    }

    private getType(): Types {
        return this.is_supplier ? Types.supplier : Types.customer;
    }
}
