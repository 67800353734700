var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("on-off", {
    attrs: {
      options: {
        id: "kbk-selector-popup",
        onEsc: false,
        onBody: false,
        nobodyscroll: true,
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (onoff) {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: onoff.ison,
                    expression: "onoff.ison",
                  },
                ],
                staticClass: "atmo-popup-wrapper",
              },
              [
                _c("kbk-limit-selector-popup-controller", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (kbkspc) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "atmo-popup-content atmo-standard-selector-popup atmo-kbk",
                              },
                              [
                                kbkspc.isloading
                                  ? _c("div", { staticClass: "atmo-spinner" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../../../../assets/images/loaders/spinner-sector.svg"),
                                          alt: "",
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "atmo-button--small atmo-close",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return kbkspc.exit.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "atmo-icon-close" })]
                                ),
                                _c("h5", [_vm._v("Выберите лимит КБК")]),
                                _c("p", [
                                  _vm._v("Год плана-графика лимитов: "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(kbkspc.request.year),
                                    },
                                  }),
                                  _vm._v("; Тип закупки: "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        kbkspc.purchaseTypeTitle
                                      ),
                                    },
                                  }),
                                ]),
                                _c(
                                  "header",
                                  [
                                    _c("KBKLimitSelectorFilter.view", {
                                      attrs: { controller: kbkspc },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "main",
                                  [
                                    _c("KBKLimitSelectorList.view", {
                                      key: kbkspc.random(),
                                      attrs: {
                                        controller: kbkspc,
                                        kbkitems: kbkspc.list,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "footer",
                                  [
                                    !kbkspc.islistempty
                                      ? _c("StandardPagination.view", {
                                          attrs: {
                                            pagination: kbkspc.pagination,
                                            pageupdatehandler:
                                              kbkspc.pageUpdate,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }