import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';

export class PersonalLimitsAPIService extends BaseApiService {
    constructor() {
        super('personal/limits');
    }

    async getLimitsList(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async refreshLimits() {
        try {
            await axios.post(this.getUrl());
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
