'use strict';

import { ModuleTree } from 'vuex';

import Config from '@/rearchitected/core/js/ddriven/application/config/Config';
import groups from './modules/groups/index';
import users from './modules/users/index';
import application from './modules/application/index';
// import contracts from './modules/contracts/index';
import ATMOAPIFacade, { IATMOAPIConfig } from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/ATMOAPIFacade';

/**
 * This is the root store config in the rearchitected code module structure.
 * It is temporarily put in the role of store module in this transient project to keep
 * its native naming in its native rearhitectured code to understand its real role in the code.
 */
export default class MainStore {
    namespaced: boolean;

    strict: boolean;
    modules: unknown;
    state: unknown;
    getters: unknown;
    mutations: unknown;

    constructor() {
        this.namespaced = true;

        this.strict = true;

        this.modules = {
            groups,
            users,
            application
            // contracts
        };

        this.state = {
            utils: {}
        };

        this.mutations = {
            initializeUtils: function (state: Record<string, unknown>, rootState: Record<string, string>) {
                const headers = rootState.token && rootState.token.length > 0 ? { Authorization: `Bearer ${rootState.token}` } : {};

                state.utils = Object.freeze({
                    atmoapi: new ATMOAPIFacade(Config.get('api.config') as unknown as IATMOAPIConfig, headers)
                });
            }
        };
    }
}
