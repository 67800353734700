'use strict';

type TSelfPOJO = KBKLimitUpdateItemVO;

export default class KBKLimitUpdateItemVO {
    id: number;
    year: number;
    amount_assigned: number;

    constructor(data: TSelfPOJO) {
        this.id = data.id;
        this.year = data.year;
        this.amount_assigned = data.amount_assigned;
    }
}
