'use strict';

import PurchaseItem, { IPurchaseRawDataItem } from '@core/js/ddriven/domain/model/purchases/view/list/PurchaseItem.valueobject';
import PurchaseItemDetailsVO, { IRawPurchaseDetailsDataItem } from '@/rearchitected/core/js/ddriven/domain/model/purchases/view/item/PurchaseItemDetails.valueobject';

import { IState } from './state';
import { IPurchaseRequest } from '@core/js/ddriven/application/http/requests/purchases/PurchaseRequest.valueobject';
import StandardPagination, { IStandardPaginationRawData } from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import PurchaseItemEditableVO, { IPurchaseDraft } from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditable.valueobject';

const mutations = {
    setList: function (state: IState, data: IPurchaseRawDataItem[]): void {
        state.list = data.map((purchaseData: IPurchaseRawDataItem) => {
            return Object.freeze(new PurchaseItem(purchaseData));
        });
    },

    setItem: function (state: IState, rawItemData: IRawPurchaseDetailsDataItem): void {
        state.item = Object.freeze(PurchaseItemDetailsVO.factory(rawItemData as IRawPurchaseDetailsDataItem));
    },

    setRequest: function (state: IState, request: IPurchaseRequest): void {
        state.request = request;
    },

    updateRequest: function (state: IState, payload: undefined): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.request[payload.key] = payload.value;
    },

    setPagination: function (state: IState, data: IStandardPaginationRawData): void {
        state.pagination = new StandardPagination(data);
    },

    routereset: function (state: IState, newState: boolean): void {
        state.routereset = newState;
    },

    createNewPurchase: function (state: IState): void {
        state.itemeditable = new PurchaseItemEditableVO();
    },

    setPurchaseDraft: function (state: IState, purchaseDraft: IPurchaseDraft) {
        // @ts-ignore
        const dictionaries = state.dictionaries;
        state.itemeditable = PurchaseItemEditableVO.fromDraft(purchaseDraft, { durations: dictionaries.durations, urgencyreasons: dictionaries.urgencyreasons, deliverablegroups: dictionaries.deliverablesgroups });
    }
};

export default mutations;
