'use strict';

import State from './state';
import getters from './getters';
// import actions from './actions';
import mutations from './mutations';

const UsersStoreModule = {
    namespaced: true,

    state: new State(),
    getters: getters,
    // actions: actions,
    mutations: mutations
};

export default UsersStoreModule;
