<template>
    <div class="atmo-component-wrapper">
        <ul v-bind:class="pi.getStatusClass('atmo-entity-status-')" class="atmo-entity-item-title atmo-icon-custom">
            <li style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis" class="pr-4">
                <a v-bind:href="`/purchases/grouped/${pi.elpc.group}/item/${pi.purchaseitem.id}/view`" target="_blank">№&nbsp;<span v-text="pi.purchaseitem.registration_number"></span></a>
                <template v-if="pi.purchaseitem.purchase_object">&nbsp;На закупку "{{ pi.purchaseitem.purchase_object }}"</template>
            </li>
            <li class="text-nowrap"><span v-text="pi.status.title" class="text-uppercase"></span></li>
        </ul>

        <ul class="atmo-entity-item-summary">
            <li>
                <dt><abbr title="Начальная цена контракта">НЦК</abbr>:</dt>
                <dd>
                    <span>{{ pi.purchaseitem.starting_price | formatnumber }}</span
                    >&nbsp;руб.
                </dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Предложений:&nbsp;</dt>
                <dd>
                    <span v-if="!pi.purchaseitem.is_best_proposal_hidden" v-text="pi.purchaseitem.proposals_count"></span>
                    <span v-else>Скрыто</span>
                </dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Лучшее:&nbsp;</dt>
                <!-- <dd v-if="false"> -->
                <dd v-if="!pi.purchaseitem.is_best_proposal_hidden">
                    <span v-if="pi.purchaseitem.best_proposal_price > 0">{{ pi.purchaseitem.best_proposal_price | formatnumber }}&nbsp;руб.</span>
                    <span v-else>-</span>
                </dd>
                <dd v-else>Скрыто</dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Снижение:&nbsp;</dt>
                <dd>
                    <span v-if="pi.purchaseitem.is_best_proposal_hidden">Скрыто</span>
                    <span v-else>{{ pi.purchaseitem.best_proposal_discount_percent }}%</span>
                </dd>
            </li>
            <!-- <li>, is_best_proposal_hidden: <span v-text="pi.purchaseitem.is_best_proposal_hidden"></span></li> -->
        </ul>

        <ul class="atmo-entity-item-details">
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li v-if="pi.status.code === 'accepting'">
                        <section>
                            <span v-if="pi.countdown"
                                ><dt>До окончания приема предложений:</dt>
                                <dd v-text="pi.countdown"></dd
                            ></span>
                            <dt v-if="pi.countdown === false">Прием предложений завершен</dt>
                        </section>
                        <section v-if="pi.countdown && pi.user && pi.user.is_logged_in && pi.user.is_supplier">
                            <button v-if="pi.purchaseitem.has_proposal_from_loggedin_organization" v-on:click.stop="pi.submitOrUpdateProposal" class="atmo-button--small atmo-accented-mid">Изменить предложение</button>
                            <button v-else v-on:click.stop="pi.submitOrUpdateProposal" class="atmo-button--small">Подать предложение</button>
                        </section>
                    </li>
                    <li v-if="pi.status.code === 'summarizing'">Прием предложений завершен</li>
                    <li v-if="pi.status.code === 'summarized'">
                        <dt>Подведены итоги:</dt>
                        <dd>{{ pi.purchaseitem.results_protocol_date | formatdate('removeSeconds') }}</dd>
                    </li>
                    <li v-if="pi.status.code === 'failed'">
                        <dt>Подведены итоги:</dt>
                        <dd>{{ pi.purchaseitem.proposal_accept_end_date | formatdate('removeSeconds') }}</dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li>
                        <dt>Предмет закупки:</dt>
                        <dd v-text="pi.purchaseitem.description"></dd>
                    </li>
                    <li>
                        <dt>Организация:</dt>
                        <dd><a v-bind:href="'/organizations/' + pi.purchaseitem.customer_organization_id" v-text="pi.purchaseitem.customer_organization_title" target="_blank"></a></dd>
                    </li>
                    <li v-if="pi.hasSupplier">
                        <dt>Поставщик:</dt>
                        <dd><a v-bind:href="'/organizations/' + pi.purchaseitem.supplier_organization_id" v-text="pi.purchaseitem.supplier_organization_title" target="_blank"></a></dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li>
                        <dt>Планируемая дата исполнения:</dt>
                        <dd>{{ pi.purchaseitem.planned_delivery_date | formatdate('onlyDate') }}</dd>
                    </li>
                    <li>Количество позиций: <b v-text="pi.purchaseitem.items_count"></b></li>
                    <li v-if="pi.hasDeliveryAddresses">
                        <dt>Место доставки или указание на самовывоз:</dt>
                        <dd v-for="(address, index) in pi.purchaseitem.delivery_addresses" v-text="address" v-bind:key="index"></dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li>
                        <a v-bind:href="`/purchases/grouped/${pi.elpc.group}/item/${pi.purchaseitem.id}/view`" target="_blank"><i class="atmo-icon-search"></i><span>Информация о закупке</span></a>
                    </li>
                    <li v-if="pi.purchaseitem.hasProposals()">
                        <a v-bind:href="`/purchases/grouped/${pi.elpc.group}/item/${pi.purchaseitem.id}/view#atmo-proposals`" target="_blank"><i class="atmo-icon-dynamic"></i><span>Динамика предложений</span></a>
                    </li>
                    <li v-if="pi.hasProtocol">
                        <a v-bind:href="`/purchases/grouped/${pi.elpc.group}/item/${pi.purchaseitem.id}/view#atmo-supplier-info`" target="_blank"><i class="atmo-icon-shield"></i><span>Итоговый протокол</span></a>
                    </li>
                    <li v-if="pi.hasContract">
                        <a v-bind:href="`/purchases/grouped/${pi.elpc.group}/item/${pi.purchaseitem.id}/view#atmo-contract`" target="_blank"><i class="atmo-icon-contract"></i><span>Контракт</span></a>
                    </li>
                    <li><DealStageActionButton v-bind:group="pi.elpc.group" v-bind:buttondata="pi.buttondata"></DealStageActionButton></li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import DealStageActionButton from './DealStageActionButton.view.vue';

export default {
    props: {
        pi: { required: true, type: Object }
    },
    components: {
        DealStageActionButton
    }
};
</script>
