var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group" },
    [
      _c("h5", [_vm._t("default")], 2),
      _c("text-row", {
        attrs: {
          value: _vm.getBankTypeTitle(_vm.supplierAccounting.bank_type),
          label: "Вид обслуживающей организации",
        },
      }),
      _c("text-row", {
        attrs: { label: "БИК банка" },
        model: {
          value: _vm.supplierAccounting.bik,
          callback: function ($$v) {
            _vm.$set(_vm.supplierAccounting, "bik", $$v)
          },
          expression: "supplierAccounting.bik",
        },
      }),
      _c("text-row", {
        attrs: { label: "Название банка" },
        model: {
          value: _vm.supplierAccounting.bank_name,
          callback: function ($$v) {
            _vm.$set(_vm.supplierAccounting, "bank_name", $$v)
          },
          expression: "supplierAccounting.bank_name",
        },
      }),
      _c("text-row", {
        attrs: { label: "Город банка" },
        model: {
          value: _vm.supplierAccounting.bank_city,
          callback: function ($$v) {
            _vm.$set(_vm.supplierAccounting, "bank_city", $$v)
          },
          expression: "supplierAccounting.bank_city",
        },
      }),
      _c("text-row", {
        attrs: { label: "Кор. счет" },
        model: {
          value: _vm.supplierAccounting.ks,
          callback: function ($$v) {
            _vm.$set(_vm.supplierAccounting, "ks", $$v)
          },
          expression: "supplierAccounting.ks",
        },
      }),
      _c("text-row", {
        attrs: { label: "Расчетный счет" },
        model: {
          value: _vm.supplierAccounting.rs,
          callback: function ($$v) {
            _vm.$set(_vm.supplierAccounting, "rs", $$v)
          },
          expression: "supplierAccounting.rs",
        },
      }),
      _vm.supplierAccounting.bank_type !== "bank"
        ? _c("text-row", {
            attrs: { label: "Лицевой счет" },
            model: {
              value: _vm.supplierAccounting.ls,
              callback: function ($$v) {
                _vm.$set(_vm.supplierAccounting, "ls", $$v)
              },
              expression: "supplierAccounting.ls",
            },
          })
        : _vm._e(),
      _vm.supplierAccounting.bank_type !== "bank"
        ? _c("text-row", {
            attrs: { label: "ИНН финансового органа" },
            model: {
              value: _vm.supplierAccounting.fo_inn,
              callback: function ($$v) {
                _vm.$set(_vm.supplierAccounting, "fo_inn", $$v)
              },
              expression: "supplierAccounting.fo_inn",
            },
          })
        : _vm._e(),
      _vm.supplierAccounting.bank_type !== "bank"
        ? _c("text-row", {
            attrs: { label: "Полное наименование финансового органа" },
            model: {
              value: _vm.supplierAccounting.fo_name,
              callback: function ($$v) {
                _vm.$set(_vm.supplierAccounting, "fo_name", $$v)
              },
              expression: "supplierAccounting.fo_name",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }