<template>
    <on-off v-slot:default="onoff" v-bind:options="{ id: 'global-waiting-spinner', onEsc: false, onBody: false }">
        <div v-if="onoff.ison" class="atmo-popup-wrapper">
            <div class="atmo-spinner-branded">
                <img src="@themes/atmo/assets/images/loaders/spinner-branded.svg" alt="Идет загрузка..." />
            </div>
        </div>
    </on-off>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';

export default {
    components: {
        'on-off': new OnOff()
    }
};
</script>
