'use strict';

import deepmerge from 'deepmerge';
import { isArray, isEmpty } from 'lodash';

export interface IPurchaseRequestFilter {
    [index: string]: unknown;
    purchaseName: null | string; // DEPRECATED:;
    purchaseCategories: number[] | null;
    municipalities: number[] | null;
    conditionname: null | string;
    orderType: null | number;
    customer: null | string;
    regNumber: null | string;
    orderDateStart: null | string;
    orderDateFinish: null | string;
    priceStartFrom: null | number;
    priceStartTo: null | number;
    purchaseObject: null | string;
}

export default class PurchaseRequestFilter implements IPurchaseRequestFilter {
    [index: string]: unknown;
    purchaseName: string | null; // DEPRECATED:;
    purchaseCategories: number[] | null;
    municipalities: number[] | null;
    conditionname: string | null;
    orderType: number | null;
    customer: string | null;
    regNumber: string | null;
    orderDateStart: string | null;
    orderDateFinish: string | null;
    priceStartFrom: number | null;
    priceStartTo: number | null;
    purchaseObject: string | null;

    constructor(purchaseRequestFilterRawData?: IPurchaseRequestFilter) {
        this.purchaseName = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.purchaseName) as string;
        this.purchaseCategories = purchaseRequestFilterRawData?.purchaseCategories ?? [];
        this.municipalities = purchaseRequestFilterRawData?.municipalities ?? [];
        this.conditionname = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.conditionname) as string;
        this.orderType = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.orderType) as number;
        this.customer = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.customer) as string;
        this.regNumber = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.regNumber) as string;
        this.orderDateStart = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.orderDateStart) as string;
        this.orderDateFinish = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.orderDateFinish) as string;
        this.priceStartFrom = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.priceStartFrom) as number;
        this.priceStartTo = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.priceStartTo) as number;
        this.purchaseObject = this.actualOrDefault(purchaseRequestFilterRawData, purchaseRequestFilterRawData?.purchaseObject) as string;
    }

    private actualOrDefault(purchaseRequestFilterRawData?: IPurchaseRequestFilter, actualValue?: unknown, defaultValue?: unknown): unknown {
        return purchaseRequestFilterRawData && actualValue ? actualValue : defaultValue ? defaultValue : null;
    }

    public static nullify(filter: IPurchaseRequestFilter): IPurchaseRequestFilter {
        const nullified = deepmerge({}, filter);

        Object.keys(nullified).forEach((key: string) => {
            isArray(nullified[key]) && isEmpty(nullified[key]) && (nullified[key] = null);
        });

        return nullified;
    }
}
