'use strict';

import CommonRequest from '../common/CommonRequest';
import ICommonRequest from '../common/interfaces/ICommonRequest';
import DeliverableSearchRequestFilterVO from './DeliverableSearchRequestFilter.valueobject';

export interface IRawRequestData {
    filter?: DeliverableSearchRequestFilterVO;
    page?: number;
}

export default class DeliverableSearchRequestVO extends CommonRequest implements ICommonRequest {
    [index: string]: unknown;

    filter: DeliverableSearchRequestFilterVO;
    page: number;

    constructor(data?: IRawRequestData) {
        super();

        this.filter = new DeliverableSearchRequestFilterVO(!!data && data.filter ? data.filter : undefined);
        this.page = data && data.page ? data.page : 1;
    }
}
