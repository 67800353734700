var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "atmo-content-group" }, [
    _c("h6", [_vm._v("Требования к поставщикам")]),
    _c(
      "ul",
      { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
      [
        _vm.$props.required.a31s1p1
          ? _c(
              "li",
              { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
              [
                _vm._m(0),
                _c("dd", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$props.confirmed.a31s1p1,
                        expression: "$props.confirmed.a31s1p1",
                      },
                    ],
                    staticClass: "atmo-icon-checkmark",
                    attrs: {
                      disabled: _vm.isDataSigned,
                      required: "",
                      type: "checkbox",
                      name: "federal-law-a31s1p1",
                      id: "federal-law-a31s1p1",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.$props.confirmed.a31s1p1)
                        ? _vm._i(_vm.$props.confirmed.a31s1p1, null) > -1
                        : _vm.$props.confirmed.a31s1p1,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.$props.confirmed.a31s1p1,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.$props.confirmed,
                                "a31s1p1",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.$props.confirmed,
                                "a31s1p1",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.$props.confirmed, "a31s1p1", $$c)
                        }
                      },
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm.$props.required.a31s1p1
          ? _c("li", { staticClass: "atmo-wide-text" }, [
              _c("dt", [
                _vm._v(
                  "Следующими документами подтверждаю соответствие указанным требованиям:"
                ),
              ]),
            ])
          : _vm._e(),
        _vm.$props.required.a31s1p1
          ? _c("li", [
              _c("table", { staticClass: "atmo-table-slim atmo-table-fixed" }, [
                _c(
                  "tbody",
                  _vm._l(
                    _vm.$props.confirmed.a31s1p1_details.supporting_documents,
                    function (document, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticStyle: { width: "50%" } }, [
                          _vm._v(_vm._s(document.description) + ": "),
                        ]),
                        _c(
                          "td",
                          [
                            _c("editable-file-attachments-list-controller", {
                              attrs: {
                                initiallist: document.attachment
                                  ? [document.attachment]
                                  : [],
                                isrequired: true,
                                origin: "requirements",
                                maxfilesallowed: 1,
                              },
                              on: {
                                "fileattachment:changed":
                                  _vm.$props.controller
                                    .updateSupplierRequirementsFileAttachments,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (efalc) {
                                      return [
                                        _c("table", [
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "atmo-left" },
                                                [
                                                  !efalc.isempty
                                                    ? _c("span", [
                                                        _c("button", {
                                                          staticClass:
                                                            "atmo-button-link",
                                                          attrs: {
                                                            title:
                                                              "Скачать файл",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              efalc.list[0].name
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return efalc.downloadFileAttachment(
                                                                efalc.list[0]
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ])
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "atmo-error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Прикрепите файл"
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "atmo-tc-button-icon atmo-center",
                                                  attrs: {
                                                    title: "Прикрепить файл",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "atmo-button-icon",
                                                      attrs: {
                                                        disabled:
                                                          efalc.islistfull ||
                                                          _vm.isDataSigned,
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _c("i", {
                                                          staticClass:
                                                            "atmo-icon-attachment",
                                                        }),
                                                      ]),
                                                      _c("input", {
                                                        attrs: {
                                                          type: "file",
                                                          accept:
                                                            ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return efalc.add(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "atmo-tc-button-icon atmo-center",
                                                  attrs: {
                                                    title: "Удалить файл",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "atmo-button-icon",
                                                      attrs: {
                                                        disabled:
                                                          efalc.isempty ||
                                                          _vm.isDataSigned,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return efalc.remove(
                                                            efalc.list[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "atmo-icon-trash",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        _vm.$props.required.a31s1ps3_5ps7_11
          ? _c(
              "li",
              { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
              [
                _vm._m(1),
                _c("dd", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$props.confirmed.a31s1ps3_5ps7_11,
                        expression: "$props.confirmed.a31s1ps3_5ps7_11",
                      },
                    ],
                    staticClass: "atmo-icon-checkmark",
                    attrs: {
                      disabled: _vm.isDataSigned,
                      required: "",
                      type: "checkbox",
                      name: "federal-law-a31s1ps3_5ps7_11",
                      id: "federal-law-a31s1ps3_5ps7_11",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.$props.confirmed.a31s1ps3_5ps7_11
                      )
                        ? _vm._i(_vm.$props.confirmed.a31s1ps3_5ps7_11, null) >
                          -1
                        : _vm.$props.confirmed.a31s1ps3_5ps7_11,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.$props.confirmed.a31s1ps3_5ps7_11,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.$props.confirmed,
                                "a31s1ps3_5ps7_11",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.$props.confirmed,
                                "a31s1ps3_5ps7_11",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.$props.confirmed,
                            "a31s1ps3_5ps7_11",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm.$props.required.a31s1_1
          ? _c(
              "li",
              { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
              [
                _vm._m(2),
                _c("dd", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$props.confirmed.a31s1_1,
                        expression: "$props.confirmed.a31s1_1",
                      },
                    ],
                    staticClass: "atmo-icon-checkmark",
                    attrs: {
                      disabled: _vm.isDataSigned,
                      required: "",
                      type: "checkbox",
                      name: "federal-law-a31s1_1",
                      id: "federal-law-a31s1_1",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.$props.confirmed.a31s1_1)
                        ? _vm._i(_vm.$props.confirmed.a31s1_1, null) > -1
                        : _vm.$props.confirmed.a31s1_1,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.$props.confirmed.a31s1_1,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.$props.confirmed,
                                "a31s1_1",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.$props.confirmed,
                                "a31s1_1",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.$props.confirmed, "a31s1_1", $$c)
                        }
                      },
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm.$props.required.supplier_reqs_sonko
          ? _c(
              "li",
              { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
              [
                _vm._m(3),
                _c("dd", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$props.confirmed.supplier_reqs_sonko,
                        expression: "$props.confirmed.supplier_reqs_sonko",
                      },
                    ],
                    staticClass: "atmo-icon-checkmark",
                    attrs: {
                      disabled: _vm.isDataSigned,
                      required: "",
                      type: "checkbox",
                      name: "requirement-sonko",
                      id: "requirement-sonko",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.$props.confirmed.supplier_reqs_sonko
                      )
                        ? _vm._i(
                            _vm.$props.confirmed.supplier_reqs_sonko,
                            null
                          ) > -1
                        : _vm.$props.confirmed.supplier_reqs_sonko,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.$props.confirmed.supplier_reqs_sonko,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.$props.confirmed,
                                "supplier_reqs_sonko",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.$props.confirmed,
                                "supplier_reqs_sonko",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.$props.confirmed,
                            "supplier_reqs_sonko",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm.$props.required.additional.state.is_required
          ? _c(
              "li",
              { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
              [
                _vm._m(4),
                _c("dd", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.$props.confirmed.additional.state
                            .is_confirmed_at_contract,
                        expression:
                          "$props.confirmed.additional.state.is_confirmed_at_contract",
                      },
                    ],
                    staticClass: "atmo-icon-checkmark",
                    attrs: {
                      disabled: _vm.isDataSigned,
                      required: "",
                      type: "checkbox",
                      name: "requirement-additional",
                      id: "requirement-additional",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.$props.confirmed.additional.state
                          .is_confirmed_at_contract
                      )
                        ? _vm._i(
                            _vm.$props.confirmed.additional.state
                              .is_confirmed_at_contract,
                            null
                          ) > -1
                        : _vm.$props.confirmed.additional.state
                            .is_confirmed_at_contract,
                    },
                    on: {
                      change: function ($event) {
                        var $$a =
                            _vm.$props.confirmed.additional.state
                              .is_confirmed_at_contract,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.$props.confirmed.additional.state,
                                "is_confirmed_at_contract",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.$props.confirmed.additional.state,
                                "is_confirmed_at_contract",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.$props.confirmed.additional.state,
                            "is_confirmed_at_contract",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm.$props.required.additional.state.is_required
          ? _c(
              "li",
              [
                _c("FileAttachmentSlimWidget.view", {
                  attrs: {
                    documents: _vm.$props.confirmed.additional.requirements,
                    update:
                      _vm.$props.confirmed.additional.updateAttachment.bind(
                        _vm.$props.confirmed.additional
                      ),
                    is_disabled: _vm.isDataSigned,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "federal-law-a31s1p1" } }, [
        _vm._v(
          "Подтверждаю, что соответствую требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "federal-law-a31s1ps3_5ps7_11" } }, [
        _vm._v(
          "Подтверждаю, что соответствую требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "federal-law-a31s1_1" } }, [
        _vm._v(
          "Подтверждаю, что соответствую требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "requirement-sonko" } }, [
        _vm._v(
          "Подтверждаю, что организация является социально-ориентированной некоммерческой организацией (СОНКО):"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "requirement-additional" } }, [
        _vm._v("Подтверждаю, что соответствую дополнительным требованиям:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }