'use strict';

import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import Config from '@core/js/ddriven/application/config/Config';
import DateCoundown, { ICountdownResult, ICountdownResultFormatted } from '@lib/js/src/misc/DateCountdown';
import PurchaseItemVO from '@core/js/ddriven/domain/model/purchases/view/list/PurchaseItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

interface IData {
    countdown: null | string;
}

@Component
export default class PurchaseItem extends BaseViewModel {
    constructor() {
        super();
        this.name = 'PurchaseItem';
    }

    @Prop({ required: true, type: Object }) readonly elpc!: Vue;
    @Prop({ required: true, type: Object }) readonly purchaseitem!: PurchaseItemVO;

    mounted() {
        this.hasCountdown && this.initializeCountdown(this);
    }

    data(): IData {
        return {
            countdown: null
        };
    }

    /**
     * Computed
     */
    get status() {
        return this.getStatus.call(this);
    }

    get hasSupplier() {
        return this.$props.purchaseitem.supplier_organization_id !== null;
    }

    get hasDeliveryAddresses() {
        return this.$props.purchaseitem.delivery_addresses.length > 0;
    }

    get hasAllSummaryItemsVisible() {
        return [7, 8].indexOf(this.status.id) === -1;
    }

    get hasContract() {
        return this.$props.purchaseitem.contract !== null;
    }

    get hasProtocol() {
        return this.$props.purchaseitem.results_protocol_date !== null;
    }

    get hasCountdown() {
        return this.status.code === 'accepting';
    }

    get user() {
        return this.$store.getters['rearchitected/users/user'];
    }

    get buttondata() {
        return {
            isActive: this.$props.purchaseitem.active_deal_stage !== null,
            purchaseId: this.$props.purchaseitem.id,
            hash: this.$props.purchaseitem.active_deal_stage ? this.$props.purchaseitem.active_deal_stage.hash : null,
            buttonText: this.$props.purchaseitem.active_deal_stage ? this.$props.purchaseitem.active_deal_stage.title : null
        };
    }

    /**
     * Methods
     */
    getStatusClass(classPrefix: string) {
        return `${classPrefix}${this.status.code}`;
    }

    submitOrUpdateProposal() {
        ApplicationServiceLocator.get('supplier').checkBeforeProposal(this.$props.purchaseitem.id);
    }

    /**
     * Prototype general methods.
     */
    getStatus() {
        return this.$props.elpc.dictionaries.statuses.find((dictionaryStatus: ExtendedDictionaryItem) => {
            return this.$props.purchaseitem.status_id === dictionaryStatus.id;
        });
    }

    initializeCountdown(vm: Vue) {
        new DateCoundown(vm.$props.purchaseitem.proposal_accept_end_date, Config.get('timezone.customer') as string, (countdown: ICountdownResult, formatted: ICountdownResultFormatted) => {
            const countdownString = countdown.days > 0 ? `${formatted.days} ${formatted.dayWord} ` : '';
            vm.$data.countdown = countdown.done ? false : `${countdownString}${formatted.hours}:${formatted.minutes}:${formatted.seconds}`;
        });
    }
}
