<template>
    <on-off v-slot:default="onoff" v-bind:options="{ id: 'kbk-selector-popup', onEsc: false, onBody: false, nobodyscroll: true }">
        <div v-show="onoff.ison" class="atmo-popup-wrapper">
            <kbk-limit-selector-popup-controller v-slot:default="kbkspc">
                <div class="atmo-popup-content atmo-standard-selector-popup atmo-kbk">
                    <div v-if="kbkspc.isloading" class="atmo-spinner"><img src="../../../../../assets/images/loaders/spinner-sector.svg" alt="" /></div>

                    <button v-on:click.stop="kbkspc.exit" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                    <h5>Выберите лимит КБК</h5>
                    <p>Год плана-графика лимитов: <span v-text="kbkspc.request.year"></span>; Тип закупки: <span v-text="kbkspc.purchaseTypeTitle"></span></p>

                    <header>
                        <KBKLimitSelectorFilter.view v-bind:controller="kbkspc" />
                    </header>

                    <main>
                        <KBKLimitSelectorList.view v-bind:key="kbkspc.random()" v-bind:controller="kbkspc" v-bind:kbkitems="kbkspc.list"> </KBKLimitSelectorList.view>
                    </main>

                    <footer>
                        <StandardPagination.view v-if="!kbkspc.islistempty" v-bind:pagination="kbkspc.pagination" v-bind:pageupdatehandler="kbkspc.pageUpdate" />
                    </footer>
                </div>
            </kbk-limit-selector-popup-controller>
        </div>
    </on-off>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import StandardPaginationView from '@core/views/layouts/common/StandardPagination.view.vue';
import KBKLimitSelectorPopupController from '@core/js/viewmodels/purchases/item/update/kbk-limits/KBKLimitSelectorPopupController.viewmodel';

import KBKLimitSelectorListView from './KBKLimitSelectorList.view.vue';
import KBKLimitSelectorFilterView from './KBKLimitSelectorFilter.view.vue';

export default {
    components: {
        'on-off': new OnOff(),
        'kbk-limit-selector-popup-controller': KBKLimitSelectorPopupController,

        'StandardPagination.view': StandardPaginationView,
        'KBKLimitSelectorList.view': KBKLimitSelectorListView,
        'KBKLimitSelectorFilter.view': KBKLimitSelectorFilterView
    }
};
</script>
