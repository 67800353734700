<template>
    <confirmation-dialogue v-slot:default="cd" v-bind:id="'protocol-summarization-confirmation-popup'">
        <on-off v-slot:default="onoff" v-bind:options="{ id: cd.$props.id, onEsc: false, onBody: false }">
            <div v-if="onoff.ison" class="atmo-popup-wrapper">
                <div class="atmo-popup-content">
                    <button v-on:click.stop="cd.cancel" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                    <header>
                        <h5>Уважаемый пользователь!</h5>
                    </header>

                    <main>
                        <p>Обращаем Ваше внимание, что данное действие необратимо.</p>
                        <p>Нажимая кнопку "Сформировать итоговый протокол" Вы подтверждаете выбор поставщика для данной закупки.</p>
                    </main>

                    <footer>
                        <ul class="atmo-buttons atmo-align-right">
                            <li><button v-on:click="cd.cancel" class="atmo-button-inverse atmo-dimmed">Отмена</button></li>
                            <li><button v-on:click="cd.confirm" class="atmo-button">Сформировать итоговый протокол</button></li>
                        </ul>
                    </footer>
                </div>
            </div>
        </on-off>
    </confirmation-dialogue>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import ConfirmationDialogue from '@lib/js/src/vue/components/ConfirmationDialogue.viewmodel.ts';

export default {
    components: {
        'on-off': new OnOff(),
        'confirmation-dialogue': ConfirmationDialogue
    }
};
</script>
