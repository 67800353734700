import { render, staticRenderFns } from "./DeliverableDetails.view.vue?vue&type=template&id=3e3c6851&"
import script from "./DeliverableDetails.view.vue?vue&type=script&lang=js&"
export * from "./DeliverableDetails.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-dev/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e3c6851')) {
      api.createRecord('3e3c6851', component.options)
    } else {
      api.reload('3e3c6851', component.options)
    }
    module.hot.accept("./DeliverableDetails.view.vue?vue&type=template&id=3e3c6851&", function () {
      api.rerender('3e3c6851', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rearchitected/themes/atmo/views/pages/purchases/item/common/partials/deliverables/view/DeliverableDetails.view.vue"
export default component.exports