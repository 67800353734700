'use strict';

export type TStateInput = [boolean, boolean];

export default class RequirementStateVO {
    public is_required: boolean;
    public is_confirmed: boolean;

    // NB: Whether the object is confirmed by supplier at contract timing.
    public is_confirmed_at_contract: boolean;

    constructor(state?: TStateInput) {
        this.is_required = state ? state[0] : false;
        this.is_confirmed = state ? state[1] : false;
        this.is_confirmed_at_contract = false;
    }

    public static toRaw(state: RequirementStateVO): TStateInput {
        return [state.is_required, state.is_confirmed];
    }
}
