'use strict';

import format from 'format-number';

export default class FormatNumber {
    public static configs = {
        default: { integerSeparator: ' ', decimal: ',', padRight: 2, round: 2 },
        nopad: { integerSeparator: ' ', decimal: ',', round: 0 },
        nodecimal: { integerSeparator: ' ', decimal: ',', truncate: 0 }
    };

    public static formatNumber(value: number, config?: Record<string, unknown>): string {
        const formatter = format(config);
        return formatter(value);
    }

    public static createFormatter(config?: Record<string, unknown>) {
        return format(config);
    }
}
