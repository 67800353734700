'use strict';

import AnnexItemEditableVO from '../AnnexItemEditable.valueobject';
import { toXML } from 'jstoxml';
import AnnexDeliverableItemEditableVO from '../../../common/deliverable/update/annex/AnnexDeliverableItemEditable.valueobject';

export interface IParameters {
    user_id: number;
    organization_id: number;
    annex_id: number;
    annex: AnnexItemEditableVO;
    comment: string;
}

export default class AnnexDisputeReplylForXMLVO {
    user_id: number;
    organization_id: number;
    supplementary_agreement_id: number;
    dispute_message: string;
    new_supplementary_agreement_file: {
        name: string;
        stored_document_id: string;
    };
    new_supplementary_agreement_date: string;
    new_specifications: {
        item: {
            id: number;
            purchase_item_id: number;
            okei_id: number;
            ktru_item_id: number;
            okpd_id: number;
            amount: number;
            price: number;
            total_cost: number;
            is_delivery_item: boolean;
            is_manually_added: boolean;
            is_deleted: boolean;
        };
    }[];

    constructor(data: IParameters) {
        this.user_id = data.user_id;
        this.organization_id = data.organization_id;
        this.supplementary_agreement_id = data.annex_id;
        this.dispute_message = data.comment;

        this.new_supplementary_agreement_file = {
            name: data.annex.attachment!.name as string,
            stored_document_id: data.annex.attachment!.file_id as string
        };

        this.new_supplementary_agreement_date = data.annex.signature_date as string;

        this.new_specifications = data.annex.deliverables.unfilteredList.map((deliverable: AnnexDeliverableItemEditableVO) => {
            return {
                item: {
                    id: deliverable.another_id as number,
                    purchase_item_id: deliverable.id as number,
                    okei_id: deliverable.okei_id,
                    ktru_item_id: deliverable.gsc_details.id,
                    okpd_id: deliverable.okpd_id,
                    amount: deliverable.quantity,
                    price: deliverable.price_per_unit,
                    total_cost: deliverable.price_total,
                    is_delivery_item: deliverable.is_delivery,
                    is_manually_added: deliverable.isDuplicate,
                    is_deleted: deliverable.isDeleted
                }
            };
        });
    }

    public toXML(): string {
        const object = JSON.parse(JSON.stringify({ signed_form: { contract_supplementary_agreement_dispute: this } }));
        return toXML(object, { header: true, indent: '    ' });
    }
}
