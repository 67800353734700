'use strict';

import purchaseStatusRawDictionaryData from './data/statuses.json';
import typesRawDictionaryData from './data/types.json';

import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';

export interface IPurchasesDictionaries {
    statuses: null | ExtendedDictionaryItem[];
    types: null | ExtendedDictionaryItem[];
}

export default class State implements IPurchasesDictionaries {
    statuses: null | ExtendedDictionaryItem[];
    types: null | ExtendedDictionaryItem[];

    constructor() {
        this.statuses = purchaseStatusRawDictionaryData.map((purchaseStatusRawDataItem: ExtendedDictionaryItem) => {
            return Object.freeze(
                new ExtendedDictionaryItem({
                    id: purchaseStatusRawDataItem.id,
                    title: purchaseStatusRawDataItem.title,
                    code: purchaseStatusRawDataItem.code,
                    is_active: purchaseStatusRawDataItem.is_active
                })
            );
        });

        this.types = typesRawDictionaryData.map((purchaseTypeRawDataItem: ExtendedDictionaryItem) => {
            return Object.freeze(new ExtendedDictionaryItem(purchaseTypeRawDataItem));
        });
    }
}
