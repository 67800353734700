'use strict';

import ContractRequest from '@core/js/ddriven/application/http/requests/contracts/ContractRequest.valueobject';
import State, { ActionContext } from 'vuex';

const actions = {
    load: async function (context: ActionContext<typeof State, any>, request: ContractRequest): Promise<void> {
        const response = await context.rootState.rearchitected.utils.atmoapi.load('contracts:commercial', request.toQueryString());
        context.commit('setList', response.data.data);
        context.commit('setPagination', response.data.meta);
    }
};

export default actions;
