'use strict';

import { IRawFileAttachmentData } from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import SRDA31s1p1SupportingDocumentVO from './SRDA31s1p1SupportingDocument.valueobject';
import SupplierRequirementDetailsA31s1p1VO from './SupplierRequirementDetailsA31s1p1.valueobject';
import SupplierRequirementAdditionalVO, { TRawAdditional } from './SupplierRequirementAdditional.valueobject';

export type TSelfPOJO = Omit<SupplierRequirementsVO, 'isValid' | 'isA31s1p1DetailsValid' | 'isValidAgainstRequired'>;

export interface IRawSupplierRequirements {
    supplier_reqs_a: boolean;
    supplier_reqs_b: boolean;
    supplier_reqs_c: boolean;
    supplier_reqs_a_text: string | null;
    supplier_required_documents: IRawSupplierRequirementsDocument[];

    /**
     * NB: The initial requirements object was designed as a uniform one
     * and is used as such throughout the entire application. It is more difficult to
     * introduce the new requirements in a non-uniform structure dependent on the stages
     * a purchase is in (create purchase, submit proposal, sign contract etc.)
     *
     * Thus I decided to implement th new requirements as well in a uniform structure
     * as it is simpler.
     */
    supplier_reqs_rating: boolean;
    additional: TRawAdditional;
}

export interface IRawSupplierRequirementsDocument {
    id?: number | string;
    document_name?: string;
    name?: string;
    storedDocument?: IRawFileAttachmentData;
    stored_document?: IRawFileAttachmentData;
}

export default class SupplierRequirementsVO {
    is_only_smb: boolean;
    supplier_reqs_sonko: boolean;
    a31s1ps3_5ps7_11: boolean; // NB: a: article, s: section, p(s): paragraph(s).
    a31s1p1: boolean;
    a31s1_1: boolean;
    a31s1p1_details: SupplierRequirementDetailsA31s1p1VO;
    rating: boolean;
    additional: SupplierRequirementAdditionalVO;

    private static _flags = ['a31s1ps3_5ps7_11', 'a31s1p1', 'a31s1_1', 'supplier_reqs_sonko'];

    constructor(data?: TSelfPOJO) {
        this.is_only_smb = data?.is_only_smb ?? false;
        this.supplier_reqs_sonko = data?.supplier_reqs_sonko ?? false;
        this.a31s1ps3_5ps7_11 = data?.a31s1ps3_5ps7_11 ?? true; // Is "supplier_reqs_b" field in requests to backend API;
        this.a31s1p1 = data?.a31s1p1 ?? false; // Is a "supplier_reqs_a" field;
        this.a31s1_1 = data?.a31s1_1 ?? false; // Is a "supplier_reqs_c" field;
        this.a31s1p1_details = data?.a31s1p1_details ? new SupplierRequirementDetailsA31s1p1VO(data.a31s1p1_details) : new SupplierRequirementDetailsA31s1p1VO(); // See inside the object;
        this.rating = data?.rating ?? false;
        this.additional = data?.additional ?? new SupplierRequirementAdditionalVO();
    }

    public static requirementsConfirmedFactory(required: SupplierRequirementsVO): SupplierRequirementsVO {
        const pojo = JSON.parse(JSON.stringify(required));
        pojo.additional = SupplierRequirementAdditionalVO.fromPOJO(pojo.additional);

        const confirmed = new SupplierRequirementsVO(pojo);

        confirmed.resetFlags();
        return confirmed;
    }

    public isValid(): boolean {
        return (!this.a31s1p1 || (this.a31s1p1 && this.isA31s1p1DetailsValid())) && this.additional.is_valid;
    }

    public isValidAgainstRequired(required: TSelfPOJO): boolean {
        const hasValidFlags = SupplierRequirementsVO._flags.every((flagFieldName: string) => {
            // @ts-ignore
            return this[flagFieldName] === required[flagFieldName];
        });
        const hasValidAttachments =
            this.a31s1p1_details.supporting_documents.length === required.a31s1p1_details.supporting_documents.length &&
            this.a31s1p1_details.supporting_documents.every((document: SRDA31s1p1SupportingDocumentVO) => {
                return document.attachment !== null;
            });

        const hasValidAdditional = this.additional.is_valid_at_contract;

        return hasValidFlags && hasValidAttachments && hasValidAdditional;
    }

    private isA31s1p1DetailsValid(): boolean {
        return this.a31s1p1_details.isValid();
    }

    private resetFlags(): void {
        SupplierRequirementsVO._flags.forEach((flagFieldName: string) => {
            // @ts-ignore
            this[flagFieldName] = false;
        });
    }
}
