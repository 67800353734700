<template>
    <div class="atmo-content-group">
        <h5>Изменить статус закупки</h5>

        <purchase-status-change-widget-controller v-slot:default="pscwc" v-bind:initialid="$props.status.id" v-bind:contractid="this.$props.contractid">
            <div class="atmo-purchase-status-widget atmo-form__field atmo-as-input">
                <p v-text="pscwc.item"></p>

                <dropdown-select v-slot:default="ds" v-bind:list="$props.contractstatuses" v-bind:initialid="$props.status.id" v-on:dropdown:select:selected="pscwc.manageSelection">
                    <!-- v-bind:currentid="pfc.filters.orderType" -->

                    <on-off v-slot:default="onoff">
                        <div class="atmo-dropdown-select">
                            <button type="button" v-on:click.stop="onoff.toggle">
                                <span v-if="ds.hasSelected" v-html="ds.text"></span>
                                <i v-if="!onoff.ison" class="atmo-icon-angle-down"></i>
                                <i v-else class="atmo-icon-angle-up"></i>
                            </button>
                            <ul v-show="onoff.ison" class="atmo-dropdown">
                                <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id)">
                                    <span v-html="item.title"></span>
                                </li>
                            </ul>
                        </div>
                    </on-off>
                </dropdown-select>

                <ul class="atmo-buttons">
                    <li>
                        <button v-bind:disabled="!pscwc.canUpdateStatus" v-on:click="pscwc.updateStatus" class="atmo-button">Изменить статус</button>&nbsp;
                        <!-- <div v-if="pscwc.isloading" class="atmo-spinner"><img src="../../../../../../../assets/images/loaders/spinner-sector.png" alt="Loading spinner"></div> -->
                        <div v-if="pscwc.isloading" class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
                    </li>
                </ul>
            </div>
        </purchase-status-change-widget-controller>
    </div>
</template>

<script>
import DropdownSelect from '@lib/js/src/vue/components/DropdownSelect.viewmodel.ts';
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import PurchaseStatusChangeWidgetController from '@core/js/viewmodels/purchases/item/view/widgets/PurchaseStatusChangeWidgetController.viewmodel';

export default {
    props: {
        contractid: { requied: true, type: Number },
        contractstatuses: { required: true, type: Array },
        status: { required: true }
    },
    components: {
        'dropdown-select': DropdownSelect,
        'on-off': new OnOff(),
        'purchase-status-change-widget-controller': PurchaseStatusChangeWidgetController
    }
};
</script>
