var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "atmo-list" },
    [
      _c("li", [
        _c("dt", [_vm._v("Описание")]),
        _vm._v(" : "),
        _c("dd", { domProps: { textContent: _vm._s(_vm.description) } }),
      ]),
      _vm._l(_vm.requirements, function (requirement, index) {
        return _c("li", { key: index }, [
          _c("dt", { domProps: { textContent: _vm._s(requirement.title) } }),
          _vm._v(" : "),
          _c("dd", {
            domProps: { textContent: _vm._s(requirement.description) },
          }),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }