'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IOnOffEvent from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import UserVO from '../../ddriven/domain/model/users/User.valueobject';
import SupplierService from '@core/js/ddriven/domain/services/supplier/SupplierService';
import DownloadConfigFactory from '@core/js/ddriven/domain/services/downloads/DownloadConfigFactory';
import DownloadService from '../../ddriven/domain/services/downloads/DownloadService';
import ApplicationServiceLocator from '../../ddriven/application/services/ApplicationServiceLocator';

@Component
export default class SupplierDepositReplenishPopupForm extends BaseViewModel {
    amountInputElement: HTMLInputElement | null;

    constructor() {
        super();
        this.name = 'SupplierDepositReplenishPopupForm';
        this.amountInputElement = null;
    }

    updated() {
        this.$nextTick(() => {
            this.amountInputElement = this.$el.querySelector('#deposit-amount');

            this.$data.amount = this.initialAmount;
            this.$data.minimumAmount = this.initialAmount;
            this.$data.email = this.user.email;

            const missing_deposit_amount = this.user.organization.missing_deposit_amount;
            missing_deposit_amount && this.amountInputElement?.setAttribute('min', missing_deposit_amount.toString());
            this.$data.issending = false;
        });
    }

    data() {
        return {
            amount: null,
            email: null,
            minimumAmount: null,
            issending: false
        };
    }

    /**
     * Methods
     */
    close(payload?: IOnOffEvent) {
        // Guard against closing on 'on' state.
        if (payload && payload.ison) {
            return;
        }
        this.$store.commit('rearchitected/application/popups/set', { deposit_invoice_request: false });
    }

    async sendInvoiceToEmail() {
        this.$data.issending = true;
        await ApplicationServiceLocator.get('supplier').sendInvoiceToEmail(this.$data.email, this.$data.amount);
        this.close();
    }

    async downloadInvoice() {
        this.$data.issending = true;
        const config = DownloadConfigFactory.forDepositReplenishInvoice(this.$data.amount);
        await ApplicationServiceLocator.get('download').fileByBrowser(config);
        this.close();
    }

    /**
     * Computeds
     */

    get ison() {
        return this.$store.getters['rearchitected/application/popups/get']('deposit_invoice_request');
    }

    get user() {
        return this.$store.getters['rearchitected/users/user'];
    }

    get initialAmount() {
        return this.$store.getters['rearchitected/users/user'] ? this.$store.getters['rearchitected/users/user'].organization.missing_deposit_amount : null;
    }

    get isInputValid() {
        return this.user && this.user.organization ? this.user.isEmailValid(this.$data.email) && this.user.organization.isMissingDepositAmountValid(this.$data.amount, this.$data.minimumAmount) : false;
    }
}
