'use strict';

import Config from '@core/js/ddriven/application/config/Config';
import PurchaseItemEditableVO from './PurchaseItemEditable.valueobject';
import SpecificationEditableVO from './SpecificationEditable.valueobject';
import SupplierRequirementsVO from './supplier-requirements/SupplierRequirements.valueobject';

interface ErrorsFlags {
    [key: string]: PurchaseItemEditableValidationErrorsVO[keyof PurchaseItemEditableValidationErrorsVO];
}

export default class PurchaseItemEditableValidationErrorsVO {
    private static termsFileds = ['deliverable_group_id', 'purchase_object', 'urgency_reason_id', 'planned_contract_signature_date', 'planned_contract_fulfillment_date', 'supplier_requirements', 'advance_percentage'];
    private static deliverablesFields = ['deliverables', 'kbk_limits', 'contract_draft_attachment'];

    [index: string]: any;

    deliverable_group_id: boolean;
    purchase_object: boolean;
    urgency_reason_id: boolean;
    advance_percentage: boolean;
    planned_contract_signature_date: boolean;
    planned_contract_fulfillment_date: boolean;
    deliverables: boolean;
    kbk_limits: boolean;
    contract_draft_attachment: boolean;
    supplier_requirements: boolean;

    constructor(rawErrors?: ErrorsFlags) {
        this.deliverable_group_id = this.valueOrFalse('deliverable_group_id', rawErrors);
        this.purchase_object = this.valueOrFalse('purchase_object', rawErrors);
        this.urgency_reason_id = this.valueOrFalse('urgency_reason_id', rawErrors);
        this.advance_percentage = this.valueOrFalse('advance_percentage', rawErrors);
        this.planned_contract_signature_date = this.valueOrFalse('planned_contract_signature_date', rawErrors);
        this.planned_contract_fulfillment_date = this.valueOrFalse('planned_contract_fulfillment_date', rawErrors);
        this.deliverables = this.valueOrFalse('deliverables', rawErrors);
        this.kbk_limits = this.valueOrFalse('kbk_limits', rawErrors);
        this.contract_draft_attachment = this.valueOrFalse('contract_draft_attachment', rawErrors);
        this.supplier_requirements = this.valueOrFalse('supplier_requirements', rawErrors);
    }

    public static validate(item: PurchaseItemEditableVO, withoutLimits: boolean): PurchaseItemEditableValidationErrorsVO {
        const rawErrors: ErrorsFlags = {};

        Object.keys(new PurchaseItemEditableValidationErrorsVO()).forEach((key: string) => {
            if (PurchaseItemEditableValidationErrorsVO.isPrimitiveEmpty(item[key])) {
                rawErrors[key] = true;
            }
        });

        rawErrors.urgency_reason_id = item.isUrgentPurchase(item) && item.urgency_reason.id === null;

        rawErrors.advance_percentage = item.with_advance && (!item.advance_percentage || item.advance_percentage <= 0 || item.advance_percentage > 30);

        rawErrors.deliverables = this.hasDeliverablesErrors(item);

        rawErrors.kbk_limits = !withoutLimits && this.hasKBKLimitsErrors(item);

        rawErrors.supplier_requirements = this.hasSupplierRequirementsError(item.supplier_requirements);

        return new PurchaseItemEditableValidationErrorsVO(rawErrors);
    }

    public hasErrors(): boolean {
        const allFields = [...PurchaseItemEditableValidationErrorsVO.termsFileds, ...PurchaseItemEditableValidationErrorsVO.deliverablesFields];
        return allFields.filter(this.isFieldTrue.bind(this)).length > 0;
    }

    public tabbedErrorCounts(): Record<string, any> {
        return {
            terms: PurchaseItemEditableValidationErrorsVO.termsFileds.filter(this.isFieldTrue.bind(this)).length,
            deliverables: PurchaseItemEditableValidationErrorsVO.deliverablesFields.filter(this.isFieldTrue.bind(this)).length
        };
    }

    private isFieldTrue(field: string) {
        return this[field] === true;
    }

    private static isPrimitiveEmpty(value: any): boolean {
        return value === null || value === 0 || (Array.isArray(value) && value.length === 0) || value === '';
    }

    private static hasDeliverablesErrors(item: PurchaseItemEditableVO): boolean {
        return SpecificationEditableVO.hasDeliverablesErrors(item.specification);
    }

    private static hasKBKLimitsErrors(item: PurchaseItemEditableVO): boolean {
        return Config.get('theme.features.kbk_limits') && item.specification.hasKBKLimitsErrors();
    }

    private valueOrFalse(propertyName: string, rawErrors?: ErrorsFlags): any | boolean {
        return rawErrors && rawErrors[propertyName] ? rawErrors[propertyName] : false;
    }

    private static hasSupplierRequirementsError(requirements: SupplierRequirementsVO): boolean {
        return !requirements.isValid();
    }
}
