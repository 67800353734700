<template>
    <div>
        <b-row class="cursor-pointer fs-14 py-1 notification-row" :class="'notification-row-' + variant" @click="show">
            <b-col class="col-auto pr-1 my-auto">
                <b-icon v-if="withCheckbox" id="check" :icon="rowSelected ? 'check2-square' : 'square'" class="row-checkbox" @click="select"/>
            </b-col>
            <b-col class="col-auto px-1 my-auto">
                <b-icon v-if="notification.is_read" icon="circle" :scale="0.7" class="green-color read-icon" title="Пометить непрочитанным" aria-hidden="true" @click="markNotificationsAsUnread" />
                <b-icon v-else icon="circle-fill" :scale="0.7" class="green-color read-icon" title="Пометить прочитанным" aria-hidden="true" @click="markNotificationsAsRead" />
            </b-col>
            <b-col class="px-1 notification-title my-auto">
                {{ notification.type_name }}
            </b-col>
            <b-col class="col-auto text-nowrap date-col my-auto">
                {{ getDateTimeFormatSeconds(notification.created_at) }}
            </b-col>
            <b-col class="col-auto px-1">
                <b-button style="width: 24px; height: 24px" class="p-0" :variant="'custom-secondary-' + variant" @click="trash"><b-icon icon="trash" scale="0.7" class="my-auto" aria-hidden="true" /></b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: 'notification-row',
    props: {
        notification: {
            type: Object,
            required: true
        },
        rowSelected: {
            required: true
        },
        withCheckbox: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: 'grey'
        }
    },
    methods: {
        select(event) {
            this.$emit('select', this.notification.id);
            event.stopPropagation();
        },
        markNotificationsAsRead(event) {
            this.$emit('read', this.notification.id);
            event.stopPropagation();
        },
        markNotificationsAsUnread(event) {
            this.$emit('unread', this.notification.id);
            event.stopPropagation();
        },
        show() {
            this.$router.push({name: 'NotificationShow', params: {id: this.notification.id}});
            this.$emit('click');
        },
        trash(event) {
            this.$emit('delete', this.notification.id);
            event.stopPropagation();
        }
    },
};
</script>
<style scoped>
.row-checkbox {
    position: inherit;
    left: -6px;
}
.read-icon {
    border: 1px solid transparent;
    border-color: var(--green-color);
    border-radius: 7px;
}

.notification-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.notification-row {
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(100%);
}

.notification-row:hover {
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(95%);
}

.notification-row-grey {
    background-color: var(--greyBg-color) !important;
}

.notification-row-white {
    background-color: var(--white-color) !important;
}

.btn-custom-secondary-grey {
    background-color: var(--greyBg-color) !important;
    color: var(--grey-color) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(100%);
}

.btn-custom-secondary-grey:hover {
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(85%);
}

.btn-custom-secondary-white {
    background-color: var(--white-color) !important;
    color: var(--grey-color) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(100%);
}

.btn-custom-secondary-white:hover {
    -webkit-transition: 250ms -webkit-filter linear;
    -moz-transition: 250ms -moz-filter linear;
    -moz-transition: 250ms filter linear;
    -ms-transition: 250ms -ms-filter linear;
    -o-transition: 250ms -o-filter linear;
    transition: 250ms filter linear, 250ms -webkit-filter linear;
    filter: brightness(85%);
}

.date-col {
    opacity: 50%;
}
</style>
