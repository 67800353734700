export default [
    {
        path: '/import_substitution/create',
        name: 'ImportSubstitutionRequestCreate',
        component: () => import(/* webpackChunkName: "main" */ '@/components/import-substitution/import-substitution-request-create')
    },
    {
        path: '/import_substitution',
        redirect: { name: 'ImportSubstitutionRequestsList', params: { path: 'open' } },
        component: () => import(/* webpackChunkName: "main" */ '@/components/import-substitution/import-substitution-requests-list'),
        children: [
            {
                path: ':path',
                name: 'ImportSubstitutionRequestsList',
                component: () => import(/* webpackChunkName: "main" */ '@/components/import-substitution/import-substitution-requests-list')
            }
        ]
    },
    {
        path: '/import_substitution/:id/show',
        name: 'ImportSubstitutionRequestShow',
        component: () => import(/* webpackChunkName: "main" */ '@/components/import-substitution/import-substitution-request-show')
    },
    {
        path: '/import_substitution/:id/reply',
        name: 'ImportSubstitutionReplyCreate',
        component: () => import(/* webpackChunkName: "main" */ '@/components/import-substitution/import-substitution-reply-create')
    }
];
