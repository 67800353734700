'use strict';

import { Component, Prop } from 'vue-property-decorator';
import NoticeRequestFilter, { INoticeRequestFilter } from '@core/js/ddriven/application/http/requests/purchases/NoticeRequestFilter.valueobject';
import EntityFiltersController from './EntityFiltersController.viewmodel';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import isTypeOrNull from '@lib/js/src/misc/isTypeOrNull';

@Component
export default class NoticeFiltersController extends EntityFiltersController {
    $data!: {
        filters: INoticeRequestFilter;
    };
    constructor() {
        super();
        this.name = 'NoticeFiltersController';
    }

    created() {
        this.$data.filters = this.createRequestFilter(this.filterConstructor, this.$props.requestfilter);
    }

    @Prop({
        required: true,
        validator: (prop) => {
            return isTypeOrNull(prop, 'Array');
        }
    })
    readonly types!: ExtendedDictionaryItem[];

    get filterConstructor() {
        return NoticeRequestFilter;
    }
}
