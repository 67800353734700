<template>
    <sign-contract-annex-popup-controller v-slot:default="scapc">
        <on-off v-slot:default="onoff" v-bind:options="{ id: 'sign-contract-annex-popup', onEsc: false, onBody: false }">
            <div v-if="onoff.ison" class="atmo-popup-wrapper">
                <div class="atmo-popup-content atmo-save-purchase-popup">
                    <button v-on:click.stop="onoff.off" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                    <header>
                        <h5>Подписать дополнительное соглашение</h5>

                        <tab-menu-controller v-slot:default="tmc" v-bind:tabmenuid="'sign-contract-tabmenu'">
                            <ul class="atmo-tabs-menu-universal">
                                <li>
                                    <button v-on:click="tmc.select(1)" v-bind:class="{ 'atmo-active': tmc.isSelected(1) }">Информация о документе</button>
                                </li>
                                <li>
                                    <button v-on:click="tmc.select(2)" v-bind:class="{ 'atmo-active': tmc.isSelected(2) }">XML-представление данных</button>
                                </li>
                            </ul>
                        </tab-menu-controller>
                    </header>

                    <tab-menu-tabs-controller v-slot:default="tmtc" v-bind:tabmenuid="'sign-contract-tabmenu'">
                        <main class="atmo-tabs-container">
                            <TabContractAnnexInfoPreview.view v-if="tmtc.isSelected(1)" v-bind:controller="scapc" v-bind:certificate="scapc.currentParsedCertificate" />

                            <TabXMLPreview.view v-if="tmtc.isSelected(2)" v-bind:xml="scapc.xml" v-bind:certificate="scapc.currentParsedCertificate" />
                        </main>
                    </tab-menu-tabs-controller>

                    <footer>
                        <CertificateInfo.view v-if="scapc.hasCertificatesLoaded" v-bind:certificate="scapc.currentParsedCertificate" />

                        <ul class="atmo-buttons">
                            <li><button v-on:click="onoff.off" class="atmo-button-inverse atmo-dimmed">Отмена</button></li>
                            <li v-if="!scapc.isDataSigned && scapc.hasCertificatesLoaded && scapc.currentParsedCertificate">
                                <button v-on:click="scapc.signData" class="atmo-button">Подписать</button>
                                <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
                            </li>
                            <li v-if="scapc.isDataSigned">
                                <button v-on:click="scapc.signAnnex" class="atmo-button">Отправить</button>&nbsp;
                                <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
                            </li>
                        </ul>
                    </footer>
                </div>
            </div>
        </on-off>
    </sign-contract-annex-popup-controller>
</template>

<script>
import SignContractAnnexPopupController from '@core/js/viewmodels/purchases/item/view/widgets/contract/annexes/sign/SignContractAnnexPopupController.viewmodel';
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import TabMenuController from '@lib/js/src/vue/components/tabmenu/TabMenuController.viewmodel';
import TabMenuTabsController from '@lib/js/src/vue/components/tabmenu/TabMenuTabsController.viewmodel';

import TabContractAnnexInfoPreview from './TabContractAnnexInfoPreview.view.vue';
import TabXMLPreview from 'themes/views/overlays/popups/common/partials/TabXMLPreview.view.vue';
import CertificateInfoView from 'themes/views/overlays/popups/common/partials/CertificateInfo.view.vue';

export default {
    components: {
        'on-off': new OnOff(),
        'sign-contract-annex-popup-controller': SignContractAnnexPopupController,
        'tab-menu-controller': TabMenuController,
        'tab-menu-tabs-controller': TabMenuTabsController,
        'TabContractAnnexInfoPreview.view': TabContractAnnexInfoPreview,
        'TabXMLPreview.view': TabXMLPreview,
        'CertificateInfo.view': CertificateInfoView
    }
};
</script>
