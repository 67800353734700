'use strict';

import DownloadConfig, { Body } from '../DownloadConfig.valueobject';

export default class Invoice extends DownloadConfig {
    private static _type = 'invoice';

    constructor(amount: number) {
        super(Invoice._type);
        this.verb = 'POST';
        this.endpoint = '/account/invoice/download';
        this.body = this.setBody(amount);
    }

    public setBody(amount: number): Body {
        return { amount: amount };
    }

    // NB: Still to be tested when testing the DownloadService.fileByBrowser method;
    public setName(contentDispositionHTTPHeader: string) {
        const prefix = this.getFilePrefixFromHeader(contentDispositionHTTPHeader);

        this.name = `Счет-договор_СМЗ-${prefix}.pdf`;
    }

    private getFilePrefixFromHeader(contentDispositionHTTPHeader: string): string {
        const matches = contentDispositionHTTPHeader.match(/\d{4}-\d{6}/);

        if (!matches) {
            throw new Error(`ATMO Exception: the actual Content-Disposition header value ("${contentDispositionHTTPHeader}") does not have the required data.`);
        }

        return matches[0];
    }
}
