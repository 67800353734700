'use strict';

import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import { clone } from 'lodash';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import ContractItemDetailsVO from '@core/js/ddriven/domain/model/contracts/ContractItemDetails.valueobject';
import AnnexItemEditableVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItemEditable.valueobject';
import { IErrors } from '@core/js/ddriven/domain/model/common/deliverable/update/annex/AnnexDeliverableItemEditables.collection';
import AnnexDeliverableItemEditableVO from '@core/js/ddriven/domain/model/common/deliverable/update/annex/AnnexDeliverableItemEditable.valueobject';
import { Command, IFileAttachmentVMEvent } from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import UpdateContractAnnexRequestVO from '@core/js/ddriven/application/http/requests/contracts/UpdateContractAnnexRequest.valueobject';
import { IRawAnnexItemData } from '@core/js/ddriven/domain/model/contracts/annex/AnnexItem.valueobject';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

enum EMode {
    Create = 'create',
    Edit = 'edit'
}

interface IData {
    deliverables: AnnexDeliverableItemEditableVO[];
    errors: IErrors;
    required: {
        signature_date: number | null;
        annex_attachment: FileAttachmentVO | null;
    };
    price_total_contract: number;
    price_total_annex: number;
    has_delivery_duplicate: boolean;
}

interface IDeliverableInputs {
    display_id: string;
    quantity: number;
    price_per_unit: number;
}

interface IAllInputs {
    deliverables: IDeliverableInputs[];
    date: string | null;
    attachment: FileAttachmentVO | null;
}

@Component
export default class ContractAnnexUpdateController extends BaseViewModel {
    [index: string]: any;

    private _annexitemeditable?: AnnexItemEditableVO;

    constructor() {
        super();
        this.name = 'ContractAnnexUpdateController';
    }

    @Prop({ required: true, type: String }) readonly mode!: EMode;
    @Prop({ required: true, type: Object }) readonly contract!: ContractItemDetailsVO;

    async created() {
        this._annexitemeditable = await this.annexItemEditableFactory(this.$props.contract);
        this.$data.deliverables = clone(this._annexitemeditable.deliverables.list);
        this.$data.errors = clone(this._annexitemeditable.deliverables.errors);

        this.$data.price_total_contract = this._annexitemeditable.price_total_contract;
        this.$data.price_total_annex = this._annexitemeditable.price_total_annex;
        this.$data.required.signature_date = this._annexitemeditable.signature_date;
    }

    data(): IData {
        return {
            deliverables: [],
            errors: {
                quantity: false,
                price_per_unit: false,
                price_total: false,
                kbk_limit: false
            },
            required: {
                signature_date: null,
                annex_attachment: null
            },
            price_total_contract: 0,
            price_total_annex: 0,
            has_delivery_duplicate: false
        };
    }

    /**
     * Computed
     */
    get deliverableinputs(): IDeliverableInputs[] {
        return this.$data.deliverables.map((deliverable: AnnexDeliverableItemEditableVO): IDeliverableInputs => {
            return {
                display_id: deliverable.display_id,
                quantity: deliverable.quantity,
                price_per_unit: deliverable.price_per_unit
            };
        });
    }

    get allinputs(): IAllInputs {
        return {
            deliverables: this.deliverableinputs,
            date: this.$data.required.signature_date,
            attachment: this.$data.required.annex_attachment
        };
    }

    get hasErrors(): boolean {
        return (
            Object.values(this.$data.errors as IErrors).some((value: boolean) => {
                return value === true;
            }) ||
            Object.values(this.$data.required as IData['required']).some((value: unknown | null) => {
                return value === null;
            })
        );
    }

    get hasDateError(): boolean {
        return this.$data.required.signature_date === null;
    }

    /**
     * Watch
     */
    @Watch('deliverableinputs')
    onDeliverableinputsChanged(deliverableinputs: IDeliverableInputs[], oldDeliverableInputs: IDeliverableInputs[]) {
        if (!this.hasInputsChanged(deliverableinputs, oldDeliverableInputs)) {
            return;
        }

        this.updateData();
    }

    @Watch('allinputs')
    onAllinputsChanged(allinputs: IAllInputs) {
        this._annexitemeditable!.attachment = this.$data.required.annex_attachment;
        this._annexitemeditable!.signature_date = this.$data.required.signature_date;
        this._annexitemeditable!.price_total_annex = this.$data.price_total_annex;

        this.$emit('annexitemeditable:changed', this._annexitemeditable);
    }

    /**
     * Methods
     */
    public insertDeliverable(deliverable: AnnexDeliverableItemEditableVO): void {
        this._annexitemeditable?.deliverables.duplicate(deliverable);
        this._annexitemeditable!.deliverables.validate();
        this.$data.deliverables = clone(this._annexitemeditable!.deliverables.list);
        this.$data.has_delivery_duplicate = this._annexitemeditable!.deliverables.hasDeliveryDuplicate;
    }

    public async removeDeliverableDuplicate(deliverable: AnnexDeliverableItemEditableVO) {
        const isConfirmed = await new Promise((resolve) => {
            this.$root.$emit('public:onoff:toggle', { id: 'contract-annex-deliverable-duplicate-remove-confirmation-popup', ison: true, data: { respond: resolve } } as IOnOffOnToggleEventPayload);
        });
        if (!isConfirmed) {
            return;
        }

        this._annexitemeditable!.deliverables.removeDuplicate(deliverable);
        this._annexitemeditable!.deliverables.validate();
        this.$data.deliverables = clone(this._annexitemeditable!.deliverables.list);
        this.$data.has_delivery_duplicate = this._annexitemeditable!.deliverables.hasDeliveryDuplicate;
    }

    public handleAnnexAttachment(event: IFileAttachmentVMEvent): void {
        const value = event.command === Command.Add ? event.attachment : null;
        this.$data.required.annex_attachment = value;
    }

    public async updateAnnex(evt: Event) {
        // Update AnnexItemEditable instance with date and file.
        this._annexitemeditable!.attachment = this.$data.required.annex_attachment;
        this._annexitemeditable!.signature_date = this.$data.required.signature_date;
        this._annexitemeditable!.price_total_annex = this.$data.price_total_annex;

        const request = new UpdateContractAnnexRequestVO(this._annexitemeditable!);

        actWithLoadingSpinner(evt, async () => {
            const response = await ApplicationServiceLocator.get('api').entities.updateContractAnnex(this.$props.contract.id, request);
            response.isSuccess && window.location.reload();
        });
    }

    /**
     * General prototype methods.
     */

    /**
     * Due to suboptimal API architecture one has to get the deliverables data from different
     * endpoints and in different format. This requires the responses normalization for
     * the cases of annex creaction or editing. The particular case is distinguished with
     * EMode enum.
     */
    private async annexItemEditableFactory(contract: ContractItemDetailsVO): Promise<AnnexItemEditableVO> {
        const method = this.$props.mode === EMode.Create ? 'loadSupplementaryAgreementItems' : 'loadSupplementaryAgreements';
        const response = await ApplicationServiceLocator.get('api').entities[method](contract.id);

        const items =
            this.$props.mode === EMode.Create
                ? response.data
                : response.data.data.find((item: IRawAnnexItemData) => {
                      return item.id === contract.pendingAnnex()?.id;
                  }).items;

        return new AnnexItemEditableVO(contract, items);
    }

    private hasInputsChanged(deliverableinputs: IDeliverableInputs[], oldDeliverableInputs: IDeliverableInputs[]): boolean {
        if (oldDeliverableInputs.length < 1) {
            return false;
        }

        const lengthChanged = deliverableinputs.length !== oldDeliverableInputs.length;

        const contentChanged = deliverableinputs.some((input: IDeliverableInputs) => {
            const old = oldDeliverableInputs.find((oldInput: IDeliverableInputs) => {
                return oldInput.display_id === input.display_id;
            });
            return old && (old.quantity !== input.quantity || old.price_per_unit !== input.price_per_unit);
        });

        return contentChanged || lengthChanged;
    }

    private updateData(): void {
        this._annexitemeditable!.deliverables.recalculateDeliverableTotalPrice();
        this._annexitemeditable!.deliverables.validate();

        this.$data.deliverables = clone(this._annexitemeditable!.deliverables.list);
        this.$data.price_total_annex = this._annexitemeditable!.deliverables.priceTotal();
        this.$data.errors = clone(this._annexitemeditable!.deliverables.errors);
    }
}
