var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group my-4 py-0" },
    [
      this.$props.origin === "notice"
        ? _c("h3", [_vm._v(" Спецификация "), _vm._t("default")], 2)
        : _c("h5", [_vm._v(" Спецификация "), _vm._t("default")], 2),
      _c("div", { staticClass: "table-responsive my-2 py-0" }, [
        _c(
          "table",
          {
            staticClass: "atmo-content-table atmo-deliverables my-0 py-0",
            class: { "atmo-empty": this.isempty },
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "atmo-center" }, [_vm._v("№")]),
                _c("th", { staticStyle: { "min-width": "400px" } }, [
                  _vm._v("Наименование ТРУ"),
                ]),
                _c("th", { staticClass: "atmo-ctc-quantity" }, [
                  _vm._v("Количество"),
                ]),
                _c("th", { staticClass: "atmo-ctc-okpd-symbolic-code" }, [
                  _vm._v("Ед.изм"),
                ]),
                _c("th", { staticClass: "atmo-ctc-price-per-unit" }, [
                  _vm._v("Цена за ед., руб."),
                ]),
                _c("th", { staticClass: "atmo-ctc-price-total" }, [
                  _vm._v("Стоимость, руб."),
                ]),
                this.$props.iseditable ? _c("th", [_vm._v(" ")]) : _vm._e(),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm._l(this.$props.deliverables, function (deliverable, index) {
                  return _c("fragment", { key: index }, [
                    _c(
                      "tr",
                      { class: _vm.visible[index] ? "border-bottom-0" : "" },
                      [
                        _c("td", {
                          staticClass: "atmo-center",
                          domProps: { textContent: _vm._s(index + 1) },
                        }),
                        _c(
                          "td",
                          [
                            deliverable.gsc_details || deliverable.okpd_code
                              ? _c("action-button-small", {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle",
                                      value: "collapse-" + index,
                                      expression: "'collapse-' + index",
                                    },
                                  ],
                                  attrs: {
                                    icon: !_vm.visible[index]
                                      ? "eye"
                                      : "eye-slash",
                                    title:
                                      (!_vm.visible[index]
                                        ? "Показать"
                                        : "Скрыть") + " характеристики",
                                  },
                                })
                              : _vm._e(),
                            _c("b-collapse", {
                              staticClass: "d-none",
                              attrs: { id: "collapse-" + index },
                              model: {
                                value: _vm.visible[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.visible, index, $$v)
                                },
                                expression: "visible[index]",
                              },
                            }),
                            _vm._v(" " + _vm._s(deliverable.title) + " "),
                          ],
                          1
                        ),
                        _vm.$props.iseditable
                          ? _c("td", { staticClass: "atmo-ctc-quantity" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "atmo-form__field atmo-inverse atmo-small",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: deliverable.quantity,
                                        expression: "deliverable.quantity",
                                        modifiers: { number: true },
                                      },
                                      {
                                        name: "stop-number-mousewheel",
                                        rawName: "v-stop-number-mousewheel",
                                      },
                                    ],
                                    attrs: {
                                      min: "1",
                                      required: "",
                                      type: "number",
                                    },
                                    domProps: { value: deliverable.quantity },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          deliverable,
                                          "quantity",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _c("td", {
                              staticClass: "atmo-ctc-quantity",
                              domProps: {
                                textContent: _vm._s(deliverable.quantity),
                              },
                            }),
                        _c("td", {
                          staticClass: "atmo-ctc-okpd-symbolic-code",
                          domProps: {
                            textContent: _vm._s(deliverable.okei_symbolic_code),
                          },
                        }),
                        _c("td", { staticClass: "atmo-ctc-price-per-unit" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatnumber")(deliverable.price_per_unit)
                            )
                          ),
                        ]),
                        _c("td", { staticClass: "atmo-ctc-price-total" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatnumber")(deliverable.price_total)
                            )
                          ),
                        ]),
                        _vm.$props.iseditable
                          ? _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "atmo-button-icon",
                                  attrs: {
                                    title: "Удалить позицию из спецификации",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$props.removehandler(index)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "atmo-icon-trash" })]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.visible[index],
                            expression: "visible[index]",
                          },
                        ],
                      },
                      [
                        _c(
                          "td",
                          { attrs: { colspan: "7" } },
                          [
                            deliverable.gsc_details
                              ? _c("div", [
                                  deliverable.gsc_details.registration_number
                                    ? _c("p", [
                                        _c("strong", [
                                          _vm._v("Рег. номер ТРУ:"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              deliverable.gsc_details
                                                .registration_number
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("ul", { staticClass: "fs-14" }, [
                                    deliverable.okpd_code
                                      ? _c("li", [
                                          _c("strong", [_vm._v("Код ОКПД2: ")]),
                                          _vm._v(_vm._s(deliverable.okpd_code)),
                                        ])
                                      : _vm._e(),
                                    deliverable.gsc_details.group
                                      ? _c("li", [
                                          _c("strong", [
                                            _vm._v("Группа ТРУ: "),
                                          ]),
                                          _vm._v(
                                            _vm._s(
                                              deliverable.gsc_details.group
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    deliverable.gsc_details.title
                                      ? _c("li", [
                                          _c("strong", [
                                            _vm._v("Наименование: "),
                                          ]),
                                          _vm._v(
                                            _vm._s(
                                              deliverable.gsc_details.title
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    deliverable.gsc_details.type
                                      ? _c("li", [
                                          _c("strong", [_vm._v("Тип: ")]),
                                          _vm._v(
                                            _vm._s(deliverable.gsc_details.type)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("DeliverableDetails.view", {
                              attrs: {
                                description: deliverable.gsc_details
                                  ? deliverable.gsc_details.description
                                  : null,
                                requirements: deliverable.requirements,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                }),
                this.isempty
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "3" } }, [
                        _vm._v("Спецификация пуста"),
                      ]),
                    ])
                  : _c("tr", { staticClass: "atmo-totals" }, [
                      _c(
                        "td",
                        { staticClass: "atmo-right", attrs: { colspan: "6" } },
                        [
                          _vm._v(
                            "Стоимость итого, руб.:  " +
                              _vm._s(_vm._f("formatnumber")(_vm.price_total))
                          ),
                        ]
                      ),
                    ]),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._t("errors"),
      this.$props.iseditable
        ? _c(
            "button",
            {
              staticClass: "atmo-button",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$props.selectorpopup.apply(null, arguments)
                },
              },
            },
            [_vm._v("Добавить спецификацию")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }