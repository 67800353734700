<template>
    <div id="atmo-deliverables" class="atmo-content-group table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th style="width: 120px">Рег.номер</th>
                    <th style="width: 120px">ОКПД2</th>
                    <th style="min-width: 400px; width: 400px">Наименование товара (работ, услуг)</th>
                    <th style="width: 100px">Ед.изм</th>
                    <th style="width: 100px">Страна происхождения</th>
                    <th style="width: 20px"></th>
                </tr>
            </thead>

            <tbody>
                <fragment v-for="(deliverable, index) in this.$props.deliverables" :key="index">
                    <tr>
                        <td>{{ deliverable.gsc_details.registration_number }}</td>
                        <td>{{ deliverable.okpd_code }}</td>
                        <td>
                            <action-button-small :title="(!visible[deliverable.id] ? 'Показать' : 'Скрыть') + ' характеристики'" v-b-toggle="'collapse-' + deliverable.id" :icon="!visible[deliverable.id] ? 'eye' : 'eye-slash'" />
                            <b-collapse :id="'collapse-' + deliverable.id" v-model="visible[deliverable.id]" class="d-none" />
                            {{ deliverable.title }}
                        </td>
                        <td>{{ deliverable.okei_symbolic_code }}</td>
                        <td>{{ deliverable.country_of_origin_name }}</td>
                        <td class="text-right text-nowrap">
                            <action-button-small title="Добавить спецификацию в закупку" icon="plus" @click="$props.controller.selected(index)" />
                            <action-button-small v-if="!deliverable.is_favorite" class="ml-1" title="Добавить в избранное" icon="star" @click="addDeleteFavoriteItem(deliverable)" />
                            <action-button-small v-else variant="outline" class="ml-1" title="Удалить из избранного" icon="star-fill" @click="addDeleteFavoriteItem(deliverable)" />
                        </td>
                    </tr>
                    <tr v-show="visible[deliverable.id]">
                        <td class="align-text-top" colspan="6">
                            <template v-if="deliverable.description || (deliverable.requirements && deliverable.requirements.length > 0)">
                                <p v-if="deliverable.description"><b>Описание:</b> {{ deliverable.description }}</p>
                                <p v-if="deliverable.requirements && deliverable.requirements.length > 0"><b>Характеристики:</b></p>
                                <ul v-if="deliverable.requirements && deliverable.requirements.length > 0">
                                    <li v-for="(requirement, requirementIndex) of deliverable.requirements" :key="deliverable.id + '_requirement_' + requirementIndex">
                                        <b>{{ requirement.title }}</b> - {{ requirement.description }}
                                    </li>
                                </ul>
                            </template>
                            <template v-else>&mdash;</template>
                        </td>
                    </tr>
                </fragment>
                <tr v-if="this.isEmpty" class="atmo-centered">
                    <td colspan="6">
                        <span class="atmo-error">Позиции КТРУ по вашему запросу не найдены.</span>
                        <span>
                            Вы можете
                            <router-link v-bind:to="{ name: 'KtruRequestsCreate' }" class="atmo-button-link" target="_blank">сформировать запрос на добавление позиции КТРУ</router-link>.</span
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    computed: {
        isEmpty: function () {
            return this.$props.deliverables.length < 1;
        }
    },
    props: {
        controller: { required: true, type: Object },
        deliverables: { required: true, type: Array }
    },
    data() {
        return {
            visible: []
        };
    },
    methods: {
        async addDeleteFavoriteItem(item) {
            if (!item.is_favorite) {
                const formData = {
                    ktru_item_id: item.id
                };
                if (await this.$api.cabinet.ktru.ktruFavorites.addKtruItem(formData)) await this.$props.controller.reload();
            } else {
                if (await this.$api.cabinet.ktru.ktruFavorites.deleteKtruItem(item.favorite_id)) await this.$props.controller.reload();
            }
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 8px;
    color: var(--grey-color);
    border-top: 0;
}
.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}
</style>
