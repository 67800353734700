import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';
import store from '@/store';

export class PersonalAddressesAPIService extends BaseApiService {
    constructor() {
        super('personal/addresses');
    }

    async deleteAddress(id) {
        try {
            await axios.delete(this.getUrl(id));
            await store.dispatch('showSuccessToast', 'Адрес успешно удалён');
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async createAddress(payload) {
        try {
            await axios.post(this.getUrl(), payload);
            await store.dispatch('showSuccessToast', 'Адрес успешно добавлен');
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async getAddressesList() {
        try {
            const response = await axios.get(this.getUrl());
            return response.data.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }
}
