'use strict';

import notices from './notices/index';
import purchases from './purchases/index';
import contracts from './contracts/index';

const GroupFL44StoreModule = {
    namespaced: true,

    modules: {
        notices,
        purchases,
        contracts
    }
};

export default GroupFL44StoreModule;
