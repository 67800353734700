'use strict';

import dayjs from 'dayjs';
import DeliverableItemVO from '@core/js/ddriven/domain/model/common/deliverable/DeliverableItem.valueobject';
import FileAttachmentVO from '../../common/FileAttachment.valueobject';
import ContractItemDetailsVO from '../ContractItemDetails.valueobject';
import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';
import AnnexDeliverableItemEditablesCollection from '../../common/deliverable/update/annex/AnnexDeliverableItemEditables.collection';
import { IRawSupplementaryAgreementItem } from '../../common/deliverable/update/annex/AnnexDeliverableItemEditable.valueobject';

interface IParams {
    deliverables: DeliverableItemVO[];
}

export default class AnnexItemEditableVO {
    deliverables: AnnexDeliverableItemEditablesCollection;
    price_total_contract: number | null;
    price_total_annex: number | null;
    signature_date: string | null;
    attachment: FileAttachmentVO | null;

    constructor(contract?: ContractItemDetailsVO, rawSupplementaryAgreementItems?: IRawSupplementaryAgreementItem[]) {
        const pendingAnnex = contract ? contract.pendingAnnex() : null;

        this.deliverables = contract && contract.price_total && rawSupplementaryAgreementItems ? new AnnexDeliverableItemEditablesCollection(rawSupplementaryAgreementItems, contract.price_total) : new AnnexDeliverableItemEditablesCollection();
        this.price_total_contract = contract ? contract.price_total : null;
        this.price_total_annex = contract && contract.hasAnnexes() ? this.deliverables.priceTotal() : this.price_total_contract;
        this.signature_date = pendingAnnex ? dayjs(pendingAnnex.date).format('YYYY-MM-DD') : null;
        this.attachment = null;
    }

    public hasErrors(): boolean {
        const deliverablesErrors = Object.keys(this.deliverables.errors).some((key: string) => {
            return this.deliverables.errors[key] === true;
        });
        return isPrimitiveEmpty(this.signature_date) || this.attachment === null || deliverablesErrors;
    }
}
