var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "atmo-table-slim atmo-table-fixed" }, [
    _c(
      "tbody",
      _vm._l(_vm.$props.documents, function (document, index) {
        return _c("tr", { key: index }, [
          _c(
            "td",
            {
              staticStyle: { "max-width": "50%" },
              attrs: { title: document.description },
            },
            [_vm._v(_vm._s(document.description) + ": ")]
          ),
          _c(
            "td",
            [
              _c("editable-file-attachments-list-controller", {
                attrs: {
                  initiallist: document.attachment ? [document.attachment] : [],
                  isrequired: true,
                  origin: "slim",
                  maxfilesallowed: 1,
                },
                on: { "fileattachment:changed": _vm.$props.update },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (efalc) {
                        return [
                          _c("table", [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", { staticClass: "atmo-left" }, [
                                  !efalc.isempty
                                    ? _c("span", [
                                        _c("button", {
                                          staticClass: "atmo-button-link",
                                          attrs: { title: "Скачать файл" },
                                          domProps: {
                                            textContent: _vm._s(
                                              efalc.list[0].name
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return efalc.downloadFileAttachment(
                                                efalc.list[0]
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    : _c(
                                        "span",
                                        { staticClass: "atmo-error" },
                                        [_vm._v("Прикрепите файл")]
                                      ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "atmo-tc-button-icon atmo-center",
                                    attrs: { title: "Прикрепить файл" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "atmo-button-icon",
                                        attrs: {
                                          disabled:
                                            efalc.islistfull ||
                                            _vm.$props.is_disabled,
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass: "atmo-icon-attachment",
                                          }),
                                        ]),
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            accept:
                                              ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return efalc.add($event, index)
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "atmo-tc-button-icon atmo-center",
                                    attrs: { title: "Удалить файл" },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "atmo-button-icon",
                                        attrs: {
                                          disabled:
                                            efalc.isempty ||
                                            _vm.$props.is_disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return efalc.remove(
                                              efalc.list[0],
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "atmo-icon-trash",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }