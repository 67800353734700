var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticStyle: { width: "100%" } }, [
    _c("div", { staticClass: "table-responsive" }, [
      _c(
        "table",
        {
          staticClass: "atmo-content-table",
          class: { "atmo-empty": _vm.$props.additional.is_empty },
        },
        [
          _vm._m(0),
          _c(
            "tbody",
            [
              _vm._l(
                _vm.$props.additional.requirements,
                function (requirement, index) {
                  return _c("tr", { key: index }, [
                    _c("td", {
                      staticClass: "atmo-ctc-place",
                      domProps: { textContent: _vm._s(index + 1) },
                    }),
                    _c("td", { staticClass: "atmo-ctc-width-45pct" }, [
                      _c(
                        "span",
                        { staticClass: "atmo-form__field atmo-inverse" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: requirement.description,
                                expression: "requirement.description",
                              },
                            ],
                            attrs: {
                              id: `requirement-additional-item-${index + 1}`,
                              type: "text",
                              required: "",
                              min: "1",
                            },
                            domProps: { value: requirement.description },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  requirement,
                                  "description",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("td", { staticClass: "atmo-ctc-width-45pct" }, [
                      _c(
                        "span",
                        { staticClass: "atmo-form__field atmo-inverse" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: requirement.reason,
                                expression: "requirement.reason",
                              },
                            ],
                            attrs: { type: "text", required: "", min: "1" },
                            domProps: { value: requirement.reason },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  requirement,
                                  "reason",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("td", { staticClass: "atmo-ctc-command-remove" }, [
                      _c(
                        "button",
                        {
                          staticClass: "atmo-button-icon",
                          attrs: { title: "Удалить описание" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$props.additional.remove(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "atmo-icon-trash" })]
                      ),
                    ]),
                  ])
                }
              ),
              _vm.$props.additional.is_empty
                ? _c("tr", { staticClass: "atmo-ctc-center" }, [
                    _c(
                      "td",
                      { staticClass: "atmo-centered", attrs: { colspan: "3" } },
                      [_vm._v("Не добавлено описаний документов")]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]),
    _c("footer", [
      _vm.$props.additional.is_empty
        ? _c("p", { staticClass: "atmo-error" }, [
            _vm._v("Добавьте и опишите дополнительные требования."),
          ])
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "atmo-button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$props.additional.add()
            },
          },
        },
        [_vm._v("Добавить описание требования")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "atmo-ctc-place" }, [_vm._v("№")]),
        _c("th", { staticClass: "atmo-ctc-width-45pct" }, [_vm._v("Описание")]),
        _c("th", { staticClass: "atmo-ctc-width-45pct" }, [
          _vm._v("Причина или цель установки требования"),
        ]),
        _c("th", { staticClass: "atmo-ctc-command-remove" }, [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }