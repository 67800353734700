'use strict';

import ValidationRules from '@lib/js/src/misc/ValidationRules';
import cloneDeep from 'lodash.clonedeep';
import groupBy from 'lodash.groupby';
import round from 'lodash.round';
import { IValidatorErrors } from './AnnexDeliverableItemEditable.validator';
import AnnexDeliverableItemEditableVO, { IRawSupplementaryAgreementItem } from './AnnexDeliverableItemEditable.valueobject';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';

type TOriginalIds = { grouping_id: string; display_id: string };

export interface IErrors extends IValidatorErrors {
    [index: string]: boolean;

    price_total: boolean;
}

interface IKBKLimitValidationSummary {
    kbk_limit: {
        id: number;
        limit_available: number;
    };
    deliverables_price_total_sum: number;
    is_limit_violated: boolean;
}

export default class AnnexDeliverableItemEditablesCollection {
    private _list: AnnexDeliverableItemEditableVO[];
    private _price_total_initial: number | null;
    private _errors: IErrors;
    private _has_delivery_duplicate: boolean;

    constructor(items?: IRawSupplementaryAgreementItem[], priceTotalInitial?: number) {
        this._list = items ? AnnexDeliverableItemEditablesCollection.fromRawSupplementaryAgeementItemsArray(items) : [];

        this._price_total_initial = priceTotalInitial ?? null;

        this._errors = {
            price_per_unit: false,
            quantity: false,
            kbk_limit: false,
            price_total: false
        };

        this._has_delivery_duplicate = false;
    }

    get list(): AnnexDeliverableItemEditableVO[] {
        return this._list.filter((deliverable: AnnexDeliverableItemEditableVO) => {
            return !deliverable.isDeleted;
        });
    }

    get unfilteredList(): AnnexDeliverableItemEditableVO[] {
        return this._list;
    }

    get priceTotalInitial(): number | null {
        return this._price_total_initial;
    }

    get errors(): IErrors {
        return this._errors;
    }

    get hasDeliveryDuplicate(): boolean {
        return this._has_delivery_duplicate;
    }

    private static fromRawSupplementaryAgeementItemsArray(items: IRawSupplementaryAgreementItem[]): AnnexDeliverableItemEditableVO[] {
        return items.map((item: IRawSupplementaryAgreementItem) => {
            return AnnexDeliverableItemEditableVO.fromRawSupplementaryAgeementItem(item);
        });
    }

    public priceTotal(): number {
        const price = this.list.reduce((accumulator: number, currentDeliverable: AnnexDeliverableItemEditableVO) => {
            return accumulator + (currentDeliverable && currentDeliverable.price_per_unit && currentDeliverable.quantity ? currentDeliverable.price_per_unit * currentDeliverable.quantity : 0);
        }, 0);

        return roundToTwoDecimals(price);
    }

    public recalculateDeliverableTotalPrice(): void {
        this._list = this._list.map((deliverable: AnnexDeliverableItemEditableVO) => {
            deliverable.recalculateTotalPrice();
            return cloneDeep(deliverable);
        });
    }

    public duplicate(original: AnnexDeliverableItemEditableVO): AnnexDeliverableItemEditableVO | null {
        if (original.isDuplicate) {
            return null;
        }

        // Find the original index.
        const originalIndex = this._list.findIndex((deliverable: AnnexDeliverableItemEditableVO) => {
            return !deliverable.isDuplicate && deliverable.grouping_id === original.grouping_id;
        });

        if (originalIndex === -1) {
            return null;
        }

        // Prevent the original to bring with the reactivity with itself.
        const duplicate = this._list[originalIndex].duplicate();

        // Insert the copy after the original.
        this._list.splice(originalIndex + 1, 0, duplicate);

        if (original.is_delivery) {
            this._has_delivery_duplicate = true;
        }

        return duplicate;
    }

    public removeDuplicate(duplicate: AnnexDeliverableItemEditableVO): AnnexDeliverableItemEditableVO | null {
        if (!duplicate.isDuplicate) {
            return null;
        }

        const duplicateIndex = this._list.findIndex((deliverable: AnnexDeliverableItemEditableVO) => {
            return deliverable.isDuplicate && deliverable.display_id === duplicate.display_id;
        });

        if (duplicateIndex === -1) {
            return null;
        }

        if (duplicate.isRestored) {
            this._list[duplicateIndex].setDeleted();
        } else {
            this._list.splice(duplicateIndex, 1);
        }

        if (duplicate.is_delivery) {
            this._has_delivery_duplicate = false;
        }

        return this._list[duplicateIndex];
    }

    public validate(): AnnexDeliverableItemEditablesCollection {
        this.validatePricePerUnit();
        this.validateQuantity();
        this.validatePriceTotal();
        return this;
    }

    private validatePricePerUnit(): void {
        const errors: IValidatorErrors[] = [];

        this._list = this._list.map((deliverable: AnnexDeliverableItemEditableVO) => {
            deliverable.validate();
            return cloneDeep(deliverable);
        });

        this._errors.price_per_unit = this._list.some((deliverable: AnnexDeliverableItemEditableVO) => {
            return deliverable.validator.errors.price_per_unit === true;
        });
    }

    /**
     * Set the quantity error flags on collection and on each deliverable
     * within a group formed around deliverable original.
     */
    private validateQuantity(): void {
        // First group deliverables copies around each original.
        const groupedIds = this.groupDeliverablesIdsByOriginal();

        /**
         * Now have to validate the total quantity per group vs
         * the original.validator.initial.quantity +/- 10%.
         * If the validation fails, iterate each deliverable in the group and call
         * deliverable.setQuantityError().
         */
        groupedIds.forEach((group: TOriginalIds[]) => {
            const groupTotalQuantity = group.reduce((accumulator, ids: TOriginalIds) => {
                const found = this.list.find((deliverable: AnnexDeliverableItemEditableVO) => {
                    return deliverable.display_id === ids.display_id;
                });
                return accumulator + (found ? found.quantity : 0);
            }, 0);

            const original = this.list.find((deliverable: AnnexDeliverableItemEditableVO) => {
                return deliverable.grouping_id === group[0].grouping_id;
            });

            // Quantity validation error should not be set for delivery deliverable.
            const quantityValidationError: boolean = original!.is_delivery ? false : !ValidationRules.between(groupTotalQuantity, original!.validator.targets.quantity.min, original!.validator.targets.quantity.max);

            // Then update (set or clear) the quantity error flag for each deliverable in group.
            group.forEach((ids: TOriginalIds) => {
                const found = this.list.find((deliverable: AnnexDeliverableItemEditableVO) => {
                    return deliverable.display_id === ids.display_id;
                });
                if (found) {
                    found.validator.setQuantityError(quantityValidationError);
                }
            });
        });

        // Finally update the collection quantity error flag.
        this.errors.quantity = this.list.some((deliverable: AnnexDeliverableItemEditableVO) => {
            return deliverable.validator.errors.quantity;
        });
    }

    private validatePriceTotal(): void {
        this.errors.price_total = this.priceTotalInitial !== null ? !ValidationRules.between(this.priceTotal(), 0, this.priceTotalInitial * 1.1) : false;
    }

    private groupDeliverablesIdsByOriginal(): TOriginalIds[][] {
        const originalIds: TOriginalIds[] = [];

        this.list.forEach((deliverable: AnnexDeliverableItemEditableVO) => {
            if (!deliverable.isDuplicate) {
                originalIds.push({ grouping_id: deliverable.grouping_id, display_id: deliverable.display_id });
            }
        });

        const groupedIds = originalIds.map((originalIds: TOriginalIds) => {
            const duplicateIds: TOriginalIds[] = [];
            this.list.forEach((deliverable: AnnexDeliverableItemEditableVO) => {
                if (deliverable.isDuplicate && deliverable.display_id?.includes(originalIds.grouping_id)) {
                    duplicateIds.push({ grouping_id: deliverable.grouping_id, display_id: deliverable.display_id });
                }
            });
            return [originalIds, ...duplicateIds];
        });

        return groupedIds;
    }
}
