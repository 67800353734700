var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-icon", {
    staticClass: "btn btn-primary p-0",
    staticStyle: { width: "24px", height: "24px" },
    attrs: { icon: "x", "aria-hidden": "true" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }