import axios from 'axios';
import { BaseV1ApiService } from '@/services/backend/baseV1';
import store from "@/store";

export class NotificationsApiService extends BaseV1ApiService {
    constructor() {
        super('notifications');
    }

    async getNotificationList(payload) {
        try {
            const response = await axios.get(this.getUrl(), {params: payload});
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async showNotification(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}`));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
            throw err;
        }
    }

    async markAsRead(payload) {
        try {
            const response = await axios.put(this.getUrl('mark_as_read'), { ...payload });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async markAsUnread(payload) {
        try {
            const response = await axios.put(this.getUrl('mark_as_unread'), { ...payload });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async markAllAsRead() {
        try {
            const response = await axios.put(this.getUrl('mark_all_as_read'));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(payload) {
        try {
            const response = await axios.delete(this.getUrl('delete'), {params: {notifications: payload}});
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async deleteAll() {
        try {
            const response = await axios.delete(this.getUrl('delete_all'));
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async saveNotificationSettings(payload) {
        try {
            const response = await axios.put(this.getUrl('save_settings'), payload);
            await store.dispatch('showSuccessToast', 'Настройки уведомлений сохранены');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
            throw err;
        }
    }
}
