'use strict';

import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import { toXML } from 'jstoxml';

export default class DisputeAttachmentForXMLVO {
    document: {
        name: string;
        stored_document_id: string;
    };

    constructor(attachment: FileAttachmentVO) {
        this.document = {
            name: attachment.name,
            stored_document_id: attachment.file_id as string
        };
    }

    public toXML(): string {
        const object = JSON.parse(JSON.stringify({ contract_file: this }));
        return toXML(object, { header: true, indent: '    ' });
    }
}
