<template>
    <section class="atmo-content-group" v-if="this.$props.supplier">
        <h6>Информация о поставщике</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Наименование:</dt>
                <dd v-text="this.$props.supplier.title"></dd>
            </li>
            <li>
                <dt>ИНН:</dt>
                <dd v-text="this.$props.supplier.inn"></dd>
            </li>
            <li v-if="this.$props.supplier.kpp">
                <dt>КПП:</dt>
                <dd v-text="this.$props.supplier.kpp"></dd>
            </li>
            <li>
                <dt>Адрес:</dt>
                <dd v-text="this.$props.supplier.address"></dd>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        supplier: { required: true }
    }
};
</script>
