<template>
    <div class="atmo-content-group">
        <on-off v-slot:default="onoff" v-bind:options="{ onEsc: false, onBody: false }">
            <!-- v-bind:currentstate="true" -->

            <kbk-limits-autonomous-update-widget-controller v-slot:default="klauwc" v-bind:purchaseid="$props.purchaseid" v-bind:entity="$props.entity" v-bind:onoff="onoff">
                <div class="atmo-component-wrapper">
                    <div v-if="onoff.ison" class="atmo-nested-section">
                        <KBKLimitsEditableList.view v-bind:initiallist="klauwc.limits" v-bind:totalamount="klauwc.deliverables_total_amount" v-bind:limitfilterpresets="$props.limitfilterpresets">
                            <template v-slot:title>
                                <h6>Измените лимиты</h6>
                            </template>
                        </KBKLimitsEditableList.view>

                        <ul v-if="onoff.ison" class="atmo-buttons">
                            <li>
                                <button
                                    v-on:click.stop="
                                        onoff.off();
                                        klauwc.close();
                                    "
                                    class="atmo-button atmo-button-inverse atmo-dimmed">
                                    Отмена
                                </button>
                            </li>
                            <li>
                                <ProgressIndicatingButton.component v-bind:clickhandler="klauwc.saveLimits" v-bind:text="'Сохранить изменения'" v-bind:classes="'atmo-button atmo-accented-mid'" v-bind:disabledstate="klauwc.has_limits_errors || !klauwc.have_limits_changed" />
                            </li>
                        </ul>
                    </div>

                    <button v-if="!onoff.ison" v-on:click.stop="onoff.on" class="atmo-button">Изменить лимиты</button>
                </div>
            </kbk-limits-autonomous-update-widget-controller>
        </on-off>
    </div>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import KBKLimitsAutonomousUpdateWidgetController from '@core/js/viewmodels/purchases/item/view/widgets/KBKLimitsAutonomousUpdateWidgetController.viewmodel.ts';
import KBKLimitsEditableListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/update/KBKLimitsEditableList.view.vue';
import ProgressIndicatingButtonComponent from '@lib/js/src/vue/components/progress/button/ProgressIndicatingButton.component.vue';

export default {
    props: {
        purchaseid: { required: true, type: Number },
        entity: { required: true, type: Object }, // NB: Either contract or annex.
        limitfilterpresets: { required: true, type: Object }
    },
    components: {
        'kbk-limits-autonomous-update-widget-controller': KBKLimitsAutonomousUpdateWidgetController,
        'on-off': new OnOff(),
        'KBKLimitsEditableList.view': KBKLimitsEditableListView,
        'ProgressIndicatingButton.component': ProgressIndicatingButtonComponent
    }
};
</script>
