'use strict';

import isFinite from 'lodash.isfinite';
import cloneDeep from 'lodash.clonedeep';

import DeliverableItemRequirementVO, { IRawDeliverableItemRequirement } from '../../DeliverableItemRequirement.valueobject';
import GSCDetailsVO, { IRawGSCDetails } from '../../GSCDetails.valueobject';

import DeliverableDictionaryItemVO from '../../DeliverableDictionaryItem.valueobject';
import { IOriginalSpecItem } from './harness/OriginalSpecItem.valueobject';
import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';

export type TSelfPOJO = Omit<DeliverableItemEditableVO, 'errors' | 'updatePriceTotal' | 'hasKBKLimit' | 'setLimitViolationError' | 'hasPricePerUnit'>;

export default class DeliverableItemEditableVO {
    id: number | null;
    quantity: number | null;
    price_per_unit: number | null;
    price_total: number | null;
    title: string | null;
    okpd_id: number | null;
    okpd_code: string | null;
    okpd_title: string | null;
    okei_id: number | null;
    okei_symbolic_code: string | null;
    gsc_details: GSCDetailsVO | {};
    requirements: DeliverableItemRequirementVO[];

    constructor(data?: TSelfPOJO) {
        this.id = data?.id ?? null;
        this.quantity = data?.quantity ?? 0;
        this.price_per_unit = data?.price_per_unit ?? 0;
        this.price_total = data?.price_total ?? 0;
        this.title = data?.title ?? null;
        this.okpd_id = data?.okpd_id ?? null;
        this.okpd_code = data?.okpd_code ?? null;
        this.okpd_title = data?.okpd_title ?? null;
        this.okei_id = data?.okei_id ?? null;
        this.okei_symbolic_code = data?.okei_symbolic_code ?? null;

        this.gsc_details = data?.gsc_details ?? {};
        this.requirements = data?.requirements ?? [];
    }

    public static fromPurchaseDraft(data: IOriginalSpecItem): DeliverableItemEditableVO {
        const pojo: TSelfPOJO = {
            id: data.ktru_item_id,
            quantity: data.count,
            price_per_unit: data.price,
            price_total: 0,
            title: data.ktru?.name ?? data.name,
            okpd_id: data.okpd?.id ?? null,
            okpd_code: data.okpd?.code ?? null,
            okpd_title: data.okpd?.name ?? null,
            okei_id: data.okei.id,
            okei_symbolic_code: data.okei.local_symbol,

            // NB: Later the any has to be removed and data types should be aligned.
            gsc_details: new GSCDetailsVO(data.ktru as any),
            requirements: DeliverableItemRequirementVO.fromArray(data.ktru?.specs)
        };

        return new DeliverableItemEditableVO(pojo);
    }

    public static fromDictionaryItem(deliverable: DeliverableDictionaryItemVO): DeliverableItemEditableVO {
        const pojo: TSelfPOJO = {
            id: deliverable.id,
            quantity: 0,
            price_per_unit: 0,
            price_total: 0,
            title: deliverable.title,
            okpd_id: deliverable.okpd_id,
            okpd_code: deliverable.okpd_code,
            okpd_title: deliverable.okpd_title,
            okei_id: deliverable.okei_id,
            okei_symbolic_code: deliverable.okei_symbolic_code,

            gsc_details: cloneDeep(deliverable.gsc_details) as GSCDetailsVO,
            requirements: deliverable.requirements as []
        };

        return new DeliverableItemEditableVO(pojo);
    }

    public updatePriceTotal(): void {
        this.price_total = !isFinite(this.price_per_unit) || !isFinite(this.quantity) || this.quantity! < 0 ? 0 : roundToTwoDecimals(this.price_per_unit! * this.quantity!);
    }

    public hasPricePerUnit(): boolean {
        return !isPrimitiveEmpty(this.price_per_unit) && isFinite(this.price_per_unit) && this.price_per_unit! > 0;
    }
}
