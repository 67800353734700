<template>
    <purchase-item-view-controller v-cloak v-slot:default="pivc" v-bind:group="this.$props.group" v-bind:id="this.$props.id">
        <div id="atmo-top" class="atmo-hash-jump-point">
            <FastAccessMenu.view v-bind:pivc="pivc" v-bind:purchaseitem="pivc.purchaseitem" />

            <section class="atmo-page-banner-wrapper atmo-slim">
                <div class="atmo-container-fixed">
                    <h2><span>№&nbsp;</span><span v-text="pivc.purchaseitem.registration_number"></span></h2>

                    <ul class="atmo-content-block-2-columns atmo-no-item-separator atmo-in-banner">
                        <li>
                            <dt>Статус закупки:</dt>
                            <dd v-text="pivc.purchaseitem.status_title" class="atmo-placeholder-animated atmo-inverse"></dd>
                        </li>
                        <li>
                            <dt>Заказчик:</dt>
                            <dd v-text="pivc.purchaseitem.customer.title" class="atmo-placeholder-animated atmo-inverse"></dd>
                        </li>
                        <li v-if="pivc.hasSupplier">
                            <dt>Поставщик:</dt>
                            <dd v-text="pivc.purchaseitem.supplier.title" class="atmo-placeholder-animated atmo-inverse"></dd>
                        </li>
                    </ul>
                </div>
            </section>

            <div class="atmo-container-fixed atmo-page-content">
                <CustomerInfo.view v-bind:purchaseitem="pivc.purchaseitem" />

                <PurchaseTerms.view v-bind:pivc="pivc" v-bind:purchaseitem="pivc.purchaseitem" />

                <SupplierRequirements.view v-if="!pivc.isPurchaseOutATMO" v-bind:requirements="pivc.purchaseitem.supplier_requirements" v-bind:purchase="pivc.purchaseitem" />

                <SupplyTerms.view v-if="!pivc.isPurchaseOutATMO" v-bind:purchaseitem="pivc.purchaseitem" />

                <!-- The purchase notice specification -->
                <DeliverablesList.view v-bind:origin="'notice'" v-bind:deliverables="pivc.purchaseitem.deliverables" id="atmo-notice-deliverables" class="atmo-hash-jump-point">
                    <span v-if="!pivc.isPurchaseOutATMO">извещения</span>
                </DeliverablesList.view>

                <KBKLimitsList.view v-if="pivc.features.kbk_limits && pivc.isUserEngagedCustomer && !pivc.purchaseitem.without_limits" v-bind:limits="pivc.purchaseitem.kbklimits" />

                <AccordionItem :visible="$route.hash === '#explanation-requests'" title="Запросы разъяснений по закупке" class="atmo-hash-jump-point" id="explanation-requests">
                    <ExplanationRequestList :purchase-item="pivc.purchaseitem" />
                </AccordionItem>

                <ProposalsList.view v-bind:pivc="pivc" v-bind:proposals="pivc.purchaseitem.proposals" v-bind:purchaseitem="pivc.purchaseitem" />

                <ProtocolSummarizationWidget.view v-if="pivc.isUserEngagedCustomer && pivc.isPurchaseSummarizing" v-bind:proposals="pivc.purchaseitem.proposals" v-bind:purchaseitem="pivc.purchaseitem" />

                <SupplierInfo.view v-if="pivc.isPurchaseSummarized || pivc.hasContract || pivc.hasPurchaseProtocol" v-bind:pivc="pivc" v-bind:purchaseitem="pivc.purchaseitem" v-bind:supplier="pivc.purchaseitem.supplier" />

                <PriceBreakdown.view v-if="(pivc.isPurchaseSummarized || pivc.hasContract || pivc.hasPurchaseProtocol) && !pivc.isPurchaseOutATMO" v-bind:pricebreakdown="pivc.pricebreakdown" />

                <!-- The accepted proposal specification -->
                <DeliverablesListWithVat.view
                    v-if="pivc.isPurchaseSummarized && !pivc.hasContract"
                    v-bind:origin="'notice'"
                    v-bind:islimitvisible="pivc.isUserEngagedCustomer"
                    v-bind:deliverables="pivc.acceptedProposal.deliverables"
                    v-bind:offer-cost="pivc.acceptedProposal.offer_cost"
                    v-bind:price-wo-vat="pivc.acceptedProposal.price_wo_vat">
                    <span v-if="!pivc.isPurchaseOutATMO">принятого предложения</span>
                </DeliverablesListWithVat.view>

                <ContractDetails.view v-if="pivc.hasContract" v-bind:pivc="pivc" v-bind:purchaseitem="pivc.purchaseitem" />

                <!-- The contract specification -->
                <DeliverablesListWithVat.view
                    v-if="pivc.hasContract && !pivc.isPurchaseOutATMO"
                    v-bind:origin="'contract'"
                    v-bind:islimitvisible="false"
                    v-bind:deliverables="pivc.purchaseitem.contract.deliverables"
                    v-bind:offer-cost="pivc.purchaseitem.contract.offer_cost"
                    v-bind:price-wo-vat="pivc.purchaseitem.contract.price_wo_vat">
                    <span v-if="!pivc.isPurchaseOutATMO">договора</span>
                </DeliverablesListWithVat.view>

                <KBKLimitsList.view v-if="pivc.features.kbk_limits && pivc.isUserEngagedCustomer && !pivc.purchaseitem.without_limits && pivc.hasContract && !pivc.isPurchaseOutATMO" v-bind:limits="pivc.purchaseitem.contract.kbk_limits" />

                <KBKLimitsEditableList.view
                    v-if="!pivc.purchaseitem.without_limits && pivc.features.kbk_limits && pivc.hasContract && pivc.isUserEngagedCustomer && !pivc.isContractSignedByCustomer && pivc.isContractSignedBySupplier"
                    v-bind:initiallist="pivc.purchaseitem.contract.kbk_limits"
                    v-bind:totalamount="pivc.purchaseitem.contract.deliverables_total_amount"
                    v-bind:limitfilterpresets="{ year: pivc.purchaseitem.limit_year, purchase_type_id: pivc.purchaseitem.type_id, purchase_id: pivc.purchaseitem.id }">
                    <template v-slot:title>
                        <h5>Измените лимиты</h5>
                    </template>
                </KBKLimitsEditableList.view>

                <KBKLimitsAutonomousUpdateWidget.view
                    v-if="!pivc.purchaseitem.without_limits && pivc.features.kbk_limits && pivc.isContractActive && pivc.isUserEngagedCustomer && pivc.areContractLimitsEditable"
                    v-bind:purchaseid="pivc.purchaseitem.id"
                    v-bind:entity="pivc.purchaseitem.contract"
                    v-bind:limitfilterpresets="{ year: pivc.purchaseitem.limit_year, purchase_type_id: pivc.purchaseitem.type_id, purchase_id: pivc.purchaseitem.id }" />

                <!-- Customer can inline-edit purchase within a view here: -->
                <!-- * add & sign contract if there is yet no one -->
                <editable-file-attachments-list-controller
                    v-slot:default="efalc"
                    v-if="pivc.isUserEngagedCustomer && pivc.isPurchaseSummarized && !pivc.hasContract && !pivc.isContractSignedByCustomer"
                    v-bind:isrequired="true"
                    v-bind:maxfilesallowed="1"
                    v-on:fileattachment:changed="pivc.handleContractAttachment">
                    <!-- NB: Bind origin if attachment handler to handle more than one file origin. -->
                    <!-- v-bind:origin="'contract'" -->

                    <div id="atmo-contract" class="atmo-component-wrapper">
                        <EditableFileAttachmentsList.view v-bind:controller="efalc">
                            <span>
                                <h3>Договор</h3>
                                <div class="atmo-buttons">
                                    <a href="https://app.hinted.me/simulations/3dd987db-9fcf-429b-b912-8ced475b06b9/view" rel="noopener" target="_blank" class="atmo-button atmo-accented"><i class="atmo-icon-play"></i>&nbsp;Инструкция по заключению договора и дополнительного соглашения</a>
                                </div>
                            </span>
                            <template v-slot:controls>
                                <li v-bind:disabled="!pivc.gotContractAttached">
                                    <ProgressIndicatingButton.component v-bind:clickhandler="pivc.storeContract" v-bind:text="'Отправить договор'" />
                                </li>
                            </template>
                        </EditableFileAttachmentsList.view>
                    </div>
                </editable-file-attachments-list-controller>

                <ContractSupplierAccountingInfo.view
                    v-if="pivc.purchaseitem.contract.supplier_accounting && !pivc.purchaseitem.without_limits && pivc.features.kbk_limits && pivc.hasContract && pivc.isUserEngagedCustomer && pivc.isContractSignedByCustomer && pivc.isContractSignedBySupplier"
                    v-bind:supplier-accounting="pivc.purchaseitem.contract.supplier_accounting">
                    <span>Банковские реквизиты поставщика</span>
                </ContractSupplierAccountingInfo.view>

                <ContractSupplierAccounting.view
                    v-if="!pivc.purchaseitem.without_limits && pivc.features.kbk_limits && pivc.hasContract && pivc.isUserEngagedCustomer && !pivc.isContractSignedByCustomer && pivc.isContractSignedBySupplier"
                    v-bind:initial-data="pivc.purchaseitem.contract.supplier_accounting"
                    v-on:select="pivc.supplierAccountingUpdated">
                    <span>Банковские реквизиты поставщика</span>
                </ContractSupplierAccounting.view>

                <ContractAttachments.view v-if="pivc.hasContract" v-bind:pivc="pivc" v-bind:signatures="pivc.purchaseitem.contract.signatures" v-bind:attachments="pivc.contractAttachments.main">
                    <span>Основной договор</span>
                    <template v-if="(pivc.mustSupplierSignContract || pivc.mustCustomerignContract) && !pivc.isContractDisputed" v-slot:controls>
                        <button v-on:click="pivc.invokeSignContractPopup" v-bind:disabled="(pivc.features.kbk_limits && pivc.hasContractErrors) || !$store.getters.getCanSign" class="atmo-button mr-2">Подписать и отправить договор</button>
                        <template v-if="!$store.getters.getCanSign">
                            <span v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
                            <span v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                        </template>
                    </template>
                </ContractAttachments.view>

                <ContractAttachments.view v-if="pivc.hasContract && pivc.isPurchaseOutATMO" v-bind:pivc="pivc" v-bind:signatures="{}" v-bind:attachments="pivc.contractAttachments.confirmation">
                    <span>Подтверждающие документы (акт, накладная, УПД)</span>
                </ContractAttachments.view>

                <ContractDisputes.view v-if="pivc.hasContract && pivc.hasDisputes" v-bind:pivc="pivc" v-bind:disputes="pivc.purchaseitem.contract.disputes" />

                <ContractDisputesResolutionWidget.view v-if="((pivc.isUserEngagedCustomer && pivc.isContractDisputed) || (pivc.isContractProposed && pivc.isUserEngagedSupplier && pivc.canSupplierSubmitDisputes)) && pivc.isPurchaseSummarized" v-bind:pivc="pivc" />

                <ContractAnnexesList.view v-if="pivc.hasContract && pivc.hasContractAnnexes" v-bind:pivc="pivc" v-bind:annexes="pivc.purchaseitem.contract.annexes" />

                <ContractAnnexesUpdateWidget.view v-if="(pivc.isUserEngagedCustomer || pivc.isUserEngagedSupplier) && pivc.isContractSignedByBoth && pivc.isContractActive" v-bind:pivc="pivc" />

                <ContractFulfillment.view v-if="!pivc.isUserEngagedCustomer && pivc.hasContract" v-bind:pivc="pivc" v-bind:attachments="pivc.purchaseitem.contract.fulfillment_attachments" />

                <!-- Customer can inline-edit purchase within a view here: -->
                <!-- * add | remove fulfillment attachments -->
                <editable-contract-fulfillment-file-attachments-list-controller
                    v-slot:default="efalc"
                    v-if="pivc.isUserEngagedCustomer && pivc.hasContract && pivc.isContractSignedByBoth"
                    v-bind:contractid="pivc.purchaseitem.contract.id"
                    v-bind:initiallist="pivc.purchaseitem.contract.fulfillment_attachments"
                    v-bind:origin="'fulfillment'">
                    <!-- v-on:fileattachment:changed="picc.fileAttachmentEventHandler" -->

                    <EditableFileAttachmentsList.view v-bind:controller="efalc">
                        <template>
                            <h3 id="atmo-contract-fulfillment" class="atmo-hash-jump-point atmo-content-group">Исполнение договора</h3>

                            <PurchaseStatusUpdateWidget.view v-if="pivc.hasContract && pivc.isUserEngagedCustomer && pivc.isContractSignedByBoth" v-bind:contractid="pivc.purchaseitem.contract.id" v-bind:contractstatuses="pivc.contractStatuses" v-bind:status="pivc.status" />

                            <h5>Документы об исполнении</h5>
                        </template>
                    </EditableFileAttachmentsList.view>
                </editable-contract-fulfillment-file-attachments-list-controller>

                <ul v-if="!pivc.isPurchaseOutATMO" class="atmo-buttons">
                    <li>
                        <ProgressIndicatingButton.component v-bind:type="'dimmed'" v-bind:clickhandler="pivc.downloadPurchaseInfo" v-bind:text="'Скачать извещение'" />
                    </li>
                    <li v-if="pivc.isPurchaseSummarized || pivc.hasContract || pivc.hasPurchaseProtocol">
                        <ProgressIndicatingButton.component v-bind:type="'dimmed'" v-bind:clickhandler="pivc.downloadProtocol" v-bind:text="'Скачать итоговый протокол'" />
                    </li>
                    <li v-if="pivc.hasContract">
                        <ProgressIndicatingButton.component v-bind:type="'dimmed'" v-bind:clickhandler="pivc.downloadContract" v-bind:text="'Скачать договор'" />
                    </li>
                    <li v-if="pivc.isPurchaseAcceptingProposals && pivc.isSupplier && !pivc.isPurchaseSummarizing">
                        <button v-on:click.stop="pivc.submitOrUpdateProposal" class="atmo-button">
                            <span v-if="!pivc.purchaseitem.has_proposal_from_current_supplier">Подать</span>
                            <span v-else>Изменить</span> предложение
                        </button>
                    </li>
                </ul>

                <div id="atmo-bottom" class="atmo-hash-jump-point"></div>
            </div>
        </div>
    </purchase-item-view-controller>
</template>

<script>
import PurchaseItemViewController from '@core/js/viewmodels/purchases/item/view/PurchaseItemViewController.viewmodel';
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import EditableContractFulfillmentFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableContractFulfillmentFileAttachmentsListController.viewmodel';

import ProgressIndicatingButtonComponent from '@lib/js/src/vue/components/progress/button/ProgressIndicatingButton.component.vue';

import FastAccessMenuView from './partials/FastAccessMenu.view.vue';
import CustomerInfoView from './partials/CustomerInfo.view.vue';
import SupplierInfoView from './partials/SupplierInfo.view.vue';
import PriceBreakdownView from './partials/PriceBreakdown.view.vue';
import SupplierRequirementsView from './partials/supplier-requirements/SupplierRequirements.view.vue';
import PurchaseTermsView from './partials/PurchaseTerms.view.vue';
import SupplyTermsView from './partials/SupplyTerms.view.vue';
import PurchaseStatusUpdateWidgetView from './partials/update/PurchaseStatusUpdateWidget.view.vue';
import ProtocolSummarizationWidgetView from './partials/update/protocol/ProtocolSummarizationWidget.view.vue';
import DeliverablesListView from '../common/partials/deliverables/view/DeliverablesList.view.vue';
import DeliverablesListWithVatView from '../common/partials/deliverables/view/DeliverablesListWithVat.view.vue';
import KBKLimitsListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/view/KBKLimitsList.view.vue';
import KBKLimitsEditableListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/update/KBKLimitsEditableList.view.vue';
import KBKLimitsAutonomousUpdateWidgetView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/update/KBKLimitsAutonomousUpdateWidget.view.vue';
import ProposalsListView from './partials/ProposalsList.view.vue';
import ContractDetailsView from './partials/contract/ContractDetails.view.vue';
import ContractAttachmentsView from './partials/contract/ContractAttachments.view.vue';
import ContractDisputesView from './partials/contract/disputes/ContractDisputes.view.vue';
import ContractDisputesResolutionWidgetView from './partials/update/ContractDisputesResolutionWidget.view.vue';
import ContractAnnexesListView from './partials/contract/annexes/ContractAnnexesList.view.vue';
import ContractAnnexesUpdateWidgetView from './partials/update/annexes/ContractAnnexesUpdateWidget.view.vue';
import ContractFulfillmentView from './partials/contract/ContractFulfillment.view.vue';
import EditableFileAttachmentsListView from 'themes/views/pages/purchases/item/common/partials/EditableFileAttachmentsList.view.vue';
import ContractSupplierAccountingView from './partials/contract/ContractSupplierAccounting.view.vue';
import ContractSupplierAccountingInfoView from './partials/contract/ContractSupplierAccountingInfo.view.vue';
import AccordionItem from '@/components/common/accordion-item.vue';
import ExplanationRequestList from '@/components/purchases/ExplanationRequests/ExplanationRequestList.vue';

export default {
    metaInfo: {
        title: 'Просмотр закупки'
    },
    components: {
        ExplanationRequestList,
        AccordionItem,
        'purchase-item-view-controller': PurchaseItemViewController,
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController,
        'editable-contract-fulfillment-file-attachments-list-controller': EditableContractFulfillmentFileAttachmentsListController,

        'ProgressIndicatingButton.component': ProgressIndicatingButtonComponent,
        'FastAccessMenu.view': FastAccessMenuView,
        'CustomerInfo.view': CustomerInfoView,
        'SupplierInfo.view': SupplierInfoView,
        'PriceBreakdown.view': PriceBreakdownView,
        'PurchaseTerms.view': PurchaseTermsView,
        'SupplierRequirements.view': SupplierRequirementsView,
        'SupplyTerms.view': SupplyTermsView,
        'DeliverablesList.view': DeliverablesListView,
        'DeliverablesListWithVat.view': DeliverablesListWithVatView,
        'KBKLimitsList.view': KBKLimitsListView,
        'KBKLimitsEditableList.view': KBKLimitsEditableListView,
        'KBKLimitsAutonomousUpdateWidget.view': KBKLimitsAutonomousUpdateWidgetView,
        'ProposalsList.view': ProposalsListView,
        'ProtocolSummarizationWidget.view': ProtocolSummarizationWidgetView,
        'PurchaseStatusUpdateWidget.view': PurchaseStatusUpdateWidgetView,
        'ContractDetails.view': ContractDetailsView,
        'ContractAttachments.view': ContractAttachmentsView,
        'ContractSupplierAccounting.view': ContractSupplierAccountingView,
        'ContractSupplierAccountingInfo.view': ContractSupplierAccountingInfoView,
        'ContractDisputes.view': ContractDisputesView,
        'ContractDisputesResolutionWidget.view': ContractDisputesResolutionWidgetView,
        'ContractAnnexesList.view': ContractAnnexesListView,
        'ContractAnnexesUpdateWidget.view': ContractAnnexesUpdateWidgetView,
        'ContractFulfillment.view': ContractFulfillmentView,
        'EditableFileAttachmentsList.view': EditableFileAttachmentsListView
    },
    props: {
        group: { required: true, type: String },
        id: { required: true }
    }
};
</script>
