'use strict';

import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import { Command, IFileAttachmentVMEvent } from '../../../../../common/attachment/EditableFileAttachmentsListController.viewmodel';
import OrganizationVO from '@core/js/ddriven/domain/model/users/Organization.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

export interface IData {
    declinereasons: StandardDictionaryItem[];
    inputs: {
        declinereasonid: number | null;
        comment: string | null;
        attachment: FileAttachmentVO | null;
    };
}

interface IErrors {
    comment: boolean;
    declinereasonid: boolean;
    attachment: boolean;
}

@Component
export default class DeclinePoposalPopupController extends BaseViewModel {
    public static popupId = 'decline-proposal-popup';

    constructor() {
        super();
        this.name = 'DeclinePoposalPopupController';
    }

    @Prop({ required: true }) readonly supplier_ratings!: OrganizationVO['ratings'];
    @Prop({ required: true, default: false, type: Boolean }) readonly supplier_reqs_rating = false;

    created(): void {
        this.$root.$on('public:onoff:toggle', this.initializeData);
    }

    data(): IData {
        return {
            declinereasons: [],
            inputs: {
                declinereasonid: null,
                comment: null,
                attachment: null
            }
        };
    }

    /**
     * Computed
     */
    get declinereasonsFiltered(): StandardDictionaryItem[] {
        const isLowSupplierRatingReasonAvailable: boolean = (!this.$props.supplier_ratings.atmo || parseFloat(this.$props.supplier_ratings.atmo) < 4.5) && this.$props.supplier_reqs_rating;
        return this.$data.declinereasons.filter((item: StandardDictionaryItem) => {
            return (item.id === 1 && isLowSupplierRatingReasonAvailable) || item.id !== 1;
        });
    }

    get errors(): IErrors {
        return {
            declinereasonid: isPrimitiveEmpty(this.$data.inputs.declinereasonid),
            comment: isPrimitiveEmpty(this.$data.inputs.comment) || this.$data.inputs.comment.length < 10,
            attachment: this.$data.inputs.attachment === null
        };
    }

    get hasErrors(): boolean {
        return Object.values(this.errors).some((value: boolean) => {
            return value;
        });
    }

    /**
     * Methods
     */

    public updateFileAttachment(event: IFileAttachmentVMEvent): void {
        this.$data.inputs.attachment = event.command === Command.Add ? event.attachment : null;
    }

    public reset(): void {
        this.$data.inputs.declinereasonid = null;
        this.$data.inputs.comment = null;
        this.$data.inputs.attachment = null;
    }

    public detachedInputs(): IData['inputs'] {
        return Object.assign({}, this.$data.inputs);
    }

    /**
     * Prototype general methods
     */
    private async initializeData(payload: IOnOffOnToggleEventPayload) {
        if (!this.isMeAndAskedTurnOn(payload)) {
            return;
        }
        this.$data.declinereasons = await this.declineReasonsFactory();
    }

    private isMeAndAskedTurnOn(payload: IOnOffOnToggleEventPayload): boolean {
        return payload.id === DeclinePoposalPopupController.popupId && payload.ison === true;
    }

    private async declineReasonsFactory(): Promise<StandardDictionaryItem[]> {
        const response = await ApplicationServiceLocator.get('api').dictionaries.loadDeclineReasons();
        return response.data.map((item: { id: number; name: string }) => {
            return Object.freeze(new StandardDictionaryItem({ id: item.id, title: item.name, is_active: true }));
        });
    }
}
