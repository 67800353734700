'use strict';

// @ts-ignore
import config from 'themes/config/features.json';
import defaults from '@core/config/features.json';

import get from 'lodash.get';
import has from 'lodash.has';
import isEmpty from 'lodash.isempty';

import { FeatureFlags } from '@core/js/ddriven/application/abstractions/types/FeatureFlags';

export default class FeatureFlagsConfig {
    protected static _config: FeatureFlags;

    constructor() {
        FeatureFlagsConfig._config = !isEmpty(config) ? config : defaults;
    }

    public static get(path?: string): FeatureFlags {
        if (!FeatureFlagsConfig._config) {
            new FeatureFlagsConfig();
        } // NB: Create config if it was not before.

        if (path && !has(FeatureFlagsConfig._config, path)) {
            throw new Error(`SVTA Error: theme config variable "${path}" not found.`);
        }

        return path ? get(FeatureFlagsConfig._config, path, true) : FeatureFlagsConfig._config;
    }
}
