'use strict';

import Vue from 'vue';
import BaseLocator from '../abstractions/BaseLocator';

import Config from '../config/Config';
import PurchaseItemEditableService from '@core/js/ddriven/domain/services/purchases/PurchaseItemEditableService';
import DadataService from '@core/js/ddriven/domain/services/address/DadataService';
import ATMOAPIFacade, { IATMOAPIConfig } from '../../ports/adapters/http/outgoing/atmo/ATMOAPIFacade';
import UIToastService from './uitoast/UIToastService';
import SupplierService from '@core/js/ddriven/domain/services/supplier/SupplierService';
import DownloadService from '../../domain/services/downloads/DownloadService';

type TGeneralizedService = PurchaseItemEditableService & DadataService & ATMOAPIFacade & UIToastService & SupplierService & DownloadService;

export default class ApplicationServiceLocator extends BaseLocator {
    protected static _container: ApplicationServiceLocator;

    private vue: Vue;
    private purchaseItemEditableService: PurchaseItemEditableService;
    private dadata: DadataService;
    private api: ATMOAPIFacade;
    private uitoasts: UIToastService;
    private supplier: SupplierService;
    private download: DownloadService;

    constructor(vue: Vue) {
        super();

        this.vue = vue;
        this.purchaseItemEditableService = new PurchaseItemEditableService(this.vue);
        this.dadata = new DadataService(Config.get('theme.variables.global.dadata.baseurl') as string, Config.get('dadata.token') as string);

        this.api = this.apiFactory();
        this.uitoasts = new UIToastService(this.vue);
        this.supplier = new SupplierService(this.vue);
        this.download = new DownloadService(this.api);

        ApplicationServiceLocator._container = this;
    }

    public static get(key: string): TGeneralizedService {
        return super.get(key);
    }

    private apiFactory(): ATMOAPIFacade {
        const token = this.vue.$store.state.token;
        const headers = token && token.length > 0 ? { Authorization: `Bearer ${token}` } : {};
        return new ATMOAPIFacade(Config.get('api.config') as unknown as IATMOAPIConfig, headers);
    }
}
