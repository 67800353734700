<template>
    <div class="accordion atmo-content-group" role="tablist">
        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1 custom-green" role="tab" @click="displayed = !displayed">
                <h3 class="white-color font-weight-bold my-2 ml-3 cursor-pointer">
                    {{ title }}
                    <span class="float-right mr-3"><b-icon :icon="displayed ? 'chevron-up' : 'chevron-down'" class="icon alt" /> </span>
                </h3>
            </b-card-header>
            <b-collapse :id="id" v-model="displayed" :accordion="'my_' + id" role="tabpanel">
                <b-card-body>
                    <b-card-text>
                        <slot></slot>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
export default {
    name: 'accordion-item',
    props: {
        title: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            displayed: this.visible
        };
    },
    computed: {
        id() {
            return 'accordion_' + this._uid;
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
