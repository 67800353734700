<template>
    <div>
        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
            <b-container v-if="!isSended" class="w-50">
                <b-form-group label="Сброс пароля" label-class="fs-18 grey-color">
                    <form-row-edit-text class="mb-0" label="Логин" v-model="username" :v="$v.username" @input="onInput" :error-text="loginErrorText" />
                </b-form-group>
            </b-container>
            <b-container v-else class="grey-color fs-20 fw-600 text-center my-5">
                <p>На Ваш Email отправлено письмо с инструкциями по сбросу пароля.</p>
            </b-container>
            <footer class="modal-footer pb-0 px-0">
                <div class="w-100 text-left">
                    <b-button v-if="!isSended" class="text-uppercase mr-2" variant="custom-outline-secondary" @click="onClose">Отмена</b-button>
                    <b-button v-if="!isSended" :disabled="!state" class="text-uppercase" variant="custom-green" @click="sendRequest">Продолжить</b-button>
                    <b-button v-if="isSended" class="text-uppercase mr-2" variant="custom-outline-secondary" @click="onDone">Закрыть</b-button>
                </div>
            </footer>
        </b-overlay>
    </div>
</template>

<script>
import { helpers, required } from 'vuelidate/lib/validators';
import { $api } from '@/services/backend/api';

const loginBackError = (param) => helpers.withParams({ type: 'loginBackError', value: param }, (value) => !helpers.req(value) || param === false);
export default {
    name: 'forgot-password',
    validations() {
        return {
            username: {
                required,
                loginBackError: loginBackError(this.loginBackError)
            }
        };
    },
    data() {
        return {
            username: '',
            loginBackError: false,
            loginErrorText: '',
            isLoading: false,
            isSended: false
        };
    },
    methods: {
        onInput() {
            this.loginBackError = false;
        },
        onClose() {
            this.$emit('close');
        },
        onDone() {
            this.$emit('done');
        },
        async sendRequest() {
            this.$emit('loadStart');
            this.isLoading = true;
            $api.authentication
                .forgotPassword(this.username)
                .then(() => {
                    this.isSended = true;
                })
                .catch((error) => {
                    this.loginBackError = true;
                    this.loginErrorText = error.response.data.message;
                })
                .finally(() => {
                    this.$emit('loadEnd');
                    this.isLoading = false;
                });
        }
    },
    computed: {
        state() {
            return this.$v !== null ? !this.$v.$invalid : null;
        },
        errorMessage() {
            if (this.$v) {
                if (!this.$v.username.loginBackError) return this.loginErrorText;
            }
            return '';
        }
    }
};
</script>
