import { OrgRoles } from '@/const';
import Dummy from '@/components/Dummy.vue';

export default [
    {
        path: '/external_purchases/:mode(create|edit_draft)/:id(\\d+)?',
        component: () => import(/* webpackChunkName: "main" */ '@/components/externalPurchases/Create'),
        name: 'ExternalPurchaseCreate',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_FL44 }
    },
    {
        path: '/external_purchases/:id(\\d+)/edit',
        component: () => import(/* webpackChunkName: "main" */ '@/components/externalPurchases/ExternalPurchaseEdit'),
        name: 'ExternalPurchaseEdit',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_FL44 }
    },
    {
        path: '/external_purchases/:id(\\d+)',
        name: 'ExternalPurchaseShow',
        component: () => import(/* webpackChunkName: "main" */ '@/components/externalPurchases/Show.vue')
    },
    {
        path: '/purchases/create/:id?',
        redirect: () => {
            return `/purchases/grouped/fl44/item/create`;
        }
    },
    {
        path: '/purchases/show/:id',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/Show'),
        name: 'PurchasesShow'
    },
    {
        path: '/purchases',
        redirect: { name: 'PurchasesList', params: { path: 'new' } },
        name: 'TestTest',
        component: Dummy,
        children: []
    },
    {
        path: '/purchases/:id/new_offer',
        name: 'PurchasesNewOffer',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/NewOffer')
    },
    {
        path: '/purchases/fl223/create',
        component: () => import(/* webpackChunkName: "main" */ '@/components/products/fl223/create.vue'),
        name: 'Fl223PurchaseCreate',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_FL223 }
    },
    {
        path: '/purchases/fl223/:id/edit',
        component: () => import(/* webpackChunkName: "main" */ '@/components/products/fl223/create.vue'),
        name: 'Fl223PurchaseEdit',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_FL223 }
    },
    {
        path: '/purchases/fl223/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/products/fl223/show.vue'),
        name: 'Fl223PurchaseShow'
    },
    {
        path: '/purchases/fl223/:id/new_proposal',
        name: 'Fl223PurchaseNewProposal',
        component: () => import(/* webpackChunkName: "main" */ '@/components/products/fl223/proposal-create.vue'),
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_FL223 }
    },
    {
        path: '/purchases/commercial/create',
        component: () => import(/* webpackChunkName: "main" */ '@/components/products/commercial/create.vue'),
        name: 'CommercialPurchaseCreate',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_COMMERCIAL }
    },
    {
        path: '/purchases/commercial/:id/edit',
        component: () => import(/* webpackChunkName: "main" */ '@/components/products/commercial/create.vue'),
        name: 'CommercialPurchaseEdit',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_COMMERCIAL }
    },
    {
        path: '/purchases/commercial/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/products/commercial/show.vue'),
        name: 'CommercialPurchaseShow'
    },
    {
        path: '/purchases/commercial/:id/new_proposal',
        name: 'CommercialPurchaseNewProposal',
        component: () => import(/* webpackChunkName: "main" */ '@/components/products/commercial/proposal-create.vue'),
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_COMMERCIAL }
    }
];
