<template>
    <entity-status-menu-controller v-slot:default="esmc" v-bind:route="elpc.storeprefix" v-bind:statuses="elpc.dictionaries.statusesmenu" v-bind:currentstatus="elpc.currentstatus" v-on:status:update="elpc.processRequestUpdate">
        <responsive-on-off v-slot:default="ronoff">
            <div class="atmo-container-fixed">
                <div class="atmo-status-menu-controller">
                    <button v-on:click="ronoff.toggle" class="atmo-onoff"><i class="atmo-icon-burger"></i>&nbsp;<span v-text="esmc.currentTitle"></span></button>

                    <ul v-show="ronoff.ison" class="atmo-menu">
                        <li v-for="menuitem in esmc.statuses" v-bind:key="menuitem.id" v-on:click="ronoff.off">
                            <button v-bind:disabled="esmc.isDisabledMenuItem(menuitem.id)" v-on:click="esmc.status(menuitem.id)">
                                <span v-text="menuitem.title"></span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </responsive-on-off>
    </entity-status-menu-controller>
</template>

<script>
import isTypeOrNull from '@lib/js/src/misc/isTypeOrNull';
import EntityStatusMenuController from '@core/js/viewmodels/common/entity/EntityStatusMenuController.viewmodel.ts';
import ResponsiveOnOff from '@lib/js/src/vue/components/ResponsiveOnOff.viewmodel.ts';

export default {
    props: {
        elpc: { required: true, type: Object },
        route: { required: true, type: String },
        statuses: { required: true, type: Array },
        currentstatus: {
            required: true,
            validator: (prop) => {
                return isTypeOrNull(prop, 'number');
            }
        }
    },
    components: {
        'entity-status-menu-controller': EntityStatusMenuController,
        'responsive-on-off': ResponsiveOnOff
    }
};
</script>
