'use strict';

import { Command, IFileAttachmentVMEvent } from '@/rearchitected/core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import BaseVO from './Base.valueobject';

type TSelfPOJO = Omit<FileAttachmentVO, 'valueOrDefault'>;

export enum FileAttachmentOrigin {
    Contract = 'contract',
    Notice = 'notice'
}

export interface IRawFileAttachmentRefactored {
    file_id: string;
    name: string;
}

export interface IRawFileAttachmentData {
    id: number | string;
    name: string;
    is_archived?: boolean;
    created_at?: string;
    attachment_record_id?: number;
    type: string;
}

export default class FileAttachmentVO extends BaseVO {
    file_id: number | string;
    name: string;
    attachment_record_id?: number | null;
    is_archived?: boolean;
    created_at?: string | null;
    storage: string;

    constructor(data: IRawFileAttachmentData) {
        super();

        this.file_id = data.id;
        this.name = data.name;
        this.attachment_record_id = this.valueOrDefault('attachment_record_id', data);
        this.is_archived = data.is_archived ?? false;
        this.created_at = this.valueOrDefault('created_at', data);
        this.storage = data.type;

        Object.seal(this);
    }

    public static fromPOJO(data: TSelfPOJO): FileAttachmentVO {
        const raw: IRawFileAttachmentData = {
            id: data.file_id,
            name: data.name,
            is_archived: data.is_archived,
            created_at: data.created_at as string,
            attachment_record_id: data.attachment_record_id as number,
            type: data.storage
        };

        return new FileAttachmentVO(raw);
    }

    public static fromArray(dataArray: IRawFileAttachmentData[]): FileAttachmentVO[] {
        return dataArray.map((data: IRawFileAttachmentData) => {
            return new FileAttachmentVO(data);
        });
    }

    public static updatedAttachmentValue(updated: IFileAttachmentVMEvent): FileAttachmentVO | null {
        let value = null;
        updated.command === Command.Add && (value = FileAttachmentVO.fromPOJO(updated.attachment));

        return value;
    }
}
