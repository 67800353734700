'use strict';

// import State from './state';
// import getters from './getters';
// import actions from './actions';
// import mutations from './mutations';
import popups from './popups/index';

const ApplicationStoreModule = {
    namespaced: true,

    // state: new State(),
    // getters: getters,
    // actions: actions,
    // mutations: mutations,
    modules: {
        popups
    }
};

export default ApplicationStoreModule;
