import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';

export class PersonalFl44APIService extends BaseApiService {
    constructor() {
        super('personal/fl44');
    }

    // async getNoticesList(filters) {
    //     try {
    //         const response = await axios.get(this.getUrl('notices'), { params: filters });
    //         return response.data;
    //     } catch (err) {
    //         this.handleErrors(err);
    //     }
    // }

    async getProposalsList(filters) {
        try {
            const response = await axios.get(this.getUrl('proposals'), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
