<template>
    <b-row>
        <filter-text :md="0" v-model="$props.controller.request.filter.reg_number" label="Рег.номер" placeholder="Введите рег.номер" />
        <filter-text :md="0" v-model="$props.controller.request.filter.name" label="Наименование товара (работ, услуг)" placeholder="Введите наименование или его часть" />
        <filter-text :md="0" v-model="$props.controller.request.filter.specs" label="Характеристики" placeholder="Введите текст характеристик" />
        <filter-text :md="0" v-model="$props.controller.request.filter.okpd_code" label="Код ОКПД2" placeholder="Введите код ОКПД2 или его часть" />
        <b-col md="auto" class="favorite-btn text-right">
            <b-button v-if="$props.controller.request.filter.show_favorite" class="text-uppercase" variant="custom-outline-secondary" @click="$props.controller.request.filter.show_favorite = !$props.controller.request.filter.show_favorite"
                >Избранное <b-icon icon="star-fill" class="ml-2"
            /></b-button>
            <b-button v-else class="text-uppercase" variant="custom-green" @click="$props.controller.request.filter.show_favorite = !$props.controller.request.filter.show_favorite">Избранное <b-icon icon="star" class="ml-2" /></b-button>
        </b-col>

        <!--        <filter-text :md="3" v-model="$props.controller.request.filter.reg_number" label="Регистрационный номер" placeholder="Введите регистрационный номер позиции КТРУ" />-->
        <!--        <clearable-input v-slot:default="ci">-->
        <!--            <li class="atmo-form__field atmo-inverse">-->
        <!--                <label for="gsc-details-registration-number">Регистрационный номер</label>-->
        <!--                <input v-model="$props.controller.request.filter.reg_number" type="text" id="gsc-details-registration-number" placeholder="Введите регистрационный номер позиции КТРУ" autocomplete="off" />-->
        <!--                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>-->
        <!--            </li>-->
        <!--        </clearable-input>-->

        <!--        <clearable-input v-slot:default="ci">-->
        <!--            <li class="atmo-form__field atmo-inverse">-->
        <!--                <label for="deliverable-item-title">Наименование товара (работ, услуг)</label>-->
        <!--                <input v-model="$props.controller.request.filter.name" type="text" id="deliverable-item-title" placeholder="Введите наименование или его часть" autocomplete="off" />-->
        <!--                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>-->
        <!--            </li>-->
        <!--        </clearable-input>-->

        <!--        <clearable-input v-slot:default="ci">-->
        <!--            <li class="atmo-form__field atmo-inverse">-->
        <!--                <label for="deliverable-item-requirements">Характеристики</label>-->
        <!--                <input v-model="$props.controller.request.filter.specs" type="text" id="deliverable-item-requirements" placeholder="Введите характеристики" autocomplete="off" />-->
        <!--                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>-->
        <!--            </li>-->
        <!--        </clearable-input>-->

        <!--        <clearable-input v-slot:default="ci">-->
        <!--            <li class="atmo-form__field atmo-inverse">-->
        <!--                <label for="deliverable-item-okpd-code">Код ОКПД2</label>-->
        <!--                <input v-model="$props.controller.request.filter.okpd_code" type="text" id="deliverable-item-okpd-code" placeholder="Введите код ОКПД2 или его часть" autocomplete="off" />-->
        <!--                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>-->
        <!--            </li>-->
        <!--        </clearable-input>-->
    </b-row>
</template>

<script>
// import ClearableInput from '@lib/js/src/vue/components/ClearableInput.viewmodel';
import FilterText from '@/components/common/filter-components/filter-text.vue';

export default {
    props: {
        controller: { required: true, type: Object }
    },
    components: {
        FilterText
        // 'clearable-input': ClearableInput
    }
};
</script>
<style scoped>
.favorite-btn {
    margin-top: 28px;
}
</style>
