import { render, staticRenderFns } from "./text-row-link.vue?vue&type=template&id=1fb03ae8&"
import script from "./text-row-link.vue?vue&type=script&lang=js&"
export * from "./text-row-link.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-dev/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fb03ae8')) {
      api.createRecord('1fb03ae8', component.options)
    } else {
      api.reload('1fb03ae8', component.options)
    }
    module.hot.accept("./text-row-link.vue?vue&type=template&id=1fb03ae8&", function () {
      api.rerender('1fb03ae8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/form-rows/text-row-link.vue"
export default component.exports