<template>
    <div id="atmo-price-breakdown" class="atmo-hash-jump-point atmo-content-group">
        <h3>Стоимость</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li class="mb-2 pb-2">
                <dt>Начальная цена контракта, руб.:</dt>
                <dd>{{ this.$props.pricebreakdown.starting_price | formatnumber }}</dd>
            </li>
            <li class="mb-2 pb-2">
                <dt>Финальная цена договора (сделки), руб.:</dt>
                <dd>{{ this.$props.pricebreakdown.final_price | formatnumber }}</dd>
            </li>
            <li class="mb-2 pb-2">
                <dt>Размер скидки, руб. / %:</dt>
                <dd>
                    <span>{{ this.$props.pricebreakdown.discount | formatnumber }}</span>
                    &nbsp;/&nbsp;
                    <span>{{ this.$props.pricebreakdown.discount_percent | formatnumber:rounded }}%</span>
                </dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        pricebreakdown: { required: true, type: Object }
    }
};
</script>
