<template>
    <section v-if="noticeitem.actions.supplier?.proposal.create || noticeitem.actions.supplier.proposal.edit">
        <template v-if="hasRestrictions">
            <b-button v-if="noticeitem.actions.supplier.proposal.create" :id="'popover-fl223-' + noticeitem.id" class="text-uppercase my-3" target="_blank" variant="custom-green"> Подать предложение</b-button>
            <b-button v-if="noticeitem.actions.supplier.proposal.edit" :id="'popover-fl223-' + noticeitem.id" class="text-uppercase my-3" target="_blank" variant="custom-golden"> Изменить предложение</b-button>
            <b-popover :target="'popover-fl223-' + noticeitem.id" triggers="hover">
                <p v-if="noticeitem.restrictions.money.status" class="fs-12 my-1 py-1">
                    На лицевом счете недостаточно средств. Для подачи предложения не хватает <span class="text-danger text-nowrap">{{ $formatPrice(noticeitem.restrictions.money.not_enough) }}</span> руб.<br />
                    <router-link :to="{ name: 'ProviderAccountCabinet' }" class="system_link-style">Пополнить лицевой счет</router-link>
                    .
                </p>
                <p v-if="noticeitem.restrictions.smp.status" class="fs-12 my-1 py-1">Подача предложения невозможна, так как Ваша организация не является СМП.</p>
            </b-popover>
        </template>
        <template v-else>
            <b-button v-if="noticeitem.actions.supplier.proposal.create" :to="{ name: 'Fl223PurchaseNewProposal', params: { id: noticeitem.id } }" class="text-uppercase my-3" target="_blank" variant="custom-green"> Подать предложение</b-button>
            <b-button v-if="noticeitem.actions.supplier.proposal.edit" :to="{ name: 'Fl223PurchaseNewProposal', params: { id: noticeitem.id } }" class="text-uppercase my-3" target="_blank" variant="custom-golden"> Изменить предложение</b-button>
        </template>
    </section>
</template>

<script>
export default {
    name: 'fl223-new-proposal-button',
    props: ['noticeitem'],
    computed: {
        hasRestrictions() {
            return this.noticeitem.restrictions.money.status || this.noticeitem.restrictions.smp.status;
        }
    }
};
</script>
