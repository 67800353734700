import axios from 'axios';
import { BaseV1ApiService } from '@/services/backend/baseV1';
import store from '@/store';

export class QuotationRequestsApiService extends BaseV1ApiService {
    constructor() {
        super('quotation_requests');
    }

    async getList(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getItem(id) {
        try {
            const response = await axios.get(this.getUrl(`offers/${id}`));
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async showRequest(id) {
        try {
            const response = await axios.get(this.getUrl(`requests/${id}`));
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async showOffer(id) {
        try {
            const response = await axios.get(this.getUrl(`offers/${id}/show`));
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    downloadRequest(id) {
        return axios.get(this.getUrl(`requests/${id}/download`), { responseType: 'blob' });
    }

    downloadOffer(id) {
        return axios.get(this.getUrl(`offers/${id}/download`), { responseType: 'blob' });
    }

    async getRequestXml(data) {
        try {
            const response = await axios.post(this.getUrl('requests/get_xml'), data);
            return response.data.xml;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async getOfferXml(id, data) {
        try {
            const response = await axios.post(this.getUrl(`offers/${id}/get_xml`), data);
            return response.data.xml;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async storeSignedRequest(data) {
        try {
            await axios.post(this.getUrl('requests'), data);
            store
                .dispatch('pushToast', {
                    text: 'Запрос КП сформирован и размещен',
                    title: 'Успех',
                    variant: 'green',
                    timer: 5000
                })
                .then(() => {});
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async storeSignedOffer(id, data) {
        try {
            await axios.post(this.getUrl(`offers/${id}`), data);
            store
                .dispatch('pushToast', {
                    text: 'Предложение КП сформировано',
                    title: 'Успех',
                    variant: 'green',
                    timer: 5000
                })
                .then(() => {});
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }
}
