'use strict';

import FileAttachmentVO from '../../../common/FileAttachment.valueobject';
import { toXML } from 'jstoxml';

export interface IParameters {
    user_id: number;
    organization_id: number;
    annex_id: number;
    comment: string;
    attachment: FileAttachmentVO;
}

export default class AnnexDisputeProtocolForXMLVO {
    user_id: number;
    organization_id: number;
    supplementary_agreement_id: number;
    dispute_message: string;
    dispute_file: {
        name: string;
        stored_document_id: string;
    };

    constructor(data: IParameters) {
        this.user_id = data.user_id;
        this.organization_id = data.organization_id;
        this.supplementary_agreement_id = data.annex_id;
        this.dispute_message = data.comment;
        this.dispute_file = {
            name: data.attachment.name,
            stored_document_id: data.attachment.file_id as string
        };
    }

    public toXML(): string {
        const object = JSON.parse(JSON.stringify({ signed_form: { contract_supplementary_agreement_dispute: this } }));
        return toXML(object, { header: true, indent: '    ' });
    }
}
