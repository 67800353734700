'use strict';

import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class BaseViewModel extends Vue {
    name: string | undefined;

    constructor() {
        super();
        this.name = 'BaseViewModel';
    }

    render() {
        return this.$scopedSlots.default ? this.$scopedSlots.default(this) : this.$slots.default;
    }
}
