var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group" },
    [
      _c("on-off", {
        attrs: { options: { onEsc: false, onBody: false } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (onoff) {
              return [
                _c("kbk-limits-autonomous-update-widget-controller", {
                  attrs: {
                    purchaseid: _vm.$props.purchaseid,
                    entity: _vm.$props.entity,
                    onoff: onoff,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (klauwc) {
                          return [
                            _c(
                              "div",
                              { staticClass: "atmo-component-wrapper" },
                              [
                                onoff.ison
                                  ? _c(
                                      "div",
                                      { staticClass: "atmo-nested-section" },
                                      [
                                        _c("KBKLimitsEditableList.view", {
                                          attrs: {
                                            initiallist: klauwc.limits,
                                            totalamount:
                                              klauwc.deliverables_total_amount,
                                            limitfilterpresets:
                                              _vm.$props.limitfilterpresets,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "title",
                                                fn: function () {
                                                  return [
                                                    _c("h6", [
                                                      _vm._v("Измените лимиты"),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        onoff.ison
                                          ? _c(
                                              "ul",
                                              { staticClass: "atmo-buttons" },
                                              [
                                                _c("li", [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "atmo-button atmo-button-inverse atmo-dimmed",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          onoff.off()
                                                          klauwc.close()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Отмена ")]
                                                  ),
                                                ]),
                                                _c(
                                                  "li",
                                                  [
                                                    _c(
                                                      "ProgressIndicatingButton.component",
                                                      {
                                                        attrs: {
                                                          clickhandler:
                                                            klauwc.saveLimits,
                                                          text: "Сохранить изменения",
                                                          classes:
                                                            "atmo-button atmo-accented-mid",
                                                          disabledstate:
                                                            klauwc.has_limits_errors ||
                                                            !klauwc.have_limits_changed,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !onoff.ison
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "atmo-button",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return onoff.on.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Изменить лимиты")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }