'use strict';

import { Component } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import TabMenuService, { ITabMenuData } from '@lib/js/src/vue/services/TabMenuService';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import PurchaseItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditable.valueobject';
import PurchaseItemEditablePartialDecodedVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditablePartialDecoded.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import DownloadConfigFactory from '@core/js/ddriven/domain/services/downloads/DownloadConfigFactory';
import ApplicationServiceLocator from '@/rearchitected/core/js/ddriven/application/services/ApplicationServiceLocator';
import { Certificate, createXMLSignature, getCertificate } from 'crypto-pro';
import store from '@/store';

interface IData {
    user: UserVO | {};
    inputs: PurchaseItemEditableVO | {};
    decoded: PurchaseItemEditablePartialDecodedVO | {};
    durations: ExtendedDictionaryItem[];
    xml: string;
    isDataSigned: boolean;
    tabs: ITabMenuData;
    certificate: Certificate | null;
}

@Component
export default class SavePurchasePopupController extends BaseViewModel {
    public static popupId = 'save-purchase-popup';
    public tabMenuService?: TabMenuService;

    constructor() {
        super();
        this.name = 'SavePurchasePopupController';
    }

    beforeCreate(): void {
        this.tabMenuService = new TabMenuService();
    }

    created(): void {
        this.$root.$on('public:onoff:toggle', this.initializeData);
    }

    data(): IData {
        return {
            user: { organization: {} },
            inputs: {},
            decoded: {},
            durations: [],
            xml: '',
            isDataSigned: false,

            // REFACTOR: Extract the tabs behaviour to the dedicated
            // viewmodel controller. The motivation is to eliminate
            // the tabs menu management logic that is alien here.
            tabs: this.tabMenuService!.tabs || null,
            certificate: null
        };
    }

    /**
     * Computed
     */
    get hasCertificatesLoaded(): boolean {
        // @ts-ignore
        return !!this.$data.certificate;
    }

    get currentParsedCertificate(): Certificate | {} {
        if (!this.hasCertificatesLoaded) {
            return {};
        }
        return this.$data.certificate;
    }

    /**
     * Methods
     */
    public selectTab(index: number) {
        this.tabMenuService!.select.call(this.$data, index);
    }

    public isSelectedTab(index: number): boolean {
        return this.tabMenuService!.isSelected.call(this.$data, index);
    }

    public async downloadFileAttachment(attachment: FileAttachmentVO, evt?: Event) {
        const config = DownloadConfigFactory.fromFileAttachment(attachment);
        await ApplicationServiceLocator.get('download').fileByBrowser(config);
    }

    public async signData(evt: Event) {
        await actWithLoadingSpinner(evt, async () => {
            try {
                this.$data.xml = await createXMLSignature(store.getters.getCurrentThumbprint, this.$data.xml);
                this.$data.isDataSigned = true;
            } catch (err) {
                store.dispatch('showDangerToast', err).then(() => {});
            }
        });
    }

    async savePurchase(evt: Event) {
        if (!this.$data.certificate) {
            return;
        }

        await actWithLoadingSpinner(evt, async () => {
            await ApplicationServiceLocator.get('purchaseItemEditableService').savePurchase(this.$data.inputs, { durations: this.$data.durations, urgencyreasons: this.$data.urgencyreasons }, this.$data.xml, this.$data.certificate);
        });

        this.$root.$emit('public:onoff:toggle', { id: SavePurchasePopupController.popupId, ison: false });
        this.$router.push({ name: 'CustomerPurchasesCabinet' });
    }

    /**
     * Prototype general methods
     */
    private async initializeData(payload: IOnOffOnToggleEventPayload) {
        if (payload.id !== 'save-purchase-popup' || (payload.id === 'save-purchase-popup' && !payload.ison)) {
            return;
        }
        this.selectTab(1);
        this.$data.isDataSigned = false;
        this.$data.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);

        this.$data.user = payload.data.user;
        this.$data.inputs = payload.data.inputs;
        this.$data.decoded = payload.data.decoded;
        this.$data.durations = payload.data.durations;
        this.$data.urgencyreasons = payload.data.urgencyreasons;

        this.$data.xml = (await ApplicationServiceLocator.get('api').entities.generatePurchaseXML(payload.data.inputs.id)).original.response?.data.xml;
    }
}
