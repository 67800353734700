'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';

import isTypeOrNull from '@lib/js/src/misc/isTypeOrNull';
import StandardPagination from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import PaginationHelpers from './PaginationHelpers';
import IViewmodelEventPayload from '@core/js/ddriven/application/abstractions/vue/IViewmodelEventPayload';

interface IData {
    pages: number[];
    first: null | number;
    last: null | number;
    current: null | number;
    prev: null | number;
    next: null | number;
}

@Component
export default class StandardPaginationController extends BaseViewModel {
    constructor() {
        super();
        this.name = 'StandardPaginationController';
    }

    @Prop({ default: 'short', type: String }) readonly representaton!: string;
    @Prop({
        required: true,
        validator: (prop: null | StandardPagination) => {
            return isTypeOrNull(prop, 'object');
        }
    })
    readonly pagination!: StandardPagination;

    data(): IData {
        return {
            pages: [],
            first: null,
            last: null,
            current: null,
            prev: null,
            next: null
        };
    }

    /**
     * Computed
     */
    get isPaginationVisible() {
        return this.$data.pages.length > 0;
    }

    get isLeftControlsVisible() {
        return this.$data.current > this.$data.first;
    }

    get isRightControlsVisible() {
        return this.$data.current < this.$data.last;
    }

    /**
     * Methods
     */
    page(newPage: number) {
        if (newPage === this.$data.current) {
            return;
        }
        this.$emit('pagination:pageupdate', { key: 'page', value: newPage } as IViewmodelEventPayload);
    }

    isActive(pageNumber: number) {
        return pageNumber === this.$data.current;
    }

    @Watch('pagination', { immediate: true, deep: true })
    onPaginationChanged(newState: StandardPagination) {
        if (!newState) return;
        this.$data.first = 1;
        this.$data.last = newState.page.last;
        this.$data.current = newState.page.current;
        this.$data.prev = newState.page.current > 1 ? newState.page.current - 1 : newState.page.current;
        this.$data.next = newState.page.current < newState.page.last ? newState.page.current + 1 : newState.page.current;

        const pages = this.$props.representaton === 'short' ? PaginationHelpers.fourToThreePages(newState.page.current, newState.page.last) : PaginationHelpers.allPages(newState.page.last);

        this.$data.pages = newState.itemsTotal < newState.page.perpage ? [] : pages;
    }
}
