'use strict';

import S3 from './strategies/S3Strategy.valueobject';
import Purchaseinfo from './strategies/PurchaseinfoStrategy.valueobject';
import Invoice from './strategies/InvoiceStrategy.valueobject';
import Protocol from './strategies/ProtocolStrategy.valueobject';
import Contract from './strategies/ContractStrategy.valueobject';

import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import { AllowedConfigTypes, ConfigFactorySettings } from './DownloadConfig.valueobject';
import Annex from './strategies/AnnexStrategy.valueobject';
import OrganizationVO from '../../model/users/Organization.valueobject';
import OrganizationReportStrategy from './strategies/OrganizationReportStrategy.valueobject';
import SignOrganizationReliabilityReportDownloadRequestVO from '@core/js/ddriven/application/http/requests/misc/SignOrganizationReliabilityReportDownloadRequest.valueobject';
import ReliabilityReport from './strategies/ReliabiltyReportStrategy.valueobject';

type Strategies = [typeof S3, typeof Purchaseinfo, typeof Invoice, typeof Protocol, typeof Annex, typeof Contract, typeof ReliabilityReport];
type TypeofStrategy = typeof S3 | typeof Purchaseinfo | typeof Invoice | typeof Protocol | typeof Annex | typeof Contract | typeof ReliabilityReport;
export type Strategy = S3 | Purchaseinfo | Invoice | Protocol | Annex | Contract | OrganizationReportStrategy | ReliabilityReport;

export default class DownloadConfigFactory {
    private static _strategies: Strategies = [S3, Purchaseinfo, Invoice, Protocol, Annex, Contract, ReliabilityReport];

    public static fromFileAttachment(attachment: FileAttachmentVO): Strategy {
        const strategyConstructor = DownloadConfigFactory.getStrategyConstructorByType(attachment.storage || 's3');

        /**
         * REFACTOR: Here and below so far at the existing (small) number of target configs
         * it is not clear how to better align the input arguments (group them?) with all the
         *  target configs.
         *
         * Potential future solution could be to group target configs under a union type
         * and unify the input parameter into the single;
         */
        // @ts-ignore
        return new strategyConstructor(attachment.storage ? attachment : { ...attachment, storage: 's3' });
    }

    public static fromPurchaseItemDetails(id: number, purchaseRegistrationNumber: string): Strategy {
        const strategyConstructor = DownloadConfigFactory.getStrategyConstructorByType('purchaseinfo');

        /**
         * REFACTOR: See the comment above.
         */
        // @ts-ignore
        return new strategyConstructor(id, purchaseRegistrationNumber);
    }

    public static forDepositReplenishInvoice(amount: number): Strategy {
        const strategyConstructor = DownloadConfigFactory.getStrategyConstructorByType('invoice');

        // @ts-ignore
        return new strategyConstructor(amount);
    }

    public static forFinalProtocol(id: number, purchaseRegistrationNumber: string) {
        const strategyConstructor = DownloadConfigFactory.getStrategyConstructorByType('protocol');

        // @ts-ignore
        return new strategyConstructor(id, purchaseRegistrationNumber);
    }

    public static forContract(id: number, purchaseRegistrationNumber: string) {
        const strategyConstructor = DownloadConfigFactory.getStrategyConstructorByType('contract');

        // @ts-ignore
        return new strategyConstructor(id, purchaseRegistrationNumber);
    }

    public static forOrganizationReport(organization: OrganizationVO): OrganizationReportStrategy {
        return new OrganizationReportStrategy(organization);
    }

    // REFACTOR: All other factory methods in favor of forAnyDownloads
    // to make the parameter of any strategy a uniform object as much as possible.
    public static forAnyDownload(settings: ConfigFactorySettings) {
        const strategyConstructor = DownloadConfigFactory.getStrategyConstructorByType(settings.type);

        // @ts-ignore
        return new strategyConstructor(settings);
    }

    public static forOrganizationReliabilityReport(request: SignOrganizationReliabilityReportDownloadRequestVO) {
        const strategyConstructor = DownloadConfigFactory.getStrategyConstructorByType(AllowedConfigTypes.reliabiltyreport);

        // @ts-ignore
        return new strategyConstructor(request);
    }

    private static getStrategyConstructorByType(type: string): TypeofStrategy {
        const strategyConstructor = DownloadConfigFactory._strategies.find((strategy: any) => {
            // return strategy.prototype.constructor.name.toLowerCase() === type;
            return strategy._type.toLowerCase() === type;
        });

        if (!strategyConstructor) {
            throw new Error(`ATMO Exception: The template for download type: "${type}" is not defined in the dictionary.`);
        }

        return strategyConstructor;
    }
}
