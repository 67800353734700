import { CabinetModelApiService } from '@/services/backend/cabinet/base';
import axios from 'axios';
import store from '@/store';

export class CabinetKtruRequestsAPIService extends CabinetModelApiService {
    constructor() {
        super('ktru/requests');
    }

    async getList(page) {
        try {
            const response = await axios.get(this.getUrl(), { params: { page: page } });
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async getDraft(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}`));
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async store(form, isDraft = true, id = null) {
        try {
            const formData = {
                is_draft: isDraft,
                form: form
            };
            const response = await axios.post(this.getUrl() + (id ? `/${id}` : ''), formData);
            await store.dispatch('showSuccessToast', `${isDraft ? 'Черновик' : 'Запрос'} успешно сохранён`);
            return response.data;
        } catch (error) {
            this.handleErrors(error);
            throw new Error(error.message);
        }
    }

    async delete(id) {
        try {
            await axios.delete(this.getUrl(id));
            await store.dispatch('showSuccessToast', 'Запрос на добавление позиции КТРУ успешно удалён');
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }
}
