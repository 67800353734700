var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-contract" },
    },
    [
      _c("h3", [_vm._v("Договор")]),
      _vm.pivc.isUserEngagedCustomer || _vm.pivc.isUserEngagedSupplier
        ? _c("div", { staticClass: "atmo-buttons" }, [
            _vm.pivc.isUserEngagedCustomer
              ? _c(
                  "a",
                  {
                    staticClass: "atmo-button atmo-accented",
                    attrs: {
                      href: "https://app.hinted.me/simulations/3dd987db-9fcf-429b-b912-8ced475b06b9/view",
                      rel: "noopener",
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-play" }),
                    _vm._v(
                      " Инструкция по заключению договора и дополнительного соглашения"
                    ),
                  ]
                )
              : _vm._e(),
            _vm.pivc.isUserEngagedSupplier
              ? _c(
                  "a",
                  {
                    staticClass: "atmo-button atmo-accented",
                    attrs: {
                      href: "https://app.hinted.me/simulations/3116f060-cfc9-4600-baa2-68c13a96320a/view",
                      rel: "noopener",
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-play" }),
                    _vm._v(
                      " Инструкция по заключению договора и дополнительного соглашения"
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.pivc.isPurchaseOutATMO
        ? _c(
            "ul",
            {
              staticClass:
                "atmo-content-block-2-columns atmo-no-item-separator",
            },
            [
              _c("li", { staticClass: "mb-2 pb-2" }, [
                _c("dt", [
                  _vm._v("Предмет договора - наименование закупочной сессии:"),
                ]),
                _c("dd", {
                  domProps: {
                    textContent: _vm._s(this.$props.purchaseitem.description),
                  },
                }),
              ]),
              this.$props.purchaseitem.customer_contract_number
                ? _c("li", { staticClass: "mb-2 pb-2" }, [
                    _c("dt", [
                      _vm._v("Номер договора в системе учета заказчика:"),
                    ]),
                    _c("dd", {
                      domProps: {
                        textContent: _vm._s(
                          this.$props.purchaseitem.customer_contract_number
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
              this.$props.purchaseitem.contract.hasDeliveryCost()
                ? _c("li", { staticClass: "mb-2 pb-2" }, [
                    _c("dt", [_vm._v("Стоимость доставки, руб.:")]),
                    _c("dd", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatnumber")(
                            this.$props.purchaseitem.contract.delivery_cost
                          )
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("li", { staticClass: "mb-2 pb-2" }, [
                _c("dt", [_vm._v("Цена договора, руб.:")]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatnumber")(
                        this.$props.purchaseitem.contract.price_total
                      )
                    )
                  ),
                ]),
              ]),
              _c("li", { staticClass: "mb-2 pb-2" }, [
                _c("dt", [_vm._v("Статус договора, дата:")]),
                _c("dd", [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        this.$props.purchaseitem.contract
                          .acceptance_status_title
                      ),
                    },
                  }),
                  _vm._v(", "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatdate")(
                          this.$props.purchaseitem.contract
                            .acceptance_status_updated_at,
                          "withSeconds"
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _c("li", { staticClass: "mb-2 pb-2" }, [
                _c("dt", [_vm._v("Статус закупки:")]),
                _c("dd", {
                  domProps: {
                    textContent: _vm._s(this.$props.purchaseitem.status_title),
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }