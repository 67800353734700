'use strict';

import { nanoid } from 'nanoid';
import random from 'lodash.random';

import DeliverableItemEditableValidator from './AnnexDeliverableItemEditable.validator';
import GSCDetailsVO, { IRawGSCDetails } from '../../GSCDetails.valueobject';
import DeliverableItemRequirementVO, { IRawDeliverableItemRequirement } from '../../DeliverableItemRequirement.valueobject';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';

interface INormalizedData {
    grouping_id: string;
    id: number | string | null;
    another_id: number | string | null;
    is_delivery: boolean;
    quantity: number;
    price_per_unit: number;
    title: string;
    okpd_id: number;
    okpd_code: string;
    okei_id: number;
    okei_symbolic_code: string;
    gsc_details: GSCDetailsVO;
    requirements: DeliverableItemRequirementVO[];
    validator: DeliverableItemEditableValidator;
}

export interface IRawSupplementaryAgreementItem {
    id: number | null;
    purchase_item_id: number | null;
    is_delivery_item: boolean;
    is_manually_added: boolean;
    amount: number;
    price: number;
    name: string;
    okpd_id: number;
    okpd_code: string;
    okei_id: number;
    okei_name: string;
    ktru_item: IRawGSCDetails;

    // Validation targets
    min_amount: number;
    max_amount: number;
    max_price: number;
}

export default class AnnexDeliverableItemEditableVO {
    private _isDuplicate: boolean;
    private _isRestored: boolean;
    private _isDeleted: boolean;
    private _validator: DeliverableItemEditableValidator;

    grouping_id: string;

    id: number | string | null;

    /**
     * There are 2 IDs for the same object that come from backend. Not clear why the same
     * object has to have two IDs but I am forced to implement both. I cannot think a good
     * name for just `id` one as I see no meaning for it. So I named it simply as follows.
     */
    another_id: number | string | null;

    is_delivery: boolean;
    display_id: string;
    quantity: number;
    price_per_unit: number;
    price_total: number;
    title: string;
    okpd_id: number;
    okpd_code: string;
    okei_id: number;
    okei_symbolic_code: string;
    gsc_details: GSCDetailsVO;
    requirements: DeliverableItemRequirementVO[];

    private constructor(data: INormalizedData, isDuplicate?: boolean, isRestored?: boolean) {
        this._isDuplicate = isDuplicate ?? false;
        this._isRestored = isRestored ?? false;
        this._isDeleted = false;
        this._validator = data.validator;

        this.grouping_id = data.grouping_id;

        this.id = data.id;
        this.another_id = data.another_id;
        this.is_delivery = data.is_delivery;
        this.display_id = this.displayId(data.grouping_id);
        this.quantity = data.quantity;
        this.price_per_unit = data.price_per_unit;
        this.price_total = roundToTwoDecimals(data.price_per_unit * data.quantity);
        this.title = data.title;
        this.okpd_id = data.okpd_id;
        this.okpd_code = data.okpd_code;
        this.okei_id = data.okei_id;
        this.okei_symbolic_code = data.okei_symbolic_code;

        this.gsc_details = data.gsc_details;
        this.requirements = data.requirements;
    }

    public static fromRawSupplementaryAgeementItem(data: IRawSupplementaryAgreementItem): AnnexDeliverableItemEditableVO {
        const isDuplicate: boolean = data.is_manually_added;

        const normalized: INormalizedData = {
            grouping_id: data.purchase_item_id ? data.purchase_item_id.toString() : nanoid(5),
            id: data.purchase_item_id,
            another_id: data.id ?? null,
            is_delivery: data.is_delivery_item,
            quantity: data.amount,
            price_per_unit: data.price,
            title: data.name,
            okpd_id: data.okpd_id,
            okpd_code: data.okpd_code,
            okei_id: data.okei_id,
            okei_symbolic_code: data.okei_name,
            gsc_details: new GSCDetailsVO(data.ktru_item),
            requirements: data.ktru_item?.specs.map((data: IRawDeliverableItemRequirement) => {
                return new DeliverableItemRequirementVO(data);
            }),
            validator: DeliverableItemEditableValidator.fromRawSupplementaryAgeementItem(data)
        };

        return new AnnexDeliverableItemEditableVO(normalized, isDuplicate, isDuplicate);
    }

    get isDuplicate(): boolean {
        return this._isDuplicate;
    }

    get isRestored(): boolean {
        return this._isRestored;
    }

    get isDeleted(): boolean {
        return this._isDeleted;
    }

    get isEditable(): boolean {
        return !this.is_delivery;
    }

    get validator(): DeliverableItemEditableValidator {
        return this._validator;
    }

    public duplicate(): AnnexDeliverableItemEditableVO {
        const duplicate = new AnnexDeliverableItemEditableVO(this, true);

        duplicate.display_id = this.displayId(this.grouping_id);
        duplicate.quantity = 1;
        duplicate.another_id = null;
        duplicate.price_per_unit = this.validator.initials.price_per_unit;

        return duplicate;
    }

    public validate(): DeliverableItemEditableValidator {
        return this._validator.validate(this);
    }

    public recalculateTotalPrice(): void {
        this.price_total = this.price_per_unit * this.quantity;
    }

    public setDeleted(): void {
        this._isDeleted = true;
    }

    private displayId(id: number | string | null): string {
        return `${id}:${random(1000, 10000)}`;
    }
}
