'use strict';

import buildFormatter, { format as Formatter } from 'format-number';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';

interface IData {
    isInputFocused: boolean;
    isInputValid: boolean;
    inputValue: unknown;
}

const REMOVE_LEADING_ZERO_REGEX = /^0+(?![\b0,|.])/;

@Component
export default class FormattedInputNumber extends BaseViewModel {
    private inputEl?: HTMLInputElement | null;
    private lastValidInputWithDecimalsValue?: string;
    private format = { integerSeparator: ' ', decimal: ',', padRight: 2, padLeft: 1, round: 2 };
    private formatter?: Formatter;

    constructor() {
        super();
        this.name = 'FormattedInputNumber';
        this.inputEl = undefined;
        this.lastValidInputWithDecimalsValue = undefined;
    }

    @Prop({ default: 2, type: Number }) readonly decimals!: number;
    @Prop({ default: true, type: Boolean }) readonly padright!: number;

    created(): void {
        this.format.padRight = this.$props.padright ? this.$props.decimals : 0;
        this.format.round = this.$props.decimals;
        this.formatter = buildFormatter(this.format);
    }

    mounted(): void {
        this.inputEl = this.$el.querySelector('input');
        if (!this.inputEl || this.inputEl.type !== 'number') {
            throw new Error(`WJ Exception: There must be an input element of type "number" witin a scope of the ${this.name} viewmodel.`);
        }

        this.lastValidInputWithDecimalsValue = this.inputEl.value;
        this.$data.inputValue = this.inputEl.value;
        this.$data.isInputValid = this.inputEl.validity.valid;
    }

    data(): IData {
        return {
            isInputFocused: false,
            isInputValid: true,
            inputValue: null
        };
    }

    /**
     * Computed
     */
    public get formatted(): string {
        return this.formatter!(this.$data.inputValue);
    }

    /**
     * Methods
     */
    public focus(): void {
        this.$data.isInputFocused = true;
        this.$nextTick(() => {
            this.inputEl!.focus();
        });
    }

    public onTriggerFocus(evt: Event): void {
        this.focus();
    }

    /**
     * General prototype methods
     */
    public processBlurEvent(evt: FocusEvent): void {
        const inputValue = this.inputEl!.value.replace(REMOVE_LEADING_ZERO_REGEX, '');

        this.$data.isInputFocused = false;
        this.$data.inputValue = isPrimitiveEmpty(inputValue) ? '0' : inputValue;
        this.$data.isInputValid = this.inputEl!.validity.valid;
    }

    public processKeydownEvent(evt: KeyboardEvent): void {
        if (!evt.key || ['e', '-'].includes(evt.key)) {
            evt.preventDefault();
        }
    }

    public processInputEvent(evt: InputEvent): void {
        const value = (evt.target as HTMLInputElement).value.replace(REMOVE_LEADING_ZERO_REGEX, '');

        // Guard against input more decimals than allowed.
        if (value.includes('.') && value.split('.')[1].length > this.$props.decimals) {
            (evt.target as HTMLInputElement).value = this.lastValidInputWithDecimalsValue as string;
            evt.target!.dispatchEvent(new Event('input'));
            // WARNING: Watch out to not delete this early return.
            return;
        }

        // WARNING: Set the new last valid decimals value.
        this.lastValidInputWithDecimalsValue = value;
    }
}
