<template>
    <section class="atmo-content-group" v-if="this.$props.customer">
        <h6>Информация о заказчике</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Полное наименование:</dt>
                <dd v-text="this.$props.customer.title"></dd>
            </li>
            <li>
                <dt>ИНН:</dt>
                <dd v-text="this.$props.customer.inn"></dd>
            </li>
            <li v-if="this.$props.customer.kpp">
                <dt>КПП:</dt>
                <dd v-text="this.$props.customer.kpp"></dd>
            </li>
            <li v-if="this.$props.customer.ogrn">
                <dt>ОГРН:</dt>
                <dd v-text="this.$props.customer.ogrn"></dd>
            </li>
            <li>
                <dt>Адрес:</dt>
                <dd v-text="this.$props.customer.address"></dd>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        customer: { required: true }
    }
};
</script>
