import fl223 from './fl223';
import fl44 from './fl44';
import commercial from './commercial';

export default {
    // legacy: {
    //   directories: {
    //     paymentTypes: new LegacyDirectoryPaymentTypes(),
    //     paymentTerms: new LegacyDirectoryPaymentTerms(),
    //     purchaseCategories: new LegacyDirectoryPurchaseCategories(),
    //   },
    //   organizations: new LegacyOrganizations(),
    // },
    fl223: fl223,
    fl44: fl44,
    commercial: commercial
};
