<template>
    <sign-contract-popup-controller v-slot:default="scpc">
        <on-off v-slot:default="onoff" v-bind:options="{ id: 'sign-contract-popup', onEsc: false, onBody: false }">
            <div v-if="onoff.ison" class="atmo-popup-wrapper">
                <div class="atmo-popup-content atmo-save-purchase-popup">
                    <button v-on:click.stop="onoff.off" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                    <header>
                        <h5>Подписать и отправить контракт</h5>

                        <tab-menu-controller v-slot:default="tmc" v-bind:tabmenuid="'sign-contract-tabmenu'">
                            <ul class="atmo-tabs-menu-universal">
                                <li>
                                    <button v-on:click="tmc.select(1)" v-bind:class="{ 'atmo-active': tmc.isSelected(1) }">Данные закупки</button>
                                </li>
                                <li>
                                    <button v-on:click="tmc.select(2)" v-bind:class="{ 'atmo-active': tmc.isSelected(2) }">XML-представление данных закупки</button>
                                </li>
                            </ul>
                        </tab-menu-controller>
                    </header>

                    <tab-menu-tabs-controller v-slot:default="tmtc" v-bind:tabmenuid="'sign-contract-tabmenu'">
                        <main class="atmo-tabs-container">
                            <TabPurchasePreview.view v-if="tmtc.isSelected(1)" v-bind:user="scpc.user" v-bind:controller="scpc" v-bind:onoff="onoff" v-bind:purchaseitem="scpc.purchaseitem" v-bind:handlers="scpc.handlers" v-bind:certificate="scpc.$data.certificate" />

                            <TabPurchaseXMLPreview.view v-if="tmtc.isSelected(2)" v-bind:controller="scpc" v-bind:onoff="onoff" v-bind:xml="scpc.xml" v-bind:certificate="scpc.$data.certificate" />
                        </main>
                    </tab-menu-tabs-controller>
                </div>
            </div>
        </on-off>
    </sign-contract-popup-controller>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import SignContractPopupController from '@core/js/viewmodels/purchases/item/view/contract/sign/SignContractPopupController.viewmodel';
import TabMenuController from '@lib/js/src/vue/components/tabmenu/TabMenuController.viewmodel';
import TabMenuTabsController from '@lib/js/src/vue/components/tabmenu/TabMenuTabsController.viewmodel';

import TabPurchasePreview from './TabPurchasePreview.view.vue';
import TabPurchaseXMLPreview from './TabPurchaseXMLPreview.view.vue';

export default {
    components: {
        'on-off': new OnOff(),
        'sign-contract-popup-controller': SignContractPopupController,
        'tab-menu-controller': TabMenuController,
        'tab-menu-tabs-controller': TabMenuTabsController,
        'TabPurchasePreview.view': TabPurchasePreview,
        'TabPurchaseXMLPreview.view': TabPurchaseXMLPreview
    }
};
</script>
