import axios from 'axios';
import FileDownload from 'js-file-download';

export default {
    documentDownload(file) {
        axios.get('/documents/' + file.id, { responseType: 'blob' }).then((response) => {
            FileDownload(response.data, file.name);
        });
    },
    commonDocumentDownload(file) {
        this.documentDownload(file);
    },
    downloadInvoice(url, name, payload = null, method = 'POST') {
        axios({
            url: url,
            method: method,
            data: payload,
            responseType: 'blob' // important
        })
            .then((response) => {
                var filename = '';
                var disposition = response.headers['content-disposition'];
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let invoiceNumberRegex = /\d\d\d\d-\d\d\d\d\d\d/;
                    var matches = invoiceNumberRegex.exec(disposition);
                    if (matches != null && matches[0]) {
                        filename = matches[0].replace(/['"]/g, '');
                    }
                }
                filename = 'Счет-договор_СМЗ-' + filename + '.pdf';

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => console.log(error));
    },
    sendFileToEmail(url, payload = {}, method = 'POST') {
        return axios({
            url: url,
            method: method,
            data: payload,
            responseType: 'json'
        });
    }
};
