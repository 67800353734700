<template>
    <supplier-deposit-replenish-popup-form v-slot:default="sdrpf">
        <on-off v-slot:default="onoff" v-bind:currentstate="sdrpf.ison" v-on:onoff:ontoggle="sdrpf.close">
            <div v-show="sdrpf.ison" class="atmo-popup-wrapper">
                <div v-on:click.stop class="atmo-popup-content">
                    <button v-on:click.stop="onoff.off" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                    <h5>Внимание!</h5>

                    <p>Блокировка денежных средств для обеспечения проведения торгов невозможна. Пожалуйста пополните лицевой счет.</p>
                    <p>Необходимо для списания <span v-text="sdrpf.minimumAmount"></span> рублей.</p>

                    <form id="form-supplier-deposit-replenish" class="atmo-form--inverse">
                        <div class="atmo-form__field atmo-inverse">
                            <input v-model="sdrpf.amount" type="number" id="deposit-amount" name="deposit-amount" min="0" />
                            <label for="deposit-amount">Сумма пополнения депозита</label>
                        </div>

                        <div class="atmo-form__field atmo-inverse">
                            <input v-model="sdrpf.email" type="email" id="deposit-user-email" name="deposit-user-email" />
                            <label for="deposit-user-email">E-mail пользователя</label>
                        </div>
                    </form>

                    <div class="atmo-controls">
                        <button v-bind:disabled="!sdrpf.isInputValid || sdrpf.issending" v-on:click="sdrpf.sendInvoiceToEmail" class="atmo-button">Отправить на E-MAIL</button>
                        <button v-bind:disabled="!sdrpf.isInputValid || sdrpf.issending" v-on:click="sdrpf.downloadInvoice" class="atmo-button">Скачать PDF</button>
                        <div v-if="sdrpf.issending" class="atmo-spinner"><img src="../../../assets/images/loaders/spinner-sector.svg" alt="" /></div>
                    </div>

                    <div v-if="!sdrpf.isInputValid" class="atmo-message">
                        <p>Введены некорректные значения полей. Пожалуйста, исправьте для отправки инвойса.</p>
                    </div>
                </div>
            </div>
        </on-off>
    </supplier-deposit-replenish-popup-form>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import SupplierDepositReplenishPopupForm from '@core/js/viewmodels/supplier/SupplierDepositReplenishPopupForm.viewmodel.ts';

export default {
    components: {
        'on-off': new OnOff(),
        'supplier-deposit-replenish-popup-form': SupplierDepositReplenishPopupForm
    }
};
</script>
