'use strict';

import State from './state';
import { IRawStandardDictionaryData, IRawDictionaryItem } from '../../purchases/dictionaries/mutations';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
// import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';

const mutations = {
    // setPurchaseTypes: function (state: State, data: IRawDictionaryItem[]) {
    //     state.types = data.map((item: IRawDictionaryItem) => {
    //         return Object.freeze(new ExtendedDictionaryItem({ id: item.id, title: item.text, value: item.value }));
    //     });
    // },

    setDeliverablesGroups: function (state: State, data: IRawStandardDictionaryData[]) {
        state.deliverablesgroups = data.map((item: IRawStandardDictionaryData) => {
            return Object.freeze(new StandardDictionaryItem({ id: item.id, title: item.name, is_active: item.is_active }));
        });
    },

    setMunicipalities: function (state: State, data: IRawStandardDictionaryData[]) {
        state.municipalities = data.map((item: IRawStandardDictionaryData) => {
            return Object.freeze(new StandardDictionaryItem({ id: item.id, title: item.name, is_active: item.is_active }));
        });
    }
};

export default mutations;
