<template>
    <on-off v-slot:default="onoff" v-bind:options="{ id: 'signature-popup', onEsc: false, onBody: false }">
        <div v-show="onoff.ison" class="atmo-popup-wrapper">
            <div v-if="onoff.content" class="atmo-popup-content">
                <button v-on:click.stop="onoff.off" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                <h5>
                    <span v-if="onoff.content.length < 2">Электронная подпись</span>
                    <span v-else>Электронные подписи</span>
                </h5>

                <SignatureViewItem.view v-for="(signature, index) in onoff.content" v-bind:key="index" v-bind:data="signature" />
            </div>
        </div>
    </on-off>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import SignatureViewItemView from './SignatureViewItem.view';

export default {
    components: {
        'on-off': new OnOff(),
        'SignatureViewItem.view': SignatureViewItemView
    }
};
</script>
