<template>
    <section class="atmo-tab-content atmo-content-group">
        <h6>Организация</h6>

        <OrganizationInfo.view v-bind:organization="this.controller.viewmodelDataService.viewables.organization" />

        <p class="atmo-error">Стоимость отчета <b v-text="this.controller.viewmodelDataService.viewables.financials.report_price"></b> руб.<br />Эта сумма будет списана с лицевого счета Вашей организации при успешном скачивании отчета.</p>

        <p v-if="this.controller.viewmodelDataService.editable.has_to_email_report">Отчет будет дополнительно отправлен на email: <b v-text="this.controller.viewmodelDataService.editable.email"></b></p>
    </section>
</template>

<script>
import OrganizationInfoView from 'themes/views/pages/client-space/supplier/organization-reliability-report/OrganizationInfo.partial.view.vue';

export default {
    props: {
        controller: { required: true, type: Object },
        certificate: { required: true, type: Object }
    },
    components: {
        'OrganizationInfo.view': OrganizationInfoView
    }
};
</script>
