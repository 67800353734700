'use strict';

export type Body = Record<string, unknown> | {};

export interface ConfigFactorySettings {
    type: AllowedConfigTypes;
    payload: Record<string, any>;
}

export enum AllowedConfigTypes {
    local = 'local',
    s3 = 's3',
    purchaseinfo = 'purchaseinfo',
    invoice = 'invoice',
    protocol = 'protocol',
    annex = 'annex',
    contract = 'contract',
    organizationreport = 'organizationreport',
    reliabiltyreport = 'reliabiltyreport'
}

export default class DownloadConfig {
    private static _allowedConfigTypes: string[] = Object.values(AllowedConfigTypes);

    verb: string | null;
    type: string | null;
    endpoint: string | null;
    name: string | null;
    body: Body;

    constructor(configType: string) {
        if (!this.isAllowedConfigType(configType)) {
            throw new Error(`ATMO Exception: Invalid or missing download type: "${configType}"`);
        }

        this.verb = null;
        this.type = configType;
        this.endpoint = null;
        this.name = null;
        this.body = {};
    }

    public hasSeparateNameSetter(): boolean {
        return (
            Object.prototype.hasOwnProperty.call(Object.getPrototypeOf(this), 'setName') &&
            // @ts-ignore
            typeof this.setName === 'function'
        );
    }

    private isAllowedConfigType(configType?: string): boolean {
        return configType ? DownloadConfig._allowedConfigTypes.indexOf(configType) > -1 : false;
    }
}
