<template>
    <section class="atmo-page-banner-wrapper atmo-slim">
        <div class="atmo-container-fixed">
            <h2>Объявление о закупочной сессии</h2>

            <ul class="atmo-content-block-2-columns atmo-no-item-separator atmo-in-banner">
                <li>
                    <dt>Организация:</dt>
                    <dd v-text="this.$props.user.organization.title"></dd>
                </li>
                <li>
                    <dt>Сокращенное наименование:</dt>
                    <dd v-text="this.$props.user.organization.title_brief"></dd>
                </li>
                <li>
                    <dt>ИНН:</dt>
                    <dd v-text="this.$props.user.organization.inn"></dd>
                </li>
                <li v-if="this.$props.user.organization.kpp">
                    <dt>КПП:</dt>
                    <dd v-text="this.$props.user.organization.kpp"></dd>
                </li>
                <li v-if="this.$props.user.organization.ogrn">
                    <dt>ОГРН:</dt>
                    <dd v-text="this.$props.user.organization.ogrn"></dd>
                </li>
                <li>
                    <dt>Адрес:</dt>
                    <dd v-text="this.$props.user.organization.address"></dd>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        user: { required: true, type: Object }
    }
};
</script>
