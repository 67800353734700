var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "select-cert-modal",
          attrs: {
            size: "lg",
            "hide-header": "",
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          on: { show: _vm.onShow },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isLoading,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c("form-row-edit-textarea", {
                    attrs: {
                      label:
                        "Напишите текст " +
                        _vm.titleText +
                        " разъяснений по закупке",
                      v: _vm.$v.form.text,
                      "label-on-top": true,
                      rows: 5,
                    },
                    model: {
                      value: _vm.form.text,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "text", $$v)
                      },
                      expression: "form.text",
                    },
                  }),
                  _c("form-row-single-line-document-upload", {
                    staticClass: "mb-0",
                    attrs: {
                      title: "Загрузка файла",
                      v: _vm.$v.form.document,
                      "label-cols-lg": "2",
                    },
                    model: {
                      value: _vm.form.document,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "document", $$v)
                      },
                      expression: "form.document",
                    },
                  }),
                ],
                1
              ),
              _c("b-form-invalid-feedback", { attrs: { state: _vm.state } }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isLoading,
                    "spinner-small": "",
                    rounded: "",
                    opacity: "0.6",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v("Отмена")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { disabled: !_vm.state, variant: "custom-green" },
                      on: { click: _vm.onSave },
                    },
                    [_vm._v("Отправить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }