'use strict';

import Config from '@core/js/ddriven/application/config/Config';
import StatusDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StatusDictionaryItem.valueobject';
import ActiveDealStage from '@core/js/ddriven/domain/model/purchases/deal/ActiveDealStage.valueobject';

export interface INoticeRawDataItem {
    product: {
        id: number;
        title: string;
    };
    id: number;
    registration_number: string;

    proposals_count: number;
    best_proposal_price: number | null;
    discount_percentage: number | null;
    status: StatusDictionaryItem;
    starting_price: number;
    planned_date_contract_signed: string;
    planned_date_contract_fulfilled: string;
    protocol_date: string | null;
    deliverables_count: number;
    deliverable_group_title: string;
    purchase_object: string | null;
    customer: {
        id: number;
        name: string;
    };
    contract: boolean;
    delivery_addresses: string[];
    purchase_publish_date: string;
    proposal_accept_end_date: string;
    are_proposals_hidden: boolean;
    conditions: Record<string, boolean>;
    actions: any;
    restrictions: any;
    only_smp: boolean;
    supplier: {
        id: number;
        name: string;
    } | null;
}

export default class NoticeItem {
    product: {
        id: number;
        title: string;
    };
    id: number;
    registration_number: string;
    proposals_count: number;
    best_proposal_price: number | null;
    discount_percentage: number | null;
    status: StatusDictionaryItem;
    starting_price: number;
    planned_date_contract_signed: string;
    planned_date_contract_fulfilled: string;
    protocol_date: string | null;
    deliverables_count: number;
    deliverable_group_title: string;
    purchase_object: string | null;
    customer: {
        id: number;
        name: string;
    };
    contract: boolean;
    delivery_addresses: string[];
    purchase_publish_date: string;
    proposal_accept_end_date: string;
    are_proposals_hidden: boolean;
    active_deal_stage: ActiveDealStage | null;
    actions: any;
    restrictions: any;
    only_smp: boolean;
    supplier: {
        id: number;
        name: string;
    } | null;

    constructor(purchaseData: INoticeRawDataItem) {
        const timezone = Config.get('timezone.customer') as string;

        this.product = {
            id: purchaseData.product.id,
            title: purchaseData.product.title
        };
        this.id = purchaseData.id;
        this.registration_number = purchaseData.registration_number;
        this.proposals_count = purchaseData.proposals_count;
        this.best_proposal_price = purchaseData.best_proposal_price;
        this.discount_percentage = purchaseData.discount_percentage;
        this.status = new StatusDictionaryItem(purchaseData.status);
        this.starting_price = purchaseData.starting_price;
        this.planned_date_contract_signed = new Date(purchaseData.planned_date_contract_signed).toISOString();
        this.planned_date_contract_fulfilled = new Date(purchaseData.planned_date_contract_fulfilled).toISOString();
        this.protocol_date = purchaseData.protocol_date ? new Date(purchaseData.protocol_date).toISOString() : null;
        this.deliverables_count = purchaseData.deliverables_count;
        this.deliverable_group_title = purchaseData.deliverable_group_title;
        this.purchase_object = purchaseData.purchase_object;
        this.customer = {
            id: purchaseData.customer.id,
            name: purchaseData.customer.name
        };
        this.contract = purchaseData.contract ?? false;
        this.delivery_addresses = purchaseData.delivery_addresses;
        this.purchase_publish_date = new Date(purchaseData.purchase_publish_date).toISOString();
        this.proposal_accept_end_date = purchaseData.proposal_accept_end_date;
        this.are_proposals_hidden = purchaseData.are_proposals_hidden;
        this.active_deal_stage = ActiveDealStage.factoryFromDealActivities(purchaseData.conditions);
        this.actions = purchaseData.actions;
        this.restrictions = purchaseData.restrictions;
        this.only_smp = purchaseData.only_smp ?? false;
        this.supplier = purchaseData.supplier
            ? {
                  id: purchaseData.supplier.id,
                  name: purchaseData.supplier.name
              }
            : null;
    }

    public hasProposals(): boolean {
        return this.proposals_count > 0;
    }
}
