<template>
    <section class="atmo-content-group" v-if="this.$props.purchaseitem">
        <h6>Предмет договора</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Предмет закупки:</dt>
                <dd v-text="this.$props.purchaseitem.description"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.winnerProposal && this.$props.purchaseitem.winnerProposal.delivery_cost">
                <dt>Стоимость доставки (руб.):</dt>
                <dd>{{ this.$props.purchaseitem.winnerProposal.delivery_cost | formatnumber }}</dd>
            </li>
            <li v-if="this.$props.purchaseitem.winnerProposal && this.$props.purchaseitem.winnerProposal.total_price">
                <dt>Цена договора, руб:</dt>
                <dd>{{ this.$props.purchaseitem.winnerProposal.total_price | formatnumber }}</dd>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        purchaseitem: { required: true }
    }
};
</script>
