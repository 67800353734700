'use strict';

import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import ContractItemVO from '@core/js/ddriven/domain/model/contracts/ContractItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import SupplierService from '@core/js/ddriven/domain/services/supplier/SupplierService';
import ApplicationServiceLocator from '../../ddriven/application/services/ApplicationServiceLocator';

interface IData {
    countdown: null | string;
}

@Component
export default class ContractItem extends BaseViewModel {
    constructor() {
        super();
        this.name = 'ContractItem';
    }

    @Prop({ required: true, type: Object }) readonly elpc!: Vue;
    @Prop({ required: true, type: Object }) readonly contractitem!: ContractItemVO;

    data(): IData {
        return {
            countdown: null
        };
    }

    /**
     * Computed
     */
    get status() {
        return this.getStatus.call(this);
    }

    get hasSupplier() {
        return this.$props.contractitem.supplier_organization_id !== null;
    }

    get hasDeliveryAddresses() {
        return this.$props.contractitem.delivery_addresses.length > 0;
    }

    get hasAllSummaryItemsVisible() {
        return [7, 8].indexOf(this.status.id) === -1;
    }

    get hasCompletionDate() {
        return this.$props.contractitem.planned_completion_date !== null;
    }

    get user() {
        return this.$store.getters['rearchitected/users/user'];
    }

    /**
     * Methods
     */
    public getStatusClass(classPrefix: string) {
        return `${classPrefix}${this.status.code}`;
    }

    public async submitProposal() {
        await ApplicationServiceLocator.get('api').checkBeforeProposal(this.$props.contractitem.id);
    }

    public async updateProposal() {
        await ApplicationServiceLocator.get('api').checkBeforeProposal(this.$props.contractitem.id);
    }

    /**
     * Prototype general methods.
     */
    getStatus() {
        return this.$props.elpc.dictionaries.statuses.find((dictionaryStatus: ExtendedDictionaryItem) => {
            return this.$props.contractitem.status_id === dictionaryStatus.id;
        });
    }
}
