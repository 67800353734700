var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-component-wrapper" }, [
    _c(
      "ul",
      {
        staticClass: "atmo-entity-item-title atmo-icon-custom",
        class: _vm.ci.getStatusClass("atmo-entity-status-"),
      },
      [
        _c(
          "li",
          {
            staticClass: "pr-4",
            staticStyle: {
              "white-space": "nowrap",
              overflow: "hidden",
              "text-overflow": "ellipsis",
            },
          },
          [
            _c(
              "b-badge",
              {
                staticClass: "my-auto fs-14 mr-2",
                attrs: { variant: "primary" },
              },
              [_vm._v("44-ФЗ")]
            ),
            _vm.ci.contractitem.purchase_is_outside_atmo
              ? _c("b-badge", { staticClass: "my-auto fs-14 mr-2" }, [
                  _vm._v("Вне ИС"),
                ])
              : _vm._e(),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.ci.contractitem.purchase_is_outside_atmo
                    ? `/external_purchases/${_vm.ci.contractitem.purchase_id}`
                    : `/purchases/grouped/${_vm.ci.elpc.group}/item/${_vm.ci.contractitem.purchase_id}/view`,
                  target: "_blank",
                },
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.ci.contractitem.purchase_registration_number
                    ),
                  },
                }),
              ]
            ),
            _vm.ci.contractitem.purchase_object
              ? [
                  _vm._v(
                    ' На закупку "' +
                      _vm._s(_vm.ci.contractitem.purchase_object) +
                      '"'
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("li", { staticClass: "text-nowrap" }, [
          _c("span", {
            staticClass: "text-uppercase",
            domProps: { textContent: _vm._s(_vm.ci.status.title) },
          }),
        ]),
      ]
    ),
    _c("ul", { staticClass: "atmo-entity-item-details" }, [
      _c("li", { staticClass: "atmo-column atmo-special-style" }, [
        _c("ul", [
          _vm.ci.status.code !== "omitted"
            ? _c("li", [
                _c("dt", [_vm._v("Договор заключен:")]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.ci.contractitem.signature_date,
                        "onlyDate"
                      )
                    )
                  ),
                ]),
                _vm.ci.contractitem.purchase_is_outside_atmo &&
                _vm.ci.contractitem.planned_completion_date
                  ? _c("span", [
                      _c("dt", [_vm._v("Дата исполнения договора:")]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatdate")(
                              _vm.ci.contractitem.planned_completion_date,
                              "onlyDate"
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column" }, [
        _c("ul", [
          _c("li", [
            _c("dt", [_vm._v("Предмет закупки:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(_vm.ci.contractitem.purchase_description),
              },
            }),
          ]),
          _c("li", [
            _c("dt", [_vm._v("Организация:")]),
            _c("dd", [
              _c("a", {
                attrs: {
                  href:
                    "/organizations/" +
                    _vm.ci.contractitem.customer_organization_id,
                },
                domProps: {
                  textContent: _vm._s(
                    _vm.ci.contractitem.customer_organization_title
                  ),
                },
              }),
            ]),
          ]),
          _vm.ci.hasSupplier
            ? _c("li", [
                _c("dt", [_vm._v("Поставщик:")]),
                _c("dd", [
                  _c("a", {
                    attrs: {
                      href:
                        "/organizations/" +
                        _vm.ci.contractitem.supplier_organization_id,
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.ci.contractitem.supplier_organization_title
                      ),
                    },
                  }),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column" }, [
        _c("ul", [
          _vm.ci.hasCompletionDate
            ? _c("li", [
                _c("dt", [_vm._v("Планируемая дата исполнения:")]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.ci.contractitem.planned_completion_date,
                        "onlyDate"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _c("li", [
            _vm._v("Количество позиций: "),
            _c("b", {
              domProps: {
                textContent: _vm._s(_vm.ci.contractitem.purchase_items_count),
              },
            }),
          ]),
          _c("li", [
            _c("abbr", { attrs: { title: "Начальная цена контракта" } }, [
              _vm._v("НЦК"),
            ]),
            _vm._v(": "),
            _c("b", [
              _vm._v(
                _vm._s(
                  _vm._f("formatnumber")(
                    _vm.ci.contractitem.purchase_starting_price
                  )
                ) + " руб."
              ),
            ]),
          ]),
          _vm.ci.hasDeliveryAddresses
            ? _c(
                "li",
                [
                  _c("dt", [
                    _vm._v("Место доставки или указание на самовывоз:"),
                  ]),
                  _vm._l(
                    _vm.ci.contractitem.delivery_addresses,
                    function (address, index) {
                      return _c("dd", {
                        key: index,
                        domProps: { textContent: _vm._s(address) },
                      })
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column atmo-special-style" }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.ci.contractitem.purchase_is_outside_atmo
                    ? `/external_purchases/${_vm.ci.contractitem.purchase_id}`
                    : `/purchases/grouped/${_vm.ci.elpc.group}/item/${_vm.ci.contractitem.purchase_id}/view`,
                  target: "_blank",
                },
              },
              [
                _c("i", { staticClass: "atmo-icon-search" }),
                _c("span", [_vm._v("Информация о закупке")]),
              ]
            ),
          ]),
          !_vm.ci.contractitem.purchase_is_outside_atmo
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/purchases/grouped/${_vm.ci.elpc.group}/item/${_vm.ci.contractitem.purchase_id}/view#atmo-proposals`,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-dynamic" }),
                    _c("span", [_vm._v("Динамика предложений")]),
                  ]
                ),
              ])
            : _vm._e(),
          !_vm.ci.contractitem.purchase_is_outside_atmo
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/purchases/grouped/${_vm.ci.elpc.group}/item/${_vm.ci.contractitem.purchase_id}/view#atmo-supplier-info`,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-shield" }),
                    _c("span", [_vm._v("Итоговый протокол")]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.ci.contractitem.purchase_is_outside_atmo
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/external_purchases/${_vm.ci.contractitem.purchase_id}#supplier-info`,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-shield" }),
                    _c("span", [_vm._v("Поставщик")]),
                  ]
                ),
              ])
            : _vm._e(),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.ci.contractitem.purchase_is_outside_atmo
                    ? `/external_purchases/${_vm.ci.contractitem.purchase_id}#contract`
                    : `/purchases/grouped/${_vm.ci.elpc.group}/item/${_vm.ci.contractitem.purchase_id}/view#atmo-contract`,
                  target: "_blank",
                },
              },
              [
                _c("i", { staticClass: "atmo-icon-contract" }),
                _c("span", [_vm._v("Договор")]),
              ]
            ),
          ]),
          !_vm.ci.contractitem.purchase_is_outside_atmo
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/purchases/grouped/${_vm.ci.elpc.group}/item/${_vm.ci.contractitem.purchase_id}/view#atmo-contract-fulfillment`,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-fulfilled" }),
                    _c("span", [_vm._v("Исполнение договора")]),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }