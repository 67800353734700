<template>
    <div id="atmo-protocol-completion" class="atmo-hash-jump-point atmo-content-group">
        <h3>Подведение итогов закупочной сессии</h3>

        <div class="atmo-buttons">
            <a href="https://app.hinted.me/simulations/6e3eff3f-0f64-4270-ac99-e88aba11356f/view" rel="noopener" target="_blank" class="atmo-button atmo-accented"><i class="atmo-icon-play"></i>&nbsp;Инструкция по подведению итогов</a>
        </div>

        <button v-on:click="pivc().invokeProtocolSummarizationConfirmationPopup()" class="atmo-buttons atmo-button">Сформировать итоговый протокол</button>

        <h5>Список предложений</h5>

        <ProposalDetails.view v-for="(proposal, index) in this.$props.proposals" v-bind:key="index" v-bind:proposal="proposal" v-bind:purchaseitem="purchaseitem" v-bind:index="index" />
    </div>
</template>

<script>
import ProposalDetailsView from './ProposalDetails.view.vue';

export default {
    inject: ['pivc'],
    components: {
        'ProposalDetails.view': ProposalDetailsView
    },
    props: {
        proposals: { required: true, type: Array },
        purchaseitem: { required: true, type: Object }
    }
};
</script>
