'use strict';

enum ConturFocusRatings {
    Red = 'red',
    Yellow = 'yellow',
    Green = 'green'
}

export interface IRawOrganizationData {
    [index: string]: number | boolean | string | undefined | IRawOrganizationData['contacts'];

    id: number;
    is_provider?: boolean | undefined;
    full_name?: string;
    short_name?: string;
    inn?: string;
    kpp?: string;
    ogrn?: string;
    post_address?: string;
    region_code?: string;
    rating?: string;
    focus_red: boolean;
    focus_yellow: boolean;
    focus_green: boolean;
    contacts?: {
        email: string;
        name: string;
        phone: string;
    };
}

export interface IPurchaseOffersListOrganizationData {
    id?: number;
    organization_id?: number;
    full_name: string;
    short_name: string;
    inn: string;
    kpp: string;
    rating: string;
    region_code?: string;
    focus_red: boolean;
    focus_yellow: boolean;
    focus_green: boolean;
    contacts: {
        email: string;
        name: string;
        phone: string;
    };
}

export default class OrganizationVO {
    private static REGION_CODES = ['02', '39'];

    id: number | null;
    is_supplier: boolean;
    title?: string | null;
    title_brief: string | null;
    inn: string | null;
    kpp: string | null;
    ogrn: string | null;
    address?: string | null;
    region_code?: string | null;
    ratings: {
        atmo: string | null;
        contur_focus: string[];
    };
    contacts: {
        email: string | null;
        name: string | null;
        phone: string | null;
    };

    is_customer: boolean;
    missing_deposit_amount: number | null;

    /**
     * The constructor can receive raw organization data from two different sources from API:
     * - the organization as a part of a logged in user data structure (can be either customer or supplier);
     * - the organization as a part of purchase item details (is a customer in this case);
     *
     * So for the time being both cases are processed by the same constructor till the
     * moment the data for each case can be treated in the polymorphic manner by the same constructor.
     *
     * Since the data would diverge I would have to find the way to split constructor behavior
     * into different objects (e.g. create base object with inherited specific objects, make a decorator?).
     */
    constructor(rawOrganizationData?: IRawOrganizationData, isSupplier?: boolean) {
        this.id = rawOrganizationData ? rawOrganizationData.id : null;
        this.is_supplier = rawOrganizationData && rawOrganizationData.is_provider ? true : isSupplier ?? false;
        this.title = rawOrganizationData ? rawOrganizationData.full_name : null;
        this.title_brief = rawOrganizationData ? (rawOrganizationData.short_name as string) : null;
        this.inn = rawOrganizationData ? (rawOrganizationData.inn as string) : null;
        this.kpp = rawOrganizationData ? (rawOrganizationData.kpp as string) : null;
        this.ogrn = rawOrganizationData ? (rawOrganizationData.ogrn as string) : null;
        this.address = rawOrganizationData ? rawOrganizationData.post_address : null;
        this.region_code = rawOrganizationData?.region_code ?? null;
        this.ratings = this.ratingsFactory(rawOrganizationData);
        this.contacts = this.contactsFactory(rawOrganizationData);

        this.is_customer = !this.is_supplier;
        this.missing_deposit_amount = null;
    }

    public setMissingDepositAmount(amount: string | number) {
        this.missing_deposit_amount = typeof amount === 'number' ? amount : parseFloat(amount as unknown as string);
    }

    public isMissingDepositAmountValid(amount: number, minimumAmount: number): boolean {
        return !isNaN(amount) && amount >= minimumAmount;
    }

    public isLocalRegion(): boolean {
        return !!this.region_code && OrganizationVO.REGION_CODES.includes(this.region_code);
    }

    public hasConturFocusRating(): boolean {
        return this.ratings.contur_focus.length > 0;
    }

    private ratingsFactory(data?: IRawOrganizationData): OrganizationVO['ratings'] {
        const conturFocusRatingKeys = {
            focus_red: ConturFocusRatings.Red,
            focus_yellow: ConturFocusRatings.Yellow,
            focus_green: ConturFocusRatings.Green
        };

        const ratings: OrganizationVO['ratings'] = {
            atmo: data?.rating ?? null,
            contur_focus: []
        };

        Object.entries(conturFocusRatingKeys).forEach((entry: [string, string]) => {
            const [key, value] = entry;
            if (data && data[key]) {
                ratings.contur_focus.push(value);
            }
        });

        return ratings;
    }

    private contactsFactory(data?: IRawOrganizationData): OrganizationVO['contacts'] {
        return {
            email: (data?.contacts?.email as string) ?? null,
            name: (data?.contacts?.name as string) ?? null,
            phone: (data?.contacts?.phone as string) ?? null
        };
    }
}
