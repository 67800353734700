import { OrgRoles } from '@/const';

export default [
    {
        path: '/proposals/fl44/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/proposals/fl44-proposal-show.vue'),
        name: 'Fl44ProposalShow',
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_FL44 }
    },
    {
        path: '/proposals/fl223/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/proposals/fl223-proposal-show.vue'),
        name: 'Fl223ProposalShow',
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_FL223 }
    },
    {
        path: '/proposals/commercial/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/proposals/commercial-proposal-show.vue'),
        name: 'CommercialProposalShow',
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_COMMERCIAL }
    }
];
