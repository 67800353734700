'use strict';

import isEqual from 'lodash.isequal';
import BaseCollection from '../../../../application/abstractions/BaseCollection';
import KBKLimitDictionaryItemVO from '../../common/dictionaries/kbk-limits/KBKLimitDictionaryItem.valueobject';
import KBKLimitSpecificationItemEditableVO, { IRawKBKLimit } from './KBKLimitSpecificationItemEditable.valueobject';
import { IPurchaseDraft } from './PurchaseItemEditable.valueobject';
import Config from '@core/js/ddriven/application/config/Config';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';

export interface IErrors {
    is_empty: boolean;
    items_amount_assigned: boolean;
}

export default class KBKLimitsSpecificationItemsEditableCollection extends BaseCollection<KBKLimitSpecificationItemEditableVO> {
    constructor(...items: KBKLimitSpecificationItemEditableVO[]) {
        super(...items);
    }

    public static fromAPIResponse(data: IPurchaseDraft['draft']['limits']): KBKLimitsSpecificationItemsEditableCollection {
        const items = Config.get('theme.features.kbk_limits')
            ? data.map((limit: IRawKBKLimit) => {
                  return KBKLimitSpecificationItemEditableVO.fromAPIResponse(limit);
              })
            : [];

        return new KBKLimitsSpecificationItemsEditableCollection(...items);
    }

    get distributed_amount_total(): number {
        return roundToTwoDecimals(
            this.items.reduce((accumulator: number, item: KBKLimitSpecificationItemEditableVO) => {
                return (accumulator += item.amount_assigned);
            }, 0)
        );
    }

    get errors(): IErrors {
        return {
            is_empty: this.isEmpty(),
            items_amount_assigned: this.items.some((item: KBKLimitSpecificationItemEditableVO) => {
                return item.errors.amount_assigned;
            })
        };
    }

    public hasItem(item: KBKLimitDictionaryItemVO, limitIndex: number): boolean {
        return !!this.items.find((iterated: KBKLimitSpecificationItemEditableVO) => {
            return iterated.id === item.id && iterated.year === item.limits[limitIndex].year;
        });
    }

    public hasErrors(): boolean {
        return Object.values(this.errors).some((value: boolean) => {
            return value === true;
        });
    }

    public replace(id: number, item: KBKLimitSpecificationItemEditableVO): void {
        const index = this.items.findIndex((item: KBKLimitSpecificationItemEditableVO) => {
            return item.id === id;
        });
        index > -1 && this.items.splice(index, 1, item);
    }

    public clear(): void {
        this.items.splice(0, this.items.length);
    }

    public duplicate(): KBKLimitsSpecificationItemsEditableCollection {
        const items = this.items.map((item: KBKLimitSpecificationItemEditableVO) => {
            return new KBKLimitSpecificationItemEditableVO(item);
        });
        return new KBKLimitsSpecificationItemsEditableCollection(...items);
    }

    public isSameAs(limits: KBKLimitsSpecificationItemsEditableCollection): boolean {
        return isEqual(this, limits);
    }
}
