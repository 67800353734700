import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';

export class PersonalFl44PurchaseReportsAPIService extends BaseApiService {
    constructor() {
        super('personal/fl44_reports');
    }

    async getReportsList(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getReport(reportId) {
        try {
            if (!reportId) throw Error('Id is not provided');
            const response = await axios.get(this.getUrl(reportId));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeReport(reportId, data) {
        try {
            if (!reportId) throw Error('Id is not provided');
            await axios.post(this.getUrl(reportId), data);
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(reportId, data) {
        try {
            if (!reportId) throw Error('Id is not provided');
            await axios.post(this.getUrl(`${reportId}/delete`), data);
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async recalculateReport(reportId) {
        try {
            if (!reportId) throw Error('Id is not provided');
            await axios.put(this.getUrl(reportId));
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
