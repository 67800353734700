'use strict';

interface IViewportData {
    width: number;
    height: number;
}

export default class Viewport {
    width: number = 0;
    height: number = 0;

    constructor() {
        this.setViewport();

        if (window) {
            window.addEventListener('resize', () => {
                this.setViewport();
            });
        }
    }

    private setViewport() {
        const viewport = this.viewport();

        this.width = viewport.width;
        this.height = viewport.height;
    }

    private viewport(): IViewportData {
        return {
            width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        };
    }
}
