import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import store from '@/store';

import authRoutes from './auth';
import openRoutes from './open-routes';
import clientsRoutes from './clients';
import cabinetRoutes from './cabinets';
import purchasesRoutes from './purchases';
import quotationRequestsRoutes from './quotation-requests';
import quotationsRoutes from './quotations';
import contractsRoutes from './contracts';
import noticesRoutes from './notices';
import importSubstitutionsRoutes from './import-substitutions';
import proposalsRoutes from './proposals';

import { replaceWithManyByRoutePath } from '@core/js/routing/routes/index.ts';
import RouteDataServiceFacade from '@core/js/routing/RouteDataServiceFacade';

const purchasesRoutesRefactored = replaceWithManyByRoutePath('/purchases', '/purchases', purchasesRoutes);
const contractsRoutesRefactored = replaceWithManyByRoutePath('/contracts', '/contracts', contractsRoutes);
const noticesRoutesRefactored = replaceWithManyByRoutePath('/notices', '/notices', noticesRoutes);

Vue.use(Router);
Vue.use(Meta);

const ROUTES = [
    { path: '', component: () => import(/* webpackChunkName: "main" */ '@/components/Home'), name: 'Home' },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "main" */ '@/components/elements/404'),
        name: '404'
    }
]
    .concat(authRoutes)
    .concat(openRoutes)
    .concat(clientsRoutes)
    .concat(cabinetRoutes)

    .concat(purchasesRoutesRefactored)
    .concat(contractsRoutesRefactored)
    .concat(noticesRoutesRefactored)

    .concat(quotationRequestsRoutes)
    .concat(quotationsRoutes)
    .concat(importSubstitutionsRoutes)
    .concat(proposalsRoutes)
    .concat([{ path: '*', redirect: '/404' }]);

const router = new Router({
    routeDataServiceFacade: null,
    base: '/',
    mode: 'history',
    routes: ROUTES,

    scrollBehavior(to, from, savedPosition) {
        // Assume only hash is changed and scroll is managed within current viewmodel if needed.
        if (to.path === from.path) {
            return;
        }

        let positionObject = { x: 0, y: 0 };

        if (savedPosition) {
            positionObject = savedPosition;
        }
        if (to.hash) {
            positionObject = { selector: to.hash };
        }
        if (from.path === to.path) {
            positionObject = {};
        }

        return positionObject;
    }
});

router.options.routeDataServiceFacade = new RouteDataServiceFacade(store, router);

router.beforeEach(async (to, from, next) => {
    let nextArg = undefined;

    if (store.getters.isLoggedIn && to.name !== 'Logout') {
        if (!store.getters.isProductionsAllowed && to.name.startsWith('ProviderProductions')) {
            nextArg = false;
        } else {
            await store.dispatch('getUserData');
        }
    } else {
        localStorage.removeItem('user');
        localStorage.removeItem('organization');
    }

    if (RouteDataServiceFacade.isAllowedEntityRoute(to.name)) {
        await router.options.routeDataServiceFacade.load(to, from);
    }

    next(nextArg);
});

export default router;
