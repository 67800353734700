'use strict';

import ValidationRules from '@lib/js/src/misc/ValidationRules';
import AnnexDeliverableItemEditableVO, { IRawSupplementaryAgreementItem } from './AnnexDeliverableItemEditable.valueobject';

interface IValidatorInitials {
    price_per_unit: number;
    quantity: number;
}

export interface IValidatorTargets {
    quantity: {
        min: number;
        max: number;
    };
    price_per_unit: {
        max: number;
    };
}

export interface IValidatorInputs {
    initials: IValidatorInitials;
    targets: IValidatorTargets;
}

export interface IValidatorErrors {
    price_per_unit: boolean;
    quantity: boolean;
    kbk_limit: boolean;
}

export default class AnnexDeliverableItemEditableValidator {
    private _initials: IValidatorInitials;
    private _targets: IValidatorTargets;
    private _errors: IValidatorErrors;

    private constructor(inputs: IValidatorInputs) {
        this._initials = {
            price_per_unit: inputs.initials.price_per_unit,
            quantity: inputs.initials.quantity
        };
        this._targets = {
            quantity: {
                min: inputs.targets.quantity.min,
                max: inputs.targets.quantity.max
            },
            price_per_unit: {
                max: inputs.targets.price_per_unit.max
            }
        };
        this._errors = {
            price_per_unit: false,
            quantity: false,
            kbk_limit: false
        };
        Object.seal(this);
    }

    get initials(): IValidatorInitials {
        return this._initials;
    }

    get targets(): IValidatorTargets {
        return this._targets;
    }

    get errors(): IValidatorErrors {
        return this._errors;
    }

    public static fromRawSupplementaryAgeementItem(data: IRawSupplementaryAgreementItem): AnnexDeliverableItemEditableValidator {
        const inputs: IValidatorInputs = {
            initials: {
                quantity: data.amount,
                price_per_unit: data.price
            },
            targets: {
                price_per_unit: {
                    max: data.max_price
                },
                quantity: {
                    min: data.min_amount,
                    max: data.max_amount
                }
            }
        };
        return new AnnexDeliverableItemEditableValidator(inputs);
    }

    /**
     * Only validates "price_per_unit" as quantity is validated in groups by the collection.
     */
    public validate(validated: AnnexDeliverableItemEditableVO): AnnexDeliverableItemEditableValidator {
        this._errors.price_per_unit = !ValidationRules.between(validated.price_per_unit, 0, this.targets.price_per_unit.max);
        return this;
    }

    public setQuantityError(state: boolean): void {
        this._errors.quantity = state;
    }

    public setKBKLimitError(state: boolean): void {
        this._errors.kbk_limit = state;
    }
}
