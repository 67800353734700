<template>
    <div>
        <template v-if="isUserProvider && allowToAddExplanationRequest">
            <b-button variant="custom-green" class="text-uppercase" @click="onAddRequestClick">Добавить запрос</b-button>
            <b-form-text class="desc-text mb-2 mt-0"> Доступна отправка не более двух запросов от организации до подачи предложения </b-form-text>
        </template>
        <b-table :fields="fields" :items="explanationRequests" table-class="table-atmo" show-empty empty-text="Запросы отсутствуют">
            <template #cell(request)="row">
                <div :class="row.item.is_my_request ? 'text-danger' : ''">
                    {{ row.item.request }}
                </div>
                <a v-if="row.item.request_document" :href="$getAttachmentDownloadLink(row.item.request_document.id)" class="atmo-button-link">{{ row.item.request_document.name }}</a>
            </template>
            <!--<template #cell(request_document)="row">
                <a v-if="row.item.request_document" :href="$getAttachmentDownloadLink(row.item.request_document.id)" class="btn btn-custom-green-small text-uppercase" :title="row.item.request_document.name">Скачать</a>
            </template>-->
            <template #cell(answer)="row">
                <div>
                    {{ row.item.answer ?? '—' }}
                </div>
                <a v-if="row.item.answer_document" :href="$getAttachmentDownloadLink(row.item.answer_document.id)" class="atmo-button-link">{{ row.item.answer_document.name }}</a>
            </template>
            <!--<template #cell(answer_document)="row">
                <a v-if="row.item.answer_document" :href="$getAttachmentDownloadLink(row.item.answer_document.id)" class="btn btn-custom-green-small text-uppercase mt-1" :title="row.item.answer_document.name">Скачать</a>
            </template>-->
            <template #cell(actions)="row">
                <b-button v-if="row.item.allow_to_add_answer" variant="custom-green-small" class="text-uppercase bg-secondary" @click="onAnswerClick(row.item.id)">Ответить</b-button>
            </template>
        </b-table>
        <ExplanationRequestAddModal :show-modal="showModal" :is-request="isRequest" @done="onDone" @close="showModal = false" :request-id="requestId" />
    </div>
</template>

<script>
import ExplanationRequestAddModal from '@/components/purchases/ExplanationRequests/explanation-request-add-modal.vue';
import axios from 'axios';

export default {
    name: 'ExplanationRequestList',
    components: { ExplanationRequestAddModal },
    props: {
        purchaseItem: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            fields: [
                { key: 'request', label: 'Запрос', tdClass: 'text-block' },
                //{key: 'request_document', label: "", tdClass: 'btn-width'},
                { key: 'answer', label: 'Ответ', tdClass: 'text-block' },
                //{key: 'answer_document', label: "", tdClass: 'btn-width'},
                { key: 'actions', label: '', tdClass: 'text-right btn-width' }
            ],
            explanationRequests: [],
            allowToAddExplanationRequest: false,
            currentDate: null,
            showModal: false,
            requestId: null,
            isRequest: true
        };
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        onAddRequestClick() {
            this.isRequest = true;
            this.showModal = true;
        },
        onDone(data) {
            this.allowToAddExplanationRequest = data.data.allow_to_add_explanation_request;
            this.explanationRequests = data.data.explanation_requests;
            this.showModal = false;
        },
        async fillData() {
            await axios
                .get('/purchases/' + this.$route.params.id + '/explanation_requests')
                .then((response) => {
                    this.allowToAddExplanationRequest = response.data.data.allow_to_add_explanation_request;
                    this.explanationRequests = response.data.data.explanation_requests;
                })
                .catch((error) => {
                    this.pushToast({
                        text: error.response.data.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        onAnswerClick(requestId) {
            this.requestId = requestId;
            this.isRequest = false;
            this.showModal = true;
        }
    },
    computed: {
        isUserProvider() {
            return this.$store.state.organization && this.$store.state.organization.is_provider;
        }
    }
};
</script>

<style>
.btn-width {
    width: 1% !important;
}
.text-block {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    text-indent: -4px;
    width: 50%;
}
</style>

<style scoped>
.desc-text {
    color: var(--danger) !important;
}
</style>
