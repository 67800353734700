'use strict';

import ATMOAPIResponseNormalizedVO from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/ATMOAPIResponseNormalized.valueobject';

type TSelfPOJO = Omit<ToastDataVO, 'toToastOptions'>;

export enum TToastType {
    Success = 'green',
    Error = 'red'
}

export type TToastOptions = ToastOptionsVO;

export default class ToastDataVO {
    private static DEFAULTS = {
        type: TToastType.Success,
        title: ['Успех', 'Ошибка'],
        message: ['Действие выполнено.', 'Неизвестная ошибка, обратитесь в техническую поддержку'],
        timeout: [5000, 10000]
    };

    type?: TToastType;
    title?: string;
    message?: string;
    timeout?: number;

    constructor(data?: TSelfPOJO) {
        this.type = data?.type ?? TToastType.Success;
        this.title = data?.title ?? ToastDataVO.DEFAULTS.title[0];
        this.message = data?.message ?? ToastDataVO.DEFAULTS.message[0];
        this.timeout = data?.timeout ?? ToastDataVO.DEFAULTS.timeout[0];
    }

    public static unknownError(): ToastDataVO {
        const pojo: TSelfPOJO = {
            type: TToastType.Error,
            title: ToastDataVO.DEFAULTS.title[1],
            message: ToastDataVO.DEFAULTS.message[1],
            timeout: ToastDataVO.DEFAULTS.timeout[1]
        };

        return new ToastDataVO(pojo);
    }

    public static fromNormalizedResponse(response: ATMOAPIResponseNormalizedVO): ToastDataVO {
        const defaultsIndex = response.isSuccess ? 0 : 1;

        const pojo: TSelfPOJO = {
            type: response.isSuccess ? TToastType.Success : TToastType.Error,
            title: ToastDataVO.DEFAULTS.title[defaultsIndex],
            message: response.hasUserMessage() ? this.toFlatMessage(response) : ToastDataVO.DEFAULTS.message[defaultsIndex],
            timeout: ToastDataVO.DEFAULTS.timeout[defaultsIndex]
        };

        return new ToastDataVO(pojo);
    }

    private static toFlatMessage(response: ATMOAPIResponseNormalizedVO): string {
        const messages = [response.message.general, ...response.message.details];
        return messages
            .map((message: string | null) => {
                return message === null ? '' : `${message} `;
            })
            .reduce((accumulator: string, message: string) => {
                return (accumulator += message);
            }, '')
            .toString();
    }

    public toToastOptions(): TToastOptions {
        return new ToastOptionsVO(this);
    }
}

class ToastOptionsVO {
    variant: TToastType;
    title: string;
    text: string;
    timer: number;

    constructor(data: ToastDataVO) {
        this.variant = data.type!;
        this.title = data.title!;
        this.text = data.message!;
        this.timer = data.timeout!;
    }
}
