var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "atmo-form atmo-filters" },
    [
      _c("clearable-input", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ci) {
              return [
                _c("li", { staticClass: "atmo-form__field atmo-inverse" }, [
                  _c("label", { attrs: { for: "kbk-limit-code" } }, [
                    _vm._v("КБК"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$props.controller.request.filter.kbk,
                        expression: "$props.controller.request.filter.kbk",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "kbk-limit-code",
                      placeholder: "Введите КБК",
                      autocomplete: "off",
                    },
                    domProps: {
                      value: _vm.$props.controller.request.filter.kbk,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$props.controller.request.filter,
                          "kbk",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "atmo-clear",
                      attrs: { tabindex: "-1", title: "Очистить поле ввода" },
                      on: { click: ci.clear },
                    },
                    [_c("i", { staticClass: "atmo-icon-close" })]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("clearable-input", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ci) {
              return [
                _c("li", { staticClass: "atmo-form__field atmo-inverse" }, [
                  _c(
                    "label",
                    { attrs: { for: "kbk-limit-funds-sourcing-code" } },
                    [_vm._v("Код типа средств")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$props.controller.request.filter.funds_type,
                        expression:
                          "$props.controller.request.filter.funds_type",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "kbk-limit-funds-sourcing-code",
                      placeholder: "Введите код типа средств",
                      autocomplete: "off",
                    },
                    domProps: {
                      value: _vm.$props.controller.request.filter.funds_type,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$props.controller.request.filter,
                          "funds_type",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "atmo-clear",
                      attrs: { tabindex: "-1", title: "Очистить поле ввода" },
                      on: { click: ci.clear },
                    },
                    [_c("i", { staticClass: "atmo-icon-close" })]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("clearable-input", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ci) {
              return [
                _c("li", { staticClass: "atmo-form__field atmo-inverse" }, [
                  _c("label", { attrs: { for: "kbk-limit-sub-kosgu-code" } }, [
                    _vm._v("Код CубКОСГУ"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.$props.controller.request.filter.subkosgu_code,
                        expression:
                          "$props.controller.request.filter.subkosgu_code",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "kbk-limit-sub-kosgu-code",
                      placeholder: "Введите код СубКОСГУ",
                      autocomplete: "off",
                    },
                    domProps: {
                      value: _vm.$props.controller.request.filter.subkosgu_code,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$props.controller.request.filter,
                          "subkosgu_code",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "atmo-clear",
                      attrs: { tabindex: "-1", title: "Очистить поле ввода" },
                      on: { click: ci.clear },
                    },
                    [_c("i", { staticClass: "atmo-icon-close" })]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }