<template>
    <b-form-group :description="description" :label="label + ':'" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" label-cols-lg="3" label-cols-sm="4">
        <template v-if="withValidation">
            <b-input-group>
                <b-form-input :id="id" v-model="text" :autocomplete="autocomplete" :disabled="disabled || (emailConfirmed && !isEdit)" :state="state" class="fs-14" type="email" @input="$emit('input')" />
                <b-input-group-append>
                    <b-button v-if="!emailConfirmed" :disabled="v !== null && !state" class="text-uppercase fs-14" style="height: 35px" variant="custom-danger" @click="sendEmailConfirmationRequest">Подтвердить</b-button>
                    <b-button v-else class="text-uppercase fs-14" disabled style="height: 35px" variant="custom-green">Подтвержден</b-button>
                </b-input-group-append>
            </b-input-group>
            <template v-if="!state">
                <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
            </template>
            <success-confirm-modal :success-confirm="successConfirm" @hide="hideSuccessConfirm" />
            <b-modal ref="email-confirmation-modal" v-model="showEmailConfirmationModal" centered hide-footer hide-header-close no-close-on-backdrop no-close-on-esc title="Введите код подтверждения, отправленный на указанный адрес электронной почты">
                <div class="d-block mb-4">
                    <the-mask v-model="emailConfirmationCode" class="form-control" mask="######" placeholder="Введите код"></the-mask>
                </div>
                <div class="mb-4">
                    <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelEmailConfirmation">Отмена</b-button>
                    <b-button class="text-uppercase" variant="custom-green" @click="checkEmail"> Подтвердить</b-button>
                </div>
            </b-modal>
        </template>
        <template v-else>
            <b-form-input :id="id" v-model="text" :autocomplete="autocomplete" :disabled="disabled" :state="state" class="fs-14"></b-form-input>
            <template v-if="!state">
                <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
            </template>
        </template>
    </b-form-group>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import SuccessConfirmModal from '@/components/auth/parts/success-confirm-modal';
import { $api } from '@/services/backend/api';

export default {
    name: 'form-row-edit-email',
    components: {
        SuccessConfirmModal,
        TheMask
    },
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        withValidation: {
            type: Boolean,
            default: false
        },
        emailConfirmed: {
            type: Boolean,
            default: false
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: 'off'
        }
    },
    data() {
        return {
            emailConfirmationCode: '',
            successConfirm: false,
            showEmailConfirmationModal: false
        };
    },
    methods: {
        checkEmail() {
            $api.authentication
                .checkEmailConfirmation(this.text, this.emailConfirmationCode)
                .then(() => {
                    this.successConfirm = true;
                    setTimeout(() => {
                        this.successConfirm = false;
                    }, 3000);
                    this.emailConfirmed = true;
                    this.$emit('email-confirmed', this.emailConfirmed);
                    this.cancelEmailConfirmation();
                })
                .catch((err) => {
                    this.pushToast({
                        text: err.response.data.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        sendEmailConfirmationRequest() {
            $api.authentication
                .requestEmailConfirmation(this.text)
                .then(() => {
                    this.emailConfirmationCode = '';
                    this.showEmailConfirmationModal = true;
                })
                .catch((err) => {
                    this.pushToast({
                        text: err.response.data.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        cancelEmailConfirmation() {
            this.emailConfirmationCode = '';
            this.showEmailConfirmationModal = false;
        },
        hideSuccessConfirm() {
            this.successConfirm = false;
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('value', value);
            }
        },
        id() {
            return 'form_row_edit_email_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
                if (!this.v.minLength && !!this.v.$params.minLength) return `Минимальная длина поля - ${this.v.$params.minLength.min} символа/ов`;
                if (!this.v.email && !!this.v.$params.email) return 'Введите действительный email-адрес';
            }
            return '';
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
