'use strict';

import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import { Command, IFileAttachmentVMEvent } from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import ContractItemDetailsVO from '@core/js/ddriven/domain/model/contracts/ContractItemDetails.valueobject';
import SignContractAnnexDisputePopupController, { ISignContractAnnexDisputePopupEventPayload } from './sign/SignContractAnnexDisputePopupController.viewmodel';
import AnnexItemEditableVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItemEditable.valueobject';

interface IData {
    attachments: {
        annex: FileAttachmentVO | null;
        dispute: FileAttachmentVO | null;
    };
    comment: string | null;
    annexitemeditable: AnnexItemEditableVO | null;
}

@Component
export default class ContractAnnexDisputeResolutionController extends BaseViewModel {
    constructor() {
        super();
        this.name = 'ContractAnnexDisputeResolutionController';
    }

    @Prop({ required: true, type: Object }) readonly contract!: ContractItemDetailsVO;
    @Prop({ required: true, type: Object }) readonly user!: UserVO;

    data(): IData {
        return {
            attachments: {
                annex: null,
                dispute: null
            },
            comment: null,
            annexitemeditable: null
        };
    }

    /**
     * Computed
     */
    get isDisputeAttachmentEmpty(): boolean {
        return this.$data.attachments.dispute === null;
    }

    get isAnnexAttachmentEmpty(): boolean {
        return this.$data.attachments.annex === null;
    }

    get isCommentEmpty(): boolean {
        return isPrimitiveEmpty(this.$data.comment);
    }

    get isAnnexEditableEmpty(): boolean {
        return this.$data.annexitemeditable === null;
    }

    /**
     * Methods
     */
    public handleAttachment(event: IFileAttachmentVMEvent): void {
        const value = event.command === Command.Add ? event.attachment : null;
        this.$data.attachments[event.origin] = value;
    }

    public invokeSignContractAnnexDisputePopup(): void {
        /**
         * The payload must match 2 use cases at once: a supplier sends a dispute prototocol
         * and a customer replies to the supplier protocol.
         */
        const payload: ISignContractAnnexDisputePopupEventPayload = {
            id: SignContractAnnexDisputePopupController.popupId,
            ison: true,
            data: {
                user: this.$props.user,
                contract: this.$props.contract,
                annexitemeditable: this.$data.annexitemeditable,
                attachments: {
                    annex: this.$data.attachments.annex,
                    dispute: this.$data.attachments.dispute
                },
                comment: this.$data.comment
            }
        };
        this.$root.$emit('public:onoff:toggle', payload);
    }

    public onAnnexItemEditableChange(annex: AnnexItemEditableVO): void {
        this.$data.annexitemeditable = annex.hasErrors() ? null : annex;
        this.$data.attachments.annex = annex.hasErrors() ? null : annex.attachment;
    }
}
