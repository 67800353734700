<template>
    <div id="atmo-purchase-terms" class="atmo-hash-jump-point atmo-content-group" v-if="this.$props.requirements">
        <h6>Требования к поставщикам</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li class="atmo-wide-text">
                <dt>Участником закупки могут быть только субъекты малого предпринимательства (СМП):</dt>
                <dd>
                    <span v-if="$props.requirements.is_only_smb">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>
            <li class="atmo-wide-text">
                <dt>Заказчик установил требование к участникам по рейтингу в ИС АТМО и может отклонить предложение участника с рейтингом ниже 4,5 балла:</dt>
                <dd>
                    <span v-if="$props.requirements.rating">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>
            <li v-if="$props.requirements.a31s1p1" class="atmo-wide-text">
                <dt>Участник закупки должен соответствовать требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ</dt>
                <dd>
                    <span v-if="$props.requirements.a31s1p1">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>

            <!-- // NB: a31s1p1 view for a non-summarized purchase case. -->
            <li v-if="$props.requirements.a31s1p1 && !$props.ispurchasesummarized">
                <dt>Участник закупки должен соответствовать следующим требованиям:</dt>
                <dd>
                    <span v-text="$props.requirements.a31s1p1_details.description"></span>
                </dd>
            </li>

            <!-- // NB: a31s1p1 view for a summarized purchase case. -->
            <li v-if="$props.requirements.a31s1p1 && $props.ispurchasesummarized" class="atmo-wide-text">
                <dt>Участник закупки подтвердил соответствие указанным требованиям, прикрепив следующие документы при подаче предложения:</dt>
            </li>

            <li v-if="$props.requirements.a31s1p1">
                <dt v-if="!$props.ispurchasesummarized">Участник закупки должен подтвердить соответствие указанным требованиям, прикрепив следующие документы при подаче предложения:</dt>
                <dd>
                    <ul>
                        <li v-for="(document, index) in $props.requirements.a31s1p1_details.supporting_documents" v-bind:key="index">
                            <span v-text="document.description"></span>
                        </li>
                    </ul>
                </dd>
            </li>

            <li v-if="$props.requirements.a31s1ps3_5ps7_11" class="atmo-wide-text">
                <dt>Участник закупки должен соответствовать требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ</dt>
                <dd>
                    <span v-if="$props.requirements.a31s1ps3_5ps7_11">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>
            <li v-if="$props.requirements.a31s1_1" class="atmo-wide-text">
                <dt>Участник закупки должен соответствовать требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ</dt>
                <dd>
                    <span v-if="$props.requirements.a31s1_1">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>

            <li class="atmo-wide-text">
                <dt>Участник закупки должен подтвердить, что является <abbr title="Социально ориентированная некоммерческая организация">СОНКО</abbr>:</dt>
                <dd>
                    <span v-if="$props.requirements.supplier_reqs_sonko">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        requirements: { required: true }
    }
};
</script>
