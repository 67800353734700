'use strict';

import isFinite from 'lodash.isfinite';

/**
 * Return true if value is null or empty string and not a number.
 */
export default function isPrimitiveEmpty(value: number | string | null): boolean {
    return value === null || (value !== null && !isFinite(value) && (value as string).length !== undefined && (value as string).length < 1);
}
