<template>
    <on-off v-slot:default="onoff" v-bind:options="{ onEsc: false, onBody: false }">
        <td class="atmo-ctc-finyears-limits-dropdown">
            <button v-on:click.stop="onoff.toggle" class="atmo-button-icon">
                <span v-if="!onoff.ison" title="Показать список лимитов по годам финансирования"><i class="atmo-icon-show"></i></span>
                <span v-else title="Скрыть список"><i class="atmo-icon-hide"></i></span>
            </button>

            <section v-show="onoff.ison" v-on:click.stop class="atmo-dropdown">
                <!-- // WRITE: Add the list of limit detail properties on top of dropdown. -->

                <!-- // NB: The commented out lines below are kept for easier changes in the actual list. -->
                <ul class="atmo-details">
                    <!-- <li><dt>Лицевой счет:&nbsp;</dt><dd v-text="$props.kbkitem.account"></dd>,</li> -->
                    <!-- <li><dt>Код РКС:&nbsp;</dt><dd v-text="$props.kbkitem.rks_code"></dd>,</li> -->
                    <li v-if="$props.kbkitem.kosgu_code">
                        <dt>Код КОСГУ:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.kosgu_code"></dd>
                    </li>
                    <!-- <li v-if="$props.kbkitem.sub_kosgu_code"><dt>Код СубКОСГУ:&nbsp;</dt><dd v-text="$props.kbkitem.sub_kosgu_code"></dd></li> -->
                    <!-- <li><dt>Наименование бюджета:&nbsp;</dt><dd v-text="$props.kbkitem.budget_name"></dd></li> -->
                    <!-- <li><dt>Код цели:&nbsp;</dt><dd v-text="$props.kbkitem.target_code"></dd></li> -->
                    <li v-if="$props.kbkitem.source_funds">
                        <dt>Бюджет - источник трансферта:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.source_funds"></dd>
                    </li>
                    <li v-if="$props.kbkitem.subsidy_code">
                        <dt>Код субсидии:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.subsidy_code"></dd>
                    </li>
                    <li v-if="$props.kbkitem.invest_object">
                        <dt>Объект:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.invest_object"></dd>
                    </li>
                    <li v-if="$props.kbkitem.direction_code">
                        <dt>Код направления:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.direction_code"></dd>
                    </li>
                    <!-- <li><dt>Код расходного обязательства:&nbsp;</dt><dd v-text="$props.kbkitem.pay_promise_code"></dd></li> -->
                    <!-- <li><dt>Код целевых средств:&nbsp;</dt><dd v-text="$props.kbkitem.targeted_funds_code"></dd></li> -->
                    <!-- <li><dt>Код бюджетного ассигнования:&nbsp;</dt><dd v-text="$props.kbkitem.budget_allocation_code"></dd></li> -->
                    <li v-if="$props.kbkitem.regional_project_result">
                        <dt>Результат проекта:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.regional_project_result"></dd>
                    </li>
                    <li v-if="$props.kbkitem.additional_information_code">
                        <dt>Код доп. информации:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.additional_information_code"></dd>
                    </li>
                    <li v-if="$props.kbkitem.funds_type_code">
                        <dt>Код типа средств:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.funds_type_code"></dd>
                    </li>
                    <li v-if="$props.kbkitem.event_code">
                        <dt>Код мероприятия:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.event_code"></dd>
                    </li>
                    <li v-if="$props.kbkitem.eis_ident_no">
                        <dt>ИКЗ:&nbsp;</dt>
                        <dd v-text="$props.kbkitem.eis_ident_no"></dd>
                    </li>
                </ul>

                <table>
                    <thead>
                        <tr>
                            <th class="atmo-right">Год финансирования</th>
                            <th class="atmo-right">Доступный лимит, руб.</th>
                            <th class="atmo-right">Общий лимит, руб.</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(limit, index) in $props.kbkitem.limits">
                            <tr v-if="limit.amount_total !== 0" v-bind:key="index">
                                <td v-text="limit.year" class="atmo-right"></td>
                                <td class="atmo-right">{{ limit.amount_available | formatnumber }}</td>
                                <td class="atmo-right">{{ limit.amount_total | formatnumber }}</td>
                                <td>
                                    <button v-on:click="kbkspc().selected($props.index, index)" class="atmo-button-icon" title="Выбрать лимит"><i class="atmo-icon-add-to-list"></i></button>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="isLimitsListEmpty">
                            <td colspan="4">Нет доступных лимитов.</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </td>
    </on-off>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';

export default {
    inject: ['kbkspc'],
    props: {
        index: { required: true, type: Number },
        kbkitem: { required: true, type: Object }
    },
    components: {
        'on-off': new OnOff()
    },
    computed: {
        isLimitsListEmpty: function () {
            return (
                !!this.$props.kbkitem.limits.filter((limit) => {
                    return limit.amount_available !== 0;
                })?.length < 1
            );
        }
    }
};
</script>
