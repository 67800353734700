export default [
    {
        path: '/contracts/fl44',
        redirect: { name: 'ContractsList', params: { contract: 'all' } },
        component: () => import(/* webpackChunkName: "main" */ '@/components/global/fl44-contracts/contracts-list'),
        children: [
            {
                path: ':contract(all|concluded|finished|failed)',
                name: 'ContractsList',
                component: () => import(/* webpackChunkName: "main" */ '@/components/global/fl44-contracts/contracts-list')
            }
        ]
    }
];
