var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$store.getters.isLoggedIn
    ? _c(
        "b-modal",
        {
          ref: "auth-modal",
          attrs: { centered: "", "hide-footer": "", title: "Регистрация" },
          on: { close: _vm.close },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-center w-100 m-auto" }, [
            _c("h4", [_vm._v("Уважаемый пользователь!")]),
          ]),
          _c("div", { staticClass: "d-block text-justify w-100 my-3" }, [
            _c("p", { staticClass: "grey-color" }, [
              _vm._v(
                " Прохождение процедуры регистрации / аккредитации означает согласие с "
              ),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "var(--green-color) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: { href: _vm.links.user_agreement, target: "_blank" },
                },
                [_vm._v("Пользовательским соглашением")]
              ),
              _vm._v(", "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "var(--green-color) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: { href: _vm.links.privacy_policy, target: "_blank" },
                },
                [_vm._v("Политикой конфиденциальности")]
              ),
              _vm._v(", "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "var(--green-color) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: { href: _vm.links.public_offer, target: "_blank" },
                },
                [_vm._v("Публичной офертой")]
              ),
              _vm._v(" и ознакомление с "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "var(--green-color) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: { href: _vm.links.user_guide, target: "_blank" },
                },
                [_vm._v("Руководством пользователя")]
              ),
              _vm._v(". "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "d-block text-center w-100 my-3" },
            [
              _c(
                "b-form-group",
                { attrs: { state: _vm.isCheckRules } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { state: _vm.isCheckRules },
                      model: {
                        value: _vm.readedDocumetation,
                        callback: function ($$v) {
                          _vm.readedDocumetation = $$v
                        },
                        expression: "readedDocumetation",
                      },
                    },
                    [
                      _c("span", { staticClass: "grey-color fw-600" }, [
                        _vm._v("Подтверждаю ознакомление с материалами"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-block text-center w-75 m-auto" },
            [
              _vm.theme.features.customer_registration
                ? _c(
                    "b-link",
                    {
                      staticClass: "btn btn-block btn-custom-green mb-4",
                      attrs: {
                        disabled: !_vm.readedDocumetation,
                        to: "/auth/register/customer",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showModal = false
                        },
                      },
                    },
                    [_vm._v(" Регистрация в качестве Заказчика ")]
                  )
                : _vm._e(),
              _c(
                "b-link",
                {
                  staticClass: "btn btn-block btn-custom-green",
                  attrs: {
                    disabled: !_vm.readedDocumetation,
                    to: "/auth/register/provider",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showModal = false
                    },
                  },
                },
                [_vm._v(" АККРЕДИТАЦИЯ ПОСТАВЩИКА ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "my-3" }, [
            _c("p", { staticClass: "m-0 fs-12 grey-color text-center" }, [
              _vm._v('Уже зарегистрированы? Используйте кнопку "Вход в ЛК"'),
            ]),
          ]),
          !_vm.theme.features.customer_registration
            ? _c(
                "div",
                {
                  staticClass:
                    "d-block text-justify w-100 my-4 fs-14 grey-color",
                },
                [
                  _c("p", { staticClass: "text-center" }, [
                    _c("strong", { staticClass: "text-danger" }, [
                      _vm._v("ВНИМАНИЮ ЗАКАЗЧИКОВ!"),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(
                      "Если Вам необходимо зарегистрироваться в качестве Заказчика - напишите в техническую поддержку запрос на регистрацию в качестве заказчика и следуйте дальнейшим указаниям."
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "mt-3" }, [
            _c("p", { staticClass: "m-0 fs-12 grey-color text-center" }, [
              _c(
                "a",
                {
                  staticClass: "grey-color",
                  staticStyle: {
                    color: "var(--green-color) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: {
                    href: _vm.theme.variables.global.links.manuals
                      .workbench_setup,
                    target: "_blank",
                  },
                },
                [_vm._v("Инструкция по настройке")]
              ),
              _vm._v(
                " сервисов КриптоПро, для использования электронной подписи "
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }