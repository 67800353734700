var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "atmo-tab-content" },
    [
      _c("CustomerInfo.view", {
        attrs: { customer: this.$props.controller.custom.customer },
      }),
      _c("SupplierInfo.view", {
        attrs: { supplier: this.$props.controller.custom.supplier },
      }),
      _c("AnnexInfo.view", {
        attrs: {
          annex: this.$props.controller.custom.annex,
          handlers: {
            downloadFileAttachment:
              this.$props.controller.custom.downloadFileAttachment,
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }