import { CabinetUsersAPIService } from '@/services/backend/cabinet/users';
import { fl223 } from '@/services/backend/cabinet/fl223';
import { CabinetQuotationRequestsAPIService } from '@/services/backend/cabinet/quotation-requests';
import { provider } from '@/services/backend/cabinet/provider';
import { customer } from '@/services/backend/cabinet/customer';
import { ktru } from '@/services/backend/cabinet/ktru';

export const cabinet = {
    users: new CabinetUsersAPIService(),
    fl223: fl223,
    provider: provider,
    customer: customer,
    quotationRequests: new CabinetQuotationRequestsAPIService(),
    ktru: ktru
};
