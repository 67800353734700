<template>
    <div class="atmo-component-wrapper">
        <h5>Разногласия по дополнительному соглашению</h5>

        <DisputesTable.view v-bind:pivc="$props.pivc" v-bind:disputes="$props.disputes" />
    </div>
</template>

<script>
import DisputesTableView from '../../../common/DisputesTable.view.vue';

export default {
    props: {
        pivc: { required: true, type: Object },
        disputes: { required: true, type: Array }
    },
    components: {
        'DisputesTable.view': DisputesTableView
    }
};
</script>
