'use strict';

export interface AnnexStrategyPayload {
    contract_id: number;
    annex_id: number;
    purchase_registration_number: string;
    annex_number: number;
}

import DownloadConfig, { ConfigFactorySettings } from '../DownloadConfig.valueobject';

export default class Annex extends DownloadConfig {
    protected static _type = 'annex';

    constructor(settings: ConfigFactorySettings) {
        super(settings.type);
        this.verb = 'GET';
        this.endpoint = `/contracts/${settings.payload.contract_id}/supplementary_agreements/${settings.payload.annex_id}/pdf`;
        this.name = `Дополнительное соглашение №${settings.payload.annex_number} к договору ${settings.payload.purchase_registration_number}.pdf`;
    }
}
