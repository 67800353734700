var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$props.buttondata.isActive
    ? _c(
        "router-link",
        {
          staticClass: "atmo-button--small atmo-accented",
          attrs: {
            to: {
              name: "purchases.grouped.item.view",
              params: {
                group: this.$props.group,
                id: this.$props.buttondata.purchaseId,
              },
              hash: this.$props.buttondata.hash,
            },
            target: "_blank",
          },
        },
        [_vm._v(_vm._s(this.$props.buttondata.buttonText))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }