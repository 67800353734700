'use strict';

import { timeUntil } from '@tobynatooor/countdown';
import FormatDate from '@lib/js/src/misc/FormatDate';

export interface ICountdownResult {
    done: boolean;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export interface ICountdownResultFormatted {
    done: boolean;
    days: string | number;
    dayWord: string;
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
}

export default class DateCountdown {
    /**
     * REFACTOR: The constructor parameters list is too long to be convenient
     * and there is way too much happens in the constructor.
     *
     * Better to extract the creation of the actual countdown to the factory method
     * and keep only some initialization part in the constructor.
     */
    constructor(dateISO: string, fromTimezone: string, callback: (countdownObject: ICountdownResult, formattedData: ICountdownResultFormatted) => void, intervalMilliseconds: number = 1000) {
        /**
         * As the dateISO is in some timezone time and the
         * countdown base time is now in the current local time
         * the timezones of dateISO and local can be different.
         *
         * The timeUntil operates on the current timezone. So I need to
         * convert dateISO to UTC. Then convert the result to current timezone.
         * It should be supplied to countdown timeUntil utility.
         */
        const dateISOUTC = FormatDate.dateFromTimezonedToISOUTC(dateISO);

        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const dateISOCurrentTimezone = FormatDate.dateFromISOUTCToTimezoned(dateISOUTC, currentTimezone);

        const ivl = setInterval(() => {
            const countdown: ICountdownResult = timeUntil(dateISOCurrentTimezone);
            countdown.done && clearInterval(ivl);

            const formatted: ICountdownResultFormatted = {
                done: countdown.done,
                days: countdown.days % 365,
                dayWord: this.dayWord(countdown.days % 365),
                hours: this.padWithZero(countdown.hours % 24),
                minutes: this.padWithZero(countdown.minutes % 60),
                seconds: this.padWithZero(countdown.seconds % 60)
            };

            callback(countdown, formatted);
        }, intervalMilliseconds);
    }

    padWithZero(value: number): string | number {
        return value < 10 ? `0${value}` : value;
    }

    dayWord(days: number): string {
        const dictionary: { [index: string]: string } = { 1: 'день', 234: 'дня', 567890: 'дней' };
        const remaining = days % 10;
        const found = Object.keys(dictionary).find((key) => {
            return `${key}`.indexOf(`${remaining}`) > -1;
        });

        return found ? dictionary[found] : 'дней';
    }
}
