'use strict';

import Vue from 'vue';
import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import { IDropdownSelectedEventPayload } from '@lib/js/src/vue/components/DropdownSelect.viewmodel';
import OnOffComposable from '@lib/js/src/vue/composables/OnOff.composable';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

interface IData {
    selectedstatusid: number | null;
    justchanged: boolean;
}

@Component
export default class PurchaseStatusChangeWidgetController extends BaseViewModel {
    onoff: OnOffComposable;

    constructor() {
        super();
        this.name = 'PurchaseStatusChangeWidgetController';
        this.onoff = Vue.observable(new OnOffComposable());
    }

    @Prop({ required: true, type: Number }) readonly contractid!: string;
    @Prop({ required: true, type: Number }) readonly initialid!: string;

    data(): IData {
        return {
            selectedstatusid: null,
            justchanged: false
        };
    }

    /**
     * Computed
     */
    get isloading(): boolean {
        return this.onoff.ison;
    }

    get canUpdateStatus(): boolean {
        return this.$data.justchanged && this.$data.selectedstatusid !== this.$props.initialid;
    }
    /**
     * Watch
     */
    @Watch('selectedstatusid')
    onSelectedstatusidChanged(statusid: number, oldstatusid: number | null): void {
        if (oldstatusid === null) {
            return;
        }
        this.$data.justchanged = true;
    }

    /**
     * Methods
     */
    public manageSelection(payload: IDropdownSelectedEventPayload): void {
        this.$data.selectedstatusid = payload.id;
    }

    public async updateStatus() {
        this.onoff.on();

        const response = await ApplicationServiceLocator.get('api').entities.updatePurchaseStatus(this.$props.contractid, this.$data.selectedstatusid);
        response.isSuccess && (this.$data.justchanged = false);
        response.isSuccess &&
            setTimeout(() => {
                window.location.reload();
            }, 500);
    }
}
