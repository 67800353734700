<template>
    <div class="atmo-component-wrapper">
        <ul v-bind:class="pi.getStatusClass('atmo-entity-status-')" class="atmo-entity-item-title atmo-icon-custom">
            <li style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis" class="pr-4 align-content-center">
                <b-badge class="my-auto fs-14 mr-2" variant="primary">{{ pi.noticeitem.product.title }}</b-badge>
                <b-badge class="my-auto fs-14 mr-2" variant="custom-orange" v-if="pi.noticeitem.only_smp">Только СМП</b-badge>
                <a v-bind:href="`/purchases/commercial/${pi.noticeitem.id}/show`" target="_blank">№&nbsp;<span v-text="pi.noticeitem.registration_number"></span></a>
                <template v-if="pi.noticeitem.purchase_object">&nbsp;На закупку "{{ pi.noticeitem.purchase_object }}"</template>
            </li>
            <li class="text-nowrap"><span v-text="pi.status.title" class="text-uppercase"></span></li>
        </ul>

        <ul class="atmo-entity-item-summary">
            <li>
                <dt>Сумма закупки:&nbsp;</dt>
                <dd>
                    <span>{{ pi.noticeitem.starting_price | formatnumber }}</span
                    >&nbsp;руб.
                </dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Предложений:&nbsp;</dt>
                <dd>
                    <span v-if="!pi.noticeitem.are_proposals_hidden" v-text="pi.noticeitem.proposals_count"></span>
                    <span v-else>Скрыто</span>
                </dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Лучшее:&nbsp;</dt>
                <dd v-if="!pi.noticeitem.are_proposals_hidden">
                    <span v-if="pi.noticeitem.best_proposal_price > 0">{{ pi.noticeitem.best_proposal_price | formatnumber }}&nbsp;руб.</span>
                    <span v-else>-</span>
                </dd>
                <dd v-else>Скрыто</dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Снижение:&nbsp;</dt>
                <dd>
                    <span v-if="pi.noticeitem.are_proposals_hidden">Скрыто</span>
                    <span v-else>{{ pi.noticeitem.discount_percentage }}%</span>
                </dd>
            </li>
        </ul>

        <ul class="atmo-entity-item-details">
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li v-if="pi.status.code === 'accepting'">
                        <section>
                            <span v-if="pi.countdown"
                                ><dt>До окончания приема предложений:</dt>
                                <dd v-text="pi.countdown"></dd
                            ></span>
                            <dt v-if="pi.countdown === false">Прием предложений завершен</dt>
                        </section>
                        <commercial-new-proposal-button v-if="pi.countdown && pi.user && pi.user.is_logged_in" :noticeitem="pi.noticeitem" />
                    </li>
                    <li v-if="pi.status.code === 'summarizing'">Прием предложений завершен</li>
                    <li v-if="pi.status.code === 'summarized'">
                        <dt>Подведены итоги:</dt>
                        <dd>{{ pi.noticeitem.protocol_date | formatdate('removeSeconds') }}</dd>
                    </li>
                    <li v-if="pi.status.code === 'failed'">
                        <dt>Подведены итоги:</dt>
                        <dd>{{ pi.noticeitem.proposal_accept_end_date | formatdate('removeSeconds') }}</dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li>
                        <dt>Предмет закупки:</dt>
                        <dd v-text="pi.noticeitem.deliverable_group_title"></dd>
                    </li>
                    <li>
                        <dt>Организация:</dt>
                        <dd><a v-bind:href="'/organizations/' + pi.noticeitem.customer.id" v-text="pi.noticeitem.customer.name" target="_blank"></a></dd>
                    </li>
                    <li v-if="pi.noticeitem.hasSupplier">
                        <dt>Поставщик:</dt>
                        <dd><a v-bind:href="'/organizations/' + pi.noticeitem.supplier.id" v-text="pi.noticeitem.supplier.name" target="_blank"></a></dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li>
                        <dt>Планируемая дата исполнения:</dt>
                        <dd>{{ pi.noticeitem.planned_date_contract_fulfilled | formatdate('onlyDate') }}</dd>
                    </li>
                    <li>Количество позиций: <b v-text="pi.noticeitem.deliverables_count"></b></li>
                    <li v-if="pi.noticeitem.delivery_addresses && pi.noticeitem.delivery_addresses.length > 0">
                        <dt>Адреса для доставки товаров/выполнения работ/оказания услуг:</dt>
                        <dd v-for="(address, index) in pi.noticeitem.delivery_addresses" v-bind:key="index">&mdash;&nbsp;{{ address }}</dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li>
                        <a v-bind:href="`/purchases/commercial/${pi.noticeitem.id}/show`" target="_blank"><i class="atmo-icon-search"></i><span>Информация о закупке</span></a>
                    </li>
                    <li v-if="pi.noticeitem.hasProtocol">
                        <a v-bind:href="`/purchases/commercial/${pi.noticeitem.id}/show#atmo-supplier-info`" target="_blank"><i class="atmo-icon-shield"></i><span>Итоговый протокол</span></a>
                    </li>
                    <li v-if="pi.noticeitem.hasContract">
                        <a v-bind:href="`/purchases/commercial/${pi.noticeitem.id}/show#atmo-contract`" target="_blank"><i class="atmo-icon-contract"></i><span>Договор</span></a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
// import DealStageActionButton from './DealStageActionButton.view.vue';

import CommercialNewProposalButton from '@/components/global/notices/partials/commercial-new-proposal-button.vue';

export default {
    props: {
        pi: { required: true, type: Object }
    },
    components: {
        CommercialNewProposalButton
        // DealStageActionButton
    }
};
</script>
