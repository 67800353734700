var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("save-purchase-popup-controller", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (sppc) {
          return [
            _c("on-off", {
              attrs: {
                options: {
                  id: "save-purchase-popup",
                  onEsc: false,
                  onBody: false,
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (onoff) {
                      return [
                        onoff.ison
                          ? _c("div", { staticClass: "atmo-popup-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "atmo-popup-content atmo-save-purchase-popup",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "atmo-button--small atmo-close",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return onoff.off.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "atmo-icon-close",
                                      }),
                                    ]
                                  ),
                                  _c("h5", [
                                    _vm._v("Подписать и сохранить извещение"),
                                  ]),
                                  _c(
                                    "ul",
                                    { staticClass: "atmo-tabs-menu-universal" },
                                    [
                                      _c("li", [
                                        _c(
                                          "button",
                                          {
                                            class: {
                                              "atmo-active":
                                                sppc.isSelectedTab(1),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return sppc.selectTab(1)
                                              },
                                            },
                                          },
                                          [_vm._v("Данные формы")]
                                        ),
                                      ]),
                                      _c("li", [
                                        _c(
                                          "button",
                                          {
                                            class: {
                                              "atmo-active":
                                                sppc.isSelectedTab(2),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return sppc.selectTab(2)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "XML-представление данных формы"
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "main",
                                    { staticClass: "atmo-tabs-container" },
                                    [
                                      sppc.isSelectedTab(1)
                                        ? _c("TabFormDataPreviewView.view", {
                                            attrs: {
                                              download:
                                                sppc.downloadFileAttachment,
                                              user: sppc.user,
                                              inputs: sppc.inputs,
                                              decoded: sppc.decoded,
                                              certificate:
                                                sppc.$data.certificate,
                                            },
                                          })
                                        : _vm._e(),
                                      sppc.isSelectedTab(2)
                                        ? _c("TabXMLDataPreviewView.view", {
                                            attrs: {
                                              xml: sppc.xml,
                                              certificate:
                                                sppc.$data.certificate,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "footer",
                                    [
                                      sppc.$data.certificate
                                        ? _c("CertificateInfo.view", {
                                            attrs: {
                                              certificate:
                                                sppc.$data.certificate,
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "ul",
                                        { staticClass: "atmo-buttons" },
                                        [
                                          _c("li", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "atmo-button-inverse atmo-dimmed",
                                                on: { click: onoff.off },
                                              },
                                              [_vm._v("Отмена")]
                                            ),
                                          ]),
                                          !sppc.isDataSigned &&
                                          sppc.$data.certificate
                                            ? _c("li", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "atmo-button",
                                                    on: {
                                                      click: sppc.signData,
                                                    },
                                                  },
                                                  [_vm._v("Подписать")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "atmo-spinner",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          sppc.isDataSigned
                                            ? _c(
                                                "li",
                                                {
                                                  on: {
                                                    click: sppc.savePurchase,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "atmo-button",
                                                    },
                                                    [_vm._v("Сохранить")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "atmo-spinner",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
                                                          alt: "",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }