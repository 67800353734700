'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';

import { AxiosResponse } from 'axios';
import EditableFileAttachmentsListController, { Command, IFileAttachmentVMEvent } from './EditableFileAttachmentsListController.viewmodel';
import FileAttachmentVO, { IRawFileAttachmentData } from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import ApplicationServiceLocator from '../../../ddriven/application/services/ApplicationServiceLocator';

interface IResponsePayloadItem {
    id: number;
    file: IRawFileAttachmentData;
}

@Component
export default class EditableContractFulfillmentFileAttachmentsListController extends EditableFileAttachmentsListController {
    constructor() {
        super();
        this.name = 'EditableContractFulfillmentFileAttachmentsListController';
    }

    @Prop({ required: true, type: Number }) readonly contractid?: boolean;

    /**
     * Prototype general methods.
     */

    /**
     * Has to replace the parent's main remove in order to integrate the remove
     * action confirmation and a remove call to API absent in parent's remove.
     * NB: The remove method is the public interface, accessible from template.
     */
    public async remove(attachment: FileAttachmentVO) {
        const isConfirmed = await new Promise((resolve) => {
            this.$root.$emit('public:onoff:toggle', {
                id: 'contract-fulfillment-attachment-remove-popup',
                ison: true,
                data: { content: { name: attachment.name }, respond: resolve }
            } as IOnOffOnToggleEventPayload);
        });
        if (!isConfirmed) {
            return;
        }

        await ApplicationServiceLocator.get('api').entities.removeContractFulfillmentAttachment(this.$props.contractid, attachment.attachment_record_id!);
        const removed = this.removeFromList(this.$data.list, attachment);
        this.$emit('fileattachment:changed', { origin: this.$props.origin, command: Command.Remove, attachment: removed } as IFileAttachmentVMEvent);
    }

    /**
     * Has to replace the parent's upload method due to different endpoint called,
     * requiring the additional arguments (contract ID) and the need to normalize
     * the result returned.
     * NB: The upload method is a private interface used only by viewmodel, not engaged in template.
     */
    protected async upload(file: File): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('file', file);
        const response = await ApplicationServiceLocator.get('api').entities.uploadAndAttachContractFulfillmentAttachment(this.$props.contractid, formData);
        const item = response.data.data.sort((first: IResponsePayloadItem, second: IResponsePayloadItem) => {
            return second.id - first.id;
        })[0];

        const rawData: IRawFileAttachmentData = {
            attachment_record_id: item.id,
            ...item.file
        };

        response.data = rawData;

        return response;
    }
}
