'use strict';

import DownloadConfig, { AllowedConfigTypes } from '../DownloadConfig.valueobject';
import SignOrganizationReliabilityReportDownloadRequestVO from '@core/js/ddriven/application/http/requests/misc/SignOrganizationReliabilityReportDownloadRequest.valueobject';

export default class ReliabilityReport extends DownloadConfig {
    private static _type = AllowedConfigTypes.reliabiltyreport;

    constructor(request: SignOrganizationReliabilityReportDownloadRequestVO) {
        super(ReliabilityReport._type);
        this.verb = 'POST';
        this.endpoint = '/cabinet/provider/organization_reports';
        this.body = request.toPlainObject();
        this.name = `Проверка контрагента ${request.inn}.pdf`;
    }
}
