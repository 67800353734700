export default [
    {
        path: '/auth/register/customer',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/RegisterCustomer'),
        name: 'RegisterCustomer'
    },
    {
        path: '/auth/reset_password/:token',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/reset-password'),
        name: 'ResetPassword'
    },
    {
        path: '/auth/register/provider',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/RegisterProvider'),
        name: 'RegisterProvider'
    }
];
