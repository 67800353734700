'use strict';

import State from './state';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import ExtendedDictionaryCollection from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionary.collection';

export interface IRawDictionaryItem {
    id: number;
    text: string;
    value: number | string;
    is_active: boolean;
}

export interface IRawStandardDictionaryData {
    id: number;
    name: string;
    is_active: boolean;
}

interface UserAddressBookData {
    id: number;
    short_address: string;
    full_address: string;
    is_active: boolean;
}

interface Payload {
    deliverablesGroupsData: IRawStandardDictionaryData[];
    purchaseTypesData: IRawDictionaryItem[];
}

const mutations = {
    updatePurchaseEditableDictionaries: function (state: State, payload: Payload): void {
        state.deliverablesgroups = payload.deliverablesGroupsData.map((item: IRawStandardDictionaryData) => {
            return Object.freeze(new StandardDictionaryItem({ id: item.id, title: item.name, is_active: item.is_active }));
        });

        // REFACTOR: ExtendedDictionaryCollection into a collection class using private "items" property.
        state.types = extendedDictionaryCollectionFactory(payload.purchaseTypesData);
    },

    setPurchaseTypes: function (state: State, data: IRawDictionaryItem[]) {
        state.types = extendedDictionaryCollectionFactory(data);
    },

    setDeliverablesGroups: function (state: State, data: IRawStandardDictionaryData[]) {
        state.deliverablesgroups = data.map((item: IRawStandardDictionaryData) => {
            return Object.freeze(new StandardDictionaryItem({ id: item.id, title: item.name, is_active: item.is_active }));
        });
    },

    setMunicipalities: function (state: State, data: IRawStandardDictionaryData[]) {
        state.municipalities = data.map((item: IRawStandardDictionaryData) => {
            return Object.freeze(new StandardDictionaryItem({ id: item.id, title: item.name, is_active: item.is_active }));
        });
    }
};

function extendedDictionaryCollectionFactory(data: IRawDictionaryItem[]): ExtendedDictionaryCollection<unknown> {
    return new ExtendedDictionaryCollection(
        ...data.map((type: IRawDictionaryItem) => {
            return Object.freeze(new ExtendedDictionaryItem({ id: type.id, title: type.text, value: type.value, is_active: type.is_active }));
        })
    );
}

export default mutations;
