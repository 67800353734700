var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c("filter-text", {
        attrs: { md: 0, label: "Рег.номер", placeholder: "Введите рег.номер" },
        model: {
          value: _vm.$props.controller.request.filter.reg_number,
          callback: function ($$v) {
            _vm.$set(_vm.$props.controller.request.filter, "reg_number", $$v)
          },
          expression: "$props.controller.request.filter.reg_number",
        },
      }),
      _c("filter-text", {
        attrs: {
          md: 0,
          label: "Наименование товара (работ, услуг)",
          placeholder: "Введите наименование или его часть",
        },
        model: {
          value: _vm.$props.controller.request.filter.name,
          callback: function ($$v) {
            _vm.$set(_vm.$props.controller.request.filter, "name", $$v)
          },
          expression: "$props.controller.request.filter.name",
        },
      }),
      _c("filter-text", {
        attrs: {
          md: 0,
          label: "Характеристики",
          placeholder: "Введите текст характеристик",
        },
        model: {
          value: _vm.$props.controller.request.filter.specs,
          callback: function ($$v) {
            _vm.$set(_vm.$props.controller.request.filter, "specs", $$v)
          },
          expression: "$props.controller.request.filter.specs",
        },
      }),
      _c("filter-text", {
        attrs: {
          md: 0,
          label: "Код ОКПД2",
          placeholder: "Введите код ОКПД2 или его часть",
        },
        model: {
          value: _vm.$props.controller.request.filter.okpd_code,
          callback: function ($$v) {
            _vm.$set(_vm.$props.controller.request.filter, "okpd_code", $$v)
          },
          expression: "$props.controller.request.filter.okpd_code",
        },
      }),
      _c(
        "b-col",
        { staticClass: "favorite-btn text-right", attrs: { md: "auto" } },
        [
          _vm.$props.controller.request.filter.show_favorite
            ? _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      _vm.$props.controller.request.filter.show_favorite =
                        !_vm.$props.controller.request.filter.show_favorite
                    },
                  },
                },
                [
                  _vm._v("Избранное "),
                  _c("b-icon", {
                    staticClass: "ml-2",
                    attrs: { icon: "star-fill" },
                  }),
                ],
                1
              )
            : _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { variant: "custom-green" },
                  on: {
                    click: function ($event) {
                      _vm.$props.controller.request.filter.show_favorite =
                        !_vm.$props.controller.request.filter.show_favorite
                    },
                  },
                },
                [
                  _vm._v("Избранное "),
                  _c("b-icon", {
                    staticClass: "ml-2",
                    attrs: { icon: "star" },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }