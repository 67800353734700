'use strict';

import { Route } from 'vue-router';

import RouteDataServiceFacade from '../RouteDataServiceFacade';
import ContractRequest from '@core/js/ddriven/application/http/requests/contracts/ContractRequest.valueobject';

export default class ContractsRouteDataService {
    private theFacade: RouteDataServiceFacade;

    constructor(theFacade: RouteDataServiceFacade) {
        this.theFacade = theFacade;
    }

    public async loadContractsGrouped(to: Route) {
        const request = ContractRequest.fromQueryObject(to.query, ContractRequest) as ContractRequest;
        await this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/contracts/dictionaries/loadDeliverablesGroups`);
        await this.theFacade.store.dispatch(`rearchitected/groups/${to.params.group}/contracts/dictionaries/loadMunicipalities`);
        this.theFacade.loadEntitiesGrouped('contracts', request, to.query, to);
    }
}
