'use strict';

import DownloadFileByBrowser from 'js-file-download';

import ATMOAPIFacade from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/ATMOAPIFacade';
import DownloadConfig from './DownloadConfig.valueobject';
import { AxiosResponse } from 'axios';
import ATMOAPIResponseNormalizedVO from '../../../ports/adapters/http/outgoing/atmo/ATMOAPIResponseNormalized.valueobject';

export default class DownloadService {
    api: ATMOAPIFacade;

    constructor(api: ATMOAPIFacade) {
        this.api = api;
    }

    public async fileByBrowser(config: DownloadConfig) {
        const response = await (this.api.common.downloadFileBlob(config) as Promise<AxiosResponse>);

        if (response.status !== 200) {
            throw new Error(`ATMO Exception: Server error: "${response.statusText}"`);
        }

        //@ts-ignore
        config.hasSeparateNameSetter() && config.setName(response.headers['content-disposition']);

        this.actuallyDownloadByBrowser(response.data, config.name as string);
    }

    public async fileByBrowserV1(config: DownloadConfig) {
        const response = await (this.api.common.downloadFileBlob(config, true) as Promise<ATMOAPIResponseNormalizedVO>);

        if (response.status.code !== 200) {
            throw new Error(`ATMO Exception: Server error: "${response.status.message}"`);
        }

        //@ts-ignore
        config.hasSeparateNameSetter() && config.setName(response.headers['content-disposition']);

        this.actuallyDownloadByBrowser(response.original.response?.data, config.name as string);
    }

    public actuallyDownloadByBrowser(data: Blob, filename: string) {
        DownloadFileByBrowser(data, filename);
    }
}
