<template>
    <b-form-group :description="description" :label="label + ':'" :label-cols-lg="labelColsLg" :label-cols-sm="labelColsSm" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 align-items-baseline">
        <date-time-picker
            :id="id"
            v-model="date"
            :class="stateClass"
            :disabled="disabled"
            :label="placeholder"
            :max-date="maxDate"
            :min-date="minDate"
            :no-button-now="withoutNow"
            :no-label="!!value"
            :only-date="true"
            autoClose
            buttonNowTranslation="Сегодня"
            format="YYYY-MM-DD"
            formatted="DD.MM.YYYY"/>
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormRowEditDate',
    props: {
        labelColsLg: {
            type: Number,
            default: 3
        },
        labelColsSm: {
            type: Number,
            default: 4
        },
        value: {
            type: String,
            default: ''
        },
        v: {
            type: Object,
            default: null
        },
        label: {
            type: String,
            required: true
        },
        minDate: {
            default: null
        },
        maxDate: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Выберите дату'
        },
        withoutNow: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: '',
        },
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            }
        },
        id() {
            return 'form_row_edit_date_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        stateClass() {
            if (!this.v) {
                return '';
            } else {
                return this.v.$invalid ? 'is-invalid' : 'is-valid';
            }
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
                if (!this.v.minValue && 'minValue' in this.v.$params) return this.minDate ? 'Значение поля должно быть больше или равно ' + this.$formatDate(this.minDate) : 'Проверьте правильность введенных данных';
            }
            return '';
        }
    }
};
</script>
