<template>
    <div id="atmo-purchase-terms" class="atmo-hash-jump-point atmo-content-group">
        <h3>Требования к поставщикам</h3>

        <p v-if="pivc().isPurchaseSummarized || pivc().hasContract">
            <strong><i class="atmo-icon-checkbox"></i> Поставщик подтвердил полное соответствие указанным ниже Требованиям.</strong>
        </p>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Участником закупки могут быть только субъекты малого предпринимательства (СМП):</dt>
                <dd>
                    <span v-if="$props.requirements.is_only_smb">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>
            <li>
                <dt>Заказчик установил требование к участникам по рейтингу в ИС АТМО и может отклонить предложение участника с рейтингом ниже 4,5 балла:</dt>
                <dd>
                    <span v-if="$props.requirements.rating">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>
            <li v-if="$props.requirements.a31s1p1" class="atmo-wide-text">
                <dt>Участник закупки должен соответствовать требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ</dt>
            </li>

            <!-- // NB: a31s1p1 view for a non-summarized purchase case. -->
            <li v-if="$props.requirements.a31s1p1">
                <dt>Участник закупки должен соответствовать следующим требованиям:</dt>
                <dd>
                    <span v-text="$props.requirements.a31s1p1_details.description"></span>
                    <ul>
                        <li v-for="(document, index) in $props.requirements.a31s1p1_details.supporting_documents" v-bind:key="index">
                            <span v-text="index + 1"></span>.&nbsp;
                            <span v-text="document.description"></span>
                        </li>
                    </ul>
                </dd>
            </li>

            <SupportingDocuments.view v-if="actual_requirements?.a31s1p1 && is_confirmed_requirement_visible" v-bind:documents="actual_requirements.a31s1p1_details.supporting_documents">
                Участник закупки подтвердил соответствие требованиям п.1 ч.1 ст.31, прикрепив следующие документы</SupportingDocuments.view
            >

            <li v-if="$props.requirements.a31s1ps3_5ps7_11" class="atmo-wide-text">
                <dt>Участник закупки должен соответствовать требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ</dt>
            </li>
            <li v-if="$props.requirements.a31s1_1" class="atmo-wide-text">
                <dt>Участник закупки должен соответствовать требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ</dt>
            </li>

            <li>
                <dt>Участник закупки должен подтвердить, что является <abbr title="Социально ориентированная некоммерческая организация">СОНКО</abbr>:</dt>
                <dd>
                    <span v-if="$props.requirements.supplier_reqs_sonko">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>

            <li v-if="$props.requirements?.additional?.state.is_required">
                <dt>Участник закупки должен соответствовать следующим дополнительным требованиям:</dt>
                <dd>
                    <ul>
                        <li v-for="(requirement, index) in $props.requirements.additional.requirements" v-bind:key="index">
                            <div v-text="`${index + 1}. ${requirement.description}`"></div>
                            <div>Причина или цель установки: {{ requirement.reason }}</div>
                        </li>
                    </ul>
                </dd>
            </li>

            <SupportingDocuments.view v-if="$props.requirements?.additional?.state.is_required && is_confirmed_requirement_visible" v-bind:documents="actual_requirements.additional.requirements"> Подтверждающие документы:</SupportingDocuments.view>
        </ul>
    </div>
</template>

<script>
import SupportingDocumentsView from './SupportingDocuments.view.vue';

export default {
    inject: ['pivc'],
    components: {
        'SupportingDocuments.view': SupportingDocumentsView
    },
    props: {
        requirements: { required: true, type: Object },
        purchase: { required: true, type: Object }
    },
    computed: {
        actual_requirements: function () {
            return this.pivc().isPurchaseSummarized && !this.pivc().hasContract ? this.$props.purchase.accepted_proposal.supplier_requirements : this.pivc().hasContract ? this.$props.purchase.contract.supplier_requirements : this.$props.purchase.supplier_requirements;
        },
        is_confirmed_requirement_visible: function () {
            return (this.pivc().isPurchaseSummarized || this.pivc().hasContract) && (this.pivc().isUserEngagedCustomer || this.pivc().isUserEngagedSupplier);
        }
    }
};
</script>
