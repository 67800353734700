<template>
    <div>
        <b-form-group label="КриптоПро ЭЦП Browser plug-in не найден" label-class="fs-22 text-danger" class="text-left">
            <div class="error">
                <p class="fs-20 fw-600 grey-color">Возможные причины ошибки:</p>
                <ul class="error-list">
                    <li class="fs-16 grey-color fw-400 my-2">— Не установлен КриптоПро ЭЦП Browser plug-in</li>
                    <li class="fs-16 grey-color fw-400 my-2">— Не установлен программный комплекс КриптоПро CSP</li>
                    <li class="fs-16 grey-color fw-400 my-2">— КриптоПро ЭЦП Browser plug-in не активен в настройках расширений Вашего браузера</li>
                </ul>
                <p class="fs-18 fw-600 grey-color">
                    <a href="https://www.cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html" class="fs-20 fw-600 green-color">Проверить работу КриптоПро и плагина</a>
                </p>

                <p class="fs-18 fw-600 grey-color">
                    <a href="https://info.zakupschik.ru/209--oshibka-plagin-kriptopro-ne-najden" class="fs-20 fw-600 green-color">Подробнее об ошибке и её устранении</a>
                </p>
                <p class="fs-20 fw-600 grey-color">В случае возникновения дополнительных вопросов рекомендуем обратиться в техническую поддержку (Кнопка "Поддержка" в правом нижнем углу).</p>
            </div>
        </b-form-group>
    </div>
</template>
<script>
export default {
    name: 'cryptopro-error'
};
</script>

<style scoped>
.error-list {
    list-style-type: none;
}
</style>
