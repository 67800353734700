<template>
    <div class="atmo-content-group">
        <h3>Информация о заказчике</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Дополнительная контактная информация:</dt>
                <dd>
                    <span class="atmo-form__field atmo-inverse">
                        <input type="text" v-model="$props.inputs.additional_contact_info" />
                    </span>
                </dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        user: { required: true, type: Object },
        inputs: { required: true, type: Object }
    }
};
</script>
