<template>
    <confirmation-dialogue v-slot:default="cd" v-bind:id="'decline-proposal-popup'">
        <on-off v-slot:default="onoff" v-bind:options="{ id: cd.$props.id, onEsc: false, onBody: false }">
            <decline-proposal-popup-controller v-slot:default="dppc" v-bind:supplier_ratings="cd.content && cd.content.proposal.supplier.ratings" v-bind:supplier_reqs_rating="Boolean(cd?.content?.purchaseSupplierRequirements?.rating)">
                <div v-if="onoff.ison" class="atmo-popup-wrapper">
                    <div class="atmo-popup-content atmo-wide">
                        <p v-text="dppc.item"></p>

                        <button v-on:click.stop="cd.cancel" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                        <header>
                            <h5>Отклонение ценового предложения</h5>
                            <p class="atmo-text-center">
                                <strong>Поставщик: <span v-text="cd.content.proposal.supplier.title_brief"></span></strong>
                            </p>
                        </header>

                        <main>
                            <ul class="atmo-content-block-2-columns atmo-no-item-separator">
                                <li>
                                    <dt>Причина отклонения:</dt>
                                    <dd v-bind:class="{ 'atmo-has-errors': dppc.errors.declinereasonid }" class="atmo-form__field atmo-as-input">
                                        <dropdown-select v-slot:default="ds" v-bind:list="dppc.declinereasonsFiltered">
                                            <on-off v-slot:default="onoff" v-bind:options="{ onBody: 'intelligent' }">
                                                <div class="atmo-dropdown-select">
                                                    <input type="number" v-model.number="dppc.inputs.declinereasonid" />

                                                    <button v-on:click="onoff.toggle" type="button">
                                                        <span v-if="!ds.hasSelected || onoff.ison" class="atmo-placeholder">Выберите причину отклонения предложения</span>
                                                        <span v-if="ds.hasSelected && !onoff.ison" v-html="ds.text"></span>
                                                        <i v-bind:class="{ 'atmo-icon-angle-up': onoff.ison, 'atmo-icon-angle-down': !onoff.ison }"></i>
                                                    </button>
                                                    <ul v-show="onoff.ison" v-on:click="onoff.off" class="atmo-dropdown">
                                                        <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id)">
                                                            <span v-html="item.title"></span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </on-off>
                                        </dropdown-select>

                                        <p v-if="dppc.errors.declinereasonid" class="atmo-error">Поле обязательно для заполнения</p>
                                    </dd>
                                </li>

                                <li class="atmo-align-top">
                                    <dt>Комментарий:</dt>
                                    <dd class="atmo-form__field atmo-inverse">
                                        <textarea v-model="dppc.inputs.comment" required minlength="10" rows="2"></textarea>
                                        <p v-if="dppc.errors.comment" class="atmo-error">Длина поля должна быть минимум 10 символов</p>
                                    </dd>
                                </li>

                                <li>
                                    <dt>Обоснование причины отклонения:</dt>
                                    <dd>
                                        <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:isrequired="true" v-bind:origin="'proposals'" v-bind:maxfilesallowed="1" v-on:fileattachment:changed="dppc.updateFileAttachment">
                                            <table class="atmo-table-slim">
                                                <tbody>
                                                    <tr>
                                                        <td class="atmo-left">
                                                            <span v-if="!efalc.isempty">
                                                                <button v-on:click="efalc.downloadFileAttachment(efalc.list[0])" class="atmo-button-link" v-text="efalc.list[0].name" title="Скачать файл"></button>
                                                            </span>
                                                            <span v-else class="atmo-error">Прикрепите документ</span>
                                                        </td>
                                                        <td class="atmo-tc-button-icon atmo-center" title="Прикрепить файл">
                                                            <label v-bind:disabled="efalc.islistfull" class="atmo-button-icon">
                                                                <span><i class="atmo-icon-attachment"></i></span>
                                                                <input v-on:change="efalc.add" type="file" accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx" />
                                                            </label>
                                                        </td>
                                                        <td class="atmo-tc-button-icon atmo-center" title="Удалить файл">
                                                            <button v-on:click="efalc.remove(efalc.list[0])" v-bind:disabled="efalc.isempty" class="atmo-button-icon">
                                                                <i class="atmo-icon-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </editable-file-attachments-list-controller>
                                    </dd>
                                </li>
                            </ul>
                        </main>

                        <footer>
                            <ul class="atmo-buttons atmo-align-right">
                                <li>
                                    <button
                                        v-on:click="
                                            cd.cancel();
                                            dppc.reset();
                                        "
                                        class="atmo-button-inverse atmo-dimmed">
                                        Отмена
                                    </button>
                                </li>
                                <li>
                                    <button
                                        v-bind:disabled="dppc.hasErrors"
                                        v-on:click="
                                            cd.confirm({ inputs: dppc.detachedInputs(), declinereasons: dppc.declinereasons });
                                            dppc.reset();
                                        "
                                        class="atmo-button atmo-accented">
                                        Отклонить
                                    </button>
                                </li>
                            </ul>
                        </footer>
                    </div>
                </div>
            </decline-proposal-popup-controller>
        </on-off>
    </confirmation-dialogue>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import ConfirmationDialogue from '@lib/js/src/vue/components/ConfirmationDialogue.viewmodel.ts';
import DeclinePoposalPopupController from '@/rearchitected/core/js/viewmodels/purchases/item/view/widgets/protocol/DeclinePoposalPopupController';
import DropdownSelect from '@lib/js/src/vue/components/DropdownSelect.viewmodel.ts';
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';

export default {
    components: {
        'on-off': new OnOff(),
        'dropdown-select': DropdownSelect,
        'confirmation-dialogue': ConfirmationDialogue,
        'decline-proposal-popup-controller': DeclinePoposalPopupController,
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController
    }
};
</script>
