'use strict';

import { Route } from 'vue-router';

import RouteDataServiceFacade from '../RouteDataServiceFacade';
import PurchaseRequest from '@core/js/ddriven/application/http/requests/purchases/PurchaseRequest.valueobject';

export default class NoticesRouteDataService {
    private theFacade: RouteDataServiceFacade;

    constructor(theFacade: RouteDataServiceFacade) {
        this.theFacade = theFacade;
    }

    public async loadNotices(to: Route) {
        const request = PurchaseRequest.fromQueryObject(to.query, PurchaseRequest) as PurchaseRequest;
        await this.theFacade.store.dispatch(`rearchitected/groups/global/notices/dictionaries/loadDeliverablesGroups`);
        await this.theFacade.store.dispatch(`rearchitected/groups/global/notices/dictionaries/loadPurchaseTypes`);
        await this.theFacade.store.dispatch(`rearchitected/groups/global/notices/dictionaries/loadMunicipalities`);
        this.theFacade.loadEntitiesGrouped('notices', request, to.query, to);
    }

    public async loadNoticesFl223(to: Route) {
        const request = PurchaseRequest.fromQueryObject(to.query, PurchaseRequest) as PurchaseRequest;
        await this.theFacade.store.dispatch(`rearchitected/groups/fl223/notices/dictionaries/loadDeliverablesGroups`);
        await this.theFacade.store.dispatch(`rearchitected/groups/fl223/notices/dictionaries/loadMunicipalities`);
        this.theFacade.loadEntitiesGrouped('notices', request, to.query, to);
    }

    public async loadNoticesCommercial(to: Route) {
        const request = PurchaseRequest.fromQueryObject(to.query, PurchaseRequest) as PurchaseRequest;
        await this.theFacade.store.dispatch(`rearchitected/groups/commercial/notices/dictionaries/loadDeliverablesGroups`);
        await this.theFacade.store.dispatch(`rearchitected/groups/commercial/notices/dictionaries/loadMunicipalities`);
        this.theFacade.loadEntitiesGrouped('notices', request, to.query, to);
    }

    public async loadNoticesFl44(to: Route) {
        const request = PurchaseRequest.fromQueryObject(to.query, PurchaseRequest) as PurchaseRequest;
        await this.theFacade.store.dispatch(`rearchitected/groups/fl44/notices/dictionaries/loadDeliverablesGroups`);
        await this.theFacade.store.dispatch(`rearchitected/groups/fl44/notices/dictionaries/loadMunicipalities`);
        this.theFacade.loadEntitiesGrouped('notices', request, to.query, to);
    }
}
