'use strict';

import CommonRequest from '@core/js/ddriven/application/http/requests/common/CommonRequest';

export interface IContractRequestFilter {
    [index: string]: unknown;

    purchaseName: null | string; // Описание закупки
    purchaseCategories: number[];
    municipalities: number[];
    conditionname: null | string; // Наименование ТРУ
    customer: null | string; // Покупатель
    provider: null | string; // Поставщик
    regNumber: null | string; // Регистрационный номер закупки
    fromStartPrice: null | number; // Цена извещения от
    toStartPrice: null | number; // Цена извещения до
    fromPurchaseStart: null | string; // Дата размещения извещения от
    toPurchaseStart: null | string; // Дата размещения извещения до
    type: null | string; // Статус договора: в/вне АТМО (contractType | is_external)
}

export default class ContractRequestFilter implements IContractRequestFilter {
    [index: string]: unknown;

    purchaseName: null | string;
    purchaseCategories: number[];
    municipalities: number[];
    conditionname: null | string;
    customer: null | string;
    provider: null | string;
    regNumber: null | string;
    fromStartPrice: null | number;
    toStartPrice: null | number;
    fromPurchaseStart: null | string;
    toPurchaseStart: null | string;
    type: null | string;

    constructor(contractRequestFilterRawData?: IContractRequestFilter) {
        this.purchaseName = CommonRequest.actualOrDefault('purchaseName', null, contractRequestFilterRawData);
        this.purchaseCategories = contractRequestFilterRawData?.purchaseCategories ?? [];
        this.municipalities = contractRequestFilterRawData?.municipalities ?? [];
        this.conditionname = CommonRequest.actualOrDefault('conditionname', null, contractRequestFilterRawData);
        this.customer = CommonRequest.actualOrDefault('customer', null, contractRequestFilterRawData);
        this.provider = CommonRequest.actualOrDefault('provider', null, contractRequestFilterRawData);
        this.regNumber = CommonRequest.actualOrDefault('regNumber', null, contractRequestFilterRawData);
        this.fromStartPrice = CommonRequest.actualOrDefault('fromStartPrice', null, contractRequestFilterRawData);
        this.toStartPrice = CommonRequest.actualOrDefault('toStartPrice', null, contractRequestFilterRawData);
        this.fromPurchaseStart = CommonRequest.actualOrDefault('fromPurchaseStart', null, contractRequestFilterRawData);
        this.toPurchaseStart = CommonRequest.actualOrDefault('toPurchaseStart', null, contractRequestFilterRawData);
        this.type = CommonRequest.actualOrDefault('type', null, contractRequestFilterRawData);
    }
}
