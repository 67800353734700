var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-content-group" }, [
    _c("h5", [_vm._v("Лимиты по КБК")]),
    _c("div", { staticClass: "table-responsive" }, [
      _c("table", { staticClass: "atmo-content-table" }, [
        _vm._m(0),
        _c(
          "tbody",
          [
            _vm._l(this.$props.limits.items, function (limit, index) {
              return _c(
                "tr",
                { key: `${limit.id}${index + 1}` },
                [
                  _c("td", {
                    staticClass: "atmo-center",
                    domProps: { textContent: _vm._s(index + 1) },
                  }),
                  _c("td", { domProps: { textContent: _vm._s(limit.code) } }),
                  _c("td", {
                    domProps: { textContent: _vm._s(limit.sub_kosgu_code) },
                  }),
                  _c("KBKLimitDetails.view", { attrs: { limit: limit } }),
                  _c("td", {
                    staticClass: "atmo-right",
                    domProps: { textContent: _vm._s(limit.year) },
                  }),
                  _c("td", { staticClass: "atmo-right" }, [
                    _vm._v(
                      _vm._s(_vm._f("formatnumber")(limit.amount_available))
                    ),
                  ]),
                  _c("td", { staticClass: "atmo-right" }, [
                    _vm._v(_vm._s(_vm._f("formatnumber")(limit.amount_total))),
                  ]),
                  _c("td", { staticClass: "atmo-right" }, [
                    _vm._v(
                      _vm._s(_vm._f("formatnumber")(limit.amount_assigned))
                    ),
                  ]),
                ],
                1
              )
            }),
            _c("tr", { staticClass: "atmo-totals" }, [
              _c("td", { staticClass: "atmo-right", attrs: { colspan: "8" } }, [
                _vm._v(
                  "Распределено итого, руб.:  " +
                    _vm._s(
                      _vm._f("formatnumber")(
                        _vm.$props.limits.distributed_amount_total
                      )
                    )
                ),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "atmo-center" }, [_vm._v("№")]),
        _c("th", [_vm._v("КБК")]),
        _c("th", [_vm._v("СубКОСГУ")]),
        _c("th", [_vm._v("Подробности")]),
        _c("th", { staticClass: "atmo-right" }, [_vm._v("Год финансирования")]),
        _c("th", { staticClass: "atmo-right" }, [
          _vm._v("Доступный лимит, руб."),
        ]),
        _c("th", { staticClass: "atmo-right" }, [_vm._v("Общий лимит, руб.")]),
        _c("th", { staticClass: "atmo-right" }, [
          _vm._v("Распределено, руб. "),
          _c("i", {
            staticClass: "atmo-quick-helper atmo-icon-question-mark",
            attrs: {
              title:
                "Часть Стоимости спецификации, распределенная на данный Доступный лимит.",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }