<template>
    <b-button :title="title" style="width: 24px; height: 24px" class="p-0" :variant="variant" :to="to" v-if="!!to" :disabled="disabled"><b-icon :icon="icon" :scale="scale" class="my-auto" aria-hidden="true" /></b-button>
    <b-button :title="title" style="width: 24px; height: 24px" class="p-0" :variant="variant" v-else @click="click" :disabled="disabled"><b-icon :icon="icon" scale="0.6" class="my-auto" aria-hidden="true" /></b-button>
</template>

<script>
export default {
    name: 'action-button-small',
    // props: ['variant', 'icon', 'to'],
    props: {
        variant: {
            type: String,
            default: 'primary'
        },
        icon: {
            type: String,
            required: true
        },
        title: {
            default: ''
        },
        disabled: {
            default: false
        },
        scale: {
            type: Number,
            default: 0.6
        },
        to: {}
    },
    methods: {
        click() {
            this.$emit('click');
        }
    }
};
</script>

<style scoped>
.btn-primary {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
    vertical-align: -0.15em;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:active {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
    filter: brightness(85%);
}
.btn-primary:focus {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
}
.btn-primary:hover {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
    filter: brightness(85%);
}
.btn-outline {
    color: var(--grey-color);
    background-color: transparent;
    border-color: var(--grey-color);
    vertical-align: -0.15em;
}
.btn-outline:not(:disabled):not(.disabled):active,
.btn-outline:active {
    color: var(--grey-color);
    background-color: transparent;
    border-color: var(--grey-color);
    filter: brightness(85%);
}
.btn-outline:focus {
    color: var(--grey-color);
    background-color: transparent;
    border-color: var(--grey-color);
}
.btn-outline:hover {
    color: var(--grey-color);
    background-color: transparent;
    border-color: var(--grey-color);
    filter: brightness(85%);
}
.btn-danger {
    color: #fff;
    background-color: var(--red-color);
    border-color: var(--red-color);
    vertical-align: -0.15em;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:active {
    color: #fff;
    background-color: var(--red-color);
    border-color: var(--red-color);
    filter: brightness(85%);
}
.btn-danger:focus {
    color: #fff;
    background-color: var(--red-color);
    border-color: var(--red-color);
}
.btn-danger:hover {
    color: #fff;
    background-color: var(--red-color);
    border-color: var(--red-color);
    filter: brightness(85%);
}
.btn-warning {
    color: #fff;
    background-color: var(--yellow-color);
    border-color: var(--yellow-color);
    vertical-align: -0.15em;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:active {
    color: #fff;
    background-color: var(--yellow-color);
    border-color: var(--yellow-color);
    filter: brightness(85%);
}
.btn-warning:focus {
    color: #fff;
    background-color: var(--yellow-color);
    border-color: var(--yellow-color);
}
.btn-warning:hover {
    color: #fff;
    background-color: var(--yellow-color);
    border-color: var(--yellow-color);
    filter: brightness(85%);
}
</style>
