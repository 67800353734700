'use strict';

import State, { ActionContext } from 'vuex';

import PurchaseRequest from '@core/js/ddriven/application/http/requests/purchases/PurchaseRequest.valueobject';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';

const actions = {
    load: async function (context: ActionContext<any, any>, request: PurchaseRequest): Promise<void> {
        const response = await context.rootState.rearchitected.utils.atmoapi.loadV1('notices:global', request.toQueryStringNew());

        context.commit('setList', response.data.data);
        context.commit('setPagination', response.data.meta);
    },

    // REFACTOR: Temporary solution for API v1.
    // Should be replaced when API v2 for /notices/<id> is ready with
    // single call to /notices/<id> to get proposals in response.data.data.v2.actualOffers.
    loadItem: async function (context: ActionContext<any, any>, id: number): Promise<void> {
        const user = context.rootGetters['rearchitected/users/user'];

        const proposalsResponse = await context.rootState.rearchitected.utils.atmoapi.entities.loadPurchaseProposalsForUserType(id, UserVO.type(user));

        const response = await context.rootState.rearchitected.utils.atmoapi.load('notices:itemById', id);

        // REFACTOR: Afterwards. Now artificially add actual offers to response.
        response.data.data.v2 = {
            actualOffers: proposalsResponse.data.actualOffers
        };

        if (!response.data.data.offers) {
            response.data.data.offers = proposalsResponse.data.actualOffers;
        }

        if (response.data.data.protocol_forming) {
            response.data.data.offers = response.data.data.protocol_forming.offers;
        }

        if (response.data.data.offers && response.data.data.offers.length < 1 && proposalsResponse.data.actualOffers && proposalsResponse.data.actualOffers.length < 1) {
            response.data.data.offers = proposalsResponse.data.providers;
        }

        // REFACTOR: Afterwards. Now artificially add annexes and fulfillment attachments to response.
        if (response.data.data.contract) {
            const fulfillmentResponse = await context.rootState.rearchitected.utils.atmoapi.entities.loadContractExecutionDocuments(response.data.data.contract.id);
            response.data.data.contract.finalOffer = response.data.data.finalOffer || null;
            response.data.data.contract.annexes = response.data.data.contract.supplementary_agreements;
            response.data.data.contract.fulfillment_attachments = fulfillmentResponse.data.data;
        }

        context.commit('setItem', response.data.data);
    },

    loadPurchaseDraft: async function (context: ActionContext<any, any>, id: number): Promise<void> {
        const draft = await context.rootState.rearchitected.utils.atmoapi.entities.loadPurchaseDraft(id);
        context.commit('setPurchaseDraft', draft.data);
    }
};

export default actions;
