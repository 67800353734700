<template>
    <kbk-limits-editable-controller v-slot:default="klec" v-bind:initiallist="this.$props.initiallist" v-bind:totalamount="this.$props.totalamount" v-bind:limitfilterpresets="this.$props.limitfilterpresets">
        <div class="atmo-content-group">
            <slot name="title">
                <h5>Лимиты по КБК</h5>
            </slot>

            <p>
                <span>Добавьте или измените лимиты. Необходимо полностью распределить стоимость спецификации по соответствующим лимитам.</span>&nbsp;
                <i class="atmo-quick-helper atmo-icon-question-mark" title='Можно добавить новый лимит года финансирования по КБК (кнопка "Добавить лимит") или сменить лимит для уже добавленного в список КБК на лимит другого года (кнопка с кодом КБК).'></i>
            </p>

            <slot name="info"></slot>

            <div class="table-responsive">
                <table v-bind:class="{ 'atmo-empty': klec.islistempty }" class="atmo-content-table">
                    <thead>
                        <tr>
                            <th class="atmo-center">№</th>
                            <th>КБК</th>
                            <th>СубКОСГУ</th>
                            <th>Подробности</th>
                            <th class="atmo-right">Год финансирования</th>
                            <th class="atmo-right">Доступный лимит, руб.</th>
                            <th class="atmo-right">Общий лимит, руб.</th>
                            <th class="atmo-right">Распределено, руб. <i class="atmo-quick-helper atmo-icon-question-mark" title="Часть Стоимости спецификации, распределенная на данный Доступный лимит."></i></th>
                            <th class="atmo-ctc-command-remove">&nbsp;</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(limit, index) in klec.list.items" v-bind:key="`${limit.id}${index + 1}`">
                            <td v-text="index + 1" class="atmo-center"></td>

                            <td>
                                <button v-text="limit.code" v-on:click.stop="klec.invokeKBKLimitSelectorPopup(limit)" class="atmo-button atmo-button-small" title="Нажмите, чтобы выбрать лимит другого года финансирования внутри этого КБК."></button>
                            </td>
                            <td v-text="limit.sub_kosgu_code"></td>

                            <KBKLimitDetails.view v-bind:limit="limit" />

                            <td v-text="limit.year" class="atmo-right"></td>
                            <td class="atmo-right">{{ limit.amount_available | formatnumber }}</td>
                            <td class="atmo-right">{{ limit.amount_total | formatnumber }}</td>

                            <td class="atmo-ctc-price-total">
                                <formatted-input-number v-slot:default="fim">
                                    <div class="atmo-component-wrapper">
                                        <button v-if="!fim.isInputFocused" v-on:click.stop="fim.focus" v-on:focus="fim.onTriggerFocus" v-bind:class="{ 'atmo-invalid': !fim.isInputValid || limit.errors.amount_assigned }" class="atmo-button--small atmo-input" v-text="fim.formatted"></button>

                                        <span v-show="fim.isInputFocused" v-bind:class="{ 'atmo-has-errors': limit.errors.amount_assigned }" class="atmo-form__field atmo-inverse atmo-small">
                                            <input
                                                v-model.number="limit.amount_assigned"
                                                v-on:blur="fim.processBlurEvent"
                                                v-on:keydown="fim.processKeydownEvent"
                                                v-on:input="fim.processInputEvent"
                                                v-on:keyup.enter="fim.processBlurEvent"
                                                v-stop-number-mousewheel
                                                required
                                                type="number"
                                                step="0.01"
                                                min="0.01" />
                                        </span>
                                    </div>
                                </formatted-input-number>
                            </td>

                            <td class="atmo-ctc-command-remove">
                                <button v-on:click="klec.removeItem(index)" class="atmo-button-icon" title="Удалить лимит из списка"><i class="atmo-icon-trash"></i></button>
                            </td>
                        </tr>

                        <tr v-if="!klec.islistempty" class="atmo-totals">
                            <td colspan="7">Распределено итого, руб.:</td>
                            <td class="atmo-right">{{ klec.list.distributed_amount_total | formatnumber }}</td>
                        </tr>

                        <tr v-if="!klec.islistempty" class="atmo-totals">
                            <td colspan="7">Стоимость спецификации итого, руб.:</td>
                            <td class="atmo-right">{{ $props.totalamount | formatnumber }}</td>
                        </tr>

                        <tr v-if="!klec.islistempty && klec.limits_deliverables_variance !== 0" class="atmo-totals">
                            <td colspan="7">Отклонение от стоимости спецификации, руб.:</td>
                            <td class="atmo-right">{{ klec.limits_deliverables_variance | formatnumber }}</td>
                        </tr>

                        <tr v-if="klec.islistempty">
                            <td colspan="9" class="atmo-center">Лимиты не добавлены</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p v-if="klec.islistempty" class="atmo-error">Добавьте лимиты</p>
            <p v-if="klec.list.errors.items_amount_assigned" class="atmo-error">Поле "Распределено" не заполнено или превышает доступный лимит для одного или нескольких лимитов.</p>
            <p v-if="!klec.islistempty && klec.error_amount_distribution" class="atmo-error">Итоговая сумма распределенных средств из лимитов не совпадает со стоимостью спецификации.</p>

            <button v-on:click.stop="klec.invokeKBKLimitSelectorPopup" class="atmo-button">Добавить лимит</button>
        </div>
    </kbk-limits-editable-controller>
</template>

<script>
import KBKLimitsEditableController from '@core/js/viewmodels/purchases/item/update/kbk-limits/KBKLimitsEditableController.viewmodel.ts';
import FormattedInputNumber from '@lib/js/src/vue/components/FormattedInputNumber.viewmodel.ts';
import KBKLimitDetailsView from './KBKLimitDetails.view.vue';

export default {
    props: {
        initiallist: { required: true, type: Object },
        totalamount: { required: true, type: Number },
        limitfilterpresets: { default: () => {}, type: Object }
    },
    components: {
        'kbk-limits-editable-controller': KBKLimitsEditableController,
        'formatted-input-number': FormattedInputNumber,
        'KBKLimitDetails.view': KBKLimitDetailsView
    }
};
</script>
