'use strict';

import groupBy from 'lodash.groupby';

import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';
import DeliverableItemEditableVO from './DeliverableItemEditable.valueobject';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';

export interface IErrors {
    is_quantity_empty: boolean;
    is_price_per_unit_empty: boolean;
    is_specification_total_price_invalid: boolean;
}

export default class DeliverableItemsEditableListValidator {
    private _errors: IErrors;

    constructor() {
        this._errors = {
            is_quantity_empty: false,
            is_price_per_unit_empty: false,
            is_specification_total_price_invalid: false
        };
    }

    get errors(): IErrors {
        return this._errors;
    }

    public validate(deliverables: DeliverableItemEditableVO[], max_price: number = 9999999999999.0): DeliverableItemsEditableListValidator {
        this._errors.is_quantity_empty = deliverables.some((deliverable: DeliverableItemEditableVO) => {
            return isPrimitiveEmpty(deliverable.quantity) || deliverable.quantity! <= 0;
        });

        this._errors.is_price_per_unit_empty = deliverables.some((deliverable: DeliverableItemEditableVO) => {
            return isPrimitiveEmpty(deliverable.price_per_unit) || deliverable.price_per_unit! <= 0;
        });

        this._errors.is_specification_total_price_invalid =
            roundToTwoDecimals(
                deliverables.reduce((accumulator: number, deliverable: DeliverableItemEditableVO) => {
                    return accumulator + (deliverable.price_total ?? 0);
                }, 0)
            ) > max_price;

        return this;
    }
}
