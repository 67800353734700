<template>
    <div v-bind:class="{ 'atmo-isprogressing': isprogressing }" class="atmo-progress-indicating-button">
        <slot v-bind:component="component">
            <button v-if="$props.type === 'dimmed'" v-on:click="baseClickHandler" v-bind:disabled="isprogressing" class="atmo-button-inverse atmo-dimmed">{{ $props.text }}</button>
            <button v-else v-on:click="baseClickHandler" v-bind:disabled="isprogressing || $props.disabledstate" v-bind:class="$props.classes" class="atmo-button">{{ $props.text }}</button>
        </slot>
        <div v-if="isprogressing" class="atmo-spinner"><img src="@theme/assets/images/loaders/spinner-sector.svg" alt="Waiting spinner" /></div>
    </div>
</template>

<script>
export default {
    props: {
        type: { default: '', type: String },
        classes: { default: '', type: String },
        clickhandler: { required: true, type: Function },
        text: { default: 'Button Text', type: String },
        disabledstate: { default: false, type: Boolean }
    },
    data() {
        return {
            isprogressing: false
        };
    },
    computed: {
        component: function () {
            return {
                handler: this.baseClickHandler,
                isprogressing: this.$data.isprogressing
            };
        }
    },
    methods: {
        baseClickHandler: async function (...args) {
            this.$data.isprogressing = true;
            await this.$props.clickhandler(...args);
            this.$data.isprogressing = false;
        }
    }
};
</script>
