'use strict';

import Collection from '@lib/js/src/misc/Collection';
import ExtendedDictionaryItem from './ExtendedDictionaryItem.valueobject';

export default class ExtendedDictionaryCollection<T> extends Collection<T> {
    constructor(...items: ExtendedDictionaryItem[]) {
        super(...(items as any));
        Object.setPrototypeOf(this, ExtendedDictionaryCollection.prototype);
    }

    public findById(id: ExtendedDictionaryItem['id']): T | undefined {
        return this.find((item: any) => {
            return item.id === id;
        });
    }

    public default(): T | undefined {
        return this.find((item: any) => {
            return item.is_default === true;
        });
    }
}
