<template>
    <div class="atmo-content-group my-4 py-0">
        <h5>Документы</h5>

        <div class="table-responsive my-2 py-0">
            <table class="b-table table">
                <thead>
                    <tr>
                        <th style="width: 20px; max-width: 20px">№</th>
                        <th style="width: 200px; max-width: 200px">Дата и время направления документов</th>
                        <th>Документ</th>
                        <th width="50">Подпись заказчика</th>
                        <th width="50">Подпись поставщика</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(attachment, index) in this.$props.annex.attachments" v-bind:key="index">
                        <td v-text="index + 1"></td>
                        <td>{{ attachment.created_at | formatdate('withSeconds') }}</td>
                        <td style="min-width: 300px">
                            <template v-if="attachment.is_archived">(Архив)&nbsp;</template>
                            <a :href="$getAttachmentDownloadLink(attachment.file_id)" class="atmo-button-link" style="color: var(--green-color) !important" target="_blank">{{ attachment.name }}</a>
                        </td>
                        <td class="text-center">
                            <template v-if="annex.isSignedByBoth() && !attachment.is_archived">
                                <action-button-small icon="clipboard-check" title="Информация о подписи" @click="$props.pivc.invokeSignaturePopup([annex.signatures.customer])" />
                                <action-button-small v-if="annex.signatures.customer.poa_number && annex.signatures.customer.poa_check_link" class="ml-1" icon="clipboard-plus" title="Информация о МЧД" variant="warning" @click="$props.pivc.invokeSignaturePopup([annex.signatures.customer])" />
                            </template>
                        </td>
                        <td class="text-center">
                            <template v-if="annex.isSignedByBoth() && !attachment.is_archived">
                                <action-button-small icon="clipboard-check" title="Информация о подписи" @click="$props.pivc.invokeSignaturePopup([annex.signatures.supplier])" />
                                <action-button-small v-if="annex.signatures.supplier.poa_number && annex.signatures.supplier.poa_check_link" class="ml-1" icon="clipboard-plus" title="Информация о МЧД" variant="warning" @click="$props.pivc.invokeSignaturePopup([annex.signatures.supplier])" />
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { require: true, type: Object },
        annex: { required: true, type: Object }
    }
};
</script>
