var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "atmo-tab-content" }, [
    _c("div", { staticClass: "atmo-content-group" }, [
      _c("h6", [_vm._v("XML-представление и подпись")]),
      _c("pre", { domProps: { textContent: _vm._s(this.$props.xml) } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }