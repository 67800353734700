<template>
    <b-col cols="12" :md="md" :lg="lg">
        <b-form-group>
            <currency-input :placeholder="placeholder" v-stop-number-mousewheel v-model="text" class="form-control" currency="RUB" locale="ru" :allow-negative="false" />
            <span class="fs-10 halfWhite-color label-position">{{ label }}</span>
        </b-form-group>
    </b-col>
</template>
<script>
export default {
    name: 'filter-input-currency',
    props: {
        value: {
            type: Number,
            default: null
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        md: {
            type: String,
            default: '3'
        },
        lg: {
            type: String,
            default: '3'
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
<style scoped>
.halfWhite-color {
    color: rgba(255, 255, 255, 0.5);
}

.label-position {
    display: block;
    margin-top: 5px;
}

.category_menu li {
    padding-bottom: 9px;
}

.category_menu li + li {
    margin-left: 20px;
}

.category_menu li a {
    font-size: 14px;
    color: var(--grey-color);
    border-bottom: 2px solid transparent;
    text-decoration: none;
    padding-bottom: 9px;
    white-space: nowrap;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
}

.mobile_category_menu li {
    padding: 10px 0;
}

.mobile_category_menu li + li {
    border-top: 1px solid var(--green-color);
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 767px) {
    .purchase_part-body_col > div::before {
        display: none;
    }

    /*.purchase_status {*/
    /*  margin-left: 5px !important;*/
    /*}*/
}

@media screen and (max-width: 414px) {
}

@media screen and (max-width: 320px) {
}
</style>
<style lang="less">
@subtext-color: #777;
@preloader-bg-color: rgba(255, 255, 255, 0.75);
@suggestions-bg-color: #fff;
@subtext-label-color: #f5f5f5;

.box-sizing(@sizing: border-box) {
    -ms-box-sizing: @sizing;
    -moz-box-sizing: @sizing;
    -webkit-box-sizing: @sizing;
    box-sizing: @sizing;
}

.rounded(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.cross-button-mixin() {
    @cross-padding: 20;
    @cross-ray-width: 10;
    @cross-ray-length: 50 - @cross-padding - @cross-ray-width;
    @svg: "<svg width='100' height='100' xmlns='http://www.w3.org/2000/svg'><g><path d='m@{cross-padding},@{cross-padding}m@{cross-ray-width},0l@{cross-ray-length},@{cross-ray-length}l@{cross-ray-length},-@{cross-ray-length}l@{cross-ray-width},@{cross-ray-width}l-@{cross-ray-length},@{cross-ray-length}l@{cross-ray-length},@{cross-ray-length}l-@{cross-ray-width},@{cross-ray-width}l-@{cross-ray-length},-@{cross-ray-length}l-@{cross-ray-length},@{cross-ray-length}l-@{cross-ray-width},-@{cross-ray-width}l@{cross-ray-length},-@{cross-ray-length}l-@{cross-ray-length},-@{cross-ray-length}l@{cross-ray-width},-@{cross-ray-width}' fill='#999'/></g></svg>";
    @svg64: `new Buffer(@{svg}) .toString('base64') `;
    background: transparent url('data:image/svg+xml;base64,@{svg64}') 50% 50% no-repeat;
    background-size: contain;

    cursor: pointer;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
}

/**
     * Основной INPUT
     */

/**
     * Блок в правой части основного INPUT'а
     * В нем показывается иконка ЗАГРУЗКА
     */

/**
     * Выпадающий блок с найденными подсказками
     */

/**
     * Контейнер для одной подсказки
     */

/**
     * Выбранная (активная) подсказка
     */

/**
     * Информационный блок в верхней части выпадашки с подсказками
     */

/**
     * Ограничения поиска, показываются в левой части основного INPUT'а
     */

/**
     * Дополнительный текст в подсказке, который идет второй строкой
     */

/**
     * Размещает дополнительный текст в одну строку с основным текстом подсказки
     */

/**
     * Разделитель нескольких дополнительных текстов
     */

/**
     * Выделяет подсказку
     */

/**
     * Промо-блок
     */

.b-form-datepicker > button,
.b-form-datepicker > label {
    color: rgba(255, 255, 255, 0.7) !important;
}
</style>
