var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-content-group" }, [
    _c("h3", [_vm._v("Информация о заказчике")]),
    _c(
      "ul",
      { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
      [
        _c("li", [
          _c("dt", [_vm._v("Дополнительная контактная информация:")]),
          _c("dd", [
            _c("span", { staticClass: "atmo-form__field atmo-inverse" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$props.inputs.additional_contact_info,
                    expression: "$props.inputs.additional_contact_info",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.$props.inputs.additional_contact_info },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$props.inputs,
                      "additional_contact_info",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }