<template>
    <router-link v-if="this.$props.buttondata.isActive" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: this.$props.group, id: this.$props.buttondata.purchaseId }, hash: this.$props.buttondata.hash }" class="atmo-button--small atmo-accented" target="_blank">{{
        this.$props.buttondata.buttonText
    }}</router-link>
</template>

<script>
export default {
    props: {
        group: { required: true, type: String },
        buttondata: { required: true, type: Object }
    }
};
</script>
