'use strict';

import Protocol from './ProtocolStrategy.valueobject';

export default class Contract extends Protocol {
    protected static _type = 'contract';

    /**
     * REFACTOR: See the refactor note in front\src\rearchitected\core\js\ddriven\domain\services\downloads\strategies\PurchaseinfoStrategy.valueobject.ts.
     */
    constructor(id: number, purchaseRegistrationNumber: string) {
        super(id, purchaseRegistrationNumber);
        this.type = Contract._type;
        this.endpoint = `/purchases/${id}/contract_pdf`;
        this.name = `Договор ${purchaseRegistrationNumber}.pdf`;
    }
}
