'use strict';

import PurchaseItem, { IPurchaseRawDataItem } from '@core/js/ddriven/domain/model/purchases/view/list/PurchaseItem.valueobject';
import PurchaseItemDetailsVO, { IRawPurchaseDetailsDataItem } from '@/rearchitected/core/js/ddriven/domain/model/purchases/view/item/PurchaseItemDetails.valueobject';

import { IState } from './state';
import StandardPagination, { IStandardPaginationRawData } from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import { INoticeRequest } from '@core/js/ddriven/application/http/requests/purchases/NoticeRequest.valueobject';
import NoticeItem, { INoticeRawDataItem } from '@core/js/ddriven/domain/model/notices/view/list/NoticeItem.valueobject';

const mutations = {
    setList: function (state: IState, data: INoticeRawDataItem[]): void {
        state.list = data.map((noticeData: INoticeRawDataItem) => {
            return Object.freeze(new NoticeItem(noticeData));
        });
    },

    setItem: function (state: IState, rawItemData: IRawPurchaseDetailsDataItem): void {
        state.item = Object.freeze(PurchaseItemDetailsVO.factory(rawItemData as IRawPurchaseDetailsDataItem));
    },

    setRequest: function (state: IState, request: INoticeRequest): void {
        state.request = request;
    },

    updateRequest: function (state: IState, payload: undefined): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.request[payload.key] = payload.value;
    },

    setPagination: function (state: IState, data: IStandardPaginationRawData): void {
        state.pagination = new StandardPagination(data);
    },

    routereset: function (state: IState, newState: boolean): void {
        state.routereset = newState;
    }
};

export default mutations;
