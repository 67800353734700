<template>
    <div class="atmo-component-wrapper">
        <ul v-bind:class="ci.getStatusClass('atmo-entity-status-')" class="atmo-entity-item-title atmo-icon-custom">
            <li style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis" class="pr-4">
                <b-badge class="my-auto fs-14 mr-2" variant="primary">44-ФЗ</b-badge>
                <b-badge v-if="ci.contractitem.purchase_is_outside_atmo" class="my-auto fs-14 mr-2">Вне ИС</b-badge>

                <a v-bind:href="ci.contractitem.purchase_is_outside_atmo ? `/external_purchases/${ci.contractitem.purchase_id}` : `/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view`" target="_blank">
                    <span v-text="ci.contractitem.purchase_registration_number"></span>
                </a>
                <template v-if="ci.contractitem.purchase_object">&nbsp;На закупку "{{ ci.contractitem.purchase_object }}"</template>
            </li>
            <li class="text-nowrap"><span v-text="ci.status.title" class="text-uppercase"></span></li>
        </ul>

        <ul class="atmo-entity-item-details">
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li v-if="ci.status.code !== 'omitted'">
                        <dt>Договор заключен:</dt>
                        <dd>{{ ci.contractitem.signature_date | formatdate('onlyDate') }}</dd>
                        <span v-if="ci.contractitem.purchase_is_outside_atmo && ci.contractitem.planned_completion_date">
                            <dt>Дата исполнения договора:</dt>
                            <dd>{{ ci.contractitem.planned_completion_date | formatdate('onlyDate') }}</dd>
                        </span>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li>
                        <dt>Предмет закупки:</dt>
                        <dd v-text="ci.contractitem.purchase_description"></dd>
                    </li>
                    <li>
                        <dt>Организация:</dt>
                        <dd><a v-bind:href="'/organizations/' + ci.contractitem.customer_organization_id" v-text="ci.contractitem.customer_organization_title"></a></dd>
                    </li>
                    <li v-if="ci.hasSupplier">
                        <dt>Поставщик:</dt>
                        <dd><a v-bind:href="'/organizations/' + ci.contractitem.supplier_organization_id" v-text="ci.contractitem.supplier_organization_title"></a></dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li v-if="ci.hasCompletionDate">
                        <dt>Планируемая дата исполнения:</dt>
                        <dd>{{ ci.contractitem.planned_completion_date | formatdate('onlyDate') }}</dd>
                    </li>
                    <li>Количество позиций: <b v-text="ci.contractitem.purchase_items_count"></b></li>
                    <li>
                        <abbr title="Начальная цена контракта">НЦК</abbr>: <b>{{ ci.contractitem.purchase_starting_price | formatnumber }} руб.</b>
                    </li>
                    <li v-if="ci.hasDeliveryAddresses">
                        <dt>Место доставки или указание на самовывоз:</dt>
                        <dd v-for="(address, index) in ci.contractitem.delivery_addresses" v-text="address" v-bind:key="index"></dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li>
                        <a v-bind:href="ci.contractitem.purchase_is_outside_atmo ? `/external_purchases/${ci.contractitem.purchase_id}` : `/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view`" target="_blank"
                            ><i class="atmo-icon-search"></i><span>Информация о закупке</span></a
                        >
                    </li>
                    <li v-if="!ci.contractitem.purchase_is_outside_atmo">
                        <a v-bind:href="`/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view#atmo-proposals`" target="_blank"><i class="atmo-icon-dynamic"></i><span>Динамика предложений</span></a>
                    </li>
                    <li v-if="!ci.contractitem.purchase_is_outside_atmo">
                        <a v-bind:href="`/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view#atmo-supplier-info`" target="_blank"><i class="atmo-icon-shield"></i><span>Итоговый протокол</span></a>
                    </li>
                    <li v-if="ci.contractitem.purchase_is_outside_atmo">
                        <a v-bind:href="`/external_purchases/${ci.contractitem.purchase_id}#supplier-info`" target="_blank"><i class="atmo-icon-shield"></i><span>Поставщик</span></a>
                    </li>
                    <li>
                        <a v-bind:href="ci.contractitem.purchase_is_outside_atmo ? `/external_purchases/${ci.contractitem.purchase_id}#contract` : `/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view#atmo-contract`" target="_blank"
                            ><i class="atmo-icon-contract"></i><span>Договор</span></a
                        >
                    </li>
                    <li v-if="!ci.contractitem.purchase_is_outside_atmo">
                        <a v-bind:href="`/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view#atmo-contract-fulfillment`" target="_blank"><i class="atmo-icon-fulfilled"></i><span>Исполнение договора</span></a>
                    </li>
                    <!-- <li><DealStageActionButton v-bind:buttondata="ci.buttondata"></DealStageActionButton></li> -->
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
// import DealStageActionButton from './DealStageActionButton.view.vue';

export default {
    props: {
        ci: { required: true, type: Object }
    },
    components: {
        // DealStageActionButton
    }
};
</script>
