import axios from 'axios';
import store from '@/store';
import Config from '@core/js/ddriven/application/config/Config.ts';
import { IntegrationsApiService } from '@/services/backend/integrations';
import { cabinet } from '@/services/backend/cabinet';
import products from '@/services/backend/products';
import { createXMLSignature } from 'crypto-pro';
import personal from '@/services/backend/personal';
import proposals from '@/services/backend/proposals';
import { OrganizationsApiService } from '@/services/backend/organizations';
import { QuotationRequestsApiService } from '@/services/backend/quotation-requests';
import { AuthenticationApiService } from '@/services/backend/authentication.ts';
import { KtruApiService } from '@/services/backend/ktru';
import { DictionariesApiService } from '@/services/backend/dictionaries';

class LegacyBaseApiService {
    baseUrl = Config.get('api.config.legacy.url');
    resource;

    constructor(resource) {
        if (!resource) throw new Error('Resource is not provided');
        this.resource = resource;
    }

    getUrl(id = '') {
        return id ? `${this.baseUrl}/${this.resource}/${id}` : `${this.baseUrl}/${this.resource}`;
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        store.dispatch('showError', err).then(() => {});
    }
}

class BaseApiService {
    baseUrl = Config.get('api.config.v1.url');
    resource;

    constructor(resource) {
        if (!resource) throw new Error('Resource is not provided');
        this.resource = resource;
    }

    getUrl(id = '') {
        return id ? `${this.baseUrl}/${this.resource}/${id}` : `${this.baseUrl}/${this.resource}`;
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        store.dispatch('showError', err).then(() => {});
    }
}

class ReadOnlyApiService extends BaseApiService {
    constructor(resource) {
        super(resource);
    }

    async fetch() {
        try {
            const response = await axios.get(this.getUrl());
            return await response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async get(id) {
        try {
            if (!id) throw Error('Id is not provided');
            const response = await axios.get(this.getUrl(id));
            return await response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

class PurchasesApiService extends LegacyBaseApiService {
    constructor() {
        super('purchases');
    }

    async getPurchaseOfferCreateData(purchaseId) {
        try {
            const response = await axios.get(this.getUrl(purchaseId) + '/offers/create');
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getPurchaseOfferXml(purchaseId, form) {
        try {
            const response = await axios.post(this.getUrl(purchaseId) + '/offers/offer_xml', { offer: form });
            return response.data?.xml;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storePurchaseOffer(purchaseId, form, xml, certificate) {
        try {
            await axios.post(this.getUrl(purchaseId) + '/offers', {
                form: form,
                xml: xml,
                certificate: certificate
            });
            await store.dispatch('showSuccessToast', 'Ваше предложение к закупочной сессии успешно подано');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async checkProvider(purchaseId) {
        try {
            const response = await axios.get(this.getUrl(purchaseId) + '/check_provider');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

class ImportSubstitutionRequestsApiService extends ReadOnlyApiService {
    constructor() {
        super('import_substitution_requests');
    }

    async fetchWithFilters(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async store(data) {
        try {
            const response = await axios.post(this.getUrl(), data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeReply(importSubstitutionRequestId, data) {
        try {
            const response = await axios.post(this.getUrl(importSubstitutionRequestId), data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

class CountriesDictionaryApiService extends BaseApiService {
    constructor() {
        super('dictionaries/countries');
    }

    async getSelectItems() {
        try {
            const response = await axios.get(this.getUrl('get_select_items'));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

class PurchaseCategoriesDirectoryApiService extends LegacyBaseApiService {
    constructor() {
        super('directories/purchase_categories');
    }

    async getSelectItems() {
        try {
            const response = await axios.get(this.getUrl());
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

class ExternalPurchasesApiService extends LegacyBaseApiService {
    constructor() {
        super('external_purchases');
    }

    // DEPRECATED для закупок вне ИС
    async storeExecutionDocument(purchaseId, formData) {
        try {
            const response = await axios.post(this.getUrl(purchaseId) + '/execution_documents', formData);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    // DEPRECATED для закупок вне ИС
    async deleteExecutionDocument(purchaseId, storedDocumentId) {
        try {
            const response = await axios.delete(this.getUrl(purchaseId) + '/execution_documents/' + storedDocumentId);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async changeStatus(purchaseId, statusId) {
        try {
            const response = await axios.post(this.getUrl(purchaseId) + '/change_status', { status_id: statusId });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export const $api = {
    authentication: new AuthenticationApiService(),
    purchases: new PurchasesApiService(),
    externalPurchases: new ExternalPurchasesApiService(),
    importSubstitutionRequests: new ImportSubstitutionRequestsApiService(),
    dictionaries: {
        countries: new CountriesDictionaryApiService()
    },
    directories: {
        purchaseCategories: new PurchaseCategoriesDirectoryApiService()
    },
    integrations: new IntegrationsApiService(),
    cabinet: cabinet,
    products: products,
    personal: personal,
    proposals: proposals,
    ktru: new KtruApiService(),
    dictionary: new DictionariesApiService(),
    organizations: new OrganizationsApiService(),
    quotationRequests: new QuotationRequestsApiService(),
    async signXML(xml) {
        try {
            return await createXMLSignature(store.getters.getCurrentThumbprint, xml);
        } catch (err) {
            await store.dispatch('showDangerToast', err);
        }
    }
};
