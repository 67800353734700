'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IViewmodelEventPayload from '@core/js/ddriven/application/abstractions/vue/IViewmodelEventPayload';
import { Route } from 'vue-router';
import RouteDataServiceFacade from '@core/js/routing/RouteDataServiceFacade';
import PurchaseRequestFilter from '@core/js/ddriven/application/http/requests/purchases/PurchaseRequestFilter.valueobject';

@Component
export default class NoticeListPageController extends BaseViewModel {
    constructor() {
        super();
        this.name = 'NoticeListPageController';
    }

    data() {
        return {};
    }

    /**
     * Computed
     */
    get storeprefix() {
        return this.makeEntityStorePrefix(this.$route);
    }

    get group() {
        return this.$route.params.group;
    }

    get pagination() {
        return this.$store.getters[`${this.storeprefix}/pagination`];
    }

    get dictionaries() {
        return this.$store.getters[`${this.storeprefix}/dictionaries/all`];
    }

    get currentstatus() {
        return this.$store.getters[`${this.storeprefix}/currentstatus`];
    }

    get list() {
        return this.$store.getters[`${this.storeprefix}/list`];
    }

    get routereset() {
        return this.$store.getters[`${this.storeprefix}/routereset`];
    }

    get requestfilter(): PurchaseRequestFilter {
        return this.$store.getters[`${this.storeprefix}/request`].filter;
    }

    get isListEmpty() {
        return this.list.length < 1;
    }

    /**
     * Methods
     */
    processRequestUpdate(payload: IViewmodelEventPayload) {
        const request = this.$store.getters[`${this.storeprefix}/request`];

        /**
         * When the status or filter changes reset the page to 1.
         */
        if (['status', 'filter'].indexOf(payload.key as string) > -1) {
            this.$store.commit(`${this.storeprefix}/updateRequest`, { key: 'page', value: null });
        }
        this.$store.commit(`${this.storeprefix}/updateRequest`, payload);
        this.$router.push({ path: `${this.$route.path}`, query: request.toPlainObject() });
    }

    /**
     * Prototype methods
     */
    makeEntityStorePrefix(route: Route): string {
        return `rearchitected/groups/fl44/notices`;
    }
}
