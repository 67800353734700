var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-purchase-terms" },
    },
    [
      _c("h3", [_vm._v("Требования к поставщикам")]),
      _vm.pivc().isPurchaseSummarized || _vm.pivc().hasContract
        ? _c("p", [_vm._m(0)])
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
        [
          _c("li", [
            _c("dt", [
              _vm._v(
                "Участником закупки могут быть только субъекты малого предпринимательства (СМП):"
              ),
            ]),
            _c("dd", [
              _vm.$props.requirements.is_only_smb
                ? _c("span", [_vm._v("Да")])
                : _c("span", [_vm._v("Нет")]),
            ]),
          ]),
          _c("li", [
            _c("dt", [
              _vm._v(
                "Заказчик установил требование к участникам по рейтингу в ИС АТМО и может отклонить предложение участника с рейтингом ниже 4,5 балла:"
              ),
            ]),
            _c("dd", [
              _vm.$props.requirements.rating
                ? _c("span", [_vm._v("Да")])
                : _c("span", [_vm._v("Нет")]),
            ]),
          ]),
          _vm.$props.requirements.a31s1p1
            ? _c("li", { staticClass: "atmo-wide-text" }, [
                _c("dt", [
                  _vm._v(
                    "Участник закупки должен соответствовать требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.$props.requirements.a31s1p1
            ? _c("li", [
                _c("dt", [
                  _vm._v(
                    "Участник закупки должен соответствовать следующим требованиям:"
                  ),
                ]),
                _c("dd", [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$props.requirements.a31s1p1_details.description
                      ),
                    },
                  }),
                  _c(
                    "ul",
                    _vm._l(
                      _vm.$props.requirements.a31s1p1_details
                        .supporting_documents,
                      function (document, index) {
                        return _c("li", { key: index }, [
                          _c("span", {
                            domProps: { textContent: _vm._s(index + 1) },
                          }),
                          _vm._v(".  "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(document.description),
                            },
                          }),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.actual_requirements?.a31s1p1 &&
          _vm.is_confirmed_requirement_visible
            ? _c(
                "SupportingDocuments.view",
                {
                  attrs: {
                    documents:
                      _vm.actual_requirements.a31s1p1_details
                        .supporting_documents,
                  },
                },
                [
                  _vm._v(
                    " Участник закупки подтвердил соответствие требованиям п.1 ч.1 ст.31, прикрепив следующие документы"
                  ),
                ]
              )
            : _vm._e(),
          _vm.$props.requirements.a31s1ps3_5ps7_11
            ? _c("li", { staticClass: "atmo-wide-text" }, [
                _c("dt", [
                  _vm._v(
                    "Участник закупки должен соответствовать требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.$props.requirements.a31s1_1
            ? _c("li", { staticClass: "atmo-wide-text" }, [
                _c("dt", [
                  _vm._v(
                    "Участник закупки должен соответствовать требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ"
                  ),
                ]),
              ])
            : _vm._e(),
          _c("li", [
            _vm._m(1),
            _c("dd", [
              _vm.$props.requirements.supplier_reqs_sonko
                ? _c("span", [_vm._v("Да")])
                : _c("span", [_vm._v("Нет")]),
            ]),
          ]),
          _vm.$props.requirements?.additional?.state.is_required
            ? _c("li", [
                _c("dt", [
                  _vm._v(
                    "Участник закупки должен соответствовать следующим дополнительным требованиям:"
                  ),
                ]),
                _c("dd", [
                  _c(
                    "ul",
                    _vm._l(
                      _vm.$props.requirements.additional.requirements,
                      function (requirement, index) {
                        return _c("li", { key: index }, [
                          _c("div", {
                            domProps: {
                              textContent: _vm._s(
                                `${index + 1}. ${requirement.description}`
                              ),
                            },
                          }),
                          _c("div", [
                            _vm._v(
                              "Причина или цель установки: " +
                                _vm._s(requirement.reason)
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.$props.requirements?.additional?.state.is_required &&
          _vm.is_confirmed_requirement_visible
            ? _c(
                "SupportingDocuments.view",
                {
                  attrs: {
                    documents: _vm.actual_requirements.additional.requirements,
                  },
                },
                [_vm._v(" Подтверждающие документы:")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("strong", [
      _c("i", { staticClass: "atmo-icon-checkbox" }),
      _vm._v(
        " Поставщик подтвердил полное соответствие указанным ниже Требованиям."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _vm._v("Участник закупки должен подтвердить, что является "),
      _c(
        "abbr",
        {
          attrs: {
            title: "Социально ориентированная некоммерческая организация",
          },
        },
        [_vm._v("СОНКО")]
      ),
      _vm._v(":"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }