'use strict';

import DeliverableItemRequirementVO, { IRawDeliverableItemRequirement } from './DeliverableItemRequirement.valueobject';
import GSCDetailsVO, { IRawGSCDetails } from './GSCDetails.valueobject';

export interface IRawGSCDictionaryEntry {
    id: number | null;
    name: string;
    okpd: {
        id: number;
        code: string;
        name: string;
    };
    okei: {
        id: number;
        local_symbol: string;
    };
    reg_number: string;
    ktru_group_name: string;
    type: string;
    description: string;
    specs: IRawDeliverableItemRequirement[];
    country_of_origin_name: string;
    is_favorite: boolean;
    favorite_id: number | null;
}

export default class DeliverableDictionaryItemVO {
    id: number | null;
    title: string | null;
    description: string | null;
    okpd_id: number | null;
    okpd_code: string | null;
    okpd_title: string | null;
    okei_id: number | null;
    okei_symbolic_code: string | null;
    gsc_details: GSCDetailsVO | null;
    requirements: DeliverableItemRequirementVO[] | null;
    country_of_origin_name: string | null;
    is_favorite: boolean | null;
    favorite_id: number | null;

    constructor(data?: DeliverableDictionaryItemVO) {
        this.id = data?.id ?? null;
        this.title = data?.title ?? null;
        this.description = data?.description ?? null;
        this.okpd_id = data?.okpd_id ?? null;
        this.okpd_code = data?.okpd_code ?? null;
        this.okpd_title = data?.okpd_title ?? null;
        this.okei_id = data?.okei_id ?? null;
        this.okei_symbolic_code = data?.okei_symbolic_code ?? null;
        this.gsc_details = data?.gsc_details ?? null;
        this.requirements = data?.requirements ?? null;
        this.country_of_origin_name = data?.country_of_origin_name ?? null;
        this.is_favorite = data?.is_favorite ?? null;
        this.favorite_id = data?.favorite_id ?? null;

        Object.seal(this);
    }

    public static fromGSCDictionaryEntry(data: IRawGSCDictionaryEntry): DeliverableDictionaryItemVO {
        const pojo: DeliverableDictionaryItemVO = {
            id: data.id,
            title: data.name,
            description: data.description,
            okpd_id: data.okpd.id,
            okpd_code: data.okpd.code,
            okpd_title: data.okpd.name,
            okei_id: data.okei.id,
            okei_symbolic_code: data.okei.local_symbol,
            gsc_details: GSCDetailsVO.fromGSCDictionaryEntry(data),
            requirements: DeliverableItemRequirementVO.fromArray(data.specs),
            country_of_origin_name: data.country_of_origin_name,
            is_favorite: data.is_favorite,
            favorite_id: data.favorite_id
        };

        return new DeliverableDictionaryItemVO(pojo);
    }
}
