<template>
    <div class="atmo-content-group table-responsive">
        <table class="atmo-content-table">
            <thead>
                <tr>
                    <th>КБК</th>
                    <th>СубКОСГУ</th>
                    <th>Лимиты</th>
                    <th>Лицевой счет</th>
                    <th>Наименование бюджета</th>
                    <th>Код цели</th>
                    <th>Код целевых средств</th>
                    <th>Код расходного обязательства</th>
                    <th>Код бюджетного ассигнования</th>
                    <th>Код РКС</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(kbkitem, index) in $props.kbkitems" v-bind:key="kbkitem.id">
                    <td v-text="kbkitem.code"></td>
                    <td v-text="kbkitem.sub_kosgu_code"></td>

                    <FinancingYearLimitsList.view v-bind:index="index" v-bind:kbkitem="kbkitem" />

                    <td v-text="kbkitem.account"></td>
                    <td v-text="kbkitem.budget_name"></td>
                    <td v-text="kbkitem.target_code"></td>
                    <td v-text="kbkitem.targeted_funds_code"></td>
                    <td v-text="kbkitem.pay_promise_code"></td>
                    <td v-text="kbkitem.budget_allocation_code"></td>
                    <td v-text="kbkitem.rks_code"></td>
                </tr>

                <tr v-if="this.isEmpty" class="atmo-centered">
                    <td colspan="9">
                        <span class="atmo-error">Лимиты по КБК по вашему запросу не найдены.</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import FinancingYearLimitsListView from './FinancingYearLimitsList.view.vue';

export default {
    computed: {
        isEmpty: function () {
            return this.$props.kbkitems.length < 1;
        }
    },
    props: {
        controller: { required: true, type: Object },
        kbkitems: { required: true, type: Array }
    },
    components: {
        'FinancingYearLimitsList.view': FinancingYearLimitsListView
    }
};
</script>
