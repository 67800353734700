import { CabinetModelApiService } from '@/services/backend/cabinet/base';
import axios from 'axios';

export class CabinetQuotationRequestsAPIService extends CabinetModelApiService {
    constructor() {
        super('quotation_requests');
    }

    async getRequestList(param, page) {
        try {
            const response = await axios.get(this.getUrl() + '/requests/' + param, { params: { page: page } });
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async getOfferList(param, page) {
        try {
            const response = await axios.get(this.getUrl() + '/offers/' + param, { params: { page: page } });
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async stopRequest(quotationRequestId) {
        try {
            await axios.delete(this.getUrl() + '/requests/' + quotationRequestId);
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }
}
