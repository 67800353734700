<template>
    <section class="atmo-tab-content">
        <div class="atmo-content-group">
            <h6>Подпись</h6>
            <pre v-text="this.$props.xml"></pre>
        </div>

        <SupplierRequirementsConfirmation.view v-if="this.$props.controller.isSupplier" v-bind:controller="this.$props.controller" v-bind:required="this.$props.controller.purchaseitem.supplier_requirements" v-bind:confirmed="this.$props.controller.supplier_requirements_confirmed" />

        <CertificateInfo.view v-if="this.$props.certificate" v-bind:certificate="this.$props.certificate" />

        <p v-if="this.$props.controller.hasErrors && this.$props.controller.isSupplier" class="atmo-error">Невозможно подписать и сохранить договор. Пожалуйста, заполните раздел "Требования к поставщикам".</p>

        <ul class="atmo-buttons">
            <li><button v-on:click="onoff.off" class="atmo-button-inverse atmo-dimmed">Отмена</button></li>
            <li v-if="!this.$props.controller.isDataSigned && this.$props.certificate">
                <ProgressIndicatingButton.component v-bind:clickhandler="this.$props.controller.signData" v-slot="slotProps">
                    <button v-on:click="slotProps.component.handler" v-bind:disabled="slotProps.component.isprogressing || !$props.controller.isSignContractAllowed" class="atmo-button">Подписать</button>
                </ProgressIndicatingButton.component>
            </li>
            <li v-if="this.$props.certificate && this.$props.controller.isDataSigned && this.$props.controller.isSignContractAllowed">
                <button v-on:click="this.$props.controller.signContract" class="atmo-button">Отправить</button>
                <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
            </li>
        </ul>
    </section>
</template>

<script>
import SupplierRequirementsConfirmationView from './partials/SupplierRequirementsConfirmation.view.vue';
import CertificateInfoView from 'themes/views/overlays/popups/common/partials/CertificateInfo.view.vue';
import ProgressIndicatingButtonComponent from '@lib/js/src/vue/components/progress/button/ProgressIndicatingButton.component.vue';

export default {
    props: {
        controller: { required: true, type: Object },
        onoff: { required: true, type: Object },
        xml: { required: true, type: String },
        certificate: { required: true }
    },
    components: {
        'SupplierRequirementsConfirmation.view': SupplierRequirementsConfirmationView,
        'CertificateInfo.view': CertificateInfoView,
        'ProgressIndicatingButton.component': ProgressIndicatingButtonComponent
    }
};
</script>
