'use strict';

import { RouteConfig, Route } from 'vue-router';

import PurchasesList from 'themes/views/pages/purchases/list/PurchasesList.view.vue';
import PurchaseItemView from 'themes/views/pages/purchases/item/view/PurchaseItemView.view.vue';
import PurchaseItemUpdate from 'themes/views/pages/purchases/item/update/PurchaseItemUpdate.view.vue';
import PurchasesMapView from 'themes/views/pages/purchases/map/PurchasesMap.view.vue';

export default [
    {
        path: '/purchases/map',
        name: 'purchases.map',
        meta: { title: 'Карта закупок' },
        component: PurchasesMapView
    },
    {
        path: '/purchases/grouped/:group',
        name: 'purchases.grouped',
        meta: { title: 'Извещения' },
        component: PurchasesList
    },
    {
        path: '/purchases/grouped/:group/item/:id/view',
        name: 'purchases.grouped.item.view',
        meta: { title: 'Просмотр извещения' },
        component: PurchaseItemView,
        props: true
    },
    {
        path: '/purchases/grouped/:group/item/create',
        name: 'purchases.grouped.item.create',
        meta: { title: 'Создание извещения' },
        component: PurchaseItemUpdate,
        props: true
    },
    {
        path: '/purchases/grouped/:group/item/edit/:id',
        name: 'purchases.grouped.item.edit',
        meta: { title: 'Редактирование извещения' },
        component: PurchaseItemUpdate,
        props: true
    }
] as RouteConfig[];
