'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import isEqual from 'lodash.isequal';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import { IFilterPresets } from '@core/js/ddriven/application/http/requests/dictionaries/KBKDictionaryFilteredRequest.valueobject';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import KBKLimitSelectorPopupController from './KBKLimitSelectorPopupController.viewmodel';
import KBKLimitSpecificationItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/KBKLimitSpecificationItemEditable.valueobject';
import KBKLimitsSpecificationItemsEditableCollection from '@core/js/ddriven/domain/model/purchases/update/KBKLimitsSpecificationItemsEditable.collection';
import KBKLimitDictionaryItemVO from '@core/js/ddriven/domain/model/common/dictionaries/kbk-limits/KBKLimitDictionaryItem.valueobject';

enum Command {
    Add = 'add',
    Replace = 'replace'
}

interface IData {
    list: KBKLimitsSpecificationItemsEditableCollection;
}

type TLimitSelectorResponse = [KBKLimitDictionaryItemVO, number];

@Component
export default class KBKLimitsEditableController extends BaseViewModel {
    public static eventLimitsUpdated = 'public:limitslist:updated';

    [key: string]: any;

    constructor() {
        super();
        this.name = 'KBKLimitsEditableController';
    }

    @Prop({ required: true, type: Object }) readonly initiallist?: KBKLimitsSpecificationItemsEditableCollection;
    @Prop({ required: true, type: Number }) readonly totalamount?: number;
    @Prop({ default: () => {}, type: Object }) readonly limitfilterpresets?: IFilterPresets;

    data(): IData {
        return {
            list: this.$props.initiallist
        };
    }

    /**
     * Computed
     */
    get islistempty(): boolean {
        return this.$data.list.isEmpty();
    }

    get error_amount_distribution(): boolean {
        return (this.$data.list.distributed_amount_total > 0 || this.$props.totalamount > 0) && this.$data.list.distributed_amount_total !== this.$props.totalamount;
    }

    get limits_deliverables_variance(): number {
        return this.$data.list.distributed_amount_total - this.$props.totalamount;
    }

    /**
     * Watch
     */
    @Watch('list', { deep: true })
    onListChanged(newCollection: KBKLimitsSpecificationItemsEditableCollection, oldCollection: KBKLimitsSpecificationItemsEditableCollection) {
        this.$root.$emit(KBKLimitsEditableController.eventLimitsUpdated, { kbkLimits: new KBKLimitsSpecificationItemsEditableCollection(...newCollection.items) });
    }

    // WARNING: the filter presets object changes unexpectedly that resets the KBK limits to empty list.
    // This happens because of the original Create view viewmodel repeatedly overrides
    // its data properties with no reason even with the same data that triggers the presets
    // object change.
    @Watch('limitfilterpresets', { immediate: true, deep: true })
    async onLimitfilterpresetsChanged(newPresets: IFilterPresets, oldPresets: IFilterPresets) {
        // Cases:
        // 1. Create / update purchase - initial load:
        // - change 1: newPresets: purchase_id: null, type: 2, year: 2022; oldPresets: undefined;
        // - change 2: newPresets: purchase_id: null, type: 2, year: 2022; oldPresets === newPresets;
        // - change 3: same as change 2;
        // - change 4: newPresets: id: NEW purchase_id, type: 2, year: 2022; oldPresets: purchase_id: null, type: 2, year: 2022;

        if (!oldPresets || isEqual(newPresets, oldPresets) || (newPresets.purchase_id !== null && oldPresets.purchase_id === null)) {
            return;
        }
        this.$data.list.clear();
    }

    /**
     * Methods
     */
    public removeItem(index: number): void {
        this.$data.list.items.splice(index, 1);
    }

    /**
     * Invoke KBK limit selector popup and process the return results.
     * It can eiether add the new limit to the list or replace the existing item's year limit
     * with its other year limit.
     */
    public async invokeKBKLimitSelectorPopup(item: KBKLimitSpecificationItemEditableVO & PointerEvent) {
        /**
         * WARNING: This is pretty unclear code.
         * Here I  use an array destructuring to simplify resolve() argument.
         * When the promise returns null, I have to transform null into empty array
         * to allow destructuring work.
         */
        const [kbkLimitItem, limitIndex]: TLimitSelectorResponse =
            (await new Promise((resolve) => {
                this.$root.$emit('public:onoff:toggle', {
                    id: KBKLimitSelectorPopupController.popupId,
                    ison: true,
                    data: { presets: Object.assign({}, { ...this.$props.limitfilterpresets, item_id: item.id }) as IFilterPresets },
                    respond: resolve
                } as IOnOffOnToggleEventPayload);
            })) || [];

        if (!kbkLimitItem || limitIndex === undefined) {
            return;
        }

        const command = this.wantAddNewItem(item) && !this.hasLimitItemInList(kbkLimitItem, limitIndex) ? Command.Add : !this.wantAddNewItem(item) && !this.hasLimitItemInList(kbkLimitItem, limitIndex) ? Command.Replace : null;

        command && this[`${command}Item`](kbkLimitItem, limitIndex, item);
    }

    /**
     * Prototype general methods.
     */
    private hasLimitItemInList(item: KBKLimitDictionaryItemVO, limitIndex: number): boolean {
        return this.$data.list.hasItem(item, limitIndex);
    }

    private wantAddNewItem(object: KBKLimitSpecificationItemEditableVO & PointerEvent): boolean {
        return !!object && 'type' in object && object?.type === 'click';
    }

    private addItem(dictionaryItem: KBKLimitDictionaryItemVO, limitIndex: number, item: KBKLimitSpecificationItemEditableVO): void {
        (this.$data as IData).list.items.push(KBKLimitSpecificationItemEditableVO.fromDictionaryItem(dictionaryItem, dictionaryItem.limits[limitIndex].year!));
    }

    private replaceItem(dictionaryItem: KBKLimitDictionaryItemVO, limitIndex: number, item: KBKLimitSpecificationItemEditableVO): void {
        (this.$data as IData).list.replace(
            item.id!,
            KBKLimitSpecificationItemEditableVO.build(KBKLimitSpecificationItemEditableVO.fromDictionaryItem(dictionaryItem, dictionaryItem.limits[limitIndex].year!), {
                amount_assigned: item.amount_assigned
            })
        );
    }

    // DEPRECATED: remove later.
    // private isFilterPresetsValid(presets: IFilterPresets): boolean {
    //     return Object.values(presets).every((value: number | null) => {
    //         return value !== null;
    //     });
    // }
}
