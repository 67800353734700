'use strict';

import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import UserVO, { Types as UserTypes } from '@core/js/ddriven/domain/model/users/User.valueobject';
import PurchaseItemDetailsVO from '@core/js/ddriven/domain/model/purchases/view/item/PurchaseItemDetails.valueobject';
// import EntitiesAPIFacadePartial from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/partials/EntitiesAPIFacadePartial';
import SupplierRequirementsVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierRequirements.valueobject';
import { IFileAttachmentVMEvent } from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import SRDA31s1p1SupportingDocumentVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import PurchaseItemDetailsXMLRequestVO from '@core/js/ddriven/application/http/requests/purchases/PurchaseItemDetailsXMLRequest.valueobject';
import SignContractRequestVO from '@/rearchitected/core/js/ddriven/application/http/requests/contracts/SignContractRequest.valueobject';
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';
import { Certificate, createXMLSignature, getCertificate } from 'crypto-pro';
import store from '@/store';

interface IData {
    user: UserVO | {};
    purchaseitem: PurchaseItemDetailsVO;
    xml: string;
    isDataSigned: boolean;
    supplier_requirements_confirmed: SupplierRequirementsVO;
    supplier_accounting: SupplierAccountingVO;
    customer_contract_number: string | null;
    errors: {
        supplierRequirementsConfirmed: boolean;
    };
    certificate: Certificate | null;
}

@Component({})
export default class SignContractPopupController extends BaseViewModel {
    static popupId: string = 'sign-contract-popup';

    private handlers: {
        downloadFileAttachment?: () => {} | null;
    };

    constructor() {
        super();
        this.name = 'SignContractPopupController';
        this.handlers = {
            downloadFileAttachment: undefined
        };
        Object.seal(this.handlers);
    }

    /**
     * Computed
     */
    get isSupplier(): boolean {
        return this.$data.user.type === UserTypes.supplier;
    }

    // WARNING: Cannot name it isCustomer as it is taken by the legacy on global Vue instance.
    get isACustomer(): boolean {
        return this.$data.user.type === UserTypes.customer;
    }

    get hasCertificatesLoaded(): boolean {
        // @ts-ignore
        return !!this.$data.certificate;
    }

    get isSignContractAllowed(): boolean {
        return !this.isSupplier || (this.isSupplier && !this.hasErrors);
    }

    get hasErrors(): boolean {
        return Object.keys(this.$data.errors).some((key: string) => {
            return this.$data.errors[key] === true;
        });
    }

    get currentParsedCertificate(): Certificate | {} {
        if (!this.hasCertificatesLoaded) {
            return {};
        }
        return this.$data.certificate;
    }

    created(): void {
        this.$root.$on('public:onoff:toggle', this.initializeData);
    }

    data(): IData {
        return {
            user: { organization: {} },
            purchaseitem: {} as PurchaseItemDetailsVO,
            xml: '',
            isDataSigned: false, // NB: Just a renderiing dummy. The actual data is inirialized below.
            supplier_requirements_confirmed: new SupplierRequirementsVO(),
            supplier_accounting: new SupplierAccountingVO(),
            customer_contract_number: null,
            errors: {
                supplierRequirementsConfirmed: true
            },
            certificate: null
        };
    }

    /**
     * Watch
     */
    @Watch('supplier_requirements_confirmed', { deep: true }) onSupplierRequirementsConfirmedChanged(newRequirements: SupplierRequirementsVO) {
        this.$data.errors.supplierRequirementsConfirmed = !newRequirements.isValidAgainstRequired(this.$data.purchaseitem.supplier_requirements);
    }

    /**
     * Methods
     */
    // REFACTOR: This could be placed in the object holding attachment
    // and referenced as file attachment change handler in the view.
    public updateSupplierRequirementsFileAttachments(payload: IFileAttachmentVMEvent): void {
        /**
         * WARNING: Strangely when payload.command=="remove" payload.attachment value is an array, when "add" it is the object.
         * Will have to investigate if it works the same way for normal attachments lists and
         * why it works this way here with slim file attachments list in the popup.
         */
        // @ts-ignore
        this[`${payload.command}FileAttachment`](payload.attachment as any, payload.index);
    }

    public async signData(evt: Event) {
        /**
         * REFACTOR: The following manual replacement of purchaseitem.supplier_requirements property is redundant
         * if I refactor PurchaseItemDetailsXMLRequestVO replacing "purchase" parameter with "supplier_requirements" parameter.
         */
        const purchaseitem = JSON.parse(JSON.stringify(this.$data.purchaseitem));
        this.isSupplier && (purchaseitem.supplier_requirements = this.$data.supplier_requirements_confirmed);
        const response = await ApplicationServiceLocator.get('api').entities.generatePurchaseContractXML(this.$data.purchaseitem.id, new PurchaseItemDetailsXMLRequestVO(purchaseitem));

        // @ts-ignore
        this.$data.xml = await createXMLSignature(store.getters.getCurrentThumbprint, response.data.xml);
        this.$data.isDataSigned = true;
    }

    public async signContract(evt: Event) {
        if (!this.$data.certificate) {
            return;
        }

        const purchaseitem = JSON.parse(JSON.stringify(this.$data.purchaseitem));
        this.isSupplier && (purchaseitem.supplier_requirements = this.$data.supplier_requirements_confirmed);
        this.isACustomer && (purchaseitem.supplier_requirements = null);
        let supplierAccounting = null;
        this.isACustomer && (supplierAccounting = JSON.parse(JSON.stringify(this.$data.supplier_accounting)));
        let customerContractNumber = null;
        this.isACustomer && (customerContractNumber = this.$data.customer_contract_number);

        const request = new SignContractRequestVO(purchaseitem, this.$data.xml, this.$data.certificate, supplierAccounting, customerContractNumber);

        await actWithLoadingSpinner(evt, async () => {
            const response = await ApplicationServiceLocator.get('api').entities.signPurchaseContract(request);
            response.isSuccess &&
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
        });
    }

    /**
     * Prototype general methods
     */
    private async initializeData(payload: IOnOffOnToggleEventPayload) {
        if (!this.isMeAndAskedTurnOn(payload)) {
            return;
        }
        this.$data.isDataSigned = false;

        this.$data.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);

        this.$data.user = payload.data.user;
        this.$data.purchaseitem = payload.data.purchaseitem;
        this.$data.supplier_accounting = payload.data.supplier_accounting;
        this.$data.customer_contract_number = payload.data.customer_contract_number;

        this.handlers.downloadFileAttachment = payload.data.downloadFileAttachment;
        this.$data.supplier_requirements_confirmed = SupplierRequirementsVO.requirementsConfirmedFactory(payload.data.purchaseitem.contract.supplier_requirements);

        // Generate the initial XML.
        const response = await ApplicationServiceLocator.get('api').entities.generatePurchaseContractXML(this.$data.purchaseitem.id, new PurchaseItemDetailsXMLRequestVO());

        this.$data.xml = response.data.xml;
    }

    private isMeAndAskedTurnOn(payload: IOnOffOnToggleEventPayload): boolean {
        return payload.id === SignContractPopupController.popupId && payload.ison === true;
    }

    private addFileAttachment(attachment: FileAttachmentVO, index?: number): void {
        if (index === undefined) {
            return;
        }
        this.$data.supplier_requirements_confirmed.a31s1p1_details.supporting_documents[index].attachment = FileAttachmentVO.fromPOJO(attachment);
    }

    private removeFileAttachment(attachments: FileAttachmentVO[], index?: number): void {
        const foundIndex = this.$data.supplier_requirements_confirmed.a31s1p1_details.supporting_documents.findIndex((document: SRDA31s1p1SupportingDocumentVO) => {
            return document.attachment?.file_id === attachments[0].file_id;
        });
        foundIndex >= 0 && (this.$data.supplier_requirements_confirmed.a31s1p1_details.supporting_documents[foundIndex].attachment = null);
    }
}
