'use strict';

import Vue from 'vue';
import { AxiosResponse } from 'axios';
import ATMOAPIFacade from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/ATMOAPIFacade';
import ToastDataVO, { TToastType } from '@core/js/ddriven/application/services/uitoast/ToastData.valueobject';
import ApplicationServiceLocator from '../../../application/services/ApplicationServiceLocator';
import ATMOAPIResponseNormalizedVO from '../../../ports/adapters/http/outgoing/atmo/ATMOAPIResponseNormalized.valueobject';

interface ICheckDepositResponseData {
    accounting: boolean;
    isAvailable: boolean;
    needDep: string;
}

export default class SupplierService {
    vm: Vue;

    constructor(vm: Vue) {
        this.vm = vm;
    }

    public async checkBeforeProposal(purchaseId: number) {
        /**
         * Send request to POST /account/provider/check_deposit. If error show error toast and return early.
         */
        let response: ATMOAPIResponseNormalizedVO;

        response = await ApplicationServiceLocator.get('api').supplier.checkDeposit(purchaseId);

        if (!response.isSuccess) {
            return;
        }

        /**
         * If not enough deposit balance switch on DepositInvoiceRequest popup
         * via store popup control and return early.
         */
        if (!response.original.response?.data.isAvailable) {
            this.vm.$store.commit('rearchitected/users/setMissingDepositAmount', response.original.response?.data.needDep);
            this.vm.$store.commit('rearchitected/application/popups/set', { deposit_invoice_request: true });
            return;
        }

        /**
         * Send request to GET /purchase/:id/check_provider. If error show error toast and return early.
         */
        response = await ApplicationServiceLocator.get('api').supplier.checkSupplier(purchaseId);

        if (!response.isSuccess) {
            return;
        }

        this.redirectToProposalPage(purchaseId);
    }

    public redirectToProposalPage(purchaseId: number) {
        this.vm.$router.push({ name: 'PurchasesNewOffer', params: { id: purchaseId.toString() } });
    }

    /**
     * Send request to POST /account/mail/sending
     * If OK show success toast and return early
     * Otherwise show error toast and return early
     *
     * @param {string} email
     * @param {number} amount
     * @memberof SupplierService
     */
    public async sendInvoiceToEmail(email: string, amount: number) {
        const response = await ApplicationServiceLocator.get('api').supplier.sendInvoiceToEmail(email, amount);

        response.isSuccess && ApplicationServiceLocator.get('uitoasts').show(new ToastDataVO({ type: TToastType.Success, message: 'Счет отправлен на Ваш email' }));
    }
}
