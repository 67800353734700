'use strict';

import State, { IContractsDictionaries } from './state';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';

const getters = {
    statuses: function (state: State): StandardDictionaryItem[] | null {
        return state.statuses;
    },
    statusesmenu: function (state: State): ExtendedDictionaryItem[] | null {
        return state.statusesmenu;
    },
    types: function (state: State): ExtendedDictionaryItem[] | null {
        return state.types;
    },
    deliverablesgroups: function (state: State): ExtendedDictionaryItem[] | null {
        return state.deliverablesgroups;
    },
    municipalities: function (state: State): StandardDictionaryItem[] | null {
        return state.municipalities;
    },
    all: function (state: State): Record<string, StandardDictionaryItem[] | ExtendedDictionaryItem[] | null> {
        return {
            statuses: state.statuses,
            // REFACTOR: Rename to menustatuses
            statusesmenu: state.statusesmenu,
            // REFACTOR: Rename to types
            types: state.types,
            deliverablesgroups: state.deliverablesgroups,
            municipalities: state.municipalities
        };
    }
};

export default getters;
