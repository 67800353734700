<template>
    <ul>
        <li v-if="$props.requirements.a31s1p1">
            <i class="atmo-icon-checkbox"></i>&nbsp;Поставщик подтвердил, что соответствует требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ.
        </li>
        <li v-if="$props.requirements.a31s1p1">
            <ul>
                <li>Документы, подтверждающие соответствие требованиям:</li>
                <li v-for="(document, index) in $props.requirements.a31s1p1_details.supporting_documents" v-bind:key="index">
                    <ul>
                        <li v-text="`${index + 1}. ${document.description}`"></li>
                        <li>
                            <button v-on:click="pivc().downloadFileAttachment(document.attachment)" class="atmo-button-link">
                                <span>{{ document.attachment.name }}</span>
                            </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li v-if="$props.requirements.a31s1ps3_5ps7_11">
            <i class="atmo-icon-checkbox"></i>&nbsp;Поставщик подтвердил, что соответствует требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ.
        </li>
        <li v-if="$props.requirements.a31s1_1">
            <i class="atmo-icon-checkbox"></i>&nbsp;Поставщик подтвердил, что соответствует требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ.
        </li>
        <li v-if="$props.requirements.supplier_reqs_sonko"><i class="atmo-icon-checkbox"></i>&nbsp;Поставщик подтвердил, что является <abbr title="Социально ориентированная некоммерческая организация">СОНКО</abbr>.</li>
        <li v-if="$props.requirements.additional.state.is_confirmed">
            <i class="atmo-icon-checkbox"></i>&nbsp;Поставщик подтвердил соответствие дополнительным требованиям:
            <ul>
                <li v-for="(requirement, index) in $props.requirements.additional.requirements" v-bind:key="index">
                    <ul>
                        <li v-text="`${index + 1}. ${requirement.description}`"></li>
                        <li>Причина или цель установки требования: {{ requirement.reason }}</li>
                        <li>
                            <button v-on:click="pivc().downloadFileAttachment(requirement.attachment)" class="atmo-button-link">
                                <span>{{ requirement.attachment.name }}</span>
                            </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    inject: ['pivc'],
    props: {
        requirements: { required: true, type: Object }
    }
};
</script>
