var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-content-group table-responsive",
      attrs: { id: "atmo-deliverables" },
    },
    [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _c(
          "tbody",
          [
            _vm._l(this.$props.deliverables, function (deliverable, index) {
              return _c("fragment", { key: index }, [
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(deliverable.gsc_details.registration_number)),
                  ]),
                  _c("td", [_vm._v(_vm._s(deliverable.okpd_code))]),
                  _c(
                    "td",
                    [
                      _c("action-button-small", {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle",
                            value: "collapse-" + deliverable.id,
                            expression: "'collapse-' + deliverable.id",
                          },
                        ],
                        attrs: {
                          title:
                            (!_vm.visible[deliverable.id]
                              ? "Показать"
                              : "Скрыть") + " характеристики",
                          icon: !_vm.visible[deliverable.id]
                            ? "eye"
                            : "eye-slash",
                        },
                      }),
                      _c("b-collapse", {
                        staticClass: "d-none",
                        attrs: { id: "collapse-" + deliverable.id },
                        model: {
                          value: _vm.visible[deliverable.id],
                          callback: function ($$v) {
                            _vm.$set(_vm.visible, deliverable.id, $$v)
                          },
                          expression: "visible[deliverable.id]",
                        },
                      }),
                      _vm._v(" " + _vm._s(deliverable.title) + " "),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(deliverable.okei_symbolic_code))]),
                  _c("td", [
                    _vm._v(_vm._s(deliverable.country_of_origin_name)),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-right text-nowrap" },
                    [
                      _c("action-button-small", {
                        attrs: {
                          title: "Добавить спецификацию в закупку",
                          icon: "plus",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$props.controller.selected(index)
                          },
                        },
                      }),
                      !deliverable.is_favorite
                        ? _c("action-button-small", {
                            staticClass: "ml-1",
                            attrs: {
                              title: "Добавить в избранное",
                              icon: "star",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addDeleteFavoriteItem(deliverable)
                              },
                            },
                          })
                        : _c("action-button-small", {
                            staticClass: "ml-1",
                            attrs: {
                              variant: "outline",
                              title: "Удалить из избранного",
                              icon: "star-fill",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addDeleteFavoriteItem(deliverable)
                              },
                            },
                          }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.visible[deliverable.id],
                        expression: "visible[deliverable.id]",
                      },
                    ],
                  },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "align-text-top",
                        attrs: { colspan: "6" },
                      },
                      [
                        deliverable.description ||
                        (deliverable.requirements &&
                          deliverable.requirements.length > 0)
                          ? [
                              deliverable.description
                                ? _c("p", [
                                    _c("b", [_vm._v("Описание:")]),
                                    _vm._v(
                                      " " + _vm._s(deliverable.description)
                                    ),
                                  ])
                                : _vm._e(),
                              deliverable.requirements &&
                              deliverable.requirements.length > 0
                                ? _c("p", [
                                    _c("b", [_vm._v("Характеристики:")]),
                                  ])
                                : _vm._e(),
                              deliverable.requirements &&
                              deliverable.requirements.length > 0
                                ? _c(
                                    "ul",
                                    _vm._l(
                                      deliverable.requirements,
                                      function (requirement, requirementIndex) {
                                        return _c(
                                          "li",
                                          {
                                            key:
                                              deliverable.id +
                                              "_requirement_" +
                                              requirementIndex,
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(_vm._s(requirement.title)),
                                            ]),
                                            _vm._v(
                                              " - " +
                                                _vm._s(
                                                  requirement.description
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          : [_vm._v("—")],
                      ],
                      2
                    ),
                  ]
                ),
              ])
            }),
            this.isEmpty
              ? _c("tr", { staticClass: "atmo-centered" }, [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _c("span", { staticClass: "atmo-error" }, [
                      _vm._v("Позиции КТРУ по вашему запросу не найдены."),
                    ]),
                    _c(
                      "span",
                      [
                        _vm._v(" Вы можете "),
                        _c(
                          "router-link",
                          {
                            staticClass: "atmo-button-link",
                            attrs: {
                              to: { name: "KtruRequestsCreate" },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "сформировать запрос на добавление позиции КТРУ"
                            ),
                          ]
                        ),
                        _vm._v("."),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "120px" } }, [_vm._v("Рег.номер")]),
        _c("th", { staticStyle: { width: "120px" } }, [_vm._v("ОКПД2")]),
        _c("th", { staticStyle: { "min-width": "400px", width: "400px" } }, [
          _vm._v("Наименование товара (работ, услуг)"),
        ]),
        _c("th", { staticStyle: { width: "100px" } }, [_vm._v("Ед.изм")]),
        _c("th", { staticStyle: { width: "100px" } }, [
          _vm._v("Страна происхождения"),
        ]),
        _c("th", { staticStyle: { width: "20px" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }