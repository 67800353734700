var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "atmo-content-table" }, [
      _c("caption", [_vm._t("default")], 2),
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.$props.documents, function (document, index) {
          return _c("tr", { key: index }, [
            _c("td", {
              staticClass: "atmo-ctc-place",
              domProps: { textContent: _vm._s(index + 1) },
            }),
            _c("td", {
              staticClass: "atmo-ctc-width-400",
              domProps: { textContent: _vm._s(document.description) },
            }),
            _c("td", [
              _c("button", {
                staticClass: "atmo-button-link",
                domProps: { textContent: _vm._s(document.attachment.name) },
                on: {
                  click: function ($event) {
                    _vm.pivc().downloadFileAttachment(document.attachment)
                  },
                },
              }),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "atmo-ctc-place" }, [_vm._v("№")]),
        _c("th", { staticClass: "atmo-ctc-width-400" }, [_vm._v("Описание")]),
        _c("th", [_vm._v("Документ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }