var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-wrapper layout-1" },
    [
      _c(
        "div",
        { staticClass: "layout-inner greyBg" },
        [
          _c("div", { staticClass: "sticky" }, [_c("app-layout-navbar")], 1),
          _c("div", { staticClass: "layout-container greyBg" }, [
            _c("div", { staticClass: "layout-content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "router-transitions container-fluid flex-grow-1 container-p-y p-0",
                },
                [_c("router-view")],
                1
              ),
            ]),
          ]),
          _c("app-layout-footer"),
        ],
        1
      ),
      _c("SVTAOverlayContainer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }