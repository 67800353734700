var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("kbk-limits-editable-controller", {
    attrs: {
      initiallist: this.$props.initiallist,
      totalamount: this.$props.totalamount,
      limitfilterpresets: this.$props.limitfilterpresets,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (klec) {
            return [
              _c(
                "div",
                { staticClass: "atmo-content-group" },
                [
                  _vm._t("title", function () {
                    return [_c("h5", [_vm._v("Лимиты по КБК")])]
                  }),
                  _c("p", [
                    _c("span", [
                      _vm._v(
                        "Добавьте или измените лимиты. Необходимо полностью распределить стоимость спецификации по соответствующим лимитам."
                      ),
                    ]),
                    _vm._v("  "),
                    _c("i", {
                      staticClass: "atmo-quick-helper atmo-icon-question-mark",
                      attrs: {
                        title:
                          'Можно добавить новый лимит года финансирования по КБК (кнопка "Добавить лимит") или сменить лимит для уже добавленного в список КБК на лимит другого года (кнопка с кодом КБК).',
                      },
                    }),
                  ]),
                  _vm._t("info"),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c(
                      "table",
                      {
                        staticClass: "atmo-content-table",
                        class: { "atmo-empty": klec.islistempty },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "atmo-center" }, [
                              _vm._v("№"),
                            ]),
                            _c("th", [_vm._v("КБК")]),
                            _c("th", [_vm._v("СубКОСГУ")]),
                            _c("th", [_vm._v("Подробности")]),
                            _c("th", { staticClass: "atmo-right" }, [
                              _vm._v("Год финансирования"),
                            ]),
                            _c("th", { staticClass: "atmo-right" }, [
                              _vm._v("Доступный лимит, руб."),
                            ]),
                            _c("th", { staticClass: "atmo-right" }, [
                              _vm._v("Общий лимит, руб."),
                            ]),
                            _c("th", { staticClass: "atmo-right" }, [
                              _vm._v("Распределено, руб. "),
                              _c("i", {
                                staticClass:
                                  "atmo-quick-helper atmo-icon-question-mark",
                                attrs: {
                                  title:
                                    "Часть Стоимости спецификации, распределенная на данный Доступный лимит.",
                                },
                              }),
                            ]),
                            _c(
                              "th",
                              { staticClass: "atmo-ctc-command-remove" },
                              [_vm._v(" ")]
                            ),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(klec.list.items, function (limit, index) {
                              return _c(
                                "tr",
                                { key: `${limit.id}${index + 1}` },
                                [
                                  _c("td", {
                                    staticClass: "atmo-center",
                                    domProps: {
                                      textContent: _vm._s(index + 1),
                                    },
                                  }),
                                  _c("td", [
                                    _c("button", {
                                      staticClass:
                                        "atmo-button atmo-button-small",
                                      attrs: {
                                        title:
                                          "Нажмите, чтобы выбрать лимит другого года финансирования внутри этого КБК.",
                                      },
                                      domProps: {
                                        textContent: _vm._s(limit.code),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return klec.invokeKBKLimitSelectorPopup(
                                            limit
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(limit.sub_kosgu_code),
                                    },
                                  }),
                                  _c("KBKLimitDetails.view", {
                                    attrs: { limit: limit },
                                  }),
                                  _c("td", {
                                    staticClass: "atmo-right",
                                    domProps: {
                                      textContent: _vm._s(limit.year),
                                    },
                                  }),
                                  _c("td", { staticClass: "atmo-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatnumber")(
                                          limit.amount_available
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("td", { staticClass: "atmo-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatnumber")(
                                          limit.amount_total
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "atmo-ctc-price-total" },
                                    [
                                      _c("formatted-input-number", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (fim) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "atmo-component-wrapper",
                                                    },
                                                    [
                                                      !fim.isInputFocused
                                                        ? _c("button", {
                                                            staticClass:
                                                              "atmo-button--small atmo-input",
                                                            class: {
                                                              "atmo-invalid":
                                                                !fim.isInputValid ||
                                                                limit.errors
                                                                  .amount_assigned,
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  fim.formatted
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return fim.focus.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                              focus:
                                                                fim.onTriggerFocus,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                fim.isInputFocused,
                                                              expression:
                                                                "fim.isInputFocused",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "atmo-form__field atmo-inverse atmo-small",
                                                          class: {
                                                            "atmo-has-errors":
                                                              limit.errors
                                                                .amount_assigned,
                                                          },
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model.number",
                                                                value:
                                                                  limit.amount_assigned,
                                                                expression:
                                                                  "limit.amount_assigned",
                                                                modifiers: {
                                                                  number: true,
                                                                },
                                                              },
                                                              {
                                                                name: "stop-number-mousewheel",
                                                                rawName:
                                                                  "v-stop-number-mousewheel",
                                                              },
                                                            ],
                                                            attrs: {
                                                              required: "",
                                                              type: "number",
                                                              step: "0.01",
                                                              min: "0.01",
                                                            },
                                                            domProps: {
                                                              value:
                                                                limit.amount_assigned,
                                                            },
                                                            on: {
                                                              blur: [
                                                                fim.processBlurEvent,
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$forceUpdate()
                                                                },
                                                              ],
                                                              keydown:
                                                                fim.processKeydownEvent,
                                                              input: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  )
                                                                    return
                                                                  _vm.$set(
                                                                    limit,
                                                                    "amount_assigned",
                                                                    _vm._n(
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  )
                                                                },
                                                                fim.processInputEvent,
                                                              ],
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                )
                                                                  return null
                                                                return fim.processBlurEvent.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "atmo-ctc-command-remove" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "atmo-button-icon",
                                          attrs: {
                                            title: "Удалить лимит из списка",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return klec.removeItem(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "atmo-icon-trash",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            !klec.islistempty
                              ? _c("tr", { staticClass: "atmo-totals" }, [
                                  _c("td", { attrs: { colspan: "7" } }, [
                                    _vm._v("Распределено итого, руб.:"),
                                  ]),
                                  _c("td", { staticClass: "atmo-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatnumber")(
                                          klec.list.distributed_amount_total
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            !klec.islistempty
                              ? _c("tr", { staticClass: "atmo-totals" }, [
                                  _c("td", { attrs: { colspan: "7" } }, [
                                    _vm._v(
                                      "Стоимость спецификации итого, руб.:"
                                    ),
                                  ]),
                                  _c("td", { staticClass: "atmo-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatnumber")(
                                          _vm.$props.totalamount
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            !klec.islistempty &&
                            klec.limits_deliverables_variance !== 0
                              ? _c("tr", { staticClass: "atmo-totals" }, [
                                  _c("td", { attrs: { colspan: "7" } }, [
                                    _vm._v(
                                      "Отклонение от стоимости спецификации, руб.:"
                                    ),
                                  ]),
                                  _c("td", { staticClass: "atmo-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatnumber")(
                                          klec.limits_deliverables_variance
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            klec.islistempty
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "atmo-center",
                                      attrs: { colspan: "9" },
                                    },
                                    [_vm._v("Лимиты не добавлены")]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                  klec.islistempty
                    ? _c("p", { staticClass: "atmo-error" }, [
                        _vm._v("Добавьте лимиты"),
                      ])
                    : _vm._e(),
                  klec.list.errors.items_amount_assigned
                    ? _c("p", { staticClass: "atmo-error" }, [
                        _vm._v(
                          'Поле "Распределено" не заполнено или превышает доступный лимит для одного или нескольких лимитов.'
                        ),
                      ])
                    : _vm._e(),
                  !klec.islistempty && klec.error_amount_distribution
                    ? _c("p", { staticClass: "atmo-error" }, [
                        _vm._v(
                          "Итоговая сумма распределенных средств из лимитов не совпадает со стоимостью спецификации."
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "atmo-button",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return klec.invokeKBKLimitSelectorPopup.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("Добавить лимит")]
                  ),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }