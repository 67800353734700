'use strict';

import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';

import { IState } from './state';

export default {
    user: function (state: IState): UserVO | null {
        return state.user;
    },

    isGuest: function (state: IState): boolean {
        return state.user === null;
    }
};
