'use strict';

import { Component } from 'vue-property-decorator';

import Vue from 'vue';
import isEmpty from 'lodash.isempty';
import BaseSignPopupController from '@core/js/viewmodels/common/popup/BaseSignPopupController.viewmodel';
import OnOffComposable from '@lib/js/src/vue/composables/OnOff.composable';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import ContractItemDetailsVO from '@core/js/ddriven/domain/model/contracts/ContractItemDetails.valueobject';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import AnnexDisputeProtocolForXMLVO, { IParameters as IForProtocolXMLParameters } from '@core/js/ddriven/domain/model/contracts/annex/dispute/AnnexDisputeProtocolForXML.valueobject';
import StoreContractAnnexDisputeRequestVO, { IParameters as IRequestParameters } from '@core/js/ddriven/application/http/requests/contracts/StoreContractAnnexDisputeRequest.valueobject';
import EntitiesAPIFacadePartial from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/partials/EntitiesAPIFacadePartial';
import AnnexItemEditableVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItemEditable.valueobject';
import AnnexDisputeReplylForXMLVO, { IParameters as IForReplyXMLParameters } from '@core/js/ddriven/domain/model/contracts/annex/dispute/AnnexDisputeReplylForXML.valueobject';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import { Certificate } from 'crypto-pro';

export interface ISignContractAnnexDisputePopupEventPayload extends IOnOffOnToggleEventPayload {
    data: {
        user: UserVO;
        contract: ContractItemDetailsVO;
        annexitemeditable: AnnexItemEditableVO;
        attachments: {
            annex: FileAttachmentVO | null;
            dispute: FileAttachmentVO | null;
        };
        comment: string;
    };
}

@Component
export default class SignContractAnnexDisputePopupController extends BaseSignPopupController {
    [index: string]: any;

    private contract?: ContractItemDetailsVO;

    public static popupId = 'sign-contract-annex-dispute-popup';

    public onoff: OnOffComposable;

    constructor() {
        super();
        this.name = 'SignContractAnnexDisputePopupController';
        this.popupId = SignContractAnnexDisputePopupController.popupId;
        this.onoff = Vue.observable(new OnOffComposable());
    }

    /**
     * Methods
     */
    public async signAnnexDispute(evt: Event) {
        if (isEmpty(this.currentParsedCertificate)) {
            return;
        }

        const args: IRequestParameters = {
            annex: this.$data.custom.annexitemeditable,
            attachments: {
                dispute: this.$data.custom.attachment,
                annex: this.$data.custom.annexitemeditable ? this.$data.custom.annexitemeditable.attachment : null
            },
            comment: this.$data.custom.comment,
            xml: this.$data.xml,
            certificate: this.$data.certificate,
            thumbprint: (this.currentParsedCertificate as Certificate).thumbprint
        };

        const request = new StoreContractAnnexDisputeRequestVO(args);

        this.onoff.on();

        await actWithLoadingSpinner(evt, async () => {
            const response = await ApplicationServiceLocator.get('api').entities.storeContractAnnexDispute(this.contract!.id, this.contract!.pendingAnnex()!.id as number, request);
            response.isSuccess &&
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
        });
    }

    protected initializeCustomData(payload: ISignContractAnnexDisputePopupEventPayload): void {
        this.contract = payload.data.contract;
        this.$data.user = payload.data.user;
        this.$set(this.$data.custom, 'annexitemeditable', payload.data.annexitemeditable);

        const attachment = payload.data.user.is_supplier ? payload.data.attachments.dispute : payload.data.attachments.annex;
        this.$set(this.$data.custom, 'attachment', attachment);

        this.$set(this.$data.custom, 'comment', payload.data.comment);

        const xmlFactoryMethodPrefix = payload.data.user.is_supplier ? 'protocol' : 'reply';

        this.$data.xml = this[`${xmlFactoryMethodPrefix}XMLVO`](payload).toXML();
    }

    private protocolXMLVO(payload: ISignContractAnnexDisputePopupEventPayload): AnnexDisputeProtocolForXMLVO {
        const xmlArgs: IForProtocolXMLParameters = {
            user_id: payload.data.user.id,
            organization_id: payload.data.user.organization.id as number,
            annex_id: (payload.data.contract as ContractItemDetailsVO).pendingAnnex()!.id as number,
            comment: payload.data.comment,
            attachment: payload.data.attachments.dispute as FileAttachmentVO
        };

        return new AnnexDisputeProtocolForXMLVO(xmlArgs);
    }

    private replyXMLVO(payload: ISignContractAnnexDisputePopupEventPayload): AnnexDisputeReplylForXMLVO {
        const xmlArgs: IForReplyXMLParameters = {
            user_id: payload.data.user.id,
            organization_id: payload.data.user.organization.id as number,
            annex_id: (payload.data.contract as ContractItemDetailsVO).pendingAnnex()!.id as number,
            annex: payload.data.annexitemeditable,
            comment: payload.data.comment
        };

        return new AnnexDisputeReplylForXMLVO(xmlArgs);
    }
}
