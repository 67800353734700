<template>
    <div id="atmo-protocol-completion" class="atmo-hash-jump-point atmo-content-group">
        <h3 class="d-flex justify-content-between mb-3">
            Подведение итогов закупочной сессии
            <youtube-link-button href="https://app.hintedme.ru/simulations/bb5c1b62-5285-41e6-92a0-0f3283d2fc23/view" class="float-right" text="Инструкция по подведению итогов" />
        </h3>

        <button v-on:click="pivc().invokeProtocolSummarizationConfirmationPopup()" class="atmo-buttons atmo-button">Сформировать итоговый протокол</button>

        <h5>Список предложений</h5>

        <ProposalDetails.view v-for="(proposal, index) in this.$props.proposals" v-bind:key="index" v-bind:proposal="proposal" v-bind:purchaseitem="purchaseitem" v-bind:index="index" />
    </div>
</template>

<script>
import ProposalDetailsView from './ProposalDetails.view.vue';
import YoutubeLinkButton from '@/components/externalPurchases/YoutubeLinkButton.vue';

export default {
    inject: ['pivc'],
    components: {
        YoutubeLinkButton,
        'ProposalDetails.view': ProposalDetailsView
    },
    props: {
        proposals: { required: true, type: Array },
        purchaseitem: { required: true, type: Object }
    }
};
</script>
