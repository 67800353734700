var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    attrs: {
      src: require("@/rearchitected/themes/atmo/assets/images/logo/logo.svg"),
      alt: "logo",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }