<template>
    <div id="atmo-purchase-terms" class="atmo-hash-jump-point atmo-content-group">
        <h3>Условия закупки</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Предмет закупки:</dt>
                <dd v-text="this.$props.purchaseitem.description"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.purchase_object">
                <dt>Объект закупки:</dt>
                <dd v-text="this.$props.purchaseitem.purchase_object"></dd>
            </li>
            <li>
                <dt>Тип закупки:</dt>
                <dd v-text="this.$props.purchaseitem.type_title"></dd>
            </li>
            <li>
                <dt>Продолжительность закупочной сессии:</dt>
                <dd v-text="this.$props.purchaseitem.duration_type_title"></dd>
            </li>
            <li v-if="!pivc.isPurchaseOutATMO && this.$props.purchaseitem.with_advance && this.$props.purchaseitem.advance_percentage">
                <dt>Размер аванса, %:</dt>
                <dd v-text="this.$props.purchaseitem.advance_percentage"></dd>
            </li>

            <li>
                <dt v-if="pivc.isPurchaseOutATMO">Общая стоимость, руб.:</dt>
                <dt v-else>Начальная цена контракта, руб.:</dt>
                <dd>{{ this.$props.purchaseitem.starting_price | formatnumber }}</dd>
            </li>
            <li>
                <dt v-if="pivc.isPurchaseOutATMO">Дата заключения контракта:</dt>
                <dt v-else>Дата и время размещения закупки:</dt>
                <dd>{{ this.$props.purchaseitem.publication_date | formatdate('withSeconds') }}</dd>
            </li>
            <li v-if="!pivc.isPurchaseOutATMO">
                <dt>Дата и время окончания подачи предложений:</dt>
                <dd>{{ this.$props.purchaseitem.proposal_accept_end_date | formatdate('withSeconds') }}</dd>
            </li>
            <li v-if="!pivc.isPurchaseOutATMO">
                <dt>Планируемая дата заключения контракта:</dt>
                <dd>{{ this.$props.purchaseitem.planned_contract_signature_date | formatdate('onlyDate') }}</dd>
            </li>
            <li v-if="pivc.isPurchaseOutATMO">
                <dt>Дата исполнения контракта:</dt>
                <dd>
                    <span v-if="this.$props.purchaseitem.planned_fulfillment_date">{{ this.$props.purchaseitem.planned_fulfillment_date | formatdate('onlyDate') }}</span>
                    <span v-else>—</span>
                </dd>
            </li>
            <li v-if="!pivc.isPurchaseOutATMO">
                <dt>Приложение заказчика к объявлению:</dt>
                <dd>
                    <ul v-if="this.$props.purchaseitem.attachments.length > 0" class="atmo-list">
                        <li v-for="(attachment, index) in this.$props.purchaseitem.attachments" v-bind:key="index">
                            <a class="atmo-button-link" style="color: var(--green-color) !important" :href="$getAttachmentDownloadLink(attachment.file_id)" target="_blank">{{ attachment.name }}</a>
                        </li>
                    </ul>
                </dd>
            </li>
            <li v-if="!pivc.isPurchaseOutATMO">
                <dt>Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством РФ:</dt>
                <dd>
                    <span v-if="this.$props.purchaseitem.unilateral_contract_termination_info" v-text="this.$props.purchaseitem.unilateral_contract_termination_info"></span>
                    <span v-else>Нет</span>
                </dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { required: true, type: Object },
        purchaseitem: { required: true, type: Object }
    }
};
</script>
