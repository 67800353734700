var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-protocol-completion" },
    },
    [
      _c(
        "h3",
        { staticClass: "d-flex justify-content-between mb-3" },
        [
          _vm._v(" Подведение итогов закупочной сессии "),
          _c("youtube-link-button", {
            staticClass: "float-right",
            attrs: {
              href: "https://app.hinted.me/simulations/6e3eff3f-0f64-4270-ac99-e88aba11356f/view",
              text: "Инструкция по подведению итогов",
            },
          }),
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "atmo-buttons atmo-button",
          on: {
            click: function ($event) {
              _vm.pivc().invokeProtocolSummarizationConfirmationPopup()
            },
          },
        },
        [_vm._v("Сформировать итоговый протокол")]
      ),
      _c("h5", [_vm._v("Список предложений")]),
      _vm._l(this.$props.proposals, function (proposal, index) {
        return _c("ProposalDetails.view", {
          key: index,
          attrs: {
            proposal: proposal,
            purchaseitem: _vm.purchaseitem,
            index: index,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }