var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$props.customer
    ? _c("section", { staticClass: "atmo-content-group" }, [
        _c("h6", [_vm._v("Информация о заказчике")]),
        _c(
          "ul",
          {
            staticClass: "atmo-content-block-2-columns atmo-no-item-separator",
          },
          [
            _c("li", [
              _c("dt", [_vm._v("Полное наименование:")]),
              _c("dd", {
                domProps: { textContent: _vm._s(this.$props.customer.title) },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("ИНН:")]),
              _c("dd", {
                domProps: { textContent: _vm._s(this.$props.customer.inn) },
              }),
            ]),
            this.$props.customer.kpp
              ? _c("li", [
                  _c("dt", [_vm._v("КПП:")]),
                  _c("dd", {
                    domProps: { textContent: _vm._s(this.$props.customer.kpp) },
                  }),
                ])
              : _vm._e(),
            this.$props.customer.ogrn
              ? _c("li", [
                  _c("dt", [_vm._v("ОГРН:")]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(this.$props.customer.ogrn),
                    },
                  }),
                ])
              : _vm._e(),
            _c("li", [
              _c("dt", [_vm._v("Адрес:")]),
              _c("dd", {
                domProps: { textContent: _vm._s(this.$props.customer.address) },
              }),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }