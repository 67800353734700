var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "atmo-nested-section" },
    [
      _c("div", { staticClass: "table-responsive" }, [
        _c(
          "table",
          { staticClass: "atmo-content-table atmo-protocol-completion" },
          [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("td", {
                  staticClass: "atmo-ctc-place",
                  domProps: { textContent: _vm._s(_vm.index + 1) },
                }),
                _c(
                  "td",
                  { staticClass: "atmo-ctc-supplier-link atmo-ctc-width-200" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "atmo-button-link",
                        attrs: {
                          to: {
                            name: "OrganizationShow",
                            params: { id: _vm.proposal.supplier.id },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _vm.proposal.supplier.isLocalRegion()
                          ? _c("span", [
                              _c("img", {
                                attrs: {
                                  src: require("@theme/assets/images/icons/local-supplier-badge.svg"),
                                  alt: "Local supplier badge",
                                },
                              }),
                              _vm._v("  "),
                            ])
                          : _vm._e(),
                        _vm._v(
                          " " + _vm._s(_vm.proposal.supplier.title_brief) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("td", { staticClass: "atmo-ctc-price-total" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatnumber")(_vm.proposal.total_price))
                  ),
                ]),
                _c("td", { staticClass: "atmo-ctc-submit-date" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.proposal.submitted_at,
                        "withSeconds"
                      )
                    )
                  ),
                ]),
                _c("td", { staticClass: "atmo-ctc-center" }, [
                  _vm.proposal.supplier.ratings.atmo
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.proposal.supplier.ratings.atmo
                          ),
                        },
                      })
                    : _c("span", [_vm._v("Нет")]),
                ]),
                _c("td", { staticClass: "atmo-ctc-center" }, [
                  _vm.proposal.supplier.hasConturFocusRating()
                    ? _c(
                        "ul",
                        { staticClass: "atmo-contur-focus-ratings-list" },
                        _vm._l(
                          _vm.proposal.supplier.ratings.contur_focus,
                          function (value, index) {
                            return _c("li", { key: index }, [
                              _c("span", {
                                staticClass: "atmo-contur-focus-rating",
                                class: `atmo-${value}`,
                              }),
                            ])
                          }
                        ),
                        0
                      )
                    : _c("span", [_vm._v("Нет")]),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _vm.proposal.is_declined
        ? _c("p", [
            _c("b", [_vm._v("Предложение отклонено.")]),
            _vm._v(" Причина отклонения: "),
            _c("span", {
              domProps: { textContent: _vm._s(_vm.proposal.decline_reason) },
            }),
          ])
        : _vm._e(),
      _c("on-off", {
        attrs: { options: { onEsc: false, onBody: false } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (onoff) {
              return [
                _c("div", { staticClass: "atmo-content-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "atmo-buttons atmo-button--small",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return onoff.toggle.apply(null, arguments)
                        },
                      },
                    },
                    [
                      onoff.ison
                        ? _c("span", [_vm._v("Скрыть")])
                        : _c("span", [_vm._v("Показать")]),
                      _vm._v(" подробности "),
                    ]
                  ),
                  onoff.ison
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "atmo-content-block-2-columns atmo-no-item-separator",
                        },
                        [
                          _c("li", [
                            _c("dt", [_vm._v("Контакты:")]),
                            _c("dd", [
                              _c("ul", [
                                _c("li", [
                                  _vm._v("ФИО: "),
                                  _c("b", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.proposal.supplier.contacts.name
                                      ),
                                    },
                                  }),
                                ]),
                                _c("li", [
                                  _vm._v(" Телефон: "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: `tel:+${_vm.proposal.supplier.contacts.phone}`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$parsePhone(
                                            _vm.proposal.supplier.contacts.phone
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("li", [
                                  _vm._v("Email: "),
                                  _c("a", {
                                    attrs: {
                                      href: `mailto:${_vm.proposal.supplier.contacts.email}`,
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.proposal.supplier.contacts.email
                                      ),
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm.proposal.organization_card_attachment
                            ? _c("li", [
                                _c("dt", [_vm._v("Карточка предприятия:")]),
                                _c("dd", [
                                  _c("ul", [
                                    _c("li", [
                                      _c("a", {
                                        staticClass: "atmo-button-link",
                                        attrs: {
                                          href: _vm.$getAttachmentDownloadLink(
                                            _vm.proposal
                                              .organization_card_attachment
                                              .file_id
                                          ),
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.proposal
                                              .organization_card_attachment.name
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _c("li", [
                            _c("dt", [_vm._v("Требования к поставщикам:")]),
                            _c(
                              "dd",
                              [
                                _c("SupplierRequirements.view", {
                                  attrs: {
                                    requirements:
                                      _vm.proposal.supplier_requirements,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("li", [
                            _c("dt", [
                              _vm._v(
                                "Дополнительные документы, приложенные поставщиком к предложению:"
                              ),
                            ]),
                            _c("dd", [
                              _vm.proposal.hasAttachments()
                                ? _c(
                                    "ul",
                                    _vm._l(
                                      _vm.proposal.attachments,
                                      function (attachment, index) {
                                        return _c("li", { key: index }, [
                                          _c("button", {
                                            staticClass: "atmo-button-link",
                                            domProps: {
                                              textContent: _vm._s(
                                                attachment.name
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm
                                                  .pivc()
                                                  .downloadFileAttachment(
                                                    attachment
                                                  )
                                              },
                                            },
                                          }),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _c("span", [_vm._v("Отсутствуют")]),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("ul", { staticClass: "atmo-buttons" }, [
        _c("li", [
          _c(
            "button",
            {
              staticClass: "atmo-button-inverse atmo-dimmed",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm
                    .pivc()
                    .downloadOrganizationReport(_vm.proposal.supplier, $event)
                },
              },
            },
            [_vm._v("Скачать аналитический отчет об организации")]
          ),
          _vm._m(1),
        ]),
        !_vm.proposal.is_declined
          ? _c("li", [
              _c(
                "button",
                {
                  staticClass: "atmo-button atmo-accented",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm
                        .pivc()
                        .invokeDeclineProposalPopup(
                          _vm.proposal,
                          _vm.purchaseitem.supplier_requirements,
                          $event
                        )
                    },
                  },
                },
                [_vm._v("Отклонить")]
              ),
              _vm._m(2),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "atmo-ctc-place" }, [_vm._v("№")]),
        _c("th", { staticClass: "atmo-ctc-width-200" }, [_vm._v("Поставщик")]),
        _c("th", { staticClass: "atmo-ctc-price-total" }, [
          _vm._v("Стоимость, руб."),
        ]),
        _c("th", { staticClass: "atmo-ctc-submit-date" }, [
          _vm._v("Дата и время подачи"),
        ]),
        _c("th", { staticClass: "atmo-ctc-center" }, [_vm._v("Рейтинг ИС")]),
        _c("th", { staticClass: "atmo-ctc-center" }, [
          _vm._v("Рейтинг Контур.Фокус"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "atmo-spinner" }, [
      _c("img", {
        attrs: {
          src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "atmo-spinner" }, [
      _c("img", {
        attrs: {
          src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }