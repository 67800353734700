var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group" },
    [
      _c("h5", [_vm._v("Изменить статус закупки")]),
      _c("purchase-status-change-widget-controller", {
        attrs: {
          initialid: _vm.$props.status.id,
          contractid: this.$props.contractid,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (pscwc) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "atmo-purchase-status-widget atmo-form__field atmo-as-input",
                  },
                  [
                    _c("p", { domProps: { textContent: _vm._s(pscwc.item) } }),
                    _c("dropdown-select", {
                      attrs: {
                        list: _vm.$props.contractstatuses,
                        initialid: _vm.$props.status.id,
                      },
                      on: { "dropdown:select:selected": pscwc.manageSelection },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ds) {
                              return [
                                _c("on-off", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (onoff) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "atmo-dropdown-select",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return onoff.toggle.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    ds.hasSelected
                                                      ? _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              ds.text
                                                            ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    !onoff.ison
                                                      ? _c("i", {
                                                          staticClass:
                                                            "atmo-icon-angle-down",
                                                        })
                                                      : _c("i", {
                                                          staticClass:
                                                            "atmo-icon-angle-up",
                                                        }),
                                                  ]
                                                ),
                                                _c(
                                                  "ul",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: onoff.ison,
                                                        expression:
                                                          "onoff.ison",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "atmo-dropdown",
                                                  },
                                                  _vm._l(
                                                    ds.list,
                                                    function (item) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.id,
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return ds.select(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.title
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("ul", { staticClass: "atmo-buttons" }, [
                      _c("li", [
                        _c(
                          "button",
                          {
                            staticClass: "atmo-button",
                            attrs: { disabled: !pscwc.canUpdateStatus },
                            on: { click: pscwc.updateStatus },
                          },
                          [_vm._v("Изменить статус")]
                        ),
                        _vm._v("  "),
                        pscwc.isloading
                          ? _c("div", { staticClass: "atmo-spinner" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
                                  alt: "",
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }