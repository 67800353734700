'use strict';

import fl44 from './fl44/index';
import fl223 from './fl223/index';
import commercial from './commercial/index';
import global from './global/index';

const GroupsStoreModule = {
    namespaced: true,

    modules: {
        fl44,
        fl223,
        commercial,
        global
    }
};

export default GroupsStoreModule;
