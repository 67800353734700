import { BaseApiService } from '@/services/backend/base';
import axios from 'axios';

export class Fl44ProposalsApiService extends BaseApiService {
    constructor() {
        super('proposals/fl44');
    }

    async get(id) {
        try {
            const response = await axios.get(this.getUrl(id));
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
