'use strict';

import { Component } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import { noop } from 'lodash';
import DownloadConfigFactory from '../../../ddriven/domain/services/downloads/DownloadConfigFactory';
import FileAttachmentVO from '../../../ddriven/domain/model/common/FileAttachment.valueobject';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import ApplicationServiceLocator from '../../../ddriven/application/services/ApplicationServiceLocator';
import Vue from 'vue';
import OnOffComposable from '@lib/js/src/vue/composables/OnOff.composable';
import ToastMixins from '@/mixins/toast';
import { Certificate, getCertificate } from 'crypto-pro';
import { $api } from '@/services/backend/api';

export interface IData {
    user: UserVO | {};
    xml: string;
    isDataSigned: boolean;
    custom: Record<string, any>;
    certificate: Certificate | null;
}

@Component({
    mixins: [ToastMixins]
})
export default class BaseSignPopupController extends BaseViewModel {
    protected popupId: string | null;
    protected onoff: OnOffComposable;

    constructor() {
        super();
        this.popupId = null;
        this.name = 'BaseSignPopupController';
        this.onoff = Vue.observable(new OnOffComposable());
    }

    /**
     * Throw if there is no popupId property is set via child object constructor.
     */
    beforeCreate(): void {
        if (this.name !== BaseSignPopupController.name && this.popupId === null) {
            throw new Error(`ATMO Exception: The viewmodel ${this.constructor.name} must set "popupId"`);
        }
    }

    created(): void {
        this.$root.$on('public:onoff:toggle', (payload: IOnOffOnToggleEventPayload) => {
            if (!this.isMeAndAskedTurnOn(payload)) {
                return;
            }
            this.initializeData(payload);
        });
    }

    data(): IData {
        return {
            // ison: false,
            user: { organization: {} },
            xml: '',
            isDataSigned: false,
            custom: {},
            certificate: null
        };
    }

    /**
     * Computed
     */
    get hasCertificatesLoaded(): boolean {
        // @ts-ignore
        return !!this.$data.certificate;
    }

    get currentParsedCertificate(): Certificate | {} {
        if (!this.hasCertificatesLoaded) {
            return {};
        }
        return this.$data.certificate;
    }

    /**
     * Methods
     */
    public async signData(evt: Event) {
        actWithLoadingSpinner(evt, async () => {
            // @ts-ignore
            this.$data.xml = await $api
                .signXML(this.$data.xml)
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    // @ts-ignore
                    this.pushToast({
                        text: error.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                    return false;
                });
            this.$data.isDataSigned = !!this.$data.xml;
        });
    }

    public downloadFileAttachment(attachment: FileAttachmentVO) {
        const config = DownloadConfigFactory.fromFileAttachment(attachment);
        ApplicationServiceLocator.get('download').fileByBrowser(config);
    }

    /**
     * Prototype general methods
     */
    private async initializeData(payload: IOnOffOnToggleEventPayload) {
        this.onoff.on();
        this.$data.isDataSigned = false;
        this.$data.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);
        // @ts-ignore
        this.$data.user = payload.data?.user ?? null;
        this.initializeCustomData(payload);
    }

    /**
     * Replace in child if needed.
     */
    protected initializeCustomData(payload: IOnOffOnToggleEventPayload): void {
        noop();
    }

    private isMeAndAskedTurnOn(payload: IOnOffOnToggleEventPayload): boolean {
        return payload.id === this.popupId && payload.ison === true;
    }
}
