'use strict';

import PurchaseItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditable.valueobject';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import SRDA31s1p1SupportingDocumentVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import { TSelfPOJO as DIETSelfPOJO } from '@core/js/ddriven/domain/model/common/deliverable/update/general/DeliverableItemEditable.valueobject';
import GSCDetailsVO from '@core/js/ddriven/domain/model/common/deliverable/GSCDetails.valueobject';
import StandardDictionaryItem from '../../../../domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import KBKLimitSpecificationItemEditableVO from '../../../../domain/model/purchases/update/KBKLimitSpecificationItemEditable.valueobject';
import SupplierRequirementAdditionalVO, { TRawAdditional } from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierRequirementAdditional.valueobject';
import { IDeliveryAddress } from '@core/js/ddriven/domain/model/contracts/ContractItem.valueobject';
import { Certificate } from 'crypto-pro';

type IFile = {
    isMain: boolean;
    id: string;
    name: string;
    path: string;
    type: string;
};

export type ISpec = {
    count: number;
    price: number;
    ktru_item_id: number;
};

type ISpecInner = {
    name: string;
    value: string;
};

type IKTRU = {
    id: number | null;
    reg_number: string;
    ktru_group_name: string;
    name: string;
    ktru_type_name: string;
    specs: ISpecInner[];
};

export type TParamDictionaries = {
    durations: ExtendedDictionaryItem[];
    urgencyreasons: StandardDictionaryItem[];
};

export default class PurchaseItemEditableRequestVO {
    id: number | null;
    xml: string | null;
    thumbprint: string | null;
    certificate: Certificate | null;

    form: {
        additional_contact_info: string | null;
        purchase_comment: string | null;
        without_limits: boolean;
        purchase_limit_year: number | null;
        purchase_category_id: number | null;
        purchase_object: string | null;
        with_advance: boolean;
        advance_percentage: number;
        order_type_id: number | null;
        purchase_length: number | null;
        planned_contract_date: string | null;
        planned_end_date: string | null;
        start_price: number | null;
        additional_info: string | null;

        only_smp: boolean;
        supplier_reqs_sonko: boolean;
        delivery_addresses: IDeliveryAddress[] | [];
        order_plan: string | null;

        fast_purchase_justification: string | null;
        fast_purchase_justification_stored_document: {
            id: string;
            name: string;
            type: string;
        } | null;

        main_file: IFile | null;
        files: IFile[];
        specs: ISpec[];

        // These are constants
        delivery_address: string | null;
        delivery_region: string | null;
        purchase_start: string | null;
        purchase_end: string | null;
        payment_deadline: string | null;
        payment_deadline_days: number;

        supplier_reqs_a: boolean;
        supplier_reqs_a_text: string | null;
        supplier_reqs_b: boolean;
        supplier_reqs_c: boolean;
        supplier_reqs_rating: boolean;
        supplier_required_documents: string[];

        // NB: More supplier requirements added.
        supplier_requirements: {
            additional: TRawAdditional;
        };

        limits: {
            id: number;
            year: number;
            amount_assigned: number;
        }[];
    };

    constructor(data: PurchaseItemEditableVO, dictionaries: TParamDictionaries, xml?: string, certificate?: any) {
        this.id = data.id;
        this.xml = xml ?? null;
        this.thumbprint = certificate?.thumbprint ?? null;
        this.certificate = certificate ?? null;

        this.form = {
            additional_contact_info: data.additional_contact_info,
            purchase_comment: data.note,
            without_limits: data.without_limits,
            purchase_limit_year: data.limit_year,
            purchase_category_id: data.deliverable_group_id,
            purchase_object: data.purchase_object,
            with_advance: data.with_advance ?? false,
            advance_percentage: data.advance_percentage ?? 0,
            order_type_id: data.type_id,
            purchase_length: (ExtendedDictionaryItem.findById(data.duration_id as number, dictionaries.durations)! as ExtendedDictionaryItem).value as number,
            planned_contract_date: data.planned_contract_signature_date,
            planned_end_date: data.planned_contract_fulfillment_date,
            start_price: data.starting_price,
            additional_info: data.unilateral_contract_termination_info,
            delivery_addresses: data.delivery_addresses,
            order_plan: data.delivery_schedule,

            fast_purchase_justification: (ExtendedDictionaryItem.findById(data.urgency_reason.id, dictionaries.urgencyreasons)! as ExtendedDictionaryItem)?.title ?? '',
            fast_purchase_justification_stored_document:
                data.urgency_reason.attachment && 'file_id' in data.urgency_reason.attachment
                    ? {
                          id: data.urgency_reason.attachment.file_id as string,
                          name: data.urgency_reason.attachment.name,
                          type: data.urgency_reason.attachment.storage
                      }
                    : null,

            main_file: data.contract_draft_attachment.length < 1 ? null : this.fromFileAttachment(data.contract_draft_attachment[0], true),

            files: data.notice_supplementary_attachments.map((attachment: FileAttachmentVO): IFile => {
                return this.fromFileAttachment(attachment);
            }),

            specs: data.specification.deliverables.map((deliverable: DIETSelfPOJO, index: number): ISpec => {
                return {
                    count: deliverable.quantity as number,
                    price: deliverable.price_per_unit as number,
                    ktru_item_id: (deliverable.gsc_details as GSCDetailsVO)!.id
                };
            }),

            // These are constants
            delivery_address: null,
            delivery_region: null,
            purchase_start: null,
            purchase_end: null,
            payment_deadline: null,
            payment_deadline_days: 0,

            // Supplier Requirements.
            only_smp: data.supplier_requirements.is_only_smb,
            supplier_reqs_sonko: data.supplier_requirements.supplier_reqs_sonko,
            supplier_reqs_a: data.supplier_requirements.a31s1p1,
            supplier_reqs_a_text: data.supplier_requirements.a31s1p1 ? (data.supplier_requirements.a31s1p1_details.description as string) : null,
            supplier_reqs_b: data.supplier_requirements.a31s1ps3_5ps7_11,
            supplier_reqs_c: data.supplier_requirements.a31s1_1,
            supplier_reqs_rating: data.supplier_requirements.rating,
            supplier_required_documents: data.supplier_requirements.a31s1p1
                ? data.supplier_requirements.a31s1p1_details.supporting_documents.map((document: SRDA31s1p1SupportingDocumentVO) => {
                      return document.description as string;
                  })
                : [],

            supplier_requirements: {
                additional: SupplierRequirementAdditionalVO.toRequestPayload(data.supplier_requirements.additional)
            },

            limits: data.specification.kbkLimits.items.map((limit: KBKLimitSpecificationItemEditableVO) => {
                return {
                    id: limit.id as number,
                    year: limit.year as number,
                    amount_assigned: limit.amount_assigned
                };
            })
        };
    }

    private fromFileAttachment(attachment: FileAttachmentVO, isMain: boolean = false): IFile {
        return {
            isMain: isMain,
            id: attachment.file_id as string,
            name: attachment.name,
            path: `documents/${attachment.file_id}.pdf`,
            type: attachment.storage
        };
    }
}
