'use strict';

import DadataAPIFacade, { DadataAPIConfig, DadataLocationsBoost } from '@core/js/ddriven/ports/adapters/http/outgoing/DadataAPIFacade';
import { IRawDadataOrganization, TOrganizationTypes } from '@core/js/ddriven/domain/model/misc/organization-reliability-report/OrganizationReliabilityReportOrganization.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import ToastDataVO from '@core/js/ddriven/application/services/uitoast/ToastData.valueobject';

export default class DadataService {
    private _apifacade: DadataAPIFacade;
    private _apiconfig?: DadataAPIConfig;

    constructor(apiURL: string, token: string) {
        this._apifacade = new DadataAPIFacade(apiURL, token);
    }

    public async iplocate(): Promise<DadataLocationsBoost> {
        const response = await this._apifacade.iplocate();

        const kladr_id: string = response.data.location ? response.data.location.data.kladr_id : '';

        return { kladr_id: kladr_id };
    }

    public async addresses(query: string, useConfig?: boolean): Promise<string[]> {
        const config = useConfig ? this._apiconfig : undefined;

        const response = await this._apifacade.addresses(query, config);

        return response.data.suggestions.map((suggestion: Record<string, unknown>) => {
            return suggestion.value;
        });
    }

    public async findByInn(inn: string, organizationType: TOrganizationTypes): Promise<IRawDadataOrganization | undefined> {
        const response = await this._apifacade.findByInn(inn, organizationType);

        if (response.status !== 200) {
            ApplicationServiceLocator.get('uitoasts').show(ToastDataVO.unknownError());
            throw new Error(`SVTA Error: Undexpected Dadata response status ${response.status}`);
        }

        const data = response.status === 200 && response.data.suggestions.length > 0 ? response.data.suggestions[0].data : undefined;

        return data;
    }

    public config(config: DadataAPIConfig): void {
        this._apiconfig = config;
    }
}
