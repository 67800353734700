'use strict';

import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import SignContractDisputeProtocolPopupController from '@core/js/viewmodels/purchases/item/view/widgets/contract/disputes/sign/SignContractDisputeProtocolPopupController.viewmodel';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import { Command, IFileAttachmentVMEvent } from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import { trim } from 'lodash';

export interface IDisputeResolutionWidgetData {
    purchaseId: number;
    contractAttachmentFileId: string | null;
    attachment: FileAttachmentVO;
    comment: string | null;
}

export interface ISignDisputeResolutionEventPayload extends IOnOffOnToggleEventPayload {
    data: IDisputeResolutionWidgetData;
}

interface IData {
    attachments: {
        contract: FileAttachmentVO | null;
        dispute: FileAttachmentVO | null;
    };
    comment: string | null;
}

@Component
export default class ContractDisputeResolutionWidgetController extends BaseViewModel {
    [index: string]: any;

    constructor() {
        super();
        this.name = 'ContractDisputeResolutionWidgetController';
    }

    @Prop({ required: true, type: Number }) readonly purchaseid!: number;

    data(): IData {
        return {
            attachments: {
                contract: null,
                dispute: null
            },
            comment: null
        };
    }

    /**
     * Computed
     */
    get isContractAttachmentEmpty(): boolean {
        return this.$data.attachments.contract === null;
    }

    get isCommentEmpty(): boolean {
        return !(this.$data.comment !== null && trim(this.$data.comment).length > 0);
    }

    /**
     * Methods
     */
    public handleAttachment(event: IFileAttachmentVMEvent): void {
        const value = event.command === Command.Add ? event.attachment : null;
        this.$data.attachments[event.origin] = value;
    }

    public invokeSignContractDisputeProtocol() {
        const payload: ISignDisputeResolutionEventPayload = {
            id: SignContractDisputeProtocolPopupController.popupId,
            ison: true,
            data: {
                purchaseId: this.$props.purchaseid,
                contractAttachmentFileId: !this.isContractAttachmentEmpty ? this.$data.attachments.contract.file_id : null,
                attachment: this.$data.attachments.dispute,
                comment: this.$data.comment
            }
        };
        this.$root.$emit('public:onoff:toggle', payload);
    }

    /**
     * General prototype methods.
     */
}
