var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("on-off", {
    attrs: { options: { onEsc: false, onBody: false } },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (onoff) {
          return [
            _c("section", { staticClass: "atmo-annex py-0" }, [
              _c("header", [
                _c(
                  "ul",
                  {
                    staticClass: "atmo-buttons my-4",
                    staticStyle: { "text-transform": "unset !important" },
                  },
                  [
                    _c("li", [
                      _c(
                        "button",
                        {
                          staticClass: "atmo-button",
                          attrs: {
                            title: "Показать/скрыть дополнительное соглашение",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return onoff.toggle.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(" Дополнительное соглашение № "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$props.index + 1),
                            },
                          }),
                          _vm._v("  "),
                          onoff.ison
                            ? _c("span", { staticClass: "atmo-icon-angle-up" })
                            : _c("span", {
                                staticClass: "atmo-icon-angle-down",
                              }),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _vm.annex.isSignedByBoth()
                        ? _c(
                            "button",
                            {
                              staticClass: "atmo-button-icon",
                              attrs: {
                                title:
                                  "Нажмите, чтобы скачать дополнительное соглашение в формате PDF",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$props.pivc.downloadAnnex(
                                    _vm.annex,
                                    _vm.$props.index + 1
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "atmo-icon-pdf" })]
                          )
                        : _vm._e(),
                    ]),
                    _c("li", { staticClass: "align-middle my-auto fs-14" }, [
                      _vm.annex.isSignedByBoth()
                        ? _c("span", [
                            _c("b", [
                              _vm._v("Подписано поставщиком и заказчиком"),
                            ]),
                          ])
                        : _vm.annex.is_signed_by.supplier
                        ? _c("span", [
                            _c("b", [_vm._v("Подписано поставщиком")]),
                          ])
                        : _c("span", [
                            _c("b", [
                              _vm._v("Ожидает подписи "),
                              !_vm.annex.is_signed_by.supplier
                                ? _c("span", [_vm._v("поставщика, ")])
                                : _vm._e(),
                              !_vm.annex.is_signed_by.customer
                                ? _c("span", [_vm._v("заказчика")])
                                : _vm._e(),
                            ]),
                          ]),
                    ]),
                  ]
                ),
              ]),
              onoff.ison
                ? _c(
                    "main",
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "atmo-content-block-2-columns atmo-no-item-separator",
                        },
                        [
                          _c("li", { staticClass: "mb-2 pb-2" }, [
                            _c("dt", [_vm._v("Общая стоимость, руб.:")]),
                            _c("dd", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatnumber")(
                                    _vm.$props.annex.price_total
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("li", { staticClass: "mb-2 pb-2" }, [
                            _c("dt", [
                              _vm._v(
                                "Дата заключения дополнительного соглашения:"
                              ),
                            ]),
                            _c("dd", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatdate")(
                                      _vm.$props.annex.date,
                                      "onlyDate"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c("AnnexAttachments.view", {
                        attrs: {
                          annex: _vm.$props.annex,
                          pivc: _vm.$props.pivc,
                        },
                      }),
                      _c(
                        "DeliverablesList.view",
                        {
                          attrs: {
                            deliverables: _vm.$props.annex.deliverables,
                            origin: "contract",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "дополнительного соглашения №" +
                                _vm._s(_vm.$props.index + 1)
                            ),
                          ]),
                        ]
                      ),
                      !_vm.$props.pivc.purchaseitem.without_limits &&
                      _vm.$props.pivc.features.kbk_limits &&
                      _vm.pivc.isUserEngagedCustomer &&
                      _vm.pivc.isContractSignedByCustomer
                        ? _c("KBKLimitsList.view", {
                            attrs: { limits: _vm.$props.annex.kbk_limits },
                          })
                        : _vm._e(),
                      !_vm.$props.pivc.purchaseitem.without_limits &&
                      _vm.$props.pivc.features.kbk_limits &&
                      _vm.canEditLimitsOnActualAnnex
                        ? _c("KBKLimitsAutonomousUpdateWidget.view", {
                            staticClass: "atmo-is-nested",
                            attrs: {
                              entity: _vm.$props.annex,
                              limitfilterpresets: {
                                year: _vm.$props.pivc.purchaseitem.limit_year,
                                purchase_type_id:
                                  _vm.$props.pivc.purchaseitem.type_id,
                                purchase_id: _vm.$props.pivc.purchaseitem.id,
                              },
                              purchaseid: _vm.$props.pivc.purchaseitem.id,
                            },
                          })
                        : _vm._e(),
                      !_vm.$props.pivc.purchaseitem.without_limits &&
                      _vm.$props.pivc.features.kbk_limits &&
                      _vm.canEditLimitsOnPedingAnnex
                        ? _c("KBKLimitsEditableList.view", {
                            staticClass: "atmo-is-nested",
                            attrs: {
                              initiallist: _vm.$data.kbk_limits,
                              limitfilterpresets: {
                                year: _vm.$props.pivc.purchaseitem.limit_year,
                                purchase_type_id:
                                  _vm.$props.pivc.purchaseitem.type_id,
                                purchase_id: _vm.$props.pivc.purchaseitem.id,
                              },
                              totalamount: _vm.$props.annex.price_total,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("h5", [_vm._v("Измените лимиты")]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                      _vm.$props.annex.isPending() &&
                      _vm.$props.pivc.isUserEngagedCustomer &&
                      _vm.$props.pivc.hasContractPendingAnnexes &&
                      _vm.$props.pivc.pendingAnnex.isSignedBySupplier() &&
                      !_vm.$props.pivc.pendingAnnex.isSignedByCustomer() &&
                      !_vm.$props.pivc.purchaseitem.without_limits &&
                      _vm.$props.pivc.features.kbk_limits
                        ? _c(
                            "ContractSupplierAccounting.view",
                            {
                              attrs: {
                                initialData:
                                  _vm.$props.annex.supplier_accounting,
                              },
                              on: { select: _vm.updateSupplierAccounting },
                            },
                            [
                              _c("span", [
                                _vm._v("Банковские реквизиты поставщика"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.$props.annex.supplier_accounting &&
                      _vm.$props.pivc.isUserEngagedCustomer &&
                      _vm.$props.annex.isSignedByCustomer() &&
                      _vm.$props.annex.isSignedBySupplier() &&
                      !_vm.$props.pivc.purchaseitem.without_limits &&
                      _vm.$props.pivc.features.kbk_limits
                        ? _c(
                            "ContractSupplierAccountingInfo.view",
                            {
                              attrs: {
                                "supplier-accounting":
                                  _vm.$props.annex.supplier_accounting,
                              },
                            },
                            [
                              _c("span", [
                                _vm._v("Банковские реквизиты поставщика"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.$props.pivc.hasContractPendingAnnexes &&
                      !_vm.$props.pivc.pendingAnnex.hasUnresolvedDisputes()
                        ? _c(
                            "div",
                            { staticClass: "atmo-buttons" },
                            [
                              _vm.$props.annex.isPending() &&
                              _vm.$props.pivc.isUserEngagedSupplier &&
                              !_vm.$props.pivc.pendingAnnex.isSignedBySupplier()
                                ? [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "atmo-button",
                                        attrs: {
                                          disabled:
                                            !_vm.$store.getters.getCanSign,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.$props.pivc.invokeSignContractAnnexPopup.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Подписать дополнительное соглашение и отправить Заказчику"
                                        ),
                                      ]
                                    ),
                                    !_vm.$store.getters.getCanSign
                                      ? [
                                          _vm.$store.getters.getLoginType ===
                                          "cert"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                                },
                                                [
                                                  _vm._v(
                                                    "Для действия требуется загрузить/заменить МЧД на странице: "
                                                  ),
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "green-link",
                                                      attrs: {
                                                        to: {
                                                          name: "UserProfile",
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Данные учетной записи"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                                },
                                                [
                                                  _vm._v(
                                                    "Подписание возможно только при авторизации при помощи ЭЦП"
                                                  ),
                                                ]
                                              ),
                                        ]
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm.$props.annex.isPending() &&
                              _vm.$props.pivc.isUserEngagedCustomer &&
                              _vm.$props.pivc.hasContractPendingAnnexes &&
                              _vm.$props.pivc.pendingAnnex.isSignedBySupplier() &&
                              !_vm.$props.pivc.pendingAnnex.isSignedByCustomer()
                                ? [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "atmo-button",
                                        attrs: {
                                          disabled:
                                            (!_vm.$props.pivc.purchaseitem
                                              .without_limits &&
                                              _vm.$props.pivc.features
                                                .kbk_limits &&
                                              (_vm.has_limits_errors ||
                                                _vm.has_supplier_accounting_errors)) ||
                                            !_vm.$store.getters.getCanSign,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.invokeSignContractAnnexPopup.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Подписать дополнительное соглашение "
                                        ),
                                      ]
                                    ),
                                    !_vm.$store.getters.getCanSign
                                      ? [
                                          _vm.$store.getters.getLoginType ===
                                          "cert"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                                },
                                                [
                                                  _vm._v(
                                                    "Для действия требуется загрузить/заменить МЧД на странице: "
                                                  ),
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass: "green-link",
                                                      attrs: {
                                                        to: {
                                                          name: "UserProfile",
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Данные учетной записи"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                                },
                                                [
                                                  _vm._v(
                                                    "Подписание возможно только при авторизации при помощи ЭЦП"
                                                  ),
                                                ]
                                              ),
                                        ]
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.$props.annex.hasDisputes()
                        ? _c("AnnexDisputes.view", {
                            attrs: {
                              disputes: _vm.$props.annex.disputes,
                              pivc: _vm.$props.pivc,
                            },
                          })
                        : _vm._e(),
                      (_vm.$props.pivc.isUserEngagedSupplier &&
                        !_vm.$props.annex.hasUnresolvedDisputes() &&
                        !_vm.$props.annex.isSignedBySupplier()) ||
                      (_vm.$props.pivc.isUserEngagedCustomer &&
                        _vm.$props.annex.hasUnresolvedDisputes() &&
                        !_vm.$props.annex.isSignedByCustomer())
                        ? _c("ContractAnnexDisputeResolutionWidget.view", {
                            attrs: {
                              annex: _vm.$props.annex,
                              pivc: _vm.$props.pivc,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }