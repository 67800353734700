var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", [
      _vm._v(
        "Укажите, какими документами участник закупки должен подтвердить соответствие указанным требованиям:"
      ),
    ]),
    _c("div", { staticClass: "table-responsive" }, [
      _c(
        "table",
        {
          staticClass: "atmo-content-table atmo-supporting-documents",
          class: { "atmo-empty": _vm.$props.controller.isListEmpty },
        },
        [
          _vm._m(0),
          _c(
            "tbody",
            [
              _vm._l(_vm.$props.controller.list, function (document, index) {
                return _c("tr", { key: index }, [
                  _c("td", { domProps: { textContent: _vm._s(index + 1) } }),
                  _c("td", [
                    _c(
                      "span",
                      { staticClass: "atmo-form__field atmo-inverse" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: document.description,
                              expression: "document.description",
                            },
                          ],
                          attrs: {
                            id: _vm.$props.controller.inputIdPrefix + index,
                            type: "text",
                            required: "",
                            min: "1",
                          },
                          domProps: { value: document.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                document,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("p", { staticClass: "atmo-error" }, [
                          _vm._v("Заполните поле"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "atmo-button-icon",
                        attrs: { title: "Удалить описание" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$props.controller.remove(index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "atmo-icon-trash" })]
                    ),
                  ]),
                ])
              }),
              _vm.$props.controller.isListEmpty
                ? _c("tr", [
                    _c(
                      "td",
                      { staticClass: "atmo-centered", attrs: { colspan: "3" } },
                      [_vm._v("Не добавлено описаний документов")]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]),
    _c("footer", [
      _vm.$props.controller.isListEmpty
        ? _c("p", { staticClass: "atmo-error" }, [
            _vm._v("Добавьте описание документа"),
          ])
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "atmo-button",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$props.controller.add.apply(null, arguments)
            },
          },
        },
        [_vm._v("Добавить описание документа")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№")]),
        _c("th", [_vm._v("Описание документа")]),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }