var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("on-off", {
    attrs: {
      options: { id: "global-waiting-spinner", onEsc: false, onBody: false },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (onoff) {
          return [
            onoff.ison
              ? _c("div", { staticClass: "atmo-popup-wrapper" }, [
                  _c("div", { staticClass: "atmo-spinner-branded" }, [
                    _c("img", {
                      attrs: {
                        src: require("@themes/atmo/assets/images/loaders/spinner-branded.svg"),
                        alt: "Идет загрузка...",
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }