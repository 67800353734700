'use strict';

export default class DeliverableSearchRequestFilterVO {
    [index: string]: unknown;
    show_favorite?: boolean | null;
    name?: string | null;
    reg_number?: string | null;
    specs?: string | null;
    okpd_code?: string | null;

    constructor(data?: DeliverableSearchRequestFilterVO) {
        this.show_favorite = this.actualOrDefault('show_favorite', data);
        this.reg_number = this.actualOrDefault('reg_number', data);
        this.name = this.actualOrDefault('name', data);
        this.specs = this.actualOrDefault('specs', data);
        this.okpd_code = this.actualOrDefault('okpd_code', data);
    }

    private actualOrDefault(propertyName: string, data?: DeliverableSearchRequestFilterVO, defaultValue: unknown = null): any | null {
        return data && data[propertyName] ? data[propertyName] : defaultValue;
    }
}
