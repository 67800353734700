import axios from 'axios';

export default {
    getContracts(filters) {
        return axios
            .get('/contracts', {
                params: filters
            })
            .then((response) => {
                return response.data;
            });
    }
};
