<template>
    <div id="atmo-contract" class="atmo-hash-jump-point atmo-content-group">
        <h3>Контракт</h3>

        <div v-if="pivc.isUserEngagedCustomer || pivc.isUserEngagedSupplier" class="atmo-buttons">
            <a v-if="pivc.isUserEngagedCustomer" class="atmo-button atmo-accented" href="https://app.hintedme.ru/simulations/47863fd4-85ab-41ec-983a-803cec83b4c7/view" rel="noopener" target="_blank"><i class="atmo-icon-play"></i>&nbsp;Инструкция по заключению договора и дополнительного соглашения</a>
            <a v-if="pivc.isUserEngagedSupplier" class="atmo-button atmo-accented" href="https://app.hintedme.ru/simulations/f5bee876-d175-46a7-9ab2-f6f546bf6871/view" rel="noopener" target="_blank"><i class="atmo-icon-play"></i>&nbsp;Инструкция по заключению договора и дополнительного соглашения</a>
        </div>

        <ul v-if="!pivc.isPurchaseOutATMO" class="atmo-content-block-2-columns atmo-no-item-separator">
            <li class="mb-2 pb-2">
                <dt>Предмет контракта - наименование закупочной сессии:</dt>
                <dd v-text="this.$props.purchaseitem.description"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.customer_contract_number" class="mb-2 pb-2">
                <dt>Номер контракта в системе учета заказчика:</dt>
                <dd v-text="this.$props.purchaseitem.customer_contract_number"></dd>
            </li>
            <li v-if="this.$props.purchaseitem.contract.hasDeliveryCost()" class="mb-2 pb-2">
                <dt>Стоимость доставки, руб.:</dt>
                <dd>{{ this.$props.purchaseitem.contract.delivery_cost | formatnumber }}</dd>
            </li>
            <li class="mb-2 pb-2">
                <dt>Цена контракта, руб.:</dt>
                <dd>{{ this.$props.purchaseitem.contract.price_total | formatnumber }}</dd>
            </li>
            <li class="mb-2 pb-2">
                <dt>Статус контракта, дата:</dt>
                <dd>
                    <span v-text="this.$props.purchaseitem.contract.acceptance_status_title"></span>,
                    <span>{{ this.$props.purchaseitem.contract.acceptance_status_updated_at | formatdate('withSeconds') }}</span>
                </dd>
            </li>
            <li class="mb-2 pb-2">
                <dt>Статус закупки:</dt>
                <dd v-text="this.$props.purchaseitem.status_title"></dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { required: true, type: Object },
        purchaseitem: { required: true, type: Object }
    }
};
</script>
