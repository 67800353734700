var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { attrs: { cols: "12", md: _vm.md, lg: _vm.lg } },
    [
      _c(
        "b-form-group",
        [
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.options,
                multiple: false,
                "allow-empty": true,
                "close-on-select": true,
                "clear-on-select": false,
                "preserve-search": false,
                "show-labels": false,
                placeholder: _vm.placeholder,
                label: _vm.labelField,
                "track-by": _vm.trackBy,
              },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            },
            [
              _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
                _vm._v("Нет данных."),
              ]),
              _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v("Записей, соответствующих вашему запросу, не найдено."),
              ]),
            ]
          ),
          _c("span", { staticClass: "fs-10 halfWhite-color label-position" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }