<template>
    <section class="atmo-tab-content">
        <CustomerInfo.view v-bind:customer="this.$props.controller.custom.customer" />

        <SupplierInfo.view v-bind:supplier="this.$props.controller.custom.supplier" />

        <AnnexInfo.view v-bind:annex="this.$props.controller.custom.annex" v-bind:handlers="{ downloadFileAttachment: this.$props.controller.custom.downloadFileAttachment }" />
    </section>
</template>

<script>
import CustomerInfoView from 'themes/views/overlays/popups/common/partials/CustomerInfo.view.vue';
import SupplierInfoView from 'themes/views/overlays/popups/common/partials/SupplierInfo.view.vue';
import AnnexInfoView from './AnnexInfo.view.vue';

export default {
    props: {
        controller: { required: true, type: Object },
        certificate: { required: true, type: Object }
    },
    components: {
        'CustomerInfo.view': CustomerInfoView,
        'SupplierInfo.view': SupplierInfoView,
        'AnnexInfo.view': AnnexInfoView
    }
};
</script>
