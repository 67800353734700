var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { attrs: { cols: "12", md: _vm.md, lg: _vm.lg } },
    [
      _c(
        "b-form-group",
        [
          _c("b-input", {
            staticClass: "fs-14 mb-0",
            attrs: { placeholder: _vm.placeholder },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          }),
          _c(
            "span",
            { staticClass: "fs-10 label-position", class: _vm.labelClass },
            [_vm._v(_vm._s(_vm.label))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }