'use strict';

import deepmerge from 'deepmerge';
import { nanoid } from 'nanoid';

const OnOff = function () {
    // eslint-disable-next-line
    const self = this;

    this.name = 'OnOff';

    this.vmOptions = {
        keyupHandler: null,
        clickHandler: null,
        clickIntelligentHandler: null
    };

    /**
     * The default options.
     * @type {Object}
     */
    const defaults = {
        options: function () {
            return { onEsc: true, onBody: 'simple', id: null, nobodyscroll: false };
        }
    };

    /**
     * Hold the setup combined from prop 'options' and 'default.options' objects
     * at 'created' time.
     * @type {Object}
     */
    let setup = null;

    this.data = function () {
        return {
            id: null,
            ison: false,
            content: null
        };
    };

    this.computed = {};

    this.props = {
        options: { type: Object, default: defaults.options },
        currentstate: { type: Boolean, default: false },
        offstate: { type: Boolean, default: null },
        data: {
            type: Object,
            default: () => {
                return {};
            }
        }
    };

    this.created = function () {
        this.$data.ison = this.$props.currentstate;
        setup = deepmerge(defaults.options(), this.$props.options || {});
        this.$data.id = setup.id || nanoid();

        if (setup.onEsc) {
            this.$options.vmOptions.keyupHandler = self.keyupHandler.bind(this);
            document.addEventListener('keyup', this.$options.vmOptions.keyupHandler);
        }

        if (setup.onBody === 'simple') {
            this.$options.vmOptions.clickHandler = self.clickHandler.bind(this);
            document.body.addEventListener('click', this.$options.vmOptions.clickHandler);
        }
    };

    /**
     * Change in v6.
     *
     * Allows to automatically distinguish the click on body within this
     * particular on-off component from clicks on other on-off components or
     * outside them.
     *
     * In case the click comes from outside of this particular component the
     * feature sets its own ison state to false.
     *
     * To use it set 'onBody' option = 'intelligent'.
     *
     * The on body clicks coming from within the on-off component DOM element
     * are candidates to propagation stopping for keeping the on-off component
     * open.
     */
    this.mounted = function () {
        this.$root.$on('public:onoff:toggle', self.publicToggleHandler.bind(this));

        if (setup.onBody !== 'intelligent') {
            return;
        }
        const to = window.setTimeout(() => {
            const idAttributeName = `data-wj-onoff-id-${this.$data.id}`;
            const idAttributeSelector = `[${idAttributeName.toLowerCase()}]`;

            this.$el.setAttribute(idAttributeName, this.$data.id);

            /**
             * WARNING: the event target for the click must not be hidden with
             * 'v-if' for this section to work.
             *
             * 'v-if' detaches the click target from DOM so the closest ancestor
             * above is never found. In this case click will always set 'ison'
             * to false.
             *
             * Use 'v-show' instead of 'v-if'.
             */
            this.$options.vmOptions.clickIntelligentHandler = (evt) => {
                self.clickIntelligentHandler.call(this, evt, idAttributeName, idAttributeSelector);
            };

            document.body.addEventListener('click', this.$options.vmOptions.clickIntelligentHandler);

            window.clearTimeout(to);
        }, 0);
    };

    this.watch = {
        ison: function (newstate) {
            const payload = { ison: newstate, id: this.$data.id, data: this.$props.data };
            this.$root.$emit('public:onoff:ontoggle', payload);
            this.$emit('onoff:ontoggle', payload);
            self.toggleBodyScroll(setup, newstate);
        },
        currentstate: function (newstate) {
            this.$data.ison = newstate;
        },
        offstate: function (newstate) {
            if (newstate) {
                this.off();
            }
        }
    };

    this.methods = {
        toggle: function () {
            this.$data.ison = !this.$data.ison;
        },

        /**
         * Pass the component ID to the function in order to prevent close when
         * click on the same OnOff component (which actually is a click on body)
         * @param  string id The component's unique ID.
         */
        off: function (id) {
            if (id !== this.$data.id) {
                this.$data.ison = false;
            }
        },
        on: function () {
            this.$data.ison = true;
        }
    };

    this.beforeDestroy = function () {
        this.$options.vmOptions.keyupHandler && document.body.removeEventListener('keyup', this.$options.vmOptions.keyupHandler);
        this.$options.vmOptions.clickHandler && document.body.removeEventListener('click', this.$options.vmOptions.clickHandler);
        this.$options.vmOptions.clickIntelligentHandler && document.body.removeEventListener('click', this.$options.vmOptions.clickIntelligentHandler);
        self.toggleBodyScroll(setup, false);
    };

    this.render = function () {
        return this.$scopedSlots.default(this);
    };
};

OnOff.prototype.keyupHandler = function (evt) {
    evt.keyCode === 27 && this.$data.ison && this.off();
};

OnOff.prototype.clickHandler = function () {
    this.$data.ison && this.off();
};

OnOff.prototype.clickIntelligentHandler = function (evt, idAttributeName, idAttributeSelector) {
    const possibleStopper = evt.target.closest(idAttributeSelector);

    if (possibleStopper && possibleStopper.getAttribute(idAttributeName) === this.$el.getAttribute(idAttributeName)) {
        evt.stopPropagation();
    } else {
        this.$data.ison = false;
    }
};

/**
 * Process 'public:onoff:toggle' event if addressed to this OnOff instance.
 *
 * @param {IOnOffToggleEvent} payload
 */
OnOff.prototype.publicToggleHandler = function (payload) {
    if (payload.id !== this.$data.id) {
        return;
    }

    this.$data.content = payload.content;
    payload.ison ? (this.$data.ison = payload.ison) : this.toggle();
};

OnOff.prototype.toggleBodyScroll = function (setup, ison) {
    if (!setup.nobodyscroll) {
        return;
    }
    const command = ison ? 'add' : 'remove';
    document.body.classList[command]('wj-noscroll');
};

export default OnOff;
