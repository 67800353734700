var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sign-contract-popup-controller", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (scpc) {
          return [
            _c("on-off", {
              attrs: {
                options: {
                  id: "sign-contract-popup",
                  onEsc: false,
                  onBody: false,
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (onoff) {
                      return [
                        onoff.ison
                          ? _c("div", { staticClass: "atmo-popup-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "atmo-popup-content atmo-save-purchase-popup",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "atmo-button--small atmo-close",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return onoff.off.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "atmo-icon-close",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "header",
                                    [
                                      _c("h5", [
                                        _vm._v(
                                          "Подписать и отправить контракт"
                                        ),
                                      ]),
                                      _c("tab-menu-controller", {
                                        attrs: {
                                          tabmenuid: "sign-contract-tabmenu",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (tmc) {
                                                return [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "atmo-tabs-menu-universal",
                                                    },
                                                    [
                                                      _c("li", [
                                                        _c(
                                                          "button",
                                                          {
                                                            class: {
                                                              "atmo-active":
                                                                tmc.isSelected(
                                                                  1
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return tmc.select(
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Данные закупки"
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _c(
                                                          "button",
                                                          {
                                                            class: {
                                                              "atmo-active":
                                                                tmc.isSelected(
                                                                  2
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return tmc.select(
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "XML-представление данных закупки"
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("tab-menu-tabs-controller", {
                                    attrs: {
                                      tabmenuid: "sign-contract-tabmenu",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (tmtc) {
                                            return [
                                              _c(
                                                "main",
                                                {
                                                  staticClass:
                                                    "atmo-tabs-container",
                                                },
                                                [
                                                  tmtc.isSelected(1)
                                                    ? _c(
                                                        "TabPurchasePreview.view",
                                                        {
                                                          attrs: {
                                                            user: scpc.user,
                                                            controller: scpc,
                                                            onoff: onoff,
                                                            purchaseitem:
                                                              scpc.purchaseitem,
                                                            handlers:
                                                              scpc.handlers,
                                                            certificate:
                                                              scpc.$data
                                                                .certificate,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  tmtc.isSelected(2)
                                                    ? _c(
                                                        "TabPurchaseXMLPreview.view",
                                                        {
                                                          attrs: {
                                                            controller: scpc,
                                                            onoff: onoff,
                                                            xml: scpc.xml,
                                                            certificate:
                                                              scpc.$data
                                                                .certificate,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }