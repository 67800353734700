'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import isTypeOrNull from '@lib/js/src/misc/isTypeOrNull';

@Component
export default class EntityStatusMenuController extends BaseViewModel {
    constructor() {
        super();
        this.name = 'EntityStatusMenuController';
    }

    @Prop({ required: true, type: String }) readonly route?: string;
    @Prop({ required: true, type: Array }) readonly statuses?: ExtendedDictionaryItem[];
    @Prop({
        required: true,
        validator: (prop: unknown) => {
            return isTypeOrNull(prop, 'number');
        }
    })
    readonly currentstatus?: string;

    data() {
        return {
            activeMenuItemStatus: this.$props.currentstatus
        };
    }

    /**
     * Methods.
     */
    status(status: number) {
        if (status === this.$data.activeMenuStatus) {
            return;
        }
        this.$data.activeMenuItemStatus = status;
        this.$emit('status:update', { key: 'status', value: status });
    }

    isDisabledMenuItem(status: number) {
        return status === this.$data.activeMenuItemStatus;
    }

    /**
     * Computed
     */
    get currentTitle() {
        return this.$props.statuses.find((status: ExtendedDictionaryItem) => {
            return status.id === this.$data.activeMenuItemStatus;
        }).title;
    }

    @Watch('currentstatus')
    onCurrentstatusChange(newValue: number) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$data.activeMenuItemStatus = newValue;
    }
}
