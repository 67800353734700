'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';

@Component
export default class ClearableInput extends BaseViewModel {
    inputEl: HTMLInputElement | null;

    constructor() {
        super();
        this.name = 'ClearableInput';
        this.inputEl = null;
    }

    mounted(): void {
        this.inputEl = this.$el.querySelector('input');
        if (!this.inputEl) {
            throw new Error(`WJ Exception: There must be input element witin a scope of the ${this.name} viewmodel.`);
        }
    }

    data() {
        return {};
    }

    /**
     * Methods
     */
    public clear() {
        if (!this.inputEl) {
            return;
        }
        (this.inputEl.value as any) = null;
        this.inputEl.dispatchEvent(new Event('input', { bubbles: true }));
        this.inputEl.focus();
    }

    public isEmpty(): boolean {
        return !!this.inputEl && (this.inputEl.value === null || this.inputEl.value === '');
    }
}
