var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-content-group table-responsive" }, [
    _c("table", { staticClass: "atmo-content-table" }, [
      _vm._m(0),
      _c(
        "tbody",
        [
          _vm._l(_vm.$props.kbkitems, function (kbkitem, index) {
            return _c(
              "tr",
              { key: kbkitem.id },
              [
                _c("td", { domProps: { textContent: _vm._s(kbkitem.code) } }),
                _c("td", {
                  domProps: { textContent: _vm._s(kbkitem.sub_kosgu_code) },
                }),
                _c("FinancingYearLimitsList.view", {
                  attrs: { index: index, kbkitem: kbkitem },
                }),
                _c("td", {
                  domProps: { textContent: _vm._s(kbkitem.account) },
                }),
                _c("td", {
                  domProps: { textContent: _vm._s(kbkitem.budget_name) },
                }),
                _c("td", {
                  domProps: { textContent: _vm._s(kbkitem.target_code) },
                }),
                _c("td", {
                  domProps: {
                    textContent: _vm._s(kbkitem.targeted_funds_code),
                  },
                }),
                _c("td", {
                  domProps: { textContent: _vm._s(kbkitem.pay_promise_code) },
                }),
                _c("td", {
                  domProps: {
                    textContent: _vm._s(kbkitem.budget_allocation_code),
                  },
                }),
                _c("td", {
                  domProps: { textContent: _vm._s(kbkitem.rks_code) },
                }),
              ],
              1
            )
          }),
          this.isEmpty
            ? _c("tr", { staticClass: "atmo-centered" }, [_vm._m(1)])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("КБК")]),
        _c("th", [_vm._v("СубКОСГУ")]),
        _c("th", [_vm._v("Лимиты")]),
        _c("th", [_vm._v("Лицевой счет")]),
        _c("th", [_vm._v("Наименование бюджета")]),
        _c("th", [_vm._v("Код цели")]),
        _c("th", [_vm._v("Код целевых средств")]),
        _c("th", [_vm._v("Код расходного обязательства")]),
        _c("th", [_vm._v("Код бюджетного ассигнования")]),
        _c("th", [_vm._v("Код РКС")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { attrs: { colspan: "9" } }, [
      _c("span", { staticClass: "atmo-error" }, [
        _vm._v("Лимиты по КБК по вашему запросу не найдены."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }