<template>
    <div class="table-responsive">
        <table class="atmo-content-table">
            <caption>
                <slot></slot>
            </caption>
            <thead>
                <tr>
                    <th class="atmo-ctc-place">№</th>
                    <th class="atmo-ctc-width-400">Описание</th>
                    <th>Документ</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(document, index) in $props.documents" v-bind:key="index">
                    <td v-text="index + 1" class="atmo-ctc-place"></td>
                    <td v-text="document.description" class="atmo-ctc-width-400"></td>
                    <td>
                        <button v-on:click="pivc().downloadFileAttachment(document.attachment)" class="atmo-button-link" v-text="document.attachment.name"></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    inject: ['pivc'],
    props: {
        documents: { required: true, type: Array }
    }
};
</script>
