<template>
    <purchase-item-update-controller v-slot:default="piuc" v-bind:group="this.$props.group" v-bind:id="this.$props.id">
        <div class="atmo-page">
            <!-- <section class="atmo-fast-access-menu">
                <ul class="atmo-container-fixed">
                    <li><a href="#atmo-supply-terms">Условия поставки</a></li>
                    <li><a href="#atmo-deliverables">Спецификации</a></li>
                </ul>
            </section> -->

            <section class="atmo-fast-access-menu">
                <ul class="atmo-container-fixed">
                    <li>
                        <a v-on:click="piuc.setTab('terms')" v-bind:class="{ 'atmo-active': piuc.visibleTab === 'terms' }">Условия закупки и поставки</a>
                        <span v-if="piuc.tabbedErrorCounts.terms" v-text="piuc.tabbedErrorCounts.terms" class="atmo-badge" title="Количество ошибочных полей"></span>
                    </li>
                    <li>
                        <a v-on:click="piuc.setTab('deliverables')" v-bind:class="{ 'atmo-active': piuc.visibleTab === 'deliverables' }">Спецификация и документы</a>
                        <span v-if="piuc.tabbedErrorCounts.deliverables" v-text="piuc.tabbedErrorCounts.deliverables" class="atmo-badge" title="Количество ошибочных полей"></span>
                    </li>
                </ul>
            </section>

            <header>
                <InfoBanner.view v-bind:user="piuc.user" />
            </header>

            <main class="atmo-container-fixed atmo-page-content">
                <div class="atmo-buttons">
                    <a href="https://app.hintedme.ru/simulations/e35e187d-ec2e-408d-ab39-3b2037dc8dff/view" rel="noopener" target="_blank" class="atmo-button atmo-accented"><i class="atmo-icon-play"></i>&nbsp;Инструкция по созданию извещения</a>
                </div>

                <section v-show="piuc.visibleTab === 'terms'" class="atmo-tab">
                    <CustomerInfo.view v-bind:user="piuc.user" v-bind:inputs.sync="piuc.inputs" />

                    <PurchaseTerms.view
                        v-bind:controller="piuc"
                        v-bind:inputs.sync="piuc.inputs"
                        v-bind:errors="piuc.errors"
                        v-bind:dictionaries="piuc.dictionaries"
                        v-bind:deliverablesgroupsselecthandler="piuc.deliverablesGroupsSelectHandler"
                        v-bind:deliverablesgroupsremovehandler="piuc.deliverablesGroupsRemoveHandler" />

                    <SupplierRequirements.view v-bind:inputs.sync="piuc.inputs" />

                    <div class="atmo-content-group">
                        <text-header-new>Условия поставки</text-header-new>
                        <form-row-edit-addresses-new label="Адреса для доставки товаров/выполнения работ/оказания услуг" :value.sync="piuc.inputs.delivery_addresses" hint="Укажите новый адрес или выберите ранее сохраненный адрес из Адресной книги." />
                        <form-row-edit-text label="График поставки товаров (выполнения работ, оказания услуг)" v-model="piuc.inputs.delivery_schedule" />
                    </div>

                    <ul class="atmo-buttons">
                        <li><router-link v-bind:to="{ name: 'Cabinet' }" class="atmo-button-inverse atmo-dimmed">Отменить</router-link></li>
                        <li><button v-on:click="piuc.setTab('deliverables')" class="atmo-button">Далее</button></li>
                        <li><button v-on:click="piuc.saveDraft" class="atmo-button atmo-accented">Сохранить черновик</button></li>
                    </ul>
                </section>

                <section v-show="piuc.visibleTab === 'deliverables'" class="atmo-tab">
                    <!-- // REFACTOR: The motivation to wrap a controller around a view instead of putting it into a view
                        is not remembered now. Probably that came from times when the deliverables list was only viewable.
                        Then deliverables editable list was introduced that required a controller.
                        At the same time I decided to keep the view inside controller for better in the future.
                        It looks like the flexibility still is not required.
                        Therefore it seems better to refactor this block to wrap the controller into the view. -->
                    <deliverables-editable-list-controller v-slot:default="dsc" v-bind:initiallist="piuc.inputs.specification.deliverables" :max_price="piuc.orderType?.max_amount ?? 9999999999999">
                        <DeliverablesEditableList.view v-bind:controller="dsc" v-bind:deliverables="dsc.list">
                            <template v-slot:errors>
                                <p v-if="dsc.islistempty" class="atmo-error">Заполните спецификацию</p>
                                <p v-if="!dsc.islistempty && dsc.errors.is_quantity_empty" class="atmo-error">У одной или нескольких позиций спецификации неправильно заполнено поле "Количество".</p>
                                <p v-if="!dsc.islistempty && dsc.errors.is_price_per_unit_empty" class="atmo-error">У одной или нескольких позиций спецификации неправильно заполнено поле "Цена за единицу".</p>
                                <p v-if="!dsc.islistempty && dsc.errors.is_specification_total_price_invalid" class="atmo-error">Общая стоимость спецификации превышает {{ $formatPrice(piuc.orderType?.max_amount) }} руб.</p>
                            </template>
                        </DeliverablesEditableList.view>
                    </deliverables-editable-list-controller>

                    <!-- // NB: See refactor note above. Here I implement a controller within view which went out quite good. -->
                    <!-- // NB: However in case of theming it can be appicable to wrap view into viewmodel so that view is a dependency, not a viewmodel. -->
                    <KBKLimitsEditableList.view
                        v-if="piuc.features.kbk_limits && !piuc.inputs.without_limits"
                        v-bind:initiallist="piuc.inputs.specification.kbkLimits"
                        v-bind:totalamount="piuc.inputs.specification.total_amount"
                        v-bind:limitfilterpresets="{ year: piuc.inputs.limit_year, purchase_type_id: piuc.inputs.type_id, purchase_id: piuc.inputs.id }">
                        <template v-slot:info>
                            <p>
                                <i class="atmo-warning atmo-icon-warning"></i>&nbsp;Внимание! Перед добавление лимитов проверьте правильность значений полей "Год ПГ" и "Тип закупки" в разделе "Условия закупки". При изменении любого из этих полей все добавленные в таблицу лимиты по КБК будут удалены.
                            </p>
                        </template>
                    </KBKLimitsEditableList.view>

                    <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:initiallist="piuc.inputs.contract_draft_attachment" v-bind:isrequired="true" v-bind:origin="'contract'" v-bind:maxfilesallowed="1" v-on:fileattachment:changed="piuc.fileAttachmentEventHandler">
                        <EditableFileAttachmentsList.view v-bind:controller="efalc"><h3>Проект ГК</h3></EditableFileAttachmentsList.view>
                    </editable-file-attachments-list-controller>

                    <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:initiallist="piuc.inputs.notice_supplementary_attachments" v-bind:origin="'notice'" v-on:fileattachment:changed="piuc.fileAttachmentEventHandler">
                        <EditableFileAttachmentsList.view v-bind:controller="efalc"><h3>Дополнительные документы</h3></EditableFileAttachmentsList.view>
                    </editable-file-attachments-list-controller>

                    <ul class="atmo-buttons">
                        <li><button v-on:click="piuc.setTab('terms')" class="atmo-button-inverse atmo-dimmed">Назад</button></li>
                        <li><button v-on:click="piuc.saveDraft" class="atmo-button atmo-accented">Сохранить черновик</button></li>
                        <li><button v-bind:disabled="piuc.hasErrors || !$store.getters.getCanSign" v-on:click="piuc.invokeSavePurchasePopup" class="atmo-button">Подписать и сохранить</button></li>
                        <template v-if="!$store.getters.getCanSign">
                            <span v-if="$store.getters.getLoginType === 'cert'" class="text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto"
                                >Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span
                            >
                            <span v-else class="text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                        </template>
                    </ul>
                </section>
            </main>
        </div>
    </purchase-item-update-controller>
</template>

<script>
import PurchaseItemUpdateController from '@core/js/viewmodels/purchases/item/update/PurchaseItemUpdateController.viewmodel';
import DeliverablesEditableListController from '@core/js/viewmodels/purchases/item/update/deliverables/DeliverablesEditableListController.viewmodel';
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';

import InfoBannerView from './partials/InfoBanner.view.vue';
import CustomerInfoView from './partials/CustomerInfo.view.vue';
import PurchaseTermsView from './partials/PurchaseTerms.view.vue';
import SupplierRequirementsView from './partials/supplier-requirements/SupplierRequirements.view.vue';
import DeliverablesEditableListView from 'themes/views/pages/purchases/item/common/partials/deliverables/update/DeliverablesEditableList.view.vue';
import KBKLimitsEditableListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/update/KBKLimitsEditableList.view.vue';
import EditableFileAttachmentsListView from 'themes/views/pages/purchases/item/common/partials/EditableFileAttachmentsList.view.vue';
import setFastAccessMenuTop from '@lib/js/src/misc/setFastAccessMenuTop.ts';

export default {
    metaInfo: {
        title: 'Создание закупки'
    },
    components: {
        'purchase-item-update-controller': PurchaseItemUpdateController,
        'deliverables-editable-list-controller': DeliverablesEditableListController,
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController,

        'InfoBanner.view': InfoBannerView,
        'CustomerInfo.view': CustomerInfoView,
        'PurchaseTerms.view': PurchaseTermsView,
        'SupplierRequirements.view': SupplierRequirementsView,
        'DeliverablesEditableList.view': DeliverablesEditableListView,
        'KBKLimitsEditableList.view': KBKLimitsEditableListView,
        'EditableFileAttachmentsList.view': EditableFileAttachmentsListView
    },
    props: {
        group: { required: true, type: String },
        id: { default: '' }
    },
    mounted() {
        setFastAccessMenuTop();
    }
};
</script>

<style scoped>
.text-initialcase {
    text-transform: initial;
}
</style>
