'use strict';

import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import PurchaseItemDetailsVO from '@core/js/ddriven/domain/model/purchases/view/item/PurchaseItemDetails.valueobject';
import SRDA31s1p1SupportingDocumentVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import SupplierRequirementAdditionalVO, { TRawAdditional } from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierRequirementAdditional.valueobject';

export interface ISupplierRequirements {
    supplier_reqs_a: boolean;
    supplier_reqs_b: boolean;
    supplier_reqs_c: boolean;
    supplier_reqs_sonko: boolean;
    supplier_required_documents: ISupplierRequirementsAttachment[];
    supplier_reqs_rating: boolean;
    additional: TRawAdditional;
}

interface ISupplierRequirementsAttachment {
    id: number;
    name: string;
    storedDocument: {
        id: string;
        name: string;
    };
}

export default class PurchaseItemDetailsXMLRequestVO extends BaseValueObject {
    supplier_requirements: ISupplierRequirements | null;

    constructor(purchase?: PurchaseItemDetailsVO) {
        super();

        this.supplier_requirements = purchase?.supplier_requirements && purchase?.supplier_requirements !== null ? this.content(purchase) : null;
    }

    private content(purchase: PurchaseItemDetailsVO): ISupplierRequirements {
        return {
            supplier_reqs_a: purchase.supplier_requirements.a31s1p1,
            supplier_reqs_b: purchase.supplier_requirements.a31s1ps3_5ps7_11,
            supplier_reqs_c: purchase.supplier_requirements.a31s1_1,
            supplier_reqs_sonko: purchase.supplier_requirements.supplier_reqs_sonko,
            supplier_required_documents: purchase.supplier_requirements.a31s1p1_details.supporting_documents.map((document: SRDA31s1p1SupportingDocumentVO) => {
                return {
                    id: document.id as number,
                    name: document.description as string,
                    storedDocument: {
                        id: document.attachment?.file_id as string,
                        name: document.attachment?.name as string
                    }
                };
            }),
            supplier_reqs_rating: purchase.supplier_requirements.rating,
            additional: SupplierRequirementAdditionalVO.toRequestPayload(purchase.supplier_requirements.additional)
        };
    }
}
