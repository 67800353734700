export default [
    {
        path: '/quotation-requests/create',
        name: 'QuotationRequestCreate',
        component: () => import(/* webpackChunkName: "main" */ '@/components/quotation-requests/RequestCreate')
    },
    {
        path: '/quotation-requests',
        redirect: { name: 'QuotationRequestsList', params: { path: 'new' } },
        component: () => import(/* webpackChunkName: "main" */ '@/components/quotation-requests/List'),
        children: [
            {
                path: ':path',
                name: 'QuotationRequestsList',
                component: () => import(/* webpackChunkName: "main" */ '@/components/quotation-requests/List')
            }
        ]
    },
    {
        path: '/quotation-requests/:id/show',
        name: 'QuotationRequestShow',
        component: () => import(/* webpackChunkName: "main" */ '@/components/quotation-requests/RequestShow')
    },
    {
        path: '/quotation-requests/:id/create-quotation',
        name: 'QuotationCreate',
        component: () => import(/* webpackChunkName: "main" */ '@/components/quotation-requests/OfferCreate.vue')
    }
];
