<template>
    <contract-disputes-resolution-widget-controller v-slot:default="cdrwc" v-bind:purchaseid="this.$props.pivc.purchaseitem.id">
        <div id="atmo-disputes-resolution" class="atmo-hash-jump-point atmo-content-group">
            <h3>Согласование разногласий по договору</h3>

            <!-- NB: Bind origin prop if attachment handler to handle more than one file origin. -->
            <editable-file-attachments-list-controller v-slot:default="efalc" v-if="pivc.isUserEngagedCustomer && pivc.isContractDisputed" v-bind:isrequired="true" v-bind:origin="'contract'" v-bind:maxfilesallowed="1" v-on:fileattachment:changed="cdrwc.handleAttachment">
                <div class="atmo-component-wrapper">
                    <EditableFileAttachmentsList.view v-bind:controller="efalc">
                        <span><h5>Новая редакция договора</h5></span>
                    </EditableFileAttachmentsList.view>
                </div>
            </editable-file-attachments-list-controller>

            <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:isrequired="true" v-bind:origin="'dispute'" v-bind:maxfilesallowed="1" v-on:fileattachment:changed="cdrwc.handleAttachment">
                <div class="atmo-component-wrapper">
                    <EditableFileAttachmentsList.view v-bind:controller="efalc">
                        <span>
                            <h5>Добавьте <span v-if="$props.pivc.isUserEngagedCustomer">ответ на</span> протокол разногласий</h5>
                        </span>

                        <template v-slot:aux>
                            <section v-show="!efalc.isempty" class="atmo-form__field atmo-inverse">
                                <label for="atmo-dispute-comment">Комментарий к протоколу разногласий:</label>
                                <textarea v-model="cdrwc.comment" id="atmo-dispute-comment" v-bind:class="{ 'atmo-empty': pivc.isUserEngagedCustomer && cdrwc.isCommentEmpty }" cols="2"></textarea>
                            </section>
                        </template>

                        <template v-slot:controls>
                            <li>
                                <button v-on:click="cdrwc.invokeSignContractDisputeProtocol" v-bind:disabled="efalc.isempty || (pivc.isUserEngagedCustomer && (cdrwc.isCommentEmpty || cdrwc.isContractAttachmentEmpty)) || !$store.getters.getCanSign" class="atmo-button mr-2">
                                    Подписать и отправить <span v-if="pivc.isUserEngagedCustomer">ответ на </span>протокол разногласий
                                </button>
                                <template v-if="!$store.getters.getCanSign">
                                    <span v-if="$store.getters.getLoginType === 'cert'" class="text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto"
                                        >Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span
                                    >
                                    <span v-else class="text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                                </template>
                            </li>
                        </template>
                    </EditableFileAttachmentsList.view>
                </div>
            </editable-file-attachments-list-controller>
        </div>
    </contract-disputes-resolution-widget-controller>
</template>

<script>
import ContractDisputeResolutionWidgetControloller from '@core/js/viewmodels/purchases/item/view/widgets/contract/disputes/ContractDisputeResolutionWidgetController.viewmodel';
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';

import EditableFileAttachmentsListView from 'themes/views/pages/purchases/item/common/partials/EditableFileAttachmentsList.view.vue';

export default {
    props: {
        pivc: { required: true, type: Object }
    },
    components: {
        'contract-disputes-resolution-widget-controller': ContractDisputeResolutionWidgetControloller,
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController,

        'EditableFileAttachmentsList.view': EditableFileAttachmentsListView
    }
};
</script>

<style scoped>
.text-initialcase {
    text-transform: initial;
}
</style>
