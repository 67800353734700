import store from '@/store';
import axios from 'axios';
import { BaseV1ApiService } from '@/services/backend/baseV1';

export class Fl44Purchases extends BaseV1ApiService {
    constructor() {
        super('purchases/fl44');
    }
    async getChangeLengthXml(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}/change_length_xml`));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeChangeLengthSigned(purchaseId, xml, certificate) {
        if (!certificate) {
            return false;
        }
        try {
            await axios.post(this.getUrl(`${purchaseId}/change_length`), {
                xml: xml,
                certificate: certificate
            });
            await store.dispatch('showSuccessToast', 'Продолжительность закупочной сессии изменена');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async showExternalPurchase(id) {
        try {
            const response = await axios.get(this.getUrl(`external_purchase/${id}`));
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                throw error;
            }
            this.handleErrors(error);
        }
    }
}
