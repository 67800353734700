'use strict';

import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { isEmpty } from 'lodash';

import BaseSignPopupController from '@core/js/viewmodels/common/popup/BaseSignPopupController.viewmodel';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import { ISignDisputeResolutionEventPayload } from '@core/js/viewmodels/purchases/item/view/widgets/contract/disputes/ContractDisputeResolutionWidgetController.viewmodel';
import DisputeAttachmentForXMLVO from '@core/js/ddriven/domain/model/contracts/dispute/DisputeAttachmentForXML.valueobject';
import LinkContractDisputeAttachmentRequestVO, { IParams } from '@core/js/ddriven/application/http/requests/purchases/disputes/LinkContractDisputeAttachmentRequest.valueobject';
import EntitiesAPIFacadePartial from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/partials/EntitiesAPIFacadePartial';
import OnOffComposable from '@lib/js/src/vue/composables/OnOff.composable';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import { Certificate } from 'crypto-pro';

@Component
export default class SignContractDisputeProtocolPopupController extends BaseSignPopupController {
    public static popupId = 'sign-contract-dispute-protocol-popup';

    public onoff: OnOffComposable;

    private attachment: FileAttachmentVO | null;
    private comment: string | null;
    private purchaseId?: number;
    private contractAttachmentFileId?: string | null;

    constructor() {
        super();
        this.name = 'SignContractDisputeProtocolPopupController';
        this.popupId = SignContractDisputeProtocolPopupController.popupId;
        this.attachment = null;
        this.comment = null;
        this.onoff = Vue.observable(new OnOffComposable());
    }

    /**
     * Methods
     */
    public async linkDisputeAttachment(evt: Event) {
        this.onoff.on();
        if (isEmpty(this.currentParsedCertificate)) {
            return;
        }
        const params: IParams = {
            widgetdata: {
                purchaseId: this.purchaseId!,
                contractAttachmentFileId: this.contractAttachmentFileId!,
                attachment: this.$data.attachment,
                comment: this.$data.comment
            },
            xml: this.$data.xml,
            thumbprint: (this.currentParsedCertificate as Certificate).thumbprint,
            certificate: this.$data.certificate
        };

        const request = new LinkContractDisputeAttachmentRequestVO(params);

        await actWithLoadingSpinner(evt, async () => {
            const response = await ApplicationServiceLocator.get('api').entities.linkContractDisputeAttachment(request);
            response.isSuccess &&
                setTimeout(() => {
                    window.location.hash = '#atmo-disputes';
                    window.location.reload();
                }, 500);
        });
    }

    /**
     * General prototype methods
     */
    protected initializeCustomData(payload: ISignDisputeResolutionEventPayload): void {
        this.$data.attachment = payload.data.attachment;
        this.$data.comment = payload.data.comment;
        this.$data.xml = new DisputeAttachmentForXMLVO(payload.data.attachment).toXML();
        this.purchaseId = payload.data.purchaseId;
        this.contractAttachmentFileId = payload.data.contractAttachmentFileId;
    }
}
