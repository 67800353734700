import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';

export class IntegrationsApiService extends BaseApiService {
    constructor() {
        super('integrations/bashmarket');
    }

    async gotoBashmarket() {
        try {
            const response = await axios.get(this.getUrl());
            if (response.data.url) {
                location.href = response.data.url;
            } else {
                throw new Error('Что-то пошло не так :(');
            }
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
