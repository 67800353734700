'use strict';

import PurchaseItem from '@core/js/ddriven/domain/model/purchases/view/list/PurchaseItem.valueobject';
import PurchaseRequest, { IPurchaseRequest } from '@/rearchitected/core/js/ddriven/application/http/requests/purchases/PurchaseRequest.valueobject';
import StandardPagination from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import PurchaseItemDetailsVO from '@core/js/ddriven/domain/model/purchases/view/item/PurchaseItemDetails.valueobject';
import PurchaseItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditable.valueobject';
import { INoticeRequest } from '@core/js/ddriven/application/http/requests/purchases/NoticeRequest.valueobject';
import NoticeItem from '@core/js/ddriven/domain/model/notices/view/list/NoticeItem.valueobject';

export interface IState {
    list: Readonly<NoticeItem>[];
    item: Readonly<PurchaseItemDetailsVO>;
    itemeditable: PurchaseItemEditableVO;
    request: IPurchaseRequest;
    pagination: null | StandardPagination;
    routereset: boolean;
}

export default class State implements IState {
    list: NoticeItem[];
    item: Readonly<PurchaseItemDetailsVO>;
    itemeditable: PurchaseItemEditableVO;
    request: IPurchaseRequest;
    pagination: null | StandardPagination;
    routereset: boolean;

    constructor() {
        this.list = [];
        this.item = Object.freeze(new PurchaseItemDetailsVO());
        this.request = new PurchaseRequest();
        this.itemeditable = new PurchaseItemEditableVO();
        this.pagination = null;
        this.routereset = false;
    }
}
