'use strict';

import PurchaseItemDetailsVO from '@core/js/ddriven/domain/model/purchases/view/item/PurchaseItemDetails.valueobject';
import SRDA31s1p1SupportingDocumentVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import KBKLimitsSpecificationItemsEditableCollection from '@core/js/ddriven/domain/model/purchases/update/KBKLimitsSpecificationItemsEditable.collection';
import KBKLimitSpecificationItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/KBKLimitSpecificationItemEditable.valueobject';
import PurchaseItemDetailsXMLRequestVO, { ISupplierRequirements } from '../purchases/PurchaseItemDetailsXMLRequest.valueobject';
import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import ContractItemDetailsVO from '@core/js/ddriven/domain/model/contracts/ContractItemDetails.valueobject';
import KBKLimitUpdateItemVO from '../purchases/kbk-limits/KBKLimitUpdateItem.valueobject';
import SupplierRequirementAdditionalVO from '../../../../domain/model/purchases/update/supplier-requirements/SupplierRequirementAdditional.valueobject';
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';
import { Certificate } from 'crypto-pro';

interface IKBKLimit {
    id: number;
    year: number;
    amount_assigned: number;
}

export default class SignContractRequestVO extends BaseValueObject {
    purchase_id: number;
    limits: IKBKLimit[];
    supplier_requirements: ISupplierRequirements;
    supplier_accounting: SupplierAccountingVO | null;
    customer_contract_number: string | null;
    xml: string;
    thumbprint: string;
    certificate: Certificate;

    constructor(purchase: PurchaseItemDetailsVO, xml: string, certificate: any, supplier_accounting: SupplierAccountingVO | null, customer_contract_number: string | null) {
        super();

        this.purchase_id = purchase.id!;
        this.limits = this.limitsFactory((purchase.contract as ContractItemDetailsVO).kbk_limits);
        this.supplier_requirements = new PurchaseItemDetailsXMLRequestVO(purchase).supplier_requirements!;
        this.supplier_accounting = supplier_accounting;
        this.customer_contract_number = customer_contract_number;
        this.xml = xml;
        this.thumbprint = certificate.thumbprint;
        this.certificate = certificate;
    }

    private limitsFactory(limits: KBKLimitsSpecificationItemsEditableCollection): IKBKLimit[] {
        return limits.items.map((item: KBKLimitSpecificationItemEditableVO) => {
            return new KBKLimitUpdateItemVO(item as KBKLimitUpdateItemVO);
        });
    }

    private supplierRequirementsFactory(purchase: PurchaseItemDetailsVO): ISupplierRequirements {
        return {
            supplier_reqs_a: purchase.supplier_requirements.a31s1p1,
            supplier_reqs_b: purchase.supplier_requirements.a31s1ps3_5ps7_11,
            supplier_reqs_c: purchase.supplier_requirements.a31s1_1,
            supplier_reqs_sonko: purchase.supplier_requirements.supplier_reqs_sonko,
            supplier_required_documents: purchase.supplier_requirements.a31s1p1_details.supporting_documents.map((document: SRDA31s1p1SupportingDocumentVO) => {
                return {
                    id: document.id as number,
                    name: document.description as string,
                    storedDocument: {
                        id: document.attachment?.file_id as string,
                        name: document.attachment?.name as string
                    }
                };
            }),
            supplier_reqs_rating: purchase.supplier_requirements.rating,
            additional: SupplierRequirementAdditionalVO.toRequestPayload(purchase.supplier_requirements.additional)
        };
    }
}
