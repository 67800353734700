'use strict';

import global from '@core/config/global.json';

// @ts-ignore
import theme from 'themes/config/theme.json';

import get from 'lodash.get';
import has from 'lodash.has';

export default class ThemeConfig {
    protected static _config: ThemeConfig;

    constructor() {
        ThemeConfig._config = { global: { ...global }, ...theme };
    }

    public static get(path?: string, fallback?: any): any {
        if (!ThemeConfig._config) {
            new ThemeConfig();
        } // NB: Create config if it was not before.

        if (path && fallback === undefined && !has(ThemeConfig._config, path)) {
            throw new Error(`SVTA Error: theme config variable "${path}" not found, no fallback value was given either.`);
        }

        return path ? get(ThemeConfig._config, path, fallback !== undefined ? fallback : undefined) : ThemeConfig._config;
    }
}
