<template>
    <ul class="atmo-form atmo-filters">
        <clearable-input v-slot:default="ci">
            <li class="atmo-form__field atmo-inverse">
                <label for="kbk-limit-code">КБК</label>
                <input v-model="$props.controller.request.filter.kbk" type="text" id="kbk-limit-code" placeholder="Введите КБК" autocomplete="off" />
                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
            </li>
        </clearable-input>

        <clearable-input v-slot:default="ci">
            <li class="atmo-form__field atmo-inverse">
                <label for="kbk-limit-funds-sourcing-code">Код типа средств</label>
                <input v-model="$props.controller.request.filter.funds_type" type="text" id="kbk-limit-funds-sourcing-code" placeholder="Введите код типа средств" autocomplete="off" />
                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
            </li>
        </clearable-input>

        <clearable-input v-slot:default="ci">
            <li class="atmo-form__field atmo-inverse">
                <label for="kbk-limit-sub-kosgu-code">Код CубКОСГУ</label>
                <input v-model="$props.controller.request.filter.subkosgu_code" type="text" id="kbk-limit-sub-kosgu-code" placeholder="Введите код СубКОСГУ" autocomplete="off" />
                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
            </li>
        </clearable-input>
    </ul>
</template>

<script>
import ClearableInput from '@lib/js/src/vue/components/ClearableInput.viewmodel';

export default {
    props: {
        controller: { required: true, type: Object }
    },
    components: {
        'clearable-input': ClearableInput
    }
};
</script>
