'use strict';

import State from './state';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import ExtendedDictionaryCollection from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionary.collection';
import Collection from '@/rearchitected/lib/js/src/misc/Collection';

const getters = {
    types: function (state: State): ExtendedDictionaryCollection<unknown> | null {
        return state.types;
    },
    statuses: function (state: State): ExtendedDictionaryItem[] | null {
        return state.statuses;
    },
    menustatuses: function (state: State): ExtendedDictionaryItem[] | null {
        return state.menustatuses;
    },
    contractstatuses: function (state: State): ExtendedDictionaryItem[] | null {
        return state.contractstatuses;
    },
    durations: function (state: State): StandardDictionaryItem[] | null {
        return state.durations;
    },
    urgencyreasons: function (state: State): StandardDictionaryItem[] | null {
        return state.urgencyreasons;
    },
    deliverablesgroups: function (state: State): ExtendedDictionaryItem[] | null {
        return state.deliverablesgroups;
    },
    municipalities: function (state: State): ExtendedDictionaryItem[] | null {
        return state.municipalities;
    },
    limityears: function (state: State): StandardDictionaryItem[] | null {
        return state.limityears;
    },
    all: function (state: State): Record<string, StandardDictionaryItem[] | ExtendedDictionaryItem[] | Collection<unknown> | null> {
        return {
            types: state.types,
            statuses: state.statuses,
            menustatuses: state.menustatuses,
            durations: state.durations,
            deliverablesgroups: state.deliverablesgroups,
            municipalities: state.municipalities
        };
    }
};

export default getters;
