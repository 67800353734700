var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c(
      "table",
      { staticClass: "table b-table" },
      _vm._l(this.$props.disputes, function (dispute, index) {
        return _c(
          "fragment",
          { key: index },
          [
            _c("tr", [
              _c("th", { attrs: { width: "1" } }, [_vm._v("№")]),
              _c("th", [_vm._v("Протокол разногласий (поставщик)")]),
              _c("th", [_vm._v("Комментарий поставщика")]),
              _c("th", { attrs: { width: "100" } }, [_vm._v("Направлен")]),
              _c("th", { attrs: { width: "50" } }, [_vm._v("Подпись")]),
            ]),
            _c("tr", [
              _c(
                "td",
                { attrs: { rowspan: dispute.reply.isEmpty() ? 1 : 3 } },
                [_vm._v(_vm._s(index + 1))]
              ),
              _c("td", [
                _c(
                  "a",
                  {
                    staticClass: "atmo-button-link",
                    staticStyle: { color: "var(--green-color) !important" },
                    attrs: {
                      href: _vm.$getAttachmentDownloadLink(
                        dispute.protocol.attachment.file_id
                      ),
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(dispute.protocol.attachment.name))]
                ),
              ]),
              _c("td", [_vm._v(_vm._s(dispute.protocol.comment))]),
              _c("td", { staticClass: "text-wrap" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatdate")(
                      dispute.protocol.created_at,
                      "withSeconds"
                    )
                  )
                ),
              ]),
              _c(
                "td",
                { staticClass: "text-center text-nowrap" },
                [
                  _c("action-button-small", {
                    attrs: {
                      icon: "clipboard-check",
                      title: "Информация о подписи",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$props.pivc.invokeSignaturePopup([
                          dispute.protocol.signature,
                        ])
                      },
                    },
                  }),
                  dispute.protocol.signature.poa_number &&
                  dispute.protocol.signature.poa_check_link
                    ? _c("action-button-small", {
                        staticClass: "ml-1",
                        attrs: {
                          icon: "clipboard-plus",
                          title: "Информация о МЧД",
                          variant: "warning",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$props.pivc.invokeSignaturePopup([
                              dispute.protocol.signature,
                            ])
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            !dispute.reply.isEmpty()
              ? [
                  _c("tr", [
                    _c("th", [
                      _vm._v("Ответ на протокол разногласий (заказчик)"),
                    ]),
                    _c("th", [_vm._v("Комментарий заказчика")]),
                    _c("th", [_vm._v("Направлен")]),
                    _c("th", [_vm._v("Подпись")]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "atmo-button-link",
                          staticStyle: {
                            color: "var(--green-color) !important",
                          },
                          attrs: {
                            href: _vm.$getAttachmentDownloadLink(
                              dispute.reply.attachment.file_id
                            ),
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(dispute.reply.attachment.name))]
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(dispute.reply.comment))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatdate")(
                            dispute.reply.created_at,
                            "withSeconds"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-center text-nowrap" },
                      [
                        _c("action-button-small", {
                          attrs: {
                            icon: "clipboard-check",
                            title: "Информация о подписи",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$props.pivc.invokeSignaturePopup([
                                dispute.reply.signature,
                              ])
                            },
                          },
                        }),
                        dispute.reply.signature.poa_number &&
                        dispute.reply.signature.poa_check_link
                          ? _c("action-button-small", {
                              staticClass: "ml-1",
                              attrs: {
                                icon: "clipboard-plus",
                                title: "Информация о МЧД",
                                variant: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$props.pivc.invokeSignaturePopup([
                                    dispute.reply.signature,
                                  ])
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }