<template>
    <h3 class="grey-color mb-3 main_text font-weight-bold">
        <slot></slot>
    </h3>
</template>

<script>
export default {
    name: 'text-header-new'
};
</script>
