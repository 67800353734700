'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';

import SRDA31s1p1SupportingDocumentVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';

interface IData {
    list: SRDA31s1p1SupportingDocumentVO[];
}

@Component
export default class SupplierRequirementSupportingDocumentsController extends BaseViewModel {
    public static updateEvent = 'supportingdocumentslist:updated';
    public static inputIdPrefix = 'supporting-document-description-';

    constructor() {
        super();
        this.name = 'SupplierRequirementSupportingDocumentsController';
    }

    @Prop({ required: true, type: Array }) readonly initiallist?: SRDA31s1p1SupportingDocumentVO[];

    created(): void {
        this.$data.list = this.$props.initiallist;
    }

    data(): IData {
        return {
            list: []
        };
    }

    /**
     * Computed
     */
    get isListEmpty(): boolean {
        return this.$data.list.length < 1;
    }

    get inputIdPrefix(): string {
        return SupplierRequirementSupportingDocumentsController.inputIdPrefix;
    }

    /**
     * Watch
     */
    @Debounce(500)
    @Watch('list', { deep: true })
    async onListChanged(newList: SRDA31s1p1SupportingDocumentVO[]) {
        const pojo = JSON.parse(JSON.stringify(newList));
        this.$root.$emit(SupplierRequirementSupportingDocumentsController.updateEvent, pojo);
    }

    /**
     * Methods
     */
    public add(): void {
        this.$data.list.push(new SRDA31s1p1SupportingDocumentVO());
        this.setInputFocus();
    }

    public remove(index: number): void {
        this.$data.list.splice(index, 1);
    }

    /**
     * General prototype methods.
     */
    private setInputFocus(): void {
        this.$nextTick(() => {
            (this.$el.querySelector(`#${this.inputIdPrefix}${this.$data.list.length - 1}`) as HTMLInputElement)!.focus();
        });
    }
}
