<template>
    <notice-item-fl223 :pi="pi" />
</template>

<script>
// import DealStageActionButton from './DealStageActionButton.view.vue';

import NoticeItemFl223 from './NoticeItemFl223.view.vue';

export default {
    props: {
        pi: { required: true, type: Object }
    },
    components: {
        NoticeItemFl223
        // DealStageActionButton
    }
};
</script>
