<template>
    <b-modal :visible="show" hide-footer no-close-on-backdrop scrollable no-close-on-esc size="xl" title="Спецификация" @cancel="cancelSpecModal" @close="cancelSpecModal">
        <template #modal-header-close>
            <button-close-modal />
        </template>
        <b-row>
            <filter-text :md="0" v-model="ktruTableFilter.reg_number" label="Рег.номер" placeholder="Введите рег.номер" @input="onKtruFilterChange" />
            <filter-text :md="0" v-model="ktruTableFilter.name" label="Название" placeholder="Введите название" @input="onKtruFilterChange" />
            <filter-text :md="0" v-model="ktruTableFilter.specs" label="Характеристики" placeholder="Введите текст характеристик" @input="onKtruFilterChange" />
            <filter-text :md="0" v-model="ktruTableFilter.okpd_code" label="Код ОКПД2" placeholder="Введите код ОКПД2 или его часть" @input="onKtruFilterChange" />
            <b-col v-if="allowFavorite" md="auto" class="favorite-btn text-right">
                <b-button v-if="ktruTableFilter.showFavorite" class="text-uppercase" variant="custom-outline-secondary" @click="showHideFavorites">Избранное <b-icon icon="star-fill" class="ml-2" /></b-button>
                <b-button v-else class="text-uppercase" variant="custom-green" @click="showHideFavorites">Избранное <b-icon icon="star" class="ml-2" /></b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!ktruItems.length" class="text-center mt-5">
                <img src="/images/empty_box.svg" alt="empty-data" />
                <p class="fs-14 text-danger my-4">
                    Позиции КТРУ по вашему запросу не найдены.
                    <span v-if="allowCreateRequest"> Вы можете <router-link :to="{ name: 'KtruRequestsCreate' }" target="_blank">сформировать запрос на добавление позиции КТРУ</router-link>.</span>
                </p>
            </b-col>
            <b-col v-else class="mb-3">
                <b-table id="ktru-table" :busy="isLoading" :fields="fields" :items="ktruItems" class="table-atmo" small hover responsive>
                    <template #row-details="data">
                        <p v-if="data.item.description"><b>Описание:</b> {{ data.item.description }}</p>
                        <p v-if="data.item.specs && data.item.specs.length > 0"><b>Характеристики:</b></p>
                        <ul v-if="data.item.specs && data.item.specs.length > 0">
                            <li v-for="(spec, key) in data.item.specs" :key="'spec' + key">
                                <span style="font-weight: 900">{{ spec.name }}</span
                                >: {{ spec.value }}
                            </li>
                        </ul>
                    </template>
                    <template #cell(reg_num)="data">
                        {{ data.item.reg_number }}
                    </template>
                    <template #cell(okpd)="data">
                        {{ data.item.okpd_code }}
                    </template>
                    <template #cell(specs)="data"> <action-button-small v-b-popover.hover.top="(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'" :icon="data.detailsShowing ? 'eye-slash' : 'eye'" @click="data.toggleDetails" /> {{ data.item.name }} </template>
                    <template #cell(okei)="data">
                        {{ data.item.okei_local_symbol }}
                    </template>
                    <template #cell(country)="data">
                        {{ data.item.country_of_origin_name }}
                    </template>
                    <template #cell(actions)="data">
                        <action-button-small icon="plus" @click="selectKtruItem(data.item)" />
                        <template v-if="allowFavorite">
                            <action-button-small v-if="!data.item.is_favorite" class="ml-1" v-b-popover.hover.left="'Добавить в избранное'" icon="star" @click="addDeleteFavoriteItem(data.item)" />
                            <action-button-small v-else variant="outline" class="ml-1" v-b-popover.hover.left="'Удалить из избранного'" icon="star-fill" @click="addDeleteFavoriteItem(data.item)" />
                        </template>
                    </template>
                </b-table>
                <b-pagination v-if="ktruItems && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" aria-controls="ktru-table" class="my-0 py-1" @input="getKtruItems" />
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import FilterText from '@/components/common/filter-components/filter-text';

export default {
    name: 'PurchaseSpecificationModal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        isImportSubstitutionRequest: {
            type: Boolean,
            default: false
        },
        isImportSubstitutionReply: {
            type: Boolean,
            default: false
        },
        allowCreateRequest: {
            type: Boolean,
            default: true
        },
        allowFavorite: {
            type: Boolean,
            default: true
        }
    },
    components: {
        FilterText
    },
    data() {
        return {
            isLoading: true,
            currentPage: 1,
            perPage: 5,
            ktruItems: [],
            ktruSelectModalShown: false,
            selectedKtruItem: null,
            selectedKtruItemId: null,
            fields: [
                { key: 'reg_num', label: 'Рег.№', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '1px' } },
                { key: 'okpd', label: 'ОКПД2', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '1px' } },
                { key: 'specs', label: 'Наименование товара (работ, услуг)', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1' },
                { key: 'okei', label: 'Ед.изм.', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'text-nowrap py-1', thStyle: { width: '1px' } },
                { key: 'country', label: 'Страна происхождения', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1', thStyle: { width: '1px' } },
                { key: 'actions', label: '', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'text-nowrap py-1', thStyle: { width: '1px' } }
            ],
            spec: {
                number: 1,
                order: 1,
                name: '',
                count: 0,
                okei: null,
                ktru_item_id: null,
                ktru: null,
                okpd: null
            },
            forFilters: {
                countries: []
            },
            sortOrder: [{ field: 'id', direction: 'asc' }],
            ktruTableFilter: {
                name: '',
                reg_number: '',
                specs: '',
                okpd_code: '',
                country_of_origin_id: [],
                showFavorite: false,
                is_import_substitution_request: this.isImportSubstitutionRequest,
                is_import_substitution_reply: this.isImportSubstitutionReply
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    async created() {
        this.$_debounceTimer = null;
        await this.fillFilters();
        await this.getKtruItems();
    },
    methods: {
        async fillFilters() {
            const data = await this.$api.dictionary.getList(['countries']);
            this.forFilters.countries = data.countries;
        },
        getFilters(page = true) {
            const filterValues = [];
            for (const [key, value] of Object.entries(this.ktruTableFilter)) {
                if (value) {
                    filterValues[key] = value;
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1
            };
        },
        async getKtruItems(page = true) {
            try {
                this.isLoading = true;
                const response = await this.$api.ktru.getList(this.getFilters(page));
                this.ktruItems = response.data;
                this.meta = response.meta;
            } catch {
                this.ktruItems = [];
            } finally {
                this.isLoading = false;
            }
        },
        async showHideFavorites() {
            this.ktruTableFilter.showFavorite = !this.ktruTableFilter.showFavorite;
            await this.getKtruItems(false);
        },
        async addDeleteFavoriteItem(item) {
            this.isLoading = true;
            if (!item.is_favorite) {
                const formData = {
                    ktru_item_id: item.id
                };
                if (await this.$api.cabinet.ktru.ktruFavorites.addKtruItem(formData)) await this.getKtruItems();
            } else {
                if (await this.$api.cabinet.ktru.ktruFavorites.deleteKtruItem(item.favorite_id)) await this.getKtruItems(!this.ktruTableFilter.showFavorite);
            }
        },
        debounce(method, timer) {
            if (this.$_debounceTimer !== null) {
                clearTimeout(this.$_debounceTimer);
            }
            this.$_debounceTimer = setTimeout(() => {
                method();
            }, timer);
        },
        cancelKtruSelectModal() {
            this.selectedKtruItem = null;
            this.selectedKtruItemId = null;
            this.ktruSelectModalShown = false;
        },
        showKtruSelectModal() {
            this.ktruSelectModalShown = true;
        },
        cancelSpecModal() {
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
            this.clearSelectedKtru();
            this.$emit('cancel');
        },
        addSpec() {
            this.$emit('add', this.spec);
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
            this.cancelKtruSelectModal();
        },
        selectKtruItem(selectedItem) {
            this.selectedKtruItem = selectedItem;
            this.selectedKtruItemId = this.selectedKtruItem.id;
            this.spec.name = this.selectedKtruItem.name;
            this.spec.okei = this.selectedKtruItem.okei;
            this.spec.okpd = this.selectedKtruItem.okpd;
            this.spec.ktru = this.selectedKtruItem;
            this.spec.ktru_item_id = this.selectedKtruItem.id;
            this.ktruSelectModalShown = false;
            this.$emit('add', this.spec);
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
            this.cancelKtruSelectModal();
        },
        clearSelectedKtru() {
            this.selectedKtruItem = null;
            this.selectedKtruItemId = null;
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
        },
        onKtruFilterChange() {
            this.meta.current_page = 1;
            this.getKtruItems();
        }
    }
};
</script>

<style>
.modal-xl {
    max-width: 95% !important;
}
</style>

<style scoped>
.favorite-btn {
    margin-top: 28px;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 8px;
    color: var(--grey-color);
    border-top: 0;
}
.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}
</style>
