'use strict';

import Config from '@core/js/ddriven/application/config/Config';
import ActiveDealStage from '../../deal/ActiveDealStage.valueobject';

export interface IPurchaseRawDataItem {
    id: number;
    purchase_object: string | null;
    notice_reg_number: string;
    purchase_name: string;
    status_id: number;
    is_hold: boolean;
    start_price: number;
    items_count: number;
    delivery_addresses: string[];
    planned_end_date: string;
    protocol_date: string;
    org_id: number;
    organization: string;
    offersCount: number;
    bestOffer: number;
    is_hidden: boolean;
    hide_proposals_at: null | string;
    is_participation?: boolean;
    discount: number;
    purchase_end: string;
    organizationFinal: null | {
        id: number;
        full_name: string;
    };
    contract: null | {
        id: number;
        updated_at: string;
    };
    conditions: Record<string, boolean>;
}

export default class PurchaseItem {
    id: number;
    registration_number: string;
    purchase_object: string | null;
    description: string;
    status_id: number;
    is_on_hold: boolean;
    starting_price: number;
    items_count: number;
    delivery_addresses: string[];
    planned_delivery_date: string;
    results_protocol_date: null | string;
    customer_organization_id: number;
    customer_organization_title: string;
    proposals_count: number;
    best_proposal_price: number;
    is_best_proposal_hidden: boolean;
    hide_proposals_at: null | string;
    has_proposal_from_loggedin_organization: boolean;
    best_proposal_discount_percent: number;
    proposal_accept_end_date: string;
    supplier_organization_id: null | number;
    supplier_organization_title: null | string;
    contract: null | { id: number; date: string };
    active_deal_stage: ActiveDealStage | null;

    constructor(purchaseData: IPurchaseRawDataItem) {
        const timezone = Config.get('timezone.customer') as string;

        this.id = purchaseData.id;
        this.registration_number = purchaseData.notice_reg_number;
        this.description = purchaseData.purchase_name;
        this.purchase_object = purchaseData.purchase_object;
        this.status_id = this.statusId(purchaseData.status_id, purchaseData.is_hold);
        this.is_on_hold = purchaseData.is_hold;
        this.starting_price = purchaseData.start_price;
        this.items_count = purchaseData.items_count;
        this.delivery_addresses = purchaseData.delivery_addresses;
        this.planned_delivery_date = new Date(purchaseData.planned_end_date).toISOString();
        this.results_protocol_date = purchaseData.protocol_date ? new Date(purchaseData.protocol_date).toISOString() : null;
        this.customer_organization_id = purchaseData.org_id;
        this.customer_organization_title = purchaseData.organization;
        this.proposals_count = purchaseData.offersCount;
        this.best_proposal_price = purchaseData.bestOffer;
        this.is_best_proposal_hidden = purchaseData.is_hidden;
        this.hide_proposals_at = purchaseData.hide_proposals_at;
        this.has_proposal_from_loggedin_organization = purchaseData.is_participation ?? false;
        this.best_proposal_discount_percent = purchaseData.discount;
        this.proposal_accept_end_date = purchaseData.purchase_end;
        this.supplier_organization_id = purchaseData.organizationFinal ? purchaseData.organizationFinal.id : null;
        this.supplier_organization_title = purchaseData.organizationFinal ? purchaseData.organizationFinal.full_name : null;
        this.contract = purchaseData.contract ? { id: purchaseData.contract.id, date: purchaseData.contract.updated_at } : null;
        this.active_deal_stage = ActiveDealStage.factoryFromDealActivities(purchaseData.conditions);
    }

    public hasProposals(): boolean {
        return this.proposals_count > 0;
    }

    private statusId(statusId: number, is_on_hold: boolean): number {
        return statusId === 1 && is_on_hold ? 99 : statusId;
    }
}
