import store from '@/store';
import axios from 'axios';
import Config from '@/rearchitected/core/js/ddriven/application/config/Config';

export class BaseCabinetApiService {
    baseUrl = Config.get('api.config.v1.url') + '/cabinet';
    resource;

    constructor(resource) {
        if (!resource) throw new Error('Resource is not provided');
        this.resource = resource;
    }

    getUrl(id = '') {
        return id ? `${this.baseUrl}/${this.resource}/${id}` : `${this.baseUrl}/${this.resource}`;
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        store.dispatch('showError', err).then(() => {});
    }
}

export class ReadOnlyCabinetApiService extends BaseCabinetApiService {
    constructor(resource) {
        super(resource);
    }

    async fetch(params) {
        try {
            const response = await axios.get(this.getUrl(), { params: { ...params } });
            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async get(id) {
        if (!id) throw Error('Id is not provided');
        try {
            const response = await axios.get(this.getUrl(id));
            return await response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
    async getSelectItems() {
        try {
            const response = await axios.get(this.getUrl('get_select_items'));
            return await response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export class CabinetModelApiService extends ReadOnlyCabinetApiService {
    constructor(resource) {
        super(resource);
    }

    async post(data = {}) {
        try {
            await axios.post(this.getUrl(), data);
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async put(id, data = {}) {
        if (!id) throw Error('Id is not provided');
        try {
            await axios.put(this.getUrl(id), data);
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(id) {
        if (!id) throw Error('Id is not provided');
        try {
            await axios.delete(this.getUrl(id));
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
