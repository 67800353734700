var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("on-off", {
    attrs: {
      options: {
        id: "deliverables-selector-popup",
        onEsc: false,
        onBody: false,
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (onoff) {
          return [
            onoff.ison
              ? _c(
                  "div",
                  { staticClass: "atmo-popup-wrapper" },
                  [
                    _c("deliverables-selector-popup-controller", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (dspc) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "atmo-popup-content atmo-deliverables-selector-popup",
                                  },
                                  [
                                    dspc.isloading
                                      ? _c(
                                          "div",
                                          { staticClass: "atmo-spinner" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../../../../assets/images/loaders/spinner-sector.svg"),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "atmo-button--small atmo-close",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return onoff.off.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "atmo-icon-close",
                                        }),
                                      ]
                                    ),
                                    _c("h5", [
                                      _vm._v("Выбрать позицию спецификации"),
                                    ]),
                                    _c(
                                      "header",
                                      [
                                        _c("DeliverablesSelectorFilter.view", {
                                          attrs: { controller: dspc },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "main",
                                      [
                                        _c("DeliverablesSelectorList.view", {
                                          key: dspc.random(),
                                          attrs: {
                                            controller: dspc,
                                            deliverables: dspc.list,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "footer",
                                      [
                                        !dspc.islistempty
                                          ? _c("StandardPagination.view", {
                                              attrs: {
                                                pagination: dspc.pagination,
                                                pageupdatehandler:
                                                  dspc.pageUpdate,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }