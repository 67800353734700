'use strict';

export default class ConfigUtils {
    public static getSafeEnv(key: string, defaultValue?: any): string {
        const result = process.env[key];

        if (result === undefined && defaultValue === undefined) {
            throw new Error(`ATMO Exception: env variable "${key}" is required.`);
        }

        return result ?? defaultValue;
    }
}
