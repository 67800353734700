var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      class: _vm.compact ? "mb-0" : "",
      attrs: {
        "label-cols-sm": _vm.labelColsSm,
        "label-cols-lg": _vm.labelColsLg,
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _vm.value.length > 0
        ? _c(
            "ul",
            { attrs: { id: _vm.id } },
            _vm._l(_vm.value, function (document) {
              return _c("li", { key: document.id }, [
                _c(
                  "a",
                  {
                    staticClass: "fs-14 col-form-label d-inline-block",
                    attrs: {
                      href: _vm.$getAttachmentDownloadLink(document.id),
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(document.name))]
                ),
              ])
            }),
            0
          )
        : _c(
            "p",
            {
              staticClass: "fs-14 grey-color my-auto col-form-label",
              attrs: { id: _vm.id },
            },
            [_vm._v("Отсутствуют")]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }