'use strict';

import PurchaseItem from '@core/js/ddriven/domain/model/purchases/view/list/PurchaseItem.valueobject';
import { IPurchaseRequest } from '@core/js/ddriven/application/http/requests/purchases/PurchaseRequest.valueobject';
import { IState } from './state';
import StandardPagination from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import PurchaseItemDetailsVO from '@core/js/ddriven/domain/model/purchases/view/item/PurchaseItemDetails.valueobject';
import PurchaseItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditable.valueobject';

export default {
    list: function (state: IState): Readonly<PurchaseItem>[] {
        return state.list;
    },

    item: function (state: IState): Readonly<PurchaseItemDetailsVO> {
        return state.item;
    },

    itemeditable: function (state: IState): PurchaseItemEditableVO {
        return state.itemeditable;
    },

    request: function (state: IState): IPurchaseRequest {
        return state.request;
    },

    currentstatus: function (state: IState): string | number | null {
        return state.request.status;
    },

    pagination: function (state: IState): StandardPagination | null {
        return state.pagination;
    },

    routereset: function (state: IState): boolean {
        return state.routereset;
    }
};
