var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accordion atmo-content-group", attrs: { role: "tablist" } },
    [
      _c(
        "b-card",
        { staticClass: "mb-1", attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              staticClass: "p-1 custom-green",
              attrs: { "header-tag": "header", role: "tab" },
              on: {
                click: function ($event) {
                  _vm.displayed = !_vm.displayed
                },
              },
            },
            [
              _c(
                "h3",
                {
                  staticClass:
                    "white-color font-weight-bold my-2 ml-3 cursor-pointer",
                },
                [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                  _c(
                    "span",
                    { staticClass: "float-right mr-3" },
                    [
                      _c("b-icon", {
                        staticClass: "icon alt",
                        attrs: {
                          icon: _vm.displayed ? "chevron-up" : "chevron-down",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              attrs: {
                id: _vm.id,
                accordion: "my_" + _vm.id,
                role: "tabpanel",
              },
              model: {
                value: _vm.displayed,
                callback: function ($$v) {
                  _vm.displayed = $$v
                },
                expression: "displayed",
              },
            },
            [_c("b-card-body", [_c("b-card-text", [_vm._t("default")], 2)], 1)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }