var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-progress-indicating-button",
      class: { "atmo-isprogressing": _vm.isprogressing },
    },
    [
      _vm._t(
        "default",
        function () {
          return [
            _vm.$props.type === "dimmed"
              ? _c(
                  "button",
                  {
                    staticClass: "atmo-button-inverse atmo-dimmed",
                    attrs: { disabled: _vm.isprogressing },
                    on: { click: _vm.baseClickHandler },
                  },
                  [_vm._v(_vm._s(_vm.$props.text))]
                )
              : _c(
                  "button",
                  {
                    staticClass: "atmo-button",
                    class: _vm.$props.classes,
                    attrs: {
                      disabled: _vm.isprogressing || _vm.$props.disabledstate,
                    },
                    on: { click: _vm.baseClickHandler },
                  },
                  [_vm._v(_vm._s(_vm.$props.text))]
                ),
          ]
        },
        { component: _vm.component }
      ),
      _vm.isprogressing
        ? _c("div", { staticClass: "atmo-spinner" }, [
            _c("img", {
              attrs: {
                src: require("@theme/assets/images/loaders/spinner-sector.svg"),
                alt: "Waiting spinner",
              },
            }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }