'use strict';

import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';

export default class StoreContractRequestVO {
    purchase_id: number;
    provider_id: number;
    contract_file: {
        id: string;
    };

    constructor(purchaseId: number, supplierId: number, attachment: FileAttachmentVO) {
        this.purchase_id = purchaseId;
        this.provider_id = supplierId;
        this.contract_file = {
            id: attachment.file_id as string
        };
    }
}
