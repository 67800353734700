var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(_vm.layout, { tag: "component" }, [_c("router-view")], 1),
      _c(
        "div",
        { staticClass: "toast_box" },
        [
          _c(
            "transition-group",
            { attrs: { name: "animate" } },
            _vm._l(_vm.toasts, function (toast) {
              return _c("toast", {
                key: "toast_" + toast.id,
                attrs: { toast: toast },
                on: {
                  hide: function ($event) {
                    return _vm.removeToast($event)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }