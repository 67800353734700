'use strict';

import { IRawDeliverableItemRequirement } from './DeliverableItemRequirement.valueobject';

export interface IRawGSCDetails {
    id: number;
    okei_id: number;
    reg_number: string;
    ktru_group_name: string | null;
    name: string | null;
    type: string | null;
    description: string | null;
    specs: IRawDeliverableItemRequirement[];
}

export interface IRawAnnexGSCDetails {
    id: number;
    reg_number: string;
    specs: IRawDeliverableItemRequirement[];
}

export interface IRawGSCDictionaryEntry {
    id: number | null;
    name: string;
    okpd: {
        id: number;
        code: string;
        name: string;
    };
    okei: {
        id: number;
        local_symbol: string;
    };
    reg_number: string;
    ktru_group_name: string;
    type: string;
    description: string;
    specs: IRawDeliverableItemRequirement[];
}

// NB: GSC short for Goods & Services Catalogue (КТРУ in Russian);
export default class GSCDetailsVO {
    id: number;
    registration_number: string;
    group: string | null;
    title: string | null;
    type: string | null;
    description: string | null;

    constructor(rawGSCDetailsData: IRawGSCDetails) {
        this.id = rawGSCDetailsData?.id ?? null;
        this.registration_number = rawGSCDetailsData?.reg_number ?? null;
        this.group = rawGSCDetailsData?.ktru_group_name ?? null;
        this.title = rawGSCDetailsData?.name ?? null;
        this.type = rawGSCDetailsData?.type ?? null;
        this.description = rawGSCDetailsData?.description ?? null;

        Object.seal(this);
    }

    public static fromGSCDictionaryEntry(data: IRawGSCDictionaryEntry): GSCDetailsVO {
        const rawGSCDetails: IRawGSCDetails = {
            id: data.id as number,
            okei_id: data.okei.id,
            reg_number: data.reg_number,
            ktru_group_name: data.ktru_group_name,
            name: data.name,
            type: data.type,
            description: data.description,
            specs: []
        };

        return new GSCDetailsVO(rawGSCDetails);
    }

    public isEmpty(): boolean {
        return this.registration_number === null;
    }
}
