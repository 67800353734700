import axios from 'axios';
import { BaseV1ApiService } from '@/services/backend/baseV1';
import store from "@/store";

export class Fl44CabinetCustomerContracts extends BaseV1ApiService {
    constructor() {
        super('cabinet/customer/contracts');
    }

    async getList(filters) {
        try {
            const response = await axios.get(this.getUrl('fl44'), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    getContractsXlsExport(filters) {
        return axios.post(this.getUrl('xls'), filters, { responseType: 'blob' });
    }

    async storeBlockRequest(blockMessage, purchaseId) {
        try {
            await axios.post(this.getUrl(purchaseId), { block_message: blockMessage });
            await store.dispatch('showSuccessToast', 'Запрос на блокировку закупки успешно создан');
            return true;
        } catch (error) {
            this.handleErrors(error);
            throw error;
        }
    }
}
