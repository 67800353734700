import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';
import store from '@/store';

export class PersonalFl44ExternalPurchasesAPIService extends BaseApiService {
    constructor() {
        super('personal/fl44/external_purchases');
    }

    async updateComment(comment, id) {
        try {
            await axios.put(this.getUrl(`${id}/update_comment`), { comment: comment });
            await store.dispatch('showSuccessToast', 'Комментарий успешно обновлён');
            return true;
        } catch (error) {
            this.handleErrors(error);
            throw new Error(error.message);
        }
    }

    async update(payload, id) {
        try {
            await axios.put(this.getUrl(`${id}`), payload);
            await store.dispatch('showSuccessToast', 'Внесистемная закупка успешно обновлена');
            return true;
        } catch (error) {
            this.handleErrors(error);
            throw new Error(error.message);
        }
    }

    async getEditData(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}/edit`));
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                throw error;
            }
            this.handleErrors(error);
        }
    }

    async getSupplementaryAgreementEditData(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}/supplementary_agreement`));
            return response.data;
        } catch (error) {
            this.handleErrors(error);
            throw error;
        }
    }

    async storeSupplementaryAgreement(id, payload) {
        try {
            await axios.post(this.getUrl(`${id}/supplementary_agreement`), payload);
            await store.dispatch('showSuccessToast', 'Дополнительное соглашение успешно добавлено');
            return true;
        } catch (error) {
            this.handleErrors(error);
            throw error;
        }
    }

    async updateSupplementaryAgreementLimits(purchaseId, id, payload) {
        try {
            await axios.put(this.getUrl(`${purchaseId}/supplementary_agreement/${id}`), payload);
            await store.dispatch('showSuccessToast', 'Лимиты успешно обновлены');
            return true;
        } catch (error) {
            this.handleErrors(error);
            throw error;
        }
    }

    async changeStatus(purchaseId, payload) {
        try {
            await axios.post(this.getUrl(`${purchaseId}/change_status`), { ...payload });
            await store.dispatch('showSuccessToast', 'Статус успешно изменён');
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async storeExecutionDocument(purchaseId, payload) {
        try {
            await axios.post(this.getUrl(`${purchaseId}/execution_documents`), payload);
            await store.dispatch('showSuccessToast', 'Документ успешно добавлен');
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async deleteExecutionDocument(purchaseId, storedDocumentId) {
        try {
            await axios.delete(this.getUrl(`${purchaseId}/execution_documents/${storedDocumentId}`));
            await store.dispatch('showSuccessToast', 'Документ успешно удалён');
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async deleteDraft(id) {
        try {
            await axios.delete(this.getUrl(`${id}`));
            await store.dispatch('showSuccessToast', 'Закупка успешно удалена');
            return true;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async getDraft(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}/draft`));
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                throw error;
            }
            this.handleErrors(error);
        }
    }

    async updateDraft(purchase, isDraft, id) {
        try {
            const formData = {
                is_draft: isDraft,
                ...purchase
            };
            const response = await axios.put(this.getUrl(`${id}/update`), formData);
            await store.dispatch('showSuccessToast', `${isDraft ? 'Черновик успешно обновлён' : 'Внесистемная закупка сформирована'}`);
            return response.data;
        } catch (error) {
            this.handleErrors(error);
            throw new Error(error.message);
        }
    }

    async store(purchase, isDraft) {
        try {
            const formData = {
                is_draft: isDraft,
                ...purchase
            };
            const response = await axios.post(this.getUrl(), formData);
            await store.dispatch('showSuccessToast', `${isDraft ? 'Черновик успешно сохранён' : 'Внесистемная закупка сформирована'}`);
            return response.data;
        } catch (error) {
            this.handleErrors(error);
            throw new Error(error.message);
        }
    }

    async getFailedPurchase(id) {
        try {
            const response = await axios.get(this.getUrl(`failed_purchase/${id}`));
            return response.data;
        } catch (error) {
            this.handleErrors(error);
            throw error;
        }
    }

    async findProvider(payload) {
        try {
            const response = await axios.post(this.getUrl('find_provider'), payload);
            return response.data;
        } catch (error) {
            if (error.response.status === 406) {
                store.dispatch('showDangerToast', error.response.data.message).then(() => {});
                return 'branches';
            }
            this.handleErrors(error);
        }
    }
}
