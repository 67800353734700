'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import FileAttachmentVO, { FileAttachmentOrigin } from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import { AxiosResponse } from 'axios';
import DownloadConfigFactory from '@core/js/ddriven/domain/services/downloads/DownloadConfigFactory';
import { FileInputEvent } from '@core/js/ddriven/application/abstractions/types/FileInputEvent';
import ApplicationServiceLocator from '../../../ddriven/application/services/ApplicationServiceLocator';
import isEmpty from 'lodash.isempty';

export enum Command {
    Add = 'add',
    Remove = 'remove'
}

export interface IFileAttachmentVMEvent {
    origin: FileAttachmentOrigin;
    command: Command;
    attachment: FileAttachmentVO;
    index?: number;
}

interface IData {
    is_waiting: boolean;
    list: FileAttachmentVO[];
}

@Component
export default class EditableFileAttachmentsListController extends BaseViewModel {
    protected static DEFAULT_MAX_FILES_ALLOWED = 1000;

    private inputEl?: HTMLInputElement;

    constructor() {
        super();
        this.name = 'EditableFileAttachmentsListController';
    }

    @Prop({ default: EditableFileAttachmentsListController.DEFAULT_MAX_FILES_ALLOWED, type: Number }) readonly maxfilesallowed?: number;
    @Prop({ default: false, type: Boolean }) readonly isrequired?: boolean;
    @Prop({ default: null, type: String }) readonly origin?: FileAttachmentOrigin;
    @Prop({ default: null, type: Array }) readonly initiallist?: FileAttachmentVO[];

    data(): IData {
        return {
            is_waiting: false,
            list: []
        };
    }

    /**
     * Computed
     */
    get isempty(): boolean {
        return this.$data.list.length < 1;
    }

    get islistfull(): boolean {
        return this.$data.list.length >= this.$props.maxfilesallowed;
    }

    /**
     * Watch
     */
    @Watch('initiallist', { immediate: true })
    onInitiallistChanged(newList: FileAttachmentVO[]) {
        this.$data.list = newList ? JSON.parse(JSON.stringify(newList)) : [];
    }

    /**
     * Methods
     */
    public async add(evt: FileInputEvent, index?: number) {
        if (this.islistfull) {
            return;
        }

        this.$data.is_waiting = true;

        this.inputEl = evt.target as HTMLInputElement; // Save to use later in remove method;
        const response = await this.upload(evt.target.files[0]);
        const attachment = new FileAttachmentVO(response.data);
        this.$data.list.push(attachment);
        this.$emit('fileattachment:changed', { origin: this.$props.origin, command: Command.Add, attachment: attachment, index: index } as IFileAttachmentVMEvent);

        this.$data.is_waiting = false;
    }

    public async remove(attachment: FileAttachmentVO, index: number): Promise<void> {
        const removed = this.removeFromList(this.$data.list, attachment);
        this.inputEl && (this.inputEl.value = ''); // Required to load the just removed file;
        this.$emit('fileattachment:changed', { origin: this.$props.origin, command: Command.Remove, attachment: removed, index: index } as IFileAttachmentVMEvent);
    }

    public downloadFileAttachment(attachment: FileAttachmentVO) {
        const config = DownloadConfigFactory.fromFileAttachment(attachment);

        ApplicationServiceLocator.get('download').fileByBrowser(config);
    }

    /**
     * Prototype general methods.
     */
    protected async upload(file: File): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('file', file);
        return await ApplicationServiceLocator.get('api').common.uploadFile(formData);
    }

    protected removeFromList(list: FileAttachmentVO[], attachment: FileAttachmentVO): FileAttachmentVO {
        const attachmentIndex: number = list.findIndex((listAttachment: FileAttachmentVO) => {
            return listAttachment.file_id === attachment.file_id;
        });

        const removed = this.$data.list.splice(attachmentIndex, 1);

        if (isEmpty(removed)) {
            throw new Error('SVTA Error: unexpected return value.');
        }

        return removed[0];
    }
}
