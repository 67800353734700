var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("confirmation-dialogue", {
    attrs: { id: "decline-proposal-popup" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (cd) {
          return [
            _c("on-off", {
              attrs: {
                options: { id: cd.$props.id, onEsc: false, onBody: false },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (onoff) {
                      return [
                        _c("decline-proposal-popup-controller", {
                          attrs: {
                            supplier_ratings:
                              cd.content &&
                              cd.content.proposal.supplier.ratings,
                            supplier_reqs_rating: Boolean(
                              cd?.content?.purchaseSupplierRequirements?.rating
                            ),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (dppc) {
                                  return [
                                    onoff.ison
                                      ? _c(
                                          "div",
                                          { staticClass: "atmo-popup-wrapper" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "atmo-popup-content atmo-wide",
                                              },
                                              [
                                                _c("p", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      dppc.item
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "atmo-button--small atmo-close",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return cd.cancel.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "atmo-icon-close",
                                                    }),
                                                  ]
                                                ),
                                                _c("header", [
                                                  _c("h5", [
                                                    _vm._v(
                                                      "Отклонение ценового предложения"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "atmo-text-center",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("Поставщик: "),
                                                        _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              cd.content
                                                                .proposal
                                                                .supplier
                                                                .title_brief
                                                            ),
                                                          },
                                                        }),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                                _c("main", [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "atmo-content-block-2-columns atmo-no-item-separator",
                                                    },
                                                    [
                                                      _c("li", [
                                                        _c("dt", [
                                                          _vm._v(
                                                            "Причина отклонения:"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "dd",
                                                          {
                                                            staticClass:
                                                              "atmo-form__field atmo-as-input",
                                                            class: {
                                                              "atmo-has-errors":
                                                                dppc.errors
                                                                  .declinereasonid,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "dropdown-select",
                                                              {
                                                                attrs: {
                                                                  list: dppc.declinereasonsFiltered,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          ds
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "on-off",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    options:
                                                                                      {
                                                                                        onBody:
                                                                                          "intelligent",
                                                                                      },
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "default",
                                                                                        fn: function (
                                                                                          onoff
                                                                                        ) {
                                                                                          return [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "atmo-dropdown-select",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "model",
                                                                                                          rawName:
                                                                                                            "v-model.number",
                                                                                                          value:
                                                                                                            dppc
                                                                                                              .inputs
                                                                                                              .declinereasonid,
                                                                                                          expression:
                                                                                                            "dppc.inputs.declinereasonid",
                                                                                                          modifiers:
                                                                                                            {
                                                                                                              number: true,
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "number",
                                                                                                      },
                                                                                                    domProps:
                                                                                                      {
                                                                                                        value:
                                                                                                          dppc
                                                                                                            .inputs
                                                                                                            .declinereasonid,
                                                                                                      },
                                                                                                    on: {
                                                                                                      input:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          if (
                                                                                                            $event
                                                                                                              .target
                                                                                                              .composing
                                                                                                          )
                                                                                                            return
                                                                                                          _vm.$set(
                                                                                                            dppc.inputs,
                                                                                                            "declinereasonid",
                                                                                                            _vm._n(
                                                                                                              $event
                                                                                                                .target
                                                                                                                .value
                                                                                                            )
                                                                                                          )
                                                                                                        },
                                                                                                      blur: function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.$forceUpdate()
                                                                                                      },
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "button",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "button",
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        onoff.toggle,
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    !ds.hasSelected ||
                                                                                                    onoff.ison
                                                                                                      ? _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "atmo-placeholder",
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "Выберите причину отклонения предложения"
                                                                                                            ),
                                                                                                          ]
                                                                                                        )
                                                                                                      : _vm._e(),
                                                                                                    ds.hasSelected &&
                                                                                                    !onoff.ison
                                                                                                      ? _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            domProps:
                                                                                                              {
                                                                                                                innerHTML:
                                                                                                                  _vm._s(
                                                                                                                    ds.text
                                                                                                                  ),
                                                                                                              },
                                                                                                          }
                                                                                                        )
                                                                                                      : _vm._e(),
                                                                                                    _c(
                                                                                                      "i",
                                                                                                      {
                                                                                                        class:
                                                                                                          {
                                                                                                            "atmo-icon-angle-up":
                                                                                                              onoff.ison,
                                                                                                            "atmo-icon-angle-down":
                                                                                                              !onoff.ison,
                                                                                                          },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "ul",
                                                                                                  {
                                                                                                    directives:
                                                                                                      [
                                                                                                        {
                                                                                                          name: "show",
                                                                                                          rawName:
                                                                                                            "v-show",
                                                                                                          value:
                                                                                                            onoff.ison,
                                                                                                          expression:
                                                                                                            "onoff.ison",
                                                                                                        },
                                                                                                      ],
                                                                                                    staticClass:
                                                                                                      "atmo-dropdown",
                                                                                                    on: {
                                                                                                      click:
                                                                                                        onoff.off,
                                                                                                    },
                                                                                                  },
                                                                                                  _vm._l(
                                                                                                    ds.list,
                                                                                                    function (
                                                                                                      item
                                                                                                    ) {
                                                                                                      return _c(
                                                                                                        "li",
                                                                                                        {
                                                                                                          key: item.id,
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return ds.select(
                                                                                                                  item.id
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            {
                                                                                                              domProps:
                                                                                                                {
                                                                                                                  innerHTML:
                                                                                                                    _vm._s(
                                                                                                                      item.title
                                                                                                                    ),
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    }
                                                                                                  ),
                                                                                                  0
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                            dppc.errors
                                                              .declinereasonid
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "atmo-error",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Поле обязательно для заполнения"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "atmo-align-top",
                                                        },
                                                        [
                                                          _c("dt", [
                                                            _vm._v(
                                                              "Комментарий:"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "dd",
                                                            {
                                                              staticClass:
                                                                "atmo-form__field atmo-inverse",
                                                            },
                                                            [
                                                              _c("textarea", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      dppc
                                                                        .inputs
                                                                        .comment,
                                                                    expression:
                                                                      "dppc.inputs.comment",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  required: "",
                                                                  minlength:
                                                                    "10",
                                                                  rows: "2",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    dppc.inputs
                                                                      .comment,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        dppc.inputs,
                                                                        "comment",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              dppc.errors
                                                                .comment
                                                                ? _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "atmo-error",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Длина поля должна быть минимум 10 символов"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("li", [
                                                        _c("dt", [
                                                          _vm._v(
                                                            "Обоснование причины отклонения:"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "dd",
                                                          [
                                                            _c(
                                                              "editable-file-attachments-list-controller",
                                                              {
                                                                attrs: {
                                                                  isrequired: true,
                                                                  origin:
                                                                    "proposals",
                                                                  maxfilesallowed: 1,
                                                                },
                                                                on: {
                                                                  "fileattachment:changed":
                                                                    dppc.updateFileAttachment,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          efalc
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "table",
                                                                              {
                                                                                staticClass:
                                                                                  "atmo-table-slim",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "tbody",
                                                                                  [
                                                                                    _c(
                                                                                      "tr",
                                                                                      [
                                                                                        _c(
                                                                                          "td",
                                                                                          {
                                                                                            staticClass:
                                                                                              "atmo-left",
                                                                                          },
                                                                                          [
                                                                                            !efalc.isempty
                                                                                              ? _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "button",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "atmo-button-link",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            title:
                                                                                                              "Скачать файл",
                                                                                                          },
                                                                                                        domProps:
                                                                                                          {
                                                                                                            textContent:
                                                                                                              _vm._s(
                                                                                                                efalc
                                                                                                                  .list[0]
                                                                                                                  .name
                                                                                                              ),
                                                                                                          },
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return efalc.downloadFileAttachment(
                                                                                                                efalc
                                                                                                                  .list[0]
                                                                                                              )
                                                                                                            },
                                                                                                        },
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "atmo-error",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "Прикрепите документ"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          {
                                                                                            staticClass:
                                                                                              "atmo-tc-button-icon atmo-center",
                                                                                            attrs:
                                                                                              {
                                                                                                title:
                                                                                                  "Прикрепить файл",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "atmo-button-icon",
                                                                                                attrs:
                                                                                                  {
                                                                                                    disabled:
                                                                                                      efalc.islistfull,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "i",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "atmo-icon-attachment",
                                                                                                      }
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "input",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        type: "file",
                                                                                                        accept:
                                                                                                          ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx",
                                                                                                      },
                                                                                                    on: {
                                                                                                      change:
                                                                                                        efalc.add,
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          {
                                                                                            staticClass:
                                                                                              "atmo-tc-button-icon atmo-center",
                                                                                            attrs:
                                                                                              {
                                                                                                title:
                                                                                                  "Удалить файл",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "button",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "atmo-button-icon",
                                                                                                attrs:
                                                                                                  {
                                                                                                    disabled:
                                                                                                      efalc.isempty,
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return efalc.remove(
                                                                                                        efalc
                                                                                                          .list[0]
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "i",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "atmo-icon-trash",
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                                _c("footer", [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "atmo-buttons atmo-align-right",
                                                    },
                                                    [
                                                      _c("li", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "atmo-button-inverse atmo-dimmed",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                cd.cancel()
                                                                dppc.reset()
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" Отмена ")]
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "atmo-button atmo-accented",
                                                            attrs: {
                                                              disabled:
                                                                dppc.hasErrors,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                cd.confirm({
                                                                  inputs:
                                                                    dppc.detachedInputs(),
                                                                  declinereasons:
                                                                    dppc.declinereasons,
                                                                })
                                                                dppc.reset()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Отклонить "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }