var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "td",
    { staticClass: "atmo-ctc-details-dropdown" },
    [
      _c("on-off", {
        attrs: { options: { onEsc: false, onBody: false } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (onoff) {
              return [
                _c("div", { staticClass: "atmo-component-wrapper" }, [
                  _c(
                    "button",
                    {
                      staticClass: "atmo-button-icon",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return onoff.toggle.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !onoff.ison
                        ? _c(
                            "span",
                            {
                              attrs: { title: "Показать подробную информацию" },
                            },
                            [_c("i", { staticClass: "atmo-icon-show" })]
                          )
                        : _c(
                            "span",
                            { attrs: { title: "Скрыть подробную информацию" } },
                            [_c("i", { staticClass: "atmo-icon-hide" })]
                          ),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: onoff.ison,
                          expression: "onoff.ison",
                        },
                      ],
                      staticClass: "atmo-list",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _vm.limit.budget_name
                        ? _c("li", [
                            _c("dt", [_vm._v("Наименование бюджета")]),
                            _vm._v(" : "),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.budget_name),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.funds_type_code
                        ? _c("li", [
                            _c("dt", [_vm._v("Код типа средств")]),
                            _vm._v(" : "),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.funds_type_code),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.event_code
                        ? _c("li", [
                            _c("dt", [_vm._v("Код мероприятия")]),
                            _vm._v(" : "),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.event_code),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.kosgu_code
                        ? _c("li", [
                            _c("dt", [_vm._v("Код КОСГУ: ")]),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.kosgu_code),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.source_funds
                        ? _c("li", [
                            _c("dt", [
                              _vm._v("Бюджет - источник трансферта: "),
                            ]),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.source_funds),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.subsidy_code
                        ? _c("li", [
                            _c("dt", [_vm._v("Код субсидии: ")]),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.subsidy_code),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.invest_object
                        ? _c("li", [
                            _c("dt", [_vm._v("Объект: ")]),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.invest_object),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.direction_code
                        ? _c("li", [
                            _c("dt", [_vm._v("Код направления: ")]),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.direction_code),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.regional_project_result
                        ? _c("li", [
                            _c("dt", [_vm._v("Результат проекта: ")]),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.limit.regional_project_result
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.additional_information_code
                        ? _c("li", [
                            _c("dt", [_vm._v("Код доп. информации: ")]),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.limit.additional_information_code
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm.limit.eis_ident_no
                        ? _c("li", [
                            _c("dt", [_vm._v("ИКЗ: ")]),
                            _c("dd", {
                              domProps: {
                                textContent: _vm._s(_vm.limit.eis_ident_no),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }