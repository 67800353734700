<template>
    <b-col :md="md > 0 ? md : ''" class="fs-14">
        <b-form-group :label="label">
            <b-input-group>
                <b-form-input v-model="text" @input="onFilterChange" :placeholder="placeholder" :disabled="disabled" class="fs-14" />
                <b-input-group-append>
                    <b-button :disabled="!text || disabled" @click="clearValue" size="sm">
                        <span>×</span>
                    </b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
    </b-col>
</template>

<script>
import debounceMixin from '@/mixins/debounce';

export default {
    name: 'filter-text',
    props: {
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        md: {
            default: 4
        }
    },
    mixins: [debounceMixin],
    methods: {
        onFilterChange(value) {
            this.debounce(() => {
                this.$emit('input', value);
            }, 750);
        },
        clearValue() {
            this.$emit('input', '');
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set() {
                return true;
            }
        },
        id() {
            return 'filter-text_' + this._uid;
        }
    }
};
</script>
