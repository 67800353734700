import axios from 'axios';
import { BaseV1ApiService } from '@/services/backend/baseV1';

export class OrganizationsApiService extends BaseV1ApiService {
    constructor() {
        super('organizations');
    }

    async getOrganizationsList(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getOrganization(id) {
        try {
            const response = await axios.get(this.getUrl(id));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getProvidersList() {
        try {
            const response = await axios.get(this.getUrl() + '/providers');
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async getCustomersList() {
        try {
            const response = await axios.get(this.getUrl() + '/customers');
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async search(data = {}) {
        try {
            const response = await axios.post(this.getUrl('search'), data);
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
