'use strict';

import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';

import contractStatusesRawDictionaryData from './data/statuses.json';
import typesRawDictionaryData from './data/types.json';
import { indexOf } from 'lodash';

export interface IContractsDictionaries {
    statuses: null | StandardDictionaryItem[];
    statusesmenu: StandardDictionaryItem[];
    types: null | ExtendedDictionaryItem[];
}

export default class State implements IContractsDictionaries {
    statuses: null | ExtendedDictionaryItem[];
    statusesmenu: StandardDictionaryItem[];
    types: null | ExtendedDictionaryItem[];

    constructor() {
        this.statuses = contractStatusesRawDictionaryData.map((contractStatusRawDataItem: ExtendedDictionaryItem) => {
            return Object.freeze(
                new ExtendedDictionaryItem({
                    id: contractStatusRawDataItem.id,
                    title: contractStatusRawDataItem.title,
                    code: contractStatusRawDataItem.code,
                    is_active: contractStatusRawDataItem.is_active
                })
            );
        });

        // NB: Remove the contract statuses grouped under status 90
        // to exclude them from statuses menu.
        const temp = contractStatusesRawDictionaryData.filter((contractStatusRawDataItem: StandardDictionaryItem) => {
            return [4, 5, 6].indexOf(contractStatusRawDataItem.id as number) === -1;
        });

        this.statusesmenu = temp.map((contractStatusRawDataItem: ExtendedDictionaryItem) => {
            return Object.freeze(
                new ExtendedDictionaryItem({
                    id: contractStatusRawDataItem.id,
                    title: contractStatusRawDataItem.title,
                    code: contractStatusRawDataItem.code,
                    is_active: contractStatusRawDataItem.is_active
                })
            );
        });

        this.types = typesRawDictionaryData.map((contractTypeRawDataItem: ExtendedDictionaryItem) => {
            return Object.freeze(
                new ExtendedDictionaryItem({
                    id: contractTypeRawDataItem.id,
                    title: contractTypeRawDataItem.title,
                    value: contractTypeRawDataItem.value,
                    code: contractTypeRawDataItem.code,
                    is_active: contractTypeRawDataItem.is_active
                })
            );
        });
    }
}
