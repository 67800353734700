var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-content-group my-4 py-0" }, [
    _c("h5", [_vm._v("Документы")]),
    _c("div", { staticClass: "table-responsive my-2 py-0" }, [
      _c("table", { staticClass: "b-table table" }, [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(this.$props.annex.attachments, function (attachment, index) {
            return _c("tr", { key: index }, [
              _c("td", { domProps: { textContent: _vm._s(index + 1) } }),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatdate")(attachment.created_at, "withSeconds")
                  )
                ),
              ]),
              _c(
                "td",
                { staticStyle: { "min-width": "300px" } },
                [
                  attachment.is_archived ? [_vm._v("(Архив) ")] : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "atmo-button-link",
                      staticStyle: { color: "var(--green-color) !important" },
                      attrs: {
                        href: _vm.$getAttachmentDownloadLink(
                          attachment.file_id
                        ),
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(attachment.name))]
                  ),
                ],
                2
              ),
              _c(
                "td",
                { staticClass: "text-center" },
                [
                  _vm.annex.isSignedByBoth() && !attachment.is_archived
                    ? [
                        _c("action-button-small", {
                          attrs: {
                            icon: "clipboard-check",
                            title: "Информация о подписи",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$props.pivc.invokeSignaturePopup([
                                _vm.annex.signatures.customer,
                              ])
                            },
                          },
                        }),
                        _vm.annex.signatures.customer.poa_number &&
                        _vm.annex.signatures.customer.poa_check_link
                          ? _c("action-button-small", {
                              staticClass: "ml-1",
                              attrs: {
                                icon: "clipboard-plus",
                                title: "Информация о МЧД",
                                variant: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$props.pivc.invokeSignaturePopup([
                                    _vm.annex.signatures.customer,
                                  ])
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "td",
                { staticClass: "text-center" },
                [
                  _vm.annex.isSignedByBoth() && !attachment.is_archived
                    ? [
                        _c("action-button-small", {
                          attrs: {
                            icon: "clipboard-check",
                            title: "Информация о подписи",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$props.pivc.invokeSignaturePopup([
                                _vm.annex.signatures.supplier,
                              ])
                            },
                          },
                        }),
                        _vm.annex.signatures.supplier.poa_number &&
                        _vm.annex.signatures.supplier.poa_check_link
                          ? _c("action-button-small", {
                              staticClass: "ml-1",
                              attrs: {
                                icon: "clipboard-plus",
                                title: "Информация о МЧД",
                                variant: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$props.pivc.invokeSignaturePopup([
                                    _vm.annex.signatures.supplier,
                                  ])
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "20px", "max-width": "20px" } }, [
          _vm._v("№"),
        ]),
        _c("th", { staticStyle: { width: "200px", "max-width": "200px" } }, [
          _vm._v("Дата и время направления документов"),
        ]),
        _c("th", [_vm._v("Документ")]),
        _c("th", { attrs: { width: "50" } }, [_vm._v("Подпись заказчика")]),
        _c("th", { attrs: { width: "50" } }, [_vm._v("Подпись поставщика")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }