'use strict';

export interface IStandardPaginationRawData {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
}

interface IPage {
    current: number;
    last: number;
    perpage: number;
}

export default class StandardPagination {
    page: IPage;
    itemsTotal: number;

    constructor(data: IStandardPaginationRawData) {
        this.page = {
            current: data.current_page,
            last: data.last_page,
            perpage: data.per_page
        };
        this.itemsTotal = data.total;
    }
}
