import axios from 'axios';
import { BaseV1ApiService } from '@/services/backend/baseV1';

export class DictionariesApiService extends BaseV1ApiService {
    constructor() {
        super('dictionaries');
    }

    async getList(list) {
        try {
            const response = await axios.get(this.getUrl('list'), { params: { dictionaries: list } });
            return response.data;
        } catch (error) {
            this.handleErrors(error);
        }
    }

    async search(dictionary, query) {
        try {
            const response = await axios.get(this.getUrl('search'), { params: { dictionary: dictionary, query: query } });
            return response.data;
        } catch (error) {
            this.handleErrors(error);
            throw new Error(error.message);
        }
    }
}
