<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>
        <div class="toast_box">
            <transition-group name="animate">
                <toast v-for="toast in toasts" :key="'toast_' + toast.id" @hide="removeToast($event)" :toast="toast"></toast>
            </transition-group>
        </div>
    </div>
</template>

<script>
import {$api} from '@/services/backend/api';
import axios from 'axios';
import {mapGetters} from 'vuex';
import Config from '@core/js/ddriven/application/config/Config';

const default_layout = 'default';

const Toast = () => import(/* webpackChunkName: "main" */ '@/components/elements/Toast.vue');

export default {
    components: { Toast },
    name: 'App',
    metaInfo: {
        title: '',
        titleTemplate: `%s | ${Config.get('theme.variables.view.application_name')}`
    },
    async created() {
        if (localStorage.getItem('token')) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
            await $api.authentication
                .recall()
                .then((response) => {
                    if (response.data.user.user_type_id !== 1) {
                        this.$store.dispatch('logout');
                    }
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                    this.$store.dispatch('login', {
                        ...response.data,
                        token: localStorage.getItem('token')
                    });
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('logout');
                    }
                });
        }
    },
    data() {
        return {
            needReload: ''
        }
    },
    mounted() {
        if (process.browser) {
            window.addEventListener('storage', this.checkReload);
        }
    },
    beforeDestroy() {
        window.removeEventListener('storage', this.checkReload);
    },
    watch: {
        needReload() {
            if (this.needReload) {
                localStorage.removeItem('need_reload');
                window.location.assign(this.needReload);
            }
        }
    },
    methods: {
        checkReload() {
            this.needReload = localStorage.getItem('need_reload');
        }
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || default_layout) + '-layout';
        },
        ...mapGetters({
            toasts: 'getToasts'
        })
    }
};
</script>

<style>
.b-form-datepicker {
    color: rgba(255, 255, 255, 0.7) !important;
    background: rgba(255, 255, 255, 0.2) !important;
    border: none !important;
}

.b-form-datepicker > button,
.b-form-datepicker > label {
    color: rgba(255, 255, 255, 0.7) !important;
}

.toast_box {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 9999;
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease;
}
.animate-enter-active {
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease;
}
.animate-leave-active {
    -webkit-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    -o-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    -moz-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.animate-enter {
    -webkit-transform: translateX(110px);
    -ms-transform: translateX(110px);
    -moz-transform: translateX(110px);
    -o-transform: translateX(110px);
    transform: translateX(110px);
    opacity: 0;
}
.animate-leave-to {
    -webkit-transform: translateX(110px);
    -ms-transform: translateX(110px);
    -moz-transform: translateX(110px);
    -o-transform: translateX(110px);
    transform: translateX(110px);
    opacity: 0;
}
</style>
