var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar-nav",
    { staticClass: "fs-14 my-auto" },
    [
      _c(
        "b-nav-dropdown",
        {
          ref: "notifications",
          attrs: {
            right: "",
            scrollable: "",
            "no-caret": "",
            "no-flip": "",
            "menu-class": "py-0 notification-shadow",
          },
          on: {
            show: function ($event) {
              return _vm.getNotifications(null)
            },
            hide: _vm.hideNotifications,
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "d-flex align-items-center col my-auto",
                      attrs: { title: "Уведомления" },
                    },
                    [
                      _vm.$store.state.user.notification_settings.internal
                        ? _c("b-icon-bell-fill", { staticClass: "grey-color" })
                        : _c("b-icon-bell-slash-fill", {
                            staticClass: "grey-color",
                          }),
                      _vm.$store.state.unreadNotificationsCount > 0
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "notifications",
                              attrs: { variant: "custom-green" },
                            },
                            [_vm._v(_vm._s(_vm.unreadNotificationsCount))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-card",
            {
              attrs: { "header-class": "py-1", "body-class": "p-0" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "b-row",
                        { attrs: { fluid: "" } },
                        [
                          _c("b-col", [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "fw-600 green-color my-0 cursor-pointer",
                                on: { click: _vm.goToCabinet },
                              },
                              [_vm._v("Уведомления")]
                            ),
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "col-auto grey-color cursor-pointer",
                              on: { click: _vm.showSettings },
                            },
                            [_c("b-icon-gear-fill")],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "col-auto px-0",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.notifications.hide(true)
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "cursor-pointer p-0",
                                staticStyle: { width: "24px", height: "24px" },
                                attrs: { icon: "x", "aria-hidden": "true" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-card-body",
                { staticClass: "p-1 notification-list grey-color" },
                [
                  !_vm.notifications.length
                    ? [
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              show: _vm.isNotificationsLoad,
                              opacity: "0.6",
                              rounded: "",
                              "spinner-variant": "primary",
                            },
                          },
                          [_c("empty-request")],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "b-overlay",
                          {
                            staticClass: "pr-3",
                            attrs: {
                              show:
                                _vm.isDataSending && _vm.isNotificationsLoad,
                              opacity: "0.6",
                              rounded: "",
                              "spinner-variant": "primary",
                            },
                          },
                          _vm._l(_vm.notifications, function (notification) {
                            return _c("notification-row", {
                              key: notification.id,
                              attrs: {
                                notification: notification,
                                "row-selected": notification.row_selected,
                                variant: "white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.notifications.hide(true)
                                },
                                delete: _vm.deleteNotifications,
                                read: _vm.markNotificationsAsRead,
                                unread: _vm.markNotificationsAsUnread,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                ],
                2
              ),
              _vm.notifications.length &&
              _vm.meta.total > 0 &&
              _vm.meta.last_page > 1
                ? _c(
                    "b-card-footer",
                    { staticClass: "py-1" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "w-100 m-0" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "b-overlay",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    show:
                                      _vm.isNotificationsLoad &&
                                      _vm.isDataSending &&
                                      _vm.notifications.length &&
                                      _vm.meta.total > 0 &&
                                      _vm.meta.last_page > 1,
                                    opacity: "0.6",
                                    rounded: "",
                                    "spinner-small": "",
                                    "spinner-variant": "primary",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "atmo-button-link cursor-pointer fs-14",
                                      on: { click: _vm.goToCabinet },
                                    },
                                    [
                                      _vm._v(
                                        "Показать все (" +
                                          _vm._s(_vm.meta.total) +
                                          ")"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }