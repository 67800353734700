'use strict';

type TSelfPOJO = FinancingYearLimitVO;

export default class FinancingYearLimitVO {
    year: number | null;
    amount_available: number | null;
    amount_total: number | null;

    constructor(data?: TSelfPOJO) {
        this.year = data?.year ?? null;
        this.amount_available = data?.amount_available ?? null;
        this.amount_total = data?.amount_total ?? null;
    }
}
