<template>
    <section class="atmo-fast-access-menu">
        <ul class="atmo-container-fixed">
            <li><a href="#atmo-customer-info">Информация о заказчике</a></li>
            <li><a href="#atmo-purchase-terms">Условия закупки</a></li>
            <li v-if="!pivc.isPurchaseOutATMO"><a href="#atmo-supply-terms">Условия поставки</a></li>
            <li><a href="#atmo-notice-deliverables">Спецификация извещения</a></li>
            <li v-if="pivc.hasPoroposals"><a href="#atmo-proposals">Предложения участников закупочной сессии</a></li>
            <li v-if="pivc.isPurchaseSummarized || pivc.hasContract || pivc.hasPurchaseProtocol"><a href="#atmo-supplier-info">Информация о поставщике (исполнителе)</a></li>
            <li v-if="(pivc.isPurchaseSummarized || pivc.hasContract || pivc.hasPurchaseProtocol) && !pivc.isPurchaseOutATMO"><a href="#atmo-price-breakdown">Стоимость</a></li>
            <li v-if="pivc.hasContract"><a href="#atmo-contract">Договор</a></li>
            <li v-if="pivc.hasContract && (pivc.isUserEngagedCustomer || pivc.isUserEngagedSupplier) && pivc.isPurchaseSummarized && pivc.purchaseitem.contract.hasDisputes()">
                <a href="#atmo-disputes">Разногласия</a>
            </li>
            <!-- <li v-if="(pivc.isUserEngagedCustomer || pivc.isUserEngagedSupplier) && pivc.isPurchaseSummarized && (pivc.isContractProposed || pivc.isContractDisputed)"><a href="#atmo-disputes-resolution">Согласование разногласий</a></li> -->
            <li v-if="pivc.hasContractAnnexes"><a href="#atmo-contract-annexes">Дополнительные соглашения</a></li>
            <li v-if="pivc.isUserEngagedCustomer && pivc.hasContract && pivc.isContractSignedByBoth"><a href="#atmo-contract-fulfillment">Исполнение договора</a></li>

            <section class="atmo-group">
                <li title="К верху страницы">
                    <a href="#atmo-top" class="atmo-button-icon"><i class="atmo-icon-angle-up"></i></a>
                </li>
                <li title="К низу страницы">
                    <a href="#atmo-bottom" class="atmo-button-icon"><i class="atmo-icon-angle-down"></i></a>
                </li>
            </section>
        </ul>
    </section>
</template>

<script>
import setFastAccessMenuTop from '@lib/js/src/misc/setFastAccessMenuTop.ts';

export default {
    mounted() {
        setFastAccessMenuTop();
    },
    props: {
        pivc: { required: true, type: Object },
        purchaseitem: { required: true, type: Object }
    }
};
</script>
