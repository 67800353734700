<template>
    <section style="width: 100%">
        <div class="table-responsive">
            <table v-bind:class="{ 'atmo-empty': $props.additional.is_empty }" class="atmo-content-table">
                <thead>
                    <tr>
                        <th class="atmo-ctc-place">№</th>
                        <th class="atmo-ctc-width-45pct">Описание</th>
                        <th class="atmo-ctc-width-45pct">Причина или цель установки требования</th>
                        <th class="atmo-ctc-command-remove">&nbsp;</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(requirement, index) in $props.additional.requirements" v-bind:key="index">
                        <td v-text="index + 1" class="atmo-ctc-place"></td>
                        <td class="atmo-ctc-width-45pct">
                            <span class="atmo-form__field atmo-inverse">
                                <!-- // NB: The ID is used by the viewmodel to set the input focus -->
                                <input v-bind:id="`requirement-additional-item-${index + 1}`" v-model="requirement.description" type="text" required min="1" />
                            </span>
                        </td>
                        <td class="atmo-ctc-width-45pct">
                            <span class="atmo-form__field atmo-inverse">
                                <input v-model="requirement.reason" type="text" required min="1" />
                            </span>
                        </td>
                        <td class="atmo-ctc-command-remove">
                            <button v-on:click.stop="$props.additional.remove(index)" class="atmo-button-icon" title="Удалить описание"><i class="atmo-icon-trash"></i></button>
                        </td>
                    </tr>

                    <tr v-if="$props.additional.is_empty" class="atmo-ctc-center">
                        <td colspan="3" class="atmo-centered">Не добавлено описаний документов</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer>
            <p v-if="$props.additional.is_empty" class="atmo-error">Добавьте и опишите дополнительные требования.</p>

            <button v-on:click.stop="$props.additional.add()" class="atmo-button">Добавить описание требования</button>
        </footer>
    </section>
</template>

<script>
export default {
    props: {
        additional: { required: true, type: Object }
    }
};
</script>
