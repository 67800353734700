var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center fast_auth col my-auto" },
    [
      !_vm.$store.state.token
        ? [
            _c(
              "b-btn",
              {
                staticClass: "text-nowrap text-uppercase",
                attrs: { variant: "custom-danger" },
                on: { click: _vm.showRegistrationModal },
              },
              [_vm._v("Регистрация")]
            ),
            _c(
              "b-btn",
              {
                staticClass: "text-nowrap text-uppercase ml-2",
                attrs: { variant: "custom-green" },
                on: { click: _vm.showLoginModal },
              },
              [_vm._v("Вход в ЛК")]
            ),
          ]
        : [
            _c(
              "b-navbar-nav",
              { staticClass: "align-items-end ml-auto" },
              [
                _c(
                  "b-nav-item-dropdown",
                  {
                    staticClass: "demo-navbar-user fs-14",
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-inline-flex flex-lg-row-reverse align-items-center align-middle",
                                staticStyle: { "max-width": "300px" },
                              },
                              [
                                _c("img", {
                                  staticClass: "d-block ui-w-30 rounded-circle",
                                  attrs: { src: `/images/user.png`, alt: "" },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "px-1 mr-lg-2 ml-2 ml-lg-0 grey-color text-right text-truncate",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$store.state.user.name)
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$store.state.user
                                            .organization_name
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-dd-item",
                      {
                        staticClass: "fs-14 text-right text-uppercase",
                        attrs: {
                          to: { name: "Cabinet" },
                          "active-class": "",
                          "link-class": "grey-color",
                        },
                      },
                      [_vm._v("Личный кабинет")]
                    ),
                    _c(
                      "b-dd-item",
                      {
                        staticClass: "fs-14 text-right text-uppercase",
                        attrs: { "link-class": "grey-color" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("Выйти")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }