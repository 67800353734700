var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", [
    _vm.$props.requirements.a31s1p1
      ? _c("li", [
          _c("i", { staticClass: "atmo-icon-checkbox" }),
          _vm._v(
            " Поставщик подтвердил, что соответствует требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ. "
          ),
        ])
      : _vm._e(),
    _vm.$props.requirements.a31s1p1
      ? _c("li", [
          _c(
            "ul",
            [
              _c("li", [
                _vm._v("Документы, подтверждающие соответствие требованиям:"),
              ]),
              _vm._l(
                _vm.$props.requirements.a31s1p1_details.supporting_documents,
                function (document, index) {
                  return _c("li", { key: index }, [
                    _c("ul", [
                      _c("li", {
                        domProps: {
                          textContent: _vm._s(
                            `${index + 1}. ${document.description}`
                          ),
                        },
                      }),
                      _c("li", [
                        _c(
                          "button",
                          {
                            staticClass: "atmo-button-link",
                            on: {
                              click: function ($event) {
                                _vm
                                  .pivc()
                                  .downloadFileAttachment(document.attachment)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(document.attachment.name)),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                }
              ),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm.$props.requirements.a31s1ps3_5ps7_11
      ? _c("li", [
          _c("i", { staticClass: "atmo-icon-checkbox" }),
          _vm._v(
            " Поставщик подтвердил, что соответствует требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ. "
          ),
        ])
      : _vm._e(),
    _vm.$props.requirements.a31s1_1
      ? _c("li", [
          _c("i", { staticClass: "atmo-icon-checkbox" }),
          _vm._v(
            " Поставщик подтвердил, что соответствует требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ. "
          ),
        ])
      : _vm._e(),
    _vm.$props.requirements.supplier_reqs_sonko
      ? _c("li", [
          _c("i", { staticClass: "atmo-icon-checkbox" }),
          _vm._v(" Поставщик подтвердил, что является "),
          _c(
            "abbr",
            {
              attrs: {
                title: "Социально ориентированная некоммерческая организация",
              },
            },
            [_vm._v("СОНКО")]
          ),
          _vm._v("."),
        ])
      : _vm._e(),
    _vm.$props.requirements.additional.state.is_confirmed
      ? _c("li", [
          _c("i", { staticClass: "atmo-icon-checkbox" }),
          _vm._v(
            " Поставщик подтвердил соответствие дополнительным требованиям: "
          ),
          _c(
            "ul",
            _vm._l(
              _vm.$props.requirements.additional.requirements,
              function (requirement, index) {
                return _c("li", { key: index }, [
                  _c("ul", [
                    _c("li", {
                      domProps: {
                        textContent: _vm._s(
                          `${index + 1}. ${requirement.description}`
                        ),
                      },
                    }),
                    _c("li", [
                      _vm._v(
                        "Причина или цель установки требования: " +
                          _vm._s(requirement.reason)
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "button",
                        {
                          staticClass: "atmo-button-link",
                          on: {
                            click: function ($event) {
                              _vm
                                .pivc()
                                .downloadFileAttachment(requirement.attachment)
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(requirement.attachment.name)),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ])
              }
            ),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }