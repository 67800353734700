<template>
    <h2 class="grey-color font-weight-bold my-3">
        <slot></slot>
    </h2>
</template>

<script>
export default {
    name: 'text-header'
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
