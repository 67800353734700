<template>
    <div class="atmo-content-group">
        <h5>
            <slot></slot>
        </h5>

        <text-row :value="getBankTypeTitle(supplierAccounting.bank_type)" label="Вид обслуживающей организации" />
        <text-row v-model="supplierAccounting.bik" label="БИК банка" />
        <text-row v-model="supplierAccounting.bank_name" label="Название банка" />
        <text-row v-model="supplierAccounting.bank_city" label="Город банка" />
        <text-row v-model="supplierAccounting.ks" label="Кор. счет" />
        <text-row v-model="supplierAccounting.rs" label="Расчетный счет" />
        <text-row v-if="supplierAccounting.bank_type !== 'bank'" v-model="supplierAccounting.ls" label="Лицевой счет" />
        <text-row v-if="supplierAccounting.bank_type !== 'bank'" v-model="supplierAccounting.fo_inn" label="ИНН финансового органа" />
        <text-row v-if="supplierAccounting.bank_type !== 'bank'" v-model="supplierAccounting.fo_name" label="Полное наименование финансового органа" />
    </div>
</template>

<script>
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';

export default {
    props: {
        supplierAccounting: { required: true, type: SupplierAccountingVO }
    },
    data() {
        return {
            bankTypes: [
                { id: 'bank', name: 'Банк' },
                { id: 'finOrg', name: 'Финансовый орган' },
                { id: 'fedKazn', name: 'Орган ФК' }
            ]
        };
    },
    methods: {
        getBankTypeTitle(typeId) {
            return this.bankTypes.find((bankType) => bankType.id === typeId)?.name;
        }
    }
};
</script>
