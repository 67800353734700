'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';

import IViewmodelEventPayload from '@core/js/ddriven/application/abstractions/vue/IViewmodelEventPayload';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import DeliverablesSelectorPopupController from './DeliverablesSelectorPopupController.viewmodel';
import DeliverableItemEditableVO from '@core/js/ddriven/domain/model/common/deliverable/update/general/DeliverableItemEditable.valueobject';
import DeliverableItemsEditableListValidator, { IErrors } from '@/rearchitected/core/js/ddriven/domain/model/common/deliverable/update/general/DeliverableItemsEditableList.validator';
import DeliverableDictionaryItemVO from '@core/js/ddriven/domain/model/common/deliverable/DeliverableDictionaryItem.valueobject';
import round from 'lodash.round';

interface IData {
    list: DeliverableItemEditableVO[];
    errors: {} | IErrors;
}

/**
 * Control a deliverables selector list view: list, add (via popup), remove deliverables.
 * Notify anyone interested about each change in the list (via event).
 * Manage the list validation and errors.
 *
 * @version 2
 * @since   2   Fill in the KBK field via popup.
 * @fires   'deliverableslist:updated'
 * @listens 'deliverable:selected'
 */
@Component
export default class DeliverablesEditableListController extends BaseViewModel {
    constructor() {
        super();
        this.name = 'DeliverablesEditableListController';
    }

    @Prop({ default: null, type: Array }) readonly initiallist?: DeliverableItemEditableVO[];
    @Prop({ default: 9999999999999.0, type: Number }) readonly max_price?: number;

    created() {
        this.$root.$on('deliverable:selected', this.processSelectedDeliverable);
    }

    data(): IData {
        return {
            list: [],
            errors: {}
        };
    }

    /**
     * Computed
     */
    get islistempty(): boolean {
        return this.$data.list.length < 1;
    }

    get specificationTotalPrice(): number {
        const amount = this.$data.list.reduce((accumulator: number, deliverable: DeliverableItemEditableVO) => {
            return accumulator + (deliverable.price_total ?? 0);
        }, 0);
        return round(amount, 2);
    }

    /**
     * Watch
     */
    @Watch('list', { deep: true })
    async onListChanged(newList: DeliverableItemEditableVO[]) {
        const validator = new DeliverableItemsEditableListValidator();
        this.refresh(newList);
        validator.validate(newList, this.max_price);
        this.$data.errors = validator.errors;
        this.$root.$emit('deliverableslist:updated', newList);
    }

    @Watch('max_price', { deep: true })
    async maxPriceChanged() {
        const validator = new DeliverableItemsEditableListValidator();
        validator.validate(this.$data.list, this.max_price);
        this.$data.errors = validator.errors;
    }

    @Watch('initiallist', { immediate: true })
    async onInitiallistChanged(initiallist: DeliverableItemEditableVO[] | null) {
        this.$data.list = initiallist ?? [];
    }

    /**
     * Methods
     */
    public invokeDeliverablesSelectorPopup(): void {
        this.$root.$emit('public:onoff:toggle', { id: DeliverablesSelectorPopupController.popupId } as IOnOffOnToggleEventPayload);
    }

    public removeItem(index: number): void {
        this.$data.list.splice(index, 1);
    }

    /**
     * Prototype general methods.
     */
    private processSelectedDeliverable(payload: IViewmodelEventPayload): void {
        this.$data.list.push(DeliverableItemEditableVO.fromDictionaryItem(payload.item as unknown as DeliverableDictionaryItemVO));
    }

    private refresh(newList: DeliverableItemEditableVO[]): void {
        newList.forEach((deliverable: DeliverableItemEditableVO) => {
            deliverable.updatePriceTotal();
        });
    }
}
