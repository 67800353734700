import Dummy from '@/components/Dummy.vue';

export default [
    {
        path: '/notices',
        component: () => Dummy,
        params: { group: 'global' },
        name: 'GlobalNoticesList'
    }
    // {
    //     path: '/notices/fl223',
    //     component: () => import(/* webpackChunkName: "main" */ '@/components/global/fl223-notices/notices-list.vue'),
    //     params: { group: 'global' },
    //     name: 'Fl223NoticesList'
    // },
    // {
    //     path: '/notices/fl44',
    //     component: () => import(/* webpackChunkName: "main" */ '@/components/global/fl44-notices/notices-list.vue'),
    //     params: { group: 'global' },
    //     name: 'Fl44NoticesList'
    // }
];
