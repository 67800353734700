'use strict';

import random from 'lodash.random';

export default class BaseCollection<T> {
    public items: T[];

    constructor(...items: T[]) {
        this.items = [...items];
    }

    public isEmpty(): boolean {
        return this.items.length < 1;
    }

    public random(): number {
        return random(1, 10000);
    }
}
