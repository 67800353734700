var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$props.certificate
    ? _c("section", { staticClass: "atmo-content-group" }, [
        _c("h6", [_vm._v("Сертификат")]),
        _c(
          "ul",
          {
            staticClass: "atmo-content-block-2-columns atmo-no-item-separator",
          },
          [
            _c("li", [
              _c("dt", [_vm._v("Владелец:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(
                    this.$props.certificate.subject ??
                      this.$props.certificate.subjectName
                  ),
                },
              }),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }