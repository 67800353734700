'use strict';

import DownloadConfig from '../DownloadConfig.valueobject';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';

export default class S3 extends DownloadConfig {
    protected static _type = 's3';

    constructor(attachmentData: FileAttachmentVO) {
        super(attachmentData.storage);
        this.verb = 'GET';
        this.endpoint = `/documents/${attachmentData.file_id}`;
        this.name = attachmentData.name;
    }

    public setBody() {
        return {};
    }
}
