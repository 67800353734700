'use strict';

import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import isEmpty from 'lodash.isempty';
import BaseSignPopupController from '@core/js/viewmodels/common/popup/BaseSignPopupController.viewmodel';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import OrganizationReliabilityReportVMDService from '@core/js/ddriven/domain/services/viewmodel-data/organization-reliability-report/OrganizationReliabilityReportVMD.service';
import SignOrganizationReliabilityReportDownloadRequestVO from '@core/js/ddriven/application/http/requests/misc/SignOrganizationReliabilityReportDownloadRequest.valueobject';
import DownloadConfigFactory from '@core/js/ddriven/domain/services/downloads/DownloadConfigFactory';
import ToastDataVO from '@core/js/ddriven/application/services/uitoast/ToastData.valueobject';
import { Certificate } from 'crypto-pro';

export interface ISignReportDownloadPopupPayload extends IOnOffOnToggleEventPayload {
    viewmodelDataService: OrganizationReliabilityReportVMDService;
}

@Component
export default class SignOrganizationReliabilityReportDownloadPopupController extends BaseSignPopupController {
    public static popupId = 'sign-organization-reliability-report-download-popup';
    private viewmodelDataService?: OrganizationReliabilityReportVMDService;
    private respond?: Function;

    constructor() {
        super();
        this.name = 'SignOrganizationReliabilityReportDownloadPopupController';
        this.popupId = SignOrganizationReliabilityReportDownloadPopupController.popupId;
    }

    /**
     * Methods
     */
    public cancel(): void {
        this.onoff.off();
        this.respond!(false);
    }

    public sign(evt: Event): void {
        if (isEmpty(this.currentParsedCertificate)) {
            return;
        }

        actWithLoadingSpinner(evt, async () => {
            this.viewmodelDataService!.viewables.is_loading.report = true;

            const request = new SignOrganizationReliabilityReportDownloadRequestVO(this.viewmodelDataService!, this.currentParsedCertificate as Certificate, this.$data.xml);
            const config = DownloadConfigFactory.forOrganizationReliabilityReport(request);
            let response = false;

            await ApplicationServiceLocator.get('download')
                .fileByBrowserV1(config)
                .then(() => {
                    this.onoff.off();
                    response = true;
                })
                .catch((error) => {
                    ApplicationServiceLocator.get('uitoasts').show(ToastDataVO.unknownError());
                });

            this.viewmodelDataService!.viewables.is_loading.report = false;
            this.respond!(response);
        });
    }

    protected async initializeCustomData(payload: ISignReportDownloadPopupPayload) {
        this.$set(this.$data.custom, 'is_loading', payload.viewmodelDataService.viewables.is_loading);

        this.viewmodelDataService = payload.viewmodelDataService;
        this.respond = payload.respond;
        this.$data.user = payload.viewmodelDataService.user;
        this.$data.xml = await this.viewmodelDataService.xml();
    }
}
