var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group" },
    [
      _c("on-off", {
        attrs: { options: { onEsc: false, onBody: false } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (onoff) {
              return [
                _c("div", { staticClass: "atmo-component-wrapper" }, [
                  _vm.$props.pivc.isUserEngagedCustomer &&
                  !_vm.$props.pivc.hasContractPendingAnnexes &&
                  !onoff.ison
                    ? _c(
                        "button",
                        {
                          staticClass: "atmo-button",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return onoff.on.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Заключить дополнительное соглашение")]
                      )
                    : _vm._e(),
                  onoff.ison
                    ? _c(
                        "div",
                        { staticClass: "atmo-nested-section" },
                        [
                          _c("h3", [
                            _vm._v("Создать дополнительное соглашение"),
                          ]),
                          _c("ContractAnnexUpdate.view", {
                            attrs: {
                              mode: "create",
                              pivc: _vm.$props.pivc,
                              disputecontroller: {
                                onAnnexItemEditableChange:
                                  _vm.onAnnexItemEditableChange,
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button atmo-dimmed",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return onoff.off.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("Отмена")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }