'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import isObjectNullified from '@lib/js/src/misc/isObjectNullified';
import StandardDictionaryItem from '../../../ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import PurchaseRequestFilter from '../../../ddriven/application/http/requests/purchases/PurchaseRequestFilter.valueobject';
import ContractRequestFilter from '../../../ddriven/application/http/requests/contracts/ContractRequestFilter.valueobject';

enum UpdateCommand {
    Add = 'add',
    Remove = 'remove'
}

enum EFilterDictionaryNames {
    DeliverableGroup = 'purchaseCategories',
    Municipality = 'municipalities'
}

interface IDropdownSelectPayload {
    id: null | number;
    item: Record<string, unknown>;
}

type TFilter = PurchaseRequestFilter | ContractRequestFilter;

@Component
export default class EntityFiltersController extends BaseViewModel {
    private enterHandler?: (event: any) => void;

    constructor() {
        super();
        this.name = 'EntityFiltersController';
    }

    /**
     * NB: requestfilter is an instance of concrete request filters
     * e.g. PurchaseRequestFilter, ContractRequestFilter etc.
     */
    @Prop({ required: true, type: Object }) readonly requestfilter!: Record<string, unknown>;
    @Prop({ required: true, type: Boolean }) readonly resetfilters!: boolean;

    mounted() {
        if (typeof this.filterConstructor !== 'function') {
            throw new Error('ATMO Exception: you must define the filter constructor as the child viemodel computed "this.computed.filterConstructor"');
        }

        this.$nextTick(() => {
            this.enterHandler = this.searchAtEnterPress.bind(this);
            window.addEventListener('keyup', this.enterHandler);
        });
    }

    beforeDestroy() {
        window.removeEventListener('keyup', this.enterHandler as (event: any) => void);
    }

    data() {
        return {
            deliverablesgroups: null, // NB: For deliverables groups multiselect
            municipalities: null, // NB: For municipalities multiselect

            filters: null,

            /**
             * Provides toggle functionality for filters in a view.
             */
            isFiltersVisible: false,

            /**
             * Signal for Search button to toggle disable state.
             */
            hasFiltersChanged: false,

            /**
             * Helps avoiding router same route navigation exception.
             * Used in reset method to avoid firinig the filters:update
             * event with empty filters object.
             */
            hasJustSearched: false
        };
    }

    /**
     * Computed
     */
    /**
     * REFACTOR: The return type should be the base type for the request filters.
     * E.g. EntityRequestFilter. When created rpelace 'any' typing with it here.
     */
    get filterConstructor(): any {
        console.log('Dummy computed. Must be redefined in the child viewmodel.');
        throw new Error('ATMO Exception: this computed must be redefined in the child viewmodel.');
    }

    get isFiltersEmpty() {
        return isObjectNullified(this.$data.filters);
    }

    get isStoreFilterEmpty() {
        return isObjectNullified(this.$props.requestfilter);
    }

    /**
     * Methods
     */
    public search() {
        this.$data.hasFiltersChanged = false;
        this.$data.hasJustSearched = true;
        const filter = JSON.parse(JSON.stringify(PurchaseRequestFilter.nullify(this.$data.filters)));
        this.$emit('filters:update', { key: 'filter', value: filter });
    }

    public toggleFilters() {
        this.$data.isFiltersVisible = !this.$data.isFiltersVisible;
    }

    public reset() {
        if (this.$data.hasJustSearched || !this.isStoreFilterEmpty) {
            this.$emit('filters:update', { key: 'filter', value: {} });
        }
        // @ts-ignore
        this.$data.filters = new this.filterConstructor();
        this.$data.deliverablesgroups = null;
        this.$data.municipalities = null;
    }

    public processDictionaryFilterUpdate(item: StandardDictionaryItem, command: UpdateCommand, dictionaryName: EFilterDictionaryNames): void {
        // @ts-ignore
        this[`${command}DictionaryFilterItem`](item, dictionaryName);
    }

    /**
     * REFACTOR: The controller may accept the prop with name of the dropdown
     * value field within 'filters' object.
     *
     * Or, better, the child controller may override
     * the parent's 'manageSelection' method.
     */
    manageSelection(payload: IDropdownSelectPayload) {
        this.$data.filters.orderType = payload.id;
    }

    @Watch('resetfilters')
    onResetfiltersChanged(newState: boolean) {
        if (!newState) {
            return;
        }
        // @ts-ignore
        this.$data.filters = new this.filterConstructor();
    }

    @Watch('filters', { deep: true })
    onFiltersChanged(newFilterObject: Record<string, unknown>, oldFilterObject: Record<string, unknown>) {
        /**
         * If oldFilterObject is null or undefined then this is the
         * first page looad and the initial hasFilterChanged is already set.
         */
        if (!oldFilterObject) {
            return;
        }
        this.$data.hasJustSearched = false;
        this.$data.hasFiltersChanged = true;
    }

    /**
     * General prototype methods.
     */
    searchAtEnterPress(event: KeyboardEvent) {
        if (event.keyCode === 13 && this.$data.hasFiltersChanged) {
            event.preventDefault();
            this.search();
        }
    }

    /**
     * REFACTOR:
     *
     * 1. filterConstructor: requires to provide the constructor on the general type.
     * 2. storeFilter: see if it is possible to create the base request filter type that is
     * extended afterwards in PurchaseReuestFilter and the like. When created update the
     * typing here.
     */
    createRequestFilter(filterConstructor: unknown, storeFilter: TFilter) {
        // NB: Preset the multiselect with data from query string if any.
        storeFilter.purchaseCategories && (this.$data.deliverablesgroups = this.dictionaryFilterFactory(storeFilter, EFilterDictionaryNames.DeliverableGroup));
        storeFilter.municipalities && (this.$data.municipalities = this.dictionaryFilterFactory(storeFilter, EFilterDictionaryNames.Municipality));

        // @ts-ignore
        return isObjectNullified(storeFilter) ? new filterConstructor() : new filterConstructor(Object.assign({}, storeFilter));
    }

    /**
     * General prototype methods
     */
    protected addDictionaryFilterItem(item: StandardDictionaryItem, dictionaryName: EFilterDictionaryNames): void {
        const index = this.$data.filters[dictionaryName]!.findIndex((id: number) => {
            return id === item.id;
        });
        if (index > -1) {
            return;
        }
        this.$data.filters[dictionaryName]!.push(item.id as number);
    }

    protected removeDictionaryFilterItem(item: StandardDictionaryItem, dictionaryName: EFilterDictionaryNames): void {
        const index = this.$data.filters[dictionaryName]!.findIndex((id: number) => {
            return id === item.id;
        });
        if (index < 0) {
            return;
        }
        this.$data.filters[dictionaryName]!.splice(index, 1);
    }

    private dictionaryFilterFactory(storeFilter: TFilter, dictionaryName: EFilterDictionaryNames): StandardDictionaryItem[] {
        /**
         * REFACTOR: Replace the below entity name detection by moving the deliverables
         * groups dictionary to the common store section.
         */
        const entityName = this.$route.name?.split('.')[0];
        const storeFilterName = dictionaryName === EFilterDictionaryNames.DeliverableGroup ? 'deliverablesgroups' : EFilterDictionaryNames.Municipality;

        return this.$store.getters[`rearchitected/groups/fl44/${entityName}/dictionaries/${storeFilterName}`].filter((item: StandardDictionaryItem) => {
            const found = (storeFilter[dictionaryName] as number[])!.find((id: number) => {
                return id === item.id;
            });
            return found;
        });
    }
}
