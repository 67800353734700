import axios from 'axios';
import { BaseV1ApiService } from '@/services/backend/baseV1';

export class KtruApiService extends BaseV1ApiService {
    constructor() {
        super('ktru');
    }

    async getList(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
