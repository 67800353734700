'use strict';

import FileAttachmentVO, { IRawFileAttachmentRefactored } from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import RequirementStateVO, { TStateInput } from './RequirementState.valueobject';
import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';
import { WritableKeysOf } from '@core/js/ddriven/application/abstractions/types/misc';
import { Command, IFileAttachmentVMEvent } from '@/rearchitected/core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';

type TRawRequirement = {
    description: string | null;
    reason: string | null;
    attachment: IRawFileAttachmentRefactored | FileAttachmentVO | null;
};

export type TRawAdditional = {
    state: TStateInput;
    requirements: TRawRequirement[];
};

type TSelfPOJO = Pick<SupplierRequirementAdditionalVO, WritableKeysOf<SupplierRequirementAdditionalVO>>;

export default class SupplierRequirementAdditionalVO {
    public state: RequirementStateVO;
    public requirements: TRawRequirement[];

    constructor(data?: TRawAdditional) {
        this.state = new RequirementStateVO(data?.state);
        this.requirements = data?.requirements ?? [];
    }

    public get is_valid(): boolean {
        return !this.state.is_required || (this.state.is_required && this.are_requirements_valid);
    }

    public get is_valid_at_contract(): boolean {
        return !this.state.is_required || (this.state.is_required && this.state.is_confirmed_at_contract && this.are_requirements_attachments_valid);
    }

    public get are_requirements_valid(): boolean {
        return (
            this.requirements.length > 0 &&
            this.requirements.every((requirement: TRawRequirement) => {
                return !isPrimitiveEmpty(requirement.description) && !isPrimitiveEmpty(requirement.reason);
            })
        );
    }

    public get are_requirements_attachments_valid(): boolean {
        return (
            this.requirements.length > 0 &&
            this.requirements.every((requirement: TRawRequirement) => {
                return requirement.attachment !== null;
            })
        );
    }

    public get is_empty(): boolean {
        return this.requirements.length < 1;
    }

    public add(): void {
        this.requirements.push({ description: null, reason: null, attachment: null });
    }

    public remove(index: number): void {
        this.requirements.splice(index, 1);
    }

    public updateAttachment(updated: IFileAttachmentVMEvent): void {
        updated.command === Command.Remove && (this.requirements[updated.index!].attachment = null);
        updated.command === Command.Add && (this.requirements[updated.index!].attachment = FileAttachmentVO.updatedAttachmentValue(updated));
    }

    public static fromPOJO(pojo: TSelfPOJO): SupplierRequirementAdditionalVO {
        const state: TStateInput = [pojo.state.is_required, pojo.state.is_confirmed];

        const raw = { ...pojo, state };
        return new SupplierRequirementAdditionalVO(raw as TRawAdditional);
    }

    public static toRequestPayload(additional: SupplierRequirementAdditionalVO): TRawAdditional {
        return {
            state: RequirementStateVO.toRaw(additional.state),
            requirements: additional.requirements
        };
    }
}
