var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-form-group", { staticClass: "fs-18 grey-color text-left pb-0" }, [
        _c(
          "div",
          [
            _c("b-container", { staticClass: "pb-2" }, [
              _c("p", { staticClass: "fw-600 my-0" }, [
                _vm._v("Для входа через ЭЦП, требуется:"),
              ]),
              _c("ul", { staticClass: "error-list" }, [
                _c("li", [_vm._v("— КриптоПро CSP")]),
                _c("li", [_vm._v("— Плагин КриптоПро")]),
              ]),
              _c("p", { staticClass: "my-0" }, [
                _vm._v("Подробнее в базе знаний - "),
                _c(
                  "a",
                  {
                    staticClass: "btn-link",
                    attrs: {
                      href: "https://info.zakupschik.ru/188--nastrojka-rabochego-mesta",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Настройка рабочего места")]
                ),
              ]),
            ]),
            _c("b-container", { staticClass: "pb-2" }, [
              _c("p", { staticClass: "fw-600 my-0" }, [
                _vm._v(
                  "При авторизации через логин и пароль недоступно осуществление юридически значимых действий. Подробнее - "
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn-link fw-600",
                    attrs: {
                      href: "https://info.zakupschik.ru/182-403--vozmozhnosti-i-varianty-avtorizacii/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("по ссылке")]
                ),
                _vm._v("."),
              ]),
            ]),
            _c("b-container", [
              _c("p", { staticClass: "fw-600 my-0" }, [
                _vm._v(
                  'Техническая поддержка доступна по кнопке "Поддержка" (в правом нижнем углу).'
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }