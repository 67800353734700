<template>
    <save-purchase-popup-controller v-slot:default="sppc">
        <on-off v-slot:default="onoff" v-bind:options="{ id: 'save-purchase-popup', onEsc: false, onBody: false }">
            <div v-if="onoff.ison" class="atmo-popup-wrapper">
                <div class="atmo-popup-content atmo-save-purchase-popup">
                    <button v-on:click.stop="onoff.off" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                    <h5>Подписать и сохранить извещение</h5>

                    <ul class="atmo-tabs-menu-universal">
                        <li>
                            <button v-on:click="sppc.selectTab(1)" v-bind:class="{ 'atmo-active': sppc.isSelectedTab(1) }">Данные формы</button>
                        </li>
                        <li>
                            <button v-on:click="sppc.selectTab(2)" v-bind:class="{ 'atmo-active': sppc.isSelectedTab(2) }">XML-представление данных формы</button>
                        </li>
                    </ul>

                    <main class="atmo-tabs-container">
                        <TabFormDataPreviewView.view v-if="sppc.isSelectedTab(1)" v-bind:download="sppc.downloadFileAttachment" v-bind:user="sppc.user" v-bind:inputs="sppc.inputs" v-bind:decoded="sppc.decoded" v-bind:certificate="sppc.$data.certificate" />

                        <TabXMLDataPreviewView.view v-if="sppc.isSelectedTab(2)" v-bind:xml="sppc.xml" v-bind:certificate="sppc.$data.certificate" />
                    </main>

                    <footer>
                        <CertificateInfo.view v-if="sppc.$data.certificate" v-bind:certificate="sppc.$data.certificate" />

                        <ul class="atmo-buttons">
                            <li><button v-on:click="onoff.off" class="atmo-button-inverse atmo-dimmed">Отмена</button></li>
                            <li v-if="!sppc.isDataSigned && sppc.$data.certificate">
                                <button v-on:click="sppc.signData" class="atmo-button">Подписать</button>
                                <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
                            </li>
                            <li v-if="sppc.isDataSigned" v-on:click="sppc.savePurchase">
                                <button class="atmo-button">Сохранить</button>
                                <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
                            </li>
                        </ul>
                    </footer>
                </div>
            </div>
        </on-off>
    </save-purchase-popup-controller>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import SavePurchasePopupController from '@core/js/viewmodels/purchases/item/update/SavePurchasePopupController.viewmodel';

import TabFormDataPreviewView from './TabFormDataPreview.view.vue';
import TabXMLDataPreviewView from './TabXMLDataPreview.view.vue';
import CertificateInfoView from 'themes/views/overlays/popups/common/partials/CertificateInfo.view.vue';

export default {
    components: {
        'on-off': new OnOff(),
        'save-purchase-popup-controller': SavePurchasePopupController,
        'TabFormDataPreviewView.view': TabFormDataPreviewView,
        'TabXMLDataPreviewView.view': TabXMLDataPreviewView,
        'CertificateInfo.view': CertificateInfoView
    }
};
</script>
