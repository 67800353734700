<template>
    <div>
        <b-form-group class="fs-18 grey-color text-left pb-0">
            <div>
                <b-container class="pb-2">
                    <p class="fw-600 my-0">Для входа через ЭЦП, требуется:</p>
                    <ul class="error-list">
                        <li>— КриптоПро CSP</li>
                        <li>— Плагин КриптоПро</li>
                    </ul>
                    <p class="my-0">Подробнее в базе знаний - <a href="https://info.zakupschik.ru/188--nastrojka-rabochego-mesta" target="_blank" class="btn-link">Настройка рабочего места</a></p>
                </b-container>
                <b-container class="pb-2">
                    <p class="fw-600 my-0">При авторизации через логин и пароль недоступно осуществление юридически значимых действий. Подробнее - <a href="https://info.zakupschik.ru/182-403--vozmozhnosti-i-varianty-avtorizacii/" target="_blank" class="btn-link fw-600">по ссылке</a>.</p>
                </b-container>
                <b-container>
                    <p class="fw-600 my-0">Техническая поддержка доступна по кнопке "Поддержка" (в правом нижнем углу).</p>
                </b-container>
            </div>
        </b-form-group>
    </div>
</template>
<script>
export default {
    name: 'login-instruction',
    methods: {
        onClose() {
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.error-list {
    list-style-type: none;
}
</style>
