'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import Viewport from '../../misc/Viewport';

@Component
export default class ResponsiveOnOff extends BaseViewModel {
    viewport: Viewport;

    constructor() {
        super();
        this.name = 'ResponsiveOnOff';
        this.viewport = new Viewport();
    }

    created() {
        this.$data.isDesktop = this.isWidthGreaterThen(960);
        this.$data.ison = this.$data.isDesktop;
    }

    data() {
        return {
            ison: true,
            isDesktop: true
        };
    }

    /**
     * Methods
     */
    toggle() {
        if (this.$data.isDesktop) {
            return;
        }
        this.$data.ison = !this.$data.ison;
    }

    off() {
        if (this.$data.isDesktop) {
            return;
        }
        this.$data.ison = false;
    }

    get viewportProxy() {
        return this.viewport;
    }

    @Watch('viewportProxy', { immediate: true, deep: true })
    onViewporttProxyChanged(newState: Record<string, number>) {
        this.$data.ison = this.isWidthGreaterThen(960);
    }

    isWidthGreaterThen(width: number) {
        return this.viewport.width > width;
    }
}
