var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$props.purchaseitem
    ? _c("section", { staticClass: "atmo-content-group" }, [
        _c("h6", [_vm._v("Условия поставки")]),
        _c(
          "ul",
          {
            staticClass: "atmo-content-block-2-columns atmo-no-item-separator",
          },
          [
            _c("li", [
              _c("dt", [
                _vm._v(
                  "Адреса для доставки товаров/выполнения работ/оказания услуг:"
                ),
              ]),
              this.$props.purchaseitem.delivery_addresses &&
              this.$props.purchaseitem.delivery_addresses.length > 0
                ? _c("dd", [
                    _c(
                      "ul",
                      _vm._l(
                        this.$props.purchaseitem.delivery_addresses,
                        function (address, index) {
                          return _c("li", { key: index }, [
                            _c("span", {
                              domProps: { textContent: _vm._s(index + 1) },
                            }),
                            _vm._v(".  "),
                            _c("span", {
                              domProps: { textContent: _vm._s(address) },
                            }),
                          ])
                        }
                      ),
                      0
                    ),
                  ])
                : _c("dd", [_vm._v("Нет")]),
            ]),
            _c("li", [
              _c("dt", [
                _vm._v(
                  "График поставки товаров (выполнения работ, оказания услуг):"
                ),
              ]),
              this.$props.purchaseitem.delivery_schedule
                ? _c("dd", {
                    domProps: {
                      textContent: _vm._s(
                        this.$props.purchaseitem.delivery_schedule
                      ),
                    },
                  })
                : _c("dd", [_vm._v("Нет")]),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Спецификации:")]),
              _c("dd", [
                _c(
                  "ul",
                  _vm._l(
                    this.$props.purchaseitem.deliverables,
                    function (deliverable, index) {
                      return _c("li", { key: index }, [
                        _c("span", {
                          domProps: { textContent: _vm._s(index + 1) },
                        }),
                        _vm._v(".  "),
                        _c("span", {
                          domProps: { textContent: _vm._s(deliverable.title) },
                        }),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Документы:")]),
              _c("dd", [
                this.$props.purchaseitem.contract &&
                this.$props.purchaseitem.contract.attachments.main.length > 0
                  ? _c("ul", { staticClass: "atmo-list" }, [
                      _c("li", [
                        _c("button", {
                          staticClass: "atmo-button-link",
                          domProps: {
                            textContent: _vm._s(
                              this.$props.purchaseitem.contract.actualMainAttachment()
                                .name
                            ),
                          },
                          on: {
                            click: function ($event) {
                              _vm.$props.handlers.downloadFileAttachment(
                                _vm.$props.purchaseitem.contract.actualMainAttachment()
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }