<template>
    <div class="table-responsive">
        <table class="atmo-content-table atmo-deliverables">
            <caption>
                <h5>Измените спецификациию</h5>
            </caption>
            <thead>
                <tr>
                    <th>№</th>
                    <th style="min-width: 400px">Наименование ТРУ</th>
                    <th class="atmo-ctc-quantity">Количество</th>
                    <th class="atmo-ctc-okpd-symbolic-code">Ед.изм</th>
                    <th class="atmo-ctc-price-per-unit">Цена за ед., руб.</th>
                    <th class="atmo-ctc-price-total">Стоимость, руб.</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <fragment v-for="(deliverable, index) in this.$props.deliverables" v-bind:key="deliverable.display_id">
                    <tr :class="visible[index] ? 'border-bottom-0' : ''">
                        <td v-text="index + 1"></td>
                        <td>
                            <action-button-small :title="(!visible[index] ? 'Показать' : 'Скрыть') + ' характеристики'" v-b-toggle="'collapse-' + index" :icon="!visible[index] ? 'eye' : 'eye-slash'" />
                            <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                            {{ deliverable.title }}
                        </td>
                        <td v-if="deliverable.isEditable" class="atmo-ctc-quantity">
                            <span v-bind:class="{ 'atmo-has-errors': deliverable.validator.errors.quantity }" class="atmo-form__field atmo-inverse atmo-small">
                                <formatted-input-number v-slot:default="fim" v-bind:decimals="9" v-bind:padright="false">
                                    <div class="atmo-component-wrapper">
                                        <button v-if="!fim.isInputFocused" v-on:click.stop="fim.focus" v-on:focus="fim.onTriggerFocus" v-bind:class="{ 'atmo-invalid': !fim.isInputValid }" class="atmo-button--small atmo-input" v-text="fim.formatted"></button>

                                        <span v-show="fim.isInputFocused" class="atmo-form__field atmo-inverse atmo-small">
                                            <input v-model.number="deliverable.quantity" v-on:blur="fim.processBlurEvent" v-on:keydown="fim.processKeydownEvent" v-on:input="fim.processInputEvent" v-stop-number-mousewheel required type="number" step="0.000000001" min="0.000000001" />
                                        </span>
                                    </div>
                                </formatted-input-number>
                            </span>
                        </td>
                        <td v-else v-text="deliverable.quantity" class="atmo-ctc-quantity"></td>

                        <td v-text="deliverable.okei_symbolic_code" class="atmo-ctc-okpd-symbolic-code"></td>

                        <td class="atmo-ctc-price-per-unit">
                            <span v-bind:class="{ 'atmo-has-errors': deliverable.validator.errors.price_per_unit }" class="atmo-form__field atmo-inverse atmo-small">
                                <!-- <input v-model.number="deliverable.price_per_unit" v-stop-number-mousewheel type="number" required step="0.01"> -->
                                <currency-input v-model="deliverable.price_per_unit" v-bind:allow-negative="false" v-bind:precision="2" currency="RUB" locale="ru" type="text" id="purchase-startingPrice" placeholder="0" autocomplete="off"> </currency-input>
                            </span>
                        </td>

                        <td class="atmo-ctc-price-total">{{ deliverable.price_total | formatnumber }}</td>

                        <td>
                            <button v-if="!deliverable.isDuplicate" v-on:click="$props.controller.insertDeliverable(deliverable)" v-bind:disabled="deliverable.is_delivery && $props.controller.has_delivery_duplicate" class="atmo-button-icon" title="Дублировать спецификацию">
                                <i class="atmo-icon-duplicate"></i>
                            </button>
                            <button v-else v-on:click="$props.controller.removeDeliverableDuplicate(deliverable)" class="atmo-button-icon atmo-dimmed" title="Удалить">
                                <i class="atmo-icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr v-show="visible[index]">
                        <td colspan="7">
                            <div>
                                <p><strong>Рег. номер ТРУ:</strong> {{ deliverable.gsc_details.registration_number }}</p>
                                <ul class="fs-14">
                                    <li v-if="deliverable.okpd_code"><strong>Код ОКПД2:&nbsp;</strong>{{ deliverable.okpd_code }}</li>
                                    <li><strong>Группа ТРУ:&nbsp;</strong>{{ deliverable.gsc_details.group }}</li>
                                    <li><strong>Наименование:&nbsp;</strong>{{ deliverable.gsc_details.title }}</li>
                                    <li><strong>Тип:&nbsp;</strong>{{ deliverable.gsc_details.type }}</li>
                                </ul>
                            </div>
                            <DeliverableDetails.view v-bind:requirements="deliverable.requirements" v-bind:description="deliverable.gsc_details ? deliverable.gsc_details.description : null" />
                        </td>
                    </tr>
                </fragment>

                <tr v-if="this.isempty">
                    <td colspan="8">Не добавлено спецификаций</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import DeliverableDetailsView from 'themes/views/pages/purchases/item/common/partials/deliverables/view/DeliverableDetails.view.vue';
import { CurrencyInput } from 'vue-currency-input';
import FormattedInputNumber from '@lib/js/src/vue/components/FormattedInputNumber.viewmodel.ts';

export default {
    computed: {
        isempty: function () {
            return this.$props.deliverables.length < 1;
        }
    },
    props: {
        controller: { required: true, type: Object },
        deliverables: { required: true, type: Array }
    },
    components: {
        'DeliverableDetails.view': DeliverableDetailsView,
        'formatted-input-number': FormattedInputNumber,
        CurrencyInput
    },
    data() {
        return {
            visible: []
        };
    }
};
</script>
