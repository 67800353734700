'use strict';

import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';

export interface IState {
    user: UserVO | null;
}

export default class State implements IState {
    user: UserVO | null;

    constructor() {
        this.user = null;
    }
}
