var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("purchase-item-update-controller", {
    attrs: { group: this.$props.group, id: this.$props.id },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (piuc) {
          return [
            _c("div", { staticClass: "atmo-page" }, [
              _c("section", { staticClass: "atmo-fast-access-menu" }, [
                _c("ul", { staticClass: "atmo-container-fixed" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: { "atmo-active": piuc.visibleTab === "terms" },
                        on: {
                          click: function ($event) {
                            return piuc.setTab("terms")
                          },
                        },
                      },
                      [_vm._v("Условия закупки и поставки")]
                    ),
                    piuc.tabbedErrorCounts.terms
                      ? _c("span", {
                          staticClass: "atmo-badge",
                          attrs: { title: "Количество ошибочных полей" },
                          domProps: {
                            textContent: _vm._s(piuc.tabbedErrorCounts.terms),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: {
                          "atmo-active": piuc.visibleTab === "deliverables",
                        },
                        on: {
                          click: function ($event) {
                            return piuc.setTab("deliverables")
                          },
                        },
                      },
                      [_vm._v("Спецификация и документы")]
                    ),
                    piuc.tabbedErrorCounts.deliverables
                      ? _c("span", {
                          staticClass: "atmo-badge",
                          attrs: { title: "Количество ошибочных полей" },
                          domProps: {
                            textContent: _vm._s(
                              piuc.tabbedErrorCounts.deliverables
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c(
                "header",
                [_c("InfoBanner.view", { attrs: { user: piuc.user } })],
                1
              ),
              _c(
                "main",
                { staticClass: "atmo-container-fixed atmo-page-content" },
                [
                  _c("div", { staticClass: "atmo-buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "atmo-button atmo-accented",
                        attrs: {
                          href: "https://app.hinted.me/simulations/7a63d719-ee38-4b27-9c11-92d26293d354/view",
                          rel: "noopener",
                          target: "_blank",
                        },
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-play" }),
                        _vm._v(" Инструкция по созданию извещения"),
                      ]
                    ),
                  ]),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: piuc.visibleTab === "terms",
                          expression: "piuc.visibleTab === 'terms'",
                        },
                      ],
                      staticClass: "atmo-tab",
                    },
                    [
                      _c("CustomerInfo.view", {
                        attrs: { user: piuc.user, inputs: piuc.inputs },
                        on: {
                          "update:inputs": function ($event) {
                            return _vm.$set(piuc, "inputs", $event)
                          },
                        },
                      }),
                      _c("PurchaseTerms.view", {
                        attrs: {
                          controller: piuc,
                          inputs: piuc.inputs,
                          errors: piuc.errors,
                          dictionaries: piuc.dictionaries,
                          deliverablesgroupsselecthandler:
                            piuc.deliverablesGroupsSelectHandler,
                          deliverablesgroupsremovehandler:
                            piuc.deliverablesGroupsRemoveHandler,
                        },
                        on: {
                          "update:inputs": function ($event) {
                            return _vm.$set(piuc, "inputs", $event)
                          },
                        },
                      }),
                      _c("SupplierRequirements.view", {
                        attrs: { inputs: piuc.inputs },
                        on: {
                          "update:inputs": function ($event) {
                            return _vm.$set(piuc, "inputs", $event)
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "atmo-content-group" },
                        [
                          _c("text-header-new", [_vm._v("Условия поставки")]),
                          _c("form-row-edit-addresses-new", {
                            attrs: {
                              label:
                                "Адреса для доставки товаров/выполнения работ/оказания услуг",
                              value: piuc.inputs.delivery_addresses,
                              hint: "Укажите новый адрес или выберите ранее сохраненный адрес из Адресной книги.",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  piuc.inputs,
                                  "delivery_addresses",
                                  $event
                                )
                              },
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              label:
                                "График поставки товаров (выполнения работ, оказания услуг)",
                            },
                            model: {
                              value: piuc.inputs.delivery_schedule,
                              callback: function ($$v) {
                                _vm.$set(piuc.inputs, "delivery_schedule", $$v)
                              },
                              expression: "piuc.inputs.delivery_schedule",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("ul", { staticClass: "atmo-buttons" }, [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "atmo-button-inverse atmo-dimmed",
                                attrs: { to: { name: "Cabinet" } },
                              },
                              [_vm._v("Отменить")]
                            ),
                          ],
                          1
                        ),
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button",
                              on: {
                                click: function ($event) {
                                  return piuc.setTab("deliverables")
                                },
                              },
                            },
                            [_vm._v("Далее")]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button atmo-accented",
                              on: { click: piuc.saveDraft },
                            },
                            [_vm._v("Сохранить черновик")]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: piuc.visibleTab === "deliverables",
                          expression: "piuc.visibleTab === 'deliverables'",
                        },
                      ],
                      staticClass: "atmo-tab",
                    },
                    [
                      _c("deliverables-editable-list-controller", {
                        attrs: {
                          initiallist: piuc.inputs.specification.deliverables,
                          max_price:
                            piuc.orderType?.max_amount ?? 9999999999999,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (dsc) {
                                return [
                                  _c("DeliverablesEditableList.view", {
                                    attrs: {
                                      controller: dsc,
                                      deliverables: dsc.list,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "errors",
                                          fn: function () {
                                            return [
                                              dsc.islistempty
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "atmo-error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Заполните спецификацию"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !dsc.islistempty &&
                                              dsc.errors.is_quantity_empty
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "atmo-error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        'У одной или нескольких позиций спецификации неправильно заполнено поле "Количество".'
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !dsc.islistempty &&
                                              dsc.errors.is_price_per_unit_empty
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "atmo-error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        'У одной или нескольких позиций спецификации неправильно заполнено поле "Цена за единицу".'
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !dsc.islistempty &&
                                              dsc.errors
                                                .is_specification_total_price_invalid
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "atmo-error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Общая стоимость спецификации превышает " +
                                                          _vm._s(
                                                            _vm.$formatPrice(
                                                              piuc.orderType
                                                                ?.max_amount
                                                            )
                                                          ) +
                                                          " руб."
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      piuc.features.kbk_limits && !piuc.inputs.without_limits
                        ? _c("KBKLimitsEditableList.view", {
                            attrs: {
                              initiallist: piuc.inputs.specification.kbkLimits,
                              totalamount:
                                piuc.inputs.specification.total_amount,
                              limitfilterpresets: {
                                year: piuc.inputs.limit_year,
                                purchase_type_id: piuc.inputs.type_id,
                                purchase_id: piuc.inputs.id,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "info",
                                  fn: function () {
                                    return [
                                      _c("p", [
                                        _c("i", {
                                          staticClass:
                                            "atmo-warning atmo-icon-warning",
                                        }),
                                        _vm._v(
                                          ' Внимание! Перед добавление лимитов проверьте правильность значений полей "Год ПГ" и "Тип закупки" в разделе "Условия закупки". При изменении любого из этих полей все добавленные в таблицу лимиты по КБК будут удалены. '
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                      _c("editable-file-attachments-list-controller", {
                        attrs: {
                          initiallist: piuc.inputs.contract_draft_attachment,
                          isrequired: true,
                          origin: "contract",
                          maxfilesallowed: 1,
                        },
                        on: {
                          "fileattachment:changed":
                            piuc.fileAttachmentEventHandler,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (efalc) {
                                return [
                                  _c(
                                    "EditableFileAttachmentsList.view",
                                    { attrs: { controller: efalc } },
                                    [_c("h3", [_vm._v("Проект ГК")])]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("editable-file-attachments-list-controller", {
                        attrs: {
                          initiallist:
                            piuc.inputs.notice_supplementary_attachments,
                          origin: "notice",
                        },
                        on: {
                          "fileattachment:changed":
                            piuc.fileAttachmentEventHandler,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (efalc) {
                                return [
                                  _c(
                                    "EditableFileAttachmentsList.view",
                                    { attrs: { controller: efalc } },
                                    [
                                      _c("h3", [
                                        _vm._v("Дополнительные документы"),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c(
                        "ul",
                        { staticClass: "atmo-buttons" },
                        [
                          _c("li", [
                            _c(
                              "button",
                              {
                                staticClass: "atmo-button-inverse atmo-dimmed",
                                on: {
                                  click: function ($event) {
                                    return piuc.setTab("terms")
                                  },
                                },
                              },
                              [_vm._v("Назад")]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "button",
                              {
                                staticClass: "atmo-button atmo-accented",
                                on: { click: piuc.saveDraft },
                              },
                              [_vm._v("Сохранить черновик")]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "button",
                              {
                                staticClass: "atmo-button",
                                attrs: {
                                  disabled:
                                    piuc.hasErrors ||
                                    !_vm.$store.getters.getCanSign,
                                },
                                on: { click: piuc.invokeSavePurchasePopup },
                              },
                              [_vm._v("Подписать и сохранить")]
                            ),
                          ]),
                          !_vm.$store.getters.getCanSign
                            ? [
                                _vm.$store.getters.getLoginType === "cert"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                      },
                                      [
                                        _vm._v(
                                          "Для действия требуется загрузить/заменить МЧД на странице: "
                                        ),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "green-link",
                                            attrs: {
                                              to: { name: "UserProfile" },
                                            },
                                          },
                                          [_vm._v("Данные учетной записи")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-initialcase text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                      },
                                      [
                                        _vm._v(
                                          "Подписание возможно только при авторизации при помощи ЭЦП"
                                        ),
                                      ]
                                    ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }