<template>
    <notice-item-fl44 :pi="pi" />
</template>

<script>
// import DealStageActionButton from './DealStageActionButton.view.vue';

import NoticeItemFl44 from './NoticeItemFl44.view.vue';

export default {
    props: {
        pi: { required: true, type: Object }
    },
    components: {
        NoticeItemFl44
        // DealStageActionButton
    }
};
</script>
