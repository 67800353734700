<template>
    <b-modal v-model="successConfirm" hide-footer centered @hidden="hide">
        <div class="pb-5 py-4 text-center">
            <img src="@theme/assets/images/icons/feedSucess.svg" alt="" />
            <p class="mt-4 mb-0 fs-14 grey-color">ПОДТВЕРЖДЕНИЕ ВЫПОЛНЕНО УСПЕШНО</p>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'success-confirm-modal',
    props: ['successConfirm'],
    methods: {
        hide() {
            this.$emit('hide');
        }
    }
};
</script>
