export default [
    {
        path: '/organizations',
        component: () => import(/* webpackChunkName: "main" */ '@/components/organizations/organizations-list.vue'),
        name: 'OrganizationsList'
    },
    {
        path: '/organizations/:id',
        component: () => import(/* webpackChunkName: "main" */ '@/components/organizations/organization-show.vue'),
        name: 'OrganizationShow'
    }
];
