'use strict';

import { Component } from 'vue-property-decorator';

import BaseSignPopupController from '@core/js/viewmodels/common/popup/BaseSignPopupController.viewmodel';
import IOnOffOnToggleEventPayload, { IOnOffToggleEvent } from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import AnnexItemVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItem.valueobject';
import OrganizationVO from '@core/js/ddriven/domain/model/users/Organization.valueobject';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import AnnexItemForXMLVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItemForXML.valueobject';
import isEmpty from 'lodash.isempty';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import KBKLimitsUpdateRequestVO from '@core/js/ddriven/application/http/requests/purchases/kbk-limits/KBKLimitsUpdateRequest.valueobject';
import { Certificate } from 'crypto-pro';

export interface ISignContractAnnexPopupPayload extends IOnOffOnToggleEventPayload {
    data: {
        user: UserVO;
        customer: OrganizationVO;
        supplier: OrganizationVO;
        annex: AnnexItemVO;
        contractId: number;
        downloadFileAttachment: Function;
    };
}

@Component
export default class SignContractAnnexPopupController extends BaseSignPopupController {
    public static popupId = 'sign-contract-annex-popup';

    constructor() {
        super();
        this.name = 'SignContractAnnexPopupController';
        this.popupId = SignContractAnnexPopupController.popupId;
    }

    /**
     * Methods
     */
    public async signAnnex(evt: Event) {
        if (isEmpty(this.currentParsedCertificate)) {
            return;
        }
        this.$root.$emit('public:onoff:toggle', { id: this.popupId, ison: false } as IOnOffToggleEvent);

        actWithLoadingSpinner(evt, async () => {
            const response = await ApplicationServiceLocator.get('api').entities.signContractAnnex(
                this.$data.custom.contractId,
                this.$data.custom.annex.id,
                this.$data.xml,
                (this.currentParsedCertificate as Certificate).thumbprint,
                new KBKLimitsUpdateRequestVO(this.$data.custom.annex.kbk_limits.items),
                this.$data.custom.annex.supplier_accounting,
                this.$data.certificate
            );
            response.isSuccess &&
                setTimeout(() => {
                    window.location.reload();
                }, 500);
        });
    }

    protected initializeCustomData(payload: ISignContractAnnexPopupPayload): void {
        this.$data.user = payload.data.user;
        this.$set(this.$data.custom, 'customer', payload.data.customer);
        this.$set(this.$data.custom, 'supplier', payload.data.supplier);
        this.$set(this.$data.custom, 'annex', payload.data.annex);
        this.$set(this.$data.custom, 'contractId', payload.data.contractId);
        this.$set(this.$data.custom, 'downloadFileAttachment', payload.data.downloadFileAttachment);

        this.$data.xml = new AnnexItemForXMLVO(payload.data.annex, payload.data.user, payload.data.contractId).toXML();
    }
}
