<template>
    <notice-list-page-controller v-slot:default="nlpc">
        <div class="atmo-page-entities-list">
            <section class="atmo-page-banner-wrapper">
                <div class="atmo-container-fixed">
                    <h2 class="mb-4">Реестр извещений 44-ФЗ</h2>
                    <NoticeFiltersView v-bind:elpc="nlpc"></NoticeFiltersView>
                </div>
            </section>

            <entity-status-menu-controller v-slot:default="esmc" v-bind:route="nlpc.storeprefix" v-bind:statuses="nlpc.dictionaries.menustatuses" v-bind:currentstatus="nlpc.currentstatus" v-on:status:update="nlpc.processRequestUpdate">
                <responsive-on-off v-slot:default="ronoff">
                    <div class="atmo-container-fixed">
                        <div class="atmo-status-menu-controller">
                            <button v-on:click="ronoff.toggle" class="atmo-onoff"><i class="atmo-icon-burger"></i>&nbsp;<span v-text="esmc.currentTitle"></span></button>

                            <ul v-show="ronoff.ison" class="atmo-menu">
                                <li v-for="menuitem in esmc.statuses" v-bind:key="menuitem.id" v-on:click="ronoff.off">
                                    <button v-bind:disabled="esmc.isDisabledMenuItem(menuitem.id)" v-on:click="esmc.status(menuitem.id)">
                                        <span v-text="menuitem.title"></span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </responsive-on-off>
            </entity-status-menu-controller>

            <StandardPaginationView v-bind:pagination="nlpc.pagination" v-bind:pageupdatehandler="nlpc.processRequestUpdate" />

            <ul class="atmo-container-fixed">
                <li v-for="noticeitem in nlpc.list" v-bind:key="noticeitem.id" class="atmo-entity-item">
                    <notice-item v-slot:default="pi" v-bind:elpc="nlpc" v-bind:noticeitem="noticeitem">
                        <NoticeItemView v-bind:pi="pi"></NoticeItemView>
                    </notice-item>
                </li>

                <li v-if="nlpc.isListEmpty">
                    <figure class="atmo-notfound-image">
                        <img src="/images/common/other/empty-box.svg" alt="Список пуст" />
                        <figcaption>По вашему запросу ничего не найдено</figcaption>
                    </figure>
                </li>
            </ul>

            <StandardPaginationView v-bind:pagination="nlpc.pagination" v-bind:pageupdatehandler="nlpc.processRequestUpdate" />
        </div>
    </notice-list-page-controller>
</template>

<script>
import EntityStatusMenuController from './EntityStatusMenuController.viewmodel.ts';
import NoticeItem from './partials/NoticeItem.viewmodel.ts';
import ResponsiveOnOff from '@lib/js/src/vue/components/ResponsiveOnOff.viewmodel.ts';

import StandardPaginationView from '@core/views/layouts/common/StandardPagination.view.vue';
import NoticeFiltersView from './partials/NoticeFilters.view.vue';
import NoticeItemView from './partials/NoticeItem.view.vue';
import NoticeListPageController from './NoticesListPageController.viewmodel';

export default {
    metaInfo: {
        title: 'Реестр извещений'
    },
    components: {
        StandardPaginationView,
        NoticeFiltersView,
        NoticeItemView,
        'notice-list-page-controller': NoticeListPageController,
        'entity-status-menu-controller': EntityStatusMenuController,
        'notice-item': NoticeItem,
        'responsive-on-off': ResponsiveOnOff
    }
};
</script>
