<template>
    <div class="atmo-content-group">
        <h5>Лимиты по КБК</h5>

        <div class="table-responsive">
            <table class="atmo-content-table">
                <thead>
                    <tr>
                        <th class="atmo-center">№</th>
                        <th>КБК</th>
                        <th>СубКОСГУ</th>
                        <th>Подробности</th>
                        <th class="atmo-right">Год финансирования</th>
                        <th class="atmo-right">Доступный лимит, руб.</th>
                        <th class="atmo-right">Общий лимит, руб.</th>
                        <th class="atmo-right">Распределено, руб. <i class="atmo-quick-helper atmo-icon-question-mark" title="Часть Стоимости спецификации, распределенная на данный Доступный лимит."></i></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(limit, index) in this.$props.limits.items" v-bind:key="`${limit.id}${index + 1}`">
                        <td v-text="index + 1" class="atmo-center"></td>
                        <td v-text="limit.code"></td>
                        <td v-text="limit.sub_kosgu_code"></td>

                        <KBKLimitDetails.view v-bind:limit="limit" />

                        <td v-text="limit.year" class="atmo-right"></td>
                        <td class="atmo-right">{{ limit.amount_available | formatnumber }}</td>
                        <td class="atmo-right">{{ limit.amount_total | formatnumber }}</td>
                        <td class="atmo-right">{{ limit.amount_assigned | formatnumber }}</td>
                    </tr>
                    <tr class="atmo-totals">
                        <td colspan="8" class="atmo-right">Распределено итого, руб.:&emsp;&emsp;{{ $props.limits.distributed_amount_total | formatnumber }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import KBKLimitDetailsView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/update/KBKLimitDetails.view.vue';

export default {
    props: {
        limits: { required: true, type: Object }
    },
    components: {
        'KBKLimitDetails.view': KBKLimitDetailsView
    }
};
</script>
