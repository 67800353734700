<template>
    <entity-list-page-controller v-slot:default="elpc">
        <div class="atmo-page-entities-list">
            <!-- // WARNING: This is basic purchase group (Federal Law 44 | 223) menu control. -->
            <!-- // WARNING: Should be uncommented and used when FL223 is introduced into planned relase. -->
            <!-- <section class="atmo-entity-groups-menu atmo-container-fixed">
                <router-link v-bind:to="{name: 'contracts.grouped', params: {group: 'fl44'}}"
                    v-bind:class="$route.params.group === 'fl44' && 'router-link-active'">44-ФЗ</router-link>&nbsp;
                <router-link v-bind:to="{name: 'contracts.grouped', params: {group: 'fl223'}}"
                    v-bind:class="$route.params.group === 'fl223' && 'router-link-active'">223-ФЗ</router-link>
            </section> -->

            <section class="atmo-page-banner-wrapper">
                <div class="atmo-container-fixed">
                    <h2>Реестр контрактов 44-ФЗ</h2>
                    <ContractFiltersView v-bind:elpc="elpc"></ContractFiltersView>
                </div>
            </section>

            <EntityStatusMenuControllerView v-bind:elpc="elpc" v-bind:route="elpc.storeprefix" v-bind:statuses="elpc.dictionaries.statusesmenu" v-bind:currentstatus="elpc.currentstatus" v-on:status:update="elpc.processRequestUpdate"> </EntityStatusMenuControllerView>

            <StandardPaginationView v-bind:pagination="elpc.pagination" v-bind:pageupdatehandler="elpc.processRequestUpdate" />

            <ul class="atmo-container-fixed">
                <li v-for="contractitem in elpc.list" v-bind:key="contractitem.id" class="atmo-entity-item">
                    <contract-item v-slot:default="ci" v-bind:elpc="elpc" v-bind:contractitem="contractitem">
                        <ContractItemView v-bind:ci="ci"></ContractItemView>
                    </contract-item>
                </li>

                <li v-if="elpc.isListEmpty">
                    <figure class="atmo-notfound-image">
                        <img src="/images/common/other/empty-box.svg" alt="Список пуст" />
                        <figcaption>По вашему запросу ничего не найдено</figcaption>
                    </figure>
                </li>
            </ul>

            <StandardPaginationView v-bind:pagination="elpc.pagination" v-bind:pageupdatehandler="elpc.processRequestUpdate" />
        </div>
    </entity-list-page-controller>
</template>

<script>
import EntityListPageController from '@core/js/viewmodels/common/entity/EntityListPageController.viewmodel.ts';

import ContractFiltersView from './partials/ContractFilters.view.vue';
import EntityStatusMenuControllerView from '@core/views/layouts/common/EntityStatusMenuController.view.vue';
import StandardPaginationView from '@core/views/layouts/common/StandardPagination.view.vue';

import ContractItemView from './partials/ContractItem.view.vue';
import ContractItem from '@core/js/viewmodels/contracts/ContractItem.viewmodel';

export default {
    metaInfo: {
        title: 'Реестр договоров'
    },
    components: {
        StandardPaginationView,
        EntityStatusMenuControllerView,
        ContractFiltersView,
        ContractItemView,
        'entity-list-page-controller': EntityListPageController,
        'contract-item': ContractItem
    }
};
</script>
