'use strict';

import get from 'lodash.get';

export default class BaseLocator {
    protected static _container: BaseLocator;

    public static get(path?: string) {
        if (!this._container) {
            this._container = new this();
        }
        return path ? get(this._container, path) : this._container;
    }
}
