<template>
    <section class="atmo-tab-content">
        <div class="atmo-content-group">
            <h6>Подпись</h6>
            <pre v-text="this.$props.xml"></pre>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        xml: { required: true, type: String },
        certificate: { required: true }
    }
};
</script>
