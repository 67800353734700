var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$props.user && this.$props.inputs && this.$props.decoded
    ? _c("section", { staticClass: "atmo-tab-content atmo-content-group" }, [
        _c("h6", [_vm._v("Информация о заказчике")]),
        _c(
          "ul",
          {
            staticClass: "atmo-content-block-2-columns atmo-no-item-separator",
          },
          [
            _c("li", [
              _c("dt", [_vm._v("Полное наименование:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.user.organization.title),
                },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("ИНН:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.user.organization.inn),
                },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("КПП:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.user.organization.kpp),
                },
              }),
            ]),
            this.$props.user.organization.ogrn
              ? _c("li", [
                  _c("dt", [_vm._v("ОГРН:")]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(this.$props.user.organization.ogrn),
                    },
                  }),
                ])
              : _vm._e(),
            _c("li", [
              _c("dt", [_vm._v("Адрес:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.user.organization.address),
                },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Дополнительная контактная информация:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(
                    this.$props.inputs.additional_contact_info
                  ),
                },
              }),
            ]),
          ]
        ),
        _c("h6", [_vm._v("Условия закупки")]),
        _c(
          "ul",
          {
            staticClass: "atmo-content-block-2-columns atmo-no-item-separator",
          },
          [
            _c("li", [
              _c("dt", [_vm._v("Предмет закупки:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(
                    this.$props.decoded.deliverable_group_title
                  ),
                },
              }),
            ]),
            this.$props.inputs.purchase_object
              ? _c("li", [
                  _c("dt", [_vm._v("Объект закупки:")]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(this.$props.inputs.purchase_object),
                    },
                  }),
                ])
              : _vm._e(),
            _c("li", [
              _c("dt", [_vm._v("Тип закупки:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.decoded.type_title),
                },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Продолжительность закупочной сессии:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.decoded.duration_title),
                },
              }),
            ]),
            this.$props.inputs.urgency_reason?.id
              ? _c("li", [
                  _c("dt", [
                    _vm._v("Причина проведения срочной закупочной сессии:"),
                  ]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(
                        this.$props.decoded.urgency_reason_title
                      ),
                    },
                  }),
                ])
              : _vm._e(),
            this.$props.inputs.urgency_reason?.attachment
              ? _c("li", [
                  _c("dt", [
                    _vm._v(
                      "Документ обоснования проведения срочной закупочной сессии:"
                    ),
                  ]),
                  _c("dd", [
                    _c("button", {
                      staticClass: "atmo-button-link",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$props.inputs.urgency_reason.attachment.name
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$props.download(
                            _vm.$props.inputs.urgency_reason.attachment
                          )
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            this.$props.inputs.with_advance
              ? _c("li", [
                  _c("dt", [_vm._v("Аванс:")]),
                  _c("dd", [
                    this.$props.inputs.with_advance
                      ? _c("span", [_vm._v("Да")])
                      : _c("span", [_vm._v("Нет")]),
                  ]),
                ])
              : _vm._e(),
            this.$props.inputs.with_advance
              ? _c("li", [
                  _c("dt", [_vm._v("Размер аванса, %:")]),
                  _c("dd", {
                    domProps: {
                      textContent: _vm._s(
                        this.$props.inputs.advance_percentage
                      ),
                    },
                  }),
                ])
              : _vm._e(),
            _c("li", [
              _c("dt", [_vm._v("Планируемая дата заключения контракта:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(
                    this.$props.inputs.planned_contract_signature_date
                  ),
                },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Планируемая дата исполнения контракта:")]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(
                    this.$props.inputs.planned_contract_fulfillment_date
                  ),
                },
              }),
            ]),
            _c("li", [
              _vm._m(0),
              _c("dd", [
                _vm._v(
                  _vm._s(
                    _vm._f("formatnumber")(this.$props.inputs.starting_price)
                  )
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [
                _vm._v(
                  "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации:"
                ),
              ]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(
                    this.$props.inputs.unilateral_contract_termination_info
                  ),
                },
              }),
            ]),
          ]
        ),
        _c("h6", [_vm._v("Требования к поставщикам")]),
        this.$props.inputs.supplier_requirements
          ? _c(
              "ul",
              {
                staticClass:
                  "atmo-content-block-2-columns atmo-no-item-separator",
              },
              [
                _c("li", { staticClass: "atmo-wide-text" }, [
                  _c("dt", [
                    _vm._v(
                      "Участником закупки могут быть только субъекты малого предпринимательства (СМП):"
                    ),
                  ]),
                  _c("dd", [
                    this.$props.inputs.supplier_requirements?.is_only_smb
                      ? _c("span", [_vm._v("Да")])
                      : _c("span", [_vm._v("Нет")]),
                  ]),
                ]),
                _c("li", { staticClass: "atmo-wide-text" }, [
                  _c("dt", [
                    _vm._v(
                      "Установить требование к участникам по рейтингу с возможностью отклонения предложения участника с рейтингом ниже 4,5 балла:"
                    ),
                  ]),
                  _c("dd", [
                    this.$props.inputs.supplier_requirements?.rating
                      ? _c("span", [_vm._v("Да")])
                      : _c("span", [_vm._v("Нет")]),
                  ]),
                ]),
                _c("li", { staticClass: "atmo-wide-text" }, [
                  _c("dt", [
                    _vm._v(
                      "Соответствие участника закупки требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:"
                    ),
                  ]),
                  this.$props.inputs.supplier_requirements?.a31s1p1
                    ? _c("dd", [_vm._v("Да")])
                    : _c("dd", [_vm._v("Нет")]),
                ]),
                this.$props.inputs.supplier_requirements?.a31s1p1
                  ? _c("li", [
                      _c("dd", { staticClass: "atmo-single-column" }, [
                        _c("p", [
                          _vm._v("Описание требований: "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                this.$props.inputs.supplier_requirements
                                  .a31s1p1_details.description
                              ),
                            },
                          }),
                        ]),
                        _c("p", [_vm._v("Подтверждающие документы:")]),
                        _c(
                          "ul",
                          _vm._l(
                            this.$props.inputs.supplier_requirements
                              .a31s1p1_details.supporting_documents,
                            function (document, index) {
                              return _c("li", { key: index }, [
                                _c("span", [_vm._v(_vm._s(index + 1) + ". ")]),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(document.description),
                                  },
                                }),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("li", { staticClass: "atmo-wide-text" }, [
                  _c("dt", [
                    _vm._v(
                      "Соответствие участника закупки требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:"
                    ),
                  ]),
                  _c("dd", [
                    this.$props.inputs.supplier_requirements?.a31s1ps3_5ps7_11
                      ? _c("span", [_vm._v("Да")])
                      : _c("span", [_vm._v("Нет")]),
                  ]),
                ]),
                _c("li", { staticClass: "atmo-wide-text" }, [
                  _c("dt", [
                    _vm._v(
                      "Соответствие участника закупки требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:"
                    ),
                  ]),
                  _c("dd", [
                    this.$props.inputs.supplier_requirements?.a31s1_1
                      ? _c("span", [_vm._v("Да")])
                      : _c("span", [_vm._v("Нет")]),
                  ]),
                ]),
                _c("li", { staticClass: "atmo-wide-text" }, [
                  _c("dt", [
                    _vm._v(
                      "Участник является социально ориентированной некоммерческой организацией (СОНКО):"
                    ),
                  ]),
                  _c("dd", [
                    this.$props.inputs.supplier_requirements?.is_only_sonko
                      ? _c("span", [_vm._v("Да")])
                      : _c("span", [_vm._v("Нет")]),
                  ]),
                ]),
                _c("li", { staticClass: "atmo-wide-text" }, [
                  _c("dt", [_vm._v("Дополнительные требования:")]),
                  _c("dd", [
                    this.$props.inputs.supplier_requirements?.additional.state
                      .is_required
                      ? _c("span", [_vm._v("Да")])
                      : _c("span", [_vm._v("Нет")]),
                  ]),
                ]),
                this.$props.inputs.supplier_requirements?.additional.state
                  .is_required
                  ? _c("li", [
                      _c("dd", { staticClass: "atmo-single-column" }, [
                        _c("table", { staticClass: "atmo-table-fixed" }, [
                          _vm._m(1),
                          _c(
                            "tbody",
                            { staticStyle: { "vertical-align": "top" } },
                            _vm._l(
                              this.$props.inputs.supplier_requirements
                                ?.additional.requirements,
                              function (requirement, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", {
                                    staticClass: "atmo-ctc-place",
                                    domProps: {
                                      textContent: _vm._s(index + 1),
                                    },
                                  }),
                                  _c("td", {
                                    staticClass: "atmo-ctc-width-45pct",
                                    domProps: {
                                      textContent: _vm._s(
                                        requirement.description
                                      ),
                                    },
                                  }),
                                  _c("td", {
                                    staticClass: "atmo-ctc-width-45pct",
                                    domProps: {
                                      textContent: _vm._s(requirement.reason),
                                    },
                                  }),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _c("h6", [_vm._v("Условия поставки")]),
        _c(
          "ul",
          {
            staticClass: "atmo-content-block-2-columns atmo-no-item-separator",
          },
          [
            _c("li", [
              _c("dt", [
                _vm._v(
                  "Адреса для доставки товаров/выполнения работ/оказания услуг:"
                ),
              ]),
              _c("dd", [
                _c(
                  "ul",
                  _vm._l(
                    this.$props.inputs?.delivery_addresses,
                    function (address, index) {
                      return _c("li", { key: index }, [
                        _c("span", {
                          domProps: { textContent: _vm._s(index + 1) },
                        }),
                        _vm._v(".  "),
                        _c("span", {
                          domProps: { textContent: _vm._s(address.address) },
                        }),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [
                _vm._v(
                  "График поставки товаров (выполнения работ, оказания услуг):"
                ),
              ]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.inputs.delivery_schedule),
                },
              }),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Спецификация:")]),
              _c("dd", [
                _c(
                  "ul",
                  [
                    _vm._l(
                      this.$props.inputs?.deliverables,
                      function (deliverable, index) {
                        return _c("li", { key: index }, [
                          _c("span", {
                            domProps: { textContent: _vm._s(index + 1) },
                          }),
                          _vm._v(".  "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(deliverable.title),
                            },
                          }),
                          _vm._v("  "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(deliverable.price_per_unit),
                            },
                          }),
                          _vm._v(" руб. *  "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(deliverable.quantity),
                            },
                          }),
                          _vm._v("  "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                deliverable.okei_symbolic_code
                              ),
                            },
                          }),
                          _vm._v(" =  "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(deliverable.price_total),
                            },
                          }),
                          _vm._v(" руб. "),
                        ])
                      }
                    ),
                    _c("li", [
                      _vm._v("ИТОГО: "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            this.$props.inputs.starting_price
                          ),
                        },
                      }),
                    ]),
                  ],
                  2
                ),
              ]),
            ]),
            _c("li", [
              _c("dt", [_vm._v("Документы:")]),
              _c("dd", [
                _c(
                  "ul",
                  _vm._l(
                    [
                      ...this.$props.inputs.contract_draft_attachment,
                      ...this.$props.inputs.notice_supplementary_attachments,
                    ],
                    function (attachment, index) {
                      return _c("li", { key: index }, [
                        _c("span", {
                          domProps: { textContent: _vm._s(index + 1) },
                        }),
                        _vm._v(".  "),
                        _c("span", {
                          domProps: { textContent: _vm._s(attachment.name) },
                        }),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("abbr", { attrs: { title: "Начальная цена контракта" } }, [
        _vm._v("НЦК"),
      ]),
      _vm._v(", руб.:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("td", { staticClass: "atmo-ctc-place" }, [_vm._v("№")]),
        _c("td", { staticClass: "atmo-ctc-width-45pct" }, [_vm._v("Описание")]),
        _c("td", { staticClass: "atmo-ctc-width-45pct" }, [
          _vm._v("Причина или цель установки требования"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }