'use strict';

import Config from '@core/js/ddriven/application/config/Config';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default class FormatDate {
    static timezone = Config.get('timezone.customer') as string;

    public static format(dateString: string, command: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return FormatDate[command](dateString);
    }

    public static withSeconds(ISODate: string): string {
        return dayjs(ISODate).tz(FormatDate.timezone).format('DD.MM.YYYY HH:mm:ss');
    }

    public static removeSeconds(ISODate: string): string {
        return dayjs(ISODate).tz(FormatDate.timezone).format('DD.MM.YYYY HH:mm');
    }

    public static onlyDate(ISODate: string): string {
        // if (!ISODate) { throw new Error(`ATMO Exception: the ISODate argument "${ISODate}" is invalid`); }
        return !ISODate ? `Invalid date input: "${ISODate}"` : dayjs(ISODate).tz(FormatDate.timezone).format('DD.MM.YYYY');
    }

    public static dateFromISOUTCToTimezoned(dateISO: string, timezone: string): string {
        // const timezonedDate = dayjs(dateISO).tz(timezone, true);
        const timezonedDate = dayjs(dateISO).tz(timezone);
        return timezonedDate.format();
    }

    public static dateFromTimezonedToISOUTC(dateTimezoned: string): string {
        return dayjs(dateTimezoned).tz('UTC').format();
    }

    public static dateNormalizedTimezoned(dateRawTimezoned: string, timezone: string): string {
        const timezonedDate = dayjs(dateRawTimezoned).tz(timezone, true);
        return timezonedDate.format();
    }
}
