var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { attrs: { lg: _vm.labelColsLg, sm: _vm.labelColsSm } },
    [
      _c(
        "b-form-group",
        {
          attrs: {
            description: _vm.description,
            label: _vm.label,
            "label-for": _vm.id,
            state: _vm.state,
            "label-class": "fs-14 grey-color fw-700",
          },
        },
        [
          _c("b-form-input", {
            staticClass: "fs-14",
            attrs: {
              id: _vm.id,
              disabled: _vm.disabled,
              maxlength: _vm.maxLength,
              state: _vm.state,
              type: _vm.type,
            },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          }),
          !_vm.state
            ? [
                _c("b-form-invalid-feedback", [
                  _vm._v(_vm._s(_vm.errorMessage)),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }