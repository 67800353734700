'use strict';

import PurchaseItem from '@core/js/ddriven/domain/model/purchases/view/list/PurchaseItem.valueobject';
import PurchaseRequest, { IPurchaseRequest } from '@core/js/ddriven/application/http/requests/purchases/PurchaseRequest.valueobject';
import StandardPagination from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';

export interface IState {
    list: Readonly<PurchaseItem>[];
    request: IPurchaseRequest;
    pagination: null | StandardPagination;
    routereset: boolean;
}

export default class State implements IState {
    list: Array<PurchaseItem>;
    request: IPurchaseRequest;
    pagination: null | StandardPagination;
    routereset: boolean;

    constructor() {
        this.list = [];
        this.request = new PurchaseRequest();
        this.pagination = null;
        this.routereset = false;
    }
}
