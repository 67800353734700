<template>
    <section class="atmo-tab-content atmo-content-group" v-if="this.$props.user && this.$props.inputs && this.$props.decoded">
        <h6>Информация о заказчике</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Полное наименование:</dt>
                <dd v-text="this.$props.user.organization.title"></dd>
            </li>
            <li>
                <dt>ИНН:</dt>
                <dd v-text="this.$props.user.organization.inn"></dd>
            </li>
            <li>
                <dt>КПП:</dt>
                <dd v-text="this.$props.user.organization.kpp"></dd>
            </li>
            <li v-if="this.$props.user.organization.ogrn">
                <dt>ОГРН:</dt>
                <dd v-text="this.$props.user.organization.ogrn"></dd>
            </li>
            <li>
                <dt>Адрес:</dt>
                <dd v-text="this.$props.user.organization.address"></dd>
            </li>
            <li>
                <dt>Дополнительная контактная информация:</dt>
                <dd v-text="this.$props.inputs.additional_contact_info"></dd>
            </li>
        </ul>

        <h6>Условия закупки</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Предмет закупки:</dt>
                <dd v-text="this.$props.decoded.deliverable_group_title"></dd>
            </li>
            <li v-if="this.$props.inputs.purchase_object">
                <dt>Объект закупки:</dt>
                <dd v-text="this.$props.inputs.purchase_object"></dd>
            </li>
            <li>
                <dt>Тип закупки:</dt>
                <dd v-text="this.$props.decoded.type_title"></dd>
            </li>
            <li>
                <dt>Продолжительность закупочной сессии:</dt>
                <dd v-text="this.$props.decoded.duration_title"></dd>
            </li>
            <li v-if="this.$props.inputs.urgency_reason?.id">
                <dt>Причина проведения срочной закупочной сессии:</dt>
                <dd v-text="this.$props.decoded.urgency_reason_title"></dd>
            </li>
            <li v-if="this.$props.inputs.urgency_reason?.attachment">
                <dt>Документ обоснования проведения срочной закупочной сессии:</dt>
                <dd>
                    <button v-on:click="$props.download($props.inputs.urgency_reason.attachment)" class="atmo-button-link" v-text="$props.inputs.urgency_reason.attachment.name"></button>
                </dd>
            </li>
            <li v-if="this.$props.inputs.with_advance">
                <dt>Аванс:</dt>
                <dd>
                    <span v-if="this.$props.inputs.with_advance">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>
            <li v-if="this.$props.inputs.with_advance">
                <dt>Размер аванса, %:</dt>
                <dd v-text="this.$props.inputs.advance_percentage"></dd>
            </li>

            <li>
                <dt>Планируемая дата заключения договора:</dt>
                <dd v-text="this.$props.inputs.planned_contract_signature_date"></dd>
            </li>
            <li>
                <dt>Планируемая дата исполнения договора:</dt>
                <dd v-text="this.$props.inputs.planned_contract_fulfillment_date"></dd>
            </li>
            <li>
                <dt><abbr title="Начальная цена контракта">НЦК</abbr>, руб.:</dt>
                <dd>{{ this.$props.inputs.starting_price | formatnumber }}</dd>
            </li>
            <li>
                <dt>Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации:</dt>
                <dd v-text="this.$props.inputs.unilateral_contract_termination_info"></dd>
            </li>
        </ul>

        <h6>Требования к поставщикам</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator" v-if="this.$props.inputs.supplier_requirements">
            <li class="atmo-wide-text">
                <dt>Участником закупки могут быть только субъекты малого предпринимательства (СМП):</dt>
                <dd>
                    <span v-if="this.$props.inputs.supplier_requirements?.is_only_smb">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>

            <li class="atmo-wide-text">
                <dt>Установить требование к участникам по рейтингу с возможностью отклонения предложения участника с рейтингом ниже 4,5 балла:</dt>
                <dd>
                    <span v-if="this.$props.inputs.supplier_requirements?.rating">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>

            <li class="atmo-wide-text">
                <dt>Соответствие участника закупки требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:</dt>
                <dd v-if="this.$props.inputs.supplier_requirements?.a31s1p1">Да</dd>
                <dd v-else>Нет</dd>
            </li>

            <li v-if="this.$props.inputs.supplier_requirements?.a31s1p1">
                <dd class="atmo-single-column">
                    <p>Описание требований: <span v-text="this.$props.inputs.supplier_requirements.a31s1p1_details.description"></span></p>
                    <p>Подтверждающие документы:</p>
                    <ul>
                        <li v-for="(document, index) in this.$props.inputs.supplier_requirements.a31s1p1_details.supporting_documents" v-bind:key="index">
                            <span>{{ index + 1 }}.&nbsp;</span>
                            <span v-text="document.description"></span>
                        </li>
                    </ul>
                </dd>
            </li>

            <li class="atmo-wide-text">
                <dt>Соответствие участника закупки требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:</dt>
                <dd>
                    <span v-if="this.$props.inputs.supplier_requirements?.a31s1ps3_5ps7_11">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>
            <li class="atmo-wide-text">
                <dt>Соответствие участника закупки требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:</dt>
                <dd>
                    <span v-if="this.$props.inputs.supplier_requirements?.a31s1_1">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>

            <li class="atmo-wide-text">
                <dt>Участник является социально ориентированной некоммерческой организацией (СОНКО):</dt>
                <dd>
                    <span v-if="this.$props.inputs.supplier_requirements?.is_only_sonko">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>

            <li class="atmo-wide-text">
                <dt>Дополнительные требования:</dt>
                <dd>
                    <span v-if="this.$props.inputs.supplier_requirements?.additional.state.is_required">Да</span>
                    <span v-else>Нет</span>
                </dd>
            </li>

            <li v-if="this.$props.inputs.supplier_requirements?.additional.state.is_required">
                <dd class="atmo-single-column">
                    <table class="atmo-table-fixed">
                        <thead>
                            <tr>
                                <td class="atmo-ctc-place">№</td>
                                <td class="atmo-ctc-width-45pct">Описание</td>
                                <td class="atmo-ctc-width-45pct">Причина или цель установки требования</td>
                            </tr>
                        </thead>
                        <tbody style="vertical-align: top">
                            <tr v-for="(requirement, index) in this.$props.inputs.supplier_requirements?.additional.requirements" v-bind:key="index">
                                <td v-text="index + 1" class="atmo-ctc-place"></td>
                                <td v-text="requirement.description" class="atmo-ctc-width-45pct"></td>
                                <td v-text="requirement.reason" class="atmo-ctc-width-45pct"></td>
                            </tr>
                        </tbody>
                    </table>
                </dd>
            </li>
        </ul>

        <h6>Условия поставки</h6>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Адреса для доставки товаров/выполнения работ/оказания услуг:</dt>
                <dd>
                    <ul>
                        <li v-for="(address, index) in this.$props.inputs?.delivery_addresses" v-bind:key="index">
                            <span v-text="index + 1"></span>.&nbsp;
                            <span v-text="address.address"></span>
                        </li>
                    </ul>
                </dd>
            </li>
            <li>
                <dt>График поставки товаров (выполнения работ, оказания услуг):</dt>
                <dd v-text="this.$props.inputs.delivery_schedule"></dd>
            </li>
            <li>
                <dt>Спецификация:</dt>
                <dd>
                    <ul>
                        <li v-for="(deliverable, index) in this.$props.inputs?.deliverables" v-bind:key="index">
                            <span v-text="index + 1"></span>.&nbsp; <span v-text="deliverable.title"></span>&nbsp; <span v-text="deliverable.price_per_unit"></span>&nbsp;руб.&nbsp;*&nbsp; <span v-text="deliverable.quantity"></span>&nbsp;
                            <span v-text="deliverable.okei_symbolic_code"></span>&nbsp;=&nbsp; <span v-text="deliverable.price_total"></span>&nbsp;руб.
                        </li>
                        <li>ИТОГО: <span v-text="this.$props.inputs.starting_price"></span></li>
                    </ul>
                </dd>
            </li>
            <li>
                <dt>Документы:</dt>
                <dd>
                    <ul>
                        <li v-for="(attachment, index) in [...this.$props.inputs.contract_draft_attachment, ...this.$props.inputs.notice_supplementary_attachments]" v-bind:key="index">
                            <span v-text="index + 1"></span>.&nbsp;
                            <span v-text="attachment.name"></span>
                        </li>
                    </ul>
                </dd>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    props: {
        download: { required: true, type: Function },
        user: { required: true },
        inputs: { required: true },
        decoded: { required: true },
        certificate: { required: true }
    }
};
</script>
