<template>
    <b-form-group :label-cols-sm="labelOnTop ? 0 : 4" :label-cols-lg="labelOnTop ? 0 : 3" :class="{ 'mb-0': compact }" :description="description" :label="label + ':'" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <b-form-textarea :id="id" v-model="text" :state="state" :disabled="disabled" :rows="rows" class="fs-14"></b-form-textarea>
        <template v-if="!state">
            <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'form-row-edit-textarea',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        rows: {
            type: Number,
            default: 2
        },
        labelOnTop: {
            type: Boolean,
            default: false
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            }
        },
        id() {
            return 'form_row_edit_textarea_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
                if (!this.v.minLength && !!this.v.$params.minLength) return `Минимальная длина поля - ${this.v.$params.minLength.min} символа/ов`;
            }
            return '';
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
