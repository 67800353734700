'use strict';

import State, { ActionContext } from 'vuex';

import PurchaseRequest from '@core/js/ddriven/application/http/requests/purchases/PurchaseRequest.valueobject';

const actions = {
    load: async function (context: ActionContext<any, any>, request: PurchaseRequest): Promise<void> {
        const response = await context.rootState.rearchitected.utils.atmoapi.load('purchases:commercial', request.toQueryString());

        context.commit('setList', response.data.data);
        context.commit('setPagination', response.data.meta);
    }
};

export default actions;
