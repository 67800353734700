'use strict';

import AnnexItemVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItem.valueobject';
import DeliverableItemVO from '@core/js/ddriven/domain/model/common/deliverable/DeliverableItem.valueobject';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import { toXML } from 'jstoxml';

export default class AnnexItemForXMLVO {
    user_id: number;
    organization_id: number;
    contract_id: number;
    supplementary_agreement_id: number;
    supplementary_agreement_date: string;

    file: {
        name: string;
        stored_document_id: string;
    };

    specifications: {
        name: string;
        amount: number;
        price: number;
        total_cost: number;
        okei_id: number;
        ktru_item_id: number | null;
        okpd_id: number;
    }[];

    constructor(annex: AnnexItemVO, user: UserVO, contractId: number) {
        this.user_id = user.id;
        this.organization_id = user.organization.id as number;
        this.contract_id = contractId;
        this.supplementary_agreement_id = annex.id as number;
        this.supplementary_agreement_date = annex.date as string;

        this.file = {
            name: annex.attachments[0].name,
            stored_document_id: annex.attachments[0].file_id as string
        };

        this.specifications = annex.deliverables.map((deliverable: DeliverableItemVO) => {
            return {
                name: deliverable.title,
                amount: deliverable.quantity as number,
                price: deliverable.price_per_unit as number,
                total_cost: deliverable.price_total as number,
                okei_id: deliverable.okei_id as number,
                ktru_item_id: deliverable.gsc_details ? deliverable.gsc_details.id : null,
                okpd_id: deliverable.okpd_id as number
            };
        });
    }

    public toXML(): string {
        const object = JSON.parse(JSON.stringify({ signed_form: { contract_supplementary_agreement: this } }));
        return toXML(object, { header: true, indent: '    ' });
    }
}
