'use strict';

import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import { IDisputeResolutionWidgetData } from '@core/js/viewmodels/purchases/item/view/widgets/contract/disputes/ContractDisputeResolutionWidgetController.viewmodel';
import { Certificate } from 'crypto-pro';

export interface IParams {
    widgetdata: IDisputeResolutionWidgetData;
    xml: string;
    thumbprint: string;
    certificate: Certificate;
}

export default class LinkContractDisputeAttachmentRequestVO extends BaseValueObject {
    new_contract: { id: string } | null;
    xml: string;
    thumbprint: string;
    certificate: Certificate;
    disagreement_protocol_file: {
        purchase_id: number;
        id: string;
        comment: string | null;
    };

    constructor(data: IParams) {
        super();

        this.new_contract = data.widgetdata.contractAttachmentFileId ? { id: data.widgetdata.contractAttachmentFileId } : null;
        this.xml = data.xml;
        this.thumbprint = data.thumbprint;
        this.certificate = data.certificate;
        this.disagreement_protocol_file = {
            purchase_id: data.widgetdata.purchaseId,
            id: data.widgetdata.attachment.file_id as string,
            comment: data.widgetdata.comment
        };
    }
}
