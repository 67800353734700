import store from '@/store';
import Config from '@/rearchitected/core/js/ddriven/application/config/Config';

export class BaseV1ApiService {
    baseUrl = Config.get('api.config.v1.url');
    resource;

    constructor(resource) {
        if (!resource) throw new Error('Resource is not provided');
        this.resource = resource;
    }

    getUrl(id = '') {
        return id ? `${this.baseUrl}/${this.resource}/${id}` : `${this.baseUrl}/${this.resource}`;
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        store.dispatch('showError', err).then(() => {});
    }
}
