'use strict';

import { Component, Prop } from 'vue-property-decorator';
import isTypeOrNull from '@lib/js/src/misc/isTypeOrNull';
import ContractRequestFilter from '@core/js/ddriven/application/http/requests/contracts/ContractRequestFilter.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import EntityFiltersController from '@core/js/viewmodels/common/entity/EntityFiltersController.viewmodel';

@Component
export default class ContractFiltersController extends EntityFiltersController {
    constructor() {
        super();
        this.name = 'ContractFiltersController';
    }

    created() {
        this.$data.filters = this.createRequestFilter(this.filterConstructor, this.$props.requestfilter);
    }

    @Prop({
        required: true,
        validator: (prop) => {
            return isTypeOrNull(prop, 'Array');
        }
    })
    readonly types!: ExtendedDictionaryItem[];

    /**
     * Computed
     */
    get filterConstructor() {
        return ContractRequestFilter;
    }

    get contracttype() {
        const found = this.$props.types.find((contracttype: Record<string, unknown>) => {
            return contracttype.code === this.$props.requestfilter.type;
        });
        const currentid = found ? found.id : null;
        return { currentid: currentid };
    }

    /**
     * Methods
     */
    manageSelection(payload: Record<string, any>) {
        this.$data.filters.type = payload.item && payload.item.code ? payload.item.code : null;
    }
}
