import { CabinetModelApiService } from '@/services/backend/cabinet/base';
import axios from 'axios';
import FileDownload from 'js-file-download';
import store from '@/store';

export class CabinetAccountingApiService extends CabinetModelApiService {
    constructor() {
        super('provider/accounting');
    }

    async getStatistics() {
        try {
            const response = await axios.get(this.getUrl('statistics'));
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getDetails(payload) {
        try {
            const response = await axios.get(this.getUrl('details'), { params: { ...payload } });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    downloadHistoryXls() {
        return axios.get(this.getUrl('history_xls'), { responseType: 'blob' });
    }

    async downloadInvoicePdf(payload) {
        await axios
            .post(this.getUrl('invoice_pdf'), payload, { responseType: 'blob' })
            .then((response) => {
                let filename = '';
                const disposition = response.headers['content-disposition'];
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let invoiceNumberRegex = /\d\d\d\d-\d\d\d\d\d\d/;
                    const matches = invoiceNumberRegex.exec(disposition);
                    if (matches != null && matches[0]) {
                        filename = matches[0].replace(/['"]/g, '');
                    }
                }
                filename = 'Счет-договор_СМЗ-' + filename + '.pdf';
                FileDownload(response.data, filename);
                if (payload.send_email) {
                    store
                        .dispatch('pushToast', {
                            text: 'Счет отправлен на Ваш email',
                            title: 'Успех',
                            variant: 'green',
                            timer: 5000
                        })
                        .then(() => {});
                }
                return true;
            })
            .catch((err) => {
                this.handleErrors(err);
            });
    }

    async storeSignedRefundRequest(form, xml, certificate) {
        try {
            await axios.post(this.getUrl('refund_requests/store'), { form, xml, certificate });
            await store.dispatch('showSuccessToast', 'Запрос на возврат средств успешно подан, средства заблокированы на ЛС.');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getXml(data) {
        try {
            const response = await axios.post(this.getUrl('refund_requests/get_xml'), data);
            return response.data.xml;
        } catch (error) {
            this.handleErrors(error);
        }
    }
}
