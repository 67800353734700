var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("contract-annex-update-controller", {
    attrs: {
      mode: this.$props.mode,
      contract: this.$props.pivc.purchaseitem.contract,
    },
    on: {
      "annexitemeditable:changed":
        this.$props.disputecontroller.onAnnexItemEditableChange,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (cauc) {
          return [
            _c(
              "div",
              { staticClass: "atmo-component-wrapper" },
              [
                _c("AnnexEditableDeliverablesList.view", {
                  attrs: { controller: cauc, deliverables: cauc.deliverables },
                }),
                cauc.errors.quantity
                  ? _c("p", { staticClass: "atmo-error" }, [
                      _vm._v(
                        "Максимальное увеличение или снижение количества может составлять не более 10%."
                      ),
                    ])
                  : _vm._e(),
                cauc.errors.price_per_unit
                  ? _c("p", { staticClass: "atmo-error" }, [
                      _vm._v(
                        "Цена единицы ТРУ в спецификации не может быть больше указанной в контракте."
                      ),
                    ])
                  : _vm._e(),
                cauc.errors.price_total
                  ? _c("p", { staticClass: "atmo-error" }, [
                      _vm._v(
                        "Превышена допустимая общая стоимость дополнительного соглашения. Максимальное увеличение может составлять не более 10%."
                      ),
                    ])
                  : _vm._e(),
                cauc.errors.kbk_limit
                  ? _c("p", { staticClass: "atmo-error" }, [
                      _vm._v(
                        "Сумма стоимостей по одной или нескольким позициям спецификации превышает доступный лимит по КБК."
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "ul",
                  {
                    staticClass:
                      "atmo-content-block-2-columns atmo-no-item-separator",
                  },
                  [
                    _c("li", [
                      _c("dt", [
                        _vm._v(
                          "Общая стоимость дополнительного соглашения, руб.:"
                        ),
                      ]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(_vm._f("formatnumber")(cauc.price_total_annex))
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("dt", [_vm._v("Общая стоимость контракта, руб.:")]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatnumber")(cauc.price_total_contract)
                          )
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("dt", [
                        _vm._v("Дата заключения дополнительного соглашения:"),
                      ]),
                      _c(
                        "dd",
                        {
                          staticClass: "atmo-form__field atmo-as-input",
                          class: { "atmo-has-errors": cauc.hasDateError },
                        },
                        [
                          _c("date-picker", {
                            attrs: {
                              id: "purchase-annexSignatureDate",
                              position: "bottom",
                              label: "Выберите дату",
                              "button-now-translation": "Сегодня",
                              format: "YYYY-MM-DD",
                              formatted: "DD.MM.YYYY",
                              "no-label": true,
                              "auto-close": true,
                              "only-date": true,
                              "min-date":
                                cauc.contract.acceptance_status_updated_at,
                              "max-date": _vm.$now(),
                            },
                            model: {
                              value: cauc.required.signature_date,
                              callback: function ($$v) {
                                _vm.$set(cauc.required, "signature_date", $$v)
                              },
                              expression: "cauc.required.signature_date",
                            },
                          }),
                          cauc.hasDateError
                            ? _c("p", { staticClass: "atmo-error" }, [
                                _vm._v("Поле обязательно для заполнения"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("editable-file-attachments-list-controller", {
                  attrs: { isrequired: true, maxfilesallowed: 1 },
                  on: { "fileattachment:changed": cauc.handleAnnexAttachment },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (efalc) {
                          return [
                            _c(
                              "EditableFileAttachmentsList.view",
                              { attrs: { controller: efalc } },
                              [
                                _c("span", [
                                  _c("h5", [
                                    _vm._v(
                                      "Добавьте дополнительное соглашение"
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm.$props.mode === "create"
                  ? _c("ul", { staticClass: "atmo-buttons" }, [
                      _c("li", { attrs: { disabled: cauc.hasErrors } }, [
                        _c(
                          "button",
                          {
                            staticClass: "atmo-button",
                            on: { click: cauc.updateAnnex },
                          },
                          [
                            _vm._v("Отправить дополнительное соглашение"),
                            _vm.$props.pivc.isUserEngagedCustomer
                              ? _c("span", [_vm._v(" поставщику")])
                              : _vm._e(),
                          ]
                        ),
                        _c("div", { staticClass: "atmo-spinner" }, [
                          _c("img", {
                            attrs: {
                              src: require("@themes/atmo/assets/images/loaders/spinner-sector.svg"),
                              alt: "",
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }