'use strict';

import DownloadConfig, { Body } from '../DownloadConfig.valueobject';

export default class Purchaseinfo extends DownloadConfig {
    private static _type = 'purchaseinfo';

    /**
     * REFACTOR: With time the strategies migrate towards the unification and normalization
     * of the API call (POSTs become GETs).
     * So possibly there will be a need to refactor from strategy pattern to a simpler solution
     * as soon as the unification reaches the desired level.
     */
    constructor(id: number, purchaseRegistrationNumber: string) {
        super(Purchaseinfo._type);
        this.verb = 'GET';
        this.type = Purchaseinfo._type;
        this.endpoint = `/purchases/${id}/order_info_pdf`;
        this.name = `Извещение ${purchaseRegistrationNumber}.pdf`;
    }
}
