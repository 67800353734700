import { CabinetModelApiService } from '@/services/backend/cabinet/base';
import axios from 'axios';

export class CabinetFl223SettingsApiService extends CabinetModelApiService {
    constructor() {
        super('fl223/settings');
    }

    async get() {
        try {
            const response = await axios.get(this.getUrl());
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async store(form) {
        try {
            await axios.post(this.getUrl(), form);
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
