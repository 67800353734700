var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { attrs: { lg: _vm.labelColsLg, sm: _vm.labelColsSm } },
    [
      _c(
        "b-form-group",
        {
          staticClass: "w-100",
          attrs: {
            description: _vm.description,
            label: _vm.label + ":",
            "label-for": _vm.id,
            state: _vm.state,
            "label-class": "fs-14 grey-color fw-700",
          },
        },
        [
          _c(
            "multiselect",
            {
              class: {
                "is-valid": _vm.state === true,
                "is-invalid": _vm.state === false,
              },
              attrs: {
                "allow-empty": _vm.allowEmpty,
                "clear-on-select": true,
                "close-on-select": true,
                disabled: _vm.disabled,
                label: _vm.labelField,
                multiple: false,
                options: _vm.options,
                placeholder: _vm.placeholder,
                "preserve-search": false,
                "show-labels": false,
                "track-by": _vm.trackBy,
              },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            },
            [
              _vm.allowEmpty
                ? _c("template", { slot: "clear" }, [
                    _vm.value
                      ? _c("div", {
                          staticClass: "multiselect__clear",
                          on: {
                            mousedown: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.clearAll()
                            },
                          },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
                _vm._v("Нет данных."),
              ]),
              _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v("Записей, соответствующих вашему запросу, не найдено."),
              ]),
            ],
            2
          ),
          !_vm.state
            ? [
                _c("b-form-invalid-feedback", { attrs: { "force-show": "" } }, [
                  _vm._v(_vm._s(_vm.errorMessage)),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }