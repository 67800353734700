<template>
    <b-form-group :id="id" :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" :class="compact ? 'mb-0' : ''">
        <a class="fs-14 col-form-label d-inline-block" style="color: var(--green-color) !important" :href="$getAttachmentDownloadLink(value.id)" target="_blank">{{ value.name }}</a>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-single-document',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: Object
        },
        description: {
            type: String,
            default: ''
        },
        compact: {
            type: Boolean,
            default: false
        },
        labelColsLg: {
            default: 3
        },
        labelColsSm: {
            default: 4
        }
    },
    computed: {
        id() {
            return 'text_row_single_document_' + this._uid;
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
