'use strict';

export default class OnOffComposable {
    private _ison: boolean;

    constructor() {
        this._ison = false;
    }

    public get ison(): boolean {
        return this._ison;
    }

    public toggle(): void {
        this._ison = !this._ison;
    }

    public on(): void {
        this._ison = true;
    }

    public off(): void {
        this._ison = false;
    }
}
