'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import isTypeOrNull from '@lib/js/src/misc/isTypeOrNull';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';

type TDropdownSelectItem = ExtendedDictionaryItem;

export type IDropdownSelectedEventPayload = {
    id: number | null;
    item: StandardDictionaryItem | ExtendedDictionaryItem;
};

@Component
export default class DropdownSelect extends BaseViewModel {
    input: HTMLInputElement | null;

    constructor() {
        super();
        this.name = 'DropdownSelect';
        this.input = null;
    }

    @Prop({
        required: true,
        validator: (prop) => {
            return isTypeOrNull(prop, 'Array');
        }
    })
    readonly list!: unknown[];
    @Prop({ type: Number, default: null }) readonly initialindex!: number;
    @Prop({ type: Number, default: null }) readonly initialid!: number;
    @Prop({ type: Number, default: null }) readonly currentid!: number;

    /**
     * Lifecycle hooks handlers
     */
    created() {
        if (this.$props.initialindex === null && this.$props.initialid === null) {
            return;
        }
        if (this.$props.initialindex) {
            const index = parseInt(this.$props.initialindex, 10);
            this.select(this.$props.list[index].id);
        }
        if (this.$props.initialid) {
            const id = parseInt(this.$props.initialid, 10);
            this.select(id);
        }
    }

    mounted() {
        this.input = this.$el.querySelector('input');
    }

    data() {
        return {
            selected: null
        };
    }

    /**
     * Computed
     */
    get text() {
        if (!this.$props.list) {
            return;
        }
        const item = this.$props.list.find((item: TDropdownSelectItem) => {
            /**
             * NB: parseInt is probably redundant here but to ensure the ID is
             * always going to be number I must normalize all IDs in all objects
             * supplied to the viewmodel. This is not going to be explicitly covered
             * by tests.
             *
             * Comment out the line is a bit of a risk of getting strange errors
             * and would require to find and fix these.
             */
            // return parseInt(item.id as unknown as string, 10) === this.$data.selected;
            return item.id === this.$data.selected;
        });
        return item.title;
    }

    get hasSelected() {
        return this.$data.selected !== null;
    }

    /**
     * Methods
     */
    public select(id: number) {
        this.$data.selected = parseInt(id as unknown as string, 10);
    }

    public clear() {
        this.$data.selected = null;
    }

    /**
     * Additional helper to transform numeric strings to int | null
     * for cases with non-v-model aproach as per here:
     * https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components;
     */
    public toNullableInt(value: string): number | null {
        return value.length > 0 ? parseInt(value, 10) : null;
    }

    /**
     * Get the desired property value from the dropdown list item by list item
     * (dictionary item) ID and the item property name.
     * Same use case as the above toNullableInt().
     */
    public getByIdFrom(id: string, propertyName: string): string | number | null {
        const found = this.$props.list.find((item: TDropdownSelectItem) => {
            return item.id === parseInt(id, 10);
        });
        return found ? found[propertyName] : null;
    }

    @Watch('selected')
    onSelectedChanged(newID: number | null) {
        const item = this.$props.list.find((item: TDropdownSelectItem) => {
            return item.id === newID;
        });
        const payload: IDropdownSelectedEventPayload = { id: newID, item: item };
        this.$emit('dropdown:select:selected', payload);
        this.updateInput(newID);
    }

    @Watch('currentid')
    onCurrentidChanged(newID: number | null) {
        this.$data.selected = newID;
    }

    /**
     * General prototype methods.
     */

    /**
     * When optional input is provided within the dropdown markup
     * with v-model, artificially trigger the input event when the
     * dropdown select value changes.
     */
    private updateInput(newID: number | null): void {
        if (!this.input) {
            return;
        }
        (this.input.value as any) = newID;
        const inputEvent = new Event('input', { bubbles: true });
        this.input.dispatchEvent(inputEvent);
    }
}
