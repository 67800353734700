var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("purchase-item-view-controller", {
    attrs: { group: this.$props.group, id: this.$props.id },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (pivc) {
          return [
            _c(
              "div",
              {
                staticClass: "atmo-hash-jump-point",
                attrs: { id: "atmo-top" },
              },
              [
                _c("FastAccessMenu.view", {
                  attrs: { pivc: pivc, purchaseitem: pivc.purchaseitem },
                }),
                _c(
                  "section",
                  { staticClass: "atmo-page-banner-wrapper atmo-slim" },
                  [
                    _c("div", { staticClass: "atmo-container-fixed" }, [
                      _c("h2", [
                        _c("span", [_vm._v("№ ")]),
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              pivc.purchaseitem.registration_number
                            ),
                          },
                        }),
                      ]),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "atmo-content-block-2-columns atmo-no-item-separator atmo-in-banner",
                        },
                        [
                          _c("li", [
                            _c("dt", [_vm._v("Статус закупки:")]),
                            _c("dd", {
                              staticClass:
                                "atmo-placeholder-animated atmo-inverse",
                              domProps: {
                                textContent: _vm._s(
                                  pivc.purchaseitem.status_title
                                ),
                              },
                            }),
                          ]),
                          _c("li", [
                            _c("dt", [_vm._v("Заказчик:")]),
                            _c("dd", {
                              staticClass:
                                "atmo-placeholder-animated atmo-inverse",
                              domProps: {
                                textContent: _vm._s(
                                  pivc.purchaseitem.customer.title
                                ),
                              },
                            }),
                          ]),
                          pivc.hasSupplier
                            ? _c("li", [
                                _c("dt", [_vm._v("Поставщик:")]),
                                _c("dd", {
                                  staticClass:
                                    "atmo-placeholder-animated atmo-inverse",
                                  domProps: {
                                    textContent: _vm._s(
                                      pivc.purchaseitem.supplier.title
                                    ),
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "atmo-container-fixed atmo-page-content" },
                  [
                    _c("CustomerInfo.view", {
                      attrs: { purchaseitem: pivc.purchaseitem },
                    }),
                    _c("PurchaseTerms.view", {
                      attrs: { pivc: pivc, purchaseitem: pivc.purchaseitem },
                    }),
                    !pivc.isPurchaseOutATMO
                      ? _c("SupplierRequirements.view", {
                          attrs: {
                            requirements:
                              pivc.purchaseitem.supplier_requirements,
                            purchase: pivc.purchaseitem,
                          },
                        })
                      : _vm._e(),
                    !pivc.isPurchaseOutATMO
                      ? _c("SupplyTerms.view", {
                          attrs: { purchaseitem: pivc.purchaseitem },
                        })
                      : _vm._e(),
                    _c(
                      "DeliverablesList.view",
                      {
                        staticClass: "atmo-hash-jump-point",
                        attrs: {
                          origin: "notice",
                          deliverables: pivc.purchaseitem.deliverables,
                          id: "atmo-notice-deliverables",
                        },
                      },
                      [
                        !pivc.isPurchaseOutATMO
                          ? _c("span", [_vm._v("извещения")])
                          : _vm._e(),
                      ]
                    ),
                    pivc.features.kbk_limits &&
                    pivc.isUserEngagedCustomer &&
                    !pivc.purchaseitem.without_limits
                      ? _c("KBKLimitsList.view", {
                          attrs: { limits: pivc.purchaseitem.kbklimits },
                        })
                      : _vm._e(),
                    _c(
                      "AccordionItem",
                      {
                        staticClass: "atmo-hash-jump-point",
                        attrs: {
                          visible: _vm.$route.hash === "#explanation-requests",
                          title: "Запросы разъяснений по закупке",
                          id: "explanation-requests",
                        },
                      },
                      [
                        _c("ExplanationRequestList", {
                          attrs: { "purchase-item": pivc.purchaseitem },
                        }),
                      ],
                      1
                    ),
                    _c("ProposalsList.view", {
                      attrs: {
                        pivc: pivc,
                        proposals: pivc.purchaseitem.proposals,
                        purchaseitem: pivc.purchaseitem,
                      },
                    }),
                    pivc.isUserEngagedCustomer && pivc.isPurchaseSummarizing
                      ? _c("ProtocolSummarizationWidget.view", {
                          attrs: {
                            proposals: pivc.purchaseitem.proposals,
                            purchaseitem: pivc.purchaseitem,
                          },
                        })
                      : _vm._e(),
                    pivc.isPurchaseSummarized ||
                    pivc.hasContract ||
                    pivc.hasPurchaseProtocol
                      ? _c("SupplierInfo.view", {
                          attrs: {
                            pivc: pivc,
                            purchaseitem: pivc.purchaseitem,
                            supplier: pivc.purchaseitem.supplier,
                          },
                        })
                      : _vm._e(),
                    (pivc.isPurchaseSummarized ||
                      pivc.hasContract ||
                      pivc.hasPurchaseProtocol) &&
                    !pivc.isPurchaseOutATMO
                      ? _c("PriceBreakdown.view", {
                          attrs: { pricebreakdown: pivc.pricebreakdown },
                        })
                      : _vm._e(),
                    pivc.isPurchaseSummarized && !pivc.hasContract
                      ? _c(
                          "DeliverablesListWithVat.view",
                          {
                            attrs: {
                              origin: "notice",
                              islimitvisible: pivc.isUserEngagedCustomer,
                              deliverables: pivc.acceptedProposal.deliverables,
                              "offer-cost": pivc.acceptedProposal.offer_cost,
                              "price-wo-vat":
                                pivc.acceptedProposal.price_wo_vat,
                            },
                          },
                          [
                            !pivc.isPurchaseOutATMO
                              ? _c("span", [_vm._v("принятого предложения")])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    pivc.hasContract
                      ? _c("ContractDetails.view", {
                          attrs: {
                            pivc: pivc,
                            purchaseitem: pivc.purchaseitem,
                          },
                        })
                      : _vm._e(),
                    pivc.hasContract && !pivc.isPurchaseOutATMO
                      ? _c(
                          "DeliverablesListWithVat.view",
                          {
                            attrs: {
                              origin: "contract",
                              islimitvisible: false,
                              deliverables:
                                pivc.purchaseitem.contract.deliverables,
                              "offer-cost":
                                pivc.purchaseitem.contract.offer_cost,
                              "price-wo-vat":
                                pivc.purchaseitem.contract.price_wo_vat,
                            },
                          },
                          [
                            !pivc.isPurchaseOutATMO
                              ? _c("span", [_vm._v("контракта")])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    pivc.features.kbk_limits &&
                    pivc.isUserEngagedCustomer &&
                    !pivc.purchaseitem.without_limits &&
                    pivc.hasContract &&
                    !pivc.isPurchaseOutATMO
                      ? _c("KBKLimitsList.view", {
                          attrs: {
                            limits: pivc.purchaseitem.contract.kbk_limits,
                          },
                        })
                      : _vm._e(),
                    !pivc.purchaseitem.without_limits &&
                    pivc.features.kbk_limits &&
                    pivc.hasContract &&
                    pivc.isUserEngagedCustomer &&
                    !pivc.isContractSignedByCustomer &&
                    pivc.isContractSignedBySupplier
                      ? _c("KBKLimitsEditableList.view", {
                          attrs: {
                            initiallist: pivc.purchaseitem.contract.kbk_limits,
                            totalamount:
                              pivc.purchaseitem.contract
                                .deliverables_total_amount,
                            limitfilterpresets: {
                              year: pivc.purchaseitem.limit_year,
                              purchase_type_id: pivc.purchaseitem.type_id,
                              purchase_id: pivc.purchaseitem.id,
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [_c("h5", [_vm._v("Измените лимиты")])]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    !pivc.purchaseitem.without_limits &&
                    pivc.features.kbk_limits &&
                    pivc.isContractActive &&
                    pivc.isUserEngagedCustomer &&
                    pivc.areContractLimitsEditable
                      ? _c("KBKLimitsAutonomousUpdateWidget.view", {
                          attrs: {
                            purchaseid: pivc.purchaseitem.id,
                            entity: pivc.purchaseitem.contract,
                            limitfilterpresets: {
                              year: pivc.purchaseitem.limit_year,
                              purchase_type_id: pivc.purchaseitem.type_id,
                              purchase_id: pivc.purchaseitem.id,
                            },
                          },
                        })
                      : _vm._e(),
                    pivc.isUserEngagedCustomer &&
                    pivc.isPurchaseSummarized &&
                    !pivc.hasContract &&
                    !pivc.isContractSignedByCustomer
                      ? _c("editable-file-attachments-list-controller", {
                          attrs: { isrequired: true, maxfilesallowed: 1 },
                          on: {
                            "fileattachment:changed":
                              pivc.handleContractAttachment,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (efalc) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "atmo-component-wrapper",
                                        attrs: { id: "atmo-contract" },
                                      },
                                      [
                                        _c(
                                          "EditableFileAttachmentsList.view",
                                          {
                                            attrs: { controller: efalc },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "controls",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "li",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !pivc.gotContractAttached,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "ProgressIndicatingButton.component",
                                                            {
                                                              attrs: {
                                                                clickhandler:
                                                                  pivc.storeContract,
                                                                text: "Отправить контракт",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _c("h3", [_vm._v("Контракт")]),
                                              _c(
                                                "div",
                                                { staticClass: "atmo-buttons" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "atmo-button atmo-accented",
                                                      attrs: {
                                                        href: "https://app.hintedme.ru/simulations/47863fd4-85ab-41ec-983a-803cec83b4c7/view",
                                                        rel: "noopener",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "atmo-icon-play",
                                                      }),
                                                      _vm._v(
                                                        " Инструкция по заключению договора и дополнительного соглашения"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    pivc.purchaseitem.contract.supplier_accounting &&
                    !pivc.purchaseitem.without_limits &&
                    pivc.features.kbk_limits &&
                    pivc.hasContract &&
                    pivc.isUserEngagedCustomer &&
                    pivc.isContractSignedByCustomer &&
                    pivc.isContractSignedBySupplier
                      ? _c(
                          "ContractSupplierAccountingInfo.view",
                          {
                            attrs: {
                              "supplier-accounting":
                                pivc.purchaseitem.contract.supplier_accounting,
                            },
                          },
                          [
                            _c("span", [
                              _vm._v("Банковские реквизиты поставщика"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    !pivc.purchaseitem.without_limits &&
                    pivc.features.kbk_limits &&
                    pivc.hasContract &&
                    pivc.isUserEngagedCustomer &&
                    !pivc.isContractSignedByCustomer &&
                    pivc.isContractSignedBySupplier
                      ? _c(
                          "ContractSupplierAccounting.view",
                          {
                            attrs: {
                              "initial-data":
                                pivc.purchaseitem.contract.supplier_accounting,
                            },
                            on: { select: pivc.supplierAccountingUpdated },
                          },
                          [
                            _c("span", [
                              _vm._v("Банковские реквизиты поставщика"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    pivc.hasContract
                      ? _c(
                          "ContractAttachments.view",
                          {
                            attrs: {
                              pivc: pivc,
                              signatures: pivc.purchaseitem.contract.signatures,
                              attachments: pivc.contractAttachments.main,
                            },
                            scopedSlots: _vm._u(
                              [
                                (pivc.mustSupplierSignContract ||
                                  pivc.mustCustomerignContract) &&
                                !pivc.isContractDisputed
                                  ? {
                                      key: "controls",
                                      fn: function () {
                                        return [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "atmo-button mr-2",
                                              attrs: {
                                                disabled:
                                                  (pivc.features.kbk_limits &&
                                                    pivc.hasContractErrors) ||
                                                  !_vm.$store.getters
                                                    .getCanSign,
                                              },
                                              on: {
                                                click:
                                                  pivc.invokeSignContractPopup,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Подписать и отправить контракт"
                                              ),
                                            ]
                                          ),
                                          !_vm.$store.getters.getCanSign
                                            ? [
                                                _vm.$store.getters
                                                  .getLoginType === "cert"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Для действия требуется загрузить/заменить МЧД на странице: "
                                                        ),
                                                        _c(
                                                          "router-link",
                                                          {
                                                            staticClass:
                                                              "green-link",
                                                            attrs: {
                                                              to: {
                                                                name: "UserProfile",
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Данные учетной записи"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Подписание возможно только при авторизации при помощи ЭЦП"
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Основной контракт")])]
                        )
                      : _vm._e(),
                    pivc.hasContract && pivc.isPurchaseOutATMO
                      ? _c(
                          "ContractAttachments.view",
                          {
                            attrs: {
                              pivc: pivc,
                              signatures: {},
                              attachments:
                                pivc.contractAttachments.confirmation,
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Подтверждающие документы (акт, накладная, УПД)"
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    pivc.hasContract && pivc.hasDisputes
                      ? _c("ContractDisputes.view", {
                          attrs: {
                            pivc: pivc,
                            disputes: pivc.purchaseitem.contract.disputes,
                          },
                        })
                      : _vm._e(),
                    ((pivc.isUserEngagedCustomer && pivc.isContractDisputed) ||
                      (pivc.isContractProposed &&
                        pivc.isUserEngagedSupplier &&
                        pivc.canSupplierSubmitDisputes)) &&
                    pivc.isPurchaseSummarized
                      ? _c("ContractDisputesResolutionWidget.view", {
                          attrs: { pivc: pivc },
                        })
                      : _vm._e(),
                    pivc.hasContract && pivc.hasContractAnnexes
                      ? _c("ContractAnnexesList.view", {
                          attrs: {
                            pivc: pivc,
                            annexes: pivc.purchaseitem.contract.annexes,
                          },
                        })
                      : _vm._e(),
                    (pivc.isUserEngagedCustomer ||
                      pivc.isUserEngagedSupplier) &&
                    pivc.isContractSignedByBoth &&
                    pivc.isContractActive
                      ? _c("ContractAnnexesUpdateWidget.view", {
                          attrs: { pivc: pivc },
                        })
                      : _vm._e(),
                    !pivc.isUserEngagedCustomer && pivc.hasContract
                      ? _c("ContractFulfillment.view", {
                          attrs: {
                            pivc: pivc,
                            attachments:
                              pivc.purchaseitem.contract
                                .fulfillment_attachments,
                          },
                        })
                      : _vm._e(),
                    pivc.isUserEngagedCustomer &&
                    pivc.hasContract &&
                    pivc.isContractSignedByBoth
                      ? _c(
                          "editable-contract-fulfillment-file-attachments-list-controller",
                          {
                            attrs: {
                              contractid: pivc.purchaseitem.contract.id,
                              initiallist:
                                pivc.purchaseitem.contract
                                  .fulfillment_attachments,
                              origin: "fulfillment",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (efalc) {
                                    return [
                                      _c(
                                        "EditableFileAttachmentsList.view",
                                        { attrs: { controller: efalc } },
                                        [
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "atmo-hash-jump-point atmo-content-group",
                                                attrs: {
                                                  id: "atmo-contract-fulfillment",
                                                },
                                              },
                                              [_vm._v("Исполнение контракта")]
                                            ),
                                            pivc.hasContract &&
                                            pivc.isUserEngagedCustomer &&
                                            pivc.isContractSignedByBoth
                                              ? _c(
                                                  "PurchaseStatusUpdateWidget.view",
                                                  {
                                                    attrs: {
                                                      contractid:
                                                        pivc.purchaseitem
                                                          .contract.id,
                                                      contractstatuses:
                                                        pivc.contractStatuses,
                                                      status: pivc.status,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _c("h5", [
                                              _vm._v("Документы об исполнении"),
                                            ]),
                                          ],
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }
                        )
                      : _vm._e(),
                    !pivc.isPurchaseOutATMO
                      ? _c("ul", { staticClass: "atmo-buttons" }, [
                          _c(
                            "li",
                            [
                              _c("ProgressIndicatingButton.component", {
                                attrs: {
                                  type: "dimmed",
                                  clickhandler: pivc.downloadPurchaseInfo,
                                  text: "Скачать извещение",
                                },
                              }),
                            ],
                            1
                          ),
                          pivc.isPurchaseSummarized ||
                          pivc.hasContract ||
                          pivc.hasPurchaseProtocol
                            ? _c(
                                "li",
                                [
                                  _c("ProgressIndicatingButton.component", {
                                    attrs: {
                                      type: "dimmed",
                                      clickhandler: pivc.downloadProtocol,
                                      text: "Скачать итоговый протокол",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          pivc.hasContract
                            ? _c(
                                "li",
                                [
                                  _c("ProgressIndicatingButton.component", {
                                    attrs: {
                                      type: "dimmed",
                                      clickhandler: pivc.downloadContract,
                                      text: "Скачать контракт",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          pivc.isPurchaseAcceptingProposals &&
                          pivc.isSupplier &&
                          !pivc.isPurchaseSummarizing
                            ? _c("li", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "atmo-button",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return pivc.submitOrUpdateProposal.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    !pivc.purchaseitem
                                      .has_proposal_from_current_supplier
                                      ? _c("span", [_vm._v("Подать")])
                                      : _c("span", [_vm._v("Изменить")]),
                                    _vm._v(" предложение "),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c("div", {
                      staticClass: "atmo-hash-jump-point",
                      attrs: { id: "atmo-bottom" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }