'use strict';

import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import Config from '@core/js/ddriven/application/config/Config';
import DateCoundown, { ICountdownResult, ICountdownResultFormatted } from '@lib/js/src/misc/DateCountdown';
import NoticeItemVO from '@core/js/ddriven/domain/model/notices/view/list/NoticeItem.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import StatusDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StatusDictionaryItem.valueobject';

interface IData {
    countdown: null | string;
}

@Component
export default class NoticeItem extends BaseViewModel {
    constructor() {
        super();
        this.name = 'NoticeItem';
    }

    @Prop({ required: true, type: Object }) readonly elpc!: Vue;
    @Prop({ required: true, type: Object }) readonly noticeitem!: NoticeItemVO;

    mounted() {
        this.hasCountdown && this.initializeCountdown(this);
    }

    data(): IData {
        return {
            countdown: null
        };
    }

    /**
     * Computed
     */
    get status() {
        return this.getStatus.call(this);
    }

    get hasSupplier() {
        return !!this.$props.noticeitem && !!this.$props.noticeitem.supplier && this.$props.noticeitem.supplier.id !== null;
    }

    get hasDeliveryAddresses() {
        return this.$props.noticeitem?.delivery_addresses?.length > 0;
    }

    get hasAllSummaryItemsVisible() {
        if (this.$props.noticeitem.product?.id === 'commercial' || this.$props.noticeitem.product?.id === 'commercial') {
            return ['cancelled', 'failed', 'accepting'].indexOf(this.status.id) === -1;
        } else {
            return ['cancelled', 'failed'].indexOf(this.status.id) === -1;
        }
    }

    get hasContract() {
        return !!this.$props.noticeitem.contract;
    }

    get hasProtocol() {
        return this.$props.noticeitem.protocol_date !== null;
    }

    get hasCountdown() {
        return this.status.code === 'accepting';
    }

    get user() {
        return this.$store.getters['rearchitected/users/user'];
    }

    get buttondata() {
        return {
            isActive: this.$props.noticeitem.active_deal_stage !== null,
            purchaseId: this.$props.noticeitem.id,
            hash: this.$props.noticeitem.active_deal_stage ? this.$props.noticeitem.active_deal_stage.hash : null,
            buttonText: this.$props.noticeitem.active_deal_stage ? this.$props.noticeitem.active_deal_stage.title : null
        };
    }

    /**
     * Methods
     */
    getStatusClass(classPrefix: string) {
        return `${classPrefix}${this.status.code}`;
    }

    submitOrUpdateProposal() {
        ApplicationServiceLocator.get('supplier').checkBeforeProposal(this.$props.noticeitem.id);
    }

    /**
     * Prototype general methods.
     */
    getStatus() {
        return this.$props.elpc.dictionaries.statuses.find((dictionaryStatus: StatusDictionaryItem) => {
            return this.$props.noticeitem.status.id === dictionaryStatus.id;
        });
    }

    initializeCountdown(vm: Vue) {
        new DateCoundown(vm.$props.noticeitem.proposal_accept_end_date, Config.get('timezone.customer') as string, (countdown: ICountdownResult, formatted: ICountdownResultFormatted) => {
            const countdownString = countdown.days > 0 ? `${formatted.days} ${formatted.dayWord} ` : '';
            vm.$data.countdown = countdown.done ? false : `${countdownString}${formatted.hours}:${formatted.minutes}:${formatted.seconds}`;
        });
    }
}
