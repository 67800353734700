<template>
    <div>
        <b-modal ref="select-cert-modal" v-model="showModal" size="lg" hide-header centered hide-header-close no-close-on-backdrop no-close-on-esc @show="onShow">
            <div class="d-block">
                <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
                    <form-row-edit-textarea :label="'Напишите текст ' + titleText + ' разъяснений по закупке'" v-model="form.text" :v="$v.form.text" :label-on-top="true" :rows="5" />
                    <form-row-single-line-document-upload v-model="form.document" title="Загрузка файла" :v="$v.form.document" label-cols-lg="2" class="mb-0" />
                </b-overlay>
                <b-form-invalid-feedback :state="state">{{ errorMessage }}</b-form-invalid-feedback>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-overlay :show="isLoading" spinner-small rounded opacity="0.6" spinner-variant="primary">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="onCancel">Отмена</b-button>
                    <b-button :disabled="!state" class="text-uppercase" variant="custom-green" @click="onSave">Отправить</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';

import { helpers, required } from 'vuelidate/lib/validators';

const backError = (param) => helpers.withParams({ type: 'backError', value: param }, (value) => !helpers.req(value) || param === false);

export default {
    name: 'explanation-request-add-modal',
    props: {
        isRequest: {
            required: true,
            type: Boolean
        },
        showModal: {
            required: true,
            type: Boolean
        },
        requestId: {}
    },
    data() {
        return {
            form: {
                text: '',
                document: null
            },
            isLoading: false,
            backError: false,
            backErrorText: '',
            titleText: ''
        };
    },
    validations() {
        return {
            form: {
                text: { required },
                document: {}
            },
            backError: backError(this.backError)
        };
    },
    methods: {
        async onSave() {
            this.isLoading = true;
            if (this.isRequest) {
                await axios
                    .post('/purchases/' + this.$route.params.id + '/explanation_requests', this.form)
                    .then((response) => {
                        this.$emit('done', response.data);
                    })
                    .catch((error) => {
                        this.backError = true;
                        this.backErrorText = error.response.data.message;
                    });
            } else {
                const data = {
                    request_id: this.requestId,
                    ...this.form
                };
                await axios
                    .put('/purchases/' + this.$route.params.id + '/explanation_requests', data)
                    .then((response) => {
                        this.$emit('done', response.data);
                    })
                    .catch((error) => {
                        this.backError = true;
                        this.backErrorText = error.response.data.message;
                    });
            }
            this.isLoading = false;
        },
        onShow() {
            this.form.text = '';
            this.form.document = null;
            this.isLoading = false;
            this.backError = false;
            this.backErrorText = '';
            this.titleText = this.isRequest ? 'запроса' : 'ответа';
        },
        onCancel() {
            this.$emit('close');
        }
    },
    computed: {
        state() {
            return this.$v !== null ? !this.$v.$invalid : null;
        },
        errorMessage() {
            if (this.$v) {
                if (!this.$v.backError) return this.backErrorText;
            }
            return '';
        }
    }
};
</script>
