<template>
    <contract-annex-update-controller v-slot:default="cauc" v-bind:mode="this.$props.mode" v-bind:contract="this.$props.pivc.purchaseitem.contract" v-on:annexitemeditable:changed="this.$props.disputecontroller.onAnnexItemEditableChange">
        <div class="atmo-component-wrapper">
            <AnnexEditableDeliverablesList.view v-bind:controller="cauc" v-bind:deliverables="cauc.deliverables" />

            <p v-if="cauc.errors.quantity" class="atmo-error">Максимальное увеличение или снижение количества может составлять не более 10%.</p>
            <p v-if="cauc.errors.price_per_unit" class="atmo-error">Цена единицы ТРУ в спецификации не может быть больше указанной в контракте.</p>
            <p v-if="cauc.errors.price_total" class="atmo-error">Превышена допустимая общая стоимость дополнительного соглашения. Максимальное увеличение может составлять не более 10%.</p>
            <p v-if="cauc.errors.kbk_limit" class="atmo-error">Сумма стоимостей по одной или нескольким позициям спецификации превышает доступный лимит по КБК.</p>

            <ul class="atmo-content-block-2-columns atmo-no-item-separator">
                <li>
                    <dt>Общая стоимость дополнительного соглашения, руб.:</dt>
                    <dd>{{ cauc.price_total_annex | formatnumber }}</dd>
                </li>
                <li>
                    <dt>Общая стоимость контракта, руб.:</dt>
                    <dd>{{ cauc.price_total_contract | formatnumber }}</dd>
                </li>
                <li>
                    <dt>Дата заключения дополнительного соглашения:</dt>
                    <dd v-bind:class="{ 'atmo-has-errors': cauc.hasDateError }" class="atmo-form__field atmo-as-input">
                        <date-picker
                            v-model="cauc.required.signature_date"
                            id="purchase-annexSignatureDate"
                            position="bottom"
                            label="Выберите дату"
                            button-now-translation="Сегодня"
                            format="YYYY-MM-DD"
                            formatted="DD.MM.YYYY"
                            v-bind:no-label="true"
                            v-bind:auto-close="true"
                            v-bind:only-date="true"
                            v-bind:min-date="cauc.contract.acceptance_status_updated_at"
                            v-bind:max-date="$now()">
                        </date-picker>

                        <p v-if="cauc.hasDateError" class="atmo-error">Поле обязательно для заполнения</p>
                    </dd>
                </li>
            </ul>

            <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:isrequired="true" v-bind:maxfilesallowed="1" v-on:fileattachment:changed="cauc.handleAnnexAttachment">
                <EditableFileAttachmentsList.view v-bind:controller="efalc">
                    <span><h5>Добавьте дополнительное соглашение</h5></span>
                </EditableFileAttachmentsList.view>
            </editable-file-attachments-list-controller>

            <ul v-if="$props.mode === 'create'" class="atmo-buttons">
                <li v-bind:disabled="cauc.hasErrors">
                    <button v-on:click="cauc.updateAnnex" class="atmo-button">Отправить дополнительное соглашение<span v-if="$props.pivc.isUserEngagedCustomer"> поставщику</span></button>
                    <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
                </li>
            </ul>
        </div>
    </contract-annex-update-controller>
</template>

<script>
import ContractAnnexUpdateController from '@core/js/viewmodels/purchases/item/view/widgets/contract/annexes/ContractAnnexUpdateController.viewmodel';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';

import AnnexEditableDeliverablesListView from './AnnexEditableDeliverablesList.view.vue';
import EditableFileAttachmentsListView from 'themes/views/pages/purchases/item/common/partials/EditableFileAttachmentsList.view.vue';

export default {
    props: {
        mode: { required: true, type: String },
        pivc: { required: true, type: Object },
        disputecontroller: { required: true, type: Object }
    },
    components: {
        'contract-annex-update-controller': ContractAnnexUpdateController,
        'date-picker': VueCtkDateTimePicker,
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController,

        'AnnexEditableDeliverablesList.view': AnnexEditableDeliverablesListView,
        'EditableFileAttachmentsList.view': EditableFileAttachmentsListView
    }
};
</script>
