var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "atmo-tab-content" }, [
    _c("section", { staticClass: "atmo-content-group" }, [
      _c("h6", [_vm._v("Документ")]),
      _c(
        "ul",
        { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
        [
          _c("li", [
            _c("dt", [_vm._v("Приложенные документы:")]),
            _c("dd", [
              _c("button", {
                staticClass: "atmo-button-link",
                domProps: { textContent: _vm._s(_vm.$props.attachment.name) },
                on: {
                  click: function ($event) {
                    return _vm.$props.controller.downloadFileAttachment(
                      _vm.$props.attachment
                    )
                  },
                },
              }),
            ]),
          ]),
          _c("li", [
            _c("dt", [_vm._v("Комментарий к протоколу разногласий:")]),
            _c("dd", {
              domProps: { textContent: _vm._s(this.$props.comment) },
            }),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }