'use strict';

import { IContractsState } from './state';
import { IContractRequest } from '@core/js/ddriven/application/http/requests/contracts/ContractRequest.valueobject';
import ContractItem, { IContractRawDataItem } from '@core/js/ddriven/domain/model/contracts/ContractItem.valueobject';
import StandardPagination, { IStandardPaginationRawData } from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';

const mutations = {
    setList: function (state: IContractsState, contractsRawDataList: IContractRawDataItem[]): void {
        state.list = contractsRawDataList.map((contractItemData: IContractRawDataItem) => {
            return Object.freeze(new ContractItem(contractItemData as unknown as IContractRawDataItem));
        });
    },
    setPagination: function (state: IContractsState, paginationData: IStandardPaginationRawData): void {
        state.pagination = new StandardPagination(paginationData);
    },
    updateRequest: function (state: IContractsState, payload: undefined): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.request[payload.key] = payload.value;
    },
    routereset: function (state: IContractsState, newState: boolean): void {
        state.routereset = newState;
    },
    setRequest: function (state: IContractsState, request: IContractRequest): void {
        state.request = request;
    }
};

export default mutations;
