<template>
    <div id="atmo-price-breakdown" class="atmo-hash-jump-point atmo-content-group">
        <h3>Стоимость</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li class="mb-2 pb-2">
                <dt>Начальная цена контракта, руб.:</dt>
                <dd>{{ this.$formatPrice(this.$props.pricebreakdown.starting_price) }}</dd>
            </li>
            <li class="mb-2 pb-2">
                <dt>Финальная цена контракта (сделки), руб.:</dt>
                <dd>{{ this.$formatPrice(this.$props.pricebreakdown.final_price) }}</dd>
            </li>
            <li class="mb-2 pb-2">
                <dt>Размер скидки, руб. / %:</dt>
                <dd>
                    <span>{{ this.$formatPrice(this.$props.pricebreakdown.discount) }}</span>
                    &nbsp;/&nbsp;
                    <span>{{ this.$formatPrice(roundToTwoDecimals(this.$props.pricebreakdown.discount_percent)) }}%</span>
                </dd>
            </li>
        </ul>
    </div>
</template>

<script>
import roundToTwoDecimals from '../../../../../../../../lib/js/src/misc/roundToTwoDecimals';

export default {
    methods: { roundToTwoDecimals },
    props: {
        pricebreakdown: { required: true, type: Object }
    }
};
</script>
