var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data
    ? _c(
        "section",
        {
          staticClass: "atmo-component-wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("ul", { staticClass: "atmo-popup-block-2-columns" }, [
            _c("li", [
              _c("dt", [
                this.$props.data.userType() === "customer"
                  ? _c("span", [_vm._v("Заказчик:")])
                  : _vm._e(),
                this.$props.data.userType() === "supplier"
                  ? _c("span", [_vm._v("Поставщик:")])
                  : _vm._e(),
              ]),
              _c("dd", {
                domProps: {
                  textContent: _vm._s(this.$props.data.organization_name),
                },
              }),
            ]),
            this.$props.data.username
              ? _c("li", [
                  _c("dt", [_vm._v("ФИО:")]),
                  _c("dd", [_vm._v(_vm._s(this.$props.data.username))]),
                ])
              : _vm._e(),
            this.$props.data.poa_check_link
              ? _c("li", [
                  _c("dt", [_vm._v("МЧД:")]),
                  _c("dd", [
                    _c(
                      "a",
                      {
                        staticClass: "green-link",
                        attrs: {
                          href: this.$props.data.poa_check_link,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(this.$props.data.poa_number))]
                    ),
                  ]),
                ])
              : _vm._e(),
            this.$props.data.sign_datetime
              ? _c("li", [
                  _c("dt", [_vm._v("Дата и время:")]),
                  _c("dd", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatdate")(
                          this.$props.data.sign_datetime,
                          "withSeconds"
                        )
                      )
                    ),
                  ]),
                ])
              : _c("li", [
                  _c("span", [_vm._v("Договор ожидает подписи ")]),
                  this.$props.data.userType() === "customer"
                    ? _c("span", [_vm._v("Заказчика")])
                    : _vm._e(),
                  this.$props.data.userType() === "supplier"
                    ? _c("span", [_vm._v("Поставщика")])
                    : _vm._e(),
                ]),
          ]),
          this.$props.data.md5
            ? _c("div", { staticClass: "atmo-signature" }, [
                _c("dd", [_c("pre", [_vm._v(_vm._s(this.$props.data.md5))])]),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }