var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": "4",
        "label-cols-lg": "3",
        "label-for": _vm.id,
        state: _vm.state,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function () {
            return [
              _vm._v(" " + _vm._s(_vm.label) + ": "),
              _vm.hint
                ? _c("div", { staticClass: "atmo-help" }, [
                    _vm._v("  "),
                    _c("i", { staticClass: "atmo-icon-question-mark" }),
                    _c("span", { staticClass: "atmo-help__text" }, [
                      _vm._v(_vm._s(_vm.hint)),
                    ]),
                  ])
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { class: { "is-data-invalid": _vm.state === false } },
        [
          _c("b-table", {
            staticClass: "table-atmo mb-0",
            attrs: {
              fields: _vm.fields,
              items: _vm.model,
              busy: _vm.isLoading,
              "empty-text": "Не добавлено адресов",
              "show-empty": "",
            },
            scopedSlots: _vm._u([
              {
                key: "head(actions)",
                fn: function () {
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Добавить адрес",
                            expression: "'Добавить адрес'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "d-inline-block",
                        attrs: { tabindex: "0" },
                      },
                      [
                        _vm.addresses.length !== 0
                          ? [
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    disabled:
                                      !!_vm.maxLength &&
                                      _vm.model.length >= _vm.maxLength,
                                    right: "",
                                    "toggle-class": "atmo-button-icon",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("i", {
                                              staticClass:
                                                "atmo-icon-add-to-list",
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    939538769
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "fs-14",
                                      on: { click: _vm.showAddressBookSelect },
                                    },
                                    [_vm._v("Из адресной книги")]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "fs-14",
                                      on: { click: _vm.showFiasSelect },
                                    },
                                    [_vm._v("Ввести вручную")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "b-button",
                                {
                                  staticClass: "atmo-button-icon",
                                  attrs: {
                                    disabled:
                                      !!_vm.maxLength &&
                                      _vm.model.length >= _vm.maxLength,
                                  },
                                  on: { click: _vm.showFiasSelect },
                                },
                                [
                                  _c("i", {
                                    staticClass: "atmo-icon-add-to-list",
                                  }),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(index)",
                fn: function (row) {
                  return [_vm._v(" " + _vm._s(row.index + 1) + ". ")]
                },
              },
              {
                key: "cell(actions)",
                fn: function (row) {
                  return [
                    _c("action-button-small", {
                      attrs: { icon: "trash" },
                      on: {
                        click: function ($event) {
                          return _vm.removeAddress(row.index)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "empty",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "text-center w-100" }, [
                      _vm._v(_vm._s(scope.emptyText)),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.state
        ? [
            _c("b-form-invalid-feedback", { attrs: { state: _vm.state } }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ]),
          ]
        : _vm._e(),
      _vm.description
        ? [
            _c("b-form-text", { staticClass: "desc-text" }, [
              _vm._v(" " + _vm._s(_vm.description) + " "),
            ]),
          ]
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: { title: "Адрес", "hide-footer": "", centered: "" },
          model: {
            value: _vm.form.showAddress,
            callback: function ($$v) {
              _vm.$set(_vm.form, "showAddress", $$v)
            },
            expression: "form.showAddress",
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "w-100",
                      attrs: { state: !_vm.$v.form.$invalid },
                    },
                    [
                      _c(
                        "multiselect",
                        {
                          class: {
                            "is-valid": !_vm.$v.form.$invalid,
                            "is-invalid": _vm.$v.form.$invalid,
                          },
                          attrs: {
                            placeholder: "Выберите адрес",
                            "deselect-label": "Выбрано",
                            "selected-label": "Выбрано",
                            "select-label": "Выбрать",
                            "track-by": "short_address",
                            label: "short_address",
                            options: _vm.addresses,
                            searchable: true,
                            "allow-empty": false,
                          },
                          model: {
                            value: _vm.form.select_address,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "select_address", $$v)
                            },
                            expression: "form.select_address",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noOptions" }, slot: "noOptions" },
                            [_vm._v("Нет данных.")]
                          ),
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [
                              _vm._v(
                                "Записей, соответствующих вашему запросу, не найдено."
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm.$v.form.$invalid
                        ? [
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { "force-show": "" } },
                              [_vm._v("Адрес не выбран")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "py-2" },
            [
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled: _vm.$v.form.$invalid,
                        variant: "custom-green",
                      },
                      on: { click: _vm.addBookAddress },
                    },
                    [_vm._v("Добавить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Адрес",
            size: "lg",
            "hide-footer": "",
            centered: "",
          },
          model: {
            value: _vm.form.showFias,
            callback: function ($$v) {
              _vm.$set(_vm.form, "showFias", $$v)
            },
            expression: "form.showFias",
          },
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.isLoading,
                rounded: "",
                opacity: "0.6",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("form-row-edit-address", {
                        attrs: {
                          value: _vm.form.address,
                          v: _vm.$v.form.address,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "address", $event)
                          },
                        },
                      }),
                      _c("form-row-edit-checkbox", {
                        attrs: { label: "Добавить в адресную книгу" },
                        model: {
                          value: _vm.form.add_flag,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "add_flag", $$v)
                          },
                          expression: "form.add_flag",
                        },
                      }),
                      _vm.form.add_flag
                        ? [
                            _c("form-row-edit-text", {
                              attrs: {
                                v: _vm.$v.form.short_address,
                                label: "Короткое название",
                                "max-length": 50,
                              },
                              model: {
                                value: _vm.form.short_address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "short_address", $$v)
                                },
                                expression: "form.short_address",
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "py-2 mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled: _vm.$v.form.$invalid,
                            variant: "custom-green",
                          },
                          on: { click: _vm.addFiasAddress },
                        },
                        [_vm._v("Добавить")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }