var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-content-group" }, [
    _c("h3", [_vm._v("Требования к поставщикам")]),
    _c(
      "ul",
      { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
      [
        _c(
          "li",
          { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
          [
            _vm._m(0),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$props.inputs.supplier_requirements.is_only_smb,
                    expression:
                      "$props.inputs.supplier_requirements.is_only_smb",
                  },
                ],
                staticClass: "atmo-icon-checkmark",
                attrs: {
                  type: "checkbox",
                  name: "only-small-business",
                  id: "only-small-business",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.$props.inputs.supplier_requirements.is_only_smb
                  )
                    ? _vm._i(
                        _vm.$props.inputs.supplier_requirements.is_only_smb,
                        null
                      ) > -1
                    : _vm.$props.inputs.supplier_requirements.is_only_smb,
                },
                on: {
                  change: function ($event) {
                    var $$a =
                        _vm.$props.inputs.supplier_requirements.is_only_smb,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "is_only_smb",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "is_only_smb",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.$props.inputs.supplier_requirements,
                        "is_only_smb",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "li",
          { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
          [
            _vm._m(1),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$props.inputs.supplier_requirements.rating,
                    expression: "$props.inputs.supplier_requirements.rating",
                  },
                ],
                staticClass: "atmo-icon-checkmark",
                attrs: { type: "checkbox", name: "rating", id: "rating" },
                domProps: {
                  checked: Array.isArray(
                    _vm.$props.inputs.supplier_requirements.rating
                  )
                    ? _vm._i(
                        _vm.$props.inputs.supplier_requirements.rating,
                        null
                      ) > -1
                    : _vm.$props.inputs.supplier_requirements.rating,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.$props.inputs.supplier_requirements.rating,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "rating",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "rating",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.$props.inputs.supplier_requirements,
                        "rating",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "li",
          { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
          [
            _vm._m(2),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$props.inputs.supplier_requirements.a31s1p1,
                    expression: "$props.inputs.supplier_requirements.a31s1p1",
                  },
                ],
                staticClass: "atmo-icon-checkmark",
                attrs: {
                  type: "checkbox",
                  name: "federal-law-a31s1p1",
                  id: "federal-law-a31s1p1",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.$props.inputs.supplier_requirements.a31s1p1
                  )
                    ? _vm._i(
                        _vm.$props.inputs.supplier_requirements.a31s1p1,
                        null
                      ) > -1
                    : _vm.$props.inputs.supplier_requirements.a31s1p1,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.$props.inputs.supplier_requirements.a31s1p1,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "a31s1p1",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "a31s1p1",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.$props.inputs.supplier_requirements,
                        "a31s1p1",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$props.inputs.supplier_requirements.a31s1p1,
                expression: "$props.inputs.supplier_requirements.a31s1p1",
              },
            ],
            staticClass: "atmo-nested-section",
          },
          [
            _c("SupplierRequirementDetailsA31s1p1.view", {
              attrs: {
                requirementdetails:
                  _vm.$props.inputs.supplier_requirements.a31s1p1_details,
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
          [
            _vm._m(3),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.$props.inputs.supplier_requirements.a31s1ps3_5ps7_11,
                    expression:
                      "$props.inputs.supplier_requirements.a31s1ps3_5ps7_11",
                  },
                ],
                staticClass: "atmo-icon-checkmark",
                attrs: {
                  disabled: "",
                  type: "checkbox",
                  name: "federal-law-a31s1ps3_5ps7_11",
                  id: "federal-law-a31s1ps3_5ps7_11",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.$props.inputs.supplier_requirements.a31s1ps3_5ps7_11
                  )
                    ? _vm._i(
                        _vm.$props.inputs.supplier_requirements
                          .a31s1ps3_5ps7_11,
                        null
                      ) > -1
                    : _vm.$props.inputs.supplier_requirements.a31s1ps3_5ps7_11,
                },
                on: {
                  change: function ($event) {
                    var $$a =
                        _vm.$props.inputs.supplier_requirements
                          .a31s1ps3_5ps7_11,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "a31s1ps3_5ps7_11",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "a31s1ps3_5ps7_11",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.$props.inputs.supplier_requirements,
                        "a31s1ps3_5ps7_11",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "li",
          { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
          [
            _vm._m(4),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$props.inputs.supplier_requirements.a31s1_1,
                    expression: "$props.inputs.supplier_requirements.a31s1_1",
                  },
                ],
                staticClass: "atmo-icon-checkmark",
                attrs: {
                  type: "checkbox",
                  name: "federal-law-a31s1_1",
                  id: "federal-law-a31s1_1",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.$props.inputs.supplier_requirements.a31s1_1
                  )
                    ? _vm._i(
                        _vm.$props.inputs.supplier_requirements.a31s1_1,
                        null
                      ) > -1
                    : _vm.$props.inputs.supplier_requirements.a31s1_1,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.$props.inputs.supplier_requirements.a31s1_1,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "a31s1_1",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "a31s1_1",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.$props.inputs.supplier_requirements,
                        "a31s1_1",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "li",
          { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
          [
            _vm._m(5),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.$props.inputs.supplier_requirements
                        .supplier_reqs_sonko,
                    expression:
                      "$props.inputs.supplier_requirements.supplier_reqs_sonko",
                  },
                ],
                staticClass: "atmo-icon-checkmark",
                attrs: {
                  type: "checkbox",
                  name: "requirement-sonko",
                  id: "requirement-sonko",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.$props.inputs.supplier_requirements.supplier_reqs_sonko
                  )
                    ? _vm._i(
                        _vm.$props.inputs.supplier_requirements
                          .supplier_reqs_sonko,
                        null
                      ) > -1
                    : _vm.$props.inputs.supplier_requirements
                        .supplier_reqs_sonko,
                },
                on: {
                  change: function ($event) {
                    var $$a =
                        _vm.$props.inputs.supplier_requirements
                          .supplier_reqs_sonko,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "supplier_reqs_sonko",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements,
                            "supplier_reqs_sonko",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.$props.inputs.supplier_requirements,
                        "supplier_reqs_sonko",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "li",
          { staticClass: "atmo-form__field atmo-checkbox atmo-wide-text" },
          [
            _vm._m(6),
            _c("dd", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.$props.inputs.supplier_requirements.additional.state
                        .is_required,
                    expression:
                      "$props.inputs.supplier_requirements.additional.state.is_required",
                  },
                ],
                staticClass: "atmo-icon-checkmark",
                attrs: {
                  type: "checkbox",
                  name: "requirement-sonko",
                  id: "requirement-sonko",
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.$props.inputs.supplier_requirements.additional.state
                      .is_required
                  )
                    ? _vm._i(
                        _vm.$props.inputs.supplier_requirements.additional.state
                          .is_required,
                        null
                      ) > -1
                    : _vm.$props.inputs.supplier_requirements.additional.state
                        .is_required,
                },
                on: {
                  change: function ($event) {
                    var $$a =
                        _vm.$props.inputs.supplier_requirements.additional.state
                          .is_required,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements.additional
                              .state,
                            "is_required",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$props.inputs.supplier_requirements.additional
                              .state,
                            "is_required",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.$props.inputs.supplier_requirements.additional
                          .state,
                        "is_required",
                        $$c
                      )
                    }
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.$props.inputs.supplier_requirements.additional.state
                    .is_required,
                expression:
                  "$props.inputs.supplier_requirements.additional.state.is_required",
              },
            ],
            staticClass: "atmo-nested-section",
          },
          [
            _c("AdditionalRequirement.view", {
              attrs: {
                additional: _vm.$props.inputs.supplier_requirements.additional,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "only-small-business" } }, [
        _vm._v(
          "Участником закупки могут быть только субъекты малого предпринимательства (СМП):"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "rating" } }, [
        _vm._v(
          "Установить требование к участникам по рейтингу с возможностью отклонения предложения участника с рейтингом ниже 4,5 балла:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "federal-law-a31s1p1" } }, [
        _vm._v(
          "Соответствие участника закупки требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "federal-law-a31s1ps3_5ps7_11" } }, [
        _vm._v(
          "Соответствие участника закупки требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ (обязательно):"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "federal-law-a31s1_1" } }, [
        _vm._v(
          "Соответствие участника закупки требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "only-small-business" } }, [
        _vm._v(
          "Участник является социально ориентированной некоммерческой организацией (СОНКО):"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("label", { attrs: { for: "requirement-sonko" } }, [
        _vm._v("Дополнительные требования:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }