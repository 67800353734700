<template>
    <on-off v-slot:default="onoff" v-bind:options="{ id: 'deliverables-selector-popup', onEsc: false, onBody: false }">
        <div v-if="onoff.ison" class="atmo-popup-wrapper">
            <deliverables-selector-popup-controller v-slot:default="dspc">
                <div class="atmo-popup-content atmo-deliverables-selector-popup">
                    <div v-if="dspc.isloading" class="atmo-spinner"><img src="../../../../assets/images/loaders/spinner-sector.svg" alt="" /></div>

                    <button v-on:click.stop="onoff.off" class="atmo-button--small atmo-close"><i class="atmo-icon-close"></i></button>

                    <h5>Выбрать позицию спецификации</h5>

                    <header>
                        <DeliverablesSelectorFilter.view v-bind:controller="dspc" />
                    </header>

                    <main>
                        <!-- Has to regenerate the component each time the page changes -->
                        <!-- via changing key binding in order to reset the requirements dropdowns -->
                        <DeliverablesSelectorList.view v-bind:key="dspc.random()" v-bind:controller="dspc" v-bind:deliverables="dspc.list"> </DeliverablesSelectorList.view>
                    </main>

                    <footer>
                        <StandardPagination.view v-if="!dspc.islistempty" v-bind:pagination="dspc.pagination" v-bind:pageupdatehandler="dspc.pageUpdate" />
                    </footer>
                </div>
            </deliverables-selector-popup-controller>
        </div>
    </on-off>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import StandardPaginationView from '@core/views/layouts/common/StandardPagination.view.vue';
import DeliverablesSelectorPopupController from '@core/js/viewmodels/purchases/item/update/deliverables/DeliverablesSelectorPopupController.viewmodel';

import DeliverablesSelectorListView from './DeliverablesSelectorList.view.vue';
import DeliverablesSelectorFilterView from './DeliverablesSelectorFilter.view.vue';

export default {
    components: {
        'on-off': new OnOff(),
        'deliverables-selector-popup-controller': DeliverablesSelectorPopupController,

        'StandardPagination.view': StandardPaginationView,
        'DeliverablesSelectorList.view': DeliverablesSelectorListView,
        'DeliverablesSelectorFilter.view': DeliverablesSelectorFilterView
    }
};
</script>
