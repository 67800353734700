<template>
    <b-form-group :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 align-items-baseline" :label-align="labelRight ? 'right' : 'left'" :class="compact ? 'mb-0' : ''">
        <p :id="id" class="fs-14 grey-color my-auto col-form-label">{{ value ? getDateTimeFormatSeconds(value) : '&mdash;' }}</p>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-datetime',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        compact: {
            type: Boolean,
            default: false
        },
        labelColsLg: {
            type: Number,
            default: 3
        },
        labelColsSm: {
            type: Number,
            default: 4
        },
        labelRight: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        id() {
            return 'text_row_datetime_' + this._uid;
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
