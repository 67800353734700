var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-component-wrapper" }, [
    _c(
      "ul",
      {
        staticClass: "atmo-entity-item-title atmo-icon-custom",
        class: _vm.pi.getStatusClass("atmo-entity-status-"),
      },
      [
        _c(
          "li",
          {
            staticClass: "pr-4 align-content-center",
            staticStyle: {
              "white-space": "nowrap",
              overflow: "hidden",
              "text-overflow": "ellipsis",
            },
          },
          [
            _c(
              "b-badge",
              {
                staticClass: "my-auto fs-14 mr-2",
                attrs: { variant: "primary" },
              },
              [_vm._v(_vm._s(_vm.pi.noticeitem.product.title))]
            ),
            _vm.pi.noticeitem.only_smp
              ? _c(
                  "b-badge",
                  {
                    staticClass: "my-auto fs-14 mr-2",
                    attrs: { variant: "custom-orange" },
                  },
                  [_vm._v("Только СМП")]
                )
              : _vm._e(),
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: `/purchases/grouped/fl44/item/${_vm.pi.noticeitem.id}/view`,
                },
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.pi.noticeitem.registration_number),
                  },
                }),
              ]
            ),
            _vm.pi.noticeitem.purchase_object
              ? [
                  _vm._v(
                    ' На закупку "' +
                      _vm._s(_vm.pi.noticeitem.purchase_object) +
                      '"'
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("li", { staticClass: "text-nowrap" }, [
          _c("span", {
            staticClass: "text-uppercase",
            domProps: { textContent: _vm._s(_vm.pi.status.title) },
          }),
        ]),
      ]
    ),
    _c("ul", { staticClass: "atmo-entity-item-summary" }, [
      _c("li", [
        _vm._m(0),
        _c("dd", [
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("formatnumber")(_vm.pi.noticeitem.starting_price))
            ),
          ]),
          _vm._v(" руб. "),
        ]),
      ]),
      _vm.pi.hasAllSummaryItemsVisible
        ? _c("li", [
            _c("dt", [_vm._v("Предложений: ")]),
            _c("dd", [
              !_vm.pi.noticeitem.are_proposals_hidden
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.pi.noticeitem.proposals_count),
                    },
                  })
                : _c("span", [_vm._v("Скрыто")]),
            ]),
          ])
        : _vm._e(),
      _vm.pi.hasAllSummaryItemsVisible
        ? _c("li", [
            _c("dt", [_vm._v("Лучшее: ")]),
            !_vm.pi.noticeitem.are_proposals_hidden
              ? _c("dd", [
                  _vm.pi.noticeitem.best_proposal_price > 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatnumber")(
                              _vm.pi.noticeitem.best_proposal_price
                            )
                          ) + " руб."
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ])
              : _c("dd", [_vm._v("Скрыто")]),
          ])
        : _vm._e(),
      _vm.pi.hasAllSummaryItemsVisible
        ? _c("li", [
            _c("dt", [_vm._v("Снижение: ")]),
            _c("dd", [
              _vm.pi.noticeitem.are_proposals_hidden
                ? _c("span", [_vm._v("Скрыто")])
                : _c("span", [
                    _vm._v(_vm._s(_vm.pi.noticeitem.discount_percentage) + "%"),
                  ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("ul", { staticClass: "atmo-entity-item-details" }, [
      _c("li", { staticClass: "atmo-column atmo-special-style" }, [
        _c("ul", [
          _vm.pi.status.code === "accepting"
            ? _c(
                "li",
                [
                  _c("section", [
                    _vm.pi.countdown
                      ? _c("span", [
                          _c("dt", [
                            _vm._v("До окончания приема предложений:"),
                          ]),
                          _c("dd", {
                            domProps: { textContent: _vm._s(_vm.pi.countdown) },
                          }),
                        ])
                      : _vm._e(),
                    _vm.pi.countdown === false
                      ? _c("dt", [_vm._v("Прием предложений завершен")])
                      : _vm._e(),
                  ]),
                  _vm.pi.countdown && _vm.pi.user && _vm.pi.user.is_logged_in
                    ? _c("fl44-new-proposal-button", {
                        attrs: { noticeitem: _vm.pi.noticeitem },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.pi.status.code === "summarizing"
            ? _c("li", [_vm._v("Прием предложений завершен")])
            : _vm._e(),
          _vm.pi.status.code === "summarized"
            ? _c("li", [
                _c("dt", [_vm._v("Подведены итоги:")]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.pi.noticeitem.protocol_date,
                        "removeSeconds"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.pi.status.code === "failed"
            ? _c("li", [
                _c("dt", [_vm._v("Подведены итоги:")]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.pi.noticeitem.proposal_accept_end_date,
                        "removeSeconds"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column" }, [
        _c("ul", [
          _c("li", [
            _c("dt", [_vm._v("Предмет закупки:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(_vm.pi.noticeitem.deliverable_group_title),
              },
            }),
          ]),
          _c("li", [
            _c("dt", [_vm._v("Заказчик:")]),
            _c("dd", [
              _c("a", {
                attrs: {
                  target: "_blank",
                  href: "/organizations/" + _vm.pi.noticeitem.customer.id,
                },
                domProps: {
                  textContent: _vm._s(_vm.pi.noticeitem.customer.name),
                },
              }),
            ]),
          ]),
          _vm.pi.hasSupplier
            ? _c("li", [
                _c("dt", [_vm._v("Поставщик:")]),
                _c("dd", [
                  _c("a", {
                    attrs: {
                      target: "_blank",
                      href: "/organizations/" + _vm.pi.noticeitem.supplier.id,
                    },
                    domProps: {
                      textContent: _vm._s(_vm.pi.noticeitem.supplier.name),
                    },
                  }),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column" }, [
        _c("ul", [
          _c("li", [
            _c("dt", [_vm._v("Планируемая дата исполнения:")]),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm._f("formatdate")(
                    _vm.pi.noticeitem.planned_date_contract_fulfilled,
                    "onlyDate"
                  )
                )
              ),
            ]),
          ]),
          _c("li", [
            _vm._v("Количество позиций: "),
            _c("b", {
              domProps: {
                textContent: _vm._s(_vm.pi.noticeitem.deliverables_count),
              },
            }),
          ]),
          _vm.pi.noticeitem.delivery_addresses &&
          _vm.pi.noticeitem.delivery_addresses.length > 0
            ? _c(
                "li",
                [
                  _c("dt", [
                    _vm._v(
                      "Адреса для доставки товаров/выполнения работ/оказания услуг:"
                    ),
                  ]),
                  _vm._l(
                    _vm.pi.noticeitem.delivery_addresses,
                    function (address, index) {
                      return _c("dd", { key: index }, [
                        _vm._v("— " + _vm._s(address)),
                      ])
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column atmo-special-style" }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: `/purchases/grouped/fl44/item/${_vm.pi.noticeitem.id}/view`,
                },
              },
              [
                _c("i", { staticClass: "atmo-icon-search" }),
                _c("span", [_vm._v("Информация о закупке")]),
              ]
            ),
          ]),
          _vm.pi.hasProtocol
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: `/purchases/grouped/fl44/item/${_vm.pi.noticeitem.id}/view#atmo-supplier-info`,
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-shield" }),
                    _c("span", [_vm._v("Итоговый протокол")]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.pi.hasContract
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: `/purchases/grouped/fl44/item/${_vm.pi.noticeitem.id}/view#atmo-contract`,
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-contract" }),
                    _c("span", [_vm._v("Контракт")]),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "li",
            [
              _vm.pi.buttondata.isActive
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "atmo-button--small atmo-accented font-weight-bold",
                      attrs: {
                        target: "_blank",
                        to: {
                          name: "purchases.grouped.item.view",
                          params: {
                            group: "fl44",
                            id: _vm.pi.buttondata.purchaseId,
                          },
                          hash: _vm.pi.buttondata.hash,
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.pi.buttondata.buttonText) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c("abbr", { attrs: { title: "Начальная цена контракта" } }, [
        _vm._v("НЦК"),
      ]),
      _vm._v(": "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }