<template>
    <ul class="atmo-content-block-2-columns atmo-no-item-separator">
        <li>
            <dt>ИНН:</dt>
            <dd v-text="organization.inn"></dd>
        </li>
        <li>
            <dt>Тип:</dt>
            <dd>
                <span v-if="organization.type === 'legal'">Организация</span>
                <span v-if="organization.type === 'individual'">ИП</span>
            </dd>
        </li>
        <li>
            <dt>Полное наименование:</dt>
            <dd v-text="organization.title_full"></dd>
        </li>
        <li>
            <dt>Адрес:</dt>
            <dd v-text="organization.address"></dd>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        organization: { type: Object, required: true }
    }
};
</script>
