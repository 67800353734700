var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("entity-status-menu-controller", {
    attrs: {
      route: _vm.elpc.storeprefix,
      statuses: _vm.elpc.dictionaries.statusesmenu,
      currentstatus: _vm.elpc.currentstatus,
    },
    on: { "status:update": _vm.elpc.processRequestUpdate },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (esmc) {
          return [
            _c("responsive-on-off", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ronoff) {
                      return [
                        _c("div", { staticClass: "atmo-container-fixed" }, [
                          _c(
                            "div",
                            { staticClass: "atmo-status-menu-controller" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "atmo-onoff",
                                  on: { click: ronoff.toggle },
                                },
                                [
                                  _c("i", { staticClass: "atmo-icon-burger" }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(esmc.currentTitle),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: ronoff.ison,
                                      expression: "ronoff.ison",
                                    },
                                  ],
                                  staticClass: "atmo-menu",
                                },
                                _vm._l(esmc.statuses, function (menuitem) {
                                  return _c(
                                    "li",
                                    {
                                      key: menuitem.id,
                                      on: { click: ronoff.off },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          attrs: {
                                            disabled: esmc.isDisabledMenuItem(
                                              menuitem.id
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return esmc.status(menuitem.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                menuitem.title
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }