var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { "hide-footer": "", centered: "" },
      on: { hidden: _vm.hide },
      model: {
        value: _vm.successConfirm,
        callback: function ($$v) {
          _vm.successConfirm = $$v
        },
        expression: "successConfirm",
      },
    },
    [
      _c("div", { staticClass: "pb-5 py-4 text-center" }, [
        _c("img", {
          attrs: {
            src: require("@theme/assets/images/icons/feedSucess.svg"),
            alt: "",
          },
        }),
        _c("p", { staticClass: "mt-4 mb-0 fs-14 grey-color" }, [
          _vm._v("ПОДТВЕРЖДЕНИЕ ВЫПОЛНЕНО УСПЕШНО"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }