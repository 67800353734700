'use strict';

import { IState } from './state';

const mutations = {
    set: function (state: IState, popupStateData: Record<string, boolean>): void {
        const popupName = Object.keys(popupStateData)[0];
        const popupPropertyName = `ison_${popupName}`;
        state[popupPropertyName] = popupStateData[popupName];
    }
};

export default mutations;
