<template>
    <b-modal v-if="!$store.getters.isLoggedIn" ref="auth-modal" v-model="showModal" @close="close" centered hide-footer title="Регистрация">
        <div class="d-block text-center w-100 m-auto">
            <h4>Уважаемый пользователь!</h4>
        </div>
        <div class="d-block text-justify w-100 my-3">
            <p class="grey-color">
                Прохождение процедуры регистрации / аккредитации означает согласие с
                <a :href="links.user_agreement" style="color: var(--green-color) !important; text-decoration: underline !important" target="_blank">Пользовательским соглашением</a>,
                <a :href="links.privacy_policy" style="color: var(--green-color) !important; text-decoration: underline !important" target="_blank">Политикой конфиденциальности</a>,
                <a :href="links.public_offer" style="color: var(--green-color) !important; text-decoration: underline !important" target="_blank">Публичной офертой</a>
                и ознакомление с <a :href="links.user_guide" style="color: var(--green-color) !important; text-decoration: underline !important" target="_blank">Руководством пользователя</a>.
            </p>
        </div>
        <div class="d-block text-center w-100 my-3">
            <b-form-group :state="isCheckRules">
                <b-form-checkbox v-model="readedDocumetation" :state="isCheckRules">
                    <span class="grey-color fw-600">Подтверждаю ознакомление с материалами</span>
                </b-form-checkbox>
            </b-form-group>
        </div>
        <div class="d-block text-center w-75 m-auto">
            <b-link v-if="theme.features.customer_registration" :disabled="!readedDocumetation" class="btn btn-block btn-custom-green mb-4" to="/auth/register/customer" @click="showModal = false"> Регистрация в качестве Заказчика </b-link>
            <b-link :disabled="!readedDocumetation" class="btn btn-block btn-custom-green" to="/auth/register/provider" @click="showModal = false"> АККРЕДИТАЦИЯ ПОСТАВЩИКА </b-link>
        </div>
        <div class="my-3">
            <p class="m-0 fs-12 grey-color text-center">Уже зарегистрированы? Используйте кнопку "Вход в ЛК"</p>
        </div>
        <div v-if="!theme.features.customer_registration" class="d-block text-justify w-100 my-4 fs-14 grey-color">
            <p class="text-center">
                <strong class="text-danger">ВНИМАНИЮ ЗАКАЗЧИКОВ!</strong>
            </p>
            <p>Если Вам необходимо зарегистрироваться в качестве Заказчика - напишите в техническую поддержку запрос на регистрацию в качестве заказчика и следуйте дальнейшим указаниям.</p>
        </div>
        <div class="mt-3">
            <p class="m-0 fs-12 grey-color text-center">
                <a :href="theme.variables.global.links.manuals.workbench_setup" class="grey-color" style="color: var(--green-color) !important; text-decoration: underline !important" target="_blank">Инструкция по настройке</a>
                сервисов КриптоПро, для использования электронной подписи
            </p>
        </div>
    </b-modal>
</template>

<script>
import { __LINKS } from '@/const';
import Config from '@core/js/ddriven/application/config/Config';

export default {
    name: 'register-modal',
    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            readedDocumetation: false
        };
    },
    mounted() {
        this.readedDocumetation = false;
    },
    methods: {
        close() {
            this.readedDocumetation = false;
            this.$emit('close');
        }
    },
    computed: {
        isCheckRules() {
            return this.readedDocumetation;
        },
        links() {
            return __LINKS;
        },
        theme() {
            return Config.get('theme');
        }
    }
};
</script>
