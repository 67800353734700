var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        {
          staticClass: "cursor-pointer fs-14 py-1 notification-row",
          class: "notification-row-" + _vm.variant,
          on: { click: _vm.show },
        },
        [
          _c(
            "b-col",
            { staticClass: "col-auto pr-1 my-auto" },
            [
              _vm.withCheckbox
                ? _c("b-icon", {
                    staticClass: "row-checkbox",
                    attrs: {
                      id: "check",
                      icon: _vm.rowSelected ? "check2-square" : "square",
                    },
                    on: { click: _vm.select },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "col-auto px-1 my-auto" },
            [
              _vm.notification.is_read
                ? _c("b-icon", {
                    staticClass: "green-color read-icon",
                    attrs: {
                      icon: "circle",
                      scale: 0.7,
                      title: "Пометить непрочитанным",
                      "aria-hidden": "true",
                    },
                    on: { click: _vm.markNotificationsAsUnread },
                  })
                : _c("b-icon", {
                    staticClass: "green-color read-icon",
                    attrs: {
                      icon: "circle-fill",
                      scale: 0.7,
                      title: "Пометить прочитанным",
                      "aria-hidden": "true",
                    },
                    on: { click: _vm.markNotificationsAsRead },
                  }),
            ],
            1
          ),
          _c("b-col", { staticClass: "px-1 notification-title my-auto" }, [
            _vm._v(" " + _vm._s(_vm.notification.type_name) + " "),
          ]),
          _c(
            "b-col",
            { staticClass: "col-auto text-nowrap date-col my-auto" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.getDateTimeFormatSeconds(_vm.notification.created_at)
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "col-auto px-1" },
            [
              _c(
                "b-button",
                {
                  staticClass: "p-0",
                  staticStyle: { width: "24px", height: "24px" },
                  attrs: { variant: "custom-secondary-" + _vm.variant },
                  on: { click: _vm.trash },
                },
                [
                  _c("b-icon", {
                    staticClass: "my-auto",
                    attrs: {
                      icon: "trash",
                      scale: "0.7",
                      "aria-hidden": "true",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }