var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block text-center w-100 m-auto" },
    [
      !_vm.showForgotWindow
        ? _c(
            "div",
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isLoading,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-container",
                    { staticClass: "w-50 pt-2" },
                    [
                      _c("form-row-edit-text", {
                        staticClass: "mb-1",
                        attrs: { label: "Логин", v: _vm.$v.form.username },
                        on: { input: _vm.onInput },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                      _c("form-row-edit-text", {
                        staticClass: "mb-1",
                        attrs: {
                          type: "password",
                          label: "Пароль",
                          v: _vm.$v.form.password,
                        },
                        on: { input: _vm.onInput },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { state: _vm.state } },
                        [_vm._v(_vm._s(_vm.errorMessage))]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "fs-14",
                          attrs: { variant: "link" },
                          on: {
                            click: function ($event) {
                              _vm.showForgotWindow = true
                            },
                            close: _vm.cancelForgot,
                          },
                        },
                        [_vm._v("Забыли пароль?")]
                      ),
                    ],
                    1
                  ),
                  _c("footer", { staticClass: "modal-footer pb-0 px-0" }, [
                    _c(
                      "div",
                      { staticClass: "w-100 text-left" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "text-uppercase mr-2",
                            attrs: { variant: "custom-outline-secondary" },
                            on: { click: _vm.onClose },
                          },
                          [_vm._v("Отмена")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "text-uppercase",
                            attrs: {
                              disabled: !_vm.state,
                              variant: "custom-green",
                            },
                            on: { click: _vm.login },
                          },
                          [_vm._v("Продолжить")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c("ForgotPassword", {
            on: {
              close: _vm.cancelForgot,
              loadStart: _vm.forgotLoadStart,
              loadEnd: _vm.forgotLoadEnd,
              done: _vm.onClose,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }