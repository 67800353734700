'use strict';

import DownloadConfig from '@core/js/ddriven/domain/services/downloads/DownloadConfig.valueobject';
import { AxiosResponse, AxiosRequestConfig, Method } from 'axios';
import ATMOAPIFacade from '../ATMOAPIFacade';
import ATMOAPIResponseNormalizedVO from '../ATMOAPIResponseNormalized.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

export default class CommonAPIFacadePartial {
    private baseFacade: ATMOAPIFacade;

    constructor(baseFacade: ATMOAPIFacade) {
        this.baseFacade = baseFacade;
    }

    async downloadFileBlob(config: DownloadConfig, apiV1?: boolean): Promise<AxiosResponse | ATMOAPIResponseNormalizedVO> {
        const axiosConfig: AxiosRequestConfig = { responseType: 'blob' };

        return apiV1 ? await this.baseFacade.unifiedResponseCall(this.baseFacade.v1, config.verb as Method, config.endpoint!, config.body, {}, axiosConfig) : await this.baseFacade.legacy.unifiedResponseCall(config.verb!, config.endpoint!, config.body, {}, axiosConfig);
    }

    async uploadFile(data: FormData): Promise<AxiosResponse> {
        const path = '/documents';

        const response = await this.baseFacade.legacy.unifiedResponseCall('POST', path, data as any, {}, {});

        if (response.status !== 201) {
            ApplicationServiceLocator.get('uitoasts').processOnlyFailure(ATMOAPIResponseNormalizedVO.fromAxiosResponse(response));
            throw new Error(`ATMO Exception: Unexpected server error: status "${response.status}", message "${response.statusText}"`);
        }

        return response;
    }
}
