<template>
    <div class="atmo-component-wrapper">
        <ul class="atmo-entity-item-title atmo-icon-custom" v-bind:class="pi.getStatusClass('atmo-entity-status-')">
            <li class="pr-4 align-content-center" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                <b-badge class="my-auto fs-14 mr-2" variant="primary">{{ pi.noticeitem.product.title }}</b-badge>
                <b-badge v-if="pi.noticeitem.only_smp" class="my-auto fs-14 mr-2" variant="custom-orange">Только СМП</b-badge>
                <a target="_blank" v-bind:href="`/purchases/grouped/fl44/item/${pi.noticeitem.id}/view`"><span v-text="pi.noticeitem.registration_number"></span></a>
                <template v-if="pi.noticeitem.purchase_object">&nbsp;На закупку "{{ pi.noticeitem.purchase_object }}"</template>
            </li>
            <li class="text-nowrap"><span class="text-uppercase" v-text="pi.status.title"></span></li>
        </ul>

        <ul class="atmo-entity-item-summary">
            <li>
                <dt><abbr title="Начальная цена контракта">НЦК</abbr>:&nbsp;</dt>
                <dd>
                    <span>{{ pi.noticeitem.starting_price | formatnumber }}</span
                    >&nbsp;руб.
                </dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Предложений:&nbsp;</dt>
                <dd>
                    <span v-if="!pi.noticeitem.are_proposals_hidden" v-text="pi.noticeitem.proposals_count"></span>
                    <span v-else>Скрыто</span>
                </dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Лучшее:&nbsp;</dt>
                <dd v-if="!pi.noticeitem.are_proposals_hidden">
                    <span v-if="pi.noticeitem.best_proposal_price > 0">{{ pi.noticeitem.best_proposal_price | formatnumber }}&nbsp;руб.</span>
                    <span v-else>-</span>
                </dd>
                <dd v-else>Скрыто</dd>
            </li>
            <li v-if="pi.hasAllSummaryItemsVisible">
                <dt>Снижение:&nbsp;</dt>
                <dd>
                    <span v-if="pi.noticeitem.are_proposals_hidden">Скрыто</span>
                    <span v-else>{{ pi.noticeitem.discount_percentage }}%</span>
                </dd>
            </li>
        </ul>

        <ul class="atmo-entity-item-details">
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li v-if="pi.status.code === 'accepting'">
                        <section>
                            <span v-if="pi.countdown"
                                ><dt>До окончания приема предложений:</dt>
                                <dd v-text="pi.countdown"></dd
                            ></span>
                            <dt v-if="pi.countdown === false">Прием предложений завершен</dt>
                        </section>
                        <fl44-new-proposal-button v-if="pi.countdown && pi.user && pi.user.is_logged_in" :noticeitem="pi.noticeitem" />
                    </li>
                    <li v-if="pi.status.code === 'summarizing'">Прием предложений завершен</li>
                    <li v-if="pi.status.code === 'summarized'">
                        <dt>Подведены итоги:</dt>
                        <dd>{{ pi.noticeitem.protocol_date | formatdate('removeSeconds') }}</dd>
                    </li>
                    <li v-if="pi.status.code === 'failed'">
                        <dt>Подведены итоги:</dt>
                        <dd>{{ pi.noticeitem.proposal_accept_end_date | formatdate('removeSeconds') }}</dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li>
                        <dt>Предмет закупки:</dt>
                        <dd v-text="pi.noticeitem.deliverable_group_title"></dd>
                    </li>
                    <li>
                        <dt>Заказчик:</dt>
                        <dd><a target="_blank" v-bind:href="'/organizations/' + pi.noticeitem.customer.id" v-text="pi.noticeitem.customer.name"></a></dd>
                    </li>
                    <li v-if="pi.hasSupplier">
                        <dt>Поставщик:</dt>
                        <dd><a target="_blank" v-bind:href="'/organizations/' + pi.noticeitem.supplier.id" v-text="pi.noticeitem.supplier.name"></a></dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li>
                        <dt>Планируемая дата исполнения:</dt>
                        <dd>{{ pi.noticeitem.planned_date_contract_fulfilled | formatdate('onlyDate') }}</dd>
                    </li>
                    <li>Количество позиций: <b v-text="pi.noticeitem.deliverables_count"></b></li>
                    <li v-if="pi.noticeitem.delivery_addresses && pi.noticeitem.delivery_addresses.length > 0">
                        <dt>Адреса для доставки товаров/выполнения работ/оказания услуг:</dt>
                        <dd v-for="(address, index) in pi.noticeitem.delivery_addresses" v-bind:key="index">&mdash;&nbsp;{{ address }}</dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li>
                        <a target="_blank" v-bind:href="`/purchases/grouped/fl44/item/${pi.noticeitem.id}/view`"><i class="atmo-icon-search"></i><span>Информация о закупке</span></a>
                    </li>
                    <!--                    <li v-if="pi.noticeitem.hasProposals">-->
                    <!--                        <a v-bind:href="`/purchases/grouped/fl44/item/${pi.noticeitem.id}/view#atmo-proposals`" target="_blank"><i class="atmo-icon-dynamic"></i><span>Динамика предложений</span></a>-->
                    <!--                    </li>-->
                    <li v-if="pi.hasProtocol">
                        <a target="_blank" v-bind:href="`/purchases/grouped/fl44/item/${pi.noticeitem.id}/view#atmo-supplier-info`"><i class="atmo-icon-shield"></i><span>Итоговый протокол</span></a>
                    </li>
                    <li v-if="pi.hasContract">
                        <a target="_blank" v-bind:href="`/purchases/grouped/fl44/item/${pi.noticeitem.id}/view#atmo-contract`"><i class="atmo-icon-contract"></i><span>Договор</span></a>
                    </li>
                    <li>
                        <router-link v-if="pi.buttondata.isActive" class="atmo-button--small atmo-accented font-weight-bold" target="_blank" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: pi.buttondata.purchaseId }, hash: pi.buttondata.hash }"
                            >{{ pi.buttondata.buttonText }}
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import Fl44NewProposalButton from '@/components/global/notices/partials/fl44-new-proposal-button.vue';

export default {
    components: { Fl44NewProposalButton },
    props: {
        pi: { required: true, type: Object }
    }
};
</script>
