'use strict';

import State, { ActionContext } from 'vuex';

import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

const actions = {
    // loadPurchaseTypes: async function (context: ActionContext<typeof State, typeof State>) {
    //     const responsePurchaseTypes = await (context.rootState as any).rearchitected.utils.atmoapi.dictionaries.loadPurchaseTypes();

    //     context.commit('setPurchaseTypes', responsePurchaseTypes.data.data);
    // },

    loadDeliverablesGroups: async function (context: ActionContext<typeof State, typeof State>) {
        const responseDeliverablesGroups = await (context.rootState as any).rearchitected.utils.atmoapi.dictionaries.loadDeliverablesGroups();

        context.commit('setDeliverablesGroups', responseDeliverablesGroups.data.data);
    },

    loadMunicipalities: async function (context: ActionContext<typeof State, typeof State>) {
        const responseMunicipaities = await ApplicationServiceLocator.get('api').dictionaries.loadMunicipalities();

        context.commit('setMunicipalities', responseMunicipaities.data.data);
    }
};

export default actions;
