<template>
    <div class="layout-wrapper layout-1">
        <div class="layout-inner greyBg">
            <div class="sticky">
                <app-layout-navbar />
            </div>

            <div class="layout-container greyBg">
                <!--        <app-layout-sidenav />-->

                <div class="layout-content">
                    <div class="router-transitions container-fluid flex-grow-1 container-p-y p-0">
                        <router-view />
                    </div>
                </div>
            </div>
            <app-layout-footer />
        </div>
        <SVTAOverlayContainer></SVTAOverlayContainer>
    </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar';
// import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from './LayoutFooter';
import SVTAOverlayContainer from 'themes/views/overlays/SVTAOverlayContainer.view.vue';

export default {
    name: 'AppLayout1',
    components: {
        'app-layout-navbar': LayoutNavbar,
        // "app-layout-sidenav": LayoutSidenav,
        'app-layout-footer': LayoutFooter,
        SVTAOverlayContainer
    },
    mounted() {},
    methods: {}
};
</script>

<style>
.sticky {
    position: sticky;
    top: 0;
    z-index: 10;
}

.table-atmo {
    border-collapse: collapse;
}

.table-atmo thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
    /* vertical-align: middle; */
}

.table-atmo tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    /* vertical-align: middle; */
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
