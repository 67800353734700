import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';
import { Certificate } from 'crypto-pro';
import store from '@/store';
import { prepareCertificateOwnerInfo } from '@/helpers';

export class PersonalProfileAPIService extends BaseApiService {
    constructor() {
        super('personal/profile');
    }

    async getInformation() {
        try {
            const response = await axios.get(this.getUrl());
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async updateInformation(data: any) {
        try {
            await axios.put(this.getUrl(), data);
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async changeCertificate(certificate: Certificate) {
        try {
            const owner_info = await prepareCertificateOwnerInfo(certificate);
            await axios.post(this.getUrl('change_certificate'), {
                certificate,
                owner_info
            });
            await store.dispatch('showSuccessToast', 'Сертификат успешно привязан');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async changePOA(data: any) {
        try {
            await axios.post(this.getUrl('poa'), data);
            await store.dispatch('showSuccessToast', 'МЧД успешно загружена');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
