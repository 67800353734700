'use strict';

import isPrimitiveEmpty from '@lib/js/src/misc/isPrimitiveEmpty';
import BaseValueObject, { TSelfBaseValueObject } from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import DeliverableItemEditableVO from '@core/js/ddriven/domain/model/common/deliverable/update/general/DeliverableItemEditable.valueobject';
import { IOriginalSpecItem } from '../../common/deliverable/update/general/harness/OriginalSpecItem.valueobject';
import { IPurchaseDraft } from './PurchaseItemEditable.valueobject';
import KBKLimitsSpecificationItemsEditableCollection from './KBKLimitsSpecificationItemsEditable.collection';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';

type TSelfPOJO = Omit<SpecificationEditableVO, TSelfBaseValueObject | 'total_amount' | 'hasKBKLimitsErrors'>;

export default class SpecificationEditableVO extends BaseValueObject {
    deliverables: DeliverableItemEditableVO[];
    kbkLimits: KBKLimitsSpecificationItemsEditableCollection;

    /**
     * REFACTOR: Specification looks anemic. Could be refactored away towards its properties'
     * methods. E.g. deliverables can be refactored to a collection that implements
     * total_amount getter. It as well can implement fromDraft method.
     *
     * Then PurchaseEditable can accommodate the creation of both deliverables and
     * kbklimits on itself.
     *
     * At the same time the refactoring requires to provide somehow the deliverables
     * total amount into kbk limits collection to make a comparison. I would not like
     * passing deliverables collection to kbk limits collection constructor. Just if
     * there is no other way.
     *
     * I could pass only deliverables.total_amount getter wrapped in object to be able to track
     * its changes in kbkimits collection.
     *
     */
    constructor(data?: TSelfPOJO) {
        super();

        this.deliverables = data?.deliverables ?? [];
        this.kbkLimits = data?.kbkLimits ?? new KBKLimitsSpecificationItemsEditableCollection();
    }

    // REFACTOR: Can be moved to deliverables collection.
    get total_amount(): number {
        const amount = this.deliverables.reduce((accumulator: number, item: DeliverableItemEditableVO) => {
            return (accumulator += item.price_total ?? 0);
        }, 0);
        return roundToTwoDecimals(amount);
    }

    public static fromDraft(data: IPurchaseDraft): SpecificationEditableVO {
        const pojo: TSelfPOJO = {
            deliverables: data.draft.specs.map((spec: IOriginalSpecItem) => {
                return DeliverableItemEditableVO.fromPurchaseDraft(spec);
            }),
            kbkLimits: KBKLimitsSpecificationItemsEditableCollection.fromAPIResponse(data.draft.limits)
        };
        return new SpecificationEditableVO(pojo);
    }

    // REFACTOR: Can be moved to deliverables collection. As well probably made non-static.
    // Besides the validations inside could be implemented on the collection.
    public static hasDeliverablesErrors(specification: SpecificationEditableVO): boolean {
        return (
            specification.deliverables.length < 1 ||
            (Array.isArray(specification.deliverables) &&
                specification.deliverables.length > 0 &&
                specification.deliverables.some((deliverable: DeliverableItemEditableVO) => {
                    return isPrimitiveEmpty(deliverable.quantity) || !deliverable.hasPricePerUnit();
                }))
        );
    }

    public hasKBKLimitsErrors(): boolean {
        return this.kbkLimits.hasErrors() || this.total_amount !== this.kbkLimits.distributed_amount_total;
    }
}
