<template>
    <ul class="atmo-list">
        <li>
            <dt>Описание</dt>
            :
            <dd v-text="description"></dd>
        </li>
        <li v-for="(requirement, index) in requirements" v-bind:key="index">
            <dt v-text="requirement.title"></dt>
            :
            <dd v-text="requirement.description"></dd>
        </li>
    </ul>
</template>

<script>
import isTypeOrNull from '@lib/js/src/misc/isTypeOrNull';

export default {
    props: {
        requirements: {
            required: true,
            validator: (prop) => {
                return isTypeOrNull(prop, 'Array');
            }
        },
        description: { type: String }
    },
    computed: {
        hasRequirements() {
            return this.$props.requirements && this.$props.requirements.length > 0;
        }
    }
};
</script>
