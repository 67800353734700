'use strict';

import axios, { AxiosInstance, AxiosRequestConfig, Method, AxiosResponse, AxiosError } from 'axios';

class HTTPRESTAPIFacade {
    axios: AxiosInstance;

    constructor(baseURL: string | undefined, customHeaders?: Record<string, unknown>) {
        if (!baseURL) {
            throw new Error('ATMO Error: "baseURL" argument must be provided.');
        }
        let headers = { 'Content-Type': 'application/json' };

        headers = { ...headers, ...(customHeaders || {}) };

        this.axios = axios.create({
            baseURL: baseURL,
            headers: headers
        });
    }

    async call(verb: string, path: string, payload?: Record<string, unknown>, headers?: Record<string, unknown>, customConfig: AxiosRequestConfig = {}): Promise<AxiosResponse> {
        let config: AxiosRequestConfig = {
            url: path,
            method: verb as Method,
            data: payload,
            headers: headers
        };

        config = { ...config, ...customConfig };

        return await this.axios.request(config);
    }

    /**
     * Proxies http facade to unify the response data format for both
     * success and error responses.
     *
     * @param {string} verb
     * @param {string} path
     * @param {Record<string, unknown>} [payload]
     * @return {*}  {Promise<AxiosResponse>}
     * @memberof ATMOAPIFacade
     */
    public async unifiedResponseCall(verb: string, path: string, payload?: Record<string, unknown>, headers: Record<string, unknown> = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
        try {
            return await this.call(verb, path, payload, headers, config);
        } catch (error) {
            return (error as AxiosError).response as AxiosResponse;
        }
    }
}

export default HTTPRESTAPIFacade;
