'use strict';

export enum PurchaseStatuses {
    Accepting = 1,
    Summarizing = 99,
    Summarized = 2,
    Contractactive = 3,
    Cancelled = 7,
    Failed = 8
}
