'use strict';

import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import HTTPRESTAPIFacade from '@core/js/ddriven/application/ports/adapters/http/outgoing/HTTPRESTAPIFacade';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import ATMOAPIResponseNormalizedVO from '../ATMOAPIResponseNormalized.valueobject';
import ATMOAPIFacade from '../ATMOAPIFacade';

export default class SupplierAPIFacadePartial {
    http: HTTPRESTAPIFacade;
    baseFacade: ATMOAPIFacade;

    constructor(http: HTTPRESTAPIFacade, baseFacade: ATMOAPIFacade) {
        this.http = http;
        this.baseFacade = baseFacade;
    }

    async checkDeposit(purchaseId: number): Promise<ATMOAPIResponseNormalizedVO> {
        const path = '/account/provider/check_deposit';

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, { purchaseId: purchaseId });
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    async checkSupplier(purchaseId: number): Promise<ATMOAPIResponseNormalizedVO> {
        const path = `/purchases/${purchaseId}/check_provider`;

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'GET', path);
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    async sendInvoiceToEmail(email: string, amount: number): Promise<ATMOAPIResponseNormalizedVO> {
        const path = '/account/mail/sending';

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.legacy, 'POST', path, { email: email, amount: amount });
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    async downloadInvoice(amount: number): Promise<AxiosResponse> {
        const path = '/account/invoice/download';
        const config: AxiosRequestConfig = { responseType: 'blob' };

        return await this.http.unifiedResponseCall('POST', path, { amount: amount }, {}, config);
    }

    async getOrganizationReliabilityReportFinancials(): Promise<ATMOAPIResponseNormalizedVO> {
        const path = '/cabinet/provider/organization_reports/accounting_info';

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.v1, 'GET', path);
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }

    async getOrganizationXML(inn: string): Promise<ATMOAPIResponseNormalizedVO> {
        const path = '/cabinet/provider/organization_reports/xml';
        const payload = { inn: inn };

        const response = await this.baseFacade.unifiedResponseCall(this.baseFacade.v1, 'POST', path, payload);
        ApplicationServiceLocator.get('uitoasts').processOnlyFailure(response);

        return response;
    }
}
