import axios from 'axios';
import store from '@/store';
import { BaseCommercialApiService } from './_base';

export class CommercialOrganizationInformation extends BaseCommercialApiService {
    constructor() {
        super('commercial');
    }

    async get() {
        const url = `${this.baseUrl}/organization/information`;
        try {
            const response = await axios.get(url);
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async updateInformation(data) {
        const url = `${this.baseUrl}/organization/information`;
        try {
            const response = await axios.post(url, data);
            await store.dispatch('showSuccessToast', 'Информация успешно обновлена');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
