'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IOnOffOnToggleEventPayload, { IOnOffToggleEvent } from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';

interface IData {
    content: any;
}

@Component
export default class ConfirmationDialogue extends BaseViewModel {
    private respond?: Function;

    constructor() {
        super();
        this.name = 'ConfirmationDialogue';
    }

    @Prop({ required: true, type: String }) readonly id!: string;

    /**
     * Lifecycle hooks handlers
     */
    created(): void {
        this.$root.$on('public:onoff:toggle', this.initializeViewmodel);
    }

    data(): IData {
        return {
            content: null
        };
    }

    /**
     * Computed
     */

    /**
     * Watch
     */

    /**
     * Methods
     */
    public confirm(data?: Record<string, any>): void {
        this.respond!(data ?? true);
        this.$root.$emit('public:onoff:toggle', { id: this.$props.id } as IOnOffToggleEvent);
    }

    public cancel(): void {
        this.respond!(false);
        this.$root.$emit('public:onoff:toggle', { id: this.$props.id } as IOnOffToggleEvent);
    }

    /**
     * General prototype methods.
     */
    private initializeViewmodel(payload: IOnOffOnToggleEventPayload): void {
        if (payload.id !== this.$props.id || !payload.ison) {
            return;
        }
        this.$data.content = payload.data.content;
        this.respond = payload.data.respond;
    }
}
