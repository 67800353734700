var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.$props.purchaseitem.delivery_addresses.length > 0 ||
    this.$props.purchaseitem.delivery_schedule
    ? _c(
        "div",
        {
          staticClass: "atmo-hash-jump-point atmo-content-group",
          attrs: { id: "atmo-supply-terms" },
        },
        [
          _c("h3", [_vm._v("Условия поставки")]),
          _c(
            "ul",
            {
              staticClass:
                "atmo-content-block-2-columns atmo-no-item-separator",
            },
            [
              this.$props.purchaseitem.delivery_addresses.length > 0
                ? _c("li", [
                    _c("dt", [
                      _vm._v("Доставка товаров или выполнение работ:"),
                    ]),
                    _c("dd", [
                      _c(
                        "ul",
                        { staticClass: "atmo-list" },
                        _vm._l(
                          this.$props.purchaseitem.delivery_addresses,
                          function (address, index) {
                            return _c("li", { key: index }, [
                              _c("b", {
                                domProps: { textContent: _vm._s(address) },
                              }),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
              this.$props.purchaseitem.delivery_schedule
                ? _c("li", [
                    _c("dt", [
                      _vm._v(
                        "График поставки товаров (выполнения работ, оказания услуг):"
                      ),
                    ]),
                    _c("dd", {
                      domProps: {
                        textContent: _vm._s(
                          this.$props.purchaseitem.delivery_schedule
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }