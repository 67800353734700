<template>
    <div class="atmo-content-group">
        <!-- Remove currentstate after development -->
        <!-- v-bind:currentstate="true" -->
        <on-off v-slot:default="onoff" v-bind:options="{ onEsc: false, onBody: false }">
            <div class="atmo-component-wrapper">
                <button v-if="$props.pivc.isUserEngagedCustomer && !$props.pivc.hasContractPendingAnnexes && !onoff.ison" v-on:click.stop="onoff.on" class="atmo-button">Заключить дополнительное соглашение</button>

                <div v-if="onoff.ison" class="atmo-nested-section">
                    <h3>Создать дополнительное соглашение</h3>
                    <!-- NB: Here have to simulate disputecontroller (see comment below). -->
                    <ContractAnnexUpdate.view v-bind:mode="'create'" v-bind:pivc="$props.pivc" v-bind:disputecontroller="{ onAnnexItemEditableChange: onAnnexItemEditableChange }" />

                    <button v-on:click.stop="onoff.off" class="atmo-button atmo-dimmed">Отмена</button>
                </div>
            </div>
        </on-off>
    </div>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import ContractAnnexUpdateView from './ContractAnnexUpdate.view.vue';
import noop from 'lodash.noop';

export default {
    methods: {
        /**
         * Stub due to iniconsistent place of ContractAnnexUpdate view and viewmodel
         * in views hierarchy: used for both update and create on within a purchase view
         * and within a purchase contract annex view.
         */
        onAnnexItemEditableChange: function () {
            noop();
        }
    },
    props: {
        pivc: { required: true, type: Object }
    },
    components: {
        'on-off': new OnOff(),
        'ContractAnnexUpdate.view': ContractAnnexUpdateView
    }
};
</script>
