var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-contract-fulfillment" },
    },
    [
      _c("h3", [_vm._v("Исполнение договора")]),
      _c(
        "ul",
        { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
        [
          _c("li", [
            _c("dt", [_vm._v("Статус закупки:")]),
            _c("dd", {
              domProps: { textContent: _vm._s(this.$props.pivc.status.title) },
            }),
          ]),
        ]
      ),
      this.$props.pivc.purchaseitem.contract.hasFulfillment()
        ? _c("div", [
            _c("h5", [_vm._v("Документы об исполнении")]),
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "atmo-content-table" }, [
                _vm._m(0),
                _c(
                  "tbody",
                  _vm._l(this.$props.attachments, function (attachment, index) {
                    return _c("tr", { key: index }, [
                      _c("td", {
                        domProps: { textContent: _vm._s(index + 1) },
                      }),
                      _c("td", [
                        _c(
                          "a",
                          {
                            staticClass: "atmo-button-link",
                            staticStyle: {
                              color: "var(--green-color) !important",
                            },
                            attrs: {
                              href: _vm.$getAttachmentDownloadLink(
                                attachment.file_id
                              ),
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(attachment.name))]
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatdate")(
                              attachment.created_at,
                              "withSeconds"
                            )
                          )
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№")]),
        _c("th", [_vm._v("Документ")]),
        _c("th", [_vm._v("Дата и время загрузки")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }