<template>
    <div class="atmo-content-group">
        <slot></slot>

        <p v-if="$props.controller.islistfull">Добавлено максимально возможное количество документов: <span v-text="$props.controller.$props.maxfilesallowed"></span></p>

        <div class="table-responsive">
            <table v-bind:class="{ 'atmo-empty': $props.controller.isrequired && $props.controller.isempty }" class="atmo-content-table atmo-editable-attachments">
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Наименование</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(attachment, index) in $props.controller.list" v-bind:key="index">
                        <td v-text="index + 1"></td>
                        <td>
                            <a :href="$getAttachmentDownloadLink(attachment.file_id)" style="color: var(--green-color)">{{ attachment.name }}</a>
                        </td>
                        <td class="text-right">
                            <action-button-small icon="trash" @click="$props.controller.remove(attachment)" title="Удалить файл из списка" />
                        </td>
                    </tr>
                    <tr v-if="$props.controller.isempty">
                        <td colspan="3" class="atmo-centered">Не добавлено документов</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p v-if="$props.controller.isrequired && $props.controller.isempty" class="atmo-error">Добавьте документы</p>

        <slot name="aux"></slot>

        <ul class="atmo-buttons">
            <li v-if="!$props.controller.islistfull">
                <label v-bind:disabled="$props.controller.is_waiting" class="atmo-button">
                    <span>Добавить</span>
                    <input v-on:change="$props.controller.add" type="file" accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx" />
                </label>
            </li>
            <slot name="controls"></slot>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        controller: { required: true, type: Object }
    }
};
</script>
