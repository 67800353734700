<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label="label + ':'" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <template v-if="withValidation">
            <b-input-group>
                <the-mask :id="id" mask="+# (###) ###-##-##" :class="'form-control fs-14 ' + (v !== null ? (state ? 'is-valid' : 'is-invalid') : '')" v-model="text" :masked="false" :disabled="phoneConfirmed || disabled"></the-mask>
                <b-input-group-append>
                    <b-button variant="custom-danger" class="text-uppercase" style="height: 35px" v-if="!phoneConfirmed" :disabled="v !== null && !state" @click="sendPhoneConfirmationRequest">Подтвердить</b-button>
                    <b-button variant="custom-green" class="text-uppercase" style="height: 35px" disabled v-else>Подтвержден</b-button>
                </b-input-group-append>
            </b-input-group>
        </template>
        <template v-else>
            <the-mask :id="id" mask="+# (###) ###-##-##" :class="'form-control fs-14 ' + (v !== null ? (state ? 'is-valid' : 'is-invalid') : '')" v-model="text" :masked="false" :disabled="disabled"></the-mask>
        </template>
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
        <success-confirm-modal :success-confirm="successConfirm" @hide="hideSuccessConfirm" />
        <b-modal v-if="withValidation" ref="phone-confirmation-modal" title="Введите код подтверждения, отправленный на указанный номер телефона" v-model="showPhoneConfirmationModal" no-close-on-backdrop no-close-on-esc hide-header-close centered hide-footer>
            <div class="d-block mb-4">
                <the-mask placeholder="Введите код" v-model="phoneConfirmationCode" mask="######" class="form-control"></the-mask>
            </div>
            <div class="mb-4">
                <b-button variant="custom-outline-secondary" class="mr-2 text-uppercase" @click="cancelPhoneConfirmation">Отмена </b-button>
                <b-button variant="custom-green" class="text-uppercase" @click="checkPhone"> Подтвердить </b-button>
            </div>
        </b-modal>
    </b-form-group>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import SuccessConfirmModal from '@/components/auth/parts/success-confirm-modal';
import { $api } from '@/services/backend/api';

export default {
    name: 'form-row-edit-phone',
    components: {
        SuccessConfirmModal,
        TheMask
    },
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        withValidation: {
            type: Boolean,
            default: false
        },
        phoneConfirmed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            phoneConfirmationCode: '',
            successConfirm: false,
            showPhoneConfirmationModal: false
        };
    },
    methods: {
        checkPhone() {
            $api.authentication
                .checkPhoneConfirmation(this.text, this.phoneConfirmationCode)
                .then(() => {
                    this.successConfirm = true;
                    setTimeout(() => {
                        this.successConfirm = false;
                    }, 3000);
                    this.phoneConfirmed = true;
                    this.$emit('phone-confirmed', this.phoneConfirmed);
                    this.cancelPhoneConfirmation();
                })
                .catch((err) => {
                    this.pushToast({
                        text: err.response.data.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        sendPhoneConfirmationRequest() {
            $api.authentication
                .requestPhoneConfirmation(this.text)
                .then(() => {
                    this.phoneConfirmationCode = '';
                    this.showPhoneConfirmationModal = true;
                })
                .catch((err) => {
                    this.pushToast({
                        text: err.response.data.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        cancelPhoneConfirmation() {
            this.phoneConfirmationCode = '';
            this.showPhoneConfirmationModal = false;
        },
        hideSuccessConfirm() {
            this.successConfirm = false;
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('value', value);
            }
        },
        id() {
            return 'form_row_edit_phone_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения. Формат: +7-(ХХХ) ХХХ-ХХ-ХХ';
                if (!this.v.minLength && !!this.v.$params.minLength) return `Минимальная длина поля - ${this.v.$params.minLength.min} символа/ов`;
                if (!this.v.phone && !!this.v.$params.phone) return 'Проверьте правильность заполнения номера телефона. Формат: +7-(ХХХ) ХХХ-ХХ-ХХ';
            }
            return '';
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
