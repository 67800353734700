'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';

export interface IData {
    tabIdentifier: TabIdentifier;
}

export interface IVMEventPayload {
    id: TabMenuID;
    selected: TabIdentifier;
}

export type TabIdentifier = number | string;
type TabMenuID = string;

@Component
export default class TabMenuController extends BaseViewModel {
    constructor() {
        super();
        this.name = 'TabMenuController';
    }

    @Prop({ required: true, type: String }) readonly tabmenuid!: number;

    data(): IData {
        return {
            tabIdentifier: 1
        };
    }

    /**
     * Computed
     */

    /**
     * Methods
     */
    select(tabIdentifier: TabIdentifier): void {
        this.$data.tabIdentifier = tabIdentifier;
        this.$root.$emit('public:tabmenu:selected', { id: this.$props.tabmenuid, selected: this.$data.tabIdentifier });
    }

    isSelected(tabIdentifier: TabIdentifier): boolean {
        return this.$data.tabIdentifier === tabIdentifier;
    }
}
