<template>
    <td class="atmo-ctc-details-dropdown">
        <on-off v-slot:default="onoff" v-bind:options="{ onEsc: false, onBody: false }">
            <div class="atmo-component-wrapper">
                <button v-on:click.stop="onoff.toggle" class="atmo-button-icon">
                    <span v-if="!onoff.ison" title="Показать подробную информацию"><i class="atmo-icon-show"></i></span>
                    <span v-else title="Скрыть подробную информацию"><i class="atmo-icon-hide"></i></span>
                </button>
                <ul v-show="onoff.ison" v-on:click.stop class="atmo-list">
                    <li v-if="limit.budget_name">
                        <dt>Наименование бюджета</dt>
                        :
                        <dd v-text="limit.budget_name"></dd>
                    </li>
                    <li v-if="limit.funds_type_code">
                        <dt>Код типа средств</dt>
                        :
                        <dd v-text="limit.funds_type_code"></dd>
                    </li>
                    <li v-if="limit.event_code">
                        <dt>Код мероприятия</dt>
                        :
                        <dd v-text="limit.event_code"></dd>
                    </li>

                    <li v-if="limit.kosgu_code">
                        <dt>Код КОСГУ:&nbsp;</dt>
                        <dd v-text="limit.kosgu_code"></dd>
                    </li>
                    <!-- <li v-if="limit.sub_kosgu_code"><dt>Код СубКОСГУ:&nbsp;</dt><dd v-text="limit.sub_kosgu_code"></dd></li> -->
                    <li v-if="limit.source_funds">
                        <dt>Бюджет - источник трансферта:&nbsp;</dt>
                        <dd v-text="limit.source_funds"></dd>
                    </li>
                    <li v-if="limit.subsidy_code">
                        <dt>Код субсидии:&nbsp;</dt>
                        <dd v-text="limit.subsidy_code"></dd>
                    </li>
                    <li v-if="limit.invest_object">
                        <dt>Объект:&nbsp;</dt>
                        <dd v-text="limit.invest_object"></dd>
                    </li>
                    <li v-if="limit.direction_code">
                        <dt>Код направления:&nbsp;</dt>
                        <dd v-text="limit.direction_code"></dd>
                    </li>
                    <li v-if="limit.regional_project_result">
                        <dt>Результат проекта:&nbsp;</dt>
                        <dd v-text="limit.regional_project_result"></dd>
                    </li>
                    <li v-if="limit.additional_information_code">
                        <dt>Код доп. информации:&nbsp;</dt>
                        <dd v-text="limit.additional_information_code"></dd>
                    </li>
                    <li v-if="limit.eis_ident_no">
                        <dt>ИКЗ:&nbsp;</dt>
                        <dd v-text="limit.eis_ident_no"></dd>
                    </li>
                </ul>
            </div>
        </on-off>
    </td>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';

export default {
    props: {
        limit: { required: true, type: Object }
    },
    components: {
        'on-off': new OnOff()
    }
};
</script>
