<template>
    <div>
        <section class="atmo-page-banner-wrapper atmo-slim">
            <div class="atmo-container-fixed">
                <h2>Карта малых закупок республики Башкортостан</h2>
            </div>
        </section>

        <div class="atmo-container-fixed atmo-page-content">
            <iframe v-if="purchases_map && purchases_map_url" v-bind:src="purchases_map_url" class="atmo-purchases-map" title="Карта закупок"></iframe>
            <p v-else>Карта не подключена в конфигурации.</p>
        </div>
    </div>
</template>

<script>
import Config from '@core/js/ddriven/application/config/Config';

export default {
    metaInfo: {
        title: 'Карта закупок'
    },
    computed: {
        purchases_map() {
            return !!Config.get('theme.features.purchases_map');
        },
        purchases_map_url() {
            return Config.get('theme.variables.links.other.purchases_map');
        }
    }
};
</script>
