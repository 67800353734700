var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { attrs: { cols: "12", md: _vm.md, lg: _vm.lg } },
    [
      _c(
        "b-form-group",
        [
          _c("currency-input", {
            directives: [
              {
                name: "stop-number-mousewheel",
                rawName: "v-stop-number-mousewheel",
              },
            ],
            staticClass: "form-control",
            attrs: {
              placeholder: _vm.placeholder,
              currency: "RUB",
              locale: "ru",
              "allow-negative": false,
            },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          }),
          _c("span", { staticClass: "fs-10 halfWhite-color label-position" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }