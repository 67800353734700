'use strict';

export interface ITabMenuData {
    currentIndex: number;
}

export default class TabMenuService {
    tabs: ITabMenuData;

    constructor() {
        this.tabs = {
            currentIndex: 1
        };
    }

    public select(index: number): void {
        this.tabs.currentIndex = index;
    }

    public isSelected(index: number): boolean {
        return this.tabs.currentIndex === index;
    }
}
